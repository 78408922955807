export enum PayLinkSteps {
  Details,
  Waiver,
  Information,
  Payment
}

export enum WaiverSusbscriptionTrigger {
  FirstSingup = 1,
  AllSignup = 2,
  OnlyForSelectServiceCatogory = 3
}

export enum TrackMode {
  StaffWebApp = 1,
  StaffMobileApp = 2,
  ClientMobileApp = 3,
  ClientPurchasePortal = 4,
  ClientWebsiteWidget = 5,
  FFMavrick = 6,
  ClientWebSignup = 7,
  FFHangfireJob = 8
}

export enum Status {
  InActive = 0,
  Active = 1
}

export interface Tenant_Access {
  id: number;
  name_primary_language: string;
  name_secondary_language: string;
}

export interface Ienum<T> {
  key: string | T,
  value: string | T
}

//#region Staff

export enum StaffStatus {
  Invited = 0,
  Active = 1,
  Suspended = 2,
  Terminated = 3
}

export enum IPSettings {
  Unrestricted = 1,
  CentreIPs = 2,
  CentrePlusAdditionalIPs = 3
}

export enum Roles {
  Admin = 1,
  Staff = 2,
  Member = 3,
  FFAdmin = 4
}

export enum StaffGender {
  Female = 1,
  Male = 2,
  Transgender = 3,
  NonBinaryOrNonConforming = 4,
  PreferNotToRespond = 5
}

//#endregion Staff



//#region Client
// export enum Client {
//   status =  0
// }
export enum ClientGender {
  Female = 1,
  Male = 2,
  Transgender = 3,
  NonBinaryOrNonConforming = 4,
  PreferNotToRespond = 5
}

export enum Crown {
  SilverCrown = 'silver-crown',
  GrayCrown = 'gray-crown',
  GoldCrown = 'gold-crown',
  Default = 'default'
}
export enum CoinIcons {
  LessSpentIcon = 'less-spent-icon',
  MediumSpentIcon = 'medium-spent-icon',
  MostSpentIcon = 'most-spent-icon',
  Default = 'default'
}
export enum ClientRegular {
  Regular = 0,
  IRRegular = 1
}

export enum ClientStatus {
  Prospect = 1,
  Trail = 2,
  Client = 3,
  ClientNonMember = 4,
  ExClient = 5,
  BlockedClient = 6
}

export enum ClientSubStatus {
  FutureClient = 1,
  ActiveCLient = 2,
  FrozenClient = 3,
  SuspendedClient = 4,
  ExMemberCancelled = 5,
  ExMemberExpired = 6,
  ExMemberTransferred = 7,
  Prospect = 8,
  Trial = 9,
  BlockedClient = 10
}

export enum ClientChannel {
  Online = 1,
  WalkIn = 2,
  Phone = 3,
  DataEntry = 4
}

export enum Gender {
  male = "Male",
  female = 'Female',
  other = 'Other'
}
export enum EnquirySource {
  web = 'web',
  walkIn = 'walk-in',
  phone = 'phone'
}

export enum TimeLine {
  BeyondFiveYears = 5,
  BeyondThreeYears = 3,
  BeyondOneYears = 1,
  TimeAgo = 'ago',
  TimeToGO = 'togo'
}

export enum GridData {
  RowLoaderTime = 1200,
  DefaultCurrentPage = 1,
  DefaultInitilaPage = 0,
  DefaultPageSize = 10,
  DefaultPageItems = 10,
  DefaultPages = 10
}
export enum ElasticProviderName {
  Clients = "Clients",
  Actions = "Actions",
  KbDocuments = "KbDocuments"
}
//#endregion Client

//#region paylink

export enum PaymentGatewaySupported {
  Offline = 0,
  RazorpayIndia = 1,
  PayenginUSA = 2,
  TwoC2pVietnam = 3,
  TwoC2pSingapore = 4,
  TwoC2pMalaysia = 5,
  EzypayAustralia = 6,
  EzypaySingapore = 7,
  EzypayMalaysia = 8,
  EzypayPhilippines = 9,
  PayenginePhilippines = 10
}

export enum PaymentGatewayType {
  Primary = 1,
  ApplePay = 2,
  GooglePay = 3,
  AmazonPay = 4,
  UPI = 5,
  Paytm = 6
}

export enum PayModeType {
  Cash = 1,
  Card = 2,
  NetBanking = 3,
  UPI = 4,
  Wallet = 5,
  ACH = 6,
  Cheque = 7,
  Other = 8,
}
//#endregion paylink


//#region Waiver
export enum WaiverVariablesType {
  CheckBox,
  Date,
  Sign,
  TextBox
}
//#endregion Waiver