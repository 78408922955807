import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
} from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { StyleClassModule } from 'primeng/styleclass';
import {
  ShortNamePipe,
  ShortNamePipeModule,
} from 'libs/shared/src/lib/filters/name-initials.pipe';
import { ClientService } from '@fitness-force/api';

@Component({
  selector: 'client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientInfoComponent implements OnInit {
  @Input() columnData = new BehaviorSubject([]);
  constructor(private router: Router, private clientService: ClientService) { }

  ngOnInit(): void {

  }

  showFullNameIntials() { }
  
  gotoClient(id: any) {
    this.router.navigate(['/client/profile/', id]);
  }

  clientStatus(client: any): string {
    if (client && client.status)
      return this.clientService.getClientStatusHtml(client.status.toString());
    else
      return '';
  }
}

@NgModule({
  declarations: [ClientInfoComponent],
  imports: [
    CommonModule,
    AvatarModule,
    DropdownModule,
    StyleClassModule,
    ShortNamePipeModule,
    RouterModule,
    OverlayPanelModule
  ],
  exports: [ClientInfoComponent],
})
export class ClientInfoComponentModule { }
