import { Countries } from "@fitness-force/models";

export const COUNTRY_MASTER_STATE: Countries = {
    countries: [],
    country: {
        id: 0,
        name: '',
        dial_code: '',
        two_letter_code: '',
        three_letter_code: '',
        currency_code: '',
        currency_name: '',
        currency_symbol: '',
        iso_numeric: 0,
        miner_currency: 0
    }
} 