import { ClientService } from '@fitness-force/api';
import { BehaviorSubject } from 'rxjs';
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { BlockUIModule } from 'primeng/blockui';
import { InputTextModule } from 'primeng/inputtext';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  ErrorsComponentModule,
  FactoryHttpLoader,
  ImageCropDialogComponent,
} from '@fitness-force/shared';
import { HttpClient } from '@angular/common/http';
import { AppErrors, ErrorMsgComponentModule } from '@fitness-force/errors';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import {
  ClientChannel,
  ClientGender,
  EnquirySource,
} from '@fitness-force/enum';
import { InputMaskModule } from 'primeng/inputmask';
import { Store } from '@ngrx/store';
import { CalendarModule } from 'primeng/calendar';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { LANGUAGE } from '@fitness-force/models';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [MessageService, DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProfileComponent implements OnInit {
  @Input() editProfileModal: boolean | null = false;
  @Input() clientData$ = new BehaviorSubject(null);
  @Output() closeEditProfileDialog = new EventEmitter();
  @ViewChild('uploadClientImage') uploadClientImage: ElementRef;

  refCropper?: DynamicDialogRef;

  editProfileFormGroup: FormGroup = new FormGroup({});
  channelSource: string = EnquirySource.phone;
  promotionPreferenceMap = new Map();

  selectedGender: { name: string };
  uploadedImageSrc = null;
  allClientData: any;
  clientInputData$ = new BehaviorSubject(null);
  EnumGender = Object.entries(ClientGender)
    .filter((key) => typeof key[1] != 'number')
    .map(([key, value]) => ({ key, value }));
  channelCollection: any[] = [
    {
      channel: ClientChannel[ClientChannel.Online],
      source: ['Facebook', 'Website', 'Instagram', 'Class Pass', 'Gym Pass'],
    },
    {
      channel: ClientChannel[ClientChannel.WalkIn],
      source: ['Hoarding', 'Referral', 'Passing By'],
    },
    {
      channel: ClientChannel[ClientChannel.Phone],
      source: ['Hoarding', 'Website', 'Referral', 'Passing By'],
    },
    {
      channel: ClientChannel[ClientChannel.DataEntry],
      source: ['Pop-up Store', 'Referral'],
    },
  ];

  mobile_country_code: string = '+91';
  companyDetails: any;

  showErrorPopup$ = new BehaviorSubject(false);
  error = new BehaviorSubject(null as AppErrors | null);
  errorMessages: any = [];

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private clientService: ClientService,
    private messageService: MessageService,
    private store: Store,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.componentInit();
  }

  componentInit() {
    this.editProfileFormGroup = this.fb.group({
      mobileNumber: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email]],
      channel: ['', [Validators.required]],
      source: ['', [Validators.required]],
      referedBy: [''],
      promotionPreferance: ['', [Validators.required]],
      clientProfile: [''],
      image: [''],
      dateOfBirth: [''],
      secondMobileNo: [''],
      preferredLanguage: [''],
      pinCode: [''],
      address: [''],
      emergencyContactName: [''],
      emergencyContactNo: [''],
      taxNumber: [''],
      migrationId: [''],
      medicalAlert: [''],
      note: [''],
    });

    this.store.select(COMPANY_INFO).subscribe({
      next: (comp) => {
        this.companyDetails = comp;
      },
      error: (err) => {},
    });
    this.clientData$.subscribe((client: any) => {
      this.GetClient(client.id);
    });

    this.editProfileFormGroup.controls.channel.valueChanges.subscribe(
      (mode: any) => {
        if (mode && mode.channel) {
          this.editProfileFormGroup.controls.source.setValidators([
            Validators.required,
          ]);
        } else {
          this.editProfileFormGroup.controls.source.clearValidators();
        }
        this.editProfileFormGroup.controls.source.updateValueAndValidity();
      }
    );
  }

  GetClient(clientId: number) {
    this.clientService.getClientById(clientId).subscribe({
      next: (client: any) => {
        this.addProfileData(client);
      },
      error: (error) => {},
    });
  }

  GetSelectedGender(): ClientGender {
    let selectedGender: ClientGender = ClientGender.PreferNotToRespond;
    let gender = this.Gender.value;
    switch (String(gender.value).toLowerCase()) {
      case 'male':
        selectedGender = ClientGender.Male;
        break;
      case 'female':
        selectedGender = ClientGender.Female;
        break;
      case 'nonbinaryornonconforming':
        selectedGender = ClientGender.NonBinaryOrNonConforming;
        break;
      case 'prefernottorespond':
        selectedGender = ClientGender.PreferNotToRespond;
        break;
      case 'transgender':
        selectedGender = ClientGender.Transgender;
        break;
      default:
        break;
    }
    return selectedGender;
  }

  addProfileData(client: any) {
    this.mobile_country_code = client.mobile_country_code
      ? client.mobile_country_code
      : this.mobile_country_code;
    this.allClientData = client;
    this.MobileNumber.setValue(client.mobile_number);
    this.FullName.setValue(client.full_name);
    this.Gender.setValue(this.EnumGender.find((g) => g.value == client.gender));
    this.EmailId.setValue(client.email_id);
    this.Channel.setValue(
      this.channelCollection.find(
        (c) => c.channel.toLowerCase() == client.channel.toLowerCase()
      )!
    );
    this.SourceControl.setValue(
      this.Channel.value.source.find(
        (s: any) => s.toLowerCase() == client.source.toLowerCase()
      )!
    );
    this.channelSource = client.channel ? client.channel.toLowerCase() : '';
    this.promotionPreferenceMap.set(
      client.source.toLowerCase(),
      client.source.toLowerCase()
    );
    this.PromotionPreferance.setValue(this.getPromotionPreferenceMapData);
    this.DateOfBirthFormControl.setValue(
      client.date_of_birth ? new Date(client.date_of_birth) : null
    ),
      this.SecondMobileNoFormControl.setValue(client.second_mobile_number);
    this.PreferedLanguageFormControl.setValue(
      this.SetPrefersLanguage(client.preferred_language)
    );
    this.PinCodeFormControl.setValue(client.address_pin_code);
    this.AddressFormControl.setValue(client.address);
    this.EmergencyContactNameFormControl.setValue(
      client.emergency_contact_person
    );
    this.EmergencyContactNoFormControl.setValue(
      client.emergency_contact_number
    );
    this.TaxNumberFormControl.setValue(client.taxnumber);
    this.MigrationIdFormControl.setValue(client.migration_id);
    this.MedicalAlertFormControl.setValue(client.medical_alert);
    this.NoteFormControl.setValue(client.note);
    this.ClientImageFormControl.setValue(client.image);
  }

  closeDialogEvent() {
    this.closeEditProfileDialog.emit(false);
  }

  updateProfile() {
    if (this.editProfileFormGroup.valid) {
      const updateProfileData = {
        home_club_tenant_id: this.allClientData.home_club_tenant_id,
        full_name: this.FullName.value,
        mobile_country_code: this.allClientData.mobile_country_code,
        mobile_number: this.MobileNumber.value,
        email_id: this.EmailId.value,
        date_of_birth: this.datePipe.transform(
          this.DateOfBirthFormControl.value,
          'yyyy-MM-dd'
        ),
        gender: this.GetSelectedGender(),
        location: this.allClientData.location,
        second_mobile_country_code: this.mobile_country_code,
        second_mobile_number: this.SecondMobileNoFormControl.value,
        preferred_language: this.PreferedLanguageFormControl.value
          ? this.PreferedLanguageFormControl.value.LANG_CODE
          : '',
        place_id: this.allClientData.place_id,
        address: this.AddressFormControl.value,
        city_code: this.allClientData.city_code,
        state_code: this.allClientData.state_code,
        country_code: this.allClientData.country_code,
        address_pin_code: this.PinCodeFormControl.value,
        emergency_contact_number: this.EmergencyContactNoFormControl.value,
        emergency_contact_person: this.EmergencyContactNameFormControl.value,
        source: this.SourceControl.value,
        channel: this.Channel.value.channel,
        heartrate_monitoring_id: this.allClientData.heartrate_monitoring_id,
        referred_by: this.ReferedBy.value ? this.ReferedBy.value : 0,
        promotional_sms: this.hasPromotionalPreference('sms'),
        promotional_email: this.hasPromotionalPreference('web'),
        medical_alert: this.MedicalAlertFormControl.value,
        note: this.NoteFormControl.value,
        taxnumber: this.TaxNumberFormControl.value,
        custom_fields: this.allClientData.custom_fields,
        current_keyfob: this.allClientData.current_keyfob,
        client_representative_id: this.allClientData.client_representative_id,
        createdin_tenant_id: this.allClientData.createdin_tenant_id,
        place_id_request: this.allClientData.place_id_request,
        migration_id: this.MigrationIdFormControl.value,
        image_base64: this.picture_Base64, 
        password: this.allClientData.password,
      };
      this.clientService
        .updateClientById(
          this.allClientData.id,
          this.allClientData.home_club_tenant_id,
          updateProfileData
        )
        .subscribe({
          next: async (res) => {
            this.translate
            .get('EDIT_CLIENT_PROFILE.MSG_UPDATE')
            .subscribe((translation) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: translation,
              });
            });
            await this.timeout(1000);
            this.closeDialogEvent();
          },
          error: (error) => {
            this.handleError(error);
          },
        });
    } else {
      Object.keys(this.editProfileFormGroup.controls).forEach((field) => {
        const control = this.editProfileFormGroup.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

  promotionalPreferenceSelection(preference: string) {
    if (this.promotionPreferenceMap.has(preference)) {
      this.promotionPreferenceMap.delete(preference);
    } else {
      this.promotionPreferenceMap.set(preference, preference);
    }
    this.PromotionPreferance.setValue(this.getPromotionPreferenceMapData);
  }

  handleFileSelect(files: NgxFileDropEntry[]) {
    let file: NgxFileDropEntry = files[0];
    if (file.fileEntry.isFile) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.showCropper(file);
      });
    }
  }

  showCropper(imageFile: any) {
    let header:string='common.IMAGE_CROP.CLIENT_IMAGE';
    this.refCropper = this.dialogService.open(ImageCropDialogComponent, {
      showHeader: false,
      baseZIndex: 10000,
      width: '70%',
      contentStyle: { 'max-height': '500px', 'max-width': '800px' },
      data: { imageFile , header},
    });
    this.refCropper.onClose.subscribe((croppedImage: any) => {
      if (croppedImage) {
        this.ClientProfile.setValue(croppedImage.image);
        this.cd.detectChanges();
      }
    });
  }

  imageUpload() {
    const uploadedImageRef = this.uploadClientImage.nativeElement;
    if (uploadedImageRef.files && uploadedImageRef.files[0]) {
      let fileToUpload = uploadedImageRef.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(fileToUpload);
      reader.onloadend = (files: any) => {
        this.uploadedImageSrc = files.target.result;
        this.ClientProfile.setValue(files.target.result);
      };
    }
  }

  hasPromotionalPreference(key: string) {
    return this.promotionPreferenceMap.has(key);
  }

  SetPrefersLanguage(langCode: string) {
    return this.parseSupportedLang.find(
      (lang) => lang.LANG_CODE.toLocaleLowerCase() == langCode
    );
  }

  handleError(error: any) {
    this.error.next(error);
    this.showErrorPopup$.next(true);
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup$?.next(false);
  }

  timeout(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get MobileNumber(): FormControl {
    return this.editProfileFormGroup.get('mobileNumber') as FormControl;
  }
  get FullName(): FormControl {
    return this.editProfileFormGroup.get('fullName') as FormControl;
  }
  get Gender(): FormControl {
    return this.editProfileFormGroup.get('gender') as FormControl;
  }
  get EmailId(): FormControl {
    return this.editProfileFormGroup.get('emailId') as FormControl;
  }
  get Channel(): FormControl {
    return this.editProfileFormGroup.get('channel') as FormControl;
  }
  get SourceControl(): FormControl {
    return this.editProfileFormGroup.controls.source as FormControl;
  }

  get ReferedBy(): FormControl {
    return this.editProfileFormGroup.get('referedBy') as FormControl;
  }

  get PromotionPreferance(): FormControl {
    return this.editProfileFormGroup.get('promotionPreferance') as FormControl;
  }

  get ClientProfile(): FormControl {
    return this.editProfileFormGroup.get('clientProfile') as FormControl;
  }

  get picture_Base64(): string {
    return this.ClientProfile.value
      ? this.ClientProfile.value.replace('data:image/png;base64,', '')
      : '';
  }

  get Source(): string[] {
    return this.Channel.value.source;
  }

  set Channel(value: any) {
    this.Channel.setValue(value);
  }

  get DisableSource(): boolean {
    return !(this.Source && this.Source.length > 0);
  }

  get DateOfBirthFormControl(): FormControl {
    return this.editProfileFormGroup.controls.dateOfBirth as FormControl;
  }

  get SecondMobileNoFormControl(): FormControl {
    return this.editProfileFormGroup.controls.secondMobileNo as FormControl;
  }

  get PreferedLanguageFormControl(): FormControl {
    return this.editProfileFormGroup.controls.preferredLanguage as FormControl;
  }

  get PinCodeFormControl(): FormControl {
    return this.editProfileFormGroup.controls.pinCode as FormControl;
  }
  get AddressFormControl(): FormControl {
    return this.editProfileFormGroup.controls.address as FormControl;
  }

  get EmergencyContactNameFormControl(): FormControl {
    return this.editProfileFormGroup.controls
      .emergencyContactName as FormControl;
  }

  get EmergencyContactNoFormControl(): FormControl {
    return this.editProfileFormGroup.controls.emergencyContactNo as FormControl;
  }

  get TaxNumberFormControl(): FormControl {
    return this.editProfileFormGroup.controls.taxNumber as FormControl;
  }

  get MigrationIdFormControl(): FormControl {
    return this.editProfileFormGroup.controls.migrationId as FormControl;
  }

  get MedicalAlertFormControl(): FormControl {
    return this.editProfileFormGroup.controls.medicalAlert as FormControl;
  }

  get NoteFormControl(): FormControl {
    return this.editProfileFormGroup.controls.note as FormControl;
  }

  get getPromotionPreferenceMapData(): Array<any> {
    return [...this.promotionPreferenceMap.values()];
  }

  get parseSupportedLang(): Array<LANGUAGE> {
    let supportedLangBuffer: Array<LANGUAGE> = [];
    const supportedLang =
      this.companyDetails.compDetails.supported_languages.map((l: any) =>
        l.trim().toLowerCase()
      );
    supportedLangBuffer = CONSTANTS_DATA.ALL_LANGUAGES.filter((lang) => {
      const languageOnlower = lang.LANG_ABBRE.toLowerCase();
      const langCodeOnlower = lang.LANG_CODE.toLowerCase();
      if (supportedLang && supportedLang.length > 0) {
        return (
          supportedLang &&
          (supportedLang.includes(languageOnlower) ||
            supportedLang.includes(langCodeOnlower))
        );
      } else if (
        this.companyDetails.compDetails.primary_language == languageOnlower
      ) {
        return lang;
      } else {
        return { COUNTRY: 'us', LANG_CODE: 'en', LANG_ABBRE: 'ENGLISH' };
      }
    });
    return supportedLangBuffer;
  }

  get ClientImageFormControl(): FormControl {
    return this.editProfileFormGroup.controls.image as FormControl;
  }
}

@NgModule({
  declarations: [EditProfileComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    InputMaskModule,
    CalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
    ErrorMsgComponentModule,
    ErrorsComponentModule,
    ToastModule,
    NgxFileDropModule,
  ],
  exports: [EditProfileComponent],
})
export class EditProfileComponentModule {}
