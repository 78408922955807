<!-- Table -->
<ng-container *ngIf="{
  row: columnData | async,
  column: columns | async,
  totalrecord: totalRecords | async,
  select: selectable | async
} as $">

<p-table
  [value]="$.row"
  selectionMode="multiple"
  scrollHeight="flex"
  scrollDirection="both"
  (onRowSelect)="selectedRow($event, true)"
  (onRowUnselect)="selectedRow($event, false)"
  (onHeaderCheckboxToggle)="SelectAllRow($event)"
  styleClass="shadow-2"
  responsiveLayout="scroll"
  [rowGroupMode]="groupMode"
  [groupRowsBy]="groupRows"
  [lazy]="true"
  [rows]="pageSize"
  [(first)]="first"
  [paginator]="paginator"
  [totalRecords]="$.totalrecord.totalItems"
  [showCurrentPageReport]="true"
  (onLazyLoad)="goToNextPage($event)"
  [loading]="loading"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 20,40]"
>
  <ng-template pTemplate="header">
    <tr class="text-xs ">
      <th *ngIf="$.select" class="text-gray-600" [ngClass]="headerClasses">
        <!-- <p-checkbox name="groupname" value="val1" label=""></p-checkbox> -->
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <ng-container *ngFor="let col of $.column">
        <th
          class="
            text-gray-600
            bg-gray-100
            font-bold
            border-none
            white-space-nowrap
          "
          [ngClass]="headerClasses"
        >
          {{ col.title }}
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowgroup="rowgroup"
    let-rowspan="rowspan"
    let-rowIndex="rowIndex"

    let-data
  >
    <tr class="text-sm hover:bg-gray-200"  >
      <td *ngIf="$.select" class="py-2 white-space-nowrap">
        <p-tableCheckbox [value]="setCheckBoxValue(rowIndex,data)"></p-tableCheckbox>
      </td>
      <ng-container *ngIf="!groupMode">
        <ng-container *ngFor="let col of $.column; let i = index">
          <td class="py-2 white-space-nowrap" [ngClass]="col.classes">
            <ng-container *ngIf="col.type == 'default'">
              {{ data[col.dataProperty] }}
            </ng-container>
            <ng-container *ngIf="col.type == 'component'">
              <app-grid-cell
                [cell]="setAsObserVable(col)"
                [cellData]="setAsObserVable(data)"
                (onAction)="onActionEvent($event)"
                [allSelection]="isAllSelected"
                [rowIndex]="rowIndex"
                [selectedRows]="selectedRows"
              ></app-grid-cell>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="groupMode">
        <ng-container *ngFor="let col of $.column; let i = index">
          <td
            *ngIf="i != 0; else spanContent"
            class="py-2 white-space-nowrap"
            [ngClass]="col.classes"
          >
            <ng-container *ngIf="col.type == 'default'">
              {{ data[col.dataProperty] }}
            </ng-container>
            <ng-container *ngIf="col.type == 'component'">
              <app-grid-cell
              [cell]="setAsObserVable(col)"
                [cellData]="setAsObserVable(data)"
                (onAction)="onActionEvent($event)"
              ></app-grid-cell>
            </ng-container>
          </td>
          <ng-template #spanContent>
            <td
              class="py-2 white-space-nowrap  bg-white"
              [ngClass]="col.classes"
              *ngIf="rowgroup"
              [attr.rowspan]="rowgroup ? rowspan : null"
            >
              <ng-container *ngIf="col.type == 'default'">
                {{ data[col.dataProperty] }}
              </ng-container>
              <ng-container *ngIf="col.type == 'component'">
                <app-grid-cell
                [cell]="setAsObserVable(col)"
                [cellData]="setAsObserVable(data)"
                  (onAction)="onActionEvent($event)"
                  [allSelection]="isAllSelected"
                ></app-grid-cell>
              </ng-container>
            </td>
          </ng-template>
        </ng-container>
      </ng-container>
    </tr>

  </ng-template>
  <ng-template pTemplate="emptymessage">

    <td [attr.colspan]="$.column.length + 1" class="pt-8 pb-2 text-center bg-white">
      <empty-state-container (onAction)="emptyAction($event)"  [emptyState]="EmptyState"></empty-state-container>
  </td>
</ng-template>
<ng-template pTemplate="loadingbody">
  <!--  TODO: show row and columns counts based on real data-->


  <p-table [value]="[0,1,2,3,4,5,6,7,8,9]">
    <ng-template pTemplate="header">
      <tr>
        <th><p-skeleton  animation="wave" width="100%" height="2rem"></p-skeleton></th>
        <th><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></th>
        <th><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></th>
        <th><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></th>
        <th><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></th>
        <th><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-items>
        <tr>
          <td><p-skeleton  animation="wave" width="100%" height="2rem"></p-skeleton></td>
          <td><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></td>
          <td><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></td>
          <td><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></td>
          <td><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></td>
          <td><p-skeleton animation="wave" width="100%" height="2rem"></p-skeleton></td>
        </tr>
      </ng-template>
    </p-table>


</ng-template>
</p-table>

</ng-container>


<!-- </ng-container> -->


<!-- </ng-container> -->


<p-dialog
  [(visible)]="showCalendar"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
  [breakpoints]="{ '1025px': '100vw', '640px': '100vw' }"
  [style]="{ width: '29vw' }"
  styleClass="no-header-modal no-overflow-content"
>
  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">
    <p-calendar
      styleClass="w-full"
      [(ngModel)]="calendarInput"
      [inline]="true"
      [showWeek]="true"
      (onSelect)="showCalendar = false"
    ></p-calendar>
  </ng-template>
</p-dialog>
