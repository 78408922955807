import { Pipe, PipeTransform, NgModule, Injectable } from '@angular/core';
import { formatCurrency, getCurrencySymbol, getLocaleCurrencyCode, getLocaleNumberFormat, getNumberOfCurrencyDigits, NumberFormatStyle } from '@angular/common';
import { Store } from '@ngrx/store';
import { COUNTRY_MASTER_STATE } from 'apps/ff-frontend/src/app/state/app.state';
import { Country } from '@fitness-force/models';
import { CommonService, CountryService } from '@fitness-force/api';
import { BehaviorSubject } from 'rxjs';
import { LOCALE_ID, Inject } from '@angular/core';

@Pipe({
  name: 'currencyformat',
})
@Injectable({
  providedIn: 'root'
})
export class CurrencyformatPipe implements PipeTransform {
  currencyCode = new BehaviorSubject('');
  currencyName = new BehaviorSubject('');
  country: Country;
  constructor(public store: Store, @Inject(LOCALE_ID) public locale: string, private commmonService: CommonService) { }

  transform(value: number, digitsInfo?: string): string {
    this.store.select(COUNTRY_MASTER_STATE).subscribe((country: Country) => {
      this.currencyCode.next(country.currency_code);
      this.currencyName.next(country.currency_name);
      this.country = country;
    });
    value = this.commmonService.ConvertAmountFromMinorCurrency(value);
    return formatCurrency(
      value,
      this.locale,
      getCurrencySymbol(this.currencyCode.value, 'wide', this.locale),
      getLocaleCurrencyCode(this.locale)!
    );
  }
}

@NgModule({
  declarations: [CurrencyformatPipe],
  imports: [],
  exports: [CurrencyformatPipe],
})
export class CurrencyformatPipeModule { }
