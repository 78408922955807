import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';

@Component({
  selector: 'no-component',
  templateUrl: './no-component.component.html',
  styleUrls: ['./no-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoComponentComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [NoComponentComponent],
  imports: [],
  exports: [NoComponentComponent]
})
export class NoComponentComponentModule {
}
