import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { CancelClassRequest, ClassById, ClassListResponse, CreateClassRequest, CreateRecurringClassRequest, CreateResponse, ListClassRecurringTemplate, ListClassRequest, ListRecurringClassRequest, RescheduleClassRequest, RescheduleRecurringClassRequest, SubstituteCoachInClassRequest, UpdateClassRequest } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClassService {

  constructor(private http: HttpClient, private router: Router) { }

  BASE_URL: string = environment.BASE_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  GetClasses(classListRequest: ListClassRequest): Observable<ClassListResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.GET_CLASSES.replace('{tenant_id}', classListRequest.tenant_id.toString());
    let queryParams = new HttpParams()
    .set('start_date', classListRequest.start_date)
    .set('end_date', classListRequest.end_date)
    if(classListRequest.getClientDetails)
    {
      queryParams = queryParams.append("getClientDetails",classListRequest.getClientDetails)
    }

    return this.http.get<ListClassRequest>(url,{headers: this.httpOptions.headers,params:queryParams}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  GetClass(tenantId: number,classId:number): Observable<ClassById> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.GET_CLASS.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.get(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  GetRecurringClassTemplates(tenantId: number): Observable<ListClassRecurringTemplate> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.GET_RECURRING_CLASS_TEMPLATE.replace('{tenant_id}', tenantId.toString());
    return this.http.get(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  GetRecurringClassesByTemplateId(listRecurringClassRequest : ListRecurringClassRequest): Observable<ListClassRecurringTemplate> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.GET_RECURRING_CLASSES.replace('{tenant_id}', listRecurringClassRequest.tenant_id.toString()).replace('{class_recurring_template_id}', listRecurringClassRequest.class_recurring_template_id.toString());
    const queryParams = {"page_number":listRecurringClassRequest.page_number,"page_size":listRecurringClassRequest.page_size};

    return this.http.get(url,{headers: this.httpOptions.headers,params:queryParams}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }


  CreateClass(tenantId:number,createClassRequest: CreateClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.POST_CLASSES.replace('{tenant_id}', tenantId.toString());
    return this.http.post<CreateClassRequest>(url,createClassRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  CreateRecurringClass(tenantId:number,createRecurringClassRequest: CreateRecurringClassRequest ): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.POST_RECURRING_CLASSES.replace('{tenant_id}', tenantId.toString());
    return this.http.post<CreateRecurringClassRequest>(url,createRecurringClassRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  UpdateClass(tenantId:number, classId:number, updateClassRequest: UpdateClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.PUT_CLASS.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.put<UpdateClassRequest>(url,updateClassRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  UpdateRecurringClass(tenantId:number, classTemplateId:number, updateClassRequest: UpdateClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.PUT_RECURRING_CLASS.replace('{tenant_id}', tenantId.toString()).replace('{class_recurring_template_id}', classTemplateId.toString());
    return this.http.put<UpdateClassRequest>(url,updateClassRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  RescheduleClass(tenantId:number, classId:number, rescheduleClassRequest: RescheduleClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.RESCHEDULE_CLASS.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.post<RescheduleClassRequest>(url,rescheduleClassRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  RescheduleRecurringClass(tenantId:number, classTemplateId:number, rescheduleClassRequest: RescheduleRecurringClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.RESCHEDULE_RECURRING_CLASS.replace('{tenant_id}', tenantId.toString()).replace('{class_recurring_template_id}', classTemplateId.toString());
    return this.http.post<RescheduleRecurringClassRequest>(url,rescheduleClassRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  SubstituteCoach(tenantId:number, classId:number, substituteCoachRequest: SubstituteCoachInClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.SUBSTITUTE_COACH.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.post<SubstituteCoachInClassRequest>(url,substituteCoachRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  SubstituteCoachInRecurring(tenantId:number, classTemplateId:number, substituteCoachRequest: SubstituteCoachInClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.SUBSTITUTE_COACH_RECURRING.replace('{tenant_id}', tenantId.toString()).replace('{class_recurring_template_id}', classTemplateId.toString());
    return this.http.post<SubstituteCoachInClassRequest>(url,substituteCoachRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  CancelClass(tenant_Id:number,classId:number, cancelRequest:CancelClassRequest): Observable<any> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.CANCEL_CLASS.replace('{tenant_id}', tenant_Id.toString()).replace('{class_id}', classId.toString());
    return this.http.post(url,cancelRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  DeleteClass(tenant_Id:number,classId:number): Observable<any> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.DELETE_CLASS.replace('{tenant_id}', tenant_Id.toString()).replace('{class_id}', classId.toString());
    return this.http.delete(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  MarkClassComplete(tenant_Id:number,classId:number): Observable<any> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS.COMPLETE_CLASS.replace('{tenant_id}', tenant_Id.toString()).replace('{class_id}', classId.toString());
    return this.http.post(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }


}
