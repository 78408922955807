import { RouterStateSnapshot } from "@angular/router";
import { ROUTER_SERIALIZER_INTERFACE } from "@fitness-force/models";
import { RouterStateSerializer } from "@ngrx/router-store";

export class RouterSerializerClass implements RouterStateSerializer<ROUTER_SERIALIZER_INTERFACE> {
    serialize(routerState: RouterStateSnapshot): ROUTER_SERIALIZER_INTERFACE {
        let route = routerState.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
     
        const {
          url,
          root: { queryParams },
        } = routerState;
        const { params } = route;
     
        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return { url, params, queryParams };
    }
}