import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentGatewaySupported } from '@fitness-force/enum';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { BehaviorSubject } from 'rxjs';
import { loadPayengine } from 'payengine';

import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'payengine',
  templateUrl: './payengine.component.html',
  styleUrls: ['./payengine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayengineComponent implements OnInit {
  private form: any;
  @Input('visible') showpg$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
  @Input('transactionId') internalTransactionId$: string = "";
  @Input('uuid') uuid: string = "";
  @Input('hmac') hmac: string = "";
  @Input('key') publicKey: string = "";
  @Input('paymethod') paymentMethod: string = "";

  showSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject(true as boolean);
  payEngine: any;
  css = {
    fontFamily: '"Helvetica Neue", Helvetica',
    boxSizing: "border-box",
    lineHeight: "1",
    fontSize: "0.875rem",
    fontWeight: "400",
    border: "1px solid #ced4da",
    color: "#495057",
    background: "#fff",
    width: "100%",
    height: "36px",
    borderRadius: "0.25rem",
    padding: "0.5rem 0.75rem",
    "&::placeholder": {
      color: "#CFD7E0",
    },
  };

  constructor(
    private router: Router,
  ) {
    setTimeout(() => {
      this.showSkeleton$.next(false);
    }, 4000);
  }

  async ngOnInit(): Promise<void> {
    this.payEngine = await loadPayengine({ publicKey: this.publicKey });



    if (this.paymentMethod == 'cc' || this.paymentMethod == 'dc')
      this.loadCardFields();

    if (this.paymentMethod == 'ach')
      this.loadBankFields();

    console.log("ngOnInit finish");
  }

  loadCardFields() {
    const css = this.css;
    this.payEngine.SecureFields.create().then((form: any) => {
      this.form = form;

      form.field("#card-name", {
        type: "text",
        name: "card_holder",
        placeholder: "Card holder",
        validations: ["required"],
        css,
      });

      form.field("#card-number", {
        type: "card-number",
        name: "card_number",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Card number",
        showCardIcon: false,
        validations: ["required", "validCardNumber"],
        css,
      });

      form.field("#card-cvc", {
        type: "card-security-code",
        name: "card_cvc",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "CVC",
        maxLength: 3,
        validations: ["required", "validCardSecurityCode"],
        css,
      });

      form.field("#card-expiry", {
        type: "card-expiration-date",
        name: "card_exp",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "MM / YY",
        validations: ["required", "validCardExpirationDate"],
        css,
      });
    });
  }

  loadBankFields() {
    const css = this.css;
    this.payEngine.SecureFields.create().then((form: any) => {
      this.form = form

      form.field("#routing-number", {
        type: "number",
        name: "routing_number",
        placeholder: "Routing Number",
        validations: ["required"],
        css,
      });

      form.field("#account-number", {
        type: "number",
        name: "account_number",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Account Number",
        validations: ["required"],
        css,
      });

      form.field("#first-name", {
        type: "text",
        name: "first_name",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "First Name",
        validations: ["required"],
        css,
      });

      form.field("#last-name", {
        type: "text",
        name: "last_name",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Last Name",
        validations: ["required"],
        css,
      });
    });
  }

  public async tokenizeCardAndProcessPayment(event: any) {
    try {
      this.showSkeleton$.next(true);
      event.preventDefault();
      // step 1: tokenize card
      const cardObj = await this.form.createCard();
      const token = cardObj.token;

      // step 2: process payment from partner backend
      this.navigateToCharge(token);
    }
    catch (e) {
      console.log(e);
      this.showSkeleton$.next(false);
    }
  }

  public async tokenizeACHAndProcessPayment(event: any) {
    try {
      this.showSkeleton$.next(true);
      event.preventDefault();
      // step 1: tokenize card
      const bankObj = await this.form.createBankAccount();
      const token = bankObj.token;

      // step 2: process payment from partner backend
      this.navigateToCharge(token);
    }
    catch (e) {
      console.log(e);
      this.showSkeleton$.next(false);
    }
  }

  async tokenizePlaidAndProcessPayment($event: any) {
    try {
      this.showSkeleton$.next(true);
      // step 1: tokenize card
      let token = $event.detail.data.token;

      // step 2: process payment from partner backend
      this.navigateToCharge(token);
      console.log('event', $event);
    }
    catch (e) {
      console.log(e);
      this.showSkeleton$.next(false);
    }
  }

  async onError($event: any) {
    // alert($event.detail.display_message);
    console.log('error event', $event);
    this.showSkeleton$.next(false);
  }

  async close() {
    this.showSkeleton$.next(false);
  }

  navigateToCharge(token: string): void {
    this.router.navigate(['/' + this.uuid + '/payment_callback'], {
      queryParams: {
        pg: PaymentGatewaySupported.PayenginUSA,
        token: token,
        internaltransactionid: this.internalTransactionId$,
      },
    });
  }

}

@NgModule({
  declarations: [PayengineComponent],
  imports: [
    CommonModule,

    ButtonModule,
    ProgressSpinnerModule,
    StyleClassModule,
  ],
  exports: [PayengineComponent]
})
export class PayengineComponentModule {
}
