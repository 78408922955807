import { StaffService } from '@fitness-force/api';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { STAFF_STATE } from 'apps/ff-frontend/src/app/state/app.state';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { StyleClassModule } from 'primeng/styleclass';
import { BehaviorSubject, forkJoin, combineLatest } from 'rxjs';

@Component({
  selector: 'client-representative',
  templateUrl: './client-representative.component.html',
  styleUrls: ['./client-representative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientRepresentativeComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  allStaffs:any = []
  staff$ = new BehaviorSubject(null)
  constructor(private store: Store, private staffService: StaffService) { }

  ngOnInit(): void {
  
    combineLatest([
      this.columnData,
      this.store.select(STAFF_STATE)
    ]).subscribe((res:any)=>{
  
      this.allStaffs = res[1].staffListAll
      this.getStaffDetails(res[0].client_representative_id)
    })
  }
  getStaffDetails(sId:any){
   if(this.allStaffs){
    const staffIdFormStore = this.allStaffs.find((s:any) => s.id == sId)
    if(staffIdFormStore){
      this.staff$.next(staffIdFormStore)
    }
  }
  //   else {
  //     this.staffService.GetStaffById(sId).subscribe((staff:any)=>{
  //       this.staff$.next(staff)
  //     })
  //   }
  //  } 
  //  else {
  //   this.staffService.GetStaffById(sId).subscribe((staff:any)=>{
  //     this.staff$.next(staff)
  //   })
  // }
  
  }

}

@NgModule({
  declarations: [ClientRepresentativeComponent],
  imports: [
    CommonModule,
    AvatarModule,
    DropdownModule,
    StyleClassModule,
    ShortNamePipeModule,
    OverlayPanelModule
  ],
  exports: [ClientRepresentativeComponent]
})
export class ClientRepresentativeComponentModule {
}
