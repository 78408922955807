export const CONSTANTS_CRM = {
  OPPORTUNITY_CONVERTITBILITY: [
    { name: 'Hot', value: '3', icon: '/assets/dist/img/icons/hot.svg' },
    { name: 'Cold', value: '1', icon: '/assets/dist/img/icons/cold.svg' },
    { name: 'Warm', value: '2', icon: '/assets/dist/img/icons/warm.svg' },
  ] as Convertibility[],

  CALL_OUTCOME: [
    {
      name: 'No Answer / Left Message',
      shortname: 'noanswer',
      value: 1,
      icon: 'pi pi-phone',
      iconColor: '#ED8536',
      class: 'text-sm bg-orange-500 border-orange-500',
    },
    {
      name: 'Contact Made',
      shortname: 'contactmade',
      value: 2,
      icon: 'pi pi-check-square',
      iconColor: '#4A9FB5',
      class: 'bg-cyan-600 border-cyan-600',
    },
    {
      name: 'NotInterested',
      shortname: 'notintrested',
      value: 3,
      icon: 'pi pi-times',
      iconColor: '#316CF3',
      class: 'p-ripple p-button-danger',
    },
    {
      name: 'booktour',
      shortname: 'booktour',
      value: 4,
      icon: 'pi pi-calendar-plus',
      iconColor: '#408558',
      class: 'p-ripple ng-star-inserted',
    },
    // {
    //   name: 'BookTrial',
    //   shortname: 'booktrial',
    //   value: 4,
    //   icon: 'pi pi-question',
    //   iconColor: '#408558',
    //   class: 'p-ripple p-button-primary',
    // },
    {
      name: 'Sign Up',
      shortname: 'signup',
      value: 6,
      icon: 'pi pi-money-bill',
      iconColor: '#CA444A',
      class: 'p-ripple p-button-primary',
    },
  ] as Outcome[],

  TOUR_OUTCOME: [
    {
      name: 'Not Interested',
      shortname: 'notintrested',
      value: 1,
      icon: 'pi pi-times',
      iconColor: '#ED8536',
      class: 'p-ripple p-button-danger'
    },
    {
      name: 'No Show',
      shortname: 'noshow',
      value: 2,
      icon: 'pi pi-ban',
      iconColor: '#4A9FB5',
      class: 'p-ripple p-button-warning',
    },
    {
      name: 'Reschedule',
      shortname: 'reschedule',
      value: 3,
      icon: 'pi pi-calendar-plus',
      iconColor: '#316CF3',
      class: 'p-ripple p-button-primary',
    },
    {
      name: 'Still Thinking',
      shortname: 'stillthinking',
      value: 4,
      icon: 'pi pi-question',
      iconColor: '#408558',
      class: 'p-ripple p-button-primary',
    },
    {
      name: 'Signing-up',
      shortname: 'signingup',
      value: 5,
      icon: 'pi pi-ticket',
      iconColor: '#CA444A',
      class: 'p-ripple p-button-primary',
    },
  ] as Outcome[],
 
};

interface Convertibility {
  name: string;
  value: string;
  icon: string;
}

interface Outcome {
  name: string;
  shortname:string;
  value: number;
  icon: string;
  iconColor: string;
  class: string;
}
