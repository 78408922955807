import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-grid-cell',
  templateUrl: './grid-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCellComponent implements OnInit, AfterViewInit, OnDestroy {
  componentFactory: any;
  componentRef: any;
  @Input() cell = new BehaviorSubject(null);
  @Input() cellData = new BehaviorSubject(null)
  @Input() allSelection = new BehaviorSubject(false);
  @Input() rowIndex :number;
  @Input() selectedRows:any;

  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef |any
  @Output() onAction = new EventEmitter();

  constructor(private componentFactoryResolver: ComponentFactoryResolver,  public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
      this.loadComponent()
  }
  loadComponent(){

    forkJoin([
      this.cell,
      this.cellData
    ]).subscribe((res:any)=>{
      this.componentFactory = res[0];
      this.componentRef = this.container.createComponent(this.componentFactoryResolver.resolveComponentFactory(this.componentFactory.data.name));
      this.componentRef.instance.columnData = of(res[1])

      if(res[0].data.additionData){
        this.componentRef.instance.additionData = of(res[0].data.aData)
      }
      this.componentRef.instance.isAllSelected = this.allSelection
      if(res[0].data.fetch_key){
        this.componentRef.instance.keyName = of(res[0].data.fetch_key)
      }
      if(this.rowIndex){
        this.componentRef.instance.rowIndex = this.rowIndex;
      }
      if(this.selectedRows){
        this.componentRef.instance.selectedRows = this.selectedRows;
      }
      this.componentRef.instance?.onAction?.subscribe((data:any)=>{
        this.onAction.emit(data)
      })
      this.componentRef.instance?.changeEvent?.subscribe((data:any)=>{
        this.onAction.emit(data)
      })

    })



   this.changeDetectorRef.detectChanges()
  }
  ngOnDestroy(): void {

    this.container.clear();
    this.componentRef.destroy()
  }
}
@NgModule({
  declarations: [GridCellComponent],
  imports: [
    CommonModule
  ],
  exports: [GridCellComponent]
})
export class GridCellModule {
}
