import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmniSearchRequest, OmniSearchResponse } from '@fitness-force/models';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../apps/ff-frontend/src/environments/environment';
import { AppErrors, NotFoundError } from '@fitness-force/errors';

@Injectable({
  providedIn: 'root'
})
export class OmniSearchService {
  BASE_URL: string = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  Search(request: OmniSearchRequest) {
    return this.http.post(this.BASE_URL + CONST_URL_SEGEMENTS.SEARCH.POST_SEARCH, request, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          }
          else {
            throw (res);
          }
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
