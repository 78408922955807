import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
} from '@angular/core';
import { AppointmentBehaviour, MembershipBehaviour } from '@fitness-force/enum';

@Component({
  selector: 'membership-behaviour-view',
  templateUrl: './membership-behaviour.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipBehaviourComponent implements OnInit {
  @Input() behavior: any;
  activeBehavior: any;
  @Input() columnData:any = ''
  @Input() additionData:any
  
  constructor() {}

  ngOnInit(): void {
    this.columnData.subscribe({next:(cData:any)=>{
      for (let [key, value] of Object.entries(MembershipBehaviour)) {
        if ( key == cData?.behaviour) {
          this.activeBehavior = { name: value, key };
        }
      }
    }})

  }


}

@NgModule({
  declarations: [MembershipBehaviourComponent],
  imports: [CommonModule],
  exports: [MembershipBehaviourComponent],
})
export class MembershipBehaviourComponentModule {}
