import { LocationStore } from '@fitness-force/models';

export const LOCATION_STATE: LocationStore = {
  location: {
    as: '',
    city: '',
    country: '',
    countryCode: '',
    isp: '',
    lat: 0,
    lon: 0,
    org: '',
    query: '',
    region: '',
    regionName: '',
    status: '',
    timezone: '',
    zip: '',
  },
};
