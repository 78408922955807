<p-dialog id="dialog_createOpportunity" [(visible)]="showPopup" [resizable]="false" appendTo="body" [modal]="true"
  (onHide)="setHidePopup()" [breakpoints]="{'960px': '100vw', '640px': '100vw'}" [style]="{width: '83vw'}"
  styleClass="no-header-modal" appendToBody="true">
  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">
    <form [formGroup]="frmgroupCreateOpportunity" (ngSubmit)="SaveData()"
      class="m-login-left card border-0 p-sm-0 p-0 start-0 end-0 top-0 bottom-0 scrollable vh-100">
      <div class="xl:flex">

        <!-- Left Side -->
        <div class="xl:w-4 hidden xl:block p-4 hidden bg-white xl:flex align-items-center justify-content-center"> <img
            src="/assets/img/create-opportunity-img2.svg" alt="Image" /> </div>
        <!-- Left Side -->

        <!-- Right Side -->
        <div class="xl:w-9 border-left-1 border-gray-300">

          <!-- Header -->
          <div class="py-3 px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
            <div class="flex align-items-center"> <i class="pi pi-plus text-gray-500 mr-3"></i>
              <h2 class="mb-0 font-normal text-lg" *ngIf="!_isEditMode">{{'OPPORTUNITY.CREATE.CREATE-OPPORTUNITY' |
                translate}}</h2>
              <h2 class="mb-0 font-normal text-lg" *ngIf="_isEditMode">{{'OPPORTUNITY.CREATE.UPDATE-OPPORTUNITY' |
                translate}}</h2>
            </div>
            <div class="pi pi-times text-gray-500 cursor-pointer" (click)="setHidePopup()"></div>
          </div>
          <!-- Header -->
          <div class="mx-2">
            <mini-search *ngIf="showPopup" [readonly]="clientIds && clientIds.length>0" [id]="clientIds"
              (clients)="setClient($event)"> </mini-search>
          </div>
          <!-- Header -->

          <!-- Content -->
          <div class="surface-50 text-sm px-3 md:px-4 py-3 border-bottom-1 border-gray-300">
            <div class="grid">
              <div class="col-6 md:col-4">
                <label
                  class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'OPPORTUNITY.CREATE.OPPORTUNITY-STAGE'
                  |
                  translate}}</label>
                <p-dropdown [options]="opportunityStageCollection" formControlName="ddlOpportunityStage"
                  optionLabel="name" styleClass="-ml-2 w-full md:w-14rem border-none surface-50"
                  placeholder="{{'common.PLACEHOLDER.SELECT' | translate}}">
                  <ng-template pTemplate="selectedItem">
                    <div *ngIf="SelectedOpportnityStage"> <strong>{{SelectedOpportnityStage.value.name}}</strong> </div>
                  </ng-template>
                  <ng-template let-opportunityStageCollection pTemplate="item">
                    <div class="text-sm">{{opportunityStageCollection.name}}</div>
                  </ng-template>
                </p-dropdown>
                <app-error-msg *ngIf="SelectedOpportnityStage.touched && SelectedOpportnityStage.invalid"
                  [errors]="SelectedOpportnityStage.errors"
                  [fieldLabel]="'OPPORTUNITY.CREATE.OPPORTUNITY-STAGE' | translate"> </app-error-msg>
              </div>
              <div class="col-6 md:col-4">
                <label
                  class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'OPPORTUNITY.CREATE.CONVERTIBILITY' |
                  translate}}</label>
                <p-dropdown [options]="convertibility" formControlName="ddlConvertibility" optionLabel="name"
                  styleClass="-ml-2 w-full md:w-14rem border-none surface-50"
                  placeholder="{{'common.PLACEHOLDER.SELECT' | translate}}">
                  <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center" *ngIf="SelectedConvertibility"> <span class="mr-2"><img
                          width="20" src="{{SelectedConvertibility.value.icon}}"
                          alt="{{SelectedConvertibility.value.name}}" /></span>
                      <strong>{{SelectedConvertibility.value.name}}</strong>
                    </div>
                  </ng-template>
                  <ng-template let-convertibility pTemplate="item">
                    <div class="flex align-items-center"> <span class="mr-2"><img width="20"
                          class="grayscale opacity-50" src="{{convertibility.icon}}"
                          alt="{{convertibility.name}}" /></span>
                      <div class="text-sm text-gray-600">{{convertibility.name}}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                <app-error-msg *ngIf="this.SelectedConvertibility.touched && this.SelectedConvertibility.invalid"
                  [errors]="SelectedConvertibility.errors"
                  [fieldLabel]="'OPPORTUNITY.CREATE.CONVERTIBILITY' | translate"> </app-error-msg>
              </div>
              <div class="col-6 md:col-4">
                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'OPPORTUNITY.CREATE.ASSIGNED-TO'
                  |
                  translate}}</label>
                <staff-selector [formGroup]="frmgroupCreateOpportunity" [controlName]="SelectedAssigned"
                  [id]=_selectedstaff></staff-selector>
                <app-error-msg *ngIf="(this.SelectedAssigned.touched && this.SelectedAssigned.invalid)"
                  [errors]="SelectedAssigned.errors" [fieldLabel]="'OPPORTUNITY.CREATE.ASSIGNED-TO' | translate">
                </app-error-msg>
              </div>
              <div class="col-6 block md:hidden"></div>
              <div class="col-6 md:col-4">
                <div class="grid">
                  <div class="lg:col-9 col-12">
                    <label
                      class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'OPPORTUNITY.CREATE.OPPORTUNITY-VALUE'
                      | translate}}</label>
                    <div class="p-inputgroup"> <span class="p-inputgroup-addon">{{this.country.currency_symbol}}</span>
                      <p-inputNumber [format]="false" mode="decimal" formControlName="expectedamount"></p-inputNumber>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 md:col-4">
                <div class="grid">
                  <div class="lg:col-9 col-12">
                    <label
                      class="font-medium text-gray-700 text-xs mb-2 block white-space-nowrap">{{'OPPORTUNITY.CREATE.EXPECTED-CLOUSURE-DATE'|
                      translate}}</label>
                    <p-calendar [showIcon]="true" formControlName="expecteddate" (keyup)="$event.preventDefault();">
                    </p-calendar>

                  </div>
                </div>
              </div>
            </div>
            <p-divider styleClass="mt-0"></p-divider>
            <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'OPPORTUNITY.CREATE.INTERESTED-IN' |
              translate}}</label>
            <p-multiSelect [options]="serviceTypeCollection" formControlName="serviceType"
              defaultLabel="{{'OPPORTUNITY.CREATE.SELECT-SERVICE-TYPE' | translate}}" optionLabel="name" display="chip"
              styleClass="w-full text-sm" (onChange)="onChangeServiceTypeCollection($event)"> </p-multiSelect>
            <app-error-msg class="mb-3" *ngIf="this.ServiceType.touched && ServiceType.invalid"
              [errors]="this.ServiceType.errors" [fieldLabel]="'common.errors.validations.REQUIRED' | translate"
              [fieldLabel]="'OPPORTUNITY.CREATE.INTERESTED-IN' | translate"></app-error-msg>
            <label class="font-medium text-gray-700 text-xs mb-2 mt-3 block uppercase">{{'OPPORTUNITY.CREATE.TITLE' |
              translate}}</label>
            <input type="text" pInputText class="w-full" placeholder="" formControlName="title" />
            <app-error-msg *ngIf="this.SelectedTitle.touched && this.SelectedTitle.invalid"
              [errors]="SelectedTitle.errors" [fieldLabel]="'OPPORTUNITY.CREATE.TITLE' | translate"></app-error-msg>
          </div>
          <!-- Content -->

          <!-- Content -->
          <div class="surface-50 px-3 md:px-4 py-3 text-sm border-bottom-1 border-gray-300">
            <textarea pInputTextarea class="w-full text-sm" placeholder="{{'common.PLACEHOLDER.COMMENTS' | translate}}"
              formControlName="comments"></textarea>
          </div>
          <!-- Content -->

          <!-- Modal Footer -->
          <div class="px-3 md:px-4 py-3">
            <div class="flex align-items-center justify-content-between">
              <div class="w-6">
                <p-button label="{{'common.PLACEHOLDER.CANCEL' | translate}}" styleClass="p-button-link text-sm"
                  (click)="setHidePopup()"></p-button>
              </div>
              <div class="w-6 text-right">
                <button *ngIf="!_isEditMode" pButton pRipple label="{{'common.PLACEHOLDER.CREATE' | translate}}"
                  type="submit" class="text-sm"></button>
                <button *ngIf="_isEditMode" pButton pRipple label="{{'common.PLACEHOLDER.UPDATE' | translate}}"
                  type="submit" class="text-sm"></button>
              </div>
            </div>
          </div>
          <!-- Modal Footer -->
        </div>
        <!-- Right Side -->

      </div>
      <app-errors *ngIf="showErrorPopup | async as show_Popup" [visible]="show_Popup" [errorData]="errorMessages"
        (hideErrorPopup)="HideErrorPopup($event)"> </app-errors>
    </form>
  </ng-template>
</p-dialog>
<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>