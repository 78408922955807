import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function IpAddressCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.errors) {
            return control.errors;
        }

        if (checkIpSetting(control.value)) {
            return { specialIpAddressCheck: true };
        } else {
            return null;
        }
    }
};

function checkIpSetting(str: any): boolean {
    let ipAddressControl: string[] = str.split(',');
    const ipRegex = new RegExp("(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)");
    let isIpInValid: boolean = false;
    if (ipAddressControl.length > 0) {
        for (let i = 0; i < ipAddressControl.length; i++) {
            if (!ipRegex.test(ipAddressControl[i])) {
                isIpInValid = true;
                break;
            }
        }
    }
    else {
        isIpInValid = true;
    }

    return isIpInValid;
}
