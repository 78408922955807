import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, BadRequestError, NotFoundError } from '@fitness-force/errors';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  private readonly httpBackend: HttpClient
  constructor(private http: HttpClient, private router: Router,  private readonly backEndClientHandler: HttpBackend  ) {
    this.httpBackend = new HttpClient(backEndClientHandler)
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  fetchAllSMSTemplates(tenantId:number): Observable<any> {

    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID}/${tenantId}${CONST_URL_SEGEMENTS.SMS.SMS_TEMPLATE_MASTER}` , {
      headers: this.httpOptions.headers
    },
   
    ).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        console.log('errrt', error)
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if(error.status === 400){
          return throwError(()=> new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }
  fetchSMSTemplatesById(tenantId:number, smsMasterID:number): Observable<any> {

    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID}/${tenantId}${CONST_URL_SEGEMENTS.SMS.SMS_TEMPLATE_MASTER}/${smsMasterID}` , {
      headers: this.httpOptions.headers
    },
   
    ).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        console.log('errrt', error)
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if(error.status === 400){
          return throwError(()=> new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }
  sendSMSTemplates(clientId:number, smsData:any): Observable<any> {

    return this.http.post(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.SMS.SEND_SMS}`,smsData, {
      headers: this.httpOptions.headers
    },
   
    ).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        console.log('errrt', error)
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if(error.status === 400){
          return throwError(()=> new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }
}
