import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ServiceCategoryService } from '@fitness-force/api';
import { ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';

import { Store } from '@ngrx/store';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { COMPANY_INFO, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';

import { ToastModule } from 'primeng/toast';
import { combineLatest, Observable } from 'rxjs';

import { FactoryHttpLoader } from '../helpsupport/helpsupport.component';

import { ToastComponentModule } from '../toast/toast.component';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';


@Component({
  selector: 'service-category-create',
  templateUrl: './service-category-create.component.html',

  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCategoryCreateComponent implements OnInit {
  @Input() activeCategory:any
  @Output() changed =new EventEmitter();
  @Output() close =new EventEmitter();
  getAccessTenantList$: Observable<any> = new Observable();
  showOptions: boolean = false;
  tenantId: number = 0;

  serverCategoryForm: FormGroup;
  primaryLanguage!: string;
  secondaryLanguage!: string;
  isSecondaryLanguage=false;
  apptCatParams = new Map();

  constructor(
    private store: Store,
    private serviceCategoryService: ServiceCategoryService,
    private messageService: MessageService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);

    combineLatest([
      this.getAccessTenantList$,
      this.store.select(COMPANY_INFO),
    ]).subscribe({
      next: ([tenant, company]: any) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });
        if (company?.compDetails?.is_secondary_language) {
          this.isSecondaryLanguage = true;
        this.translate
          .get([
            'common.LANGUAGE.' +
              tenant.selected_tenant.primary_language?.toUpperCase(),
            'common.LANGUAGE.' +
              tenant.selected_tenant.secondary_language?.toUpperCase(),
          ])
          .subscribe((translations) => {
            this.primaryLanguage =
              translations[
                'common.LANGUAGE.' +
                  tenant.selected_tenant.primary_language?.toUpperCase()
              ];
            this.secondaryLanguage =
              translations[
                'common.LANGUAGE.' +
                  tenant.selected_tenant.secondary_language?.toUpperCase()
              ];
          });
        }
        this.loadCategory();
      }
    }});
  }

  handleError(error: any) {
    if (error instanceof NotFoundError) {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'common.errors.SERVER_SIDE.PAGE_NOT_FOUND',
        ])
        .subscribe((translations: any) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.PAGE_NOT_FOUND'],
          });
        });
    } else if (
      error.originalError &&
      error.originalError.error &&
      error.originalError.error[0]
    ) {
      let currentError = error.originalError.error[0];
      if (currentError.errorCode == 'alreadyexist') {
        if (
          currentError?.identifier &&
          currentError.identifier == 'name_pri_lang'
        )
          this.NamePrimary?.setErrors({
            duplicate: true,
          });
        else if (
          currentError?.identifier &&
          currentError.identifier == 'name_sec_lang'
        )
          this.NameSecondary?.setErrors({
            duplicate: true,
          });
        this.changeDetectorRef.detectChanges();
      }
    }
    {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'common.errors.SERVER_SIDE.SOMETHING_WRONG',
        ])
        .subscribe((translations: any) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.SOMETHING_WRONG'],
          });
        });
    }
  }

  loadCategory() {
    if(this.activeCategory){
      this.serviceCategoryService
      .getSingleServiceCategory(this.tenantId, this.activeCategory.id)
      .subscribe({
        next: (res) => {
          this.activeCategory = res;
          this.buildEditForm();
          this.changeDetectorRef.detectChanges();
        },
        error: (error) => {
          this.handleError(error);
        },
      });
    }else{
      this.buildEditForm();
    }
  }

  get NamePrimary(): AbstractControl | null {
    return this.serverCategoryForm.get('name_primary_language');
  }
  get NameSecondary(): AbstractControl | null {
    return this.serverCategoryForm.get('name_secondary_language');
  }

  get DescriptionPrimary(): AbstractControl | null {
    return this.serverCategoryForm.get('description_primary_language');
  }

  get DescriptionSecondary(): AbstractControl | null {
    return this.serverCategoryForm.get('description_secondary_language');
  }

  buildEditForm() {
    this.serverCategoryForm = this.fb.group({
      name_primary_language: [
        this.activeCategory && this.activeCategory.name_primary_language?this.activeCategory.name_primary_language:"",
        Validators.required,
      ],
      name_secondary_language: [
        this.activeCategory && this.activeCategory.name_secondary_language ?this.activeCategory.name_secondary_language:"",

      ],
      description_primary_language: [
        this.activeCategory && this.activeCategory.description_primary_language?this.activeCategory.description_primary_language:"",
      ],
      description_secondary_language: [
        this.activeCategory && this.activeCategory.description_secondary_language?this.activeCategory.description_secondary_language:"",

      ],
      terms_condition: [
        this.activeCategory && this.activeCategory.terms_condition?this.activeCategory.terms_condition:""
          ,
      ],
      terms_condition_title: [
        this.activeCategory &&   this.activeCategory.terms_condition_title
          ? this.activeCategory.terms_condition_title
          : '',
      ],
      icon: [this.activeCategory && this.activeCategory.icon ? this.activeCategory.icon : ''],
    });
  }
  get Icon(): AbstractControl | null {
    return this.serverCategoryForm.get('icon');
  }
  setIcon(icon: string) {
    this.Icon?.setValue(icon);
  }

  updateServiceCategory() {
    if (this.serverCategoryForm.valid) {
      let data = this.serverCategoryForm.getRawValue();
      if(this.activeCategory){
        this.serviceCategoryService
        .updateServiceCategory(this.tenantId, this.activeCategory.id, data)
        .subscribe({
          next: (res) => {
           // this.activeCategory = null;
           this.changed.emit(res);
            this.translate
              .get([
                'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE',
                'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE_MESSAGE',
              ])
              .subscribe((translations) => {
                this.messageService.add({
                  severity: 'success',
                  summary:
                    translations['ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE'],
                  detail:
                    translations[
                      'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE_MESSAGE'
                    ],
                });
              });
          },
          error: (error) => {
            this.handleError(error);
          },
        });
      }else{
        this.serviceCategoryService.createServiceCategory(this.tenantId,data)
        .subscribe({
          next: (res) => {
            this.activeCategory = null;
            this.changed.emit(res);
            // TODO: Change translation for Create ..
            this.translate
              .get([
                'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE',
                'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE_MESSAGE',
              ])
              .subscribe((translations) => {
                this.messageService.add({
                  severity: 'success',
                  summary:
                    translations['ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE'],
                  detail:
                    translations[
                      'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE_MESSAGE'
                    ],
                });
              });
          },
          error: (error) => {
            this.handleError(error);
          },
        });
      }


      }else{
      Object.keys(this.serverCategoryForm.controls).forEach((field) => {
        const control = this.serverCategoryForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

  categoryCancel(){
    this.changed.emit()
  }
  closeDialog(){
    this.close.emit();
  }
}

@NgModule({
  declarations: [ServiceCategoryCreateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    DialogModule,
    DividerModule,
    InputTextModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
    ToastComponentModule,
    ErrorMsgComponentModule,
    ToastModule,
    InputTextareaModule

  ],
  exports: [ServiceCategoryCreateComponent],
})
export class ServiceCategoryCreateComponentModule {}
