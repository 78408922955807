
<!-- Header -->
<div class="p-3 md:px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300 -mx-3 -mt-3">
  <h2 class="mb-0 text-base md:text-lg font-normal">Confirm</h2>
  <i class="pi pi-times cursor-pointer text-gray-500 ml-3"  (click)="ref.close()"></i>
</div>
<!-- Header -->

<!-- Content -->

<div class="p-4 flex -mx-3">
  <h3>Are you sure you don't  want to save changes?</h3>
</div>

<!-- Content -->


<!-- Modal Footer -->
<div class="p-3 border-top-1 border-gray-300 -mx-3 -mb-3">
  <div class="flex align-items-center justify-content-between">
    <div class="w-6">
      <p-button (click)="close(false)" label="No" styleClass="p-button-link text-sm"></p-button>
    </div>
    <div class="w-6 text-right">
      <button class="text-sm" (click)="close(true)" pButton pRipple label="Yes"></button>
    </div>
  </div>
</div>





