import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { CurrencyformatPipeModule } from 'libs/shared/src/lib/filters/currencyformat.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  @Input() keyName = new BehaviorSubject(null);
  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [CurrencyComponent],
  imports: [
    CommonModule,
    CurrencyformatPipeModule
  ],
  exports: [
    CurrencyComponent
  ]
})
export class CurrencyComponentModule {
}
