export enum ClassCommissionMode{
  NoCommission="No Commission",
  RevenueShare="Revenue Share",
  FlatFees="FlatFees",
}

export enum ClassBehaviour{
  Free="Free",
  Paid="Paid",
  Membership_Paid="Membership + Paid",
  Free_for_Members="Free for members"
}

