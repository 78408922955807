import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateCallOutcomeRequest, CreateTourOutcomeRequest } from '@fitness-force/models';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, ignoreElements } from 'rxjs/operators';
import { environment } from '../../../../../apps/ff-frontend/src/environments/environment';
import { AppErrors, NotFoundError } from '@fitness-force/errors';

@Injectable({
  providedIn: 'root'
})
export class TaskCallOutcomeService {
  BASE_URL: string = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  createTaskCallOutcome(callOutCome: CreateCallOutcomeRequest, tenantId: number, taskId: number) {
    let url: string = this.BASE_URL + CONST_URL_SEGEMENTS.TASKOUTCOME.POST_CALL_OUTCOME.replace('{tenant_id}', tenantId.toString()).replace('{tsk_id}', taskId.toString());

    return this.http.post(url, callOutCome, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  createTourOutcome(tenantId: number, taskId: number,tourOutCome: CreateTourOutcomeRequest, ) {
    let url: string = this.BASE_URL + CONST_URL_SEGEMENTS.TASKOUTCOME.POST_TOUR_OUTCOME.replace('{tenant_id}', tenantId.toString()).replace('{tsk_id}', taskId.toString());

    return this.http.post(url, tourOutCome, this.httpOptions)
      .pipe(
        map((res: any) => {
          return true;
        }),
        catchError((error:any) => {
          console.log(error,'errororor');
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
