import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'opportunity-type',
  templateUrl: './opportunity-type.component.html',
  styleUrls: ['./opportunity-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityTypeComponent implements OnInit {
  @Input() additionData = new BehaviorSubject(null);
  @Input() columnData = new BehaviorSubject(null);
  constructor() { }

  ngOnInit(): void {
  }
    parseOpportunity(info:any, adata:any){

    return adata.find((item:any)=>{
     if(info == item.id) {
       return item
     } else {
       null
     }
    })
  }
}

@NgModule({
  declarations: [OpportunityTypeComponent],
  imports: [
    CommonModule
  ],
  exports: [OpportunityTypeComponent]
})
export class OpportunityTypeComponentModule {
}
