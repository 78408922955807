<div class="p-3 border-left-1 border-gray-300 h-full">
  <h5 class="text-base flex align-items-center">
    <i
      class="
        pi pi-question-circle
        text-xl text-400
        cursor-pointer
        mr-2
      "
    ></i>
    {{ 'ADMIN.RIGHT_SIDE.QUICK_HELP' | translate }}
  </h5>

  <div class="border-top-1 border-bottom-1 border-gray-300 py-2 my-3">
    <a href="#" class="text-primary font-bold"
      >
      {{ 'ADMIN.RIGHT_SIDE.GO_TO_PORTAL' | translate }}</a
    >
  </div>

  <h5 class="text-sm font-bold"> {{ 'ADMIN.RIGHT_SIDE.VIDEO_TITLE' | translate }}</h5>
  <img
    class="w-full mb-3"
    src="/assets/img/basics-of-zoho-crm.jpg"
    alt="Image"
  />
  <p>
    {{ 'ADMIN.RIGHT_SIDE.TOP' | translate }}
    <!-- consequat. Duis aute irure dolor in reprehenderit in voluptate
    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
    occaecat cupidatat non proident, sunt in culpa qui officia
    deserunt mollit anim id est laborum." -->
  </p>
  <p>
    <strong
      >
      {{ 'ADMIN.RIGHT_SIDE.MIDDLE_BOLD' | translate }}

      <!-- Section 1.10.32 of "de Finibus Bonorum et Malorum", written by
      Cicero in 45 BC -->
      </strong
    >
  </p>
  <p>
    {{ 'ADMIN.RIGHT_SIDE.BOTTOM' | translate }}
<!--
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
    accusantium doloremque laudantium, totam rem aperiam, -->
  </p>
</div>
