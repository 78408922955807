import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthSevices } from './auth.service';
import { CyptoSevice } from './crypto.service';
import { TenantService } from './tenant.service';
import { ClientService } from './client/client.service';
// import { TaskCallOutcomeService } from './crm/taskoutcome.service';
import { TaskCallOutcomeService } from './crm/taskoutcome.service';
import { ServiceTypeService } from './serviceType/service-type.service';
import { AdminsettingsService } from './adminsetting.service';
// import {TaskCategoryService} from './crm/task-category.service';


@NgModule({
  imports: [CommonModule],
  providers: [
    AuthSevices,
    CyptoSevice,
    TenantService,
    ClientService,
    // TaskCallOutcomeService,
    ServiceTypeService,
    AdminsettingsService,
    DatePipe,
    // TaskCategoryService
  ]
})
export class ApiModule { }
