export interface Country {
    id: number;
    name: string;
    dial_code: string;
    two_letter_code: string;
    three_letter_code: string;
    currency_code: string;
    currency_name: string;
    currency_symbol: string;
    iso_numeric: number;
    miner_currency: number;
};

export interface Countries {
    country: Country
    countries: Array<Country>
};