import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener, NgModule } from '@angular/core';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
    @Input() spiedTags = [];
    @Output() sectionChange :EventEmitter<string> = new EventEmitter();
   
    constructor(private _el: ElementRef) {}
    private currentSection: string="";

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        const children = this._el.nativeElement.children;
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop;

        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                if ((element.offsetTop - parentOffset) <= scrollTop) {
                    this.currentSection = element.id;
                }
            }
        }
        this.sectionChange.emit(this.currentSection);
    }

}

@NgModule(
    {
      declarations: [ScrollSpyDirective],
      exports:[ScrollSpyDirective]
    }
  )
  
  export class ScrollSpyDirectiveModule {}
