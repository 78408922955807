import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';

@Component({
  selector: 'hotwarmcold',
  templateUrl: './hotwarmcold.component.html',
  styleUrls: ['./hotwarmcold.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotwarmcoldComponent implements OnInit {
  @Input('status') status:string = ''
  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [HotwarmcoldComponent],
  imports: [
    CommonModule
  ],
  exports: [HotwarmcoldComponent]
})
export class HotwarmcoldComponentModule {
}
