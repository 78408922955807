export enum ValidationSeverity {
    Error = 0,
    Warning = 1,
    Info = 2
}

export interface PagedResults<T> {
    items: T[];
    total_items: number;
    total_pages: number;
    current_page: number;
  }

export interface CreateResponse {
    id: any;
    info?: ValidationError[];
}
 
export interface ValidationError {
    severity?: ValidationSeverity;
    errorCode: string;
    errorMessage: string;
    identifier: string;
    helptext?: string;
}