    <!-- Card -->
    <div class="bg-white border-1 border-gray-300 border-round shadow-1 mt-3">

      <div class="py-2 md:py-3 text-center">

        <button pButton pRipple type="button" icon="pi pi-plus" [routerLink]="['/admin/create-appointment-category']" label="Create your own Appointment"
          class="p-button-outlined mt-2 md:mt-4">
        </button>

        <p-divider align="center" styleClass="my-3 md:my-5"><b>OR</b></p-divider>

        <div class="px-2 md:px-4">
          <h3 class="text-xl font-bold">Auto create a Appointment Type</h3>
          <p class="text-gray-600">You can edit the appointment to change / tweak settings after defining.</p>

          <p-carousel [value]="classes" [numVisible]="4" [numScroll]="1" [showNavigators]="false"
            [responsiveOptions]="responsiveOptions">
            <ng-template let-class pTemplate="item">

              <div *ngIf="class.successfully_added; else elseBlock">
                <div class="border-1 border-{{class.color}} border-round px-3 pt-3 m-3 text-left">
                  <div class="-mx-3 -mt-3 mb-3 px-3 py-2 bg-green-50 text-green-400 text-sm"><i
                      class="pi pi-check mr-2"></i>Successfully added!</div>

                  <!-- Header -->
                  <div class="border-round border-1 border-{{class.color}} {{class.bg_color}} text-{{class.color}} text-lg font-bold  py-2 px-3 -mx-2 -mt-2">  {{class.title}}</div>
                  <!-- Header -->

                  <div class="flex align-items-center text-sm my-2">
                      <i class="{{class.behaviour_icon}} mr-2 text-gray-600"></i>
                      <span>{{class.behaviour}}</span>
                    </div>

                    <!-- Note -->
                    <div class="mb-2 text-xs text-gray-500">{{class.note}}</div>
                    <!-- Note -->

                      <h5 class="text-sm font-medium mb-2">Service Category <sup class="p-error">*</sup></h5>
                      <p class="text-sm text-gray-600 mb-0">P.T. 12 Sessions</p>
                      <h5 class="text-sm font-medium mb-2 mt-2">Drop-in Plan <sup class="p-error">*</sup></h5>
                      <p class="text-sm text-gray-600 mb-0">Yes</p>

                  <button pButton pRipple type="button" label="Go to Appointment List page"
                    class="p-button-outlined text-sm w-full mt-2"></button>

                  <button pButton pRipple type="button" label="Edit this Appointment"
                    class="p-button-text text-sm w-full"></button>

                </div>
              </div>
              <ng-template #elseBlock>
                <div class="shadow-2 border-round p-3 m-3 text-left">

                  <!-- Header -->
                  <div class="border-round border-1 border-{{class.color}} {{class.bg_color}} text-{{class.color}} text-lg font-bold  py-2 px-3 -mx-2 -mt-2">
                      {{class.title}}</div>
                  <!-- Header -->

                  <div class="flex align-items-center text-sm my-2">
                  <i class="{{class.behaviour_icon}} mr-2 text-gray-600"></i>
                  <span>{{class.behaviour}}</span>
                </div>

                <!-- Note -->
                <div class="mb-2 text-xs text-gray-500">{{class.note}}</div>
                <!-- Note -->

                <div *ngIf="class.title=='P.T. Trail'; else freeAppointment">
                  <ul class="mt-2 list-none p-0 ml-0 mb-4 text-gray-700 text-xs">
                    <li class="flex mt-3">
                      <i class="pi pi-check mr-2"></i>
                      <span>Free for all members</span>
                    </li>
                    <li class="flex mt-3">
                      <i class="pi pi-check mr-2"></i>
                      <span>Included with the membership</span>
                    </li>
                    <li class="flex mt-3">
                      <i class="pi pi-check mr-2"></i>
                      <span>Lorem ipsum</span>
                    </li>
                    <li class="flex mt-3">
                      <i class="pi pi-check mr-2"></i>
                      <span>Dummy text</span>
                    </li>
                  </ul>
                </div>

                <ng-template #freeAppointment>
                  <h5 class="text-sm font-medium mb-2">Service Category <sup class="p-error">*</sup></h5>
                  <p-dropdown [options]="serviceCategories" [(ngModel)]="selectedServiceCategory" optionLabel="name"
                    filterBy="name" styleClass="w-full text-sm" placeholder="Select Service Category..."
                    scrollHeight="130px">
                    <ng-template pTemplate="selectedItem">
                      <div class="text-sm" *ngIf="selectedServiceCategory">{{selectedServiceCategory.name}}</div>
                    </ng-template>
                    <ng-template let-serviceCategory pTemplate="item">
                      <div class="text-sm">{{serviceCategory.name}}</div>
                    </ng-template>
                  </p-dropdown>
                  <h5 class="text-sm font-medium mt-3 mb-2">Drop-in Plan <sup class="p-error">*</sup></h5>
                  <p-dropdown [options]="dropInPlans" [(ngModel)]="selectedDropInPlan" optionLabel="name"
                    filterBy="name" styleClass="w-full text-sm" placeholder="Select Drop-in Plan..."
                    scrollHeight="80px">
                    <ng-template pTemplate="selectedItem">
                      <div class="text-sm" *ngIf="selectedDropInPlan">{{selectedDropInPlan.name}}</div>
                    </ng-template>
                    <ng-template let-dropInPlan pTemplate="item">
                      <div class="text-sm">{{dropInPlan.name}}</div>
                    </ng-template>
                  </p-dropdown>
                  </ng-template>



                  <button pButton pRipple type="button" label="Add {{class.title}}" class="text-sm w-full mt-3">
                  </button>

                </div>
              </ng-template>


            </ng-template>
          </p-carousel>

        </div>

      </div>

    </div>
    <!-- Card -->
