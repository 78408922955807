import { AppErrors } from "./app.errors";

export class NotFoundError extends AppErrors {
}

export class FluentValidationError extends AppErrors {
}


export class ConflictError extends AppErrors {
  constructor(public originalError?: any){
    super(originalError);
  }


}
