import { CompanyInfoStoreInterface, Country, TENANTSINTERFACE } from "@fitness-force/models";
import { Action, ActionReducer, createReducer, on } from "@ngrx/store";

import {
  DIRECTION_DETAILS, ERROR_COMPANY_DETAILS, FETCHED_COMPANY_DETAILS, FETCHED_TENANT_DETAILS,
  FETCHED_TENANT_LIST, FETCH_STAFF_LIST, FETCH_STAFF_LIST_ALL, FETCH_STAGE_MASTER, SELECT_LANGUAGE,
  SET_CLAIMS_DETAILS, FETCH_COUNTRY_MASTER_LIST, FETCH_COUNTRY_MASTER, FETCH_CITY_MASTER_LIST,
  FETCH_CITY_MASTER, FETCH_SERVICETYPE_LIST, FETCH_APPOINTMENT_TYPE_LIST, FETCH_CLASS_TYPE_LIST, FETCH_ROOM_LIST, FETCH_CURRENT_LOCATION
} from "./actions";
import { ActionTypes } from "./actions/clearstate.action";
import { FETCH_REPORT_LIST } from "./actions/reports.action";
import { FETCH_STATE_MASTER, FETCH_STATE_MASTER_LIST } from "./actions/state.action";
import { REPORT_STATE } from "./app.state";
import {
  CLAIMS_STATE, COUNTRY_MASTER_STATE, INITIAL_COMPANY_STATE, STAFF_STATE,
  STAGE_MASTER_STATE, TENANT_STATE, STATE_MASTER_STATE, CITY_MASTER_STATE, SERVICETYPE_STATE,
  APPOINTMENT_TYPE_STATE, CLASS_TYPE_STATE, ROOM_STATE, LOCATION_STATE
} from "./store";

export const companyReducer = createReducer(INITIAL_COMPANY_STATE,
  on(FETCHED_COMPANY_DETAILS, (state, action): CompanyInfoStoreInterface => {
    return {
      ...state,
      compDetails: action.companyDetails
    };
  }),
  on(ERROR_COMPANY_DETAILS, (state, action): CompanyInfoStoreInterface => {
    return {
      ...state,
      err: action.err
    };
  }),
  on(SELECT_LANGUAGE, (state, action): CompanyInfoStoreInterface => {
    return {
      ...state,
      selectedLang: action.lang
    };
  }),
  on(DIRECTION_DETAILS, (state, action): CompanyInfoStoreInterface => {
    return {
      ...state,
      direction: action.dir
    };
  }),
)

export const claimsReducer = createReducer(CLAIMS_STATE,
  on(SET_CLAIMS_DETAILS, (state, action): any | null => {
    return {
      ...state,
      claims: action.claims
    };
  })
)

export const tenantReducer = createReducer(TENANT_STATE,
  on(FETCHED_TENANT_LIST, (state, action): TENANTSINTERFACE => {
    return {
      ...state,
      accessed_tenant: action.accessed_tenant
    };
  }),
  on(FETCHED_TENANT_DETAILS, (state, action): TENANTSINTERFACE => {
    return {
      ...state,
      selected_tenant: action.selected_tenant
    };
  })
)

export const staffReducer = createReducer(STAFF_STATE,
  on(FETCH_STAFF_LIST, (state, action): any => {
    return {
      ...state,
      staffList: action.staffList
    };
  }),
  on(FETCH_STAFF_LIST_ALL, (state, action): any => {
    return {
      ...state,
      staffListAll: action.staffListAll
    };
  })
)

export const reportReducer = createReducer(REPORT_STATE,
  on(FETCH_REPORT_LIST, (state, action): any => {
    return {
      ...state,
      reportList: action.reportList
    };
  })
)

export const stageReducer = createReducer(STAGE_MASTER_STATE,
  on(FETCH_STAGE_MASTER, (state, action): any => {
    return {
      ...state,
      stages: action.stages
    };
  })
)

export const countryListReducer = createReducer(COUNTRY_MASTER_STATE,
  on(FETCH_COUNTRY_MASTER_LIST, (state, action): any => {
    return {
      ...state,
      countries: action.countries
    };
  }),
  on(FETCH_COUNTRY_MASTER, (state, action): any => {
    return {
      ...state,
      country: action.country
    };
  })
)

export const appointmentTypeReducer = createReducer(APPOINTMENT_TYPE_STATE,
  on(FETCH_APPOINTMENT_TYPE_LIST, (state, action): any => {
    return {
      ...state,
      appointmentTypes: action.appointmentTypes
    };
  })
)

export const stateListReducer = createReducer(STATE_MASTER_STATE,
  on(FETCH_STATE_MASTER_LIST, (state, action): any => {
    return {
      ...state,
      states: action.states
    };
  }),
  on(FETCH_STATE_MASTER, (state, action): any => {
    return {
      ...state,
      state: action.state
    };
  })
)

export const classTypeReducer = createReducer(CLASS_TYPE_STATE,
  on(FETCH_CLASS_TYPE_LIST, (state, action): any => {
    return {
      ...state,
      classTypes: action.classTypes
    };
  })
)

export const cityListReducer = createReducer(CITY_MASTER_STATE,
  on(FETCH_CITY_MASTER_LIST, (state, action): any => {
    return {
      ...state,
      cities: action.cities
    };
  }),
  on(FETCH_CITY_MASTER, (state, action): any => {
    return {
      ...state,
      city: action.city
    };
  })
)


export const serviceTypeReducer = createReducer(SERVICETYPE_STATE,
  on(FETCH_SERVICETYPE_LIST, (state, action): any => {
    return {
      ...state,
      serviceTypeList: action.serviceTypeList
    };
  })
)

export const roomReducer = createReducer(ROOM_STATE,
  on(FETCH_ROOM_LIST, (state, action): any => {
    return {
      ...state,
      rooms: action.rooms
    };
  })
)

export const locationReducer = createReducer(LOCATION_STATE,
  on(FETCH_CURRENT_LOCATION, (state, action): any => {
    return {
      ...state,
      location: action.location
    };
  })
)

export function clearState<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state, action) {
    if (action.type === ActionTypes.LOGOUT)
      state = undefined;

    return reducer(state, action);
  };
}