<p-dialog [(visible)]="changeRepresentativeModal" [resizable]="false" appendTo="body" [modal]="true" [closeOnEscape]="true" (onHide)="closeDialogEvent()" [breakpoints]="{'767px': '100vw'}" [style]="{width: '660px'}" styleClass="no-header-modal overflow-visible">
  
  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">

    <ng-container *ngIf="{
      client: allClientData$ | async,
      staff: currentStaff$ | async,
      allStaff: allStaffs$ | async,
      selectedStaff: selectedStaff$ | async
    } as $">

    <!-- Header -->
    <div class="h-3rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">

      <div class="flex align-items-center">
        <h2 class="mb-0 text-base md:text-lg font-normal">{{'SALE_REPRESENTATIVE.CHANGE_SALES_REP' | translate}} <strong>{{$.client.full_name}}</strong>
          <span class="text-xs text-gray-600"> - {{$.client.id}}</span></h2>
      </div>
      <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mr-4 cursor-pointer"
        (click)="closeDialogEvent()"></div>
    </div>
    <!-- Header -->

    <form [formGroup]="updateClientRepFormGroup" (ngSubmit)="updateClientRepresentative()">

    <!-- Modal Content -->
    <div class="surface-100 border-bottom-1 border-gray-300 p-3 md:px-5">

      <!-- Column -->
      <div class="mb-4">
        <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SALE_REPRESENTATIVE.CURRENT_SALES_REP' | translate}}</label>

        <div class="flex align-items-center">
          <span *ngIf="$.staff.ff_picture; else noContactPic">
            <p-avatar image="{{$.staff.ff_picture}}" class="w-2rem h-2rem border-circle mr-2" shape="circle"></p-avatar>
          </span>
          <ng-template #noContactPic>
            <p-avatar styleClass="mr-2" *ngIf="$.staff.name" label="{{$.staff.name | titlecase | shortName}}" shape="circle"></p-avatar>
          </ng-template>
          <span class="text-sm">{{$.staff.name}}</span>
        </div>
        </div>
      <!-- Column -->

      <!-- Column -->
      <div class="">
        <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SALE_REPRESENTATIVE.NEW_SALES_REP' | translate}}</label>
        <!-- <div class="relative"> -->
          <staff-selector [formGroup]="updateClientRepFormGroup" [controlName]="NewRepresentative"
           [canRepresetative]="isClientRepresentative" [claims]="claims" [styleClass]="'w-full text-sm py-0 align-items-center'">
          </staff-selector>
        <!-- </div> -->
      </div>
      <!-- Column -->

    </div>
    <!-- Modal Content -->

    <!-- Modal Footer -->
    <div class="flex align-items-center justify-content-between border-top-1 border-gray-300 p-3">
      <div class="w-4">
        <p-button type="button" [label]="'SALE_REPRESENTATIVE.CANCEL' | translate" styleClass="p-button-link p-0 text-sm" (click)="closeDialogEvent()">
        </p-button>
      </div>
      <div class="w-8 text-right">
        <button type="submit" class="text-sm" [disabled]="!updateClientRepFormGroup.valid"  pButton pRipple [label]="'SALE_REPRESENTATIVE.UPDATE_SALES_REP' | translate"></button>
      </div>
    </div>
    <!--Modal Footer -->

  </form>

    <!-- Modal Content -->
    <!-- <div class="bg-white px-3 py-4 md:px-5">

      <h5 class="text-sm md:text-base font-normal">Sales Rep Change History</h5>

      <p-table [value]="tableData" styleClass="shadow-2 p-0" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr class="text-xs font-bold">
            <th class="text-gray-600 white-space-nowrap">DATE & TIME</th>
            <th class="text-gray-600 white-space-nowrap">CREATED BY</th>
            <th class="text-gray-600 white-space-nowrap">BILL OWNER</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tableData>
          <tr class="text-sm">
            <td class="py-2 border-bottom-1 border-gray-300 white-space-nowrap">16 May 2021
              <span class="ml-3">12:30 PM</span></td>
            <td class="py-3 border-bottom-1 border-gray-300 white-space-nowrap">
              <button class="p-button-link p-0 text-sm" pButton label="John Dew"
                (click)="createdBy.toggle($event)"></button>
              <p-overlayPanel #createdBy>
                <ng-template pTemplate>
                  <div class="flex">
                    <p-avatar label="JD" styleClass="p-mr-2 font-bold text-lg text-primary" shape="circle"
                      size="large">
                    </p-avatar>
                    <div class="ml-3">
                      <h6 class="text-base mb-1 font-medium">John Dew</h6>
                      <ul class="list-none p-0 m-0 text-gray-600 text-sm">
                        <li class="mt-1">Department - ADMIN</li>
                        <li class="mt-1">Mobile - 98990 00963</li>
                        <li class="mt-1">Email - <a class="no-underline text-primary"
                            href="mailto:surabhi@surabhirout.com">surabhi@surabhirout.com</a></li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>

            </td>
            <td class="py-3 border-bottom-1 border-gray-300 white-space-nowrap">
              <button class="p-button-link p-0 text-sm" pButton label="John Dew"
                (click)="billOwner.toggle($event)"></button>
              <p-overlayPanel #billOwner>
                <ng-template pTemplate>
                  <div class="flex">
                    <p-avatar label="JD" styleClass="p-mr-2 font-bold text-lg text-primary" shape="circle"
                      size="large">
                    </p-avatar>
                    <div class="ml-3">
                      <h6 class="text-base mb-1 font-medium">John Dew</h6>
                      <ul class="list-none p-0 m-0 text-gray-600 text-sm">
                        <li class="mt-1">Department - ADMIN</li>
                        <li class="mt-1">Mobile - 98990 00963</li>
                        <li class="mt-1">Email - <a class="no-underline text-primary"
                            href="mailto:surabhi@surabhirout.com">surabhi@surabhirout.com</a></li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>
      </p-table>


      <h5 class="text-sm md:text-base font-normal mt-4">Sales Rep Change History</h5>

      <p-table [value]="tableData" [scrollable]="true" crollHeight="400px" scrollDirection="both">
        <ng-template pTemplate="header">
          <tr class="text-xs">
            <th class="text-gray-600 bg-gray-100 font-bold border-none w-4 py-2">DATE & TIME</th>
            <th class="text-gray-600 bg-gray-100 font-bold border-none w-4 py-2">CREATED BY</th>
            <th class="text-gray-600 bg-gray-100 font-bold border-none w-4 py-2">BILL OWNER</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tableData>
          <tr class="text-sm">
            <td colspan="3"
              class="py-0 justify-content-center flex-wrap font-bold border-bottom-1 border-gray-300 white-space-nowrap w-full">
              <div class="my-3 w-full text-center">No records!</div>
              <img width="220" src="/assets/img/cat.svg" alt="No Records!" />
            </td>
        </ng-template>
      </p-table>

    </div> -->
    <!-- Modal Content -->

  </ng-container>
    


  </ng-template>

</p-dialog>
<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>