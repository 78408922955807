export const CONSTANTS_SCHEDULE = {
    CLASS_APPOINTMENT_BEHAVIOUR: [
      { name: 'Free', value: 'Free', icon: 'pi pi-wallet' },
      { name: 'Paid', value: 'Paid', icon: 'pi pi-dollar' },
      { name: 'Membership + Paid', value: 'Membership_Paid', icon: 'pi pi-dollar' },
      { name: 'Free For Members', value: 'Free_for_Members', icon: 'pi pi-dollar' },
    ] as Behaviour[],
}

interface Behaviour {
    name: string;
    value: string;
    icon: string;
  }
  