import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CONST_URL_SEGEMENTS } from "@fitness-force/constants";
import { AppErrors, ConflictError, NotFoundError } from "@fitness-force/errors";
import { CreateOpportunityRequest, UpdateOpportunityRequest } from "libs/models/src/lib/crm/opportunity";
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { throwError,  Observable, pipe } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { OpportunityBoardType } from "@fitness-force/enum";
import { CreateResponse } from "@fitness-force/models";




@Injectable({
  providedIn: 'root'
})
export class OpportunityService {
  constructor(private http: HttpClient, private router: Router) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
    }

  //tenants/{tenant_id:int}/boards/{board_id:int}/opportunities
  OpportunityCreateService(tenantId: number, boardType: OpportunityBoardType, createOpportunityRequest: CreateOpportunityRequest) {

    return this.http.post(`${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}`,createOpportunityRequest, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {

          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          else if (error.status === 409) {
            return throwError(() => new ConflictError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  OpportunityUpdateService(tenantId: number, boardType: OpportunityBoardType,oppId: number, updateOpportunityRequest: UpdateOpportunityRequest, ): Observable<CreateResponse> {
    return this.http.put(`${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${oppId}`
      , updateOpportunityRequest,this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  //tenants/{tenant_id:int}/boards/{board_id:int}/opportunities
  OpportunityGetService(tenantId: number, boardType: OpportunityBoardType, oppId: number): Observable<Response> {
    return this.http.get(
      `${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}/${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${oppId}`, { headers: this.httpOptions.headers }
    )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  OpportunityDeleteService(tenantId: number, boardType:any, oppId: number) {
    return this.http.delete(
      `${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}/${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${oppId}`, { headers: this.httpOptions.headers }
    )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          else {
            return throwError(() => new AppErrors(error));
          }
        })
      );
  }

  OpportunityStageChange(tenantId: number, boardType: OpportunityBoardType, oppId: number, stageMstId: number) {
    return this.http.post(
      `${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}/${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${oppId}`, { headers: this.httpOptions.headers }
    ).pipe(
      map((res: any) => {
        return res;
      })
      , catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        else {
          return throwError(() => new AppErrors(error));
        }
      })
    );
  }

  OpportunityMarkAsLost(tenantId: number, boardType: OpportunityBoardType, oppId: number, lostDealData:any) {
    return this.http.post(
      `${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${oppId}/mark_as_lost`,lostDealData, this.httpOptions
    ).pipe(
      map((res: any) => {
        return res;
      })
      , catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        else {
          return throwError(() => new AppErrors(error));
        }
      })
    );
  }


  OpportunityMarkAsWon(tenantId: number, boardType: OpportunityBoardType, oppId: number) {
    return this.http.post(
      `${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${oppId}/mark_as_won`, { headers: this.httpOptions.headers }
    ).pipe(
      map((res: any) => {
        return res;
      })
      , catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        else {
          return throwError(() => new AppErrors(error));
        }
      })
    );
  }

  OpportunityMarkAsNurture(tenantId: number, boardType: OpportunityBoardType, oppId: number) {
    return this.http.post(
      `${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}/${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${oppId}/mark_as_nurture`, { headers: this.httpOptions.headers }
    ).pipe(
      map((res: any) => {
        return res;
      }), catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        else {
          return throwError(() => new AppErrors(error));
        }
      })
    );
  }

  getOpportunityService(tenantId: number, boardType: OpportunityBoardType,allParams:any): Observable<Response> {
    let parsedParams = new HttpParams()
    allParams.forEach((p: any) => {
      parsedParams = parsedParams.append(p.name, p.value);
    });
    return this.http.get(`${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}`, {
      headers: this.httpOptions.headers, params: parsedParams
    })
      .pipe(
        map((res: any) => {
          return res.items;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  OpportunityChangeStageService(tenantId: number, boardType: OpportunityBoardType, opp_id: number, stg_master_id:number): Observable<Response> {

    return this.http.post(`${environment.BASE_URL}/tenants/${tenantId}/boards/${boardType}${CONST_URL_SEGEMENTS.OPPORTUNITY.OPPORTUNITY}/${opp_id}/change_stage/${stg_master_id}`, {})
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
