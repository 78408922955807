// import { WaiverVariables } from "./waiverVariables";

import { WaiverVariablesType } from "@fitness-force/enum";

export interface CreateClientWaiversRequest {
    waiver_master_id: number;
    waiver_payload: WaiverVariables[];
}

export interface WaiverVariables {
    type: WaiverVariablesType | string;
    key: string;
    value: any;
}