export interface City {
    id: number;
    name: string;
    code: string;
    state_code: string;
    state_id: number;
}

export interface Cities {
    city: City;
    cities: Array<City>;
};