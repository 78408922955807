import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgent {
  agent = navigator.userAgent;
  browser = '';
  browserVersion = '';
  constructor() {}
  get uAgent() {
    // console.log('agent', this.agent.split('Chrome/')[1]);
    if (this.agent.match(/chrome|chromium|crios/i)) {
      this.browser = 'Google Chrome';
    } else if (this.agent.match(/firefox|fxios/i)) {
      this.browser = 'Firefox';
    } else if (this.agent.match(/safari/i)) {
      this.browser = 'safari';
    } else if (this.agent.match(/opr\//i)) {
      this.browser = 'opera';
    } else if (this.agent.match(/edg/i)) {
      this.browser = 'edge';
    } else {
      this.browser = 'No browser detection';
    }

    return { browser: this.browser };
  }
}
