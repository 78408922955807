import { Store,select } from '@ngrx/store';
import { BehaviorSubject, map, mergeMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  ViewChild,
} from '@angular/core';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { StyleClassModule } from 'primeng/styleclass';
import {
  ShortNamePipeModule,
} from 'libs/shared/src/lib/filters/name-initials.pipe';
import { STAFF_STATE } from 'apps/ff-frontend/src/app/state/app.state';

@Component({
  selector: 'staff-info',
  templateUrl: './staff-info.component.html',
  styleUrls: ['./staff-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffInfoComponent implements OnInit {
  @Input() staffId:number=0;
  @Input() columnData:any = ''
  @Input() additionData:any
  @ViewChild('op') op: OverlayPanel;
  staffInfo:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private store:Store,
  ) {}

  ngOnInit(): void {
    this.columnData.subscribe({next:(cData:any)=>{
      if(!this.staffId)this.staffId = cData.updated_by?cData.updated_by:cData.assigned_to_staff_id;
      this.store.pipe(
        select(STAFF_STATE),
        map((loaded) => {
          return loaded.staffListAll.filter((value) => value.id === this.staffId);
        }),
        mergeMap((value) => value)
      ).subscribe({
        next: (staff) => {
          this.staffInfo.next(staff);
        },
      });
    }});

  }
  toggleOverlay = (event:any) => this.op.toggle(event);
  show = (event:any) => this.op.show(event);
  hide = () => this.op.hide();
}
@NgModule({
  declarations: [StaffInfoComponent],
  imports: [
    CommonModule,
    AvatarModule,
    DropdownModule,
    StyleClassModule,
    ShortNamePipeModule,
    OverlayPanelModule,
  ],
  exports: [StaffInfoComponent],
})
export class StaffInfoComponentModule {}
