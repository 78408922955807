<ng-container *ngIf="{
  row: columnData | async
} as $">
<div class="flex align-items-center">
    <div class="w-4rem">
      <p-tag *ngIf="$.row.status.toLowerCase() == 'due'" styleClass="text-xxs text-white uppercase font-normal"
        severity="warning">{{$.row.inv_status}}</p-tag>
      <p-tag *ngIf="$.row.status.toLowerCase() == 'overdue'" severity="danger"
        styleClass="text-xxs font-normal uppercase white-space-nowrap">{{$.row.status}}</p-tag>
      <p-tag *ngIf="$.row.status.toLowerCase() == 'paid'" severity="success"
        styleClass="text-xxs font-normal uppercase white-space-nowrap">{{$.row.status}}</p-tag>
      <p-tag *ngIf="$.row.status.toLowerCase() == 'open'" severity="danger"
      styleClass="text-xxs font-normal uppercase white-space-nowrap">{{$.row.status}}</p-tag>
      <p-tag *ngIf="$.row.status.toLowerCase() == 'closed'" severity="success"
      styleClass="text-xxs font-normal uppercase white-space-nowrap">{{$.row.status}}</p-tag>
      <p-tag *ngIf="$.row.status.toLowerCase() == 'void'" severity="success"
      styleClass="text-xxs font-normal uppercase white-space-nowrap">{{$.row.status}}</p-tag>
    </div>
  </div>
  </ng-container>
