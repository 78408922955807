import {Component,OnInit,ChangeDetectionStrategy,NgModule,Input,Output,EventEmitter,ViewChild,} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';


@Component({
  selector: 'app-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDatepickerComponent implements OnInit {
  @Input('visible') showCalendar: boolean = false;
  @Input() form!: FormGroup;
  @Input() dateRange: any;

  @Output() selectedDate = new EventEmitter<any>();
  @Output() selectedForm = new EventEmitter<any>();
  @Output("hideCalender") hideCalenderPopup = new EventEmitter<boolean>();

  timePeriodDate: Date[] | any;
  
  constructor() {}

  ngOnInit(): void {
    this.timePeriodDate = this.dateRange;
  }


  @ViewChild('calendar') private calendar: any;
  onDateSelect() {
    if (this.timePeriodDate && this.timePeriodDate[1]) {
      setTimeout(()=>{        
        this.selectedDate.emit({dateObject:this.timePeriodDate,form:this.form});
        this.showCalendar= false;
        this.hideCalenderPopup.emit(this.showCalendar)
       }, 1000);
    }
  }

  SetDate()
  {
    if (this.timePeriodDate && this.timePeriodDate[1]) {
      this.selectedDate.emit({dateObject:this.timePeriodDate,form:this.form});
      this.showCalendar= false;
      this.hideCalenderPopup.emit(this.showCalendar)
    }
  }

  ClosePopup()
  {
    this.showCalendar= false;
    this.hideCalenderPopup.emit(this.showCalendar);
  }
}

@NgModule({
  declarations: [FormDatepickerComponent],
  imports: [ReactiveFormsModule, FormsModule, BrowserModule, DialogModule, CalendarModule],
  exports: [FormDatepickerComponent],
})
export class FormDatepickerComponentModule {}
