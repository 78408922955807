import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMsgComponentModule } from '@fitness-force/errors';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'client-profile-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit {
  @Input() changePasswordModal: boolean | null = false;
  @Output() closechangePasswordDialog =  new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    PasswordModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),
    ErrorMsgComponentModule,
    ToastModule
  ],
  exports: [ChangePasswordComponent]
})
export class ChangePasswordComponentModule {
}
