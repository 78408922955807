import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RaiseSalesReturnRequest, voidInvoiceRequest } from '@fitness-force/models';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppErrors, BadRequestError, NotFoundError } from '@fitness-force/errors';
import { environment } from 'apps/ff-frontend/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  getClientInvoices(clientId: any): Observable<any> {
    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.INVOICES}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getInvoicePDF(clientId: number, invoiceId:number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      })
    };
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.INVOICES.GET_INVOICE_PDF.replace('{client_id}', clientId.toString()).replace('{invoice_id}', invoiceId.toString());
    return this.http.get(url, { responseType: 'text',headers: httpOptions.headers}).pipe(
      map((res: any) => {
          return res
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  voidInvoice(tenantId:number,clientId: number, invoiceId:number, voidRequest: voidInvoiceRequest): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.INVOICES.VOID_INVOICE.replace('{tenant_id}', tenantId.toString()).replace('{client_id}', clientId.toString()).replace('{invoice_id}', invoiceId.toString());
    return this.http.post(url, voidRequest, {headers: this.httpOptions.headers}).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  SendEmail(clientId: number, invoiceId:number): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.INVOICES.SEND_EMAIL.replace('{client_id}', clientId.toString()).replace('{invoice_id}', invoiceId.toString());
    return this.http.get(url, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  SalesReturn(tenantId:number,clientId: number, invoiceId:number, raiseSalesReturnRequest:RaiseSalesReturnRequest): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.INVOICES.SALES_RETURN.replace('{tenant_id}', tenantId.toString()).replace('{client_id}', clientId.toString()).replace('{invoice_id}', invoiceId.toString());
    return this.http.get(url, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

}
