import { RelativeDate } from "./reportaccessrequest";
import { ReportFormBase } from "./reportdata";
import { ClientReportList } from "./reportsresponse";


export enum ConstFilterIds{
    defaultFilterId =1000,
    newFilterId =999
  }
export interface Report {
    report_key: string;
    favourite:boolean;
    report_description:string;
    claims:any[];
}

export interface ReportListResponse {
   category: string;
   icon:string;
   reports: Report[]
}

export interface REPORTSTOREINTERFACE{
    report_key:string;
    claims:any[],
    payload:string,
    default_payload:string,
    default_relative_Date:string,
    mandatory_fields:any
}

export interface payload{
    from_date:string;
    to_date:string;
    plan_id:number;
    time_zone:string;
    current_page:number;
    page_size: number;
}

export interface ReportResponse {
    current_page: number;
    items:ClientReportList[] | any[];
    statistics: ClientReportList[] | any[];
    total_items:number;
    total_pages:number
 }



