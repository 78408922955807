import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { ListRoomResponse } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private http: HttpClient, private route: Router) {}
  BASE_URL: string = environment.BASE_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  GetRoomList(tenantId:number): Observable<ListRoomResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.ROOM.ROOM_LIST.replace('{tenant_id}', tenantId.toString());
    return this.http.get(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

}
