import { BehaviorSubject } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';
import { LostDealReasonComponentModule } from './utility/lost-deal-reason/lost-deal-reason.component';
import { ClientService } from '@fitness-force/api';

@Component({
  selector: 'app-lost-deal',
  templateUrl: './lost-deal.component.html',
  styleUrls: ['./lost-deal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LostDealComponent implements OnInit {
  @Input() showDailog: boolean = false
  @Input() lostDealInfo: any
  @Output() closeMainDailog: any = new EventEmitter()
  @Output() reload: any = new EventEmitter()

  showLostToCompetitors: boolean = false;
  showLostToPrice: boolean = false;
  showLostToOtherReasons: boolean = false;
  showLostNotContactable: boolean = false;
  showLosttoMissingFacility: boolean = false;
  lostDealReason: any = new BehaviorSubject({ title: '', name: '' })
  togglelostReason: boolean = false;
  constructor(private clientService: ClientService) { }

  ngOnInit(): void {
  }

  showReasonDailog(reason: string, name: string) {
    this.togglelostReason = true
    this.lostDealReason.next({ title: reason, name: name })
  }
  closeReasonDialog() {
    this.togglelostReason = false
    this.lostDealReason.next({ title: '', name: '' })
  }
  closeAllDialogs() {
    console.log('0000000000')
    this.closeReasonDialog()
    this.closeMainDailog.emit(true)
  }
  reloadData() {
    this.reload.emit(true)
  }

  clientStatus(client: any): string {
    if (client && client.status)
      return this.clientService.getClientStatusHtml(client.status.toString());
    else
      return '';
  }
}

@NgModule({
  declarations: [LostDealComponent],
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    StyleClassModule,
    ReactiveFormsModule,
    LostDealReasonComponentModule
  ],
  exports: [LostDealComponent]
})
export class LostDealComponentModule {
}
