import { SmsService } from './../../../../../../api/src/lib/sms/sms.service';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BadRequestError, ErrorMsgComponentModule } from '@fitness-force/errors';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject, NotFoundError, Observable } from 'rxjs';
import { FactoryHttpLoader } from '../../helpsupport/helpsupport.component';
import { TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { TENANTSINTERFACE } from '@fitness-force/models';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'create-sms',
  templateUrl: './create-sms.component.html',
  styleUrls: ['./create-sms.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateSmsComponent implements OnInit {
  @Input() createSmsModal: boolean | null = false;
  @Input() clientData$ = new BehaviorSubject(null)
  @Output() closeCreateSmsDialog =  new EventEmitter()

  smsUtilityForm: FormGroup = new FormGroup({})
  smsFormGroup: FormGroup = new FormGroup({})
  attachments: any;
  selectedAttachment: { name: string } | undefined;
  editorText: string | undefined;
  allClientData$ = new BehaviorSubject<any>([])
  getAccessTenantList$: Observable<TENANTSINTERFACE>
  selectedtenant:any
  clientData:any
  allSmsTemplates$ = new BehaviorSubject<any>([])
  constructor(private store: Store, private smsService: SmsService, public translate: TranslateService, private fb: FormBuilder, private messageService: MessageService) { }
  ngOnInit(): void {
    this.componentInit()
  }
  componentInit(){
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.selectedtenant =  tenant.selected_tenant

    })
    this.clientData$.subscribe(
      (client:any)=>{
      this.clientData =  client

      this.allClientData$.next(client)
    })
    this.smsUtilityForm = this.fb.group({
      smsTemplates: ['']
    })
    this.smsFormGroup =  this.fb.group({
      smsDesc: ['', [Validators.required]]
    })
    this.fetchSmsTemplates()
  }
  get SmsTemplates(): FormControl {
    return this.smsUtilityForm.get('smsTemplates') as FormControl
  }
  get SmsDesc(): FormControl{
    return this.smsFormGroup.get('smsDesc') as FormControl
  }
  closeDialogEvent(){
    this.closeCreateSmsDialog.emit(false)
  }
  fetchSmsTemplates(){
  this.smsService.fetchAllSMSTemplates(this.selectedtenant.id).subscribe((tenantData:any)=>{

    this.allSmsTemplates$.next(tenantData.items)
  })
  }
  sendSms(){
    const sendSmsModal = {
      "tenant_id":  this.selectedtenant.id,
      "oppotunity_id": 0,
      "task_id": 0,
      "template_master_id": this.SmsTemplates.value.id,
      "custom_content": this.SmsTemplates.value.id ? null: this.SmsDesc.value
    }
    this.smsService.sendSMSTemplates(this.clientData.id, sendSmsModal).subscribe({
      next: (res:any)=>{

      this.messageService.add({severity:'success', summary:'Success!', detail: `SMS sent successfully`});
      },
      error:(error:any)=>{
        if (error instanceof NotFoundError) {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Page Not Found'});
        } else if (error instanceof BadRequestError) {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail: 'Bad Request'});
        }
        else {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Something went wrong'});
        }
      }
  })
  }
  selectedTemplate(){
    const selectedTempId = this.SmsTemplates.value.id
    this.smsService.fetchSMSTemplatesById(this.selectedtenant.id, selectedTempId).subscribe((res:any)=>{
      this.SmsDesc.setValue(res.body_primary_language)
    })
  }
}

@NgModule({
  declarations: [CreateSmsComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    PasswordModule,
    FileUploadModule,
    InputTextareaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),
    ErrorMsgComponentModule,
    ToastModule
  ],
  exports: [CreateSmsComponent]
})
export class CreateSmsComponentModule {
}
