import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { HTTP_RES_ERROR_CODE } from '@fitness-force/enum';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { AuthSevices } from './auth.service';

@Injectable()
export class IntercepterService implements HttpInterceptor{
  publicApis: Array<string>;

  constructor(
    private router: Router,
    private injector: Injector
  ) {

    this.publicApis= CONST_URL_SEGEMENTS.PUBLIC_APIS;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authService = this.injector.get(AuthSevices)
    if (this.validInterceptor(req.url)) {
      let tokenizedReq = req.clone({
        setHeaders:
        {
          'Content-Type':  'application/json',
          'authorization': `Bearer ${authService.tokenFromStorage ? authService.tokenFromStorage : ''}`,
        }
      });

      return next.handle(tokenizedReq)
      .pipe((catchError((err:any) => { 
        return this.handleAuthError(err);
      })))
    }
    return next.handle(req)
    .pipe((catchError((err:any) => { 
      return this.handleAuthError(err);
    })))
  }

  private validInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'fftesting.fitnessforce.com/';
    let positionIndicatorOfAssets: string = 'dist/'
   
    let position = (requestUrl.indexOf(positionIndicatorOfAssets) == -1 ) ? requestUrl.indexOf(positionIndicator) : requestUrl.indexOf(positionIndicatorOfAssets) ;
    let positionIndicatorLength = (requestUrl.indexOf(positionIndicatorOfAssets) == -1 ) ? positionIndicator.length  : positionIndicatorOfAssets.length
    if (position > 0) {
      
      let destination: string = requestUrl.substring(position + positionIndicatorLength);
      for (let address of this.publicApis) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }
  handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === HTTP_RES_ERROR_CODE.UNAUTHORIZED || err.status === HTTP_RES_ERROR_CODE.FORBIDDEN) {
      //navigate /delete cookies or whatever
      console.log('erty', err)
      this.router.navigateByUrl(`/`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(()=>err);
  }

}
