<ng-container *ngIf="{
  row: columnData | async
} as $">
<!-- {{$.row | json}} -->
<p-overlayPanel #op styleClass="overflow-hidden text-sm">
    <ng-template pTemplate>

      <!-- Dropdown Content -->
      <div class="bg-white -mx-4 -mt-4 flex align-items-start justify-content-between px-3 py-2" *ngIf="$.row">
        <div class="flex mt-2 w-10rem line-height-1">
          <span class="text-base font-medium text-800">{{$.row.full_name}}</span>
          <span class="text-xs text-600 ml-1 mt-1">{{$.row.id}}</span>
        </div>
        <div class="mt-1" [innerHTML]="clientStatus($.row)"></div>
      </div>
      <!-- Dropdown Content -->
      
      <!-- Dropdown Footer -->
      <div class="px-3 py-2 -mx-4 -mb-4 bg-gray-200 text-800 text-xs" *ngIf="$.row">
        <div class="flex align-items-center mb-2">
          <i class="pi pi-phone mr-2 text-gray-500"></i>
          <span *ngIf="$.row.mobile_number != 0">{{$.row.mobile_country_code}}{{$.row.mobile_number}}</span>
          <span *ngIf="$.row.mobile_number == 0">NA</span>
        </div>
        <div class="flex align-items-center mb-1">
          <i class="pi pi-envelope mr-2 text-gray-500"></i>
          <span *ngIf="$.row.email_id"> {{$.row.email_id}}</span>
          <span *ngIf="!$.row.email_id">NA</span>
        </div>
      </div>
      <!-- Dropdown Footer -->

    </ng-template>
  </p-overlayPanel>


  <div class="flex align-items-center"  (click)="op.toggle($event)" *ngIf="$.row">
    <span class="cursor-pointer flex align-items-center" (mouseenter)="$.row.full_name ? op.show($event): void" (mouseleave)="op.hide()" (click)="gotoClient($.row.id)">

        <p-avatar *ngIf="!$.row.image && $.row.full_name; else elseBlock" [label]="$.row.full_name | titlecase | shortName " shape="circle" styleClass="text-sm"></p-avatar>
      <ng-template #elseBlock>
        <p-avatar [image]="$.row.image" shape="circle"></p-avatar>
        <span *ngIf="!$.row.full_name">-</span>
      </ng-template>

      <strong class="ml-2 text-primary">{{$.row.full_name}}</strong>
    </span>

  </div>
</ng-container>

