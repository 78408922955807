import {
  Component,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import {
  BehaviorSubject,
  concat,
  merge,
  Observable,
  of,
  combineLatest,
  map,
} from 'rxjs';
import { STAFF_STATE } from '../../../../../../../apps/ff-frontend/src/app/state/app.state';
import { STAFFINTERFACE } from '@fitness-force/models';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ShortNamePipeModule } from '../../../filters/name-initials.pipe';
import { AvatarModule } from 'primeng/avatar';


@Component({
  selector: 'staff-selector[formGroup]',
  templateUrl: './assigned-to.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedToComponent implements OnInit, AfterViewInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: FormControl = new FormControl();
  @Input('id') staffId$ = new BehaviorSubject<number>(0);
  @Input('styleClass') styleClass: string = 'border-none surface-50 w-full';
  @Input('placeHolder') placeHolder: string = 'Select';
  @Input('claims') claims$: Observable<string[]> = of([]);
  @Input('canRepresetative') canRepresetative$ = new BehaviorSubject(false);

  @Input('filterstaff') staffIds$: Observable<number[]> = of([-1]);
  @Output('selectedStaff') selectedStaff: EventEmitter<any> = new EventEmitter();
  staffs$ = new BehaviorSubject<STAFFINTERFACE[]>([]);
  allStaffs$ = new BehaviorSubject<STAFFINTERFACE[]>([]);


  claims: Observable<any> | any;
  constructor(public fb: FormBuilder, private store: Store) { }

  ngOnInit(): void {
    this.GetStaffList();
    this.claims = this.claims$;
  }

  ngAfterViewInit(): void {
    this.staffId$.subscribe((res) => {
      this.SetSelectedStaff();
    });
  }

  get SelectedStaff(): STAFFINTERFACE {
    return this.controlName.value;
  }

  GetStaffList(): void {
    combineLatest([
      this.store.select(STAFF_STATE),
      this.canRepresetative$,
      this.staffIds$
    ]).subscribe({
      next: (res: Array<any>) => {
        if (res[0] && res[0].staffList && res[0].staffList.length > 0)
          this.staffs$.next(res[0].staffList.filter((o: STAFFINTERFACE) => String(o.status).toLowerCase() == 'active'));
        if (res[0] && res[0].staffListAll && res[0].staffListAll.length > 0)
          this.allStaffs$.next(res[0].staffListAll);

        if (
          res[0] &&
          res[0].staffList &&
          res[0].staffListAll &&
          res[0].staffList.length > 0 &&
          res[0].staffListAll.length > 0
        )
          //this.filterStaffs();          
          this.SetSelectedStaff();

        if (res[1]) {
          this.staffs$.next(
            res[0].staffList.filter(
              (o: STAFFINTERFACE) =>
                String(o.status).toLowerCase() == 'active' &&
                o.can_be_client_rep
            )
          );
        }
        if (res[2] && res[2][0] != -1) {
          this.staffs$.next(
            res[0].staffList.filter(
              (o: STAFFINTERFACE) =>
                res[2].includes(o.id)
            )
          );
        }
      },
    });
  }

  onSelectStaff(event: any) {
    this.selectedStaff.emit(event);
  }

  SetSelectedStaff(): void {
    if (this.staffId$.value > 0) {
      let defaultSelectedStaff = this.staffs$.value.find((o: STAFFINTERFACE) => o.id == this.staffId$.value);
      if (defaultSelectedStaff && defaultSelectedStaff.id > 0) {
        this.controlName.setValue(defaultSelectedStaff);
      } else {
        defaultSelectedStaff = undefined;
        defaultSelectedStaff = this.allStaffs$.value.find((o: STAFFINTERFACE) => o.id == this.staffId$.value);
        if (defaultSelectedStaff && defaultSelectedStaff.id > 0) {
          let staff: STAFFINTERFACE[] = this.staffs$.value;
          staff.push(defaultSelectedStaff || ({} as STAFFINTERFACE));
          this.staffs$.next(staff);
          this.controlName.setValue(defaultSelectedStaff);
        }
      }
    }
  }

  OnStaffImgError(event: any) {
    event.target.src = './assets/img/avatar.jpg';
  }
}



@NgModule({
  declarations: [AssignedToComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DropdownModule, ShortNamePipeModule, AvatarModule],
  exports: [AssignedToComponent],
})
export class AssignedToComponentModule { }
