import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { ClientRegular } from '@fitness-force/enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'client-regular-status',
  templateUrl: './client-regular-status.component.html',
  styleUrls: ['./client-regular-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientRegularStatusComponent implements OnInit {
  @Input() additionData  = new BehaviorSubject(null)
  @Input() columnData = new BehaviorSubject(null)
  constructor() { }

  regularStatus:any =ClientRegular;

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [ClientRegularStatusComponent],
  imports: [],
  exports: [ClientRegularStatusComponent]
})
export class ClientRegularStatusComponentModule {
}
