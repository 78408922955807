import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriorityComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: FormControl = new FormControl();
  @Input('placeHolder') placeHolder: string = 'Select';
  @Input('styleClass') styleClass: string = '-ml-2 border-none surface-50 w-full text-sm';
  @Input('langCode') languageCode: string = 'en';

  priority: any[] = [
    { name: 'High', value: 'high', icon: '' },
    { name: 'Medium', value: 'medium', icon: '' },
    { name: 'Low', value: 'low', icon: '' },
  ];

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(this.languageCode);
    this.translate.use(this.languageCode);

    let transalteKey: string[] = ['common.PRIORITY.HIGH', 'common.PRIORITY.MEDIUM', 'common.PRIORITY.LOW'];
    this.translate.get(transalteKey).subscribe(translations => {
      this.priority.find(item => item.value == 'high').name = translations['common.PRIORITY.HIGH'];
      this.priority.find(item => item.value == 'medium').name = translations['common.PRIORITY.MEDIUM'];
      this.priority.find(item => item.value == 'low').name = translations['common.PRIORITY.LOW'];
    });
  }

  ngOnInit(): void {
  }

  get Priority(): any {
    console.log('Priority -> ', this.controlName?.value);
    return this.controlName?.value;
  }
}

@NgModule({
  declarations: [PriorityComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  exports: [PriorityComponent]
})
export class PriorityComponentModule {
}
