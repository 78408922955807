import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {ImageCropperModule,Dimensions, ImageCroppedEvent, ImageTransform  } from 'ngx-image-cropper';


import {DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
  } from '@ngx-translate/core';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropDialogComponent implements OnInit {

  imageChangedEvent: any = '';
  // Blob(File)
  imageFile:any
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  header:string='';
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,public translate: TranslateService) {
    this.imageFile=this.config.data?.imageFile;
    this.header = this.config.data?.header;
   }

  ngOnInit(): void {
  }

  doneCropping(){
      this.ref.close({image:this.croppedImage});
  }


  fileChangeEvent(event: any): void {

      this.imageChangedEvent = event;

  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;

  }

  imageLoaded() {
      this.showCropper = true;

  }

  cropperReady(sourceImageDimensions: Dimensions) {
      // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
      // console.log('Load failed');
  }

  rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }


  flipHorizontal() {
      this.transform = {
          ...this.transform,
          flipH: !this.transform.flipH
      };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.canvasRotation = 0;
      this.transform = {};
  }

  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
      this.transform = {
          ...this.transform,
          rotate: this.rotation
      };
  }

}

@NgModule({
  declarations: [ImageCropDialogComponent],
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    DynamicDialogModule,
    ImageCropperModule,FormsModule,
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: FactoryHttpLoader,
          deps: [HttpClient],
        },
      })
    ],
   
  exports: [ImageCropDialogComponent]
})
export class ImageCropDialogComponentModule {
}
