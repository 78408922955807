<div *ngIf="staffInfo | async; let data;else dash">
  <p-overlayPanel *ngIf="data" #op styleClass="overflow-hidden text-sm">
    <ng-template pTemplate>

      <!-- Dropdown Content -->
      <div class="bg-white -mx-4 -mt-4 flex align-items-start justify-content-between px-3 py-2" *ngIf="data">
        <div class="flex mt-2 w-10rem line-height-1">
          <span class="text-base font-medium text-800">{{ data.name | titlecase }}</span>
          <span class="text-xs text-600 ml-1 mt-1">{{ data.id }}</span>
        </div>
        <span class="bg-green-500 px-2 py-1 font-bold border-round text-white text-xxs uppercase ml-4 mt-1">{{ data.role |
          titlecase }}</span>
      </div>
      <!-- Dropdown Content -->

      <!-- Dropdown Footer -->
      <div class="px-3 py-2 -mx-4 -mb-4 bg-gray-200 text-800 text-xs">
        <div class="flex align-items-center mb-2">
          <i class="pi pi-phone mr-2 text-gray-500"></i>
          <span *ngIf="data.mobile_number;else not_specified ">{{ data.mobile_country_code }} {{ data.mobile_number}} </span>
          <ng-template #not_specified>Not specified</ng-template>
        </div>
        <div class="flex align-items-center mb-1">
          <i class="pi pi-envelope mr-2 text-gray-500"></i>
          <span class="break-word">{{ data.email_id }}</span>
        </div>
      </div>
      <!-- Dropdown Footer -->

    </ng-template>
  </p-overlayPanel>

  <div
    *ngIf="data"
    class="flex align-items-center cursor-pointer"
    (click)="toggleOverlay($event)"
    (mouseenter)="op.show($event)" (mouseleave)="op.hide()"
  >
    <p-avatar
      *ngIf="data && data.ff_picture; else noContactPic"
      [image]="data.ff_picture"
      styleClass="mr-3 overflow-hidden border-round"
    ></p-avatar>

    <ng-template #noContactPic>
      <p-avatar
        styleClass="text-sm mr-3"
        label="{{ data.name | titlecase | shortName }}"
      ></p-avatar>
    </ng-template>

    <span class="text-primary text-sm font-bold">{{ data.name }}</span>
  </div>
</div>
<ng-template #dash>-</ng-template>
