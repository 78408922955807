import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FactoryHttpLoader } from '../helpsupport/helpsupport.component';

@Component({
  selector: 'admin-form-right-side',
  templateUrl: './admin-form-right-side.component.html',
  styleUrls: ['./admin-form-right-side.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminFormRightSideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [AdminFormRightSideComponent],
  imports: [CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),],
  exports: [AdminFormRightSideComponent]
})
export class AdminFormRightSideComponentModule {
}
