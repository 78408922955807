import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlanMasterList, Report, ReportListResponse, Settings } from '@fitness-force/models';

@Pipe({
  name: 'settingsFilter',
})
export class SettingsFilterPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(settings: Settings[], term: string): any {
    //check if the search term is defined
    if (!settings || !term) return settings;
    const re = new RegExp(term, 'gi');
    //return updated setting array
    let result = settings.filter(s => s.name.toLowerCase().includes(term.toLowerCase())
      || s.categories.some(c => c.name.toLowerCase().includes(term.toLowerCase()))
      || s.categories.some(c => c.description.toLowerCase().includes(term.toLowerCase()))
    );
    return result;
  }
}

@Pipe({
  name: 'reportFilter',
})
export class ReportFilterPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(reports: ReportListResponse[], term: string): any {
    //check if the search term is defined
    if (!reports || !term) return reports;
    const re = new RegExp(term, 'gi');
    //return updated setting array
    let result = reports.filter(s => s.category.toLowerCase().includes(term.toLowerCase())
      || s.reports.some(c => c.report_key.toLowerCase().includes(term.toLowerCase()))
      || s.reports.some(c => c.report_description.toLowerCase().includes(term.toLowerCase()))
    );
    return result;
  }
}


@Pipe({
  name: 'fevouriteReportFilter',
})
export class FevouriteReportFilterPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(reports: any, term: string): any {
    //check if the search term is defined
    if (!reports || !term) return reports;
    const re = new RegExp(term, 'gi');
    //return updated setting array
    let result = reports.filter((s: any) => s.report.report_key.toLowerCase().includes(term.toLowerCase())
      || s.report.report_description.toLowerCase().includes(term.toLowerCase())
    );
    return result;
  }
}

@Pipe({
  name: 'highlight'
})

export class HighlightSearch implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any, args: any): any {

    if (!args) {
      return value;
    }
    // Match in a case insensitive maneer
    const re = new RegExp(args, 'gi');
    const match = value.match(re);

    // If there's no match, just return the original value.
    if (!match) {
      return value;
    }

    const replacedValue = value.replace(re, "<mark>" + match[0] + "</mark>");
    return replacedValue;
  }
}

@Pipe({
  name: 'planFilter',
})
export class PlanFilterPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(plan: any, term: any): PlanMasterList[] {
    //check if the search term is defined    
    if (!plan || !term) return plan;    
    if (!term.filter && !term.serviceTypeFilter) return plan;

    let result = {} as PlanMasterList[];
    if (term.filter && term.serviceTypeFilter) {
      result = plan.filter((s: any) =>
        (
          s.name_primary_language.toLowerCase().includes(term.filter.toLowerCase())
          || s.name_secondary_language.toLowerCase().includes(term.filter.toLowerCase())
          || s.subscription_category.toLowerCase().includes(term.filter.toLowerCase())
        ) && (
          ((s.recurring_service_catagories) && s.recurring_service_catagories.includes(term.serviceTypeFilter))
          || ((s.nonrecurring_service_catagories) && s.nonrecurring_service_catagories.includes(term.serviceTypeFilter))
        )
      );
    }
    else if (term.filter) {
      result = plan.filter((s: any) =>
        s.name_primary_language.toLowerCase().includes(term.filter.toLowerCase())
        || s.name_secondary_language.toLowerCase().includes(term.filter.toLowerCase())
        || s.subscription_category.toLowerCase().includes(term.filter.toLowerCase())
      );
    }
    else if (term.serviceTypeFilter) {
      result = plan.filter((s: any) =>
        ((s.recurring_service_catagories) && s.recurring_service_catagories.includes(term.serviceTypeFilter))
        || ((s.nonrecurring_service_catagories) && s.nonrecurring_service_catagories.includes(term.serviceTypeFilter))
      );
    }
    return result;
  }
}

@NgModule(
  {
    declarations: [HighlightSearch, SettingsFilterPipe, ReportFilterPipe, FevouriteReportFilterPipe, PlanFilterPipe],
    exports: [HighlightSearch, SettingsFilterPipe, ReportFilterPipe, FevouriteReportFilterPipe, PlanFilterPipe]
  }
)

export class CommmonPipeModule { }
