import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceStatusComponent implements OnInit {
  @Input() columnData  = new BehaviorSubject(null);
  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [InvoiceStatusComponent],
  imports: [
    CommonModule,
    TagModule
  ],
  exports: [InvoiceStatusComponent]
})
export class InvoiceStatusComponentModule {
}
