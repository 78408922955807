<input 
type="text" 
class="w-full text-sm"
[formControl]="competitorName"
pInputText
[placeholder]="placeHolderName" 
 autocorrect="off" 
autocapitalize="off"
spellcheck="off"
(keydown.enter)="$event.preventDefault()"
 #searceplace
 >
