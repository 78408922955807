import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInitService } from 'libs/api/src/lib/app-init/appinit.service';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMsgComponent implements OnInit {
  @Input() errors: any;
  @Input() fieldLabel: any;
  @Input() secondFieldLabel: any;
  errormsg = '';
  constructor(public translate: TranslateService, public appInit: AppInitService) {
  }


  ngOnInit(): void {
    console.log("...errors");
  }
  parsedata(e:any){
    console.log('added', e)
  }
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}
@NgModule({
  declarations: [ErrorMsgComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [ErrorMsgComponent]
})
export class ErrorMsgComponentModule {
}
