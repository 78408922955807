<!-- Header -->
<div *ngIf="addCategory" class="-mx-3">
  <service-category-create  (changed)="changedEditCategory($event)" (close)="addCategory=false"></service-category-create>
</div>

<form [ngClass]="{ hidden: addCategory }"
class="-m-3"
*ngIf="serviceCreateForm"
  [formGroup]="serviceCreateForm"
  (ngSubmit)="saveServiceCreateForm()">



<div  class="flex align-items-center justify-content-between p-3 border-bottom-1 border-gray-300">
    <h2 class="mb-0 text-base md:text-lg font-normal mr-3">
      <i class="pi pi-plus mr-2"></i>
      {{
        activeEditService?('ADMIN.SUBSCRIPTION.EDIT_SERVICE' | translate):('ADMIN.SERVICES.ADD_SERVICE' | translate)
      }}
    </h2>
  <div (click)="closeDialog()" class="pi pi-times text-gray-500 cursor-pointer"></div>
</div>
<!-- Header -->
<p-messages *ngIf="activeEditService && activeEditService.id" severity="info" styleClass="relative">
  <ng-template pTemplate>
    <div class="flex align-items-center  w-full  md:m-0">
      <i class="pi pi-info-circle text-xl"></i>
      <div class="ml-3 text-sm">
        {{
          'ADMIN.SERVICES.CHANGE_MSG' | translate
        }}
      </div>
    </div>
  </ng-template>
</p-messages>
<!-- Modal Content -->
<div class="py-2 md:p-3 surface-100">
  <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
     {{
      'ADMIN.SERVICES.MEMBERSHIP_BEHAVIOUR' | translate
    }} <sup class="p-error">*</sup>
  </label>

  <!-- Tabs for Desktop -->
  <div class="p-buttonset hidden md:block">
    <!-- Selected State-->
    <button
      pButton
      pRipple
      type="button"
      label="{{
      'ADMIN.SERVICES.CHECK_IN' | translate
    }}"
      icon="pi pi-sign-in"
      class="text-sm w-4-sm border-right-1 border-white hover:text-white"
      (click)="setBehavior({name:MembershipBehaviour.MemberShip,key:'MemberShip'})"
      [ngClass]="Behavior?.value?.name == MembershipBehaviour.MemberShip ? 'border-white' : 'p-button-secondary' "
    ></button>
    <!-- Selected State-->

    <button
      pButton
      type="button"
      pRipple
      label=" {{
        'ADMIN.SERVICES.APPOINTMENT_SESSION' | translate
      }}"
      icon="pi pi-user"
      class="text-sm w-4-sm border-right-1 border-white hover:text-white"
      (click)="setBehavior({name:MembershipBehaviour.AppointmentPackage,key:'AppointmentPackage'})"
      [ngClass]="Behavior?.value?.name == MembershipBehaviour.AppointmentPackage ? 'border-white' : 'p-button-secondary' "
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="{{
        'ADMIN.SERVICES.CLASS_SESSION' | translate
      }}"
      icon="pi pi-users"
      class="text-sm w-4-sm border-right-1 border-white hover:text-white"
      (click)="setBehavior({name:MembershipBehaviour.ClassPackage,key:'ClassPackage'})"
      [ngClass]="Behavior?.value?.name == MembershipBehaviour.ClassPackage ? 'border-white' : 'p-button-secondary' "
    ></button>
  </div>
  <!-- Tabs for Desktop -->

  <!-- Selectbox for Mobile -->
  <div class="md:hidden mt-3">
    <p-dropdown
    [options]="membershipBehaviour"
      optionLabel="name"
      filterBy="name"
      styleClass="w-full"
      defaultLabel="Month"
      formControlName="behaviour"
    >
      <ng-template pTemplate="selectedItem">
        <div class="text-sm flex align-items-center"><i></i>{{Behavior?.value?.name}}</div>
      </ng-template>
      <ng-template let-membershipBehaviour pTemplate="item">
        <div class="text-sm flex align-items-center"><i></i>{{membershipBehaviour.name}}</div>
      </ng-template>
    </p-dropdown>
  </div>
  <!-- Selectbox for Mobile -->
  <app-error-msg *ngIf="
  Behavior?.touched &&
  Behavior?.invalid
" [errors]="Behavior?.errors" [fieldLabel]="'ADMIN.SERVICES.MEMBERSHIP_BEHAVIOUR' | translate"></app-error-msg>

</div>
<!-- Modal Content -->

<p-divider styleClass="my-0"></p-divider>



<div class="grid m-0 px-3 mt-2 -mb-3">

  <div class="col-12 md:col-6 md:pr-4">
    <div  class="text-sm flex align-items-center justify-content-between line-height-1">
      <span class="mr-3">{{
       'ADMIN.SERVICES.NON_RECURRING' | translate
     }}<sup class="p-error">*</sup></span>
     <p-inputSwitch formControlName="non_recurring_services"></p-inputSwitch>
    </div>
  </div>

  <div class="col-12 md:col-6 md:pl-4">
    <div  class="text-sm flex align-items-center justify-content-between line-height-1">
      <span class="mr-3">{{
       'ADMIN.SERVICES.IS_BASE_MEMBERSHIP' | translate
     }}<sup class="p-error">*</sup></span>
      <p-inputSwitch formControlName="require_base_membership"></p-inputSwitch>
    </div>
  </div>

  <div class="col-12 md:col-6 md:pr-4">
    <div  class="text-sm flex align-items-center justify-content-between line-height-1">
      <span class="mr-3">Converts customer to member when this service is purchased</span>
      <p-inputSwitch></p-inputSwitch>
    </div>
  </div>

  <div class="col-12 md:col-6 md:pl-4">
    <div  class="text-sm flex align-items-center justify-content-between line-height-1">
      <span class="mr-3">Members only service <span class="block">(can be sold only to members)</span></span>
      <p-inputSwitch></p-inputSwitch>
    </div>
  </div>

  <div class="col-12 md:col-6 md:pr-4">
    <div  class="text-sm flex align-items-center justify-content-between line-height-1">
      <span class="mr-3">This is an individual non member service open to anyone</span>
      <p-inputSwitch></p-inputSwitch>
    </div>
  </div>

</div>

<p-divider styleClass="mb-0 mt-4"></p-divider>

<!-- Class Session -->
<div class="grid m-0 py-2 px-3">
  <!-- Form Group -->
  <div class="col-12 md:col-6 md:pr-4">
    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
      {{
        'ADMIN.SERVICES.SERVICE_CATEGORY' | translate
      }} <sup class="p-error">*</sup>
    </label>
    <p-dropdown
    [options]="services"
      placeholder="{{'ADMIN.SERVICES.SELECT_SERVICE_CAT' | translate}}"
      optionLabel="name_primary_language"
      filterBy="name_primary_language"
      styleClass="w-full text-sm"
      formControlName="category_id"
    >
      <ng-template pTemplate="selectedItem">
        <div class="text-sm" *ngIf="CategoryId" >{{CategoryId?.value?.name_primary_language}}</div>
      </ng-template>
      <ng-template let-serviceCategory pTemplate="item">
        <div class="text-sm">  {{ serviceCategory.name_primary_language }}</div>
      </ng-template>
      <ng-template pTemplate="footer">

        <div (click)="showCategoryDialog()"
          class="text-sm text-primary px-3 py-2 border-top-1 border-gray-300"> <i
            class="pi pi-plus mr-2"></i>{{'ADMIN.SERVICES.ADD_SERVICE_CATEGORY' | translate}}</div>
      </ng-template>

    </p-dropdown>
    <app-error-msg *ngIf="
    CategoryId?.touched &&
    CategoryId?.invalid
  " [errors]="CategoryId?.errors" [fieldLabel]="'ADMIN.SERVICES.SERVICE_CATEGORY' | translate"></app-error-msg>

  </div>
  <!-- Form Group -->

  <!-- Form Group -->
  <div class="col-12 md:col-6 md:pl-4">
    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{
      'ADMIN.SERVICES.BASE_PRICE' | translate
    }} <sup class="p-error">*</sup></label>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon text-sm"
        ><span class=""> {{currencySymbol}}</span></span>
      <p-inputNumber
      formControlName="base_cost_in_minor_currency"
        [format]="false"
        class="text-sm"
        mode="decimal"
        [minFractionDigits]="2" [maxFractionDigits]="2"
      ></p-inputNumber>

    </div>
    <app-error-msg *ngIf="
    BaseCostInMinorCurrency?.touched &&
    BaseCostInMinorCurrency?.invalid
  " [errors]="BaseCostInMinorCurrency?.errors" [fieldLabel]="'ADMIN.SERVICES.BASE_PRICE' | translate"></app-error-msg>

  </div>
  <!-- Form Group -->

  <!-- Form Group -->
  <div class="col-12 md:col-6 md:pr-4">
    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
      {{
        'ADMIN.SERVICES.TAX_GROUP' | translate
      }} <sup class="p-error">*</sup>
    </label>
    <p-dropdown
    [options]="taxGroup"
      placeholder="{{'ADMIN.SERVICES.SELECT' | translate}}"
      optionLabel="name_primary_language"
      filterBy="name_primary_language"
      styleClass="w-full text-sm"
      formControlName="taxgrp_id"
    >
      <ng-template pTemplate="selectedItem">
        <div class="text-sm"> {{TaxGroupId?.value?.name_primary_language}}</div>
      </ng-template>
      <ng-template let-taxGroup pTemplate="item">
        <div class="text-sm">{{taxGroup.name_primary_language}}</div>
      </ng-template>
    </p-dropdown>
    <app-error-msg *ngIf="
    TaxGroupId?.touched &&
    TaxGroupId?.invalid
  " [errors]="TaxGroupId?.errors" [fieldLabel]="'ADMIN.SERVICES.TAX_GROUP' | translate"></app-error-msg>

  </div>
  <!-- Form Group -->

  <!-- Form Group -->
  <div class="col-12 md:col-6 md:pl-4">
    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
      {{
        'ADMIN.SERVICES.TAX_CODE' | translate
      }} <sup class="p-error">*</sup>
    </label>
    <input formControlName="tax_item_code" type="text" pInputText placeholder="" class="text-sm w-full" />
    <app-error-msg *ngIf="
    TaxItemCode?.touched &&
    TaxItemCode?.invalid
  " [errors]="TaxItemCode?.errors" [fieldLabel]="'ADMIN.SERVICES.TAX_CODE' | translate"></app-error-msg>

  </div>
  <!-- Form Group -->

  <!-- Form Group -->
  <div class="col-12 md:col-6 md:pr-4" *ngIf="Sessions &&Behavior?.value?.name != MembershipBehaviour.MemberShip" >
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon text-sm"
        > {{
          'ADMIN.SERVICES.NUMBER_OF_SESSIONS' | translate
        }} ({{
          'ADMIN.SERVICES.IN_NUMERIC' | translate
        }})</span
      >
      <p-inputNumber
        [format]="false"
        class="text-sm"
        mode="decimal"
        formControlName="sessions"
      ></p-inputNumber>
      <app-error-msg *ngIf="
      Sessions?.touched &&
      Sessions?.invalid
    " [errors]="Sessions?.errors" [fieldLabel]="'ADMIN.SERVICES.NUMBER_OF_SESSIONS' | translate"></app-error-msg>
    </div>
  </div>
  <!-- Form Group -->
</div>
<!-- Class Session -->

<p-divider styleClass="my-0"></p-divider>



<div class="grid m-0 py-2 px-3">

    <div class="col-12 md:col-6 md:pr-4">
    <div
      class="text-sm flex align-items-center justify-content-between line-height-1"
    >
      <span class="mr-3">{{
        'ADMIN.SERVICES.BASE_MEMBERSHIP' | translate
      }} <sup class="p-error">*</sup></span>
      <p-inputSwitch formControlName="is_base_membership"></p-inputSwitch>
    </div>
  </div>

  <div class="col-12 md:col-6 md:pl-4">
    <div
      class="text-sm flex align-items-center justify-content-between line-height-1"
    >
      <span class="mr-3">Session Owner Required? <sup class="p-error">*</sup></span>
      <p-inputSwitch></p-inputSwitch>
    </div>
  </div>


</div>

<!-- Modal Footer -->
<div class="lg:px-4 px-2 py-3 border-top-1 border-gray-300 bg-white">
  <div class="flex align-items-center justify-content-between">
    <div class="w-4">
      <p-button type="button" label="{{
        'ADMIN.COMMON.CANCEL' | translate
      }}"   (click)="closeDialog()" styleClass="p-button-link text-sm"></p-button>
    </div>
    <div class="w-8 text-right">
      <button class="text-sm" type="submit" pButton pRipple label="{{
        activeEditService?('ADMIN.SERVICES.UPDATE_SERVICE'| translate):('ADMIN.SERVICES.ADD_SERVICE'| translate)
      }}"></button>
    </div>
  </div>
</div>
<!-- Modal Footer -->
</form>

<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>
