
import { Store } from '@ngrx/store';

import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AppErrors, NotFoundError, UnauthorizedError } from '@fitness-force/errors';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { LANGUAGE, LoginDataModel } from '@fitness-force/models';
import { COMPANY_INFO, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';

const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})

export class AuthSevices {
  private readonly httpBackend: HttpClient
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private readonly backEndClientHandler: HttpBackend
  ) {
    this.httpBackend = new HttpClient(backEndClientHandler)
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  loginService(logindata: LoginDataModel, BASE_URL: string) {
    return this.httpBackend.post(BASE_URL + CONST_URL_SEGEMENTS.AUTH.LOGIN_TOKEN, logindata, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res && res.token) {
            localStorage.setItem('token', res.token);
            return res;
          }
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          else if (error.status === 401) {
            return throwError(() => new UnauthorizedError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  sendForgotPasswordEmail(uemail: any, BASE_URL: string) {
    return this.http.post(BASE_URL + CONST_URL_SEGEMENTS.AUTH.PASSWORD_LOOKUP, uemail, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          } else {
            return false;
          }
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  sendUniqueIndetifierEmail(identifier: any, userid: number, BASE_URL: string,) {
    return this.http.post(BASE_URL + CONST_URL_SEGEMENTS.AUTH.UNIQUE_IDENTIFIER + userid + '/mail',
      identifier, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          } else {
            return false;
          }
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  validateNewpassword(pass: any, userid: number, BASE_URL: string) {
    return this.http.post(BASE_URL + CONST_URL_SEGEMENTS.AUTH.PASSWORD_RESET + userid + '/password',
      pass, this.httpOptions)
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          } else {
            return false;
          }
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  logout() {
    localStorage.clear();
    // this.router.navigate(['/auth/login']);
    window.location.href = '/auth/login';
  }

  get tokenFromStorage(): string | null {
    return localStorage.getItem('token');
  }

  get decodedToken(): any {
    const getToken: any = this.tokenFromStorage;
    return helper.decodeToken(getToken);
  }

  get selectedLanguageStorage(): string {
    return localStorage.getItem('DEFAULT_LANGUAGE') as string;
  }

  //Need to take decision for storage clear of language
  get selectedLanguage(): LANGUAGE {
    let selectedLang: LANGUAGE = JSON.parse(this.selectedLanguageStorage);
    if (selectedLang && selectedLang.LANG_CODE)
      return JSON.parse(this.selectedLanguageStorage);
    else
      return { COUNTRY: 'India', LANG_ABBRE: 'India', 'LANG_CODE': 'en' } as LANGUAGE
  }

  get parseDecodedToken() {
    let buffDecodedToken = this.decodedToken;
    if (buffDecodedToken && buffDecodedToken.accessed_tenant) {
      let parsedAccessed_tenant = buffDecodedToken.accessed_tenant.split(',');
      let allTenant = buffDecodedToken.all_tenant.split(',');
      buffDecodedToken.accessed_tenant = parsedAccessed_tenant;
      buffDecodedToken.all_tenant = allTenant;
      return buffDecodedToken;
    } else {
      return null
    }
  }

  //this is used to get localized value 
  getLocalizedFieldData(primaryData: string, secondaryData: string): Observable<string> {
    return combineLatest([this.store.select(COMPANY_INFO), this.store.select(TENANTS_INFO)]).pipe(
      map(([comp, tent]) => {
        if (comp?.compDetails?.is_secondary_language) {
          if (comp?.selectedLang.LANG_CODE == tent?.selected_tenant.secondary_language)
            return secondaryData;
          else
            return primaryData;
        }
        else {
          return primaryData;
        }
      })
    )
  }

  get isTokenExpired(): any {
    const getToken: any = this.tokenFromStorage;
    const tokenExpDateTime: any = helper.getTokenExpirationDate(getToken)
    const tokenExpDateTimeParse = new Date(tokenExpDateTime)
    const today = new Date()
    const extractTokenDate: any = new Date(tokenExpDateTimeParse.getFullYear(), tokenExpDateTimeParse.getMonth(), tokenExpDateTimeParse.getDate());
    const extractTodayDate: any = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    const extractTodayTime: any = today.getTime()
    const extractExpTimeTime: any = tokenExpDateTimeParse.getTime()

    if (extractTokenDate > extractTodayDate) {
      return true
    } else if (extractTokenDate === extractTodayDate) {
      if (extractExpTimeTime > extractTodayTime) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  get isLoginedIn(): boolean {
    if (this.tokenFromStorage && this.decodedToken && this.isTokenExpired) {
      // this.currentUser;
      return true;
    } else {
      return false;
    }
  }

  get currentUser(): any {
    console.log('Decoded token', this.decodedToken)
    return this.decodedToken.role;
  }

  currentUserDetails(): any {
    return this.decodedToken;
  }

  get loggedInStaffId(): number {
    return parseInt(this.decodedToken.Id);
  }

}
