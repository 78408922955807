import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
} from '@angular/core';
import { AppointmentBehaviour } from '@fitness-force/enum';

@Component({
  selector: 'behaviour-view',
  templateUrl: './behaviour-view.component.html',
  styleUrls: ['./behaviour-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BehaviourViewComponent implements OnInit {
  @Input() behavior: any;
  activeBehavior: any;
  @Input() columnData:any = ''
  @Input() additionData:any
  icon: string;
  appointmentBehaviour: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.columnData.subscribe({next:(cData:any)=>{
      for (let [key, value] of Object.entries(AppointmentBehaviour)) {
        if ( key == cData?.behaviour) {
          this.activeBehavior = { name: value, key };
          this.setIcon();
        }
      }
    }})

  }

  setIcon() {
    switch (this.activeBehavior.key) {
      case 'Free':
        this.icon="pi-money-bill";
        break;
      case 'Paid':
        this.icon="pi-dollar";
        break;
      case 'Membership_Paid':
        this.icon="pi-dollar";
        break;
      case 'Free_for_Members':
        this.icon="pi-money-bill";
        break;
    }
  }
}

@NgModule({
  declarations: [BehaviourViewComponent],
  imports: [CommonModule],
  exports: [BehaviourViewComponent],
})
export class BehaviourViewComponentModule {}
