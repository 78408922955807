import { Client, PagedResults } from '@fitness-force/models';

//#region  request

export interface OmniSearchRequest {
  language: string,
  query: string,
  providers: Providers[]
};

export interface Providers {
  provider_name: string,
  pagination: Pagination
}

export interface Pagination {
  page_number: number,
  page_size: number
}

//#endregion request

//#region  response

export interface OmniSearchResponse {
  original_query: string;
  autocomplete_suggestion: string;
  language: string;
  baseurl: string;
  searchResults: searchResultsDummy;
}

export interface searchResultsDummy {
  clients: PagedResults<Client>;
  prospects: PagedResults<Client>;
  actions: PagedResults<actionresponseDummy>;
  kbdocs: PagedResults<kbdocsresponseDummy>;
}

export interface actionresponseDummy {
  srch_act_name: string;
  srch_act_module: string;
  srch_act_url: string;
  srch_act_desc: string;
}

export interface kbdocsresponseDummy {
  kb_articles_url: string;
  kb_articles_module: string;
  kb_articles_title: string;
  kb_articles_desc: string;
}

//#endregion response