import { States } from "@fitness-force/models";

export const STATE_MASTER_STATE: States = {
    state: {
        id: 0,
        name: '',
        code: '',
        country_code: '',
        country_id: 0
    },
    states: []
}