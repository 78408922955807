<ng-container *ngIf="{
  row: columnData | async
} as $">
<div class="flex justify-content-between" (click)="executeAction('rowAction',  $.row)">
  <div class="flex align-items-end" class="mr-3 cursor-pointer"
    >{{ $.row.name_primary_language.length>24? (( $.row.name_primary_language | slice:0:24 )+'...'): $.row.name_primary_language}}
</div>

  <div class="flex  align-items-end" >
  <p-tag *ngIf="isFuture;else second"

    styleClass="p-mr-2 bg-gray-600 text-xxs font-normal uppercase white-space-nowrap"
  >
    FUTURE </p-tag>

<ng-template #second>
  <p-tag
    *ngIf="$.row.status.toUpperCase() == 'Active'.toUpperCase()"
    styleClass="p-mr-2 text-xxs font-normal uppercase white-space-nowrap"
    severity="success"
  >
    {{ $.row.status }}</p-tag
  >
  <p-tag
    *ngIf="  $.row.status.toUpperCase() == 'InActive'.toUpperCase()"
    styleClass="p-mr-2 bg-gray-600 text-xxs font-normal uppercase white-space-nowrap"
  >
    {{ $.row.status }}</p-tag
  >
</ng-template>
</div>

</div>
</ng-container>