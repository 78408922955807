import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, ConflictError, NotFoundError } from '@fitness-force/errors';
import { throwError, Observable, pipe } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ListTaskCategoryResponse } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class TaskCategoryService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {}
  getTaskCategory(
    tenantId: number,
    allParams: Array<string> = []
  ): Observable<ListTaskCategoryResponse> {
    let parsedParams = new HttpParams();
    if (allParams.length > 0) {
      allParams.forEach((p: any) => {
        parsedParams = parsedParams.append(p.name, p.value);
      });
    }
    let url =
      environment.BASE_URL +
      CONST_URL_SEGEMENTS.TASKCATEGORY.GETTASKCATEGORY.replace(
        '{tenant_id}',
        tenantId.toString()
      );
    return this.http
      .get(url, { headers: this.httpOptions.headers, params: parsedParams })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  addTaskCategory(tenantId: number, data: any): Observable<any> {
    let url =
      environment.BASE_URL +
      CONST_URL_SEGEMENTS.TASKCATEGORY.GETTASKCATEGORY.replace(
        '{tenant_id}',
        tenantId.toString()
      );
    return this.http
      .post(url, data, { headers: this.httpOptions.headers })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          } else if (error.status === 409) {
            return throwError(() => new ConflictError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateTaskCategory(
    tenantId: number,
    categoryId: number,
    data: any
  ): Observable<any> {
    let url =
      environment.BASE_URL +
      CONST_URL_SEGEMENTS.TASKCATEGORY.UPDATETASKCATEGORY.replace(
        '{tenant_id}',
        tenantId.toString()
      ).replace('{tsk_category_id}', categoryId.toString());
    return this.http.put(url, data, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  markActive(tenantId: number, categoryId: number): Observable<any> {
    let url =
      environment.BASE_URL +
      CONST_URL_SEGEMENTS.TASKCATEGORY.MARKACTIVE.replace(
        '{tenant_id}',
        tenantId.toString()
      ).replace('{tsk_category_id}', categoryId.toString());
    return this.http
      .post(url, null, { headers: this.httpOptions.headers })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markInActive(tenantId: number, categoryId: number): Observable<any> {
    let url =
      environment.BASE_URL +
      CONST_URL_SEGEMENTS.TASKCATEGORY.MARKINACTIVE.replace(
        '{tenant_id}',
        tenantId.toString()
      ).replace('{tsk_category_id}', categoryId.toString());
    return this.http
      .post(url, null, { headers: this.httpOptions.headers })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
