import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { AuthSevices } from 'libs/api/src/lib/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  constructor(private authservice: AuthSevices, private router: Router ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (this.authservice.isLoginedIn) {
       
        return true;
      } 
        this.router.navigate(['/auth/login'], {queryParams: { returnUrl: state.url }});
        return false;
  
  }
}
