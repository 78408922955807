<div *ngIf="activeEditCategory" class="-mx-3">
 <charge-category-create [activeCategory]="activeEditCategory" (changed)="changedEditCategory($event)" (close)="activeEditCategory=null"></charge-category-create>
</div>

<div [ngClass]="{ hidden: activeEditCategory }">
  <div
    class="
      md:pl-4
      pl-3
      pr-5
      py-3
      flex
      align-items-center
      relative
      border-bottom-1 border-gray-300
      -mx-3
    "
    [ngClass]="{ hidden: activeEditCategory }"
  >
    <div
      class="flex align-items-center"
      [ngClass]="{ hidden: activeEditCategory }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-tool mr-2 text-gray-500"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path
          d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5"
        ></path>
      </svg>
      <h2 class="mb-0 text-base md:text-lg font-normal">
        {{ 'ADMIN.CHARGE.MANAGE_CHARGE_CATEGORY' | translate }}
      </h2>
    </div>
    <div
      class="
        pi pi-times
        opacity-50
        absolute
        right-0
        top-0
        mt-3
        pt-1
        mr-3
        md:mr-4
        cursor-pointer
      "
      (click)="closeDialog()"
    ></div>
  </div>
  <!-- Header -->

  <!-- Content -->
  <div
    [ngClass]="{ hidden: activeEditCategory }"
    class="px-4 py-3 text-sm -mx-3"
  >
    <div class="text-base">
      {{ 'ADMIN.COMMON.TOTAL' | translate }} -
      <strong>{{ tableData.getValue().length }}</strong>
      {{ 'ADMIN.CHARGE.CHARGE_CATEGORY' | translate }}
    </div>
    <div class="shadow-2 p-0 my-3">
      <div class="p-field p-col-12 p-md-6" *ngIf="loading | async">
        <p-table [value]="[0, 1, 2, 3, 4, 5, 6]">
          <ng-template pTemplate="header">
            <tr>
              <th>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </th>
              <th>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </th>
              <th>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </th>
              <th>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </th>
              <th>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </th>
              <th>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-items>
            <tr>
              <td>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </td>
              <td>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </td>
              <td>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </td>
              <td>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </td>
              <td>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </td>
              <td>
                <p-skeleton
                  animation="wave"
                  width="100%"
                  height="2rem"
                ></p-skeleton>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <app-data-grid
        [hidden]="loading | async"
        [columnData]="tableData"
        [columns]="columns"
        [totalRecords]="totalRecordLength"
        [paginator]="false"
        [EmptyState]="emptyStateComponent$"
        [selectable]="selectAllAppCatData"
        (onAction)="onActionEvent($event)"
      ></app-data-grid>
    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>
