<ng-container *ngIf="{  row: columnData | async} as $">
  <div>


    <button *ngIf="$.row && $.row.status.toLowerCase() == 'scheduled'" pButton pRipple type="button" icon="pi pi-check"
      class="p-button-rounded p-button-outlined p-0 w-2rem h-2rem text-sm mx-auto" (click)="SetOutComeDetails($.row);">
    </button>

    <!-- Selected State -->
    <button *ngIf="$.row && $.row.status.toLowerCase() == 'completed'" pButton pRipple type="button" icon="pi pi-check"
      class="p-button-rounded p-0 w-2rem h-2rem text-sm mx-auto">
    </button>
    <!-- Selected State -->

    <app-call-outcome [visible]="showCallOutcomePopup" [data]="outcomeDetail" (hideOutCome)="HideOutComePopup($event)">
    </app-call-outcome>

    <app-tour-outcome [visible]="showMeetingOutcomePopup" [tourOutComeData]="outcomeDetail"
      (hideOutCome)="HideOutComePopup($event)" [data]="$.row">
    </app-tour-outcome>

  </div>
</ng-container>
