import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import {DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'confirm-category-dialog',
  templateUrl: './confirm-category.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmCategoryComponent implements OnInit {

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
   }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [ConfirmCategoryComponent],
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    FormsModule],
  exports: [ConfirmCategoryComponent]
})
export class ConfirmCategoryComponentModule {
}
