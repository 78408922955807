    export interface AppointmentSubType {
        id: number;
        company_id: number;
        tenant_id: number;
        created_by: number;
        created_date: Date;
        updated_by: number;
        updated_date: Date;
        appt_type_id: number;
        name_primary_language: string;
        name_secondary_language: string;
        description_primary_language: string;
        description_secondary_language: string;
        things_to_carry_primary_language: string;
        things_to_carry_secondary_language: string;
        status: string;
    }

    export interface ListAppointmentSubTypeResponse {
        items: AppointmentSubType[];
    }

    export interface AppointmentSubTypeStore {
        appointmentSubTypes: AppointmentSubType[]
     };




