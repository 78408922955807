import { Component, OnInit, NgModule, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { CalendarModule } from 'primeng/calendar';
import { Client, CreateRecurringTaskByClientRequest, CreateRecurringTaskByOpportunityRequest, CreateTaskByClientRequest, CreateTaskByOpportunityRequest, RepeatRuleResponse, TENANTSINTERFACE } from '@fitness-force/models';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TaskAssignToType, TaskPriority, TaskType } from '@fitness-force/enum';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { FooterComponentModule, HeaderComponentModule, SidebarComponentModule } from '@fitness-force/core';
import { IntercepterService } from 'libs/api/src/lib/intercepter.service';

import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DividerModule } from 'primeng/divider';
import { ListboxModule } from 'primeng/listbox';

import { COMPANY_INFO, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { AssignedToComponentModule } from '../../staff/assigned-to/assigned-to.component';
import { RruleComponentModule } from '../../rrule/rrule.component';
import { MiniSearchComponentModule } from '../../search/mini-search/mini-search.component';
import { TaskCategoryDropdownComponentModule } from '../../crm/task-category-dropdown/task-category-dropdown.component';
import { TaskTypeDropdownComponentModule } from '../../crm/task-type-dropdown/task-type-dropdown.component';
import { PriorityComponentModule } from '../../crm/priority/priority.component';
import { BehaviorSubject } from 'rxjs';
import { AuthSevices, TaskService } from '@fitness-force/api';
import { ErrorsComponentModule } from '@fitness-force/shared';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TextTransFormPipeModule } from '../../../filters/text-transform.pipe';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { AvatarModule } from 'primeng/avatar';
import { ChipModule } from 'primeng/chip';

@Component({
  selector: 'task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss'],
  providers: [MessageService],
})
export class TaskCreateComponent implements OnInit {
  @Input('visible') showPopup: boolean = false;

  @Input('cltIds') clientIds: number[] = [];
  @Input('oppIds') opportunityIds: number[] = [];

  @Input('staffId') assignedToStaffId: number = 0;
  @Input('clientGroup') clientGroup: any[] = [];

  @Input('opportunity') opportunity: any;
  @Input('clientInfo') clientInfo: any = []; //this is not required this has to be removed

  @Output('hide') HidePopup: EventEmitter<boolean> = new EventEmitter();

  clientIds$ = new BehaviorSubject<number[]>([]);

  public clients: Client[] = [];
  showMiniSearch: boolean = false;
  showRRule: boolean = false;
  tenantId: number = 0;
  formGroup: FormGroup;
  rRuleObj: RepeatRuleResponse = {} as RepeatRuleResponse;
  showErrorPopup = new BehaviorSubject(false);
  errorMessages = [] as any;
  languageCode: string = '';
  currentUserDetails: any;
  assignedToId = new BehaviorSubject(0);
  showAllMembers = false;


  constructor(
    public fb: FormBuilder,
    private store: Store,
    public translate: TranslateService,
    public taskService: TaskService,
    private messageService: MessageService,
    private authService: AuthSevices,
    private ngZone: NgZone
    // private cd: ChangeDetectorRef
  ) {
    this.formGroup = this.fb.group({
      selectedClients: new FormControl('', [Validators.required]),
      selectedTaskCategory: new FormControl('', [Validators.required]),
      selectedTask: new FormControl('', [Validators.required]),
      selectedPriority: new FormControl('', [Validators.required]),
      selectedAssigned: new FormControl('', [Validators.required]),
      scheduleDate: new FormControl('', [Validators.required]),
      scheduleTime: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required]),
    });
    this.setVariables();
  }

  ngOnInit(): void {
    this.setVariables();
    this.store.select(COMPANY_INFO).subscribe((responseData) => {
      this.languageCode = responseData.selectedLang.LANG_CODE;
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });
    this.currentUserDetails = this.authService.currentUserDetails();
    this.assignedToId.next(this.currentUserDetails.Id);
    if (this.opportunity && this.opportunity?.client_info) {
      this.clientIds$.next([this.opportunity?.client_info.id]);

      this.opportunityIds.push(this.opportunity.id);

      this.clientIds = [];
      this.clientIds.push(this.opportunity?.client_info.id);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.clientIds?.currentValue) {
      this.clientIds = changes?.clientIds?.currentValue;
      this.clientIds$.next([...new Set(this.clientIds)]);
    } else {
      this.clientIds = [];
      this.clientIds$.next([]);
    }
    // if (this.clientInfo && this.clientInfo.length > 0 && changes.clientInfo.currentValue) {
    //   this.clients = changes.clientInfo.currentValue;
    //   this.clientGroup = this.clients;
    //   this.showMiniSearch = true;
    // }
    // if (this.opportunity && changes.opportunity.currentValue) {
    //   this.clientIds = [];
    //   this.opportunityIds.push(this.opportunity.id);
    //   this.clientIds.push(this.opportunity?.client_info.id);
    //   this.opportunityIds.push(this.opportunity?.id);
    //   this.showMiniSearch = false;
    //   this.formGroup.controls.selectedClients.setValue(this.clientIds);
    // }
    // if (this.clientGroup && this.clientGroup?.length > 0 && changes?.clientGroup?.currentValue) {
    //   this.clientIds = [];
    //   this.clientGroup.forEach((val) => {
    //     this.clientIds.push(val.id);
    //   })
    //   this.showMiniSearch = false;
    //   this.formGroup.controls.selectedClients.setValue(this.clientIds);
    // }
  }

  //#region get value
  get SelectedTaskValue(): any {
    let val: any = this.SelectedTask.value;
    let taskType: any;
    if (val && val.value) {
      switch (val.value) {
        case 'email': taskType = TaskType.Email; break;
        case 'sms': taskType = TaskType.Sms; break;
        case 'call': taskType = TaskType.Call; break;
        case 'task': taskType = TaskType.Task; break;
        case 'tour': taskType = TaskType.Tour; break;
      }
    }
    return taskType;
  }

  get SelectedPriorityValue(): any {
    let val: any = this.SelectedPriority.value;
    let priority: any;
    if (val && val.value) {
      switch (val.value) {
        case 'high': priority = TaskPriority.High; break;
        case 'medium': priority = TaskPriority.Med; break;
        case 'low': priority = TaskPriority.low; break;
      }
    }
    return priority;
  }

  get SelectedClients(): number[] {
    return this.formGroup.controls.selectedClients.value || [];
  }

  get ScheduledDateTimeValue(): Date {
    let d: Date = this.ScheduleTime.value;
    return new Date(this.ScheduleDateValue.getFullYear(), this.ScheduleDateValue.getMonth(), this.ScheduleDateValue.getDate(), d.getHours(), d.getMinutes());
  }

  get ScheduleDateValue(): Date {
    let d: Date = this.ScheduleDate.value;
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
  }

  get ScheduleTimeValue(): string {
    let d: Date = this.ScheduleTime.value;
    return d.getHours() + ':' + d.getMinutes()
  }
  //#region get value


  //#region get
  get SelectedTaskCategory(): FormControl {
    return this.formGroup.controls.selectedTaskCategory as FormControl;
  }

  get SelectedTask(): FormControl {
    return this.formGroup.controls.selectedTask as FormControl;
  }

  get SelectedPriority(): FormControl {
    return this.formGroup.controls.selectedPriority as FormControl;
  }

  get SelectedAssigned(): FormControl {
    return this.formGroup.controls.selectedAssigned as FormControl;
  }

  get ScheduleDate(): FormControl {
    return this.formGroup.controls.scheduleDate as FormControl;
  }

  get ScheduleTime(): FormControl {
    return this.formGroup.controls.scheduleTime as FormControl;
  }

  get Title(): FormControl {
    return this.formGroup.controls.title as FormControl;
  }

  get Comment(): FormControl {
    return this.formGroup.controls.comment as FormControl;
  }

  get RRuleData(): RepeatRuleResponse {
    return this.rRuleObj || {} as RepeatRuleResponse;
  }

  get isRruleSet(): boolean {
    return (this.rRuleObj && this.rRuleObj.RRule && this.rRuleObj.RRule) ? true : false;
  }

  //#endregion get

  clearRRuleData() {
    this.rRuleObj = {} as RepeatRuleResponse;
  }

  setClient(e: any) {
    if (Array.isArray(e)) {
      this.clients = e;
    }
    else if (e && e.clt_id > 0) {
      this.clients = [e];
    }
    else if (e && e.id) {
      this.clients = [e];
    }
    else {
      this.clients = [];
    }

    let clientIds: number[] = this.clients.map((c) => { return c.clt_id || (c as any)?.id });
    this.formGroup.controls.selectedClients.setValue(clientIds);
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
  }

  ClosePopUp() {
    this.ngZone.run(() => {      
      this.formGroup.reset();
      this.clearRRuleData();
      this.showPopup = false;
      this.HidePopup.emit(this.showPopup);
    });
  }

  setVariables(): void {
    this.showMiniSearch = this.clients.length == 0;
    if (this.clientGroup) {
      this.clientIds = [];
      this.clientGroup.forEach((val) => {
        this.clientIds.push(val.id);
      })
      this.showMiniSearch = false;
      this.formGroup.controls.selectedClients.setValue(this.clientIds);

    }
    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' })
      }
    });

    if (this.clientIds && this.clientIds.length > 0) {
      this.formGroup.controls.selectedClients.setValue(this.clientIds);
    }

    if (this.opportunityIds.length > 0 && this.clientIds.length == 0)
      this.clientIds = [0, 0];
  }

  HideRRule(e: any): void {
    this.showRRule = e;
  }

  FetchRRuleData(e: any): void {
    this.rRuleObj = e;
  }

  CreateTask(): void {
    this.formGroup.markAllAsTouched();
    this.markAllControlsAsDirty(Object.values(this.formGroup.controls));

    if (this.opportunityIds.length > 0 && this.clientIds.length == 0) {
      this.errorMessages.push('client required');
      this.showErrorPopup?.next(true);
      return;
    }

    if (this.rRuleObj && this.rRuleObj.RRule) {
      if (this.opportunityIds.length > 0)
        this.CreateRecurringTaskByOpportunity();
      else
        this.CreateRecurringTaskByClient();
    }
    else {
      if (this.opportunityIds.length > 0)
        this.CreateTaskByOpportunity();
      else
        this.CreateTaskByClient();
    }
  }

  CreateTaskByClient(): void {
    let obj: CreateTaskByClientRequest = {
      client_id: this.SelectedClients,
      assigned_to_type: TaskAssignToType.SpecificStaff,
      assigned_to_staff_id: this.SelectedAssigned.value.id,
      type: this.SelectedTaskValue,
      category_id: this.SelectedTaskCategory.value.id,
      title: this.Title.value,
      notes: this.Comment.value,
      priority: this.SelectedPriorityValue,
      scheduled_date: this.ScheduledDateTimeValue
    }

    this.taskService.TaskCreateServiceByClient(this.tenantId, obj).subscribe({
      next: (obj: any) => {

        if (obj.task_id.length > 0) {
          this.translate.get('Shared.TASK.TASK-CREATE.TASK_CREATED_MESSAGE').subscribe((translation) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: translation, });
          });
        }
        else {
          this.errorMessages.push('Something went wrong');
          this.showErrorPopup?.next(true);
          throw new Error('Error on creating prospect.');
        }
      },
      error: (error: any) => {
        if (error instanceof NotFoundError) {
          this.errorMessages.push(error.originalError.error.errorMessage);
          this.showErrorPopup?.next(true);
        } else {
          if (error.originalError.status == 500) {
            this.errorMessages.push(error.originalError.error);
            this.showErrorPopup?.next(true);
          } else {
            error.originalError.error.forEach((e: any) => {
              this.errorMessages.push(e.errorMessage);
            });
            this.showErrorPopup?.next(true);
          }
        }
      }
    });
  }

  CreateRecurringTaskByClient(): void {
    let obj: CreateRecurringTaskByClientRequest = {
      client_id: this.SelectedClients,
      assigned_to_type: TaskAssignToType.SpecificStaff,
      assigned_to_staff_id: this.SelectedAssigned.value.id,
      type: this.SelectedTaskValue,
      category_id: this.SelectedTaskCategory.value.id,
      title: this.Title.value,
      notes: this.Comment.value,
      priority: this.SelectedPriorityValue,
      start_date: this.ScheduleDateValue,
      time: this.ScheduleTimeValue,
      repeat_rule: this.rRuleObj.RRule,
    }

    this.taskService.TaskCreateRecurringServiceByClient(this.tenantId, obj).subscribe({
      next: (obj: any) => {
        if (obj.recurring_template_id.length > 0) {
          this.translate.get('Shared.TASK.TASK-CREATE.TASK_CREATED_MESSAGE').subscribe((translation) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: translation });
          });
        }
        else {
          this.errorMessages.push('Something went wrong');
          this.showErrorPopup?.next(true);
          throw new Error('Error on creating prospect.');
        }
      },
      error: (error: any) => {
        if (error instanceof NotFoundError) {
          this.errorMessages.push(error.originalError.error.errorMessage);
          this.showErrorPopup?.next(true);
        } else {
          if (error.originalError.status == 500) {
            this.errorMessages.push(error.originalError.error);
            this.showErrorPopup?.next(true);
          } else {
            error.originalError.error.forEach((e: any) => {
              this.errorMessages.push(e.errorMessage);
            });
            this.showErrorPopup?.next(true);
          }
        }
      }
    });
  }

  CreateTaskByOpportunity(): void {
    let obj: CreateTaskByOpportunityRequest = {
      opportunity_id: this.opportunityIds,
      assigned_to_type: TaskAssignToType.SpecificStaff,
      assigned_to_staff_id: this.SelectedAssigned.value.id,
      type: this.SelectedTaskValue,
      category_id: this.SelectedTaskCategory.value.id,
      title: this.Title.value,
      notes: this.Comment.value,
      priority: this.SelectedPriorityValue,
      scheduled_date: this.ScheduledDateTimeValue
    }

    this.taskService.TaskCreateServiceByOpportunity(this.tenantId, obj).subscribe({
      next: (obj: any) => {

        if (obj.task_id.length > 0) {
          this.translate.get('Shared.TASK.TASK-CREATE.TASK_CREATED_MESSAGE').subscribe((translation) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: translation });
          });
        }
        else {
          this.errorMessages.push('Something went wrong');
          this.showErrorPopup?.next(true);
          throw new Error('Error on creating prospect.');
        }
      },
      error: (error: any) => {
        if (error instanceof NotFoundError) {
          this.errorMessages.push(error.originalError.error.errorMessage);
          this.showErrorPopup?.next(true);
        } else {
          if (error.originalError.status == 500) {
            this.errorMessages.push(error.originalError.error);
            this.showErrorPopup?.next(true);
          } else {
            error.originalError.error.forEach((e: any) => {
              this.errorMessages.push(e.errorMessage);
            });
            this.showErrorPopup?.next(true);
          }
        }
      }
    });
  }

  CreateRecurringTaskByOpportunity(): void {
    let obj: CreateRecurringTaskByOpportunityRequest = {
      opportunity_id: this.opportunityIds,
      assigned_to_type: TaskAssignToType.SpecificStaff,
      assigned_to_staff_id: this.SelectedAssigned.value.id,
      type: this.SelectedTaskValue,
      category_id: this.SelectedTaskCategory.value.id,
      title: this.Title.value,
      notes: this.Comment.value,
      priority: this.SelectedPriorityValue,
      start_date: this.ScheduleDateValue,
      time: this.ScheduleTimeValue,
      repeat_rule: this.rRuleObj.RRule,
    }

    this.taskService.TaskCreateRecurringServiceByOpportunity(this.tenantId, obj).subscribe({
      next: (obj: any) => {

        if (obj.recurring_template_id.length > 0) {
          this.translate.get('Shared.TASK.TASK-CREATE.TASK_CREATED_MESSAGE').subscribe((translation) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: translation, });
          });
        }
        else {
          this.errorMessages.push('Something went wrong');
          this.showErrorPopup?.next(true);
          throw new Error('Error on creating prospect.');
        }
      },
      error: (error: any) => {

        if (error instanceof NotFoundError) {
          this.errorMessages.push(error.originalError.error.errorMessage);
          this.showErrorPopup?.next(true);
        } else {
          if (error.originalError.status == 500) {
            this.errorMessages.push(error.originalError.error);
            this.showErrorPopup?.next(true);
          } else {
            error.originalError.error.forEach((e: any) => {
              this.errorMessages.push(e.errorMessage);
            });
            this.showErrorPopup?.next(true);
          }
        }
      }
    });
  }

  onRemoveClient(index: number) {
    if (this.clientGroup[index])
      this.clientGroup.splice(index, 1);
    // this.cd.detectChanges();
  }

  /**
   * Code used for debugging only
   */
  findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  public markAllControlsAsDirty(abstractControls: AbstractControl[]): void {
    abstractControls.forEach(abstractControl => {
      if (abstractControl instanceof FormControl) {
        (abstractControl as FormControl).markAsDirty({ onlySelf: true });
      } else if (abstractControl instanceof FormGroup) {
        this.markAllControlsAsDirty(Object.values((abstractControl as FormGroup).controls));
      } else if (abstractControl instanceof FormArray) {
        this.markAllControlsAsDirty((abstractControl as FormArray).controls);
      }
    });
  }
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}
@NgModule({
  declarations: [TaskCreateComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FooterComponentModule,
    HeaderComponentModule,
    SidebarComponentModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    StyleClassModule,
    CommonModule,
    CalendarModule,
    MultiSelectModule,
    SelectButtonModule,
    InputMaskModule,
    InputSwitchModule,
    InputSwitchModule,
    ScrollPanelModule,
    ToastModule,
    DividerModule,
    RruleComponentModule,
    AssignedToComponentModule,
    MiniSearchComponentModule,
    ErrorMsgComponentModule,
    TaskCategoryDropdownComponentModule,
    TaskTypeDropdownComponentModule,
    PriorityComponentModule,
    ErrorsComponentModule,
    TextTransFormPipeModule,
    AvatarModule,
    ChipModule,
    ShortNamePipeModule,
    ListboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: IntercepterService, multi: true },
  ],
  exports: [TaskCreateComponent]
})
export class TaskCreateComponentModule { }
