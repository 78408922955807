import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'task-type',
  templateUrl: './task-type.component.html',
  styleUrls: ['./task-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskTypeComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)
  taskTypeIcons = [
    { type: 'call', icon: 'pi pi-phone' },
    { type: 'meeting', icon: 'pi pi-users' },
    // {type: 'email', icon: 'pi pi-envelope' },
    // {type:'sms', icon:'pi pi-comments'},
    { type: 'tour', icon: 'pi pi-map-marker' }
  ]
  constructor() { }

  ngOnInit(): void {

  }

  getIconName(type: string) {
    const iconsTempBuffer = this.taskTypeIcons.find((icons: any) => icons.type === type.toLowerCase())
    return iconsTempBuffer?.icon
  }
}

@NgModule({
  declarations: [TaskTypeComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    StyleClassModule
  ],
  exports: [TaskTypeComponent]
})
export class TaskTypeComponentModule {
}
