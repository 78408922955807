import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpportunityBoardType } from '@fitness-force/enum';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { throwError, Observable, pipe } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { CreateCallOutcomeRequest, CreateRecurringTaskByClientRequest, CreateRecurringTaskByOpportunityRequest, CreateTaskByClientRequest, CreateTaskByOpportunityRequest, CreateTourOutcomeRequest, UpdateTask, TASK_TYPE, CreateResponse } from '@fitness-force/models';
import { CONST_URL_SEGEMENTS } from "@fitness-force/constants";

@Injectable({
  providedIn: 'root'
})

export class TaskService {
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  TaskGetAll(tenantId: number, allParams: Array<TASK_TYPE>): Observable<Response> {

    let parsedParams = new HttpParams()
   if(allParams.length > 0){

    allParams.forEach((p: any) => {
      parsedParams = parsedParams.append(p.paramName, p.paramValue);
    });
   }


   const url = environment.BASE_URL + CONST_URL_SEGEMENTS.TASK.GET_TASK.replace('{tenant_id}', tenantId.toString());
    return this.http.get(url,  {headers: this.httpOptions.headers , params: parsedParams}).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError());
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  TaskCreateServiceByClient(tenantId: number, createTaskByClientRequest: CreateTaskByClientRequest): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.TASK.POST_TASK_BY_CLIENT.replace('{tenant_id}', tenantId.toString());
    return this.http.post(url, createTaskByClientRequest, this.httpOptions).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError());
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  TaskCreateRecurringServiceByClient(tenantId: number, createRecurringTaskByClientRequest: CreateRecurringTaskByClientRequest): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.TASK.POST_RECURRING_TASK_BY_CLIENT.replace('{tenant_id}', tenantId.toString());
    return this.http.post(url, createRecurringTaskByClientRequest, this.httpOptions).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError());
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  TaskCreateServiceByOpportunity(tenantId: number, createTaskByOpportunityRequest: CreateTaskByOpportunityRequest): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.TASK.POST_TASK_BY_OPPORTUNITY.replace('{tenant_id}', tenantId.toString());
    return this.http.post(url, createTaskByOpportunityRequest, this.httpOptions).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError());
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  TaskCreateRecurringServiceByOpportunity(tenantId: number, createRecurringTaskByOpportunityRequest: CreateRecurringTaskByOpportunityRequest): Observable<Response> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.TASK.POST_RECURRING_TASK_BY_OPPORTUNITY.replace('{tenant_id}', tenantId.toString());
    return this.http.post(url, createRecurringTaskByOpportunityRequest, this.httpOptions).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError());
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  CreateLogCallOutcomeService(tenantId: number, taskId: number, createCallOutcomeRequest: CreateCallOutcomeRequest): Observable<Response> {
    return this.http.post(`${environment.BASE_URL}/tenants/${tenantId}/opportunity${CONST_URL_SEGEMENTS.TASK.CREATE_CALL_OUTCOME}`, this.httpOptions).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError());
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  CreateLogTourOutcomeService(tenantId: number, taskId: number, createTourOutcomeRequest: CreateTourOutcomeRequest): Observable<Response> {
    return this.http.post(`${environment.BASE_URL}/tenants/${tenantId}/opportunity${CONST_URL_SEGEMENTS.TASK.CREATE_TOUR_OUTCOME}`, this.httpOptions).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError());
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  UpdateTaskService(tenantId: number, taskId: number, updateTask: UpdateTask): Observable<CreateResponse> {
    return this.http.put(`${environment.BASE_URL}/tenants/${tenantId}/tasks/${taskId}`, updateTask, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
        , catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError());
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  GetTask(tenantId: number, taskId: number): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/tenants/${tenantId}/tasks/${taskId}`, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
        , catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError());
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
