import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'entity-status',
  templateUrl: './entity-status.component.html',
  styleUrls: ['./entity-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityStatusComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
@Input() additionData = new BehaviorSubject(null);

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [EntityStatusComponent],
  imports: [CommonModule,TagModule],
  exports: [EntityStatusComponent]
})
export class EntityStatusComponentModule {
}
