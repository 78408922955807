import { RouterReducerState } from '@ngrx/router-store';
import { ROUTER_SERIALIZER_INTERFACE } from './../../../../../libs/models/src/lib/router-serializer';

import { CLAIMSSTATEINTERFACE, CompanyInfoStoreInterface, ReportListResponse,
   STATE_STAFF_LIST, STAGE_MASTER_RESPONSE_MODEL, TENANTSINTERFACE, Countries, Country, States, Cities, ListServiceTypeResponse, AppointmentTypeStore, ClassTypeStore, RoomStore, LocationStore } from "@fitness-force/models";
import { createFeatureSelector, createSelector } from "@ngrx/store";

// Extended by lazy loaded modules

const getCompanyFeatureState = createFeatureSelector<CompanyInfoStoreInterface>('company');
export const COMPANY_INFO = createSelector(
  getCompanyFeatureState,
  state => state
);

const getClaimsState = createFeatureSelector<CLAIMSSTATEINTERFACE>('claims');
export const CLAIMS_INFO = createSelector(
  getClaimsState,
  state => state.claims
);

const getTenantsState = createFeatureSelector<TENANTSINTERFACE>('tenants');
export const TENANTS_INFO = createSelector(
  getTenantsState,
  state => state
);

// export const SELECTED_TENENT = createSelector(
//   getTenantsState,
//   state => state
// );

const getRouterState = createFeatureSelector<RouterReducerState<ROUTER_SERIALIZER_INTERFACE>>('router');
export const ROUTER_STATE = createSelector(
  getRouterState,
  router => router.state
);

const getStaffState = createFeatureSelector<STATE_STAFF_LIST>('staff');
export const STAFF_STATE = createSelector(
  getStaffState,
  state => state
);

const getReportState = createFeatureSelector<ReportListResponse[]>('report');
export const REPORT_STATE = createSelector(
  getReportState,
  state => state
);

const getStageMasterState = createFeatureSelector<STAGE_MASTER_RESPONSE_MODEL>('stages');
export const STAGE_MASTER_STATE = createSelector(
  getStageMasterState,
  state => state.stages
);

const getCountryMasterListState = createFeatureSelector<Countries>('countries');
export const COUNTRY_MASTER_STATE_LIST = createSelector(
  getCountryMasterListState,
  state => state.countries
);

const getCountryState = createFeatureSelector<Countries>('countries');
export const COUNTRY_MASTER_STATE = createSelector(
  getCountryState,
  state => state.country
);


const getAppointmentTypeState = createFeatureSelector<AppointmentTypeStore>('appointmentTypes');
export const APPOINTMENT_TYPE_STATE = createSelector(
  getAppointmentTypeState,
  state => state.appointmentTypes
);

const getClassTypeState = createFeatureSelector<ClassTypeStore>('classTypes');
export const CLASS_TYPE_STATE = createSelector(
  getClassTypeState,
  state => state.classTypes
);



const getStateMasterListState = createFeatureSelector<States>('states');
export const STATE_MASTER_STATE_LIST = createSelector(
  getStateMasterListState,
  state => state.states
);

const getState_State = createFeatureSelector<States>('states');
export const STATE_MASTER_STATE = createSelector(
  getState_State,
  state => state.state
);


const getCityMasterListState = createFeatureSelector<Cities>('cities');
export const CITY_MASTER_STATE_LIST = createSelector(
  getCityMasterListState,
  state => state.cities
);

const getCityState = createFeatureSelector<Cities>('cities');
export const CITY_MASTER_STATE = createSelector(
  getCityState,
  state => state.city
);

const getServiceTypeListState = createFeatureSelector<ListServiceTypeResponse>('serviceTypeList');
export const SERVICETYPE_LIST_STATE = createSelector(
  getServiceTypeListState,
  state => state.items
);

const getRoomListState = createFeatureSelector<RoomStore>('rooms');
export const ROOM_LIST_STATE = createSelector(
  getRoomListState,
  state => state.rooms
);

const getLocationState = createFeatureSelector<LocationStore>('location');
export const LOCATION_STATE = createSelector(
  getLocationState,
  state => state.location
);