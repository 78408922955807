<!-- <div [formGroup]="formGroup">
    <p-multiSelect  *ngIf="taskCategory$ | async as tc" [options]="tc" optionLabel="name_primary_language" placeholder="{{placeHolder}}" styleClass="{{styleClass}}"
        [formControl]="controlName">
    </p-multiSelect>
</div> -->


<div [formGroup]="formGroup">
    <p-dropdown  *ngIf="taskCategory$ | async as tc" [options]="tc" optionLabel="name_primary_language" placeholder="{{placeHolder}}" styleClass="{{styleClass}}"
        [formControl]="controlName">
        <ng-template pTemplate="TaskCategory">
            <div class="text-sm" *ngIf="TaskCategory">{{TaskCategory.name_primary_language}}</div>
        </ng-template>
        <ng-template let-taskCategory pTemplate="item">
            <div class="text-sm">{{taskCategory.name_primary_language}}</div>
        </ng-template>
    </p-dropdown>
</div>