<div class="bg-white py-2 px-4 absolute left-0 bottom-0 w-full">
  <div class="flex align-items-center">
    <ng-container *ngIf="companyInfoDetail$ | async as companyInfoDetail">
      <!-- Left Side -->
      <div class="w-12 xl:w-4 flex align-items-center justify-content-center xl:justify-content-start">
        <span class="mr-2"><img class="footer-logo" src="assets/img/fitness-force.jpg" alt="Fitness Force" width="37"></span>
        <div>
          <p class="mb-1">© FitnessForce. v2.3.3</p>
          <ul class="list-inline flex list-none mb-0 text-xs opacity-70 pl-0 mt-0">
            <li class="mr-3"><a  *ngIf="companyInfoDetail.is_terms_of_service_url" [href]="companyInfoDetail.compDetails.terms_of_service_url" class="text-600 no-underline hover:text-primary">Terms of Use</a></li>
            <li><a  *ngIf="companyInfoDetail.compDetails.is_privacy_policy_url" [href]="companyInfoDetail.compDetails.privacy_policy_url" class="text-600 no-underline hover:text-primary">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <!-- Left Side -->

      <!-- Center Side -->
      <div class="lg:w-3 md:w-6 text-end text-lg-start hidden xl:inline-block">
      <button pButton pRipple label="Contact Support" icon="pi pi-envelope" (click)="showSupportPopup = true" class="text-sm"></button>
      </div>
      <!-- Center Side -->

      <!-- Right Side -->
      <div class="lg:w-5 md:w-12 lg:mt-0 mt-4 hidden xl:flex align-items-center justify-content-between">
        <div>Download your <strong>Staff app</strong> now!</div>
        <div class="d-flex">
          <a class="mr-2"[href]="companyInfoDetail.compDetails.member_app_ios_link"  target="_blank"><img width="50" src="assets/img/apple-logo.svg" alt="Apple" width="66"></a>
          <a class="mr-2" [href]="companyInfoDetail.compDetails.member_app_android_link"  target="_blank"><img width="50" src="assets/img/googleplay-logo.svg" alt="Google Play" width="66"></a>
          <span><img width="30" src="assets/img/footer-qr-code.jpg" alt="QR Code" width="38"></span>
        </div>
      </div>
      <!-- Right Side -->
      <app-help-support [visibility]="showSupportPopup" (closeDialog)="closeSupportDialog($event)"></app-help-support>
    </ng-container>
      

    </div>
</div>