import { createAction, props } from "@ngrx/store";

export const FETCH_STATE_MASTER_LIST = createAction(
    'FETCHSTATEMASTERLIST',
    props<{states:any}>()
);

export const FETCH_STATE_MASTER = createAction(
    'FETCHSTATEMASTER',
    props<{state:any}>()
);