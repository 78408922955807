export interface State {
    id: number,
    name: string,
    code: string,
    country_code: string,
    country_id: number
}

export interface States {
    state: State
    states: Array<State>
};