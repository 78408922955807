import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, ChangeDetectorRef } from '@angular/core';
import { PlanType } from '@fitness-force/enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'subscription-category-display',
  templateUrl: './subscription-category-display.component.html',
  styleUrls: ['./subscription-category-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionCategoryDisplayComponent implements OnInit {
  @Input() additionData = new BehaviorSubject(null);
  @Input() columnData  = new BehaviorSubject(null);
  type!: any;
  constructor(private cd: ChangeDetectorRef) { }
  ngOnInit() {
    this.columnData.subscribe({next:(item:any)=>{
      this.setPlanType(item);
    }});

  }

  setPlanType(cData:any) {
    this.type = Object.entries(PlanType).map(([key, value]) => ({ id: key, value: value })).filter((item: any) => {
      return item.id == cData.subscription_category;
    })[0].value;
  }
}

@NgModule({
  declarations: [SubscriptionCategoryDisplayComponent],
  imports: [CommonModule],
  exports: [SubscriptionCategoryDisplayComponent]
})
export class SubscriptionCategoryDisplayComponentModule {
}
