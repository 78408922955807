import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wrapped-column',
  templateUrl: './wrapped-column.component.html',
  styleUrls: ['./wrapped-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrappedColumnComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)

  constructor() {}

  ngOnInit(): void {

  }
}

@NgModule({
  declarations: [WrappedColumnComponent],
  imports: [CommonModule],
  exports: [WrappedColumnComponent],
})
export class WrappedColumnComponentModule {}
