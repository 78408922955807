import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  COMPANY_INFO,
  STAGE_MASTER_STATE,
  TENANTS_INFO,
} from 'apps/ff-frontend/src/app/state/app.state';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { InplaceModule } from 'primeng/inplace';
import { CONSTANTS_CRM } from '@fitness-force/constants';
import {
  ErrorsComponentModule,
  FactoryHttpLoader,
  LostDealComponentModule,
  TaskOpportunityInfoComponentModule,
} from '@fitness-force/shared';


import { CurrencyformatPipeModule } from '../../../filters/currencyformat.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';
import { TourOutcome } from '@fitness-force/enum';
import { TaskCallOutcomeService } from 'libs/api/src/lib/crm/taskoutcome.service';
import {
  AppErrors,
  ErrorMsgComponentModule,
  NotFoundError,
} from '@fitness-force/errors';
import {
  CreateFollowupTaskByDateRequest,
  CreateLogTourNotesRequest,
  CreateTourOutcomeRequest,
} from 'libs/models/src/lib/taskCallOutcome';
import { FormDatepickerComponentModule } from '@fitness-force/shared';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TabViewModule } from 'primeng/tabview';
import { HttpClient } from '@angular/common/http';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PlanSummary, PlanSummaryClient, PlanSummaryCRM, STAGE_MASTER_MODEL, TENANTSINTERFACE, ValidationError } from '@fitness-force/models';
import { ClientService, TimeconversionService } from '@fitness-force/api';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { BillingComponentModule } from '../../billing/billing/billing.component';

@Component({
  selector: 'app-tour-outcome',
  templateUrl: './tour-outcome.component.html',
  styleUrls: ['./tour-outcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class TourOutcomeComponent implements OnInit {
  @Input('visible') showMeetingOutcome: boolean = false;
  @Input('tourOutComeData') modelData: any;
  @Output() hideOutCome: EventEmitter<boolean> = new EventEmitter();
  @Output() modified: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('notintrested') notintrestedform: TemplateRef<any> | any;
  @ViewChild('noshow') noshowform: TemplateRef<any> | any;
  @ViewChild('reschedule') rescheduleform: TemplateRef<any> | any;
  @ViewChild('stillthinking') stillthinkingform: TemplateRef<any> | any;
  // @ViewChild('signup') signupform: TemplateRef<any>;

  frmgroupCreateTourOutCome: FormGroup;
  TourOutComeNotIntrestedForm: FormGroup;
  TourOutComeStillThinkingForm: FormGroup;
  TourOutComeNoShowForm: FormGroup;
  TourOutComeRescheduleForm: FormGroup;

  public companyInfoDetail$!: Observable<any>;

  companyDetails: any;
  convertibility: any = CONSTANTS_CRM.OPPORTUNITY_CONVERTITBILITY;
  selectedConvertibility!: any;
  outcome: any = CONSTANTS_CRM.TOUR_OUTCOME;
  values: string[] | undefined;
  followUpDate: Date | undefined;
  contractEndDate: Date | undefined;
  mylookupservice: any;
  selectedOutcome!: any;
  markcompletedchecked: boolean = false;
  showCalender: Observable<boolean> | any = false;
  selectedOutcomeTemplate: any;
  selectedDateTime: Date | any;
  selectedTime: string | any;
  errMsg: Error | any;
  tenantId: number | any;
  showErrorPopup = new BehaviorSubject(false);
  errorMessages = [] as any;
  errorDataModalSubject = new BehaviorSubject([] as ValidationError[]);
  errorDataModal = [] as ValidationError[];

  //#region billing sidebar
  planSummarydataObject$: BehaviorSubject<PlanSummary> = new BehaviorSubject({} as PlanSummary);
  showPaidFullSidebar: boolean = false;
  //#endregion billing sidebar

  showChooseReason: boolean = false;
  showLostDealDialog = false
  lostDealItemInfo = ''
  stageMaster$: Observable<any> = new Subject();


  constructor(
    public store: Store,
    public translate: TranslateService,
    private fb: FormBuilder,
    private tourOutcomeService: TaskCallOutcomeService,
    private timeService: TimeconversionService,
    private messageService: MessageService,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.TourOutComeNotIntrestedForm = this.fb.group({
      comments: ['', [Validators.required]],
    });

    this.TourOutComeStillThinkingForm = this.fb.group({
      comments: ['', [Validators.required]],
    });

    this.TourOutComeNoShowForm = this.fb.group({
      comments: ['', [Validators.required]],
    });

    this.TourOutComeRescheduleForm = this.fb.group({
      rescheduleDate: ['', [Validators.required]],
      followupTime: ['', [Validators.required]],
      comments: ['', [Validators.required]],
      taskcompleted: [this.markcompletedchecked, [Validators.required,]]
    });
    this.componentInit();
    this.setNoshowFormControlValidator();
  }

  componentInit() {
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO);
    this.companyInfoDetail$.subscribe((responseData) => {
      this.companyDetails = responseData;
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' })
      }
    })

    this.stageMaster$ = this.store.select(STAGE_MASTER_STATE);

  }

  MarkTourOutComeNotIntrested(formdata: FormGroup) {
    let createTourOutcomeRequest: CreateTourOutcomeRequest = {} as CreateTourOutcomeRequest;
    let logTour = {} as CreateLogTourNotesRequest;
    logTour.transaction_notes = formdata.value.comments;
    logTour.staff_id = this.modelData.assigned_to_staff_id;
    logTour.transaction_log_date = new Date().toISOString();
    createTourOutcomeRequest.tour_outcome = TourOutcome.NotInterested;
    createTourOutcomeRequest.mark_as_complete = true;
    createTourOutcomeRequest.book_trial = null;
    createTourOutcomeRequest.log_tour = logTour;

    this.CreateTourOutCome(createTourOutcomeRequest, formdata);
  }

  MarkTourOutComeNoShow(formdata: FormGroup) {
    let createTourOutcomeRequest: CreateTourOutcomeRequest = {} as CreateTourOutcomeRequest;
    let logTour = {} as CreateLogTourNotesRequest;
    logTour.transaction_notes = formdata.value.comments;
    logTour.staff_id = this.modelData.assigned_to_staff_id;
    logTour.transaction_log_date = new Date().toISOString();
    createTourOutcomeRequest.tour_outcome = TourOutcome.NoShow;
    createTourOutcomeRequest.mark_as_complete = true;
    createTourOutcomeRequest.book_trial = null;
    createTourOutcomeRequest.log_tour = logTour;
    this.CreateTourOutCome(createTourOutcomeRequest, formdata);
  }

  RescheduleTouroutcome(formdata: FormGroup) {
    let createTourOutcomeRequest: CreateTourOutcomeRequest = {} as CreateTourOutcomeRequest;
    let scheduleTask = {} as CreateFollowupTaskByDateRequest;
    scheduleTask.scheduled_datetime = this.timeService.GetUTCDateTimeByDateAndTime(formdata.value.rescheduleDate, formdata.value.followupTime);
    let logTour = {} as CreateLogTourNotesRequest;
    logTour.transaction_notes = formdata.value.comments;
    logTour.staff_id = this.modelData.assigned_to_staff_id;
    logTour.transaction_log_date = new Date().toISOString();
    createTourOutcomeRequest.tour_outcome = TourOutcome.Reschedule;
    createTourOutcomeRequest.mark_as_complete = this.markcompletedchecked;
    createTourOutcomeRequest.reschedule_task = scheduleTask;
    createTourOutcomeRequest.log_tour = logTour;
    this.CreateTourOutCome(createTourOutcomeRequest, formdata);
  }

  MarkTourOutComeStillThinking(formdata: FormGroup) {
    let createTourOutcomeRequest: CreateTourOutcomeRequest = {} as CreateTourOutcomeRequest;
    let logTour = {} as CreateLogTourNotesRequest;
    logTour.transaction_notes = formdata.value.comments;
    logTour.staff_id = this.modelData.assigned_to_staff_id;
    logTour.transaction_log_date = new Date().toISOString();
    createTourOutcomeRequest.tour_outcome = TourOutcome.StillThinking;
    createTourOutcomeRequest.mark_as_complete = true;
    createTourOutcomeRequest.book_trial = null;
    createTourOutcomeRequest.log_tour = logTour;

    this.CreateTourOutCome(createTourOutcomeRequest, formdata);
  }

  CreateTourOutCome(createTourOutcomeRequest: CreateTourOutcomeRequest, formdata: FormGroup) {
    this.tourOutcomeService
      .createTourOutcome(this.tenantId, this.modelData.latest_task.id, createTourOutcomeRequest)
      .subscribe({
        next: async (data: any) => {
          this.translate
            .get('OUTCOME.MESSGES.TASK_SUCCESS_MSG')
            .subscribe((translation) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: translation,
              });
            });

          await this.timeout(1000);
          //Show Opportunity loss floe on Popup , also will not open for opportunity
          if (createTourOutcomeRequest.tour_outcome == TourOutcome.NotInterested && this.modelData.stage_master_id) {
            this.lostDealItemInfo = this.modelData;
            this.showChooseReason = true;
          }
          else {
            this.ClearForm(formdata);
            this.setHidePopup();
          }
        },
        error: (error: AppErrors) => {
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
          } else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error.errorMessage);
              this.errorDataModal = this.errorMessages;
              this.showErrorPopup?.next(true);
            } else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage);
              });
              this.errorDataModal = this.errorMessages;
              this.showErrorPopup?.next(true);
            }
          }
        },
      });
  }

  ClearForm(formName: FormGroup) {
    this.showMeetingOutcome = false;
    this.selectedOutcome = null;
    this.selectedOutcomeTemplate = null;
    formName.reset();
  }

  setHidePopup() {
    this.showMeetingOutcome = false;
    this.hideOutCome.emit(this.showMeetingOutcome);
    this.selectedOutcome = null;
    this.TourOutComeNoShowForm.reset();
    this.TourOutComeRescheduleForm.reset();
    this.TourOutComeNotIntrestedForm.reset();
  }

  SelectOutcome(item: any) {
    this.selectedOutcome = item;
    if (item.shortname == 'noshow')
      this.selectedOutcomeTemplate = this.noshowform;
    if (item.shortname == 'notintrested')
      this.selectedOutcomeTemplate = this.notintrestedform;
    if (item.shortname == 'reschedule')
      this.selectedOutcomeTemplate = this.rescheduleform;
    if (item.shortname == 'stillthinking')
      this.selectedOutcomeTemplate = this.stillthinkingform;
    if (item.shortname == 'signingup') {
      // this.selectedOutcomeTemplate = this.signupform;
      let draggedProduct = this.modelData;
      let planSummary: PlanSummary = {
        id: 0,
        client: {
          client_id: draggedProduct.client_info.id,
          client_name: draggedProduct.client_info.full_name,
          client_status: draggedProduct.client_info.status,
        } as PlanSummaryClient,
        couponcode: null,
        tenant_id: this.tenantId,
        company_id: draggedProduct.company_id,
        startdate: new Date(),
        plan_selectable: true,
        CRM: {
          opp_id: draggedProduct.id,
          task_id: draggedProduct.latest_task?.id || 0,
          board_id: draggedProduct.board_type
        } as PlanSummaryCRM,
        Scheduling: null,
      } as PlanSummary;

      this.planSummarydataObject$.next(planSummary);
      this.showPaidFullSidebar = true;
    }
  }

  hideBillingSummary(event: any) {
    this.showPaidFullSidebar = false;
    this.planSummarydataObject$.next({} as PlanSummary);
    if (event.uuid && String(event.uuid).trim() != '') {
      this.setHidePopup();
    }
  }

  SelectOutcomeMob(item: any) {
    this.SelectOutcome(item.value);
  }

  clickMarkcompletedChecked(e: any) {
    this.markcompletedchecked = e.checked;
  }


  setNoshowFormControlValidator() {
    this.TourOutComeRescheduleForm.controls.taskcompleted.valueChanges.subscribe(
      (value: boolean) => {
        let rescheduleDateFormcontrol = this.TourOutComeRescheduleForm.controls.rescheduleDate as FormControl;
        let followupTimeFormcontrol = this.TourOutComeRescheduleForm.controls.followupTime as FormControl;
        if (value) {
          rescheduleDateFormcontrol.setValidators(null);
          followupTimeFormcontrol.setValidators(null);
          rescheduleDateFormcontrol.setValue('');
          followupTimeFormcontrol.setValue('');
          rescheduleDateFormcontrol.disable();
          followupTimeFormcontrol.disable();
        } else {
          rescheduleDateFormcontrol.setValidators([Validators.required]);
          followupTimeFormcontrol.setValidators([Validators.required]);
          rescheduleDateFormcontrol.enable();
          followupTimeFormcontrol.enable();
        }
      }
    );
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
  }

  GetErrorData(event: any) {
    this.errorMessages = event.errors;
    this.showErrorPopup?.next(event.showErrors);
  }

  get ShowErrorPopup(): Observable<boolean> {
    return this.showErrorPopup?.asObservable() || of(false);
  }

  GetUpdatedData(event: any) {
    this.modelData = event;
    this.modified.emit(true);
  }

  get getoutcome(): any {
    return this.selectedOutcomeTemplate;
  }

  get SelectedConvertibility(): FormControl {
    return this.frmgroupCreateTourOutCome.controls.ddlConvertibility as FormControl;
  }

  closeDailog() {
    this.showChooseReason = false;
    this.showLostDealDialog = false;
  }

  OpenLostDeal(modalData: any) {
    this.showChooseReason = true;
    this.lostDealItemInfo = modalData;
  }

  clientStatus(client: any): string {
    if (client && client.status)
      return this.clientService.getClientStatusHtml(client.status.toString());
    else
      return '';
  }

  getStageStatus(stageMasterid: number): Observable<string | undefined> {
    return this.store.select(STAGE_MASTER_STATE).pipe(
      map(
        (value: STAGE_MASTER_MODEL[]) =>
          value.find(obj => obj.id == stageMasterid)?.name_primary_language

      )
    );
  }

  timeout(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}


@NgModule({
  declarations: [TourOutcomeComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    InputTextModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ChipsModule,
    ChipModule,
    InplaceModule,
    TaskOpportunityInfoComponentModule,
    CalendarModule,
    InputSwitchModule,
    StyleClassModule,
    FormDatepickerComponentModule,
    ErrorMsgComponentModule,
    InputTextareaModule,
    TabViewModule,
    ErrorMsgComponentModule,
    ErrorsComponentModule,
    ToastModule,
    CurrencyformatPipeModule,
    BillingComponentModule,
    LostDealComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TourOutcomeComponent],
})
export class TourOutcomeComponentModule { }
