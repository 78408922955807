export enum PlanCategory {
  PayAsYouGo = 1,
  PaidInFull = 2,
  DropIn = 3,
  Contract = 4,
  ContractConvertsToPayAsYouGo = 5
};

export enum PlanType {
  PayAsYouGo = "Pay as you go",
  PaidInFull = "Paid in full",
  DropIn = "Drop-in",
  Contract = "Contract",
  ContractConvertsToPayAsYouGo = "Pay as you go with Contract"
}

export enum PlanIntervalType {
  Day=0,
  Week = 1,
  Month = 2,
}

export enum PlanFreezeType {
  NoFreeze = "No Freeze",
  FreeFreeze = "Free Freeze",
  PaidFreeze = "Paid Freeze"
}

export enum SignUpStatus {
  Created = 1,
  Open = 2,
  WaiverDone = 3,
  MandatoryInformationDone = 4,
  AwaitingPayment = 5,
  SignUpSuccessfull = 6,
  SignUpFailed = 7,
  SignUpExpired = 8
}

export enum SignUpFinalStatus {
  Open = 0,
  Closed = 1
}

export enum BillingCycleWeek {
  "Sign-update" = "0",
  "Monday" = "-1",
  "Tuesday" = "-2",
  "Wednesday" = "-3",
  "Thursday" = "-4",
  "Friday" = "-5",
  "Saturday" = "-6",
  "Sunday" = "-7"
}

export enum BillingCycleMonth {
  "Sign-up date" = "0",
  "1st of Every month" = "1",
  "2nd of Every month" = "2",
  "3rd of Every month" = "3",
  "4th of Every month" = "4",
  "5th of Every month" = "5",
  "6th of Every month" = "6",
  "7th of Every month" = "7",
  "8th of Every month" = "8",
  "9th of Every month" = "9",
  "10th of Every month" = "10",
  "11th of Every month" = "11",
  "12th of Every month" = "12",
  "13th of Every month" = "13",
  "14th of Every month" = "14",
  "15th of Every month" = "15",
  "16th of Every month" = "16",
  "17th of Every month" = "17",
  "18th of Every month" = "18",
  "19th of Every month" = "19",
  "20th of Every month" = "20",
  "21st of Every month" = "21",
  "22nd of Every month" = "22",
  "23rd of Every month" = "23",
  "24th of Every month" = "24",
  "25th of Every month" = "25",
  "26th of Every month" = "26",
  "27th of Every month" = "27",
  "28th of Every month" = "28",
  "29th of Every month" = "29",
  "30th of Every month" = "30",
  "31th of Every month" = "31",
}

export enum SignUpCharges {
  "KeyFobFees" = "KeyFob Fees",
  "SignUpFees" = "Sign-up Fees",
  "TransferFees" = "Transfer Fees",
  "FreezeFees" = "Freeze Fees"
}

export enum MembershipBehaviour {
  MemberShip = "Check-in",
  AppointmentPackage = "Appointment Session",
  ClassPackage = "Class Session",
}

export enum ServiceBehaviour {
  Membership = 1,
  AppointmentPackage = 2,
  ClassPackage = 3
}

export enum PayModeLevel1 {
  Cash = 1,
  Card = 2,
  NetBanking = 3,
  UPI = 4,
  Wallet = 5,
  ACH = 6,
  Cheque = 7,
  Other = 8
}

export enum PayModeLevel2 {
  Cash = 1,
  Debit = 2,
  Credit = 3,
  NetBanking = 4,
  UPI = 5,
  Paytm = 6,
  AmazonPay = 7,
  Venmo = 8,
  ACH = 9,
  Cheque = 10,
  Sodexo = 11
}
