import { CompanyInfoStoreInterface } from "@fitness-force/models";

export const INITIAL_COMPANY_STATE: CompanyInfoStoreInterface = {
  compDetails: {
    id: 0,
    "account_manager": {
      "id": 0,
      "name": "",
      "picture": "",
      "phone_number": "",
      "email": "",
      "description": "",
      "timing": []
    },
    "status": "",
    "ff_package_template_name": "",
    "logo": "",
    "member_portal_url": "",
    "reservation_portal_url": "",
    "purchase_portal_url": "",
    "app_type": "",
    "created_by": 0,
    "created_date": "",
    "updated_by": 0,
    "updated_date": "",
    "ff_package_template_id": 0,
    "name_primary_language": "",
    "name_secondary_language": "",
    "subdomain": "",
    "purchase_portal_enable": false,
    "member_portal_enable": false,
    "member_app_android_link": "",
    "member_app_ios_link": "",
    "primary_language": "",
    "is_secondary_language": false,
    "secondary_language": "",
    "email_id": "",
    "website": "",
    "phone1": "",
    "phone2": "",
    "address1": "",
    "address2": "",
    "about_us_primary_language": "",
    "about_us_secondary_language": "",
    "is_privacy_policy_url": false,
    "is_terms_of_service_url": false,
    "privacy_policy": "",
    "terms_of_service": "",
    "privacy_policy_url": "",
    "terms_of_service_url": "",
    "suspended_by": 0,
    "suspended_date": "",
    "cancelled_by": 0,
    "cancelled_date": "0",
    "supported_languages": [],
    "suspension_reason": "",
    "cancelled_reason": "",
    "allow_remote_fflogin": false
  },
  selectedLang: {
    COUNTRY: 'us',
    LANG_CODE: 'en',
    LANG_ABBRE: 'ENGLISH'
  },
  direction: '',
  err: { details: {}, error: false }
};