import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { Routes, RouterModule, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { COMPANY_INFO, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { HttpClient } from '@angular/common/http';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('sidebar', { static: true }) sidebar: ElementRef;
  companyDetails: any;
  companyLogo!: Observable<any> | any;
  tenantLogo!: Observable<any> | any;
  selectedUrl: string;

  public companyInfoDetail$!: Observable<any>;
  public tenantInfoDetail$!: Observable<any>;

  sidebarMinimized: boolean = false;
  hoveMenu = false;
  Menus: any;
  sidebarMinizeClass: string = 'lg:sticky'
  constructor(private store: Store, private router: Router, private translate: TranslateService) {
    this.Menus = [
      {
        title: 'CRM',
        url: '/crm',
        activeUrl: 'crm',
        icon: 'pi pi-chart-pie',
        icon2: '',
        submenus: [
          {
            title: 'PIPELINE',
            url: '/crm/sales-pipeline',
            activeUrl: '/crm/sales-pipeline',
            icon: '',
          },
          {
            title: 'TASKS',
            url: '/crm/task-list',
            activeUrl: '/crm/task-list',
            icon: '',
          },
          {
            title: 'PROSPECTS',
            url: '/crm/prospect-list',
            activeUrl: '/crm/prospect-list',
            icon: '',
          },
        ],
      },
      {
        title: 'CLIENT_MANAGEMENT',
        url: '/client-management',
        activeUrl: 'crm',
        icon: 'pi pi-users',
        icon2: '',
        submenus: [
          {
            title: 'ACTIVE_CLIENTS',
            url: '/client-management/active-clients',
            activeUrl: '/client-management/active-clients',
            icon: 'pi pi-user-plus',
          },
          {
            title: 'EX_CLIENTS',
            url: '/client-management/ex-clients',
            activeUrl: '/client-management/ex-clients',
            icon: 'pi pi-user-minus',
          },
          {
            title: 'IRREGULAR_CLIENTS',
            url: '/client-management/irregular-clients',
            activeUrl: '/client-management/irregular-clients',
            icon: 'pi pi-user-minus',
          }
        ]
      },
      {
        title: 'FITNESS',
        url: 'schedule',
        activeUrl: '/schedule',
        icon: 'pi pi-calendar-plus',
        icon2: '',
        submenus: [
          {
            title: 'FITNESS_CALENDAR',
            url: '/schedule',
            activeUrl: '/schedule/fitness-calendar',
            icon: 'pi pi-calendar-plus',
          },
          {
            title: 'SCHEDULE_CLASS',
            url: '/schedule/schedule-class',
            activeUrl: '/schedule/schedule-class',
            icon: 'pi pi-calendar-plus',
          },
        ],
      },
      {
        title: 'POS',
        url: '/pos',
        activeUrl: 'pos',
        icon: 'pi pi-chart-line',
        icon2: '',
        submenus: [
          {
            title: 'MEMBERSHIP-ADDONS',
            url: '/pos/membership-addons',
            activeUrl: '/pos/membership-addons',
            icon: '',
          },
        ],
      },

      {
        title: 'REPORT',
        url: '/report',
        activeUrl: 'reports',
        icon: 'pi pi-chart-bar',
        icon2: '',
        submenus: [],
      },
      {
        title: 'ADMIN',
        url: '/admin',
        activeUrl: 'admin',
        icon: 'pi pi-cog',
        icon2: '',
        submenus: [],
      },

    ];

    this.CheckAndSetSelectedUrl();
  }

  ngOnInit(): void {
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO);
    this.tenantInfoDetail$ = this.store.select(TENANTS_INFO);

    this.companyInfoDetail$.subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
      if (responseData.compDetails != null) {
        this.companyDetails = responseData.compDetails;
        //this.companyLogo = this.companyDetails.website + this.companyDetails.logo;
        this.companyLogo = this.companyDetails.logo;
      }
    });

    this.tenantInfoDetail$.subscribe((responseData) => {
      if (responseData.selected_tenant) {
        this.tenantLogo = responseData.selected_tenant.image;
      }
    });


  }

  ngAfterViewInit() {
  }

  CheckAndSetSelectedUrl(): void {
    let url = this.router.url;
    this.selectedMenu(url?.split('?')[0]);
  }

  toggleSideBarMinimized() {
    if (this.hoveMenu) this.hoveMenu = false;
    this.sidebarMinimized = !this.sidebarMinimized;

  }


  selectedMenu(url: string) {
    this.selectedUrl = url;
    console.log(this.selectedUrl);
  }
}

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    CommonModule,
    StyleClassModule,
    DropdownModule,
    InputTextModule,
    ScrollPanelModule,
    ButtonModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [SidebarComponent],
})
export class SidebarComponentModule { }
