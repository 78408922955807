import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { GetPlanMasterByIdResponse, GetPlanMasterListResponse } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PlanmasterService {
  constructor(private http: HttpClient, private route: Router) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  //tenants/{tenant_id:int}/boards/{board_id:int}/opportunities
  getSubscriptionPlanService(tenantId: number): Observable<any> {
    return this.http.get(`${environment.BASE_URL}/tenants/${tenantId}/subscription_plans`, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getSubscriptionPlanServiceById(tenantId: number, subscription_planId: number): Observable<GetPlanMasterByIdResponse> {
    return this.http.get(`${environment.BASE_URL}/tenants/${tenantId}/subscription_plans/${subscription_planId}`, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

} // class closed
