<div *ngIf="showpg$ | async">
    <div class="">
        <ng-container *ngIf="(showSkeleton$ | async)">
            <br />

            <div style="text-align: center;margin-top: 100px;">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </ng-container>

        <div [hidden]="(showSkeleton$ | async)">
            <div class="payment-form" *ngIf="paymentMethod == 'cc' || paymentMethod == 'dc'">
                <h2>Credit Card Payment</h2>
                <form id="card-form">
                    <div class="form-pad">
                        <label for="card-name">Card Name</label>
                        <div class="form-field" id="card-name"></div>
                    </div>
                    <div class="form-pad">
                        <label for="card-number">Card Number</label>
                        <div class="form-field" id="card-number"></div>
                    </div>
                    <div class="form-pad">
                        <label for="card-expiry">Expire Date</label>
                        <div class="form-field" id="card-expiry"></div>
                    </div>
                    <div class="form-pad">
                        <label for="card-cvc">CVC</label>
                        <div class="form-field" id="card-cvc"></div>
                    </div>
                </form>
                <div>
                    <button id="btn-process-card" class="p-button" (click)="tokenizeCardAndProcessPayment($event)">
                        Submit
                    </button>
                </div>
            </div>

            <div class="payment-form" *ngIf="paymentMethod == 'ach'">
                <h2>ACH Payment</h2>
                <form id="card-form">
                    <div class="form-pad">
                        <label for="routing-number">Routing Number</label>
                        <div class="form-field" id="routing-number"></div>
                    </div>
                    <div class="form-pad">
                        <label for="account-number">Account Number</label>
                        <div class="form-field" id="account-number"></div>
                    </div>
                    <div class="form-pad">
                        <label for="first-name">First Name</label>
                        <div class="form-field" id="first-name"></div>
                    </div>
                    <div class="form-pad">
                        <label for="last-name">Last Name</label>
                        <div class="form-field" id="last-name"></div>
                    </div>
                </form>
                <div>
                    <button id="btn-process-card" class="p-button" (click)="tokenizeACHAndProcessPayment($event)">
                        Submit
                    </button>
                </div>

                <svg style="width: 0; height: 0;">
                    <style id="plaidCss">
                        .pf-plaid-btn {
                            color: royalblue;
                            text-align: center;
                            width: 100%;
                            margin: 4px;
                            font-family: "Arial";
                            font-size: large;
                        }
                    </style>
                </svg>
                <pay-engine type='plaidconnect' (success)="tokenizePlaidAndProcessPayment($event)" (error)="onError($event)"
                    title="Connect with PLAID" css="#plaidCss" hash="{{hmac}}">
                </pay-engine>
            </div>
        </div>
    </div>
</div>