import { TENANTSINTERFACE, TENANT_MODEL } from "@fitness-force/models";


export const TENANT_STATE: TENANTSINTERFACE = {
    accessed_tenant: [],
    selected_tenant: {
        id: 0,
        company_id: 0,
        created_by: 0,
        created_date: '',
        updated_by: 0,
        updated_date: '',
        status: '',
        name_primary_language: '',
        name_secondary_language: '',
        phone1: '',
        email_id: '',
        website: '',
        zone: '',
        place_id: '',
        latitude: 0,
        longitude: 0,
        city: '',
        state: '',
        zip_code: '',
        ip_setting: '',
        live_members: 0,
        image: '',
        exclude_from_app: false
    } as TENANT_MODEL
}