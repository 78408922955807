import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';

import { environment } from 'apps/ff-frontend/src/environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { OpportunityBoardType } from '@fitness-force/enum';
import { CreateResponse } from '@fitness-force/models';

@Injectable({
  providedIn: 'root',
})
export class TaxMasterService {
  constructor(private http: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getTaxGroups(tenantId: number,allParams:Array<string>=[]) {
    let parsedParams = new HttpParams()
    if(allParams.length > 0){

     allParams.forEach((p: any) => {

       parsedParams = parsedParams.append(p.name, p.value);
     });
    }
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups`,
        {headers: this.httpOptions.headers , params: parsedParams}
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
  getTaxSetting(tenantId:number,tentstgKey:string){
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/tenant_settings/${tentstgKey}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getAllTaxSetting(tenantId:number){
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/tenant_settings`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateTaxSettings(tenantId: number,  data: any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/tenant_settings/update`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getTaxLineItems(tenantId: number, groupId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups/${groupId}/tax_line_items`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
  getTaxLineItem(tenantId: number, groupId: number,taxLineItemId:number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups/${groupId}/tax_line_items/${taxLineItemId}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getTaxLineItemTypes(tenantId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_line_type`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  createTaxGroup(tenantId: number,  data: any) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  createTaxLineItem(tenantId: number, groupId: number, data: any) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups/${groupId}/tax_line_items`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateTaxGroup(tenantId: number, groupId: number, data: any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups/${groupId}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateTaxLineItem(tenantId: number, groupId: number,lineItemId:number, data: any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups/${groupId}/tax_line_items/${lineItemId}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  deleteTaxGroup(tenantId: number, groupId: number) {
    return this.http
      .delete(
        `${environment.BASE_URL}/tenants/${tenantId}/tax_groups/${groupId}`
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


}
