import { Tag } from "@fitness-force/models";

    export interface ClassType {
        id: number;
        name_primary_language: string;
        name_secondary_language: string;
        behaviour: string;
        color: string;
        status: string;
        accessible_on_staff_facing_apps: boolean;
        accessible_on_member_facing_apps: boolean;
        accessible_on_website_widgits: boolean;
        logo: string;
        is_small_group_training: boolean;
        duration_in_minutes: number;
        is_room_mandatory: boolean;
        tags: Tag[];
        created_by: number;
        updated_by: number;
        created_date: Date;
        updated_date: Date;
    }

    export interface ClassTypeList {
        items: ClassType[];
    }

    export interface ClassTypeStore {
        classTypes: ClassType[]
     };
    export interface Subtype {
        id: number;
        company_id: number;
        tenant_id: number;
        created_by: number;
        created_date: Date;
        class_type_id: number;
        name_primary_language: string;
        name_secondary_language: string;
        description_primary_language: string;
        description_secondary_language: string;
        things_to_carry_primary_language: string;
        things_to_carry_secondary_language: string;
    }

    export interface ClassTypeById {
        id: number;
        company_id: number;
        tenant_id: number;
        service_type_id: number;
        name_primary_language: string;
        name_secondary_language: string;
        behaviour: string;
        color: string;
        status: string;
        accessible_on_staff_facing_apps: boolean;
        accessible_on_member_facing_apps: boolean;
        accessible_on_website_widgits: boolean;
        logo: string;
        is_small_group_training: boolean;
        maximum_capacity: number;
        maximum_waitlist: number;
        commission_mode: string;
        minimum_booking_cancel_time: number;
        mark_trainer_busy: boolean;
        is_room_mandatory: boolean;
        maximum_booking_client_side: number;
        maximum_booking_staff_side: number;
        booking_opens_in_minutes: number;
        is_online_cancel_booking_allowed: boolean;
        duration_in_minutes: number;
        tags: Tag[];
        updated_date: Date;
        dropin_plan_id: number;
        subtypes: Subtype[];
    }

