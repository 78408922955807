import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { ClassBookingRequest, CreateResponse, MarkClientCheckedinRequest, MarkClientNoshowRequest, RemoveClientFromClassRequest } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClassBookingService {

  constructor(private http: HttpClient, private router: Router) { }

  BASE_URL: string = environment.BASE_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  BookClientsToClass(tenantId:number,classId: number,classBookingRequest:ClassBookingRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS_BOOKING.BOOK_CLIENTS_TO_CLASS.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.post<ClassBookingRequest>(url,classBookingRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  RemoveClientsToClass(tenantId:number,classId: number,removeClientFromClassRequest:RemoveClientFromClassRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS_BOOKING.REMOVE_CLIENTS_FROM_CLASS.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.post<RemoveClientFromClassRequest>(url,removeClientFromClassRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  MarkNoShow(tenantId:number,classId: number,markClientNoshowRequest:MarkClientNoshowRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS_BOOKING.MARK_CLIENT_NO_SHOW.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.post<MarkClientNoshowRequest>(url,markClientNoshowRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  MarkCheckedIn(tenantId:number,classId: number,markClientCheckedinRequest:MarkClientCheckedinRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.CLASS_BOOKING.MARK_CLIENT_CHECKEDIN.replace('{tenant_id}', tenantId.toString()).replace('{class_id}', classId.toString());
    return this.http.post<MarkClientCheckedinRequest>(url,markClientCheckedinRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

}
