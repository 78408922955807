<p-dialog [visible]="showCalendar" [resizable]="false" appendTo="body" [modal]="true" [breakpoints]="{'767px': '100vw'}"
  [style]="{width: '500px'}" styleClass="no-header-modal overflow-visible" (onHide)="ClosePopup()"
  [dismissableMask]="false">
  <ng-template pTemplate="content" styleClass="p-0">

    <p-calendar (onClickOutside)="showCalendar=false" [(ngModel)]="timePeriodDate" selectionMode="range" [inline]="true"
      inputId="range" placeholder="Select Date" inputStyleClass="text-sm" #calendar styleClass="w-full"
      (onSelect)="onDateSelect()"></p-calendar>
    
      <div class="px-3 md:px-4 py-3 border-top-1 border-gray-300">
        <div class="flex align-items-center justify-content-between">
            <p-button styleClass="p-button-link text-sm" (click)="ClosePopup()" label="Close">
            </p-button>
            <button  class="text-sm" pButton pRipple label="Set Date" (click)="SetDate()"></button>
        </div>
    </div>
  </ng-template>

</p-dialog>
