import { STAFF_STATE } from './../../../../../../../../apps/ff-frontend/src/app/state/app.state';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { StyleClassModule } from 'primeng/styleclass';

@Component({
  selector: 'assign-to-staff',
  templateUrl: './assign-to-staff.component.html',
  styleUrls: ['./assign-to-staff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignToStaffComponent implements OnInit {
  @Input() columnData: any = ''
  allStaffs: any = []
  constructor(private store: Store) { }

  ngOnInit(): void {

    this.store.select(STAFF_STATE).subscribe((staffs: any) => {
      this.allStaffs = staffs.staffList;
    })
  }
  getStaffDetails(sId:any){
  
    const staff = this.allStaffs.find((s:any) => s.id == sId)
    return staff ? staff:  null
  }
}

@NgModule({
  declarations: [AssignToStaffComponent],
  imports: [
    CommonModule,
    AvatarModule,
    DropdownModule,
    StyleClassModule,
    ShortNamePipeModule
  ],
  exports: [AssignToStaffComponent]
})
export class AssignToStaffComponentModule {
}
