import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthguardService, ClaimsguardService, DomainguardService } from '@fitness-force/shared';

const appRoutes: Routes = [
  {
    path:'',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth', loadChildren: () => import('./features/auth/auth.module').then(a => a.AuthModule),  data: {isTenant: false}
  },
  {
    path: 'crm', loadChildren: () => import('./features/crm/crm.module').then(c => c.CRMModule), data: {isTenant: true,breadcrumb:"CRM"}
  },
  {
    path: 'admin', loadChildren: () => import('./features/admin/admin.module').then(c => c.AdminModule), data: {isTenant: true,breadcrumb:"ADMIN"}
  },
  {
    path: 'report', loadChildren: () => import('./features/reports/reports.module').then(c => c.ReportsModule), data: {isTenant: true , breadcrumb:"REPORT"}
  },
  {
    path: 'schedule', loadChildren: () => import('./features/schedule/schedule.module').then(c => c.ScheduleModule), data: {isTenant: true ,breadcrumb:"SCHEDULE"}
  },
  {
    path:'client', loadChildren: () => import('./features/client-profile/client-profile.module').then(cp => cp.ClientProfileModule), data: {isTenant:true}
  },
  {
    path:'client-management', loadChildren: () => import('./features/client-management/client-management.module').then(cm => cm.ClientManagementModule), data: {isTenant:true}
  },
  {
    path: 'pos', loadChildren: () => import('./features/pos/pos.module').then(c => c.PosModule), data: {isTenant: true}
  },
  {
    path: '**',
    loadChildren: () => import('./features/page-not-found/page-not-found.component').then(p => p.PageNotFoundComponentModule), data: {isTenant: false}
  }
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes,{scrollPositionRestoration: 'enabled'})

  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
