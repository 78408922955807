import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMsgComponentModule } from '@fitness-force/errors';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendEmailComponent implements OnInit {
  @Input() sendEmailModal: boolean | null = false;
  @Input() clientData$:any = new BehaviorSubject(null)
  @Output() closeSendEmailDialog =  new EventEmitter()
  editorText: string | undefined;
  attachments: any;
  selectedAttachment: { name: string } | undefined;
  templates: any;
  selectedTemplate: { name: string } | any;
  allClientData$ = new BehaviorSubject<any>({})
  constructor() { }

  ngOnInit(): void {
    this.componentInit()
  }
  componentInit(){
    this.clientData$.subscribe(
      (client:any)=>{
      this.allClientData$.next(client)
      })
  }
  closeDialogEvent(){
    this.closeSendEmailDialog.emit(false)
  }
}

@NgModule({
  declarations: [SendEmailComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    PasswordModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),
    ErrorMsgComponentModule,
    ToastModule
  ],
  exports: [SendEmailComponent]
})
export class SendEmailComponentModule {
}
