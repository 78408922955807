import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { IPSetting } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { map, catchError, throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  IP_API_URL: string = environment.Ip_Api_URL;
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getIpData(): Observable<any> {
    return this.http.get(this.IP_API_URL).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: any) => {
      return throwError(() => new AppErrors(error));
    }));
  }

  getPosition(): Promise<IPSetting> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          let location: IPSetting = {} as IPSetting;
          location.lat = resp.coords.latitude;
          location.lon = resp.coords.longitude;
          resolve(location);
        },
        (err) => {
          this.getIpData().subscribe((res: IPSetting | PromiseLike<IPSetting>) => {
            resolve(res);
          })
        }
      );
    });
  }

}
