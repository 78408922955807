<p-dialog [(visible)]="changeReportingManagerDialog" (onHide)="ClosePopup()" [resizable]="false" appendTo="body"
    [modal]="true" [breakpoints]="{'767px': '100vw'}" [style]="{width: '660px', overflow: 'visible'}"
    styleClass="no-header-modal overflow-visible">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <form [formGroup]="formGroup" (ngSubmit)="ValidateAndSubmit()">
            <p-toast (onClose)="ClosePopup()"></p-toast>
            <!-- Header -->
            <div
                class="bg-white p-3 md:px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
                <h2 class="mb-0 text-base md:text-lg font-normal">
                    {{'STAFF.CHANGE_REPORTING_MANAGER.CHANGEREPORTINGMANAGEROF' | translate}}
                    <strong>{{staff.name}} </strong>
                    <span class="text-xs text-gray-600"> - {{staff.id}}</span>
                </h2>
                <div class="pi pi-times text-gray-500 cursor-pointer" (click)="ClosePopup()"></div>
            </div>
            <!-- Header -->

            <!-- Modal Content -->
            <div class="surface-100 p-3 md:px-5">
                <!-- Column -->
                <div class="mb-4">
                    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'STAFF.CHANGE_REPORTING_MANAGER.CURRENTREPORTINGMANAGER' | translate}}</label>
                    <div class="flex align-items-center mt-2 md:mt-0 cursor-pointer" (click)="currentRepManager.toggle($event)">
                        <span>
                            <img class="w-2rem h-2rem border-round mr-2" src="{{(reportToStaff$ | async)?.ff_picture}}"
                                (error)="OnStaffImgError($event)" alt="{{(reportToStaff$ | async)?.name}}" />
                        </span>
                        <span class="text-sm md:text-base text-primary">
                            {{(reportToStaff$ | async)?.name}}
                        </span>
                    </div>

                    <p-overlayPanel #currentRepManager styleClass="overflow-hidden">
                        <ng-template pTemplate>

                            <!-- Dropdown Content -->
                            <div class="bg-white -mx-4 -mt-4 flex align-items-start justify-content-between px-3 py-2">
                                <div class="flex mt-2 w-10rem line-height-1">
                                    <span class="text-base font-medium text-800">{{(updatedBy$|async)?.name}}</span>
                                    <span class="text-xs text-600 ml-1 mt-1">{{(updatedBy$|async)?.id}}</span>
                                </div>
                                <span
                                    class="bg-green-500 px-2 py-1 font-bold border-round text-white text-xxs uppercase ml-4 mt-1">{{(updatedBy$|async)?.designation}}</span>
                            </div>
                            <!-- Dropdown Content -->

                            <!-- Dropdown Footer -->
                            <div class="px-3 py-2 -mx-4 -mb-4 bg-gray-200 text-800 text-xs">
                                <div class="flex align-items-center mb-2">
                                    <i class="pi pi-phone mr-2 text-gray-500"></i>
                                    {{ (updatedBy$|async)?.mobile_country_code + " " + (updatedBy$|async)?.mobile_number
                                    || ""}}
                                </div>
                                <div class="flex align-items-center mb-1">
                                    <i class="pi pi-envelope mr-2 text-gray-500"></i>
                                    <span class="break-word">{{(updatedBy$|async)?.email_id}}</span>
                                </div>
                            </div>
                            <!-- Dropdown Footer -->

                        </ng-template>
                    </p-overlayPanel>
                </div>
                <!-- Column -->

                <!-- Column -->
                <div class="">
                    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'STAFF.CHANGE_REPORTING_MANAGER.NEWREPORTINGMANAGER' | translate}}</label>
                    <div class="relative">
                        <staff-selector [formGroup]="formGroup" [controlName]="ReportingManagerControl"
                            styleClass="w-full text-sm align-items-center">
                        </staff-selector>
                    </div>
                </div>
                <!-- Column -->
            </div>
            <!-- Modal Content -->

            <div class="flex align-items-center justify-content-between border-top-1 border-gray-300  p-3 md:px-5">
                <div class="w-4">
                    <p-button label="{{'STAFF.CHANGE_REPORTING_MANAGER.CANCEL' | translate}}"
                        styleClass="p-button-link p-0 text-sm" (click)="ClosePopup()">
                    </p-button>
                </div>
                <div class="w-8 text-right">
                    <button type="submit" class="text-sm" [disabled]="!formGroup.valid" pButton pRipple
                        label="{{'STAFF.CHANGE_REPORTING_MANAGER.UPDATEREPORTINGMANAGER' | translate}}"></button>
                </div>
            </div>

            <app-errors *ngIf="showErrorPopup | async as show_Popup" [visible]="show_Popup" [errorData]="errorMessages"
                (hideErrorPopup)="HideErrorPopup($event)">
            </app-errors>
        </form>
    </ng-template>
</p-dialog>