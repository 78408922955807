import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor(private http: HttpClient, private router: Router) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  GetTenantList(BASE_URL: string): Observable<any> {
    return this.http.get(BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANT_LIST, { headers: this.httpOptions.headers }).pipe(map((res: any) => {
      if (res) { return res; }
      else { return null; }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  GetTenantById(BASE_URL: string, tenantId: number): Observable<any> {
    return this.http.get(BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId, { headers: this.httpOptions.headers }).pipe(map((res: any) => {
      if (res) { return res; }
      else { return null; }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }
}
