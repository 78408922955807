import { Country } from "@fitness-force/models";

export const CONST_COUNTRIES: Country[] = [
    { "id": 1, "name": "Afghanistan", "dial_code": "93", "two_letter_code": "AF", "three_letter_code": "AFG", "currency_code": "AFN", "currency_name": "AFN", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 2, "name": "Aland Islands", "dial_code": "340", "two_letter_code": "AX", "three_letter_code": "ALA", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 3, "name": "Albania", "dial_code": "355", "two_letter_code": "AL", "three_letter_code": "ALB", "currency_code": "ALL", "currency_name": "ALL", "currency_symbol": "Lek", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 4, "name": "Algeria", "dial_code": "213", "two_letter_code": "DZ", "three_letter_code": "DZA", "currency_code": "DZD", "currency_name": "DZD", "currency_symbol": "??", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 5, "name": "American Samoa", "dial_code": "-68", "two_letter_code": "AS", "three_letter_code": "ASM", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 6, "name": "Andorra", "dial_code": "376", "two_letter_code": "AD", "three_letter_code": "AND", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 7, "name": "Angola", "dial_code": "244", "two_letter_code": "AO", "three_letter_code": "AGO", "currency_code": "AOA", "currency_name": "AOA", "currency_symbol": "Kz", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 8, "name": "Anguilla", "dial_code": "-26", "two_letter_code": "AI", "three_letter_code": "AIA", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 9, "name": "Antarctica", "dial_code": "", "two_letter_code": "AQ", "three_letter_code": "ATA", "currency_code": "", "currency_name": "", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 10, "name": "Antigua And Barbuda", "dial_code": "-26", "two_letter_code": "AG", "three_letter_code": "ATG", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 11, "name": "Argentina", "dial_code": "54", "two_letter_code": "AR", "three_letter_code": "ARG", "currency_code": "ARS", "currency_name": "ARS", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 12, "name": "Armenia", "dial_code": "374", "two_letter_code": "AM", "three_letter_code": "ARM", "currency_code": "AMD", "currency_name": "AMD", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 13, "name": "Aruba", "dial_code": "297", "two_letter_code": "AW", "three_letter_code": "ABW", "currency_code": "AWG", "currency_name": "AWG", "currency_symbol": "ƒ", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 14, "name": "Australia", "dial_code": "61", "two_letter_code": "AU", "three_letter_code": "AUS", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 15, "name": "Austria", "dial_code": "43", "two_letter_code": "AT", "three_letter_code": "AUT", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 16, "name": "Azerbaijan", "dial_code": "994", "two_letter_code": "AZ", "three_letter_code": "AZE", "currency_code": "AZN", "currency_name": "AZN", "currency_symbol": "m", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 17, "name": "Bahamas The", "dial_code": "-24", "two_letter_code": "BS", "three_letter_code": "BHS", "currency_code": "BSD", "currency_name": "BSD", "currency_symbol": "B$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 18, "name": "Bahrain", "dial_code": "973", "two_letter_code": "BH", "three_letter_code": "BHR", "currency_code": "BHD", "currency_name": "BHD", "currency_symbol": ".?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 19, "name": "Bangladesh", "dial_code": "880", "two_letter_code": "BD", "three_letter_code": "BGD", "currency_code": "BDT", "currency_name": "BDT", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 20, "name": "Barbados", "dial_code": "-24", "two_letter_code": "BB", "three_letter_code": "BRB", "currency_code": "BBD", "currency_name": "BBD", "currency_symbol": "Bds$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 21, "name": "Belarus", "dial_code": "375", "two_letter_code": "BY", "three_letter_code": "BLR", "currency_code": "BYN", "currency_name": "BYN", "currency_symbol": "Br", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 22, "name": "Belgium", "dial_code": "32", "two_letter_code": "BE", "three_letter_code": "BEL", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 23, "name": "Belize", "dial_code": "501", "two_letter_code": "BZ", "three_letter_code": "BLZ", "currency_code": "BZD", "currency_name": "BZD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 24, "name": "Benin", "dial_code": "229", "two_letter_code": "BJ", "three_letter_code": "BEN", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 25, "name": "Bermuda", "dial_code": "-44", "two_letter_code": "BM", "three_letter_code": "BMU", "currency_code": "BMD", "currency_name": "BMD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 26, "name": "Bhutan", "dial_code": "975", "two_letter_code": "BT", "three_letter_code": "BTN", "currency_code": "BTN", "currency_name": "BTN", "currency_symbol": "Nu.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 27, "name": "Bolivia", "dial_code": "591", "two_letter_code": "BO", "three_letter_code": "BOL", "currency_code": "BOB", "currency_name": "BOB", "currency_symbol": "Bs.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 28, "name": "Bosnia and Herzegovina", "dial_code": "387", "two_letter_code": "BA", "three_letter_code": "BIH", "currency_code": "BAM", "currency_name": "BAM", "currency_symbol": "KM", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 29, "name": "Botswana", "dial_code": "267", "two_letter_code": "BW", "three_letter_code": "BWA", "currency_code": "BWP", "currency_name": "BWP", "currency_symbol": "P", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 30, "name": "Bouvet Island", "dial_code": "55", "two_letter_code": "BV", "three_letter_code": "BVT", "currency_code": "NOK", "currency_name": "NOK", "currency_symbol": "kr", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 31, "name": "Brazil", "dial_code": "55", "two_letter_code": "BR", "three_letter_code": "BRA", "currency_code": "BRL", "currency_name": "BRL", "currency_symbol": "R$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 32, "name": "British Indian Ocean Territory", "dial_code": "246", "two_letter_code": "IO", "three_letter_code": "IOT", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 33, "name": "Brunei", "dial_code": "673", "two_letter_code": "BN", "three_letter_code": "BRN", "currency_code": "BND", "currency_name": "BND", "currency_symbol": "B$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 34, "name": "Bulgaria", "dial_code": "359", "two_letter_code": "BG", "three_letter_code": "BGR", "currency_code": "BGN", "currency_name": "BGN", "currency_symbol": "??.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 35, "name": "Burkina Faso", "dial_code": "226", "two_letter_code": "BF", "three_letter_code": "BFA", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 36, "name": "Burundi", "dial_code": "257", "two_letter_code": "BI", "three_letter_code": "BDI", "currency_code": "BIF", "currency_name": "BIF", "currency_symbol": "FBu", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 37, "name": "Cambodia", "dial_code": "855", "two_letter_code": "KH", "three_letter_code": "KHM", "currency_code": "KHR", "currency_name": "KHR", "currency_symbol": "KHR", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 38, "name": "Cameroon", "dial_code": "237", "two_letter_code": "CM", "three_letter_code": "CMR", "currency_code": "XAF", "currency_name": "XAF", "currency_symbol": "FCFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 39, "name": "Canada", "dial_code": "1", "two_letter_code": "CA", "three_letter_code": "CAN", "currency_code": "CAD", "currency_name": "CAD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 40, "name": "Cape Verde", "dial_code": "238", "two_letter_code": "CV", "three_letter_code": "CPV", "currency_code": "CVE", "currency_name": "CVE", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 41, "name": "Cayman Islands", "dial_code": "-34", "two_letter_code": "KY", "three_letter_code": "CYM", "currency_code": "KYD", "currency_name": "KYD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 42, "name": "Central African Republic", "dial_code": "236", "two_letter_code": "CF", "three_letter_code": "CAF", "currency_code": "XAF", "currency_name": "XAF", "currency_symbol": "FCFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 43, "name": "Chad", "dial_code": "235", "two_letter_code": "TD", "three_letter_code": "TCD", "currency_code": "XAF", "currency_name": "XAF", "currency_symbol": "FCFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 44, "name": "Chile", "dial_code": "56", "two_letter_code": "CL", "three_letter_code": "CHL", "currency_code": "CLP", "currency_name": "CLP", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 45, "name": "China", "dial_code": "86", "two_letter_code": "CN", "three_letter_code": "CHN", "currency_code": "CNY", "currency_name": "CNY", "currency_symbol": "¥", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 46, "name": "Christmas Island", "dial_code": "61", "two_letter_code": "CX", "three_letter_code": "CXR", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 47, "name": "Cocos (Keeling) Islands", "dial_code": "61", "two_letter_code": "CC", "three_letter_code": "CCK", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 48, "name": "Colombia", "dial_code": "57", "two_letter_code": "CO", "three_letter_code": "COL", "currency_code": "COP", "currency_name": "COP", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 49, "name": "Comoros", "dial_code": "269", "two_letter_code": "KM", "three_letter_code": "COM", "currency_code": "KMF", "currency_name": "KMF", "currency_symbol": "CF", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 50, "name": "Congo", "dial_code": "242", "two_letter_code": "CG", "three_letter_code": "COG", "currency_code": "XAF", "currency_name": "XAF", "currency_symbol": "FC", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 51, "name": "Congo The Democratic Republic Of The", "dial_code": "243", "two_letter_code": "CD", "three_letter_code": "COD", "currency_code": "CDF", "currency_name": "CDF", "currency_symbol": "FC", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 52, "name": "Cook Islands", "dial_code": "682", "two_letter_code": "CK", "three_letter_code": "COK", "currency_code": "NZD", "currency_name": "NZD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 53, "name": "Costa Rica", "dial_code": "506", "two_letter_code": "CR", "three_letter_code": "CRI", "currency_code": "CRC", "currency_name": "CRC", "currency_symbol": "¢", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 54, "name": "Cote D'Ivoire (Ivory Coast)", "dial_code": "225", "two_letter_code": "CI", "three_letter_code": "CIV", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 55, "name": "Croatia (Hrvatska)", "dial_code": "385", "two_letter_code": "HR", "three_letter_code": "HRV", "currency_code": "HRK", "currency_name": "HRK", "currency_symbol": "kn", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 56, "name": "Cuba", "dial_code": "53", "two_letter_code": "CU", "three_letter_code": "CUB", "currency_code": "CUP", "currency_name": "CUP", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 57, "name": "Cyprus", "dial_code": "357", "two_letter_code": "CY", "three_letter_code": "CYP", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 58, "name": "Czech Republic", "dial_code": "420", "two_letter_code": "CZ", "three_letter_code": "CZE", "currency_code": "CZK", "currency_name": "CZK", "currency_symbol": "Kc", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 59, "name": "Denmark", "dial_code": "45", "two_letter_code": "DK", "three_letter_code": "DNK", "currency_code": "DKK", "currency_name": "DKK", "currency_symbol": "Kr.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 60, "name": "Djibouti", "dial_code": "253", "two_letter_code": "DJ", "three_letter_code": "DJI", "currency_code": "DJF", "currency_name": "DJF", "currency_symbol": "Fdj", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 61, "name": "Dominica", "dial_code": "-76", "two_letter_code": "DM", "three_letter_code": "DMA", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 62, "name": "Dominican Republic", "dial_code": "+1-", "two_letter_code": "DO", "three_letter_code": "DOM", "currency_code": "DOP", "currency_name": "DOP", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 63, "name": "East Timor", "dial_code": "670", "two_letter_code": "TL", "three_letter_code": "TLS", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 64, "name": "Ecuador", "dial_code": "593", "two_letter_code": "EC", "three_letter_code": "ECU", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 65, "name": "Egypt", "dial_code": "20", "two_letter_code": "EG", "three_letter_code": "EGY", "currency_code": "EGP", "currency_name": "EGP", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 66, "name": "El Salvador", "dial_code": "503", "two_letter_code": "SV", "three_letter_code": "SLV", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 67, "name": "Equatorial Guinea", "dial_code": "240", "two_letter_code": "GQ", "three_letter_code": "GNQ", "currency_code": "XAF", "currency_name": "XAF", "currency_symbol": "FCFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 68, "name": "Eritrea", "dial_code": "291", "two_letter_code": "ER", "three_letter_code": "ERI", "currency_code": "ERN", "currency_name": "ERN", "currency_symbol": "Nfk", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 69, "name": "Estonia", "dial_code": "372", "two_letter_code": "EE", "three_letter_code": "EST", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 70, "name": "Ethiopia", "dial_code": "251", "two_letter_code": "ET", "three_letter_code": "ETH", "currency_code": "ETB", "currency_name": "ETB", "currency_symbol": "Nkf", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 71, "name": "Falkland Islands", "dial_code": "500", "two_letter_code": "FK", "three_letter_code": "FLK", "currency_code": "FKP", "currency_name": "FKP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 72, "name": "Faroe Islands", "dial_code": "298", "two_letter_code": "FO", "three_letter_code": "FRO", "currency_code": "DKK", "currency_name": "DKK", "currency_symbol": "Kr.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 73, "name": "Fiji Islands", "dial_code": "679", "two_letter_code": "FJ", "three_letter_code": "FJI", "currency_code": "FJD", "currency_name": "FJD", "currency_symbol": "FJ$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 74, "name": "Finland", "dial_code": "358", "two_letter_code": "FI", "three_letter_code": "FIN", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 75, "name": "France", "dial_code": "33", "two_letter_code": "FR", "three_letter_code": "FRA", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 76, "name": "French Guiana", "dial_code": "594", "two_letter_code": "GF", "three_letter_code": "GUF", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 77, "name": "French Polynesia", "dial_code": "689", "two_letter_code": "PF", "three_letter_code": "PYF", "currency_code": "XPF", "currency_name": "XPF", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 78, "name": "French Southern Territories", "dial_code": "", "two_letter_code": "TF", "three_letter_code": "ATF", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 79, "name": "Gabon", "dial_code": "241", "two_letter_code": "GA", "three_letter_code": "GAB", "currency_code": "XAF", "currency_name": "XAF", "currency_symbol": "FCFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 80, "name": "Gambia The", "dial_code": "220", "two_letter_code": "GM", "three_letter_code": "GMB", "currency_code": "GMD", "currency_name": "GMD", "currency_symbol": "D", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 81, "name": "Georgia", "dial_code": "995", "two_letter_code": "GE", "three_letter_code": "GEO", "currency_code": "GEL", "currency_name": "GEL", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 82, "name": "Germany", "dial_code": "49", "two_letter_code": "DE", "three_letter_code": "DEU", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 83, "name": "Ghana", "dial_code": "233", "two_letter_code": "GH", "three_letter_code": "GHA", "currency_code": "GHS", "currency_name": "GHS", "currency_symbol": "GH?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 84, "name": "Gibraltar", "dial_code": "350", "two_letter_code": "GI", "three_letter_code": "GIB", "currency_code": "GIP", "currency_name": "GIP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 85, "name": "Greece", "dial_code": "30", "two_letter_code": "GR", "three_letter_code": "GRC", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 86, "name": "Greenland", "dial_code": "299", "two_letter_code": "GL", "three_letter_code": "GRL", "currency_code": "DKK", "currency_name": "DKK", "currency_symbol": "Kr.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 87, "name": "Grenada", "dial_code": "-47", "two_letter_code": "GD", "three_letter_code": "GRD", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 88, "name": "Guadeloupe", "dial_code": "590", "two_letter_code": "GP", "three_letter_code": "GLP", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 89, "name": "Guam", "dial_code": "-67", "two_letter_code": "GU", "three_letter_code": "GUM", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 90, "name": "Guatemala", "dial_code": "502", "two_letter_code": "GT", "three_letter_code": "GTM", "currency_code": "GTQ", "currency_name": "GTQ", "currency_symbol": "Q", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 91, "name": "Guernsey and Alderney", "dial_code": "-14", "two_letter_code": "GG", "three_letter_code": "GGY", "currency_code": "GBP", "currency_name": "GBP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 92, "name": "Guinea", "dial_code": "224", "two_letter_code": "GN", "three_letter_code": "GIN", "currency_code": "GNF", "currency_name": "GNF", "currency_symbol": "FG", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 93, "name": "Guinea-Bissau", "dial_code": "245", "two_letter_code": "GW", "three_letter_code": "GNB", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 94, "name": "Guyana", "dial_code": "592", "two_letter_code": "GY", "three_letter_code": "GUY", "currency_code": "GYD", "currency_name": "GYD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 95, "name": "Haiti", "dial_code": "509", "two_letter_code": "HT", "three_letter_code": "HTI", "currency_code": "HTG", "currency_name": "HTG", "currency_symbol": "G", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 96, "name": "Heard Island and McDonald Islands", "dial_code": "", "two_letter_code": "HM", "three_letter_code": "HMD", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 97, "name": "Honduras", "dial_code": "504", "two_letter_code": "HN", "three_letter_code": "HND", "currency_code": "HNL", "currency_name": "HNL", "currency_symbol": "L", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 98, "name": "Hong Kong S.A.R.", "dial_code": "852", "two_letter_code": "HK", "three_letter_code": "HKG", "currency_code": "HKD", "currency_name": "HKD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 99, "name": "Hungary", "dial_code": "36", "two_letter_code": "HU", "three_letter_code": "HUN", "currency_code": "HUF", "currency_name": "HUF", "currency_symbol": "Ft", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 100, "name": "Iceland", "dial_code": "354", "two_letter_code": "IS", "three_letter_code": "ISL", "currency_code": "ISK", "currency_name": "ISK", "currency_symbol": "kr", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 101, "name": "India", "dial_code": "91", "two_letter_code": "IN", "three_letter_code": "IND", "currency_code": "INR", "currency_name": "INR", "currency_symbol": "?", "iso_numeric": 356, "miner_currency": 2 },
    { "id": 102, "name": "Indonesia", "dial_code": "62", "two_letter_code": "ID", "three_letter_code": "IDN", "currency_code": "IDR", "currency_name": "IDR", "currency_symbol": "Rp", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 103, "name": "Iran", "dial_code": "98", "two_letter_code": "IR", "three_letter_code": "IRN", "currency_code": "IRR", "currency_name": "IRR", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 104, "name": "Iraq", "dial_code": "964", "two_letter_code": "IQ", "three_letter_code": "IRQ", "currency_code": "IQD", "currency_name": "IQD", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 105, "name": "Ireland", "dial_code": "353", "two_letter_code": "IE", "three_letter_code": "IRL", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 106, "name": "Israel", "dial_code": "972", "two_letter_code": "IL", "three_letter_code": "ISR", "currency_code": "ILS", "currency_name": "ILS", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 107, "name": "Italy", "dial_code": "39", "two_letter_code": "IT", "three_letter_code": "ITA", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 108, "name": "Jamaica", "dial_code": "-87", "two_letter_code": "JM", "three_letter_code": "JAM", "currency_code": "JMD", "currency_name": "JMD", "currency_symbol": "J$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 109, "name": "Japan", "dial_code": "81", "two_letter_code": "JP", "three_letter_code": "JPN", "currency_code": "JPY", "currency_name": "JPY", "currency_symbol": "¥", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 110, "name": "Jersey", "dial_code": "-14", "two_letter_code": "JE", "three_letter_code": "JEY", "currency_code": "GBP", "currency_name": "GBP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 111, "name": "Jordan", "dial_code": "962", "two_letter_code": "JO", "three_letter_code": "JOR", "currency_code": "JOD", "currency_name": "JOD", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 112, "name": "Kazakhstan", "dial_code": "7", "two_letter_code": "KZ", "three_letter_code": "KAZ", "currency_code": "KZT", "currency_name": "KZT", "currency_symbol": "??", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 113, "name": "Kenya", "dial_code": "254", "two_letter_code": "KE", "three_letter_code": "KEN", "currency_code": "KES", "currency_name": "KES", "currency_symbol": "KSh", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 114, "name": "Kiribati", "dial_code": "686", "two_letter_code": "KI", "three_letter_code": "KIR", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 115, "name": "Korea North", "dial_code": "850", "two_letter_code": "KP", "three_letter_code": "PRK", "currency_code": "KPW", "currency_name": "KPW", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 116, "name": "Korea South", "dial_code": "82", "two_letter_code": "KR", "three_letter_code": "KOR", "currency_code": "KRW", "currency_name": "KRW", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 117, "name": "Kuwait", "dial_code": "965", "two_letter_code": "KW", "three_letter_code": "KWT", "currency_code": "KWD", "currency_name": "KWD", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 118, "name": "Kyrgyzstan", "dial_code": "996", "two_letter_code": "KG", "three_letter_code": "KGZ", "currency_code": "KGS", "currency_name": "KGS", "currency_symbol": "??", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 119, "name": "Laos", "dial_code": "856", "two_letter_code": "LA", "three_letter_code": "LAO", "currency_code": "LAK", "currency_name": "LAK", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 120, "name": "Latvia", "dial_code": "371", "two_letter_code": "LV", "three_letter_code": "LVA", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 121, "name": "Lebanon", "dial_code": "961", "two_letter_code": "LB", "three_letter_code": "LBN", "currency_code": "LBP", "currency_name": "LBP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 122, "name": "Lesotho", "dial_code": "266", "two_letter_code": "LS", "three_letter_code": "LSO", "currency_code": "LSL", "currency_name": "LSL", "currency_symbol": "L", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 123, "name": "Liberia", "dial_code": "231", "two_letter_code": "LR", "three_letter_code": "LBR", "currency_code": "LRD", "currency_name": "LRD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 124, "name": "Libya", "dial_code": "218", "two_letter_code": "LY", "three_letter_code": "LBY", "currency_code": "LYD", "currency_name": "LYD", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 125, "name": "Liechtenstein", "dial_code": "423", "two_letter_code": "LI", "three_letter_code": "LIE", "currency_code": "CHF", "currency_name": "CHF", "currency_symbol": "CHf", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 126, "name": "Lithuania", "dial_code": "370", "two_letter_code": "LT", "three_letter_code": "LTU", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 127, "name": "Luxembourg", "dial_code": "352", "two_letter_code": "LU", "three_letter_code": "LUX", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 128, "name": "Macau S.A.R.", "dial_code": "853", "two_letter_code": "MO", "three_letter_code": "MAC", "currency_code": "MOP", "currency_name": "MOP", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 129, "name": "Macedonia", "dial_code": "389", "two_letter_code": "MK", "three_letter_code": "MKD", "currency_code": "MKD", "currency_name": "MKD", "currency_symbol": "???", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 130, "name": "Madagascar", "dial_code": "261", "two_letter_code": "MG", "three_letter_code": "MDG", "currency_code": "MGA", "currency_name": "MGA", "currency_symbol": "Ar", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 131, "name": "Malawi", "dial_code": "265", "two_letter_code": "MW", "three_letter_code": "MWI", "currency_code": "MWK", "currency_name": "MWK", "currency_symbol": "MK", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 132, "name": "Malaysia", "dial_code": "60", "two_letter_code": "MY", "three_letter_code": "MYS", "currency_code": "MYR", "currency_name": "MYR", "currency_symbol": "RM", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 133, "name": "Maldives", "dial_code": "960", "two_letter_code": "MV", "three_letter_code": "MDV", "currency_code": "MVR", "currency_name": "MVR", "currency_symbol": "Rf", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 134, "name": "Mali", "dial_code": "223", "two_letter_code": "ML", "three_letter_code": "MLI", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 135, "name": "Malta", "dial_code": "356", "two_letter_code": "MT", "three_letter_code": "MLT", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 136, "name": "Man (Isle of)", "dial_code": "-15", "two_letter_code": "IM", "three_letter_code": "IMN", "currency_code": "GBP", "currency_name": "GBP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 137, "name": "Marshall Islands", "dial_code": "692", "two_letter_code": "MH", "three_letter_code": "MHL", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 138, "name": "Martinique", "dial_code": "596", "two_letter_code": "MQ", "three_letter_code": "MTQ", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 139, "name": "Mauritania", "dial_code": "222", "two_letter_code": "MR", "three_letter_code": "MRT", "currency_code": "MRO", "currency_name": "MRO", "currency_symbol": "MRU", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 140, "name": "Mauritius", "dial_code": "230", "two_letter_code": "MU", "three_letter_code": "MUS", "currency_code": "MUR", "currency_name": "MUR", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 141, "name": "Mayotte", "dial_code": "262", "two_letter_code": "YT", "three_letter_code": "MYT", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 142, "name": "Mexico", "dial_code": "52", "two_letter_code": "MX", "three_letter_code": "MEX", "currency_code": "MXN", "currency_name": "MXN", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 143, "name": "Micronesia", "dial_code": "691", "two_letter_code": "FM", "three_letter_code": "FSM", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 144, "name": "Moldova", "dial_code": "373", "two_letter_code": "MD", "three_letter_code": "MDA", "currency_code": "MDL", "currency_name": "MDL", "currency_symbol": "L", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 145, "name": "Monaco", "dial_code": "377", "two_letter_code": "MC", "three_letter_code": "MCO", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 146, "name": "Mongolia", "dial_code": "976", "two_letter_code": "MN", "three_letter_code": "MNG", "currency_code": "MNT", "currency_name": "MNT", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 147, "name": "Montenegro", "dial_code": "382", "two_letter_code": "ME", "three_letter_code": "MNE", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 148, "name": "Montserrat", "dial_code": "-66", "two_letter_code": "MS", "three_letter_code": "MSR", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 149, "name": "Morocco", "dial_code": "212", "two_letter_code": "MA", "three_letter_code": "MAR", "currency_code": "MAD", "currency_name": "MAD", "currency_symbol": "DH", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 150, "name": "Mozambique", "dial_code": "258", "two_letter_code": "MZ", "three_letter_code": "MOZ", "currency_code": "MZN", "currency_name": "MZN", "currency_symbol": "MT", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 151, "name": "Myanmar", "dial_code": "95", "two_letter_code": "MM", "three_letter_code": "MMR", "currency_code": "MMK", "currency_name": "MMK", "currency_symbol": "K", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 152, "name": "Namibia", "dial_code": "264", "two_letter_code": "NA", "three_letter_code": "NAM", "currency_code": "NAD", "currency_name": "NAD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 153, "name": "Nauru", "dial_code": "674", "two_letter_code": "NR", "three_letter_code": "NRU", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 154, "name": "Nepal", "dial_code": "977", "two_letter_code": "NP", "three_letter_code": "NPL", "currency_code": "NPR", "currency_name": "NPR", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 155, "name": "Bonaire, Sint Eustatius and Saba", "dial_code": "599", "two_letter_code": "BQ", "three_letter_code": "BES", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 156, "name": "Netherlands The", "dial_code": "31", "two_letter_code": "NL", "three_letter_code": "NLD", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 157, "name": "New Caledonia", "dial_code": "687", "two_letter_code": "NC", "three_letter_code": "NCL", "currency_code": "XPF", "currency_name": "XPF", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 158, "name": "New Zealand", "dial_code": "64", "two_letter_code": "NZ", "three_letter_code": "NZL", "currency_code": "NZD", "currency_name": "NZD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 159, "name": "Nicaragua", "dial_code": "505", "two_letter_code": "NI", "three_letter_code": "NIC", "currency_code": "NIO", "currency_name": "NIO", "currency_symbol": "C$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 160, "name": "Niger", "dial_code": "227", "two_letter_code": "NE", "three_letter_code": "NER", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 161, "name": "Nigeria", "dial_code": "234", "two_letter_code": "NG", "three_letter_code": "NGA", "currency_code": "NGN", "currency_name": "NGN", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 162, "name": "Niue", "dial_code": "683", "two_letter_code": "NU", "three_letter_code": "NIU", "currency_code": "NZD", "currency_name": "NZD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 163, "name": "Norfolk Island", "dial_code": "672", "two_letter_code": "NF", "three_letter_code": "NFK", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 164, "name": "Northern Mariana Islands", "dial_code": "-66", "two_letter_code": "MP", "three_letter_code": "MNP", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 165, "name": "Norway", "dial_code": "47", "two_letter_code": "NO", "three_letter_code": "NOR", "currency_code": "NOK", "currency_name": "NOK", "currency_symbol": "kr", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 166, "name": "Oman", "dial_code": "968", "two_letter_code": "OM", "three_letter_code": "OMN", "currency_code": "OMR", "currency_name": "OMR", "currency_symbol": ".?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 167, "name": "Pakistan", "dial_code": "92", "two_letter_code": "PK", "three_letter_code": "PAK", "currency_code": "PKR", "currency_name": "PKR", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 168, "name": "Palau", "dial_code": "680", "two_letter_code": "PW", "three_letter_code": "PLW", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 169, "name": "Palestinian Territory Occupied", "dial_code": "970", "two_letter_code": "PS", "three_letter_code": "PSE", "currency_code": "ILS", "currency_name": "ILS", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 170, "name": "Panama", "dial_code": "507", "two_letter_code": "PA", "three_letter_code": "PAN", "currency_code": "PAB", "currency_name": "PAB", "currency_symbol": "B/.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 171, "name": "Papua new Guinea", "dial_code": "675", "two_letter_code": "PG", "three_letter_code": "PNG", "currency_code": "PGK", "currency_name": "PGK", "currency_symbol": "K", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 172, "name": "Paraguay", "dial_code": "595", "two_letter_code": "PY", "three_letter_code": "PRY", "currency_code": "PYG", "currency_name": "PYG", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 173, "name": "Peru", "dial_code": "51", "two_letter_code": "PE", "three_letter_code": "PER", "currency_code": "PEN", "currency_name": "PEN", "currency_symbol": "S/.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 174, "name": "Philippines", "dial_code": "63", "two_letter_code": "PH", "three_letter_code": "PHL", "currency_code": "PHP", "currency_name": "PHP", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 175, "name": "Pitcairn Island", "dial_code": "870", "two_letter_code": "PN", "three_letter_code": "PCN", "currency_code": "NZD", "currency_name": "NZD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 176, "name": "Poland", "dial_code": "48", "two_letter_code": "PL", "three_letter_code": "POL", "currency_code": "PLN", "currency_name": "PLN", "currency_symbol": "zl", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 177, "name": "Portugal", "dial_code": "351", "two_letter_code": "PT", "three_letter_code": "PRT", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 178, "name": "Puerto Rico", "dial_code": "+1-", "two_letter_code": "PR", "three_letter_code": "PRI", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 179, "name": "Qatar", "dial_code": "974", "two_letter_code": "QA", "three_letter_code": "QAT", "currency_code": "QAR", "currency_name": "QAR", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 180, "name": "Reunion", "dial_code": "262", "two_letter_code": "RE", "three_letter_code": "REU", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 181, "name": "Romania", "dial_code": "40", "two_letter_code": "RO", "three_letter_code": "ROU", "currency_code": "RON", "currency_name": "RON", "currency_symbol": "lei", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 182, "name": "Russia", "dial_code": "7", "two_letter_code": "RU", "three_letter_code": "RUS", "currency_code": "RUB", "currency_name": "RUB", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 183, "name": "Rwanda", "dial_code": "250", "two_letter_code": "RW", "three_letter_code": "RWA", "currency_code": "RWF", "currency_name": "RWF", "currency_symbol": "FRw", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 184, "name": "Saint Helena", "dial_code": "290", "two_letter_code": "SH", "three_letter_code": "SHN", "currency_code": "SHP", "currency_name": "SHP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 185, "name": "Saint Kitts And Nevis", "dial_code": "-86", "two_letter_code": "KN", "three_letter_code": "KNA", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 186, "name": "Saint Lucia", "dial_code": "-75", "two_letter_code": "LC", "three_letter_code": "LCA", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 187, "name": "Saint Pierre and Miquelon", "dial_code": "508", "two_letter_code": "PM", "three_letter_code": "SPM", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 188, "name": "Saint Vincent And The Grenadines", "dial_code": "-78", "two_letter_code": "VC", "three_letter_code": "VCT", "currency_code": "XCD", "currency_name": "XCD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 189, "name": "Saint-Barthelemy", "dial_code": "590", "two_letter_code": "BL", "three_letter_code": "BLM", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 190, "name": "Saint-Martin (French part)", "dial_code": "590", "two_letter_code": "MF", "three_letter_code": "MAF", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 191, "name": "Samoa", "dial_code": "685", "two_letter_code": "WS", "three_letter_code": "WSM", "currency_code": "WST", "currency_name": "WST", "currency_symbol": "SAT", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 192, "name": "San Marino", "dial_code": "378", "two_letter_code": "SM", "three_letter_code": "SMR", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 193, "name": "Sao Tome and Principe", "dial_code": "239", "two_letter_code": "ST", "three_letter_code": "STP", "currency_code": "STD", "currency_name": "STD", "currency_symbol": "Db", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 194, "name": "Saudi Arabia", "dial_code": "966", "two_letter_code": "SA", "three_letter_code": "SAU", "currency_code": "SAR", "currency_name": "SAR", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 195, "name": "Senegal", "dial_code": "221", "two_letter_code": "SN", "three_letter_code": "SEN", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 196, "name": "Serbia", "dial_code": "381", "two_letter_code": "RS", "three_letter_code": "SRB", "currency_code": "RSD", "currency_name": "RSD", "currency_symbol": "din", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 197, "name": "Seychelles", "dial_code": "248", "two_letter_code": "SC", "three_letter_code": "SYC", "currency_code": "SCR", "currency_name": "SCR", "currency_symbol": "SRe", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 198, "name": "Sierra Leone", "dial_code": "232", "two_letter_code": "SL", "three_letter_code": "SLE", "currency_code": "SLL", "currency_name": "SLL", "currency_symbol": "Le", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 199, "name": "Singapore", "dial_code": "65", "two_letter_code": "SG", "three_letter_code": "SGP", "currency_code": "SGD", "currency_name": "SGD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 200, "name": "Slovakia", "dial_code": "421", "two_letter_code": "SK", "three_letter_code": "SVK", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 201, "name": "Slovenia", "dial_code": "386", "two_letter_code": "SI", "three_letter_code": "SVN", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 202, "name": "Solomon Islands", "dial_code": "677", "two_letter_code": "SB", "three_letter_code": "SLB", "currency_code": "SBD", "currency_name": "SBD", "currency_symbol": "Si$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 203, "name": "Somalia", "dial_code": "252", "two_letter_code": "SO", "three_letter_code": "SOM", "currency_code": "SOS", "currency_name": "SOS", "currency_symbol": "Sh.so.", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 204, "name": "South Africa", "dial_code": "27", "two_letter_code": "ZA", "three_letter_code": "ZAF", "currency_code": "ZAR", "currency_name": "ZAR", "currency_symbol": "R", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 205, "name": "South Georgia", "dial_code": "", "two_letter_code": "GS", "three_letter_code": "SGS", "currency_code": "GBP", "currency_name": "GBP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 206, "name": "South Sudan", "dial_code": "211", "two_letter_code": "SS", "three_letter_code": "SSD", "currency_code": "SSP", "currency_name": "SSP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 207, "name": "Spain", "dial_code": "34", "two_letter_code": "ES", "three_letter_code": "ESP", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 208, "name": "Sri Lanka", "dial_code": "94", "two_letter_code": "LK", "three_letter_code": "LKA", "currency_code": "LKR", "currency_name": "LKR", "currency_symbol": "Rs", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 209, "name": "Sudan", "dial_code": "249", "two_letter_code": "SD", "three_letter_code": "SDN", "currency_code": "SDG", "currency_name": "SDG", "currency_symbol": ".?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 210, "name": "Suriname", "dial_code": "597", "two_letter_code": "SR", "three_letter_code": "SUR", "currency_code": "SRD", "currency_name": "SRD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 211, "name": "Svalbard And Jan Mayen Islands", "dial_code": "47", "two_letter_code": "SJ", "three_letter_code": "SJM", "currency_code": "NOK", "currency_name": "NOK", "currency_symbol": "kr", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 212, "name": "Swaziland", "dial_code": "268", "two_letter_code": "SZ", "three_letter_code": "SWZ", "currency_code": "SZL", "currency_name": "SZL", "currency_symbol": "E", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 213, "name": "Sweden", "dial_code": "46", "two_letter_code": "SE", "three_letter_code": "SWE", "currency_code": "SEK", "currency_name": "SEK", "currency_symbol": "kr", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 214, "name": "Switzerland", "dial_code": "41", "two_letter_code": "CH", "three_letter_code": "CHE", "currency_code": "CHF", "currency_name": "CHF", "currency_symbol": "CHf", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 215, "name": "Syria", "dial_code": "963", "two_letter_code": "SY", "three_letter_code": "SYR", "currency_code": "SYP", "currency_name": "SYP", "currency_symbol": "LS", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 216, "name": "Taiwan", "dial_code": "886", "two_letter_code": "TW", "three_letter_code": "TWN", "currency_code": "TWD", "currency_name": "TWD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 217, "name": "Tajikistan", "dial_code": "992", "two_letter_code": "TJ", "three_letter_code": "TJK", "currency_code": "TJS", "currency_name": "TJS", "currency_symbol": "SM", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 218, "name": "Tanzania", "dial_code": "255", "two_letter_code": "TZ", "three_letter_code": "TZA", "currency_code": "TZS", "currency_name": "TZS", "currency_symbol": "TSh", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 219, "name": "Thailand", "dial_code": "66", "two_letter_code": "TH", "three_letter_code": "THA", "currency_code": "THB", "currency_name": "THB", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 220, "name": "Togo", "dial_code": "228", "two_letter_code": "TG", "three_letter_code": "TGO", "currency_code": "XOF", "currency_name": "XOF", "currency_symbol": "CFA", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 221, "name": "Tokelau", "dial_code": "690", "two_letter_code": "TK", "three_letter_code": "TKL", "currency_code": "NZD", "currency_name": "NZD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 222, "name": "Tonga", "dial_code": "676", "two_letter_code": "TO", "three_letter_code": "TON", "currency_code": "TOP", "currency_name": "TOP", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 223, "name": "Trinidad And Tobago", "dial_code": "-86", "two_letter_code": "TT", "three_letter_code": "TTO", "currency_code": "TTD", "currency_name": "TTD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 224, "name": "Tunisia", "dial_code": "216", "two_letter_code": "TN", "three_letter_code": "TUN", "currency_code": "TND", "currency_name": "TND", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 225, "name": "Turkey", "dial_code": "90", "two_letter_code": "TR", "three_letter_code": "TUR", "currency_code": "TRY", "currency_name": "TRY", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 226, "name": "Turkmenistan", "dial_code": "993", "two_letter_code": "TM", "three_letter_code": "TKM", "currency_code": "TMT", "currency_name": "TMT", "currency_symbol": "T", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 227, "name": "Turks And Caicos Islands", "dial_code": "-64", "two_letter_code": "TC", "three_letter_code": "TCA", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 228, "name": "Tuvalu", "dial_code": "688", "two_letter_code": "TV", "three_letter_code": "TUV", "currency_code": "AUD", "currency_name": "AUD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 229, "name": "Uganda", "dial_code": "256", "two_letter_code": "UG", "three_letter_code": "UGA", "currency_code": "UGX", "currency_name": "UGX", "currency_symbol": "USh", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 230, "name": "Ukraine", "dial_code": "380", "two_letter_code": "UA", "three_letter_code": "UKR", "currency_code": "UAH", "currency_name": "UAH", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 231, "name": "United Arab Emirates", "dial_code": "971", "two_letter_code": "AE", "three_letter_code": "ARE", "currency_code": "AED", "currency_name": "AED", "currency_symbol": "?.?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 232, "name": "United Kingdom", "dial_code": "44", "two_letter_code": "GB", "three_letter_code": "GBR", "currency_code": "GBP", "currency_name": "GBP", "currency_symbol": "£", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 233, "name": "United States", "dial_code": "1", "two_letter_code": "US", "three_letter_code": "USA", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 234, "name": "United States Minor Outlying Islands", "dial_code": "1", "two_letter_code": "UM", "three_letter_code": "UMI", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 235, "name": "Uruguay", "dial_code": "598", "two_letter_code": "UY", "three_letter_code": "URY", "currency_code": "UYU", "currency_name": "UYU", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 236, "name": "Uzbekistan", "dial_code": "998", "two_letter_code": "UZ", "three_letter_code": "UZB", "currency_code": "UZS", "currency_name": "UZS", "currency_symbol": "??", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 237, "name": "Vanuatu", "dial_code": "678", "two_letter_code": "VU", "three_letter_code": "VUT", "currency_code": "VUV", "currency_name": "VUV", "currency_symbol": "VT", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 238, "name": "Vatican City State (Holy See)", "dial_code": "379", "two_letter_code": "VA", "three_letter_code": "VAT", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 239, "name": "Venezuela", "dial_code": "58", "two_letter_code": "VE", "three_letter_code": "VEN", "currency_code": "VEF", "currency_name": "VEF", "currency_symbol": "Bs", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 240, "name": "Vietnam", "dial_code": "84", "two_letter_code": "VN", "three_letter_code": "VNM", "currency_code": "VND", "currency_name": "VND", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 241, "name": "Virgin Islands (British)", "dial_code": "-28", "two_letter_code": "VG", "three_letter_code": "VGB", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 242, "name": "Virgin Islands (US)", "dial_code": "-33", "two_letter_code": "VI", "three_letter_code": "VIR", "currency_code": "USD", "currency_name": "USD", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 243, "name": "Wallis And Futuna Islands", "dial_code": "681", "two_letter_code": "WF", "three_letter_code": "WLF", "currency_code": "XPF", "currency_name": "XPF", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 244, "name": "Western Sahara", "dial_code": "212", "two_letter_code": "EH", "three_letter_code": "ESH", "currency_code": "MAD", "currency_name": "MAD", "currency_symbol": "MAD", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 245, "name": "Yemen", "dial_code": "967", "two_letter_code": "YE", "three_letter_code": "YEM", "currency_code": "YER", "currency_name": "YER", "currency_symbol": "?", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 246, "name": "Zambia", "dial_code": "260", "two_letter_code": "ZM", "three_letter_code": "ZMB", "currency_code": "ZMW", "currency_name": "ZMW", "currency_symbol": "ZK", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 247, "name": "Zimbabwe", "dial_code": "263", "two_letter_code": "ZW", "three_letter_code": "ZWE", "currency_code": "ZWL", "currency_name": "ZWL", "currency_symbol": "$", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 248, "name": "Kosovo", "dial_code": "383", "two_letter_code": "XK", "three_letter_code": "XKX", "currency_code": "EUR", "currency_name": "EUR", "currency_symbol": "€", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 249, "name": "Curaçao", "dial_code": "599", "two_letter_code": "CW", "three_letter_code": "CUW", "currency_code": "ANG", "currency_name": "ANG", "currency_symbol": "ƒ", "iso_numeric": 0, "miner_currency": 2 },
    { "id": 250, "name": "Sint Maarten (Dutch part)", "dial_code": "172", "two_letter_code": "SX", "three_letter_code": "SXM", "currency_code": "ANG", "currency_name": "ANG", "currency_symbol": "ƒ", "iso_numeric": 0, "miner_currency": 2 }
];