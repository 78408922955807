import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Country } from '@fitness-force/models';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  GetCountry(BASE_URL: string): Observable<Country[]> {
    let url = BASE_URL + CONST_URL_SEGEMENTS.COUNTRY.GET_COUNTRY;
    return this.http.get(url, { headers: this.httpOptions.headers }).pipe(map((res: any) => {
      if (res) {
        return res;
      } else {
        throwError;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }
}