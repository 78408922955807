import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import {
  AppointmentTag,
  AppointmentTypes,
  CreateAppointmentTypesRequest,
} from 'libs/models/src/lib/admin/appointment-types';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { AppointmentTypeList, CreateResponse } from '@fitness-force/models';

@Injectable({
  providedIn: 'root',
})
export class AppointmentTypesService {
  constructor(private http: HttpClient, private router: Router) {}
  BASE_URL: string = environment.BASE_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  createAppointmentTypes(
    tenantId: number,
    createAppointmentTypesRequest: CreateAppointmentTypesRequest
  ) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/appointment_types`,
        createAppointmentTypesRequest,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getAppointmentTypes(tenantId: number, allParams:Array<string>):Observable<AppointmentTypeList> {
    let parsedParams = new HttpParams()
   if(allParams.length > 0){

    allParams.forEach((p: any) => {
      parsedParams = parsedParams.append(p.name, p.value);
    });
   }
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT_TYPE.GET_APPOINTMENT_TYPES.replace('{tenant_id}', tenantId.toString());
    return this.http.get(url,{headers: this.httpOptions.headers , params: parsedParams}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }
  getSingleAppointmentType(tenantId: number,appt_type_id:number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/appointment_types/${appt_type_id}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  updateAppointmentTypes(tenantId: number,appt_type_id:number,data:any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/appointment_types/${appt_type_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  markActiveAppointmentTypes(tenantId: number,appt_type_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/appointment_types/${appt_type_id}/mark_as_active`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markInActiveAppointmentTypes(tenantId: number,appt_type_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/appointment_types/${appt_type_id}/mark_as_inactive`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }





getAppointmentTags(tenantId: number):Observable<AppointmentTag[]> {
  return this.http
    .get(
      `${environment.BASE_URL}/tenants/${tenantId}/class_appt_tags`,
      this.httpOptions
    )
    .pipe(
      map((res: any) => {
        return res.items;
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
}

  // /tenants/{tenant_id}/appointment_types  .. Its in review merge ...
  // /tenants/{tenant_id}/class_appt_tags
getAppointmentSubTypes(tenantId: number,appt_type_id:number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/appointment_types/${appt_type_id}/appt_subtypes`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


}
