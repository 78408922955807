import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  ChangeDetectorRef,
  HostListener,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ClientService } from '@fitness-force/api';
import {
  CreateOpportunityComponentModule,
  CreateSmsComponentModule,
  FactoryHttpLoader,
  ProspectListActionsComponentModule,
  SendEmailComponentModule,
  TaskCreateComponentModule,
  timeLineService,
} from '@fitness-force/shared';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { Store } from '@ngrx/store';
import {
  STAFF_STATE,
  STAGE_MASTER_STATE,
  TENANTS_INFO,
} from 'apps/ff-frontend/src/app/state/app.state';
import { MessageService } from 'primeng/api';
import {
  BehaviorSubject,
  Observable,
  Subject,
  switchMap,
  combineLatest,
  map,
} from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { TagModule } from 'primeng/tag';
import { EditorModule } from 'primeng/editor';
import { TimelineModule } from 'primeng/timeline';
import { DateTimeFormatPipeModule } from '../../../filters/datetimeformat.pipe';
import { CurrencyformatPipeModule } from '../../../filters/currencyformat.pipe';
import { MemberCrownComponentModule } from '../../data-grid/utility/member-crown/member-crown.component';
import { MemberSinceComponentModule } from '../../data-grid/utility/member-since/member-since.component';

import { SubscriptionListComponentModule } from '../subscription-list/subscription-list.component';
import { HttpClient } from '@angular/common/http';
import { BdayPipeModule } from '../../../filters/bday.pipe';
import { TextTransFormPipeModule } from '../../../filters/text-transform.pipe';
import { STAGE_MASTER_MODEL } from '@fitness-force/models';
@Component({
  selector: 'basic-info',
  templateUrl: './basic-info-header.component.html',
  styleUrls: ['./basic-info-header.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoComponent implements OnInit {

  @Input('activeTab') activeTab: string = 'Subscription';

  toggleSendEmailDialog$: any = new BehaviorSubject<boolean>(false);
  toggleCreateSmsDialog$: any = new BehaviorSubject<boolean>(false);
  getAccessTenantList$: Observable<any> | any | null;
  allTenants: any = [];
  tenantId: number = 0;
  allStaffs: any = [];
  allClients$ = new BehaviorSubject(null);
  stageMaster$: Observable<any> = new Subject();
  tags: string[];

  editorText: string | undefined;

  channels: any;
  selectedChannel: { name: string };

  templates: any;
  selectedTemplate: { name: string } | any;

  attachments: any;
  selectedAttachment: { name: string };

  followUpDate: Date;

  tableData: any;
  showMoreTags: boolean = true;

  shareAppModal: boolean = false;
  sendEmailModal: boolean = false;
  sendEmailModal2: boolean = false;
  showEmailPreview: boolean = false;
  sendSMSModal: boolean = false;
  sendSMSModal2: boolean = false;
  createTemplateModal: boolean = false;
  showSmsToast: boolean = false;

  blockProfileModal: boolean = false;
  selectedtenant: any;
  showCreateOpportunityPopup = false;
  showTaskCreate: boolean = false;
  clientIds: number[] = [];
  isTabMenuSticky: boolean = false;

  clientDetails: any;
  clientId: number = 0;

  constructor(
    private store: Store,
    private clientService: ClientService,
    private messageService: MessageService,
    private activatedRouter: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.componentInit();
  }
  componentInit() {
    this.tags = ['New Member', 'High Value', 'Allergy'];
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.stageMaster$ = this.store.select(STAGE_MASTER_STATE);

    const getClientDetails$ = this.activatedRouter.params.pipe(
      switchMap((r: any) => {
        this.clientId = r.clientid;
        this.clientIds.push(r.clientid);
        return combineLatest([
          this.store.select(STAFF_STATE),
          this.store.select(TENANTS_INFO),
          this.clientService.getClientById(r.clientid),
        ]);
      })
    );

    getClientDetails$.subscribe((res) => {
      this.allStaffs = res[0].staffList;
      this.allTenants = res[1].accessed_tenant;
      this.tenantId = res[1].selected_tenant.id;
      this.selectedtenant = res[1].selected_tenant;
      this.clientDetails = res[2];
      this.allClients$.next(res[2] ? res[2] : []);
    });
  }
  @HostListener('window:scroll', ['$event']) stickyProfileInfo() {
    if (window.scrollY > 200) {
      this.isTabMenuSticky = true;
    } else {
      this.isTabMenuSticky = false;
    }
  }

  changeInAction(e: any) {
    this.componentInit();
  }

  addTags() {
    this.showMoreTags = false;
    this.tags = [
      'New Member',
      'High Value',
      'Allergy',
      'New Member',
      'High Value',
      'Allergy',
      'New Item',
    ];
  }
  isBday(bDate: string): boolean {
    if (timeLineService(bDate).label.toLowerCase() == 'today') {
      return true;
    } else {
      return false;
    }
  }

  memberSince(fromDate: string) {
    return timeLineService(fromDate);
  }

  crownType(clientSince: string) {
    let ValidDate = new Date(clientSince);
    if (isNaN(ValidDate.getDate())) {
      return { crown: 'silver-crown', coin: 'less-spent-icon' };
    }
    const duration = timeLineService(clientSince);
    if (
      duration.timeline !== 'day' &&
      duration.value >= 1 &&
      duration.value < 3 &&
      duration.type == 'ago'
    ) {
      return { crown: 'silver-crown', coin: 'less-spent-icon' };
    } else if (
      duration.timeline !== 'day' &&
      duration.value >= 3 &&
      duration.value < 5 &&
      duration.type == 'ago'
    ) {
      return { crown: 'gray-crown', coin: 'medium-spent-icon' };
    } else if (
      duration.timeline !== 'day' &&
      duration.value >= 5 &&
      duration.type == 'ago'
    ) {
      return { crown: 'gold-crown', coin: 'most-spent-icon' };
    } else {
      return { crown: 'default', coin: 'default' };
    }
  }

  toggleSendEmailDialog() {
    this.toggleSendEmailDialog$.next(true);
  }
  closeSendEmailDialog(e: boolean) {
    this.toggleSendEmailDialog$.next(false);
  }

  toggleCreateSmsDialog() {
    this.toggleCreateSmsDialog$.next(true);
  }
  closeCreateSmsDialog(e: boolean) {
    this.toggleCreateSmsDialog$.next(false);
  }
  hideOpportunity(isHide: any) {
    this.showCreateOpportunityPopup = false;
  }
  HideTaskCreate(e: any) {
    this.showTaskCreate = false;
  }
  newTask() {
    this.showTaskCreate = true;
  }

  goToTransactionTab() {
    this.router.navigate(['/client/profile', this.clientId, 'transaction']);
  }
  goToSubscriptionTab() {
    this.router.navigate(['/client/profile', this.clientId]);
  }


  clientStatus(client: any): string {
    if (client && client.client_status)
      return this.clientService.getClientStatusHtml(client.client_status.toString());
    else
      return '';
  }

  getStageStatus(stageMasterid: number): Observable<string | undefined> {
    return this.store.select(STAGE_MASTER_STATE).pipe(
      map((value: STAGE_MASTER_MODEL[]) => value.find(obj => obj.id == stageMasterid)?.name_primary_language)
    );
  }
}

@NgModule({
  declarations: [BasicInfoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    AvatarModule,
    TagModule,
    StyleClassModule,
    ChipsModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    TableModule,
    OverlayPanelModule,
    BlockUIModule,
    EditorModule,
    PasswordModule,
    DateTimeFormatPipeModule,
    CurrencyformatPipeModule,

    SendEmailComponentModule,
    CreateSmsComponentModule,
    CreateOpportunityComponentModule,
    TaskCreateComponentModule,
    RouterModule,
    DividerModule,
    TimelineModule,
    ToastModule,
    BdayPipeModule,
    SubscriptionListComponentModule,
    MemberCrownComponentModule,
    MemberSinceComponentModule,
    ProspectListActionsComponentModule,
    TextTransFormPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
    ShortNamePipeModule,
  ],
  exports: [BasicInfoComponent],
})
export class BasicInfoComponentModule { }
