<ng-container *ngIf="{row: columnData | async} as $">
  <p-overlayPanel #opclientinfo styleClass="overflow-hidden text-sm">
    <ng-template pTemplate>
          <!-- Dropdown Content -->
          <div class="bg-white -mx-4 -mt-4 flex align-items-start justify-content-between px-3 py-2">
            <div class="flex mt-2 w-10rem line-height-1">
              <span class="text-base font-medium text-800">{{$.row.client_info.full_name | titlecase }}</span>
              <span class="text-xs text-600 ml-1 mt-1">{{$.row.client_info.id}}</span>
            </div>
            <div [innerHTML]="clientStatus($.row.client_info)"></div>
          </div>
          <!-- Dropdown Content -->
          <!-- Dropdown Footer -->

<div class="px-3 py-2 -mx-4 -mb-4 bg-gray-200 text-800 text-xs" *ngIf="$.row">
  <div class="flex align-items-center mb-2" *ngIf="$.row.client_info?.mobile_number">
    <i class="pi pi-phone mr-2 text-gray-500"></i>
    {{$.row.client_info.mobile_country_code}} {{$.row.client_info.mobile_number}}
  </div>
  <div class="flex align-items-center mb-1" *ngIf="$.row.client_info?.email_id">
    <i class="pi pi-envelope mr-2 text-gray-500"></i>
    <span class="break-word">{{$.row.client_info.email_id}}</span>
  </div>
</div>
<!-- Dropdown Footer -->

    </ng-template>
  </p-overlayPanel>


  <div class="flex align-items-center" *ngIf="$.row && $.row.client_info">
    <span class="cursor-pointer flex align-items-center" (mouseenter)="opclientinfo.show($event)"
      (mouseleave)="opclientinfo.hide()" (click)="gotoClient($.row.client_info.id)">

      <p-avatar *ngIf="!$.row.client_info.image; else noContactPic"
        [label]="$.row.client_info.full_name | titlecase | shortName " styleClass="text-sm" shape="circle"></p-avatar>
      <ng-template #noContactPic>
        <p-avatar [image]="$.row.client_info.image" shape="circle"></p-avatar>
      </ng-template>

      <strong class="ml-2 text-primary">{{$.row.client_info.full_name | titlecase }}</strong>
    </span>

  </div>
</ng-container>
