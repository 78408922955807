import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { SubscriptionPlan } from '@fitness-force/models';

import { environment } from 'apps/ff-frontend/src/environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private http: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };



  createSubscriptionPlan(
    tenantId: number,
    createSubscriptionPlanRequest: SubscriptionPlan
  ) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/subscription_plans`,
        createSubscriptionPlanRequest,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateSubscriptionPlan(
    tenantId: number,
    sub_plan_od:number,
    updateSubscriptionPlanRequest: SubscriptionPlan
  ) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/subscription_plans/${sub_plan_od}`,
        updateSubscriptionPlanRequest,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getSingleSubscriptionPlan(tenantId: number,sub_plan_id:number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/subscription_plans/${sub_plan_id}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getSubscriptionPlans(tenantId: number,allParams:Array<string>=[]) {
    let parsedParams = new HttpParams()
    if(allParams.length > 0){

     allParams.forEach((p: any) => {

       parsedParams = parsedParams.append(p.name, p.value);
     });
    }
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/subscription_plans`,
        {headers: this.httpOptions.headers , params: parsedParams}
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  markActive(tenantId: number,sub_plan_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/subscription_plans/${sub_plan_id}/mark_as_active`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markInActive(tenantId: number,sub_plan_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/subscription_plans/${sub_plan_id}/mark_as_inactive`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
