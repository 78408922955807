<div class="-m-2">
  <!-- Header -->
  <div
    class="
      md:pl-4
      pl-3
      pr-5
      py-3
      flex
      align-items-center
      relative
      border-bottom-1 border-gray-300
    "
  >
    <div class="flex align-items-center">
      <i class="pi pi-plus mr-2 text-gray-500"></i>
      <h2 class="mb-0 text-base md:text-lg font-normal">
        {{ 'ADMIN.CHARGE.CREATE_CHARGE_CATEGORY' | translate }}
      </h2>
    </div>
    <div
      class="
        pi pi-times
        opacity-50
        absolute
        right-0
        top-0
        mt-3
        pt-1
        mr-3
        md:mr-4
        cursor-pointer
      "
      (click)="close()"
    ></div>
  </div>
  <!-- Header -->
  <form
    *ngIf="chargeCategoryForm"
    [formGroup]="chargeCategoryForm"
    (ngSubmit)="saveChargeCategory()"
  >
    <!-- Content -->
    <div class="surface-100 px-4 py-3 text-sm">
      <div class="grid my-0">
        <!-- Form Group -->
        <div class="mb-3 col-12 md:col-6">
          <label class="font-medium text-gray-700 text-xs mb-2 block uppercase"
            >{{ 'ADMIN.CHARGE.CHARGE_CATEGORY_NAME' | translate }} ({{ primaryLanguage | translate }})
            <sup class="p-error">*</sup></label
          >

          <input
            type="text"
            formControlName="name_primary_language"
            pInputText
            class="w-full text-sm"
            placeholder="{{ 'ADMIN.COMMON.NAME' | translate }}"
          />
          <app-error-msg
            *ngIf="CatNamePrimary?.touched && CatNamePrimary?.invalid"
            [errors]="CatNamePrimary?.errors"
            [fieldLabel]="'ADMIN.CHARGE.CHARGE_CATEGORY_NAME' | translate"
          ></app-error-msg>
        </div>
        <!-- Form Group -->

        <!-- Form Group -->
        <div class="mb-3 col-12 md:col-6">
          <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
            {{
              'ADMIN.CHARGE.CHARGE_CATEGORY_DESCRIPTION' | translate
            }}
            (English)</label
          >
          <textarea
            pInputTextarea
            formControlName="description_primary_language"
            class="w-full text-sm"
            placeholder="{{ 'ADMIN.CHARGE.DESCRIPTION' | translate }}"
          ></textarea>
          <app-error-msg
            *ngIf="DescPrimary?.touched && DescPrimary?.invalid"
            [errors]="DescPrimary?.errors"
            [fieldLabel]="
              'ADMIN.CHARGE.CHARGE_CATEGORY_DESCRIPTION' | translate
            "
          >
          </app-error-msg>
        </div>
        <!-- Form Group -->

        <!-- Form Group -->
        <div class="mb-3 col-12 md:col-6">
          <label class="font-medium text-gray-700 text-xs mb-2 block uppercase"
            >{{
              'ADMIN.CHARGE.CHARGE_CATEGORY_NAME' | translate
            }}
            ({{ secondaryLanguage | translate }})</label
          >
          <input
            type="text"
            formControlName="name_secondary_language"
            pInputText
            class="w-full text-sm"
            placeholder="Nom"
          />
          <app-error-msg
            *ngIf="CatNameSecondary?.touched && CatNameSecondary?.invalid"
            [errors]="CatNameSecondary?.errors"
            [fieldLabel]="'ADMIN.CHARGE.CHARGE_CATEGORY_NAME' | translate"
          ></app-error-msg>
        </div>
        <!-- Form Group -->

        <!-- Form Group -->
        <div class="mb-3 col-12 md:col-6">
          <label class="font-medium text-gray-700 text-xs mb-2 block uppercase"
            >{{
              'ADMIN.CHARGE.CHARGE_CATEGORY_DESCRIPTION' | translate
            }}
            (French)</label
          >
          <textarea
            pInputTextarea
            formControlName="description_secondary_language"
            class="w-full text-sm"
            placeholder="{{ 'ADMIN.CHARGE.DESCRIPTION' | translate }}"
          ></textarea>
          <app-error-msg
            *ngIf="DescSecondary?.touched && DescSecondary?.invalid"
            [errors]="DescSecondary?.errors"
            [fieldLabel]="
              'ADMIN.CHARGE.CHARGE_CATEGORY_DESCRIPTION' | translate
            "
          ></app-error-msg>
        </div>
        <!-- Form Group -->
      </div>
    </div>
    <!-- Content -->

    <!-- Footer -->
    <div
      class="
        p-3
        flex
        justify-content-between
        align-items-center
        border-top-1 border-gray-300
      "
    >
      <button
        pButton
        pRipple
        type="button"
        label="{{ 'ADMIN.COMMON.CANCEL' | translate }}"
        class="p-button-text text-sm"
        (click)="close()"
      ></button>
      <button
        pButton
        pRipple
        type="submit"
        label="{{ 'ADMIN.COMMON.SAVE' | translate }}"
        class="text-sm"
      ></button>
    </div>
    <!-- Footer -->
  </form>
</div>
