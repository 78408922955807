import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, OnChanges, SimpleChanges, Inject, LOCALE_ID, AfterViewInit } from '@angular/core';
import { StyleClassModule } from 'primeng/styleclass';
import { OmniSearchResponse, Client, PagedResults, ListClientResponse, TENANTSINTERFACE, STAGE_MASTER_MODEL } from '@fitness-force/models';
import { OmniSearchService } from 'libs/api/src/lib/omni-search.service';
import { BehaviorSubject, find, first, map, Observable, of, Subject } from 'rxjs';

import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { AvatarModule } from 'primeng/avatar';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { COMPANY_INFO, STAGE_MASTER_STATE, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClientService } from '@fitness-force/api';
import { ClientStatus, ElasticProviderName } from '@fitness-force/enum';
import { CommmonPipeModule } from '../../../filters/common.filter.pipe';
import { ShortNamePipeModule } from '../../../filters/name-initials.pipe';

@Component({
  selector: 'mini-search',
  templateUrl: './mini-search.component.html',
  styleUrls: ['./mini-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MiniSearchComponent implements OnInit {
  @Input('readonly') isReadonly: boolean = false;
  @Input('id') clientIds: number[] = [];
  @Input('clientId') clientId: number = 0;

  @Output('clients') clients = new EventEmitter<any>();

  clientIds$ = new BehaviorSubject<number[]>([]);

  paramNMore = { N: 0 };
  paramNList = { N: 0 };
  members$: BehaviorSubject<Client[]> = new BehaviorSubject([] as Client[]);
  selectedClients$ = new BehaviorSubject<ListClientResponse[]>([]);
  subject = new Subject();
  showAllMembers: boolean = false;
  searchText: string = "";
  inputValue: string = "";

  hideSearchDropdown: boolean = true;
  selectedClient$ = new BehaviorSubject<Client | null>(null);
  selectedClient: any = null;
  stage$ = new BehaviorSubject<STAGE_MASTER_MODEL | null>(null);
  prospectParams: Map<string, any> = new Map();
  tenantId: Number = 0;

  constructor(
    private omniSearchService: OmniSearchService,
    private clientService: ClientService,
    private store: Store,
    public translate: TranslateService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    let client: Client[] = [];
    this.members$.next(client);
  }

  ngOnInit(): void {
    let companyInfoDetail$ = this.store.select(COMPANY_INFO);

    companyInfoDetail$.subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.paramNMore = { N: this.clientIds.length - 3 };
    this.paramNList = { N: this.clientIds.length };

    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      if (tenant.selected_tenant.id > 0) {
        this.tenantId = tenant.selected_tenant.id;
      }
    });
    if (this.clientIds.length == 1) {
      this.clientId = this.clientIds[0];
      this.clientIds = [];
    }

    this.clientIds$.next(this.clientIds);

    if (this.clientIds.length > 0)
      this.GetCLientsByClientIds();

    if (this.clientId)
      this.GetCLientsByClientId();

  }

  GetCLientsByClientIds() {
    this.clientService.getProspects(this.getProspectParams, this.tenantId).subscribe((obj: PagedResults<ListClientResponse>) => {
      if (obj && obj.items && obj.items.length) {
        this.selectedClients$.next(obj.items);
        this.clients.emit(obj.items);
      }
    });
  }

  GetCLientsByClientId() {
    let request = {
      language: this.locale,
      query: this.clientId.toString(),
      providers: [{
        provider_name: ElasticProviderName.Clients,
        pagination: { page_number: 0, page_size: 10000 }
      }]
    };

    this.omniSearchService.Search(request).subscribe((search: OmniSearchResponse) => {
      if (search && search.searchResults && search.searchResults.clients && search.searchResults.clients.items && search.searchResults.clients.items.length) {
        let client = search.searchResults.clients.items[0];
        this.selectedClient$.next(client);
        this.clients.emit(client);
      }
    });
  }

  searchClients(event: any) {
    let request = {
      language: this.locale,
      query: event.query,
      providers: [{
        provider_name: ElasticProviderName.Clients,
        pagination: { page_number: 0, page_size: 10000 }
      }]
    };

    this.omniSearchService.Search(request).subscribe({
      next: (response: OmniSearchResponse) => {
        let client = response.searchResults.clients.items;
        this.members$.next(client);
      },
      error(msg) {
        console.log('Error Getting clients: ', msg);
      }
    });
  }

  setClient(client: Client) {
    this.selectedClient$.next(client);
    this.clientId = client.clt_id;
    this.members$.next([]);
    this.clients.emit(client);
  }

  clearClient() {
    this.selectedClient$.next(null);
    this.selectedClient = null;
    this.clientId = 0;
  }

  get getProspectParams(): Array<Map<string, any>> {
    this.prospectParams = new Map();
    this.prospectParams.set('home_club_tenant_id', { name: "home_club_tenant_id", value: this.tenantId });
    this.prospectParams.set('Prospect', { name: "client_status", value: 'Prospect' });
    this.prospectParams.set('Client', { name: "client_status", value: 'Client' });
    this.prospectParams.set('ClientNonMember', { name: "client_status", value: 'ClientNonMember' });
    this.prospectParams.set('ExClient', { name: "client_status", value: 'ExClient' });

    this.clientIds?.forEach((v: Number, i: Number) => {
      this.prospectParams.set(v.toString(), { name: "client_id", value: v });
    });

    // this.prospectParams.set(this.clientId.toString(), { name: "client_id", value: this.clientId });

    return [...this.prospectParams.values()];
  }

  clientStatus(client: Client | null): string {
    if (client && (client.clt_client_status || (client as any)?.client_status))
      return this.clientService.getClientStatusHtml((client.clt_client_status || (client as any)?.client_status).toString());
    else
      return '';
  }

  get filteredClient(): Array<any> {
    if (!this.searchText)
      return this.selectedClients$.value;

    let search = String(this.searchText).toLowerCase();
    return this.selectedClients$.value.filter(item => String(item.full_name).toLowerCase().indexOf(search) !== -1 || item.id == parseInt(this.searchText));
  }

  client2LetterName(name: string): string {
    if (name) {
      let nameArray: string[] = name.split(" ");
      let newNameArray: string[] = [];
      for (let i = 0; i < nameArray.length; i++) {
        if (newNameArray.length >= 2)
          break;

        if (nameArray[i]) {
          newNameArray.push(nameArray[i]);
        }
      }
      return newNameArray.join(" ");
    }
    else {
      return "";
    }
  }
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [MiniSearchComponent],
  imports: [
    CommonModule,
    StyleClassModule,
    FormsModule,
    InputTextModule,
    AvatarModule,
    ChipModule,
    DialogModule,
    AutoCompleteModule,

    CommmonPipeModule,
    ShortNamePipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [MiniSearchComponent]
})
export class MiniSearchComponentModule {
}
