<p-dialog [(visible)]="showErrorPopup" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'767px': '100vw'}" [style]="{width: '470px'}" styleClass="no-header-modal">
  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">
    <!-- Content -->
    <div class="relative text-center px-5 pt-5 pb-3">

      <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
        (click)="setHidePopup()"></div>

        <div class="text-center mb-4">
          <i class="pi pi-exclamation-triangle text-7xl p-error"></i>
        </div>

    <!-- <h4 class="text-lg mb-2 my-5">Are you sure?</h4>
    <p class="text-gray-600 mb-3">If you terminate this member, he will not be able to:</p> -->
    <ul class="list-none pl-0 ml-2 mb-5 text-gray-600 text-left line-height-4" *ngFor="let error of validationError">
        <li class="my-2 relative pl-5">
          <i class="pi pi-arrow-right absolute left-0 mt-2"></i>{{error}}</li>
    </ul>

    
  </div>
  <!-- Content -->

  <!-- Modal Footer -->
  <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
    <div class="flex align-items-center justify-content-between">
      <div class="w-4">
        <button pButton pRipple type="button" label="Cancel" class="p-button-secondary p-button-text text-sm"
      (click)="setHidePopup()"></button>
      </div>
      <div class="w-8 text-right">
        <p-button label="Continue" styleClass="text-sm p-button-danger" (click)="setHidePopup()">
        </p-button>
      </div>
    </div>
  </div>
  <!-- Modal Footer -->


</ng-template>
</p-dialog>
