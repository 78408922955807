import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppErrors, ErrorMsgComponentModule } from '@fitness-force/errors';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ErrorsComponentModule } from '../../../errors/errors.component';
import { FactoryHttpLoader } from '../../../helpsupport/helpsupport.component';
import { RruleComponentModule } from '../../../rrule/rrule.component';
import { MiniSearchComponentModule } from '../../../search/mini-search/mini-search.component';
import { AssignedToComponentModule } from '../../../staff/assigned-to/assigned-to.component';
import {
  HeaderComponentModule,
  SidebarComponentModule,
  FooterComponentModule,
} from '@fitness-force/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ClassType,
  ClassTypeById,
  CreateClassRequest,
  CreateRecurringClassRequest,
  RepeatRuleResponse,
  Room,
  TENANTSINTERFACE,
  TENANT_MODEL,
} from '@fitness-force/models';
import { FETCHED_TENANT_DETAILS } from 'apps/ff-frontend/src/app/state/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CLASS_TYPE_STATE,
  ROOM_LIST_STATE,
  STAFF_STATE,
  TENANTS_INFO,
} from 'apps/ff-frontend/src/app/state/app.state';
import {
  ClassTypeService,
  ClassService,
  TimeconversionService,
} from '@fitness-force/api';
import { RippleModule } from 'primeng/ripple';
import { GooglePlacesComponentModule } from '../../../google-places/google-places.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessageService } from 'primeng/api';
import { BreadcrumbComponentModule } from '../../../breadcrumb/breadcrumb.component';
import { Ienum, StaffStatus } from '@fitness-force/enum';

@Component({
  selector: 'create-class',
  templateUrl: './create-class.component.html',
  styleUrls: ['./create-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CreateClassComponent implements OnInit {
  getAccessTenantList$: Observable<any> | any;
  showClssSubType = new BehaviorSubject(false);
  getAccessedStaffList$: Observable<any> | any;
  classTypeList$ = new BehaviorSubject<ClassType[]>([]);
  classSubTypeList$ = new BehaviorSubject<any[]>([]);
  roomList$ = new BehaviorSubject<any[]>([]);

  selectedClassType: Observable<ClassType> | any;
  selectedClassSubType: Observable<any> | any;

  selectedTrainerId = new BehaviorSubject(0);
  selectedBehaviourType: any;
  selectedRoom: { name: string } | any;
  selectedTrainer:| { name: string; availability: string; ff_picture: string }| undefined;

  classTypeToShow: number = 5;
  createClassForm: FormGroup = new FormGroup({});
  minimumDate = new Date();
  RRuleObject = <RepeatRuleResponse>{};
  selectedLocation: { name: string; lat: number; lon: number } | any = {};
  isVirtal: boolean = false;

  showMaxCapacityInput: boolean = false;
  showMaxWaitlistInput: boolean = false;
  showRRule: boolean = false;

  errorDataModal = [] as any;
  errorMessages = [] as any;
  showErrorPopup$ = new BehaviorSubject(false);
  tenantId: number;

  disableSaveBtn:boolean= false;
  error = new BehaviorSubject(null as (AppErrors | null));
  filterStaffIds = new BehaviorSubject<number[]>([0]);

  constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    private fb: FormBuilder,
    private classTypeService: ClassTypeService,
    private classService: ClassService,
    private timeConversionService: TimeconversionService,
    public datePipe: DatePipe,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.componentInit();
  }

  componentInit() {
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });
      }
    });
    this.LoadForm();
    this.GetClassTypes();
    this.GetRooms();
    this.GetStaffs();
  }

  LoadForm() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.createClassForm = this.fb.group({
      classType: [''],
      classSubType: [''],
      selectedBehaviourType: [''],
      selectedTrainer: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      selectedRoom: [''],
      comments: [''],
      virtualUrl: ['', [Validators.pattern(reg)]],
      maxCapacity: [],
      maxWaitlist: [],
      isDropInAllowed: [false],
      rRule: [''],
      placeName: [''],
      placeId: [''],
      latitude: [''],
      longitude: [''],
    });
  }

  GetClassTypes() {
    this.store.select(CLASS_TYPE_STATE).subscribe((response: ClassType[]) => {
      this.classTypeList$.next(response.filter((cls) => cls.status.toLowerCase() == 'active'));
      if (this.classTypeList$.value.length > 0) {
        this.selectedClassType = this.classTypeList$.value[0];
        this.ClassTypeFormControl.setValue(this.selectedClassType);
        if(this.selectedClassType.is_room_mandatory)
        {
          this.SelectedRoomFormControl.setValidators(Validators.required);
          this.SelectedRoomFormControl.updateValueAndValidity();
        }
        this.GetClassType(this.classTypeList$.value[0].id);
      }
    });
  }

  get classTypes$(): Observable<ClassType[]> {
    return this.classTypeList$;
  }

  GetRooms() {
    this.store.dispatch({ type: 'FETCH_ROOM_LIST' });
    this.store.select(ROOM_LIST_STATE).subscribe((responseData: Room[]) => {
      this.roomList$.next(responseData);
    });
  }

  SelectClassType(classType: any) {
    if (classType && classType.value) this.selectedClassType = classType.value;
    else this.selectedClassType = classType;
    this.ClassTypeFormControl.setValue(this.selectedClassType);
    if(this.selectedClassType.is_room_mandatory)
    {
      this.SelectedRoomFormControl.setValidators(Validators.required);
      this.SelectedRoomFormControl.updateValueAndValidity();
    }
    this.GetClassType(this.selectedClassType.id);
  }

  GetClassType(classTypeId: number) {
    this.classTypeService
      .getSingleClassType(this.tenantId, classTypeId)
      .subscribe({
        next: (response: ClassTypeById) => {
          if (response.subtypes) {
            this.classSubTypeList$.next(response.subtypes);
            this.selectedClassSubType = response.subtypes[0];
            this.ClassSubTypeFormControl.setValue(this.selectedClassSubType);
            this.showClssSubType.next(true);
          } else {
            this.selectedClassSubType = null;
            this.showClssSubType.next(false);
          }
        },
        error: () => {
          this.selectedClassSubType = null;
          this.showClssSubType.next(false);
        },
      });
  }

  getSelectedTenant(selected_tenant: TENANT_MODEL): void {
    this.tenantId = selected_tenant.id;
    localStorage.setItem('TENANT_DEFAULT', selected_tenant.id.toString());
    this.store.dispatch(FETCHED_TENANT_DETAILS({ selected_tenant }));
    this.store.dispatch({ type: 'FETCH_STAFF_LIST' });
    this.router.navigate(['/crm/sales-pipeline'], {
      queryParams: { tenant: selected_tenant.id },
    });
  }

  GetStaffs()
  {
      this.store.select(STAFF_STATE).subscribe((data: any) => {
        if (data && data.staffList && data.staffList.length > 0)
        {
          let validStaff: number[] = [];
          this.getAccessedStaffList$ = data.staffList.filter((staff: any) => 
          { 
            let oStaffStatus = this.OStaffStatus.find((o: any) => (o.value == staff.status || o.key == staff.status));
            if(oStaffStatus!.value == StaffStatus.Active || oStaffStatus!.key == StaffStatus.Active && staff.can_take_class)
            {
              validStaff.push(staff.id);
              return staff;
            }
          });
          this.filterStaffIds.next(validStaff);
        }
      });
  }

  getPlaceDetails(place: any) {
    this.selectedLocation.name = place.formatted_address;
    this.selectedLocation.lat = place.geometry.location.lat();
    this.selectedLocation.lon = place.geometry.location.lng();
    this.PlaceNameControl.setValue(this.selectedLocation.name);
    this.PlaceIdFormControl.setValue(place.place_id);
    this.LatitudeFormControl.setValue(this.selectedLocation.lat);
    this.LongitudeFormControl.setValue(this.selectedLocation.lon);
  }

  SetIsVirtual(isVirtual: boolean) {
    this.isVirtal = isVirtual;
    if (isVirtual) {
      this.SelectedRoomFormControl.setValidators(null);
      this.SelectedRoomFormControl.setValue(null);
    } else {
      this.SelectedRoomFormControl.setValidators(this.selectedClassType.is_room_mandatory ? Validators.required : null);
      this.VirtualUrlFormControl.setValidators(null);
      this.VirtualUrlFormControl.setValue(null);
    }
    this.SelectedRoomFormControl.updateValueAndValidity();
    this.VirtualUrlFormControl.updateValueAndValidity();
  }

  toggleMaxCapacity(event: boolean) {
    this.showMaxCapacityInput = event;
    if (event) this.MaxCapacityFormControl.setValidators(Validators.required);
    else this.MaxCapacityFormControl.clearValidators();

    this.MaxCapacityFormControl.updateValueAndValidity()
    this.createClassForm.updateValueAndValidity();
  }

  toggleMaxWaitlist(event: boolean) {
    this.showMaxWaitlistInput = event;
    if (event) this.MaxWaitListFormControl.setValidators(Validators.required);
    else this.MaxWaitListFormControl.clearValidators();

    this.MaxWaitListFormControl.updateValueAndValidity()
    this.createClassForm.updateValueAndValidity();
  }

  FetchRRuleData(e: any): void {
    this.RRuleObject.RRule = e.RRule;
    this.RRuleObject.RRuleText = e.RRuleText;
  }

  HideRRule(e: any): void {
    this.showRRule = e;
  }

  HideErrorPopup() {
    this.showErrorPopup$?.next(false);
    this.errorMessages = [];
  }

  getSelectedColor(classType: any) {
    return `border-${classType.color}-400 bg-${classType.color}-50 text-${classType.color}-400 font-bold`;
  }


  
  CreateClass(form: FormGroup) {

    if(this.createClassForm.valid)
    {
      this.disableSaveBtn= true;
      let createClassRequest: CreateClassRequest = {} as CreateClassRequest;
      let createRecurringClassRequest: CreateRecurringClassRequest = {} as CreateRecurringClassRequest;
      createClassRequest.class_type_id = this.selectedClassType.id;
      createClassRequest.coach_id = form.value.selectedTrainer.id;
      createClassRequest.comments = form.value.comments;
      createClassRequest.is_virtual = this.isVirtal;
      createClassRequest.virtual_url = form.value.virtualUrl;
      (createClassRequest.room_id = form.value.selectedRoom? form.value.selectedRoom.id: 0),
      (createClassRequest.start_date_time = this.timeConversionService.GetUTCDateTimeByDateAndTime(form.value.startDate,form.value.startTime));
      createClassRequest.sub_type_id = this.selectedClassSubType? this.selectedClassSubType.id: 0;
      createClassRequest.is_dropin_allowed = form.value.isDropInAllowed;
      createClassRequest.place_id = form.value.placeId;
      createClassRequest.latitude = form.value.latitude ? form.value.latitude : 0;
      createClassRequest.longitude = form.value.longitude? form.value.longitude: 0;
      createClassRequest.max_capacity = form.value.maxCapacity ? form.value.maxCapacity: 0;
      createClassRequest.max_waitlist = form.value.maxWaitlist ? form.value.maxWaitlist: 0;createRecurringClassRequest = createClassRequest;
  
      if (this.RRuleObject.RRule) {
        createRecurringClassRequest.recurring_rule = this.RRuleObject.RRule;
        createRecurringClassRequest.start_date = createClassRequest.start_date_time;
        createRecurringClassRequest.start_time = this.timeConversionService.GetUTCTimeFromDate(form.value.startTime);
  
        this.classService.CreateRecurringClass(this.tenantId, createRecurringClassRequest).subscribe({
            next: async (response: any) => {
              this.translate
                .get('SCHEDULE.VIEWCLASS.MSG.CREATE_RECURRING_CLASS_MSG')
                .subscribe((translation) => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: translation,
                  });
                });
                if(response.info && response.info.length > 0)
                {
                  response.info.forEach((info: any) => {
                    this.translate.get('SCHEDULE.MSG.OVERLAP_MESSAGE',
                    { type:info.jsonObject.identifier,
                    startDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.startDateTime), 'medium'),
                    endDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.endDateTime),'medium')})
                    .subscribe((translation) => {
                     this.messageService.add({
                       severity: 'warn',
                       summary: 'warn',
                       detail: translation,
                     });
                   });
                  })
                }
                await this.timeout(1000);
                this.router.navigateByUrl('/schedule');
            },
            error: (error) => {
              this.disableSaveBtn= false;
              this.handleError(error);
            },
          });
      } else {
        this.classService.CreateClass(this.tenantId, createClassRequest)
          .subscribe({
            next: async (response: any) => {
              this.translate
                .get('SCHEDULE.VIEWCLASS.MSG.CREATE_CLASS_MSG')
                .subscribe((translation) => {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: translation,
                  });
                });
                if(response.info && response.info[0])
                {
                  let info = response.info[0];
                  this.translate.get('SCHEDULE.MSG.OVERLAP_MESSAGE',
                   { type:info.jsonObject.identifier,
                   startDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.startDateTime), 'medium'),
                   endDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.endDateTime),'medium')})
                   .subscribe((translation) => {
                    this.messageService.add({
                      severity: 'warn',
                      summary: 'warn',
                      detail: translation,
                    });
                  });
                }
                await this.timeout(1000);
                this.router.navigateByUrl('/schedule');
            },
            error: (error) => {
              this.disableSaveBtn= false;
              this.handleError(error);
            },
          });
      }
    }
    else{
      Object.keys(this.createClassForm.controls).forEach((field) => {
        const control = this.createClassForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
    
  }

  handleError(error: any) {
    this.error.next(error);
    this.showErrorPopup$.next(true);
  }


  GoToFitnessCalendar()
  {
    this.router.navigate(['/schedule']);
  }

  GoToClassType()
  {
    this.router.navigate(['/admin/class-categories/create-class-category']);
  }

  timeout(ms:any  ) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  get ClassTypeFormControl(): FormControl {
    return this.createClassForm.controls.classType as FormControl;
  }

  get ClassSubTypeFormControl(): FormControl {
    return this.createClassForm.controls.classSubType as FormControl;
  }


  get StartDateFormControl(): FormControl {
    return this.createClassForm.controls.startDate as FormControl;
  }

  get GetStartDateFormValue(): any {
    return this.createClassForm.controls.startDate.value;
  }

  get StartTimeFormControl(): FormControl {
    return this.createClassForm.controls.startTime as FormControl;
  }

  get GetStartTimeFormValue(): any {
    return this.createClassForm.controls.startTime.value;
  }

  get MaxCapacityFormControl(): FormControl {
    return this.createClassForm.controls.maxCapacity as FormControl;
  }

  get GetMaxCapacityFormValue(): any {
    return this.createClassForm.controls.maxCapacity.value;
  }

  get MaxWaitListFormControl(): FormControl {
    return this.createClassForm.controls.maxWaitlist as FormControl;
  }

  get GetMaxWaitListFormValue(): any {
    return this.createClassForm.controls.maxWaitlist.value;
  }

  get SelectedTrainerFormControl(): FormControl {
    return this.createClassForm.controls.selectedTrainer as FormControl;
  }

  get GetSelectedTrainerFormValue(): any {
    return this.createClassForm.controls.selectedTrainer.value;
  }

  get SelectedRoomFormControl(): FormControl {
    return this.createClassForm.controls.selectedRoom as FormControl;
  }
  get GetSelectedRoomFormValue(): any {
    return this.createClassForm.controls.selectedRoom.value;
  }

  get CommentsFormControl(): FormControl {
    return this.createClassForm.controls.comments as FormControl;
  }

  get GetCommentsFormValue(): any {
    return this.createClassForm.controls.comments.value;
  }

  get VirtualUrlFormControl(): FormControl {
    return this.createClassForm.controls.virtualUrl as FormControl;
  }

  get GetVirtualUrlFormValue(): any {
    return this.createClassForm.controls.virtualUrl.value;
  }

  get DropInFormControl(): FormControl {
    return this.createClassForm.controls.isDropInAllowed as FormControl;
  }

  get GetDropInFormValue(): any {
    return this.createClassForm.controls.isDropInAllowed.value;
  }

  get PlaceIdFormControl(): FormControl {
    return this.createClassForm.controls.placeId as FormControl;
  }

  get LongitudeFormControl(): FormControl {
    return this.createClassForm.controls.longitude as FormControl;
  }

  get LatitudeFormControl(): FormControl {
    return this.createClassForm.controls.latitude as FormControl;
  }

  get PlaceNameControl(): FormControl {
    return this.createClassForm.controls.placeName as FormControl;
  }

  get GetSelectedBehaviour(): any {
    return this.createClassForm.controls.selectedBehaviourType.value;
  }

  get GetSelectedClassSubType(): any {
    return this.createClassForm.controls.classSubType.value;
  }

  get isFormValid(): boolean {
    this.createClassForm.updateValueAndValidity();
    return this.createClassForm.valid;
  }
  
  get OStaffStatus(): Ienum<StaffStatus>[] {
    return Object.entries(StaffStatus).filter(key => typeof key[1] != 'number').map(([key, value]) => ({ key, value }));
  }
}

@NgModule({
  declarations: [CreateClassComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    InputTextareaModule,
    DropdownModule,
    DividerModule,
    AvatarModule,
    StyleClassModule,
    FormsModule,
    ReactiveFormsModule,
    RruleComponentModule,
    MiniSearchComponentModule,
    AssignedToComponentModule,
    ErrorsComponentModule,
    ErrorMsgComponentModule,
    ToastModule,
    MessagesModule,
    InputSwitchModule,
    BreadcrumbModule,
    HeaderComponentModule,
    SidebarComponentModule,
    FooterComponentModule,
    RippleModule,
    GooglePlacesComponentModule,
    InputNumberModule,
    BreadcrumbComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [DatePipe],
  exports: [CreateClassComponent],
})
export class CreateClassComponentModule {}


