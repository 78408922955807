<ng-container *ngIf="{
    staffs: allStaffs$ | async
} as $">
  <form [formGroup]="selectedStaffList">
    <p-multiSelect [showClear]="true" [options]="$.staffs" formControlName="selectedStaffs" optionLabel="name" [showHeader]="false"
      styleClass="w-full max-w-full text-sm" placeholder="{{
        'ADMIN.COMMON.SELECT' | translate
      }}" (onChange)="getSelectedStaff($event)"></p-multiSelect>
  </form>
  </ng-container>
