import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MinimumNumericalCheck(controlName: any, limit: any) {
    const format = /\d/;
    return (formGroup: FormGroup) => {
        if (!formGroup.controls) return;
        const control = formGroup.controls[controlName];

        if (control.errors) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails

        if (countSpecialCharacter(control.value) < limit) {
            control.setErrors({ minimumNumericalCheck: true });
        } else {
            control.setErrors(null);
        }
    }
}

function countSpecialCharacter(str: any): any {
    const punct = /1234567890/;
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (!punct.toString().includes(str[i])) {
            continue;
        }
        count++;
    }
    return count;
}
