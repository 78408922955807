<div [ngClass]="sidebarMinimized? 'ml-8 md:pl-7 md:pr-4 px-2 ':'px-2'"
    class="bg-black-alpha-90 lg:pt-1 lg:pb-1 py-2 z-100 relative flex align-items-center h-3rem">
    <a pRipple class="cursor-pointer block lg:hidden text-white mr-4" pStyleClass="#app-sidebar" enterClass="hidden"
        enterActiveClass="fadeinleft" leaveToClass="hidden" leaveActiveClass="fadeoutleft" [hideOnOutsideClick]="true">
        <i class="pi pi-bars text-xl opacity-80"></i>
    </a>
    <div class="grid mt-0 align-items-center w-12 w-8 -ml-4 md:ml-0">
        <div class="lg:col-6 xl:col-7 hidden xl:block">
            <div class="relative h-2rem w-full">
                <i class="pi pi-search absolute mt-2 ml-2 text-400 z-1"></i>
                <!-- <input type="text" placeholder="Create OR Search for Customers, Phone, Name, Email or Client ID"
                    class="w-full border-none outline-none h-full border-noround p-3 pl-5 surface-800 text-100" /> -->
                <p-autoComplete [(ngModel)]="selectedClient" [showEmptyMessage]="true"
                    [suggestions]="filteredClient$ | async" styleClass="w-full h-full"
                    [inputStyleClass]="'border-none outline-none border-noround py-3 pr-3 pl-5 surface-800 text-100 border-round'"
                    [panelStyleClass]="'omnisearch-dropdown absolute left-0 origin-top w-full bg-white border-1 border-gray-300 shadow-2 z-5 justify-content-between'"
                    (completeMethod)="searchClient($event,0)" field="clt_full_name" showClear="true"
                    [placeholder]="'Shared.SEARCH.MINI-SEARCH.PLACEHOLDER-SEARCH-CLIENT' | translate"
                    (onSelect)="navigateToClientProfile($event)">
                    <ng-template let-client pTemplate="item">
                        <div class="flex align-items-center justify-content-between">
                            <div class="flex align-items-center text-sm">
                                <ng-container *ngIf="client.clt_image">
                                    <p-avatar image="{{client.clt_image}}" shape="circle"></p-avatar>
                                </ng-container>
                                <ng-container *ngIf="!client.clt_image">
                                    <p-avatar *ngIf="!client.clt_image"
                                        [label]="client2LetterName(client.clt_full_name) | titlecase | shortName"
                                        shape="circle">
                                    </p-avatar>
                                    <!-- <p-avatar image="assets/img/avatar.jpg" shape="circle"></p-avatar> -->
                                </ng-container>
                                 <span class="mx-2" [innerHTML]="clientName(client)"></span>
                                <span class="text-600 font-normal text-xs">
                                    <span class="hidden md:inline">CLIENT ID </span>
                                    {{client.clt_id}}
                                </span>
                            </div>
                            <div [innerHTML]="clientStatus(client)"> </div>
                        </div>
                    </ng-template>
                    <!-- <ng-template let-client pTemplate="footer">
                        <div class="text-center cursor-pointer border-top-1 border-gray-300 p-3 text-sm text-gray-600 font-bold"
                            (click)="searchClient($event,pageNumber + 1)">
                            Load more results…
                        </div>
                    </ng-template> -->
                </p-autoComplete>
            </div>
        </div>
        <div class="col-8 xl:col-3 text-white flex align-items-center">
            <div class="relative lg:z-2 lg:mr-0 mr-2 w-full">
                <a pRipple
                    class="cursor-pointer flex pl-2 pr-3 py-2 align-items-center justify-content-between border-round surface-800 text-100"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                    leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <div
                        class="opacity-30 flex align-items-center w-full white-space-nowrap overflow-hidden text-overflow-ellipsis mr-2">
                        <i class="pi pi-map-marker mr-2"></i>
                        <span *ngIf="selectedTenant" class="block">{{selectedTenant.status}}
                            {{selectedTenant.live_members}} {{selectedTenant.name_primary_language}}</span>
                    </div>
                    <i class="pi pi-chevron-down opacity-30"></i>
                </a>
                <div 
                    class="search-tenants-list border-round shadow-0 lg:shadow-2 bg-white absolute text-400 hidden origin-top w-full">
                    <div class="relative h-3rem p-2 bg-gray-200">
                        <i class="pi pi-search absolute mt-2 ml-2 text-gray-600"></i>
                        <input type="text" placeholder="Search Tenants..."
                            class="w-full border-1 h-full border-round p-2 pl-5" [formControl]="searchTenants" />
                    </div>
                    <div class="px-2 py-1 h-12rem overflow-y-auto shadow-1 border-1 border-gray-300">
                        <ng-container *ngFor="let zone of zoneWiseTenantList">
                            <h4 class="text-900 text-uppercase mt-3 mb-1 px-2">{{zone.Zone}}</h4>
                            <a pRipple href="javascript:;"
                                class="p-ripple p-element p-2 block text-800 no-underline hover:bg-gray-800 hover:text-white font-medium border-round cursor-pointer transition-colors transition-duration-150"
                                *ngFor="let t of zone.Tenants | appsearchterm:searchTenants.value"
                                (click)="selectTenant(t)" pStyleClass=".search-tenants-list" enterClass="hidden"
                                enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true"
                                [transitionOptions]="'0ms'">
                                {{t.name_primary_language}}
                                <span class="p-ink"></span>
                            </a>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="xl:col-2 hidden xl:block">
                <button class="text-sm line-height-1 h-2rem" (click)="OpenPopup()" pButton pRipple label="Create"
                    icon="pi pi-plus"></button>
            </div>
        </div>
        <div class="flex justify-content-end col-4 xl:col-2">
            <ul class="list-none p-0 m-0 flex align-items-center">
                <li class="mx-2 block xl:hidden"><button class="bg-white text-primary border-primary-600 w-2rem h-2rem"
                        pButton pRipple label="" icon="pi pi-search" pStyleClass="#omnisearchMobile" enterClass="hidden"
                        enterActiveClass="fadeinleft" leaveToClass="hidden" leaveActiveClass="fadeoutleft"
                        [hideOnOutsideClick]="true"></button></li>
                <li class="mr-2 block xl:hidden"><button class="w-2rem h-2rem" pButton pRipple label=""
                        icon="pi pi-plus" (click)="OpenPopup()"></button></li>
                <li class="hidden xl:block">
                    <a pRipple class="flex p-3 xl:px-3 lg:py-2 align-items-center text-600 font-medium border-round cursor-pointer
                  transition-duration-150 transition-colors">
                        <i class="pi pi-bell text-base xl:text-2xl mr-2 lg:mr-0" pBadge severity="danger"></i>
                    </a>

                </li>

                <li class="relative" *ngIf="userDetails$ | async  as usr">
                    <a pRipple class="cursor-pointer" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein"
                        leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                        <ng-container *ngIf="usr && usr.ff_picture">
                            <p-avatar [image]="usr.ff_picture" shape=""></p-avatar>
                        </ng-container>
                        <ng-container *ngIf="!(usr && usr.ff_picture)">
                            <p-avatar [label]="client2LetterName(usr.name) | titlecase | shortName" shape=""></p-avatar>
                            <!-- <p-avatar image="assets/img/avatar.jpg" shape="circle"></p-avatar> -->
                        </ng-container>
                    </a>
                    <div class="bg-white border-round shadow-2 absolute right-0 hidden origin-top w-14rem text-sm">
                        <div class="p-2">
                            <div class="flex align-items-center justify-content-between mb-2">
                                <div class="font-bold mr-2" *ngIf="usr.name">{{usr.name}}</div>
                                <div class="bg-blue-50 border-primary border-1 px-2 py-1 font-bold border-round text-primary text-xxs"
                                    *ngIf="usr.role">{{usr.role}}</div>
                            </div>
                            <div class="flex align-items-center">
                                <i class="pi pi-phone mr-2"></i>
                                <span
                                    *ngIf="usr.mobile_country_code">{{usr.mobile_country_code}}{{usr.mobile_number}}</span>
                            </div>
                        </div>
                        <p-divider styleClass="my-0"></p-divider>
                        <div class="p-2 text-primary flex font-bold cursor-pointer align-items-center"
                            [routerLink]="'/auth/logout'">
                            <i class="pi pi-sign-out mr-2"></i>
                            <span>Logout</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!-- Mobile OmniSearch -->
    <div class="absolute top-0 mt-6 h-3rem w-full hidden -ml-2 md:-ml-4" id="omnisearchMobile">
        <i class="pi pi-search absolute mt-3 ml-2 text-400 z-1"></i>
        <p-autoComplete [(ngModel)]="selectedClient" [showEmptyMessage]="true" [suggestions]="filteredClient$ | async"
            styleClass="w-full h-full"
            [inputStyleClass]="'border-none outline-none border-noround py-3 pr-3 pl-5 surface-800 text-100'"
            [panelStyleClass]="'omnisearch-dropdown absolute left-0 origin-top w-full bg-white border-1 border-gray-300 shadow-2 z-5 justify-content-between'"
            (completeMethod)="searchClient($event ,0)" field="clt_full_name" showClear="true"
            [placeholder]="'Shared.SEARCH.MINI-SEARCH.PLACEHOLDER-SEARCH-CLIENT' | translate"
            (onSelect)="navigateToClientProfile($event)">
            <ng-template let-client pTemplate="item">
                <div class="flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                        <ng-container *ngIf="client.clt_image">
                            <p-avatar image="{{client.clt_image}}" shape="circle"></p-avatar>
                        </ng-container>
                        <ng-container *ngIf="!client.clt_image">
                            <p-avatar *ngIf="!client.clt_image"
                                [label]="client2LetterName(client.clt_full_name) | titlecase | shortName"
                                shape="circle">
                            </p-avatar>
                            <!-- <p-avatar image="assets/img/avatar.jpg" shape="circle"></p-avatar> -->
                        </ng-container>
                        <span class="mx-2" [innerHTML]="clientName(client)"></span>
                        <span class="text-600 font-normal text-xs">
                            <span class="hidden md:inline">CLIENT ID </span>
                            {{client.clt_id}}
                        </span>
                    </div>
                    <div [innerHTML]="clientStatus(client)"> </div>
                </div>
            </ng-template>
            <!-- <ng-template let-client pTemplate="footer">
            <div class="text-center border-top-1 border-gray-300 p-3 text-sm text-gray-600 font-bold"
                (click)="searchClient($event ,pageNumber + 1)">
                Load more results…
            </div>
        </ng-template> -->
        </p-autoComplete>
    </div>
    <!-- Mobile OmniSearch -->

    <!-- Models Prospect Start -->
    <create-prospect *ngIf="showCreateProspectPopup | async as showPopup" [visible]="showPopup"
        (hidePopup)="hidePopup($event)"></create-prospect>
    <!-- Models Prospect End -->
