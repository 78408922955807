import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {AvatarModule} from 'primeng/avatar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { UserAgent } from 'libs/api/src/lib/ip.service';
import { DialogModule } from 'primeng/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-help-support',
  templateUrl: './helpsupport.component.html',
  styleUrls: ['./helpsupport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpsupportComponent implements OnInit, OnDestroy {
  @Input('visibility') visibility: boolean = false;
  @Output() closeDialog:any = new EventEmitter()
  public companyInfoDetail$!: Observable<any>;
  companyDetails: any;
  machineDetails:any = {'browser':  '', 'browser-version': ''}

  constructor( private store: Store,
    public userAgent: UserAgent,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.componentInit()

  }
  closeDialogEvent(){
    this.closeDialog.emit(true)
  }
  componentInit(){
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO)
    this.companyInfoDetail$.subscribe((responseData)=>{
      this.companyDetails = responseData;
      // console.log('this.companyDetails', this.companyDetails)
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    })
    this.machineDetails = this.userAgent.uAgent
    // console.log('this.machineDetails', this.machineDetails)
  }
  ngOnDestroy(): void {
      this.closeDialog.emit(true)
  }
}

export function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [HelpsupportComponent],
  imports: [
   CommonModule,
   HttpClientModule,
   DialogModule,
   AvatarModule,
   TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: FactoryHttpLoader,
      deps: [HttpClient]
    }
  })
  ],
  exports: [HelpsupportComponent]
})
export class HelpsupportComponentModule {
}
