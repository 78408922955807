import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { STAFFINTERFACE, STATE_STAFF_LIST, TENANT_MODEL } from '@fitness-force/models';
import { ErrorsComponentModule } from '@fitness-force/shared'
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { COMPANY_INFO, STAFF_STATE, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { StyleClassModule } from 'primeng/styleclass';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { StaffService } from '@fitness-force/api';
import { environment } from 'apps/ff-frontend/src/environments/environment';

@Component({
  selector: 'change-tenant-access',
  templateUrl: './change-tenant-access.component.html',
  styleUrls: ['./change-tenant-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class ChangeTenantAccessComponent implements OnInit {
  @Input("visible") changeTenantAccesDialog: boolean = false;
  @Input("staff") staff: STAFFINTERFACE = {} as STAFFINTERFACE;
  @Output('closePopup') closePopup = new EventEmitter();

  accessTenant$ = new BehaviorSubject([] as TENANT_MODEL[]);
  formGroup: FormGroup = new FormGroup({});
  showErrorPopup = new BehaviorSubject(false);
  errorMessages = [] as any;

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private store: Store,
    private staffService: StaffService,
    private messageService: MessageService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.ComponentInit();
  }

  createForm(): void {
    this.formGroup = this.fb.group({
      new_tenant_access: new FormControl([], [Validators.required])
    });

    this.formGroup.controls.new_tenant_access.valueChanges.subscribe((staff: STAFFINTERFACE) => {
      console.log(staff);
    });
  }

  ComponentInit() {
    this.store.select(COMPANY_INFO).subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.store.select(TENANTS_INFO).subscribe((tenant) => {
      this.accessTenant$.next(tenant.accessed_tenant);

      let accesTenant: TENANT_MODEL[] = tenant.accessed_tenant.filter((t: TENANT_MODEL) => {
        return this.staff.tenant_access_id.includes(t.id);
      });

      this.formGroup.controls.new_tenant_access.setValue(accesTenant);
    });
  }

  OnStaffImgError(event: any) {
    event.target.src = './assets/img/avatar.jpg'
  }

  ClosePopup(): void {
    this.changeTenantAccesDialog = false;
    this.formGroup.reset();
    this.closePopup.emit();
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
  }

  get NEWTENANTACCESSCONTROL(): FormControl {
    return this.formGroup.controls.new_tenant_access as FormControl;
  }

  get NEWTENANTACCESS(): number[] {
    let tenants = this.formGroup.controls.new_tenant_access.value;
    return tenants.map((t: any) => t.id);
  }

  ValidateAndSubmit(): void {
    if (this.formGroup.valid) {
      let staffId = this.staff.id;
      this.staffService.ChangeTenantAccess(environment.BASE_URL, staffId, this.NEWTENANTACCESS).subscribe({
        next: (data: any) => {
          console.log(data);
          this.translate.get('common.message.record_updated').subscribe((translation) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: translation, });
          });
          this.store.dispatch({ type: 'FETCH_STAFF_LIST' });
          this.ClosePopup();
        },
        error: (error: any) => {
          console.log(error, 'errororor');
          if (error instanceof NotFoundError) {
            this.errorMessages.push(error.originalError.error.errorMessage);
            this.showErrorPopup?.next(true);
          } else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error);
              this.showErrorPopup?.next(true);
            }
            else {
              if (Array.isArray(error.originalError.error)) {
                error.originalError.error.forEach((e: any) => {
                  this.errorMessages.push(e.errorMessage);
                });
              }
              else {
                this.errorMessages.push(error.originalError.error.errorMessage);
              }
              this.showErrorPopup?.next(true);
            }
          }
        }
      });
    }
  }
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [ChangeTenantAccessComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    ButtonModule,
    StyleClassModule,
    ToastModule,
    MultiSelectModule,
    ErrorMsgComponentModule,
    ErrorsComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [ChangeTenantAccessComponent]
})
export class ChangeTenantAccessComponentModule {
}
