<ng-container *ngIf="{
  row: columnData | async,
  staff: staff$ | async
} as $">
<p-overlayPanel #opstaff styleClass="overflow-hidden text-sm">
    <ng-template pTemplate>
<!-- Dropdown Content -->
<div class="bg-white -mx-4 -mt-4 flex align-items-start justify-content-between px-3 py-2" *ngIf="$.row && $.row.client_representative_id &&  $.staff">
  <div class="flex mt-2 w-10rem line-height-1">
    <span class="text-base font-medium text-800">{{ $.staff.name | titlecase }}</span>
    <span class="text-xs text-600 ml-1 mt-1">{{ $.staff.id}}</span>
  </div>
  <span class="bg-green-500 px-2 py-1 font-bold border-round text-white text-xxs uppercase ml-4 mt-1">{{ $.staff.designation}}</span>
</div>
<!-- Dropdown Content -->

<!-- Dropdown Footer -->
<div class="px-3 py-2 -mx-4 -mb-4 bg-gray-200 text-800 text-xs" *ngIf="$.row && $.row.client_representative_id &&  $.staff">
  <div class="flex align-items-center mb-2">
    <i class="pi pi-phone mr-2 text-gray-500"></i>
   <span *ngIf="$.staff.mobile_number != 0"> {{ $.staff.mobile_country_code}} {{ $.staff.mobile_number}}</span>
   <span *ngIf="$.staff.mobile_number == 0">NA</span>
  </div>
  <div class="flex align-items-center mb-1">
    <i class="pi pi-envelope mr-2 text-gray-500"></i>
    <span class="break-word">{{ $.staff.email_id}}</span>
  </div>
</div>
<!-- Dropdown Footer -->

    </ng-template>
  </p-overlayPanel>


  <div class="flex align-items-center"   *ngIf="$.row && $.row.client_representative_id && $.staff">
    <span class="cursor-pointer flex align-items-center" (mouseenter)="opstaff.show($event)" (mouseleave)="opstaff.hide()" >

        <p-avatar *ngIf="$.staff &&  !$.staff.ff_picture" [label]="$.staff.name | titlecase | shortName" shape="circle" styleClass="text-sm"></p-avatar>
        <p-avatar *ngIf="$.staff &&  $.staff.ff_picture" [image]="$.staff.ff_picture"></p-avatar>

      <strong class="ml-2 text-primary">{{ $.staff.name | titlecase }}</strong>
    </span>

  </div>
  <div *ngIf="$.row && !$.row.client_representative_id && !$.staff">-</div>
</ng-container>
