import { CommonModule } from '@angular/common';

import { NgModule, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'appsearchterm'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter((val:any) => {
      let rVal = ((val.name_primary_language && val.name_primary_language.toLowerCase().includes(args.toLowerCase()))) ||
      (( val.email_id && val.email_id.toLowerCase().includes(args.toLowerCase()))) || (( val.id && val.id.toString().includes(args.toLowerCase())));
      return rVal ? rVal : null;
    })

  }

}


@NgModule({
    declarations: [SearchPipe],
    imports: [
      CommonModule
    ],
    exports: [SearchPipe]
  })
  export class SearchPipeModule { }