import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'trim-text',
  templateUrl: './trim-text.component.html',
  styleUrls: ['./trim-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrimTextComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  keyName!: string ;
  @Input() additionData = new BehaviorSubject(null)
  @Output() onAction = new EventEmitter();
  value!:number;
  constructor(private router :Router) { }
  ngOnInit(): void {
  }
 
  onGoToRoute(url:string, id:number){
    this.router.navigate([url, id]);
  }

}

@NgModule({
  declarations: [TrimTextComponent],
  imports: [CommonModule,TooltipModule],
  exports: [TrimTextComponent]
})
export class TrimTextComponentModule {
}
