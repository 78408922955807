import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, EventEmitter, Output } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tax-with-status',
  templateUrl: './tax-with-status.component.html',
  styleUrls: ['./tax-with-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxWithStatusComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  keyName!: string;
  @Input() additionData = new BehaviorSubject(null)
  @Output() onAction = new EventEmitter();
  isFuture = false;
  value!: any;

  constructor() { }

  ngOnInit(): void {
    this.columnData.subscribe((res: any) => {
      const seconds = Math.floor((+new Date() - +new Date(res.start_date)) / 1000);
      if (seconds < 0)
        this.isFuture = true;
    })
  }

  executeAction(action: string, columnData: any) {
    this.onAction.emit({ action, columnData: columnData });
  }
}

@NgModule({
  declarations: [TaxWithStatusComponent],
  imports: [CommonModule, TagModule],
  exports: [TaxWithStatusComponent]
})
export class TaxWithStatusComponentModule {
}
