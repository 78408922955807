import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { ClientGender } from '@fitness-force/enum';

@Component({
  selector: 'client-gender',
  templateUrl: './client-gender.component.html',
  styleUrls: ['./client-gender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientGenderComponent implements OnInit {
  @Input() additionData  = new BehaviorSubject(null)
  @Input() columnData = new BehaviorSubject(null)


  clientGender:any =ClientGender;
  
  constructor() { 
    // this.clientGender = ClientGender;
  }
  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [ClientGenderComponent],
  imports: [
    CommonModule
  ],
  exports: [ClientGenderComponent]
})
export class ClientGenderComponentModule {
}
