import { AuthSevices } from './../../../../api/src/lib/auth.service';
import { TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { select, Store } from '@ngrx/store';
import { filter, first, Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {SET_CLAIMS_DETAILS} from 'apps/ff-frontend/src/app/state/actions/claim.action'
@Injectable({
  providedIn: 'root'
})
export class TenantResolverService implements Resolve<any> {

  constructor(private store:Store, private authService: AuthSevices) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
    return this.store.pipe(
      select(TENANTS_INFO),
      tap((loaded)=>{
        this.store.dispatch(SET_CLAIMS_DETAILS({claims: this.authService.parseDecodedToken}));
        if(loaded && loaded?.selected_tenant?.id == 0){
          // console.log('callingg')
          this.store.dispatch({type: 'FETCHED_TENANT_LIST'});
        }
      }),
      filter((loaded:any) => {
        // console.log('loaded(((((((((((', loaded);
        return loaded?.selected_tenant?.id > 0 ?  true : false
      }),
      first()
    )
  }
}
