import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'commitment-view',
  templateUrl: './commitment-view.component.html',
  styleUrls: ['./commitment-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommitmentViewComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  @Input() additionData:any

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [CommitmentViewComponent],
  imports: [CommonModule],
  exports: [CommitmentViewComponent]
})
export class CommitmentViewComponentModule {
}
