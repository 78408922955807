import { RouterModule, Routes } from '@angular/router';
import { ClientService } from '@fitness-force/api';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { BadRequestError, ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { confirmationTextValidator, FactoryHttpLoader } from '@fitness-force/shared';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ShortNamePipeModule } from '../../../filters/name-initials.pipe'
@Component({
  selector: 'block-client',
  templateUrl: './block-client.component.html',
  styleUrls: ['./block-client.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockClientComponent implements OnInit {
  @Input() blockClientModal: boolean | null = false;
  @Input() clientData$:any = new BehaviorSubject(null)
  @Output() closeBlockClientDialog =  new EventEmitter()

  blockClientFromGroup: FormGroup = new FormGroup({})

  allClientData:any
  constructor(private fb: FormBuilder, public translate: TranslateService, private clientService: ClientService,  private messageService: MessageService) { }

  ngOnInit(): void {
    this.componentInit()
  }

  componentInit(){
    this.blockClientFromGroup = this.fb.group({
      confirmationText: [''],
      confirmationDescription: [''],
      clientName: []
    })
    this.clientData$.subscribe(
      (client:any)=>{
      this.allClientData =  client

      if(this.allClientData.is_blacklisted){
        this.ConfirmationText.setValidators([Validators.required, confirmationTextValidator('clientName','unblocked',  false)]);

       } else {
        this.ConfirmationText.setValidators([Validators.required, confirmationTextValidator('clientName','blocked',  false)]);
        this.ConfirmationDescription.setValidators([Validators.required]);
       }
      this.ClientName.setValue(this.allClientData.full_name)
    })


  }
  closeDialogEvent(action:string|null=null){
    this.closeBlockClientDialog.emit({close:true,action})
    this.ConfirmationDescription.setValidators(null);
  }

  blockUnBlockClient(){
    const blockClientModel = {
      "reason": this.ConfirmationDescription.value
    }
    if(this.allClientData.is_blacklisted){
      this.clientService.clientRemoveFromBlacklist(this.allClientData.id, this.allClientData.home_club_tenant_id).subscribe({
        next: (res:any) =>{
          // console.log('resunblocked', res)
          this.messageService.add({severity:'success', summary:'Success!', detail: `${this.allClientData.full_name} has been unblocked.`});
          this.closeDialogEvent("block");
        },
        error: (error)=>{
          // console.log('errrr', error)
          if (error instanceof NotFoundError) {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Page Not Found'});
          } else if (error instanceof BadRequestError) {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail: error.originalError.error[0].errorMessage});
          }
          else {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Something went wrong'});
          }
        }
      })
    } else {
      this.clientService.clientAddToBlacklist(this.allClientData.id,this.allClientData.home_club_tenant_id, blockClientModel).subscribe({
        next: (res:any) =>{
          // console.log('resonblocked', res)
          this.messageService.add({severity:'success', summary:'Success!', detail: `${this.allClientData.full_name} has been blocked.`});
          this.closeDialogEvent("unblock")
        },
        error: (error)=>{
          // console.log('errrr', error)
          if (error instanceof NotFoundError) {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Page Not Found'});
          } else if (error instanceof BadRequestError) {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail: error.originalError.error[0].errorMessage});
          }
          else {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Something went wrong'});
          }
        }
      })
    }
  }
  get ConfirmationText(): FormControl{
    return this.blockClientFromGroup.get('confirmationText') as FormControl;
  }
  get ConfirmationDescription(): FormControl{
    return this.blockClientFromGroup.get('confirmationDescription') as FormControl;
  }

  get ClientName(): FormControl{
    return this.blockClientFromGroup.get('clientName') as FormControl;
  }

}

@NgModule({
  declarations: [BlockClientComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    InputTextareaModule,
    PasswordModule,
    ShortNamePipeModule,
    AvatarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),
    ErrorMsgComponentModule,
    ToastModule
  ],
  exports: [BlockClientComponent]
})
export class BlockClientComponentModule {
}
