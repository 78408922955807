export enum TourOutcome {
  NotInterested = 1,
  NoShow = 2,
  Reschedule = 3,
  StillThinking = 4,
  SignUp = 5,
  BookTrial = 6
}
export enum BOARD_MASTER_IDS {
  LOST = 14,
  WON = 15
}
export enum CallOutcome {
  NoAnswer_LeftMessage = 1,
  ContactMade = 2,
  BookTour = 3,
  SignUp = 4,
  NotIntrested = 5

}

export enum OpportunityStageForNonMember {
  NewLeads = 1,
  ContactMade = 2,
  TourBooked = 3,
  TourCompleted = 4,
  TrialBooked = 5,
  TrialCompleted = 6,
  Other = 7
}

export enum OpportunityBoardType {
  NewMemberBoard = 1,
  ExistingMemberBoard = 2
}

export enum OpportunitiesType {
  Cold = 1,
  Warm = 2,
  Hot = 3
}

export enum OpportunitiesOutcome {
  InProgress = 0,
  Won = 1,
  Lost = 2,
  Nurture = 3
}


export enum TaskAssignToType {
  SpecificStaff = 1,
  ClientRep = 2
}

export enum TaskType {
  Call = 1,
  Sms = 2,
  Email = 3,
  Task = 4,
  Tour = 5
}

export enum TaskPriority {
  High = 1,
  Med = 2,
  low = 3
}
