import { NgModule, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'TimeLine',
})
export class TimeLinePipe implements PipeTransform {

  transform(value: any) {
    return timeLineService(value)
  }
}
export function timeLineService(value:any):any{
  if (value) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
   const firstDate =new Date();
    let nextDate = new Date(value);

    // Timezone modification
    const secondDate = addDateMinutes(nextDate,-1*nextDate.getTimezoneOffset());// new Date(nextDate.toLocaleString())

    const diffDays = Math.round(
      (firstDate.getTime() - secondDate.getTime()) / oneDay
    );
    const noRounddiffDays = (firstDate.getTime() - secondDate.getTime()) / oneDay
    if (diffDays === 0) {
      return { label: 'Today',task_label:"due  Today", status: 'task-due', value: 0, exact: 'Today', type: 'today'};
    } else {

      if(diffDays == 1){
          return { label: 'Yesterday',task_label:"overdue  Yesterday", status: 'task-overdue', value: -1, exact: 'Yesterday', type: 'yesterday'};
      } else if(diffDays == -1 &&  Math.sign(diffDays) == -1){
          return { label: 'Tomorrow',task_label:"due  tomorrow", status: 'task-due',  value: 1, exact: 'Tomorrow', type: 'tomorrow'};
      } else if(Math.abs(diffDays) < 365 && Math.abs(diffDays) >= 30){
         let totalMonth = Math.round(Math.abs(diffDays) / 30)
         let exactDays= Math.abs(totalMonth*30 - Math.abs(noRounddiffDays))
         let mo='months';
         if(totalMonth===1) mo="month"
          if(Math.sign(diffDays) == -1) {
            return { label: `${totalMonth} ${mo}` ,  value: `${totalMonth}` ,task_label:`due in ${totalMonth} ${mo}`, status: 'normal', exact: exactDays > 0 ? `${totalMonth} ${mo} ${exactDays} Days`: `${totalMonth} ${mo}`, type: 'togo', timeline: 'month'};
          } else {
            return { label: `${totalMonth} ${mo}`,task_label:`overdue by ${totalMonth} ${mo}`, value: `${totalMonth}`, status: 'task-overdue', exact:  exactDays > 0 ? `${totalMonth} ${mo} ${exactDays} Days`: `${totalMonth} ${mo}`, type: 'ago', timeline: 'month'};

          }
      } else if(Math.abs(diffDays) >= 365){
          let totalMonth = Math.round(Math.abs(diffDays) / 365)
          let exactMonths=   Math.round(Math.abs((totalMonth  - Math.abs(noRounddiffDays) / 365) * 12))
          let ye='years';
          if(totalMonth===1) ye="year"
           if(Math.sign(diffDays) == -1) {
            return { label: `${totalMonth} ${ye}`,task_label:`due in ${totalMonth} ${ye}`, status: 'normal', value: `${totalMonth}`, exact: exactMonths > 0 ? `${totalMonth} ${ye} ${exactMonths} Month`: `${totalMonth} ${ye}`, type: 'togo',  timeline: 'year'};
           } else {
            return { label: `${totalMonth} ${ye}`,task_label:`overdue by ${totalMonth} ${ye}`, status: 'task-overdue', value: `${totalMonth}`, exact: exactMonths > 0 ? `${totalMonth} ${ye} ${exactMonths} Month`: `${totalMonth} ${ye}`, type: 'ago',  timeline: 'year'};

           }
       }  else if( Math.abs(diffDays) < 30 && Math.abs(diffDays) > 1){

           if(Math.sign(diffDays) == -1) {

             return { label: `${Math.abs(diffDays)} days`,task_label:`due in ${Math.abs(diffDays)} days`, status: 'normal ',value: `${Math.abs(diffDays)}`, exact: `${Math.abs(diffDays)}days`, type: 'togo',  timeline: 'day'};

           } else {
            return { label: `${diffDays} days`,task_label:`overdue by ${Math.abs(diffDays)} days`, status: 'task-overdue',value: `${Math.abs(diffDays)}`, exact: `${Math.abs(diffDays)}days`, type: 'ago',  timeline: 'day'};
           }
       }
      else {
          return diffDays + 'Days';
      }
    }
  } else {
    return { label: '', status: 'no-task'};
  }

}
function addDateMinutes(date: Date, minutes: number) {
//  date = new Date(date.getTime());
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

@NgModule({
  declarations: [TimeLinePipe],
  imports: [],
  exports: [TimeLinePipe],
})
export class  TimeLinePipeModule {}
