import { createAction, props } from '@ngrx/store';


/* COMPANY DETAILS*/
export const FETCHED_COMPANY_DETAILS =  createAction(
    'FETCHEDCOMPANYDETAILS',
    props<{ companyDetails: any }>()
);
export const ERROR_COMPANY_DETAILS =  createAction(
    'ERRORCOMPANYDETAILS',
    props<{ err: any }>()
);


/*LANGUAGE*/
export const SELECT_LANGUAGE = createAction(
    'SELECTLANGUAGE',
    props<{ lang: any }>()
);
export const DIRECTION_DETAILS = createAction(
    'DIRECTIONDETAILS',
    props<{ dir: any }>()
);
