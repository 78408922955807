<div [formGroup]="formGroup">
  <!-- (click)="onClickStaff($event)"      -->
  <p-dropdown *ngIf="staffs$ | async as staffs" optionLabel="name" tooltip="{{controlName.value.name}}" tooltipStyleClass="text-xs" [filter]="true" filterBy="name" 
  (onChange)="onSelectStaff(SelectedStaff)" 
  placeholder="{{placeHolder}}" styleClass="{{styleClass}}" [options]="staffs" [formControl]="controlName" [resetFilterOnHide]="true">
    <ng-template pTemplate="selectedItem">
      <div class="flex align-items-center justify-content-between" *ngIf="controlName.value">
        <span class="w-2rem absolute left-0">
            <p-avatar *ngIf="controlName.value.ff_picture; else noContactPic1" [image]="controlName.value.ff_picture" class="h-full"></p-avatar>
            <ng-template #noContactPic1>
              <p-avatar styleClass="text-sm w-2rem h-2rem mr-2"  label="{{controlName.value.name || controlName.value.name_primary_language | titlecase | shortName}}"
                shape="circle"></p-avatar>
            </ng-template>
        </span>
        <strong class="text-900 ml-5 w-7rem overflow-hidden text-overflow-ellipsis">{{controlName.value.name}}</strong>
      </div>
    </ng-template>
    <ng-template let-staffs pTemplate="item">
      <div class="assigned-item flex align-items-center">
        <span class="w-2rem absolute left-0 ml-3">
            <p-avatar *ngIf="staffs.ff_picture; else noContactPic1" [image]="staffs.ff_picture" class=" mt-1"></p-avatar>
            <ng-template #noContactPic1>
              <p-avatar styleClass="text-sm w-2rem h-2rem mr-2"  label="{{staffs.name  | titlecase | shortName}}"
                shape="circle"></p-avatar>
            </ng-template>
        </span>
        <div class="text-sm ml-5 w-6rem md:w-7rem overflow-hidden text-overflow-ellipsis">{{staffs.name}}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div> 