import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CyptoSevice{
    tokenFromUI = '0123456789123456';
    encrypted: any = '';
    decrypted = '';

    constructor() {
    }
    encryptUsingAES256(request: any): string {
      const key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      const iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(request), key, {
          keySize: 16,
          iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
      return this.encrypted = encrypted.toString();
    }
    decryptUsingAES256(response: any): string {
      const key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      const iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

      return this.decrypted = CryptoJS.AES.decrypt(
        response, key, {
          keySize: 16,
          iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
    }
}
