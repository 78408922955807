̥
<!-- Paid Full Sidebar -->
<!-- (onHide)="setHideSideBar($event)"  -->
<p-sidebar [(visible)]="showPaidFullSidebar" [showCloseIcon]="false" position="right" [blockScroll]="true"
    styleClass="w-full payment-sidebar" [dismissible]="false" [closeOnEscape]="false"
    (onHide)="closeBillingSideBar('')">

    <form [formGroup]="fg">
        <!-- Header -->
        <div class="-mt-3 p-3 border-bottom-1 border-gray-300 relative flex align-items-center justify-content-between">
            <h4 class="flex align-items-center mb-0 mr-3 font-normal">
                <i class="pi pi-shopping-cart text-gray-400 mr-3"></i>
                <span class="text-lg font-normal">
                    {{'POS.MEMBERSHIP_ADDON.BILL_SUMMARY' | translate}}
                </span>
            </h4>

            <!-- Close Icon -->
            <i class="pi pi-times text-gray-600 cursor-pointer absolute right-0 top-0 mt-3 mr-3"
                (click)="closeBillingSideBar('')"></i>
            <!-- Close Icon -->
        </div>
        <!-- Header -->

        <div *ngIf="planSummary$ | async as planSummary;"
            class="flex px-3 pb-2 pt-3 align-items-center justify-content-between">
            <div class="flex align-items-center">
                <div class="h-2rem w-2rem">
                    <p-avatar image="/assets/img/avatar.jpg" size="small" shape="circle"></p-avatar>
                </div>
                <h3 class="mb-0 ml-2 md:-mt-1">
                    <a href="../client/profile/{{ planSummary.client.client_id }}"
                        class="text-primary text-base mr-2 font-bold border-bottom-1 border-white hover:border-primary">
                        {{planSummary.client.client_name}}
                    </a>
                    <span class="text-600 font-normal text-sm">
                        {{'common.DATA.CLIENT_ID' | translate}} - {{planSummary.client.client_id}}

                    </span>
                </h3>
            </div>
            <div [innerHTML]="clientStatus( planSummary.client)"></div>
        </div>

        <div class="shadow-2 border-round p-3 mx-3 my-2">
            <ng-container *ngIf="planSubscription$ | async as obj; else templateB">
                <p-dropdown *ngIf="disablePlanDropdown" [options]="planSubscription$ | async"
                    formControlName="ddlplanSubscriptionList" optionLabel="name_primary_language"
                    filterBy="name_primary_language" [filter]="true" styleClass="w-full text-sm"
                    placeholder="{{'POS.MEMBERSHIP_ADDON.SELECT_PRODUCT' | translate}}">
                    <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center text-xs md:text-sm" *ngIf="planList">

                            <span
                                class="bg-blue-50 uppercase px-2 py-1 border-round text-primary border-1 border-primary text-xxs">
                                {{getPlanType(planList.value.subscription_category)}}
                            </span>

                            <span class="mx-2">
                                {{planList.value.name_primary_language}}
                            </span>
                            <strong>
                                {{getPlanAmount(planList.value) | currencyformat}}
                            </strong>
                        </div>
                    </ng-template>
                    <ng-template let-memberships pTemplate="item">
                        <div class="flex align-items-center text-xxs md:text-sm">

                            <span
                                class="bg-blue-50 uppercase px-2 py-1 border-round text-primary border-1 border-primary text-xxs">
                                {{getPlanType(memberships.subscription_category)}}
                            </span>

                            <span class="mx-2">
                                {{memberships.name_primary_language}}
                            </span>
                            <strong>
                                {{getPlanAmount(memberships) | currencyformat}}
                            </strong>
                        </div>
                    </ng-template>
                </p-dropdown>
            </ng-container>
            <ng-template #templateB>
                <div class="skeleton">
                    <p-skeleton styleClass="p-mb-2"></p-skeleton>
                </div>
            </ng-template>

            <ng-container *ngIf="(planDetail$ | async) as obj; else templateA">
                <div *ngIf="!disablePlanDropdown"
                    class="bg-blue-50 inline-block px-2 py-1 border-round text-primary text-xs border-primary border-1">
                    {{'POS.PLANTYPE.' + obj.subscription_category.toUpperCase() | translate }}
                </div>

                <div class="flex justify-content-between mt-3 mb-2">
                    <div>
                        <h3 class="text-base font-bold mb-2">
                            {{obj.name_primary_language}}
                        </h3>
                        <p class="text-xs text-gray-600 mb-0">
                            {{obj.description_primary_language}}
                        </p>
                    </div>
                    <div class="white-space-nowrap ml-4 text-lg text-right">
                        <ng-container *ngIf="isPlanBehaviourRecurring(obj.subscription_category)">
                            <strong *ngIf="recurringCost$ | async as recurringCost">
                                {{ recurringCost | currencyformat }}
                            </strong>
                            <sub class="text-xs">/
                                {{ (this.billingInterval> 1)? this.billingInterval.toString() : ""}}
                                {{ 'POS.MEMBERSHIP_ADDON.' +
                                this.getInterval(this.billingInterval,this.biilingIntervalType) |
                                translate}}
                            </sub>
                            <i class="block text-gray-600 text-xxs">
                                {{'POS.MEMBERSHIP_ADDON.PRICEWITHTAX' | translate}}
                            </i>
                        </ng-container>
                        <ng-container *ngIf="!isPlanBehaviourRecurring(obj.subscription_category)">
                            <strong *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                {{ totalOneTimeCost | currencyformat }}
                            </strong>
                            <i class="block text-gray-600 text-xxs">
                                {{'POS.MEMBERSHIP_ADDON.PRICEWITHTAX' | translate}}
                            </i>
                        </ng-container>
                    </div>
                </div>

                <p-divider styleClass="m-0"></p-divider>

                <p class="mb-0 md:mb-1 mt-2 text-xs text-gray-600" [innerHTML]="allServiceNameHtml(obj)"> </p>

                <p-divider styleClass="mt-2 mb-0"></p-divider>

                <div class="grid mb-0 mt-1 text-xs white-space-nowrap">
                    <div class="col-12 md:col-6">
                        <h5 class="text-sm mb-2">
                            {{'POS.MEMBERSHIP_ADDON.START_DATE' | translate}}
                        </h5>

                        <p-calendar [showIcon]="true" formControlName="subscriptionstartdate" [readonlyInput]="true"
                            placeholder="{{'common.PLACEHOLDER.SELECT_DATE' | translate}}" inputStyleClass="text-sm"
                            styleClass="w-full" (onSelect)="GetRevisedPrice_OnStartDateChange()"></p-calendar>
                    </div>
                    <ng-container *ngIf="isPlanBehaviourRecurring(obj.subscription_category)">
                        <div class="col-12 md:col-6">
                            <h5 class="text-sm mb-2">
                                {{'POS.MEMBERSHIP_ADDON.BILLING_CYCLE' | translate}}
                            </h5>

                            <p-dropdown *ngIf="billingCycleOption$ | async as cycleOption" [options]="cycleOption"
                                formControlName="ddlBillingCycle" optionLabel="name" filterBy="name"
                                styleClass="w-full text-sm" placeholder="{{'common.PLACEHOLDER.SELECT' |  translate}}"
                                (onChange)="GetRevisedPrice_OnCycleChange()">
                                <ng-template pTemplate="selectedItem">
                                    <div class="text-sm" *ngIf="billingCycle.value">
                                        {{billingCycle.value.name}}
                                    </div>
                                </ng-template>
                                <ng-template let-billingCycle pTemplate="item">
                                    <div class="text-sm">
                                        {{billingCycle.name}}
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-template #templateA>
                <div class="skeleton" *ngIf="subscriptionPlanId > 0">
                    <br />
                    <p-skeleton styleClass="p-mb-2"></p-skeleton>
                    <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton>
                    <p-skeleton width="5rem" styleClass="p-mb-2"></p-skeleton>
                    <p-skeleton height="2rem" styleClass="p-mb-2"></p-skeleton>
                    <p-skeleton width="10rem" height="4rem"></p-skeleton>
                </div>
            </ng-template>
        </div>

        <!-- Info Card -->

        <!-- Mid Part -->
        <div class="surface-50 p-3 border-top-1 border-gray-300 mt-3 ng-tns-c274-0">
            <!-- Bill Details -->
            <div class="text-sm mb-2">
                <h5 class="text-sm mb-2">{{'POS.MEMBERSHIP_ADDON.BILLDETAILS' | translate}}</h5>
                <ng-container *ngIf="(planBreakUpCalculation$ | async) as billingBreakup; else templateC">
                    <!-- {{oJSON.stringify(billingBreakup)}} -->
                    <div *ngIf="!applyDiscount" class="border-1 border-gray-300 border-round text-sm bg-white">
                        <ng-container *ngIf="subscription_category$ | async as subscription_category;">
                            <ng-container *ngIf="isContract$.getValue()">
                                <div
                                    class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer">
                                    <div>
                                        <div style="display: inline-flex;"
                                            [innerHTML]="'POS.MEMBERSHIP_ADDON.FIRSTCHARGE' | translate">
                                        </div>
                                        (
                                        <div style="display: inline-flex;"
                                            [innerHTML]="ordinalNumber(billingBreakup.charging_today.service_first_charge.service_start_date|datetimeformat:'date')">
                                        </div>
                                        {{
                                        monthName(billingBreakup.charging_today.service_first_charge.service_start_date)
                                        }} to <div style="display: inline-flex;"
                                            [innerHTML]="ordinalNumber(billingBreakup.charging_today.service_first_charge.service_end_date  | datetimeformat:'date')">
                                        </div>
                                        {{
                                        monthName(billingBreakup.charging_today.service_first_charge.service_end_date)
                                        }}
                                        )
                                    </div>
                                    <div *ngIf="netCost$ | async as netCost">
                                        {{ netCost | currencyformat }}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-container
                            *ngIf="billingBreakup.charging_today.service_non_recurring.services && billingBreakup.charging_today.service_non_recurring.services.length > 0">
                            <div
                                class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer">
                                <span *ngIf="planDetail$ | async  as serv">
                                    {{'POS.MEMBERSHIP_ADDON.ONETIMECHARGE' | translate}}
                                    <br>
                                    {{getConcatStringfromArray(serv.non_recurring_services)}}
                                </span>
                                <span *ngIf="nonRecurringNetCost$ | async as netCost">
                                    {{ netCost | currencyformat }}
                                </span>
                            </div>
                        </ng-container>

                        <ng-container *ngFor="let charges of billingBreakup.charging_today.signup_charges">
                            <div
                                class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer">
                                <span>{{charges.name_primary_language}}</span>
                                <span>
                                    {{ getChargeNetCost(charges) | currencyformat}}
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isInclusive">
                            <div
                                class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer">
                                <div class="cursor-pointer text-primary flex align-items-center"
                                    tooltipStyleClass="text-xs" tooltipPosition="top" pTooltip="
                                GSTIN &nbsp; &nbsp; &nbsp; &nbsp; 2% 
                                GSTIN &nbsp; &nbsp; &nbsp; &nbsp; 2%">
                                    <span class="mr-2">{{'POS.MEMBERSHIP_ADDON.TAXES' | translate}}</span><i
                                        class="pi pi-info-circle text-lg text-500 cursor-pointer"></i>
                                </div>
                                <span>
                                    {{
                                    getTotalTaxAmount(billingBreakup.charging_today.service_first_charge.services)
                                    +
                                    getTotalTaxAmount(billingBreakup.charging_today.service_non_recurring.services)
                                    + getTotalChargeTaxAmount(billingBreakup.charging_today.signup_charges)
                                    | currencyformat
                                    }}
                                </span>
                            </div>
                        </ng-container>
                        <div *ngIf="!discountApplied" class="px-3 py-2 flex align-items-center justify-content-between">
                            <p-button (click)="applyDiscount=true"
                                label="{{'POS.MEMBERSHIP_ADDON.APPLY_DISCOUNT' | translate}}" class="text-xs"
                                styleClass="text-primary bg-white py-1 border-gray-400">
                            </p-button>
                        </div>
                        <div *ngIf="discountApplied" class="px-3 py-2 flex align-items-center justify-content-between">
                            <p-button (click)="applyDiscount=true"
                                label="{{'POS.MEMBERSHIP_ADDON.EDIT_DISCOUNT' | translate}}" class="text-xs"
                                styleClass="text-primary bg-white py-1 border-gray-400">
                            </p-button>
                            <button (click)="discountApplied=false;RemoveDiscount()" pButton type="button"
                                icon="pi pi-times-circle" label="{{'POS.MEMBERSHIP_ADDON.REMOVE_DISCOUNT' | translate}}"
                                class="text-xs p-button-text -mr-3"></button>
                        </div>
                        <div
                            class="px-3 py-2 flex align-items-center justify-content-between surface-900 text-white border-round-bottom mt-2">
                            <span>{{'POS.MEMBERSHIP_ADDON.GRAND_TOTAL' | translate}}</span>
                            <span *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                {{totalOneTimeCost | currencyformat}}
                            </span>
                        </div>

                    </div>
                    <!-- Discount Area -->
                    <div *ngIf="applyDiscount">
                        <div [formGroup]="fgProducts">
                            <div class="border-1 border-gray-300 border-round text-sm bg-white">
                                <div class="px-3 py-2 flex align-items-center justify-content-between">
                                    <strong class="flex align-items-center">One-time Discount <i
                                            class="pi pi-question-circle text-base text-500 ml-2 cursor-pointer"
                                            pTooltip="This is a sample!" tooltipStyleClass="text-xs"></i></strong>
                                </div>
                                <div *ngIf="isContract$.getValue()"
                                    class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer_edit">
                                    <span class="flex align-items-center">
                                        1<sup>st&nbsp;</sup>{{'POS.MEMBERSHIP_ADDON.FIRSTCHARGE' | translate}}
                                        <i class="pi pi-question-circle text-base text-500 ml-2 cursor-pointer"
                                            pTooltip="This is a sample!" tooltipStyleClass="text-xs">
                                        </i>
                                    </span>
                                    <div class="flex align-items-center ml-3 md:ml-0">
                                        <span>
                                            {{getTotalBaseCost(billingBreakup.charging_today.service_first_charge.services)
                                            | currencyformat}}
                                        </span>
                                        <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"
                                            formControlName="frmFirstTimeAmt"
                                            styleClass="w-4rem h-2rem mx-2 px-1 text-sm" inputStyleClass="px-1"
                                            (onBlur)="calculateDiscount(frmFirstTimeBaseCostAmountFormControl, firstTimeAmountFormControl, firstTimeDiscountFormControl)">
                                        </p-inputNumber>
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">%</span>
                                            <p-inputNumber styleClass="w-3rem -ml-1 px-0 text-sm" inputStyleClass="px-1"
                                                currency="USD" locale="en-US" [minFractionDigits]="2"
                                                [maxFractionDigits]="2" [formControl]="firstTimeDiscountFormControl"
                                                (onBlur)="calculateAmount(frmFirstTimeBaseCostAmountFormControl,firstTimeAmountFormControl, firstTimeDiscountFormControl)">
                                            </p-inputNumber>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="(planDetail$ | async)  as serv;">
                                    <div *ngIf="billingBreakup.charging_today.service_non_recurring.services && billingBreakup.charging_today.service_non_recurring.services.length > 0"
                                        class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer_edit">
                                        <span class="flex align-items-center">{{'POS.MEMBERSHIP_ADDON.ONETIMECHARGE'
                                            |
                                            translate}}<br>
                                            {{getConcatStringfromArray(serv.non_recurring_services)}}
                                            <i class="pi pi-question-circle text-base text-500 ml-2 cursor-pointer"
                                                pTooltip="This is a sample!" tooltipStyleClass="text-xs"></i></span>

                                        <div class="flex align-items-center ml-3 md:ml-0">
                                            <span>
                                                {{getTotalBaseCost(billingBreakup.charging_today.service_non_recurring.services)
                                                | currencyformat}}
                                            </span>

                                            <p-inputNumber formControlName="frmNonRecurringAmt" [minFractionDigits]="2"
                                                [maxFractionDigits]="2" styleClass="w-4rem h-2rem mx-2 px-1 text-sm"
                                                inputStyleClass="px-1"
                                                (onBlur)="calculateDiscount(frmNonRecurringBaseCostAmountFormControl,nonRecurringAmountFormControl, nonRecurringDiscountFormControl)">
                                            </p-inputNumber>
                                            <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">%</span>
                                                <p-inputNumber styleClass="w-3rem -ml-1 px-0 text-sm"
                                                    inputStyleClass="px-1" [minFractionDigits]="2"
                                                    [maxFractionDigits]="2" currency="USD" locale="en-US"
                                                    formControlName="frmNonRecurringDis"
                                                    (onBlur)="calculateAmount(frmNonRecurringBaseCostAmountFormControl,nonRecurringAmountFormControl, nonRecurringDiscountFormControl)">
                                                </p-inputNumber>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- <div *ngFor="let controls of charges.controls; let n=index"> -->
                                <div formArrayName="charges" class="servicechargecontainer_edit"
                                    *ngFor="let charges of (planBreakUpCalculation$ | async)?.charging_today?.signup_charges;let n=index">
                                    <div [formGroupName]="0"
                                        class="px-3 py-2 flex align-items-center justify-content-between">
                                        <span>{{charges.name_primary_language}}</span>
                                        <div class="flex align-items-center">
                                            <span>{{charges.amount_in_minor_currency |
                                                currencyformat}}</span>
                                            <p-inputNumber [minFractionDigits]="2" [maxFractionDigits]="2"
                                                [formControlName]='"charges_"+charges.id'
                                                styleClass="w-4rem h-2rem mx-2 text-sm ng-invalid ng-dirty"
                                                inputStyleClass="px-1"
                                                (onBlur)="calculateChargeDiscount(charges.amount_in_minor_currency,'charges_'+charges.id, 'discount_'+charges.id)">
                                            </p-inputNumber>
                                            <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">%</span>
                                                <p-inputNumber [formControlName]='"discount_"+charges.id'
                                                    [minFractionDigits]="2" [maxFractionDigits]="2"
                                                    styleClass="w-3rem -ml-1 px-0 text-sm" inputStyleClass="px-1"
                                                    (onBlur)="calculateChargeAmount(charges.amount_in_minor_currency,'charges_'+charges.id, 'discount_'+charges.id)">
                                                </p-inputNumber>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="px-3 flex justify-content-end" *ngIf="!inValidDiscount">
                                    <p-messages severity="error" styleClass="-mt-3 -mb-2">
                                        <ng-template pTemplate>
                                            <div class="p-error flex align-items-center -my-3 -mx-2">
                                                <i class="pi pi-exclamation-triangle p-error text-lg"></i>
                                                <div class="ml-2">
                                                    <span class="custom-message text-xs">
                                                        {{ 'POS.MEMBERSHIP_ADDON.YOUCANNOTAPPLY' | translate }}
                                                        {{ userDiscountLimit }} %
                                                        {{ 'POS.MEMBERSHIP_ADDON.DISCOUNT' | translate }}
                                                    </span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-messages>
                                </div>
                            </div>
                        </div>
                        <div class="border-1 border-gray-300 border-round text-sm bg-white mt-3"
                            [formGroup]="fgProductsRecurring">
                            <ng-container *ngIf="subscription_category$ | async as subscription_category;">
                                <ng-container *ngIf="isContract$.getValue()">
                                    <div
                                        class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer_edit">
                                        <strong class="flex align-items-center">
                                            Recurring Discount
                                            <i class="pi pi-question-circle text-base text-500 ml-2 cursor-pointer"
                                                pTooltip="This is a sample!" tooltipStyleClass="text-xs">
                                            </i>
                                        </strong>
                                    </div>
                                    <div
                                        class="px-3 py-2 flex align-items-center justify-content-between servicechargecontainer_edit">
                                        <span>Recurring Charge</span>
                                        <div class="flex align-items-center">
                                            <span *ngIf="baseCostRecurring$ | async as baseCost">{{ baseCost |
                                                currencyformat }}</span>
                                            <p-inputNumber formControlName="frmRecurringMembershipAmt"
                                                styleClass="w-4rem h-2rem mx-2 px-1 text-sm" inputStyleClass="px-1"
                                                (onBlur)="calculateRecurringDiscount(recurringMembershipBaseCostFormControl, recurringMembershipFormControl, recurringMembershipDiscountFormControl)">
                                            </p-inputNumber>
                                            <div class="p-inputgroup">
                                                <span class="p-inputgroup-addon">%</span>
                                                <p-inputNumber styleClass="w-3rem -ml-1 px-0 text-sm"
                                                    inputStyleClass="px-1" [maxFractionDigits]="2"
                                                    formControlName="frmRecurringMembershipDis"
                                                    (onBlur)="calculateRecurringAmount(recurringMembershipBaseCostFormControl,recurringMembershipFormControl, recurringMembershipDiscountFormControl)">
                                                </p-inputNumber>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="flex align-items-center justify-content-between mt-3">
                            <button (click)="applyDiscount=false;StaffDiscountCancel();" pButton type="button"
                                label="{{'common.PLACEHOLDER.CANCEL' | translate}}"
                                class="text-sm p-button-text"></button>
                            <button (click)="applyDiscount=false" (click)="discountApplied=true;StaffDiscountBreakup();"
                                pButton type="button" label="{{'common.PLACEHOLDER.APPLY' | translate}}"
                                class="text-sm"></button>
                        </div>
                    </div>
                    <!-- Discount Area -->
                </ng-container>
                <ng-template #templateC>
                    <div class="border-1 border-gray-300 border-round text-sm bg-white font-bold p-5 text-center">
                        <div>{{'POS.MEMBERSHIP_ADDON.PLEASE_SELECT_PRODUCT' | translate}}</div>
                        <img src="/assets/img/empty-favorite-img.svg"
                            title="{{'POS.MEMBERSHIP_ADDON.PLEASE_SELECT_PRODUCT' | translate}}" />
                    </div>

                </ng-template>
            </div>
            <!-- Bill Details -->

            <!-- Details -->


            <ng-container *ngIf="isContract$.getValue()">
                <div class="flex mt-3 bg-white border-1 border-gray-300 border-round shadow-2">
                    <div class="w-6 px-3 md:px-4 py-2 border-right-1 border-gray-300">
                        <h5 class="text-base mb-2 font-normal">
                            {{'POS.MEMBERSHIP_ADDON.PAYING_TODAY' | translate}}
                        </h5>
                        <strong class="text-lg block line-height-1"
                            *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                            {{totalOneTimeCost | currencyformat}}
                        </strong>
                        <i class="block text-gray-600 text-xxs">
                            {{'POS.MEMBERSHIP_ADDON.PRICEWITHTAX' | translate}}
                        </i>
                    </div>
                    <div class="w-6 px-3 md:px-4 py-2">
                        <h5 class="text-base mb-2 font-normal">
                            {{'POS.MEMBERSHIP_ADDON.RECURRING' | translate}}
                        </h5>
                        <strong class="text-lg block line-height-1 mb-1"
                            *ngIf="recurringCost$ | async as recurringCost">
                            {{ recurringCost | currencyformat }}
                        </strong>
                        <i class="block text-gray-600 text-xxs">
                            {{'POS.MEMBERSHIP_ADDON.PRICEWITHTAX' | translate}}
                        </i>
                        <div>
                            <i class="block text-xs mt-1" *ngIf="this.billingInterval && this.biilingIntervalType">
                                {{ 'POS.MEMBERSHIP_ADDON.EVERY' | translate}}
                                {{ (this.billingInterval> 1)? this.billingInterval.toString() : ""}}
                                {{ 'POS.MEMBERSHIP_ADDON.' +
                                this.getInterval(this.billingInterval,this.biilingIntervalType) |
                                translate}}
                            </i>
                        </div>
                    </div>
                </div>
            </ng-container>



            <!-- Details -->

            <!-- Bill Owner and Date -->
            <div class="flex">
                <div class="mt-4 mr-5">
                    <h5 class="text-sm mb-2">
                        {{'POS.MEMBERSHIP_ADDON.BILL_OWNER' | translate}}
                    </h5>
                    <staff-selector [formGroup]="fg" [controlName]="billingOwner" [id]=_selectedstaff
                        [styleClass]="'w-14rem p-inputtext py-0 px-0 align-items-center text-sm'"
                        [placeHolder]="'CreateEditStaff.SELECT_PLACEHOLDER' | translate" [filterstaff]="_filterStaff">
                    </staff-selector>
                </div>

                <div class="mt-4">
                    <h5 class="text-sm mb-3">
                        {{'POS.MEMBERSHIP_ADDON.BILL_DATE' | translate}}
                    </h5>
                    <p class="text-sm mb-0">
                        {{this.billDate.value | datetimeformat:'usformat'}}
                    </p>
                </div>
            </div>
            <!-- Bill Owner and Date -->
        </div>
        <!-- Mid Part -->

        <!-- Bottom Part -->
        <form [formGroup]="fgPayment">
            <div class="p-3 border-top-1 border-gray-300"
                [ngClass]="!(planBreakUpCalculation$ | async)?'pointer-events-none opacity-50 ng-tns-c274-9':''">
                <h5 class="text-sm flex align-items-center mb-4"><i class="pi pi-credit-card text-gray-500 mr-3"></i>
                    {{'POS.MEMBERSHIP_ADDON.CHOOSE_PAYMENT_METHOD' | translate}}
                </h5>

                <!-- Online Payment -->
                <div class="bg-blue-50 border-1 border-primary border-round mt-3">
                    <div class="p-3 flex align-items-center justify-content-between cursor-pointer"
                        (click)="onlinePayment=!onlinePayment;" (click)="otherpayment=false;">
                        <strong class="text-sm">{{'POS.MEMBERSHIP_ADDON.ONLINE_PAYMENT' | translate}}</strong>
                        <i *ngIf="!onlinePayment" class="pi pi-angle-double-right text-gray-500"></i>
                        <i *ngIf="onlinePayment" class="pi pi-angle-double-down text-gray-500"></i>
                    </div>
                    <div *ngIf="onlinePayment" class="text-xs px-3 mb-3 -mt-4">
                        <div class="my-3">"{{'POS.MEMBERSHIP_ADDON.DIGITALWAIVER' | translate}}"</div>
                        <p-button label="{{'POS.MEMBERSHIP_ADDON.CONTINUE' | translate}}" styleClass="w-full text-sm"
                            (click)="SaveSubscription(false);">
                        </p-button>
                    </div>
                </div>
                <!-- Online Payment -->

                <!-- Other Payment -->
                <div *ngIf="isCashRecurring" class="bg-blue-50 border-1 border-primary border-round mt-3">
                    <div class="p-3 flex align-items-center justify-content-between cursor-pointer"
                        (click)="otherpayment=!otherpayment" (click)="onlinePayment=false"
                        (click)="paymentModeValidationSet()">
                        <strong class="text-sm">{{'POS.MEMBERSHIP_ADDON.OTHER_PAYMENT' | translate}}</strong>
                        <i *ngIf="!otherpayment" class="pi pi-angle-double-right text-gray-500"></i>
                        <i *ngIf="otherpayment" class="pi pi-angle-double-down text-gray-500"></i>
                    </div>

                    <div *ngIf="otherpayment" class="bg-white border-1 border-gray-300 border-round mx-3 mb-3 -mt-1">
                        <div class="p-3 border-bottom-1 border-gray-300">
                            <p-radioButton formControlName="otherPaymentMode" name="otherPaymentMode"
                                labelStyleClass="text-sm" label="{{'POS.MEMBERSHIP_ADDON.CREDITCARD' | translate}}"
                                value="credit" (change)="changePaymentMode($event)"></p-radioButton>

                            <div class="mt-3 mx-4" *ngIf="otherPaymentMethod === 'credit'">
                                <input pInputText id="cc-number" formControlName="frmCCNumber" class="w-full text-sm"
                                    type="tel">
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmCC_NumberFormControl.touched && frmCC_NumberFormControl.invalid"
                                    [errors]="this.frmCC_NumberFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_CCCARD' | translate"></app-error-msg>
                                <input type="text" pInputText class="w-full text-sm  mt-2"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.NETWORK_TYPE' | translate}}"
                                    formControlName="frmCCNetworkType" />

                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmCC_NetworkTypeFormControl.touched && frmCC_NetworkTypeFormControl.invalid"
                                    [errors]="this.frmCC_NetworkTypeFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_CCNETWORKTYPE' | translate"></app-error-msg>

                                <!-- <p-calendar [showIcon]="true" formControlName="frmCCExpiryDate" [readonlyInput]="true"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.EXPIRY_DATE' | translate}}"
                                    inputStyleClass="text-sm" styleClass="w-full text-sm mt-2" view="month"
                                    dateFormat="mm/yy">
                                </p-calendar> -->

                                <input type="text" pInputText class="w-full text-sm  mt-2"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.EXPIRY_DATE' | translate}}"
                                    formControlName="frmCCExpiryDate" />

                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmCC_CardExpiryFormControl.touched && frmCC_CardExpiryFormControl.invalid"
                                    [errors]="this.frmCC_CardExpiryFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_CCEXPIRYDATE' | translate"></app-error-msg>

                                <p-inputNumber styleClass="w-full text-sm mt-2" inputStyleClass="px-1"
                                    formControlName="frmCCAuthCode"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.AUTH_CODE' | translate}}" [format]="false">
                                </p-inputNumber>
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmCC_AuthCodeFormControl.touched && frmCC_AuthCodeFormControl.invalid"
                                    [errors]="this.frmCC_AuthCodeFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_CCAUTHCARD' | translate"></app-error-msg>

                                <div class="mt-2 flex align-items-center">
                                    <div class="p-inputgroup w-10rem mr-3">
                                        <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                                        <p-inputNumber styleClass="w-full text-sm" inputStyleClass="px-1"
                                            formControlName="frmCCAmt" mode="decimal" [maxFractionDigits]="4"
                                            placeholder="{{'POS.MEMBERSHIP_ADDON.AMT' | translate}}">
                                        </p-inputNumber>
                                    </div>
                                    <div class="text-sm flex align-items-center" id="balance">
                                        <span class="text-gray-600 mr-2">{{'POS.MEMBERSHIP_ADDON.BALANCE' |
                                            translate}}</span>
                                        <strong *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                            {{(totalOneTimeCost - getfrmCC_Amount()) | currencyformat}}
                                        </strong>
                                    </div>
                                </div>
                                <p-messages severity="warn">
                                    <ng-template pTemplate>
                                        <div class="p-warn flex align-items-center w-full -my-2">
                                            <i class="pi pi-exclamation-triangle p-warn text-xl"></i>
                                            <div class="ml-2 line-height-3 text-xs">
                                                {{'POS.MEMBERSHIP_ADDON.BALANCEPAYMENT' | translate}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-messages>
                            </div>
                        </div>

                        <div class="p-3 border-bottom-1 border-gray-300">
                            <p-radioButton formControlName="otherPaymentMode" name="otherPaymentMode"
                                label="{{'POS.MEMBERSHIP_ADDON.DEBITCARD' | translate}}" value="debit"
                                (change)="changePaymentMode($event)"></p-radioButton>
                            <div class="mt-3 mx-4" *ngIf="otherPaymentMethod === 'debit'">

                                <p-inputNumber styleClass="w-full text-sm mt-2" formControlName="frmDCNumber"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.CARD_NO' | translate}}" [format]="false">
                                </p-inputNumber>
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmDC_NumberFormControl.touched && frmDC_NumberFormControl.invalid"
                                    [errors]="this.frmDC_NumberFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_DCCARD' | translate"></app-error-msg>

                                <input type="text" pInputText class="w-full text-sm  mt-2"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.NETWORK_TYPE' | translate}}"
                                    formControlName="frmDCNetworkType" />

                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmDC_NetworkTypeFormControl.touched && frmDC_NetworkTypeFormControl.invalid"
                                    [errors]="this.frmDC_NetworkTypeFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_DCNETWORKTYPE' | translate"></app-error-msg>

                                <!-- <p-calendar [showIcon]="true" formControlName="frmDCExpiryDate" [readonlyInput]="true"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.EXPIRY_DATE' | translate}}"
                                    inputStyleClass="text-sm" styleClass="w-full text-sm mt-2" view="month"
                                    dateFormat="mm/yy">
                                </p-calendar> -->
                                <input type="text" pInputText class="w-full text-sm  mt-2"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.EXPIRY_DATE' | translate}}"
                                    formControlName="frmDCExpiryDate" />

                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmDC_CardExpiryFormControl.touched && frmDC_CardExpiryFormControl.invalid"
                                    [errors]="this.frmDC_CardExpiryFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_DCEXPIRYDATE' | translate"></app-error-msg>


                                <p-inputNumber styleClass="w-full text-sm mt-2" formControlName="frmDCAuthCode"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.AUTH_CODE' | translate}}" [format]="false">
                                </p-inputNumber>
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmDC_AuthCodeFormControl.touched && frmDC_AuthCodeFormControl.invalid"
                                    [errors]="this.frmDC_AuthCodeFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_DCAUTHCARD' | translate"></app-error-msg>

                                <div class="mt-2 flex align-items-center">
                                    <div class="p-inputgroup w-10rem mr-3">
                                        <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                                        <p-inputNumber styleClass="w-full text-sm" formControlName="frmDCAmt"
                                            mode="decimal" [maxFractionDigits]="4"
                                            placeholder="{{'POS.MEMBERSHIP_ADDON.AMT' | translate}}">
                                        </p-inputNumber>
                                    </div>
                                    <div class="text-sm flex align-items-center" id="balance">
                                        <span class="text-gray-600 mr-2">{{'POS.MEMBERSHIP_ADDON.BALANCE' |
                                            translate}}</span>
                                        <strong *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                            {{(totalOneTimeCost - getfrmDC_Amount()) | currencyformat}}
                                        </strong>
                                    </div>
                                </div>
                                <p-messages severity="warn">
                                    <ng-template pTemplate>
                                        <div class="p-warn flex align-items-center w-full -my-2">
                                            <i class="pi pi-exclamation-triangle p-warn text-xl"></i>
                                            <div class="ml-2 line-height-3 text-xs">
                                                {{'POS.MEMBERSHIP_ADDON.BALANCEPAYMENT' | translate}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-messages>
                            </div>
                        </div>
                        <div class="p-3 border-bottom-1 border-gray-300">
                            <p-radioButton name="otherPaymentMode" formControlName="otherPaymentMode" value="cash"
                                label="{{'POS.MEMBERSHIP_ADDON.CASH' | translate}}"
                                (change)="changePaymentMode($event)"></p-radioButton>
                            <div class="mt-3 mx-4" *ngIf="otherPaymentMethod === 'cash'">
                                <div class="flex align-items-center">

                                    <div class="p-inputgroup w-10rem mr-3">
                                        <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                                        <p-inputNumber styleClass="w-full text-sm" formControlName="frmCashAmount"
                                            mode="decimal" [maxFractionDigits]="4"
                                            placeholder="{{'POS.MEMBERSHIP_ADDON.AMT' | translate}}">
                                        </p-inputNumber>
                                    </div>
                                    <div class="text-sm flex align-items-center"><span
                                            class="text-gray-600 mr-2">{{'POS.MEMBERSHIP_ADDON.BALANCE' |
                                            translate}}</span>
                                        <strong *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                            {{(totalOneTimeCost - getfrmCash_Amount()) | currencyformat}}
                                        </strong>
                                    </div>
                                </div>
                                <p-messages severity="warn">
                                    <ng-template pTemplate>
                                        <div class="p-warn flex align-items-center w-full -my-2">
                                            <i class="pi pi-exclamation-triangle p-warn text-xl"></i>
                                            <div class="ml-2 line-height-3 text-xs">
                                                {{'POS.MEMBERSHIP_ADDON.BALANCEPAYMENT' | translate}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-messages>
                            </div>
                        </div>
                        <div class="p-3 border-bottom-1 border-gray-300">
                            <p-radioButton name="otherPaymentMode" formControlName="otherPaymentMode" value="cheque"
                                label="{{'POS.MEMBERSHIP_ADDON.CHEQUE' | translate}}"
                                (change)="changePaymentMode($event)"></p-radioButton>
                            <div class="mt-3 mx-4" *ngIf="otherPaymentMethod === 'cheque'">

                                <p-inputNumber styleClass="w-full text-sm mt-2" formControlName="frmChequeBankName"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.BANK_NAME' | translate}}" [format]="false">
                                </p-inputNumber>
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmCheque_BankNameFormControl.touched && frmCheque_BankNameFormControl.invalid"
                                    [errors]="this.frmCheque_BankNameFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_CHEQUEBANKNAME' | translate"></app-error-msg>

                                <p-calendar [showIcon]="true" formControlName="frmChequeDate" [readonlyInput]="true"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.Cheque_Date' | translate}}"
                                    styleClass="w-full text-sm mt-2"></p-calendar>
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmCheque_DateFormControl.touched && frmCheque_DateFormControl.invalid"
                                    [errors]="this.frmCheque_DateFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_CHEQUEDATE' | translate"></app-error-msg>

                                <div class="mt-2 flex align-items-center">
                                    <div class="p-inputgroup w-10rem mr-3">
                                        <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                                        <p-inputNumber styleClass="w-full text-sm" inputStyleClass="px-1"
                                            formControlName="frmChequeAmount" mode="decimal" [maxFractionDigits]="4"
                                            placeholder="{{'POS.MEMBERSHIP_ADDON.AMT' | translate}}">
                                        </p-inputNumber>
                                    </div>
                                    <div class="text-sm flex align-items-center" id="balance">
                                        <span class="text-gray-600 mr-2">{{'POS.MEMBERSHIP_ADDON.BALANCE' |
                                            translate}}</span>
                                        <strong *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                            {{(totalOneTimeCost - getfrmCheque_Amount()) | currencyformat}}
                                        </strong>
                                    </div>
                                </div>

                                <p-messages severity="warn">
                                    <ng-template pTemplate>
                                        <div class="p-warn flex align-items-center w-full -my-2">
                                            <i class="pi pi-exclamation-triangle p-warn text-xl"></i>
                                            <div class="ml-2 line-height-3 text-xs">
                                                {{'POS.MEMBERSHIP_ADDON.BALANCEPAYMENT' | translate}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-messages>
                            </div>
                        </div>
                        <div class="p-3 border-bottom-1 border-gray-300">
                            <p-radioButton name="otherPaymentMode" formControlName="otherPaymentMode" value="upi"
                                label="{{'POS.MEMBERSHIP_ADDON.UPI' | translate}}" (change)="changePaymentMode($event)">
                            </p-radioButton>
                            <div class="mt-3 mx-4" *ngIf="otherPaymentMethod === 'upi'">
                                <p-inputNumber styleClass="w-full text-sm mt-2" formControlName="frmUpiProvider"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.UPI_PROVIDER' | translate}}" [format]="false">
                                </p-inputNumber>
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmUpiProviderFormControl.touched && frmUpiProviderFormControl.invalid"
                                    [errors]="this.frmUpiProviderFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_UPIID' | translate"></app-error-msg>


                                <p-inputNumber styleClass="w-full text-sm mt-2" formControlName="frmUpiVpaid"
                                    placeholder="{{'POS.MEMBERSHIP_ADDON.UPI_VPA_ID' | translate}}" [format]="false">
                                </p-inputNumber>
                                <app-error-msg class="mb-3"
                                    *ngIf="this.frmUpiVpaidFormControl.touched && frmUpiVpaidFormControl.invalid"
                                    [errors]="this.frmUpiVpaidFormControl.errors"
                                    [fieldLabel]="'POS.MEMBERSHIP_ADDON.FL_VPAID' | translate"></app-error-msg>



                                <div class="mt-2 flex align-items-center">
                                    <div class="p-inputgroup w-10rem mr-3">
                                        <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                                        <p-inputNumber styleClass="w-full text-sm" inputStyleClass="px-1"
                                            formControlName="frmUpiAmount" mode="decimal" [maxFractionDigits]="4"
                                            placeholder="{{'POS.MEMBERSHIP_ADDON.AMT' | translate}}">
                                        </p-inputNumber>
                                    </div>
                                    <div class="text-sm flex align-items-center" id="balance">
                                        <span class="text-gray-600 mr-2">{{'POS.MEMBERSHIP_ADDON.BALANCE' |
                                            translate}}</span>
                                        <strong *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                            {{(totalOneTimeCost - getfrmUpi_Amount()) | currencyformat}}
                                        </strong>
                                    </div>
                                </div>

                                <p-messages severity="warn">
                                    <ng-template pTemplate>
                                        <div class="p-warn flex align-items-center w-full -my-2">
                                            <i class="pi pi-exclamation-triangle p-warn text-xl"></i>
                                            <div class="ml-2 line-height-3 text-xs">
                                                {{'POS.MEMBERSHIP_ADDON.BALANCEPAYMENT' | translate}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-messages>
                            </div>
                        </div>

                        <ng-container *ngFor="let paymentMode1 of this.paymentModeBS$.value; let n=index">
                            <div class="p-3 border-bottom-1 border-gray-300">
                                <p-radioButton name="otherPaymentMode" formControlName="otherPaymentMode"
                                    value="{{'otherPaymentMode_rb_' + paymentMode1.id}}"
                                    label={{paymentMode1.name_primary_language}}>
                                </p-radioButton>

                                <div class="mt-3 mx-4"
                                    *ngIf="otherPaymentMethod === 'otherPaymentMode_rb_' + paymentMode1.id">
                                    <div class="flex align-items-center">
                                        <div class="p-inputgroup w-10rem mr-3">
                                            <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                                            <p-inputNumber styleClass="w-full text-sm"
                                                formControlName="{{'inp_otherPaymentMode_' + paymentMode1.id}}"
                                                mode="decimal" [maxFractionDigits]="4"
                                                placeholder="{{'POS.MEMBERSHIP_ADDON.AMT' | translate}}">
                                            </p-inputNumber>
                                        </div>
                                        <div class="text-sm flex align-items-center"><span
                                                class="text-gray-600 mr-2">{{'POS.MEMBERSHIP_ADDON.BALANCE' |
                                                translate}}</span>
                                            <strong *ngIf="totalOneTimeCost$ | async as totalOneTimeCost">
                                                <!-- {{(totalOneTimeCost - getfrmCash_Amount()) | currencyformat}} -->
                                                {{ getPaymentBalance(totalOneTimeCost,paymentMode1.id) |
                                                currencyformat}}
                                            </strong>
                                        </div>
                                    </div>
                                    <p-messages severity="warn">
                                        <ng-template pTemplate>
                                            <div class="p-warn flex align-items-center w-full -my-2">
                                                <i class="pi pi-exclamation-triangle p-warn text-xl"></i>
                                                <div class="ml-2 line-height-3 text-xs">
                                                    {{'POS.MEMBERSHIP_ADDON.BALANCEPAYMENT' | translate}}
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-messages>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="otherpayment" class="px-3 pb-3">
                        <p-button label="{{'POS.MEMBERSHIP_ADDON.CONTINUE' | translate}}" styleClass="w-full text-sm"
                            (click)="SaveSubscription(true);">
                        </p-button>
                    </div>

                </div>

                <!-- Other Payment -->
            </div>
        </form>
        <!-- Bottom Part -->
    </form>
</p-sidebar>
<app-errors *ngIf="showErrorPopup | async as showPopup" [visible]="showPopup" [errorData]="errorMessages"
    (hideErrorPopup)="HideErrorPopup($event)">
</app-errors>