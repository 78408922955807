import { CLAIMS_INFO, STAFF_STATE } from 'apps/ff-frontend/src/app/state/app.state';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Output, EventEmitter, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { StyleClassModule } from 'primeng/styleclass';
import { CLAIMSSTATEINTERFACE, STAFFINTERFACE } from '@fitness-force/models';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FactoryHttpLoader } from '../../helpsupport/helpsupport.component';

@Component({
  selector: 'multi-select-staff',
  templateUrl: './multi-select-staff.component.html',
  styleUrls: ['./multi-select-staff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectStaffComponent implements OnInit {
  @Input('selected') selected:number[]=[];
  // activefilters: any[] = [];
  selectedFilter: { name: string; } | undefined;
  allStaffs$ =  new BehaviorSubject([])
  // claims!:CLAIMSSTATEINTERFACE;
  selectedStaffList: FormGroup = new FormGroup({})
  @Output() selectStaffEvent = new EventEmitter()
  constructor(private store: Store, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.selectedStaffList = this.fb.group({
      selectedStaffs: []
    })



  this.store.select(STAFF_STATE).subscribe((s:any)=>{
    this.allStaffs$.next(s.staffListAll);
    if(s.staffListAll && s.staffListAll.length > 0)
    this.setSelectedStaff(s.staffListAll);
    if(this.selected && this.selected.length>0 && s && s.length>0){
      let staffs:any[]=[];
      s.forEach((staff:any) => {
        if(this.selected.includes(staff.id)){
          staffs.push(staff);
        }
      });
      this.selectedStaffList.get('selectedStaffs')?.setValue(staffs)
    }
  })
  }


  // Run this on exchange
  setSelectedStaff(allList:STAFFINTERFACE[]){
    let tempSelected:STAFFINTERFACE[]=[];
    allList.forEach((elm:STAFFINTERFACE) => {
      if(this.selected.includes(elm.id)){
        tempSelected.push(elm);
      }
    });

    this.selectedStaffs.setValue(tempSelected);
  }

  get selectedStaffs(): FormControl {
    return this.selectedStaffList.get('selectedStaffs') as FormControl
  }
  getSelectedStaff(e:any){
    this.selectStaffEvent.emit( this.selectedStaffs.value)
  }
}

@NgModule({
  declarations: [MultiSelectStaffComponent],
  imports: [
    CommonModule,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    StyleClassModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [MultiSelectStaffComponent]
})
export class MultiSelectStaffComponentModule {
}
