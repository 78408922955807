import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';

@Component({
  selector: 'tax-category-modal',
  templateUrl: './tax-category-modal.component.html',
  styleUrls: ['./tax-category-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxCategoryModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [TaxCategoryModalComponent],
  imports: [],
  exports: [TaxCategoryModalComponent]
})
export class TaxCategoryModalComponentModule {
}
