import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Claim } from '@fitness-force/models';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  GetClaim(BASE_URL: string): Observable<Claim[]> {
    return this.http.get(BASE_URL + CONST_URL_SEGEMENTS.CLAIM.GET_CLAIM_TEMPLATES, {
      headers: this.httpOptions.headers
    }).pipe(map((res: any) => {
      if (res && res.items) {
        return res.items;
      } else {
        throwError;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }
}