import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function SpecialCharacterCheck(controlName: any, limit: any): any {
    return (formGroup: FormGroup) => {

        if (!formGroup.controls) return;

        const control = formGroup.controls[controlName];

        if (control.errors) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (countSpecialCharacter(control.value) < limit) {
            control.setErrors({ specialCharacterCheck: true });
        } else {
            control.setErrors(null);
        }
    };
}

function countSpecialCharacter(str: any): any {
    const punct = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (!punct.toString().includes(str[i])) {
            continue;
        }
        count++;
    }
    return count;
}
