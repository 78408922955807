<!-- Header -->
<div
  class="md:px-4 px-3 py-3 flex align-items-center justify-content-between border-bottom-1 border-gray-300 -mx-3 -mt-3">
  <div class="flex align-items-center">
    <i class="pi pi-plus mr-2 text-gray-500"></i>
    <h2 class="mb-0 text-base md:text-lg font-normal">
      {{taxCategory?('ADMIN.TAX.EDIT_TAX_CATEGORY' | translate):('ADMIN.TAX.ADD_TAX_CATEGORY' | translate)}}
    </h2>
  </div>
  <div class="pi pi-times text-gray-500 cursor-pointer" (click)="close()"></div>
</div>
<!-- Header -->
<form *ngIf="taxCategoryForm" [formGroup]="taxCategoryForm" (ngSubmit)="saveTaxCategory()">
  <!-- Content -->
  <div class="surface-100 p-4 text-sm -mx-3">
    <!-- Form Group -->
    <div class="mb-4">
      <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
        {{'ADMIN.TAX.CHARGE_CATEGORY_NAME' | translate}}<span *ngIf="isSecondaryLanguage">({{ primaryLanguage | translate }})</span>
      </label>
      <input type="text" pInputText class="w-full text-sm" [placeholder]="'ADMIN.COMMON.NAME' | translate"
        formControlName="name_primary_language" />
      <app-error-msg *ngIf="CatNamePrimary?.touched && CatNamePrimary?.invalid" [errors]="CatNamePrimary?.errors"
        [fieldLabel]="'ADMIN.TAX.CHARGE_CATEGORY_NAME' | translate"></app-error-msg>
    </div>
    <!-- Form Group -->

    <!-- Form Group -->
    <div class="mb-1" *ngIf="isSecondaryLanguage">
      <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
        {{'ADMIN.TAX.CHARGE_CATEGORY_NAME' | translate}} <span *ngIf="isSecondaryLanguage">({{ secondaryLanguage | translate }})</span>
      </label>
      <input type="text" pInputText class="w-full text-sm" [placeholder]="'ADMIN.COMMON.NAME' | translate"
        formControlName="name_secondary_language" />
      <app-error-msg *ngIf="CatNameSecondary?.touched && CatNameSecondary?.invalid" [errors]="CatNameSecondary?.errors"
        [fieldLabel]="'ADMIN.TAX.CHARGE_CATEGORY_NAME' | translate"></app-error-msg>
    </div>
    <!-- Form Group -->
  </div>
  <!-- Content -->

  <!-- Footer -->
  <div class="p-3 flex justify-content-between align-items-center border-top-1 border-gray-300 -mx-3 -mb-3">
    <button pButton pRipple type="button" [label]="'ADMIN.COMMON.CANCEL'| translate" class="p-button-text text-sm"
      (click)="close()" styleClass="text-sm"></button>

    <button pButton pRipple type="submit" [label]="'ADMIN.COMMON.SAVE'| translate" class="text-sm"></button>
  </div>
  <!-- Footer -->

</form>

<app-errors *ngIf="showErrorPopup | async as show_Popup" [visible]="show_Popup" [errorData]="[]" [error]="error"
  (hideErrorPopup)="HideErrorPopup($event)">
</app-errors>
