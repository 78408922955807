import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
} from '@angular/core';
import { AppInitService } from '@fitness-force/api';
import { CarouselModule } from 'primeng/carousel';

interface CData {
  title: string | null;
  category: string[];
  pubDate: string | null;
  description: string | null;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  xmlData: any;
  blogData: CData[] = [];
  xmlString: any;
  xmlDoc: XMLDocument;
  constructor(private appInitService: AppInitService) {}

  ngOnInit(): void {
    this.appInitService.getBlogData().subscribe({
      next: (response: any) => {
        this.xmlData = response;
        let parser = new DOMParser();
        this.xmlDoc = parser.parseFromString(this.xmlData, 'text/xml');

        for (let j = 0;j < this.xmlDoc.getElementsByTagName('item').length;j++) {
          let d: CData = {} as CData;
          d.category = [];

          for (let i = 0;i < this.xmlDoc.getElementsByTagName('item')[j].childNodes.length;i++) {
            let key = this.xmlDoc.getElementsByTagName('item')[j].childNodes[i];
            switch (key.nodeName) {
              case 'title':
                d.title = key.textContent;
                break;
              case 'pubDate':
                d.pubDate = key.textContent;
                break;
              case 'category':
                d.category.push(key.textContent!);
                break;
              case 'description':
                d.description = key.textContent;
                break;
            }
          }
          this.blogData.push(d);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, CarouselModule],
  exports: [CarouselComponent],
})
export class CarouselComponentModule {}
