import { HttpClient } from '@angular/common/http';
import { Component, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskType } from '@fitness-force/enum';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'task-type',
  templateUrl: './task-type-dropdown.component.html',
  styleUrls: ['./task-type-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskTypeDropdownComponent {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: FormControl = new FormControl();
  @Input('placeHolder') placeHolder: string = 'Select';
  @Input('styleClass') styleClass: string = '-ml-2 border-none surface-50 w-full text-sm text-900';
  @Input('langCode') languageCode: string = 'en';

  taskType: any[] = [
    // { name: 'Email', value: 'email', icon: 'pi pi-envelope' },
    // { name: 'SMS', value: 'sms', icon: 'pi pi-comments' },
    { name: 'Call', value: 'call', icon: 'pi pi-phone' },
    // { name: 'Task', value: 'task', icon: 'pi pi-users' },
    { name: 'Tour', value: 'tour', icon: 'pi pi-map-marker' },
  ];


  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(this.languageCode);
    this.translate.use(this.languageCode);

    
    let transalteKey: string[] = ['common.TASKTYPE.EMAIL', 'common.TASKTYPE.SMS', 'common.TASKTYPE.CALL', 'common.TASKTYPE.TASK', 'common.TASKTYPE.TOUR'];
    this.translate.get(transalteKey).subscribe(translations => {
      // this.taskType.find(item => item.value == 'email').name = translations['common.TASKTYPE.EMAIL'];
      // this.taskType.find(item => item.value == 'sms').name = translations['common.TASKTYPE.SMS'];
      this.taskType.find(item => item.value == 'call').name = translations['common.TASKTYPE.CALL'];
      // this.taskType.find(item => item.value == 'task').name = translations['common.TASKTYPE.TASK'];
      this.taskType.find(item => item.value == 'tour').name = translations['common.TASKTYPE.TOUR'];
    });
  }

  get SelectedTaskType(): any {
    console.log(this.controlName?.value);
    return this.controlName?.value;
  }
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [TaskTypeDropdownComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TaskTypeDropdownComponent]
})
export class TaskTypeDropdownComponentModule {
}
