import { filter, Observable, BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ClientDetails, LatestTask, TaskOutcomeDetails } from '@fitness-force/models';
import { TourOutcomeComponentModule } from '../../../out-come/tour-outcome/tour-outcome.component';
import { CallOutcomeComponentModule } from '../../../out-come/call-outcome/call-outcome.component';

@Component({
  selector: 'track-status',
  templateUrl: './track-status.component.html'
})
export class TrackStatusComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)
  @Output() onAction = new EventEmitter()

  showMeetingOutcomePopup: Observable<boolean> | any = false;
  showCallOutcomePopup: Observable<boolean> | any = false;
  outcomeDetail: any = {};
  constructor() { }

  ngOnInit(): void {

  }

  SetOutComeDetails(opportunity: any) {
    let task: any = {};
    let latestTask: LatestTask = {} as LatestTask;
    latestTask.category_id = opportunity.category_id;
    latestTask.name_primary_language = opportunity.name_primary_language;
    latestTask.name_secondary_language = opportunity.name_secondary_language;
    latestTask.priority = opportunity.priority;
    latestTask.scheduled_date = opportunity.scheduled_date;
    latestTask.status = opportunity.status;
    latestTask.title = opportunity.title;
    latestTask.type = opportunity.type;
    latestTask.id = opportunity.id;

    task.id = opportunity.id;
    task.company_id = opportunity.company_id;
    task.tenant_id = opportunity.tenant_id;
    task.client_info = opportunity.client_info;

    task.service_type = [];
    if (opportunity.oppportunity_info) {
      task.board_type = opportunity.oppportunity_info.board_type;
      task.hot_warm_cold = opportunity.oppportunity_info.hot_warm_cold;
      task.expected_closure_date = opportunity.oppportunity_info.expected_closure_date;
      task.id = opportunity.oppportunity_info.id
      task.stage_master_id = opportunity.oppportunity_info.stage_master_id;
      task.value_in_minor_currency = opportunity.oppportunity_info.value_in_minor_currency;
      task.service_type = opportunity.oppportunity_info.service_type;
      task.name = opportunity.oppportunity_info.name;
    }

    task.assigned_to_staff_id = opportunity.assigned_to_staff_id;
    task.comments = "";

    task.latest_task = latestTask;

    // task.tour_id = [];
    // task.trial_id = [];
    // task.is_fresh = true;
    // task.is_stale = false;
    // task.outcome = "InProgress";
    // task.lost_reason_type = null;
    // task.lost_reason_other = "";
    // task.lost_competitor = "";
    // task.lost_competitor_latitude = "";
    // task.lost_competitor_longitude = "";
    // task.lost_competitor_place_id = "";
    // task.lost_contract_end_date = "0001-01-01T00:00:00";
    // task.lost_expected_price_in_minor_currency = 0;
    // task.lost_missing_facility = [];
    // task.lost_comments = "";
    // task.created_by = 23238;
    // task.created_date = "2022-03-25T12:50:08";
    // task.updated_by = 23392;
    // task.updated_date = "2022-06-22T18:43:22";

    this.outcomeDetail = task;

    if (opportunity.type.toLowerCase() == 'tour') {
      this.showMeetingOutcomePopup = true;
    }
    else {
      this.showCallOutcomePopup = true;
    }

  }

  HideOutComePopup(event: any) {
    this.showCallOutcomePopup = false;
    this.showMeetingOutcomePopup = false;
    this.onActionEvent(event);
  }

  onActionEvent(event: any) {
    console.log(event)
    this.onAction.emit(event);
  }
}
@NgModule({
  declarations: [TrackStatusComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    StyleClassModule,
    TourOutcomeComponentModule,
    CallOutcomeComponentModule
  ],
  exports: [TrackStatusComponent]
})
export class TrackStatusModule {
}
