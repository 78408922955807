export interface Claim {
    id: number;
    company_id: number;
    status: string;
    created_date: Date;
    updated_by: number;
    updated_date: Date;
    true_count: number;
    name: string;
    description: string
    can_do_client_profiles: boolean;
    can_manage_client_profiles: boolean;
    can_admin_client_profiles: boolean;
    can_do_billing: boolean;
    can_admin_billing: boolean;
    can_manage_membership_basic: boolean;
    can_admin_membership: boolean;
    can_manage_class: boolean;
    can_admin_class: boolean;
    can_manage_appointments: boolean;
    can_admin_appointments: boolean;
    can_do_member_communication: boolean;
    can_manage_member_communication: boolean;
    can_admin_member_communication: boolean;
    can_see_reports_finance: boolean;
    can_see_reports_clients: boolean;
    can_see_reports_operational: boolean;
    can_see_reports_appointment: boolean;
    can_see_reports_class: boolean;
    can_see_reports_sales: boolean;
    can_see_reports_staff: boolean;
    can_see_reports_marketing: boolean;
    can_see_reports_security: boolean;
    can_export_reports: boolean;
    can_export_report_with_sensetive_info: boolean;
    can_manage_staff: boolean;
}

export interface CLAIMSSTATEINTERFACE {
    claims: {
        Id: string,
        accessed_tenant: Array<string>,
        all_tenant: Array<string>,
        can_admin_appointments: string,
        can_admin_billing: string,
        can_admin_class: string,
        can_admin_client_profiles: string,
        can_admin_membercommunication: string,
        can_admin_membership: string,
        can_do_billing: string,
        can_do_client_profiles: string,
        can_do_membercommunication: string,
        can_export_report_with_sensetiveinfo: string,
        can_export_reports: string,
        can_manage_appointments: string,
        can_manage_class: string,
        can_manage_client_profiles: string,
        can_manage_membercommunication: string,
        can_manage_membership_basic: string,
        can_manage_staff: string,
        can_manage_tags: string,
        can_see_reports_appointment: string,
        can_see_reports_class: string,
        can_see_reports_clients: string,
        can_see_reports_finance: string,
        can_see_reports_marketing: string,
        can_see_reports_operational: string,
        can_see_reports_sales: string,
        can_see_reports_security: string,
        can_see_reports_staff: string,
        companyid: string,
        email: string,
        exp: number,
        iat: number,
        jti: string,
        language_iso_code: string,
        nbf: number,
        role: string,
        sub: string,
        trackmode: string
    }
}


export class ClaimConstant {

    static can_admin_appointments: string = "can_admin_appointments";
    static can_admin_billing: string = "can_admin_billing";
    static can_admin_class: string = "can_admin_class";
    static can_admin_client_profiles: string = "can_admin_client_profiles";
    static can_admin_membercommunication: string = "can_admin_membercommunication";
    static can_admin_membership: string = "can_admin_membership";
    static can_do_billing: string = "can_do_billing";
    static can_do_client_profiles: string = "can_do_client_profiles";
    static can_do_membercommunication: string = "can_do_membercommunication";
    static can_export_report_with_sensetiveinfo: string = "can_export_report_with_sensetiveinfo";
    static can_export_reports: string = "can_export_reports";
    static can_manage_appointments: string = "can_manage_appointments";
    static can_manage_class: string = "can_manage_class";
    static can_manage_client_profiles: string = "can_manage_client_profiles";
    static can_manage_membercommunication: string = "can_manage_membercommunication";
    static can_manage_membership_basic: string = "can_manage_membership_basic";
    static can_manage_staff: string = "can_manage_staff";
    static can_manage_tags: string = "can_manage_tags";
    static can_see_reports_appointment: string = "can_see_reports_appointment";
    static can_see_reports_class: string = "can_see_reports_class";
    static can_see_reports_clients: string = "can_see_reports_clients";
    static can_see_reports_finance: string = "can_see_reports_finance";
    static can_see_reports_marketing: string = "can_see_reports_marketing";
    static can_see_reports_operational: string = "can_see_reports_operational";
    static can_see_reports_sales: string = "can_see_reports_sales";
    static can_see_reports_security: string = "can_see_reports_security";
    static can_see_reports_staff: string = "can_see_reports_staff";
}
