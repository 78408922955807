import { Injectable } from '@angular/core';
import {Dropdown,HiddenField,RelativeDate,REPORTSTOREINTERFACE,TENANTSINTERFACE,TimePeriodDropdown} from '@fitness-force/models';
import { Store } from '@ngrx/store';
import { TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportDataService {
  timePeriod = this.commonService.GetTimePeriods();
  centers!: Observable<any> | any;
  tenantId! :Observable<any> | any;

  public tenantObs = new Subject<number>();

  commonFields = [
    new HiddenField({
      value: '',
      key: 'from_date',
      label: '',
      required: false,
      order: 0,
      type: 'hidden',
    }),
    new HiddenField({
      value: '',
      key: 'to_date',
      label: '',
      required: false,
      order: 0,
      type: 'hidden',
    }),
  ];

 

  constructor(private store: Store, private commonService: CommonService) {  }

  GetReportStoreData(tenantId: number) {
    this.tenantId= tenantId;
    let INITIAL_REPORT_STATE: REPORTSTOREINTERFACE[] = [
      {
        report_key: 'SubscriptionSignUpReport',
        claims: [],
        payload:
          '{"from_date":"", "to_date":"", "plan_id":"", "time_zone":"", "current_page":"1", "page_size":"100" }',
        default_payload: `{"from_date":'${this.commonService.GetRelativeFromDate(new Date(),RelativeDate.ThisMonth,0).from_date}', "to_date":'${this.commonService.GetRelativeFromDate(new Date(),RelativeDate.ThisMonth,0).from_date}', "plan_id":"", "time_zone":"", "current_page":"1", "page_size":"100" }`,
        default_relative_Date: RelativeDate[RelativeDate.ThisMonth].toString(),
        mandatory_fields: [],
      },
      {
        report_key: 'ClientListReport',
        claims: [],
        payload:
          '{"from_date":"", "to_date":"","full_name":"", "time_zone":"","home_club_id":' +this.tenantId +', "current_page":1, "page_size":100 }',
        default_payload:
          `{"from_date": "${this.commonService.GetRelativeFromDate(new Date(),RelativeDate.ThisMonth,0).from_date.toISOString()}","to_date":"${this.commonService.GetRelativeFromDate(new Date(),RelativeDate.ThisMonth,0).to_date.toISOString()}","full_name":"", "time_zone":"","home_club_id":${this.tenantId}, "current_page":1, "page_size":100 }`,
        default_relative_Date:
          RelativeDate[RelativeDate.ThisMonth].toString(),
        mandatory_fields: [
          new TimePeriodDropdown({
            value: '',
            key: 'timePeriod',
            label: 'Time Period',
            required: true,
            order: 1,
            options: this.timePeriod,
          })
        ].concat(this.commonFields),
      },
    ];
    
    return INITIAL_REPORT_STATE;
  }
}
