import { ɵHttpInterceptingHandler } from "@angular/common/http";

export interface ReportAccessRequest {
    claim:string;
    access_requested_friendly_name:string;
    request_access_comment:string
}

export interface GetReportRequest {
    tenant_id: number;
    report_key: string;
    rpt_fltr_relative_date_range: RelativeDate;
    rpt_fltr_payload: string;
} 

export enum RelativeDate
{
    Yesterday = -1,
    LastMonth = -3,
    LastSevenDays = -7,
    LastThirtyDays = -30,
    Today = 0,
    Tomorrow = 1,
    ThisMonth = 3,
    ThisQuarter = 33,
    CustomDateRange = 99
}

export enum FilterTimePeriod
{
    Today = "0",
    Tomorrow = "1",
    ThisMonth = "3",
    ThisQuarter = "33",
    CustomDateRange = "99"
}