import { createAction, props } from "@ngrx/store";

export const FETCH_CITY_MASTER_LIST = createAction(
    'FETCHCITYMASTERLIST',
    props<{cities:any}>()
);

export const FETCH_CITY_MASTER = createAction(
    'FETCHCITYMASTER',
    props<{city:any}>()
);