import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule, WeekDay } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { StyleClassModule } from 'primeng/styleclass';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { Store } from '@ngrx/store';

import { RRule, Frequency, Weekday, ByWeekday } from 'rrule'
import { RepeatRuleResponse } from '@fitness-force/models';

interface RepeatRuleRequest {
  repeat: string;
  interval: number;
  until?: Date | null;
  count?: number | null;
  weekDays?: any[] | null;
  monthDays?: number | null;
};

@Component({
  selector: 'rrule',
  templateUrl: './rrule.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RruleComponent implements OnInit {
  @Input('visible') showRRule: boolean = false;
  @Input() showNeverStop: boolean = false;
  @Input() rRuleObj: RepeatRuleResponse = {} as RepeatRuleResponse;

  @Input('minimumDate') minimumDate: Date ;

  @Output('emitRRule') EmitRule: EventEmitter<RepeatRuleResponse> = new EventEmitter();
  @Output('hideRrule') HideRule: EventEmitter<boolean> = new EventEmitter();

  formGroup: FormGroup;

  weekOptions: any[] = [
    { index: 0, value: "Sunday", name: "S" },
    { index: 1, value: "Monday", name: "M" },
    { index: 2, value: "Tuesday", name: "T" },
    { index: 3, value: "Wednesday", name: "W" },
    { index: 4, value: "Thursday", name: "T" },
    { index: 5, value: "Friday", name: "F" },
    { index: 6, value: "Saturday", name: "S" },
  ];

  monthOptions: any[] = [
    { value: 1, name: 'First' },
    { value: 2, name: 'Second' },
    { value: 3, name: 'Third' },
    { value: 4, name: 'Fourth' },
    { value: 5, name: 'Fifth' },
    { value: 6, name: 'Sixth' },
    { value: 7, name: 'Seventh' },
    { value: 8, name: 'Eighth' },
    { value: 9, name: 'Ninth' },
    { value: 10, name: 'Tenth' },
    { value: 11, name: 'Eleventh' },
    { value: 12, name: 'Twelfth' },
    { value: 13, name: 'Thirteenth' },
    { value: 14, name: 'Fourteenth' },
    { value: 15, name: 'Fifteenth' },
    { value: 16, name: 'Sixteenth' },
    { value: 17, name: 'Seventeenth' },
    { value: 18, name: 'Eighteenth' },
    { value: 19, name: 'Nineteenth' },
    { value: 20, name: 'Twentieth' },
    { value: 21, name: 'Twenty-first' },
    { value: 22, name: 'Twenty-second' },
    { value: 23, name: 'Twenty-third' },
    { value: 24, name: 'Twenty-fourth' },
    { value: 25, name: 'Twenty-fifth' },
    { value: 26, name: 'Twenty-sixth' },
    { value: 27, name: 'Twenty-seventh' },
    { value: 28, name: 'Twenty-eighth' },
    { value: 29, name: 'Twenty-ninth' },
    { value: 30, name: 'Thirtieth' },
    { value: 31, name: 'Thirty-first' },
  ];

  constructor(private fb: FormBuilder, private store: Store, public translate: TranslateService) {
    this.formGroup = this.fb.group({
      repeat: new FormControl('daily', [Validators.required]),
      interval: new FormControl('', [Validators.required]),
      until: new FormControl('', []),
      count: new FormControl('', []),
      weekDays: new FormControl([], []),
      monthDays: new FormControl('', []),
      repeatableRule: new FormControl('', []),
    });

  }

  ngOnInit(): void {
    this.CreateFormControls();
    let companyInfoDetail$ = this.store.select(COMPANY_INFO)
    companyInfoDetail$.subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });
  }

  //#region formcontrol getter

  get Repeat(): string {
    return this.formGroup.controls.repeat.value;
  }

  get MonthDays(): any {
    return this.formGroup.controls.monthDays.value;
  }

  get WeekDays(): any[] {
    return this.formGroup.controls.weekDays.value || [];
  }

  //#endregion formcontrol getter

  //#region formcontrol setter

  set Repeat(value: string) {
    this.formGroup.controls.repeat.setValue(value);
  }

  //#endregion formcontrol setter
  isValidDate(d: any): boolean {
    return d && d instanceof Date && !isNaN(d.getTime());
  }

  //#region  methods

  CreateFormControls(): void {
    if (this.rRuleObj && this.rRuleObj.RRule) {
      let ruleObj: RRule = RRule.fromString(this.rRuleObj.RRule);
      let frequency: Frequency = this.GetRRuleFrequency(String(Frequency[ruleObj.origOptions.freq || 0]).toLowerCase());

      this.formGroup.controls.repeat.setValue(String(Frequency[ruleObj.origOptions.freq || -1]).toLowerCase());
      this.formGroup.controls.interval.setValue(ruleObj.origOptions.interval);

      if (ruleObj.origOptions.count && ruleObj.origOptions.count > 0)
        this.formGroup.controls.repeatableRule.setValue('4');
      else if (ruleObj.origOptions.until && this.isValidDate(ruleObj.origOptions.until))
        this.formGroup.controls.repeatableRule.setValue('3');
      else
        this.formGroup.controls.repeatableRule.setValue(this.showNeverStop ? '2' : '1');

      this.formGroup.controls.weekDays.setValue(this.GetWeekDayFromRRuleWeekDays(frequency, ruleObj.origOptions.byweekday as ByWeekday[]));
      this.formGroup.controls.monthDays.setValue(this.monthOptions.find((o: any) => o.value == ruleObj.origOptions.bymonthday));
      this.formGroup.controls.until.setValue(ruleObj.origOptions.until);
      this.formGroup.controls.count.setValue(ruleObj.origOptions.count);
    }
    else {
      this.formGroup.controls.repeat.setValue('daily');
      this.formGroup.controls.interval.setValue(1);
      this.formGroup.controls.repeatableRule.setValue(this.showNeverStop ? '2' : '1');
      this.formGroup.controls.weekDays.setValue(null);
      this.formGroup.controls.monthDays.setValue(null);
      this.formGroup.controls.until.setValue(null);
      this.formGroup.controls.count.setValue(null);
    }


    this.formGroup.controls.weekDays.valueChanges.subscribe((val: any[]) => {
      if (!(val && val.length > 0)) {
        let obj = this.weekOptions.filter((o: any) => { return o.index == (new Date()).getDay() });
        this.formGroup.controls.weekDays.setValue(obj);
      }
    });


    this.formGroup.controls.repeatableRule.valueChanges.subscribe((val: string) => {
      if (val == '3') {
        this.formGroup.controls.count.setValue(null);

        if (this.Repeat == 'daily') {
          let now = new Date();
          let untilDate = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
          this.formGroup.controls.until.setValue(untilDate);
        }
        else if (this.Repeat == 'weekly') {
          let now = new Date();
          let untilDate = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
          this.formGroup.controls.until.setValue(untilDate);
        }
        else {
          let now = new Date();
          let untilDate = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
          this.formGroup.controls.until.setValue(untilDate);
        }
      }
      else if (val == '4') {
        this.formGroup.controls.until.setValue(null);

        if (this.Repeat == 'daily') {
          this.formGroup.controls.count.setValue(12);
        }
        else if (this.Repeat == 'weekly') {
          this.formGroup.controls.count.setValue(12);
        }
        else {
          this.formGroup.controls.count.setValue(12);
        }
      }
      else {
        this.formGroup.controls.until.setValue(null);
        this.formGroup.controls.count.setValue(null);
      }
    });

    this.formGroup.controls.repeat.valueChanges.subscribe((val: string) => {
      if (val == 'weekly') {
        let obj = this.weekOptions.filter((o: any) => { return o.index == (new Date()).getDay() });
        this.formGroup.controls.weekDays.setValue(obj);
        this.formGroup.controls.monthDays.setValue(null);
      }
      else if (val == 'monthly') {
        this.formGroup.controls.weekDays.setValue(null);
        this.formGroup.controls.monthDays.setValue(this.monthOptions[0]);
      }
      else {
        this.formGroup.controls.weekDays.setValue(null);
        this.formGroup.controls.monthDays.setValue(null);
      }
    });
  }

  SetFormControls(): void {

  }

  AddOrRemoveWeekDays(weekDay: any): void {
    let weekDaysValue = this.WeekDays;
    const index: number = weekDaysValue.map(i => i.index).indexOf(weekDay.index);
    if (index !== -1) {
      weekDaysValue.splice(index, 1);
    }
    else {
      weekDaysValue.push(weekDay);
    }
    this.formGroup.controls.weekDays.setValue(weekDaysValue);
  }

  HideRRule(): void {
    this.showRRule = false;
    this.HideRule.emit(this.showRRule);
  }

  EmitRepeatRuleData(obj: RepeatRuleResponse): void {
    this.EmitRule.emit(obj);
    this.HideRRule();
  }

  CreateRRule(): void {
    let frequency: Frequency = this.GetRRuleFrequency(this.Repeat);
    let until: Date = this.formGroup.controls.repeatableRule.value == '3' ? new Date(this.formGroup.controls.until.value) : new Date();
    const rule = new RRule({
      freq: frequency,
      interval: 1,
      byweekday: this.GetRRuleWeekDays(frequency),
      bymonthday: frequency == Frequency.MONTHLY ? this.formGroup.controls.monthDays.value.value : null,
      until: this.formGroup.controls.repeatableRule.value == '3' ? new Date(Date.UTC(until.getFullYear(), until.getMonth(), until.getDate(), until.getHours(), until.getMinutes(), until.getSeconds())) : null,
      count: this.formGroup.controls.repeatableRule.value == '4' ? this.formGroup.controls.count.value : null,
    })

    this.EmitRepeatRuleData({ RRule: rule.toString(), RRuleText: rule.toText() });
  }

  GetRRuleFrequency(val: string): Frequency {
    if (val == 'weekly') {
      return RRule.WEEKLY;
    }
    else if (val == 'monthly') {
      return RRule.MONTHLY;
    }
    else {
      return RRule.DAILY;
    }
  }

  GetRRuleWeekDays(frequency: Frequency): (Weekday[] | null) {
    if (frequency == Frequency.WEEKLY) {
      let weekday: Weekday[] = [];
      this.WeekDays.forEach((val: any) => {
        switch (String(val.value).toLowerCase()) {
          case 'sunday': weekday.push(RRule.SU); break;
          case 'monday': weekday.push(RRule.MO); break;
          case 'tuesday': weekday.push(RRule.TU); break;
          case 'wednesday': weekday.push(RRule.WE); break;
          case 'thursday': weekday.push(RRule.TH); break;
          case 'friday': weekday.push(RRule.FR); break;
          case 'saturday': weekday.push(RRule.SA); break;
          default: console.log('Wrong value passed on functio - GetRRuleWeekDays -> ', JSON.stringify(this.WeekDays)); break;
        }
      });
      return weekday;
    }
    else {
      return null;
    }
  }

  GetWeekDayFromRRuleWeekDays(frequency: Frequency, weekDays: ByWeekday[]): (any[] | null) {
    if (frequency == Frequency.WEEKLY) {
      let weekday: any[] = [];
      weekDays.forEach((val: any) => {
        switch (val.toString().toUpperCase()) {
          case "SU": weekday.push(this.weekOptions.find((o: any) => String(o.value).toLowerCase() == 'sunday')); break;
          case "MO": weekday.push(this.weekOptions.find((o: any) => String(o.value).toLowerCase() == 'monday')); break;
          case "TU": weekday.push(this.weekOptions.find((o: any) => String(o.value).toLowerCase() == 'tuesday')); break;
          case "WE": weekday.push(this.weekOptions.find((o: any) => String(o.value).toLowerCase() == 'wednesday')); break;
          case "TH": weekday.push(this.weekOptions.find((o: any) => String(o.value).toLowerCase() == 'thursday')); break;
          case "FR": weekday.push(this.weekOptions.find((o: any) => String(o.value).toLowerCase() == 'friday')); break;
          case "SA": weekday.push(this.weekOptions.find((o: any) => String(o.value).toLowerCase() == 'saturday')); break;
          default: console.log('Wrong value passed on functio - GetRRuleWeekDays -> ', JSON.stringify(val.toString())); break;
        }
      });
      console.log('weekday', JSON.stringify(weekday));
      return weekday;
    }
    else {
      return null;
    }
  }

  //#endregion  methods
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [RruleComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    StyleClassModule,
    DividerModule,
    ButtonModule,
    RadioButtonModule,
    CalendarModule,
    InputNumberModule,
    DialogModule,
    DropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [RruleComponent]
})
export class RruleComponentModule {
}
