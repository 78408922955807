<p-dialog [(visible)]="sendEmailModal" [resizable]="false" appendTo="body" [modal]="true" [closeOnEscape]="true" (onHide)="closeDialogEvent()"
[breakpoints]="{'991px': '100vw'}" [style]="{width: '870px'}" styleClass="no-header-modal overflow-visible">

<ng-template pTemplate="header" styleClass="hidden"></ng-template>
<ng-template pTemplate="content" styleClass="p-0">
  <ng-container *ngIf="{
    client: allClientData$ | async
  } as $">

  <!-- Header -->
  <div class="py-3 px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
    <h2 class="mb-0 text-base md:text-lg font-normal">Send email for <strong>{{$.client.full_name}}</strong></h2>
    <div class="pi pi-times text-gray-500 cursor-pointer" (click)="closeDialogEvent()"></div>
  </div>
  <!-- Header -->

  <!-- Content -->
  <div class="p-3 md:p-4">
   
  
    <!-- Composer -->
    <div class="bg-white shadow-3 border-round relative text-sm">

      <div class="px-3 md:px-4 py-3">

      <!-- From -->
      <div class="flex align-items-center">
        <span class="mr-2 w-3rem">From:</span>
        <strong>Anytime Fitness, Juhu</strong>
      </div>
      <!-- From -->

      <!-- To -->
      <div class="flex align-items-center my-3 border-bottom-1 pb-3 border-gray-300">
        <span class="mr-2 w-3rem">To:</span>
        <strong>Davis Macey <span class="font-normal">560901</span> (andrea@andreasolan.com)</strong>
      </div>
      <!-- To -->

      <!-- Message -->
      <div class="w-full relative">
        <p-editor [(ngModel)]="editorText" [style]="{'height':'220px'}"></p-editor>
      </div>
      <!-- Message -->

    </div>

      <!-- Options -->
      <div class="bg-gray-200 mt-2 text-center md:text-left py-1 md:px-3 px-0 md:flex align-items-center">
            <p-dropdown [options]="attachments" [(ngModel)]="selectedAttachment" optionLabel="name" filterBy="name"
            styleClass="border-none bg-gray-200 text-primary text-sm font-bold" placeholder="Attach Document">
            <ng-template pTemplate="selectedItem">
              <strong *ngIf="selectedAttachment" class="text-sm text-primary">{{selectedAttachment.name}}</strong>
            </ng-template>
            <ng-template let-attachment pTemplate="item">
              <div class="text-sm">{{attachment.name}}</div>
            </ng-template>
          </p-dropdown>

          <p-dropdown [options]="templates" [(ngModel)]="selectedTemplate" optionLabel="name" filterBy="name"
            styleClass="border-none bg-gray-200 text-primary text-sm font-bold" placeholder="Use Email Template">
            <ng-template pTemplate="selectedItem">
              <strong *ngIf="selectedTemplate" class="text-sm text-primary">{{selectedTemplate.name}}</strong>
            </ng-template>
            <ng-template let-template pTemplate="item">
              <div class="text-sm">{{template.name}}</div>
            </ng-template>
          </p-dropdown>
      </div>
      <!-- Options -->

    </div>
    <!-- Composer -->

  </div>
  <!-- Content -->

  <!-- Modal Footer -->
<div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400">
  <div class="flex align-items-center justify-content-between">
    <div class="w-4">
      <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="closeDialogEvent()"></p-button>
    </div>
    <div class="w-8 text-right">
      <button pButton pRipple label="Send Email" class="text-sm" (click)="closeDialogEvent()"></button>
    </div>
  </div>
</div>
<!-- Modal Footer -->
</ng-container>
</ng-template>

</p-dialog>
