import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { BehaviorSubject } from 'rxjs';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { ClientService } from '@fitness-force/api';
@Component({
  selector: 'prospect-info',
  templateUrl: './prospect-info.component.html',
  styleUrls: ['./prospect-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProspectInfoComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  constructor(private router: Router, private clientService: ClientService) { }

  ngOnInit(): void {
  }
  gotoClient(id: any) {
    this.router.navigate(['/client/profile/', id]);
  }

  clientStatus(client: any): string {
    if (client && client.client_status)
      return this.clientService.getClientStatusHtml(client.client_status.toString());
    else
      return '';
  }
}

@NgModule({
  declarations: [ProspectInfoComponent],
  imports: [
    CommonModule,
    OverlayPanelModule,
    AvatarModule,
    ShortNamePipeModule,
    RouterModule
  ],
  exports: [ProspectInfoComponent]
})
export class ProspectInfoComponentModule {
}
