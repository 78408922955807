import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { PayModeType } from '@fitness-force/enum';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import {
  ClientMandatoryFieldResponse, CreateClientPaymentTokenResponse, GetSignUpPaymentMethodResponse, WaiverMasterResponseList,
  SubscriptionSignUpOrderSummaryBillingDateResponse, SubscriptionSignUpOrderSummaryResponse, SubscriptionSignUpWithBillingJsonResponse, CreateClientWaiversRequest, StatusandSubscriptionDetails
} from '@fitness-force/models';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionSignupByUuidService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {

  }

  GetCompany(BASE_URL: string, UUID: string): Observable<any> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_COMPANY.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map(res => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetTenant(BASE_URL: string, UUID: string): Observable<any> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_TENANT.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map(res => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetOrderSummary(BASE_URL: string, UUID: string): Observable<SubscriptionSignUpOrderSummaryResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_ORDERSUMMARY.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetOrderDetails(BASE_URL: string, UUID: string): Observable<SubscriptionSignUpWithBillingJsonResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_ORDERDETAILS.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetOrderSubscriptionStatus(BASE_URL: string, UUID: string): Observable<StatusandSubscriptionDetails> {

    //This was temperory to get result base on the functionality
    //return new Observable(observer => observer.next(Math.floor((Math.random() * 6) + 1)))

    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_ORDERDETAILS.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map((res: any) =>
        res
      ),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetOrderSummaryPaymentTimeLine(BASE_URL: string, UUID: string): Observable<SubscriptionSignUpOrderSummaryBillingDateResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_ORDERSUMMARYBILLINGOCCURENCE.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetWaivers(BASE_URL: string, UUID: string): Observable<WaiverMasterResponseList> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_WAIVERS.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  PostWaivers(BASE_URL: string, UUID: string, waiverPayload: CreateClientWaiversRequest) {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.POST_WAIVERS.replace('{sigup_sts_uuid}', UUID);

    return this.http.post(url, waiverPayload, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetClientMandatoryField(BASE_URL: string, UUID: string): Observable<ClientMandatoryFieldResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_CLIENT_MANDATORYFIELD.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetPaymentMethods(BASE_URL: string, UUID: string): Observable<GetSignUpPaymentMethodResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.GET_PAYMENTMETHODS.replace('{sigup_sts_uuid}', UUID);

    return this.http.get(url, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  ProceedToPay(BASE_URL: string, UUID: string, payModeType: string): Observable<GetSignUpPaymentMethodResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.POST_PROCEEDTOPAYMENT.replace('{sigup_sts_uuid}', UUID);
    const params = { 'payment_modes': payModeType };

    return this.http.post(url, params, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  SaveToken(BASE_URL: string, UUID: string, token: string, internaltransactionid: string): Observable<CreateClientPaymentTokenResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.POST_SAVETOKEN.replace('{sigup_sts_uuid}', UUID);
    const params = { 'token': token, 'internaltransactionid': internaltransactionid };

    return this.http.post(url, params, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  SaveExternalTransactionId(BASE_URL: string, UUID: string, externalTransactionId: string, internalTransactionId: string): Observable<CreateClientPaymentTokenResponse> {
    let url: string = BASE_URL + CONST_URL_SEGEMENTS.SUBSCRIPTIONSIGNUP.POST_SAVEEXTERNALTRANSACTIONID.replace('{sigup_sts_uuid}', UUID);
    const params = { 'external_transaction_id': externalTransactionId, 'internal_transaction_id': internalTransactionId };

    return this.http.post(url, params, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }
}
