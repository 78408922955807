import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { voidInvoiceRequest } from '@fitness-force/models';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppErrors, BadRequestError, NotFoundError } from '@fitness-force/errors';
import { environment } from 'apps/ff-frontend/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  getPaymentPDF(clientId: number, paymentId:number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      })
    };
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.PAYMENTS.GET_PAYMENT_PDF.replace('{client_id}', clientId.toString()).replace('{pymt_id}', paymentId.toString());
    return this.http.get(url, {
      responseType: 'text',headers: httpOptions.headers
    }).pipe(
      map((res: any) => {
          return res
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  voidPayment(tenantId:number,clientId: number, paymentId:number, voidRequest: voidInvoiceRequest): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.PAYMENTS.VOID_PAYMENT.replace('{tenant_id}', tenantId.toString()).replace('{client_id}', clientId.toString()).replace('{pymt_id}', paymentId.toString());
    return this.http.post(url, voidRequest, {headers: this.httpOptions.headers}).pipe(
      map((res: any) => {
          return res
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  SendEmail(clientId: number, paymentId:number): Observable<any> {
    const url = environment.BASE_URL + CONST_URL_SEGEMENTS.PAYMENTS.SEND_EMAIL.replace('{client_id}', clientId.toString()).replace('{pymt_id}', paymentId.toString());
    return this.http.get(url, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
          return res
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

 
}
