import { ClientService } from '@fitness-force/api';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BadRequestError, ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { AssignedToComponentModule, FactoryHttpLoader } from '@fitness-force/shared';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject, concatMap } from 'rxjs';
import { STAFF_STATE } from 'apps/ff-frontend/src/app/state/app.state';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'change-sales-representative',
  templateUrl: './change-sales-representative.component.html',
  styleUrls: ['./change-sales-representative.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeSalesRepresentativeComponent implements OnInit {
  @Input() changeRepresentativeModal: boolean | null = false;
  @Input() clientData$  = new BehaviorSubject<any>(null)
  @Output() closeChangeRepresentativeDialog =  new EventEmitter()
  attachments: any;
  allClientData$ = new BehaviorSubject<any>({})
  clientData:any
  currentStaff$ =  new BehaviorSubject<any>({})
 selectedStaff$ =  new BehaviorSubject<any>({})
  allStaffs$ = new BehaviorSubject<any>([])
  updateClientRepFormGroup: FormGroup = new FormGroup({})
  isClientRepresentative = new BehaviorSubject(true);
  claims: string[] = ['can_be_client_rep'];

  constructor(private store: Store, public translate: TranslateService, private clientService: ClientService, private fb: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
    this.componentInit()
  }
  componentInit(){
  this.updateClientRepFormGroup =  this.fb.group({
    newRepresentative: ['', [Validators.required]]
  })
  this.clientData$.subscribe(
    (client:any)=>{
    this.allClientData$.next(client)
    this.isClientRepresentative.next(false)
    this.clientData = client
    console.log('allClientData', this.allClientData$)
    this.store.select(STAFF_STATE).subscribe((staff:any)=>{
      this.allStaffs$.next(staff.staffListAll)
      const defaultStaff = staff.staffListAll.find((s:any) => client.client_representative_id == s.id)
      this.currentStaff$.next(defaultStaff)
      this.selectedStaff$.next(defaultStaff)
      this.NewRepresentative.setValue(defaultStaff.id)
      console.log('staffff', staff)
    })
  })
  }
  get NewRepresentative(): FormControl{
    return this.updateClientRepFormGroup.get('newRepresentative') as FormControl;
  }
  selectStaff(staff:any){
    this.selectedStaff$.next(staff)
    this.NewRepresentative.setValue(staff.id)
  }
  closeDialogEvent(){
    this.closeChangeRepresentativeDialog.emit(false)
  }
  updateClientRepresentative(){
    console.log('NewRepresentative', this.NewRepresentative.value)
    const client_rep_model = {
      "home_club_tenant_id": this.clientData.home_club_tenant_id,
      "full_name": this.clientData.full_name,
      "mobile_country_code": this.clientData.mobile_country_code,
      "mobile_number": this.clientData.mobile_number,
      "email_id": this.clientData.email_id,
      "date_of_birth": this.clientData.date_of_birth,
      "gender": this.clientData.gender,
      "location": this.clientData.location,
      "second_mobile_country_code":  this.clientData.second_mobile_country_code,
      "second_mobile_number": this.clientData.second_mobile_number,
      "preferred_language":  this.clientData.preferred_language,
      "place_id": this.clientData.place_id,
      "address":  this.clientData.address,
      "city_code":  this.clientData.city_code,
      "state_code":this.clientData.state_code,
      "country_code": this.clientData.country_code,
      "address_pin_code": this.clientData.address_pin_code,
      "emergency_contact_number": this.clientData.emergency_contact_number,
      "emergency_contact_person": this.clientData.emergency_contact_person,
      "source": this.clientData.source,
      "channel":  this.clientData.channel,
      "heartrate_monitoring_id":  this.clientData.heartrate_monitoring_id,
      "referred_by":  this.clientData.referred_by,
      "promotional_sms":   this.clientData.promotional_sms,
      "promotional_email": this.clientData.promotional_email,
      "medical_alert": this.clientData.medical_alert,
      "note": this.clientData.note,
      "taxnumber":this.clientData.taxnumber,
      "custom_fields":this.clientData.custom_fields,
      "current_keyfob": this.clientData.current_keyfob,
      "client_representative_id": this.NewRepresentative.value.id,
      "createdin_tenant_id":  this.clientData.createdin_tenant_id,
      "place_id_request": this.clientData.place_id_request,
      "migration_id": this.clientData.migration_id,
      "image_base64": this.clientData.image_base64,
      "password": this.clientData.password
    }
    
  
    this.allClientData$.subscribe((client:any)=>{
      this.clientService.changeClientRepresentative(client.id, client.home_club_tenant_id, client_rep_model).subscribe({
        next:(res:any)=>{
          this.closeDialogEvent()
          this.messageService.add({severity:'success', summary:'Success!', detail: `${client.full_name} has been assinged.`});
        },
        error: (error)=>{
          console.log('errrr', error)
          if (error instanceof NotFoundError) {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Page Not Found'});
          } else if (error instanceof BadRequestError) {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail: error.originalError.error[0].errorMessage});
          }
          else {
            this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Something went wrong'});
          }
        }
      })
    })
   
  }
}

@NgModule({
  declarations: [ChangeSalesRepresentativeComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    PasswordModule,
    ShortNamePipeModule,
    AvatarModule,
    AssignedToComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),
    ErrorMsgComponentModule,
    ToastModule
  ],
  exports: [ChangeSalesRepresentativeComponent]
})
export class ChangeSalesRepresentativeComponentModule {
}
