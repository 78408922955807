import { createAction, props } from "@ngrx/store";

export const FETCHED_TENANT_LIST = createAction(
    'FETCHEDTENANTLIST',
    props<{ accessed_tenant: any }>()
);

export const FETCHED_TENANT_DETAILS = createAction(
    'FETCHEDTENANTDETAILS',
    props<{ selected_tenant: any }>()
);

export const ERROR_TENANT_LIST = createAction(
    'ERRORTENANTLIST',
    props<{ err: Error }>()
);