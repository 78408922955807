
<ng-container *ngIf="{
  row: columnData | async
} as $">
<p-tag
  *ngIf="$.row.status.toUpperCase() == 'Active'.toUpperCase()"
  styleClass="p-mr-2 text-xxs font-normal uppercase white-space-nowrap"
  severity="success"
  >{{ $.row.status }}</p-tag
>
<p-tag
  *ngIf="$.row.status.toUpperCase() == 'InActive'.toUpperCase()"
  styleClass="p-mr-2 bg-gray-600 text-xxs font-normal uppercase white-space-nowrap"
  >{{ $.row.status }}</p-tag
>
</ng-container>