import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TaxMasterService } from '@fitness-force/api';
import { AppErrors, ErrorMsgComponentModule, UnauthorizedError } from '@fitness-force/errors';
import { ValidationError } from '@fitness-force/models';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  ConflictError,
  NotFoundError,
} from 'libs/errors/src/lib/not-found.error';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DynamicDialogModule,
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { BehaviorSubject } from 'rxjs';
import { FactoryHttpLoader } from '../helpsupport/helpsupport.component';
import { ErrorsComponentModule } from '../errors/errors.component';

@Component({
  selector: 'tax-group-create',
  templateUrl: './tax-group-create.component.html',
  styleUrls: ['./tax-group-create.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxGroupCreateComponent implements OnInit {
  taxCategoryForm!: FormGroup;
  taxCategory!: any;
  tenantId!: number;

  primaryLanguage!: string;
  secondaryLanguage!: string;
  isSecondaryLanguage = false;

  // ERRors
  showErrorPopup = new BehaviorSubject(false);
  error = new BehaviorSubject(null as AppErrors | null);
  errorMessages: ValidationError[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private taxCategoryService: TaxMasterService,
    public translate: TranslateService,
    public fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {
    this.taxCategory = this.config.data?.taxCategory;
    this.tenantId = this.config.data?.tenantId;
    if (this.config.data?.isSecondaryLanguage) {
      this.isSecondaryLanguage = true;
      this.secondaryLanguage = this.config.data?.secondaryLanguage;
      this.primaryLanguage = this.config.data?.primaryLanguage;
    }
  }

  ngOnInit(): void {
    this.createTaxCategoryFrom();
  }

  close() {
    this.ref.close();
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
    this.error.next(null);
  }

  createTaxCategoryFrom() {
    this.taxCategoryForm = this.fb.group({
      name_primary_language: [
        this.taxCategory ? this.taxCategory.name_primary_language : '',
        [Validators.required],
      ],
      name_secondary_language: [
        this.taxCategory ? this.taxCategory.name_secondary_language : ''
      ],
    });
  }

  get CatNamePrimary(): AbstractControl | null {
    return this.taxCategoryForm.get('name_primary_language');
  }
  get CatNameSecondary(): AbstractControl | null {
    return this.taxCategoryForm.get('name_secondary_language');
  }

  saveTaxCategory() {
    if (this.taxCategoryForm.valid) {
      let formData = { ...this.taxCategoryForm.getRawValue() };
      if (this.taxCategory) {
        this.taxCategoryService
          .updateTaxGroup(this.tenantId, this.taxCategory.id, formData)
          .subscribe({
            next: (res) => {
              this.ref.close({ result: res });
            },
            error: (error) => {
              this.handleError(error);
            },
          });
      } else {
        this.taxCategoryService
          .createTaxGroup(this.tenantId, formData)
          .subscribe({
            next: (res) => {
              this.ref.close(res);
            },
            error: (error) => {
              this.handleError(error);
            },
          });
      }
    } else {
      console.log("form invlid...");
      Object.keys(this.taxCategoryForm.controls).forEach((field) => {
        const control = this.taxCategoryForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
      this.cd.detectChanges();
    }
  }

  handleError(error: any) {
    // if (error instanceof NotFoundError) {
    //   this.translate
    //     .get([
    //       'common.errors.SERVER_SIDE.ERROR_OCCURRED',
    //       'Shared.TASK.TASK-CREATE.PAGE_NOT_FOUND',
    //     ])
    //     .subscribe((translations) => {
    //       this.messageService.add({
    //         severity: 'error',
    //         summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
    //         detail: translations['common.errors.SERVER_SIDE.PAGE_NOT_FOUND'],
    //       });
    //     });
    // } else if (error instanceof UnauthorizedError) {
    // } else if (error instanceof ConflictError) {
    //   error.originalError.error.forEach((item: any) => {
    //     if (item.errorCode == 'duplicatevalue') {
    //       this.translate
    //         .get([
    //           'common.errors.SERVER_SIDE.DUPLICATE',
    //           'common.errors.SERVER_SIDE.DUPLICATE_MESSAGE',
    //         ])
    //         .subscribe((translations) => {
    //           this.messageService.add({
    //             severity: 'error',
    //             summary: translations['common.errors.SERVER_SIDE.DUPLICATE'],
    //             detail:
    //               translations['common.errors.SERVER_SIDE.DUPLICATE_MESSAGE'],
    //           });
    //         });
    //     }
    //   });
    // }
    // else {
    //   this.translate
    //     .get([
    //       'common.errors.SERVER_SIDE.ERROR_OCCURRED',
    //       'common.errors.SERVER_SIDE.SOMETHING_WRONG',
    //     ])
    //     .subscribe((translations) => {
    //       this.messageService.add({
    //         severity: 'error',
    //         summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
    //         detail: translations['common.errors.SERVER_SIDE.SOMETHING_WRONG'],
    //       });
    //     });
    // }

    this.error.next(error);
    this.showErrorPopup?.next(true);
  }

  extractErrorMessages(error: any) {
    error.originalError.error.forEach((item: any) => {
      this.errorMessages.push(item.errorMessage);
    });
    if (Array.isArray(error)) {
      error.forEach((item: any) => {
        this.errorMessages.push(item.errorMessage);
      });
    }
  }
}

@NgModule({
  declarations: [TaxGroupCreateComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    DynamicDialogModule,
    FormsModule,
    InputTextModule,
    ErrorsComponentModule,
    ErrorMsgComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TaxGroupCreateComponent],
})
export class TaxGroupCreateComponentModule {}
