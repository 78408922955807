import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { Observable } from 'rxjs';
import { FactoryHttpLoader } from '../../helpsupport/helpsupport.component';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  public companyInfoDetail$!: Observable<any>;
  companyDetails: any;
  constructor(
    private store: Store,
    public translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.componentInit()
  }
  componentInit(){
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO)
    
  }
}

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [FooterComponent]
})
export class AuthFooterComponentModule {
}
