
<ng-container *ngIf="{
    row: columnData | async,
    adata: additionData | async
  } as $">
 <span *ngIf="$.row[$.adata.keyname] !== '0001-01-01T00:00:00';else emptyVal">
<span>{{dateToLocalTimeZone($.row[$.adata.keyname]) | date:'MMM d, y, h:mm a'}}</span>
</span>
<ng-template #emptyVal>-</ng-template>

</ng-container>

