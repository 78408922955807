import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'confirm-page-change',
  templateUrl: './confirm-page-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPageChangeComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}

  close(value:boolean=false) {
    this.ref.close(value);
  }
}

@NgModule({
  declarations: [ConfirmPageChangeComponent],
  imports: [CommonModule, ButtonModule, DialogModule, DynamicDialogModule],
  exports: [ConfirmPageChangeComponent],
})
export class ConfirmPageChangeComponentModule {}
