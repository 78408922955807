<p-dialog [(visible)]="showRRule" (onShow)="CreateFormControls()" [resizable]="false" appendTo="body" [modal]="true" [breakpoints]="{'640px': '100vw'}"
    [style]="{width: '500px'}" styleClass="no-header-modal overflow-visible">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <form [formGroup]="formGroup" (ngSubmit)="CreateRRule()">

            <!-- Content -->
            <div class="p-3 md:p-4 surface-50 relative">
                <div class="pi pi-times text-gray-500 cursor-pointer absolute right-0 top-0 mt-3 mr-3"
                    (click)="HideRRule()"></div>
                <div class="p-buttonset">
                    <button type="button" (click)="Repeat = 'daily'"
                        [ngClass]="Repeat == 'daily' ? '' : 'p-button-secondary' " pButton pRipple
                        label="{{'Shared.RRULE.BUTTON_DAILY' | translate}}"
                        class="text-sm border-right-1 border-white"></button>
                    <button type="button" (click)="Repeat = 'weekly'"
                        [ngClass]="Repeat == 'weekly' ? '' : 'p-button-secondary' " pButton pRipple
                        label="{{'Shared.RRULE.BUTTON_WEEKLY' | translate}}"
                        class="text-sm border-right-1 border-white"></button>
                    <button type="button" (click)="Repeat = 'monthly'"
                        [ngClass]="Repeat == 'monthly' ? '' : 'p-button-secondary' " pButton pRipple
                        label="{{'Shared.RRULE.BUTTON_MONTHLY' | translate}}"
                        class="text-sm border-right-1 border-white"></button>
                </div>

                <!-- For Weekly -->
                <div *ngIf="Repeat == 'weekly'" class="my-2">
                    <div class="p-buttonset flex mt-3">
                        <button type="button" *ngFor="let weekDay of weekOptions" (click)="AddOrRemoveWeekDays(weekDay)"
                            [ngClass]="WeekDays.includes(weekDay) ? '' : 'p-button-secondary' "
                            class="text-sm border-right-1 border-white h-2rem w-2rem border-circle p-0 flex align-items-center justify-content-center mr-2"
                            pButton pRipple label="{{weekDay.name}}"></button>
                    </div>
                </div>
                <!-- For Weekly -->

                <!-- For Monthly -->
                <div *ngIf="Repeat == 'monthly'" class="my-2">
                    <h4 class="text-sm mb-2">
                        {{ 'Shared.RRULE.HEADER_REPEATSEVERY' | translate }}
                    </h4>
                    <div class="flex align-items-center">
                        <p-dropdown [options]="monthOptions" formControlName="monthDays" optionLabel="name"
                            placeholder="Select" styleClass="text-sm">
                            <ng-template pTemplate="selectedItem">
                                <div class="text-sm" *ngIf="MonthDays">{{MonthDays.name}}</div>
                            </ng-template>
                            <ng-template let-monthDays pTemplate="item">
                                <div class="text-sm">{{monthDays.name}}</div>
                            </ng-template>
                        </p-dropdown>
                        <span class="ml-2 text-sm">
                            {{ 'Shared.RRULE.SPAN_DAYOFTHEMONTH' | translate }}
                        </span>
                    </div>
                </div>
                <!-- For Monthly -->

                <h4 class="text-sm mb-0 line-height-1 font-bold mt-3">
                    {{ 'Shared.RRULE.HEADER_STOPCONDITIONS' | translate }}
                </h4>

                <p-divider styleClass="my-2"></p-divider>

                <ul class="list-none p-0 m-0 text-sm">
                    <li class="mt-2 flex align-items-start" *ngIf="!showNeverStop">
                        <p-radioButton name="repeatableRule" value="1" formControlName="repeatableRule"
                            inputId="option1">
                        </p-radioButton>
                        <label class="ml-2" for="option1">
                            {{ 'Shared.RRULE.LABEL_UNTILMEMBERSHIPLASTS' | translate }}
                            <span class="text-xxs text-gray-500 block">
                                {{ 'Shared.RRULE.SPAN_UNTILMEMBERSHIPLASTS' | translate }}
                            </span>
                        </label>
                    </li>
                    <li class="mt-2 flex align-items-start" *ngIf="showNeverStop">
                        <p-radioButton name="repeatableRule" value="2" formControlName="repeatableRule"
                            inputId="option2">
                        </p-radioButton>
                        <label class="ml-2" for="option2">
                            {{ 'Shared.RRULE.LABEL_NEVERSTOP' | translate }}
                            <span class="text-xxs text-gray-500 block">
                                {{ 'Shared.RRULE.SPAN_NEVERSTOP' | translate }}
                            </span>
                        </label>
                    </li>
                    <li class="mt-2 flex align-items-start">
                        <p-radioButton name="repeatableRule" value="3" formControlName="repeatableRule"
                            inputId="option3">
                        </p-radioButton>
                        <label class="ml-2" for="option3">
                            {{ 'Shared.RRULE.LABEL_RUNUNTILDATE' | translate }}
                            <p-calendar placeholder="Select Date" formControlName="until" inputStyleClass="text-sm"
                                styleClass="" [minDate]="minimumDate!" >
                            </p-calendar>
                            <span class="text-xxs text-gray-500 block">
                                {{ 'Shared.RRULE.SPAN_RUNUNTILDATE' | translate }}
                            </span>
                        </label>
                    </li>
                    <li class="mt-3 flex align-items-start">
                        <p-radioButton name="repeatableRule" value="4" formControlName="repeatableRule"
                            inputId="option4">
                        </p-radioButton>
                        <label class="ml-2" for="option4">
                            <span class="flex">
                                {{ 'Shared.RRULE.SPAN_UNTILCOUNT' | translate }}
                                <p-inputNumber [format]="false" inputId="minmax" mode="decimal" formControlName="count"
                                    inputStyleClass="w-3rem h-2rem -mt-2" class="text-sm mx-2" [min]="0" [max]="1000">
                                </p-inputNumber>
                                {{ 'Shared.RRULE.SPAN_UNTILCOUNTOCCURENCE' | translate }}
                            </span>
                            <span class="text-xxs text-gray-500 block">
                                {{ 'Shared.RRULE.SPAN_UNTILCOUNTDESCRIPTION' | translate }}
                            </span>
                        </label>
                    </li>
                </ul>
            </div>
            <!-- Content -->

            <!-- Modal Footer -->
            <div class="px-3 md:px-4 py-3 border-top-1 border-gray-300">
                <div class="flex align-items-center justify-content-between">
                    <p-button styleClass="p-button-link text-sm" (click)="HideRRule()"
                        label="{{ 'Shared.RRULE.BUTTON_CANCEL' | translate }}">
                    </p-button>
                    <button type="submit" class="text-sm" pButton pRipple
                        label="{{ 'Shared.RRULE.BUTTON_SAVE' | translate }}"></button>
                </div>
            </div>
            <!-- Modal Footer -->

        </form>
    </ng-template>
</p-dialog>