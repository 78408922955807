import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { AppointmentById, AppointmentListResponse, CancelAppointmentRequest, CreateAppointmentRequest, CreateRecurringAppointmentRequest, CreateResponse, ListAppointmentRequest, RescheduleAppointmentRequest, RescheduleRecurringAppointmentRequest, SubstituteCoachRequest, UpdateAppointmentRequest } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http: HttpClient, private router: Router) { }

  BASE_URL: string = environment.BASE_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  
  GetAppointments(appointmentRequest: ListAppointmentRequest): Observable<AppointmentListResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.GET_APPOINTMENTS.replace('{tenant_id}', appointmentRequest.tenant_id.toString());
    const queryParams = {"start_datetime":appointmentRequest.start_datetime,"end_datetime":appointmentRequest.end_datetime};
    return this.http.get<ListAppointmentRequest>(url,{headers: this.httpOptions.headers,params:queryParams}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  GetAppointment(tenantId:number,apptId:number): Observable<AppointmentById> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.GET_APPOINTMENT.replace('{tenant_id}',tenantId.toString()).replace('{appt_id}', apptId.toString());;
    let queryParam = new HttpParams();
    return this.http.get(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  CreateAppointment(tenantId:number,createAppointmentRequest: CreateAppointmentRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.POST_APPOINTMENTS.replace('{tenant_id}', tenantId.toString());
    return this.http.post<CreateAppointmentRequest>(url,createAppointmentRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  CreateRecurringAppointment(tenantId:number,createRecurringAppointmentRequest: CreateRecurringAppointmentRequest ): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.POST_RECURRING_APPOINTMENTS.replace('{tenant_id}', tenantId.toString());
    return this.http.post<CreateRecurringAppointmentRequest>(url,createRecurringAppointmentRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  UpdateAppointment(tenantId:number, apptId:number, updateAppointmentRequest: UpdateAppointmentRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.PUT_APPOINTMENT.replace('{tenant_id}', tenantId.toString()).replace('{appt_id}', apptId.toString());
    return this.http.put<UpdateAppointmentRequest>(url,updateAppointmentRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  RescheduleAppointment(tenantId:number, apptId:number, rescheduleAppointmentRequest: RescheduleAppointmentRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.RESCHEDULE_APPOINTMENT.replace('{tenant_id}', tenantId.toString()).replace('{appt_id}', apptId.toString());
    return this.http.post<RescheduleAppointmentRequest>(url,rescheduleAppointmentRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  SubstituteCoach(tenantId:number, apptId:number, substituteCoachRequest: SubstituteCoachRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.SUBSTITUTE_COACH.replace('{tenant_id}', tenantId.toString()).replace('{appt_id}', apptId.toString());
    return this.http.post<SubstituteCoachRequest>(url,substituteCoachRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  
  UpdateRecurringAppointment(tenantId:number, apptTemplateId:number, updateAppointmentRequest: UpdateAppointmentRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.PUT_RECURRING_APPOINTMENT.replace('{tenant_id}', tenantId.toString()).replace('{appt_recurring_template_id}', apptTemplateId.toString());
    return this.http.put<UpdateAppointmentRequest>(url,updateAppointmentRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  RescheduleRecurringAppointment(tenantId:number, apptTemplateId:number, rescheduleAppointmentRequest: RescheduleRecurringAppointmentRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.RESCHEDULE_RECURRING_APPOINTMENT.replace('{tenant_id}', tenantId.toString()).replace('{appt_recurring_template_id}', apptTemplateId.toString());
    return this.http.post<RescheduleRecurringAppointmentRequest>(url,rescheduleAppointmentRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  SubstituteCoachInRecurring(tenantId:number, apptTemplateId:number, substituteCoachRequest: SubstituteCoachRequest): Observable<CreateResponse> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.SUBSTITUTE_COACH_RECURRING.replace('{tenant_id}', tenantId.toString()).replace('{appt_recurring_template_id}', apptTemplateId.toString());
    return this.http.post<SubstituteCoachRequest>(url,substituteCoachRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  MarkClientCheckIn(tenant_Id:number,appt_id:number): Observable<any> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.MARK_CLIENT_CHECKEDIN.replace('{tenant_id}', tenant_Id.toString()).replace('{appt_id}', appt_id.toString());
    return this.http.post(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  MarkClientNoShow(tenant_Id:number,appt_id:number): Observable<any> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.MARK_CLIENT_NOSHOW.replace('{tenant_id}', tenant_Id.toString()).replace('{appt_id}', appt_id.toString());
    return this.http.post(url,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  CancelAppointment(tenant_Id:number,appt_id:number, cancelRequest:CancelAppointmentRequest): Observable<any> {
    const url = this.BASE_URL + CONST_URL_SEGEMENTS.SCHEDULING.APPOINTMENT.CANCEL_APPOINTMENT.replace('{tenant_id}', tenant_Id.toString()).replace('{appt_id}', appt_id.toString());
    return this.http.post(url,cancelRequest,{headers: this.httpOptions.headers}).pipe(map((res: any) => {
      if (res) {
        return res;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

}
