import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TextTransForm',
})
export class TextTransFormPipe implements PipeTransform {
  transform(status: string): any {
      console.log('sasiorjkd', status)
    if (status) {
      let result = status.replace(/([A-Z])/g, ' $1');
      let finalStatus = result.charAt(0).toUpperCase() + result.slice(1);
      console.log(finalStatus);
      if (finalStatus.toLowerCase().includes('non member')) {
        return finalStatus.toLowerCase().replace(/non member/g, 'non-member');
      } else {
        return finalStatus;
      }
    } else {
      return status;
    }
  }
}

@NgModule({
  declarations: [TextTransFormPipe],
  imports: [
      CommonModule
  ],
  exports: [TextTransFormPipe],
})
export class TextTransFormPipeModule {}
