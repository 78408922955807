import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {Component,OnInit,ChangeDetectionStrategy,NgModule,Input,Output,EventEmitter,} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FactoryHttpLoader,
  FormDatepickerComponentModule,
} from '@fitness-force/shared';
import { Store } from '@ngrx/store';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessagesModule } from 'primeng/messages';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorMsgComponentModule } from '@fitness-force/errors';
import { __values } from 'tslib';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit {
  @Input() field!: any;
  @Input() form!: FormGroup;
  @Input() selectedRelativeDate: Observable<any> | any;
  @Input("CustomDates") timePeriod:  Observable<any> | any;

  @Output() selectedTimePeriodOutput: EventEmitter<any> = new EventEmitter();
  @Output() timePeriodObjectOutput: EventEmitter<any> = new EventEmitter();
  @Output() moreThan3monthOutput: EventEmitter<any> = new EventEmitter();
  @Output() FormOutput: EventEmitter<FormGroup> = new EventEmitter();

  public companyInfoDetail$!: Observable<any>;
  selectedTimePeriod :Observable<any> | any;
  timePeriodDate: Observable<Date[]> | any;
  customDateModal = new BehaviorSubject(false);
  moreThanThreeMonths: boolean = false;
  selectedFormGroup: any;
  reportLongerThan3Months: boolean= false;
  shoCustomDateRange: boolean= false;


  constructor(private translate: TranslateService, private store: Store) {}

  ngOnInit(): void {
    if(this.selectedRelativeDate)
    {
      this.form.get('timePeriod')?.setValue(this.selectedRelativeDate);
      this.selectedTimePeriod = this.selectedRelativeDate;
      if(this.selectedTimePeriod.value == 99)
      {
        this.shoCustomDateRange = true;
      }
    }
    this.timePeriodDate= this.timePeriod;
    this.componentInit();
  }

  componentInit() {
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO);
    this.companyInfoDetail$.subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });
  }

  SelectTimePeriod(timePeriod: any, form: any) {
    this.selectedTimePeriod = timePeriod.value;
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    if (timePeriod.value.value == 99) {
      this.customDateModal.next(true);
    } else {
      startDate = new Date(this.selectedTimePeriod.date['from_date']);
      endDate = new Date(this.selectedTimePeriod.date['to_date']);
      this.moreThanThreeMonths = false;
      this.timePeriodDate =null;
    }
    form.get('to_date').setValue(endDate);
    form.get('from_date').setValue(startDate);
    this.selectedTimePeriodOutput.emit({timePeriod: this.selectedTimePeriod, form:form});
  }

  onDateSelect(event: any, form:any) {
    this.customDateModal.next(false);
    this.timePeriodDate = event.dateObject;
    const months = this.monthDiff(this.timePeriodDate[0],this.timePeriodDate[1]);

    if (months >= 3) this.moreThanThreeMonths = true;
    else this.moreThanThreeMonths = false;
    
    this.selectedFormGroup = event.form;
    event.form.get('to_date').setValue(this.timePeriodDate[1].toISOString());
    event.form.get('from_date').setValue(this.timePeriodDate[0].toISOString());
    this.selectedTimePeriodOutput.emit({timePeriod: this.selectedTimePeriod, form:form});
    this.timePeriodObjectOutput.emit({dateObject: this.timePeriodDate, disableButton:this.moreThanThreeMonths});
  }

  OpenLongerReport()
  {
    this.moreThan3monthOutput.emit({status:true, form:this.form});
  }

  monthDiff(d1: Date, d2: Date): Number {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  hideCalender(e:any)
  {
    this.customDateModal.next(false);
    this.selectedTimePeriod =null;
    this.selectedRelativeDate=null;
    this.timePeriodDate =null;
    this.form.get('timePeriod')?.setValue(null);
  }

  get GetTimeObject():Date[]{ return this.timePeriodDate;}

  get GetselectedTimePeriod(){ return this.selectedTimePeriod;}
  

  GetField(form:FormGroup, field:any): FormControl { return this.form.controls[field] as FormControl; }
  GetFieldValue(form:FormGroup, field:any) { return this.form.get(field)?.value};

  

}

@NgModule({
  declarations: [DynamicFormComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    MessagesModule,
    ErrorMsgComponentModule,
    FormDatepickerComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [DynamicFormComponent],
})
export class DynamicFormComponentModule {}
