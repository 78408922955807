import { DatePipe } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeconversionService {

  languageCode = new BehaviorSubject("");

  constructor(public store: Store, @Inject(LOCALE_ID) public locale: string, private datePipe: DatePipe) {
    this.store.select(COMPANY_INFO).subscribe((responseData) => {
      this.languageCode.next(responseData.selectedLang.LANG_CODE);
    });

  }

  private readonly datePatterns = new Map<string, string>([
    ['short', 'd/M/y, h:mm:ss a'],
    ['medium', 'MMM d, y, h:mm:ss a'],
    ['long', 'EEEE, LLLL d, y, h:mm:ss a'],
    ['dateOnly', 'MMM d, y'],
    ['full', 'EEEE, MMMM d, y, h:mm:ss a zzzz'],
    ['shortDate', 'M/d/yy'],
    ['mediumDate', 'MMM d, y'],
    ['longDate', 'MMMM d, y'],
    ['fullDate', 'EEEE, MMMM d, y'],
    ['shortTime', 'h:mm a'],
    ['mediumTime', 'h:mm:ss a'],
    ['longTime', 'h:mm:ss a z'],
    ['fullTime', 'h:mm:ss a zzzz'],
    ['atDate', 'dd MMM yyyy,hh:mm a'],
    ['usformat', 'M/d/yyyy'],
    ['expiryDate', 'M/d'],
  ]);

  formatDate(date: Date | string, format?: string): any {
    let pattern = this.datePatterns.get('dateOnly');
    if (format) pattern = this.datePatterns.get(format!);
    //  TODO:  Need to put defaultTimezone here discussion needed how localization is handled
    const datePipe: DatePipe = new DatePipe(this.languageCode.value);
    return datePipe.transform(date, pattern);
  }

  // Convert UTC time to Local Time
  ConvertUTCToLocalDateTime(date: Date | string): Date {
    let localDate = new Date(date)
    let offset = new Date().getTimezoneOffset();
    localDate.setMinutes(localDate.getMinutes() - offset);
    return localDate;
  }

  // Convert Local Time To UTC
  ConvertLocalDateTimeToUTC(date: Date | string): string {
    return new Date(date).toUTCString();
  }

  // Get converted UTC datetime by date and time
  GetUTCDateTimeByDateAndTime(date: Date | string, time: Date | string): string {
    time = new Date(time);
    date = new Date(date);

    // let followupTime = new Date(time).toLocaleTimeString();
    // let followupDate = new Date(date).toLocaleDateString();
    let followupDateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
      time.getMilliseconds()
    ).toISOString();
    return followupDateTime;
  }

  GetUTCTimeFromDate(date: Date | string) {
    let utcDate = new Date(date).toISOString();
    return this.datePipe.transform(utcDate, 'H:mm', 'UTC')!.toString();
  }
}
