import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, ChangeDetectorRef } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { ChargeMasterService } from '@fitness-force/api';
import { ConflictError, ErrorMsgComponentModule, NotFoundError, UnauthorizedError } from '@fitness-force/errors';
import { TENANTSINTERFACE, ValidationError } from '@fitness-force/models';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BehaviorSubject, Observable } from 'rxjs';
import { FactoryHttpLoader } from '../helpsupport/helpsupport.component';

@Component({
  selector: 'create-charge-category',
  templateUrl: './create-charge-category.component.html',
  styleUrls: ['./create-charge-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateChargeCategoryComponent implements OnInit {
  getAccessTenantList$!: Observable<any>;
  chargeCategoryForm!: FormGroup;
  chargeCategory!: any;
  tenantId!: number;

  primaryLanguage!: string;
  secondaryLanguage!: string;

  // ERRors
  showErrorPopup = new BehaviorSubject(false);
  errorMessages: ValidationError[] = [];

  constructor(
    private store: Store,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private chargeMasterService: ChargeMasterService,
    public translate: TranslateService,
    private messageService: MessageService,
    public fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {
    this.chargeCategory = this.config.data?.chargeCategory;
  }


  ngOnInit(): void {
    this.componentInit();

  }

  componentInit() {
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });

        this.translate
          .get([
            'common.LANGUAGE.' +
              tenant.selected_tenant.primary_language?.toUpperCase(),
            'common.LANGUAGE.' +
              tenant.selected_tenant.secondary_language?.toUpperCase(),
          ])
          .subscribe((translations) => {
            this.primaryLanguage =
              translations[
                'common.LANGUAGE.' +
                  tenant.selected_tenant.primary_language?.toUpperCase()
              ];
            this.secondaryLanguage =
              translations[
                'common.LANGUAGE.' +
                  tenant.selected_tenant.secondary_language?.toUpperCase()
              ];
          });
         this.createChargeCategoryFrom();
      }
    });
  }

  close() {
    this.ref.close();
  }

  createChargeCategoryFrom() {
    this.chargeCategoryForm = this.fb.group({
      name_primary_language: [
        this.chargeCategory ? this.chargeCategory.name_primary_language : '',
        Validators.required,
      ],
      name_secondary_language: [
        this.chargeCategory ? this.chargeCategory.name_secondary_language : '',
      ],
      description_primary_language: [
        this.chargeCategory ? this.chargeCategory.description_primary_language : '',
      ],
      description_secondary_language: [
        this.chargeCategory ? this.chargeCategory.description_secondary_language : '',
      ],
    });
  }

  get CatNamePrimary(): AbstractControl | null {
    return this.chargeCategoryForm?this.chargeCategoryForm.get('name_primary_language'):null;
  }
  get CatNameSecondary(): AbstractControl | null {
    return this.chargeCategoryForm?this.chargeCategoryForm.get('name_secondary_language'):null;
  }

  get DescPrimary(): AbstractControl | null {
    return this.chargeCategoryForm?this.chargeCategoryForm.get('description_primary_language'):null;
  }
  get DescSecondary(): AbstractControl | null {
    return this.chargeCategoryForm?this.chargeCategoryForm.get('description_secondary_language'):null;
  }

  saveChargeCategory() {
    if (this.chargeCategoryForm.valid) {
      let formData = { ...this.chargeCategoryForm.getRawValue() };
      if (this.chargeCategory) {
        this.chargeMasterService.updateChargeCategoryMaster(this.tenantId, this.chargeCategory.id, formData)
          .subscribe({
            next: (res:any) => {
              this.ref.close({ result: res });
            },
            error: (error:any) => {
              this.handleError(error);
            },
          });
      } else {
        this.chargeMasterService.createChargeCategoryMasters(this.tenantId, formData)
          .subscribe({
            next: (res:any) => {
              this.ref.close(res);
            },
            error: (error:any) => {
              this.handleError(error);
            },
          });
      }
    } else {
      Object.keys(this.chargeCategoryForm.controls).forEach((field) => {
        const control = this.chargeCategoryForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
      this.cd.detectChanges();
    }
  }

  handleError(error: any) {
    if (error instanceof NotFoundError) {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'Shared.TASK.TASK-CREATE.PAGE_NOT_FOUND',
        ])
        .subscribe((translations) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.PAGE_NOT_FOUND'],
          });
        });
    } else if (error instanceof UnauthorizedError) {
    } else if (error instanceof ConflictError) {
      let duplicate =false;
      error.originalError.error.forEach((item: any) => {
        // ERROR Codes on server are not consistent, different for same error
          if (item.errorCode == 'duplicatevalue') {
            duplicate =true;
            if (item.errorMessage.includes('pri'))
              this.chargeCategoryForm.controls['name_primary_language'].setErrors({
                duplicate: true,
              });

            if (item.errorMessage.includes('sec'))
              this.chargeCategoryForm.controls['name_secondary_language'].setErrors({
                duplicate: true,
              });
        }
      });
      if(duplicate){
        this.cd.detectChanges();
        return;
      }
    } else {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'common.errors.SERVER_SIDE.SOMETHING_WRONG',
        ])
        .subscribe((translations) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.SOMETHING_WRONG'],
          });
        });
    }
    this.extractErrorMessages(error);
    this.showErrorPopup?.next(true);
  }

  extractErrorMessages(error: any) {
    error.originalError.error.forEach((item: any) => {
      this.errorMessages.push(item.errorMessage);
    });
    if (Array.isArray(error)) {
      error.forEach((item: any) => {
        this.errorMessages.push(item.errorMessage);
      });
    }
  }
}

@NgModule({
  declarations: [CreateChargeCategoryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    DynamicDialogModule,
    FormsModule,
    InputTextModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
    ErrorMsgComponentModule,
    InputTextareaModule
  ],
  exports: [CreateChargeCategoryComponent]
})
export class CreateChargeCategoryComponentModule {}
