<ng-container *ngIf="{
    subscription: allSubscriptions$ | async,
    ssSingUp: allSubscriptionsSingups$ | async
  } as $">
  <!-- Create New Subscription -->
  <div class="bg-white border-1 border-gray-300 shadow-1 border-round text-sm">
    <div class="grid align-items-center grid-nogutter">
      <div class="col-9 flex align-items-center">
        <div class="p-3" *ngIf="!showsubscriptionDetails">
          <span class="text-gray-800">{{'SUBSCRIPTIONS.TOTAL-SUBSCRIPTION' | translate}} - <strong
              *ngIf="$.subscription">{{$.subscription.length}}</strong><strong *ngIf="!$.subscription">0</strong></span>
        </div>
        <div (click)="BacktoAllSubscriptions()"
          class="inline-flex cursor-pointer pl-2 pr-3 mr-3 py-3 md:align-items-center border-right-1 border-gray-300"
          *ngIf="showsubscriptionDetails">
          <span class="flex align-items-center text-primary text-sm font-bold"><i class="pi pi-angle-left mr-2"></i>
            <span>{{'SUBSCRIPTIONS.BACK' | translate}}</span>
          </span>
        </div>
        <strong *ngIf="showsubscriptionDetails">{{'SUBSCRIPTIONS.SUBSCRIPTION-DETAILS' | translate}}</strong>
      </div>
      <div class="col-3 text-right px-2">
        <button pButton pRipple type="button" icon="pi pi-plus" [routerLink]="['/pos/membership-addons/'+ clientId]"
          class="w-2rem h-2rem xl:w-13rem text-sm flex-row-reverse"><span
            class="hidden xl:inline ml-2">{{'SUBSCRIPTIONS.SUBSCRIPTIONS-ADDON' | translate}}</span></button>
      </div>
    </div>
  </div>
  <!-- Create New Subscription -->

  <!-- Subscriptions Card -->
  <div>

    <!-- Contract Card -->
    <ng-container *ngFor="let ss of $.subscription">      
      <div class="cursor-pointer bg-white shadow-1 border-round text-sm mt-3 p-3 md:px-4 relative"
        (click)="goToSubscriptionDetails(ss)">
        <!-- Heading -->
        <div class="grid my-0">
          <div class="col-12 md:col-8">
            <div class="flex">
              <span
                class="bg-blue-50 px-2 py-1 border-round text-primary border-1 border-primary text-xs mr-2 uppercase">
                {{'POS.PLANTYPE.'+ ss.subscription_category.toUpperCase() | translate}}
              </span>
              <span
                class="bg-green-500 px-2 py-1 flex align-items-center justify-content-center border-round text-white text-xxs uppercase">{{ss.status
                | titlecase}}</span>
            </div>
            <h3 class="my-2 font-bold text-base md:text-xl">
              <span>{{ss.name_primary_language || name_secondary_language | titlecase}}</span>
            </h3>
            <p class="mb-0 md:mb-1 text-xs text-gray-600"
              *ngIf="ss.subscription_category.toLowerCase() != 'paidinfull' && ss.subscription_category.toLowerCase() != 'dropin'">
              <!-- {{'SUBSCRIPTIONS.MONTHLY-SUBSCRIPTIONS' | translate}} -->
              <ng-container *ngFor="let membership of ss.recurring_template_recurring_services; let i = index">
                <span
                  *ngIf="membership.service_type_name_primary_language">{{membership.service_type_name_primary_language
                  || membership.service_type_name_secondary_language}}</span>
                <span class="inline-block px-2 line-height-4 text-xxs bg-gray-200 border-round mx-1"
                  *ngIf="membership.service_is_base_membership">{{'SUBSCRIPTIONS.BASE-MEMEBERSHIP' | translate}}</span>
                <br class="md:hidden">
                <span
                  *ngIf="!membership.service_type_name_primary_language && !membership.service_type_name_secondary_language">{{'SUBSCRIPTIONS.UNKNOWN'
                  | translate}}</span>
                <span
                  *ngIf="ss.recurring_template_recurring_services.length > 0 && ((ss.recurring_template_recurring_services.length - 1) !== i)">
                  , </span>
              </ng-container>
            </p>
            <p class="mb-0 md:mb-1 text-xs text-gray-600"
              *ngIf="ss.subscription_category.toLowerCase() == 'paidinfull' || ss.subscription_category.toLowerCase() == 'dropin'">
              <span>{{ss.description_primary_language}}</span>
            </p>
            <p class="mb-0 md:mb-1 text-xs text-gray-600"
              *ngIf="ss.subscription_category.toLowerCase() == 'paidinfull' || ss.subscription_category.toLowerCase() == 'dropin'">
              <!-- {{'SUBSCRIPTIONS.MONTHLY-SUBSCRIPTIONS' | translate}} -->
              <ng-container *ngFor="let membership of ss.non_recurring_template_recurring_services; let i = index">
                <span
                  *ngIf="membership.service_type_name_primary_language">{{membership.service_type_name_primary_language
                  || membership.service_type_name_secondary_language}}</span>
                <span class="inline-block px-2 line-height-4 text-xxs bg-gray-200 border-round mx-1"
                  *ngIf="membership.service_is_base_membership">{{'SUBSCRIPTIONS.BASE-MEMEBERSHIP' | translate}}</span>
                <br class="md:hidden">
                <span
                  *ngIf="!membership.service_type_name_primary_language && !membership.service_type_name_secondary_language">{{'SUBSCRIPTIONS.UNKNOWN'
                  | translate}}</span>
                <span
                  *ngIf="ss.recurring_template_recurring_services.length > 0 && ((ss.recurring_template_recurring_services.length - 1) !== i)">
                  , </span>
              </ng-container>
            </p>

          </div>
          <div class="col-12 md:col-4 text-base md:text-2xl flex align-items-center md:justify-content-end line-height-1 mb-1 -mt-1 md:m-0">
            <div class="inline-block">
            <strong class="text-2xl md:text-4xl line-height-1"
              *ngIf="ss.subscription_category.toLowerCase() != 'paidinfull' && ss.subscription_category.toLowerCase() != 'dropin'">
              {{ss.recurring_revenue | currencyformat}}
            </strong>
            <sub class="ml-1 mt-2" *ngIf="ss.subscription_category.toLowerCase() != 'paidinfull' && ss.subscription_category.toLowerCase() != 'dropin'">/
              {{ (ss.billing_interval_value> 1)? ss.billing_interval_value.toString() :""}}
              {{ 'POS.MEMBERSHIP_ADDON.' + getInterval(ss.billing_interval_value,ss.billing_interval_type) | translate}}
            </sub>
             <div class="text-xs text-gray-600">{{'SUBSCRIPTIONS.INCLU_TAX' | translate}}</div>
            </div>
          </div>

          <!-- <div class="col-12 md:col-4 text-base md:text-2xl flex align-items-center md:justify-content-end line-height-1 mb-1 -mt-1 md:m-0">
            <div class="inline-block">
                <strong class="text-2xl md:text-4xl line-height-1">$229,357.80</strong><sub class="ml-1 mt-2 text-sm"> /  Month </sub>
                <div class="text-xs text-gray-600">(excl taxes)</div>
                </div>
            </div> -->
        </div>
        <!-- Heading -->

        <!-- Content -->
        <p-divider styleClass="my-0"></p-divider>
        <div class="grid my-0">
          <div class="col-6 xl:col-3 my-2 py-1">
            <h6 class="font-medium text-gray-700 text-xs mb-2">{{'SUBSCRIPTIONS.START-DATE' | translate}}</h6>
            <strong class="block text-base line-height-1" *ngIf="ss.start_date">{{ss.start_date |
              datetimeformat: 'mediumDate'}}</strong>
          </div>
          <div class="col-6 xl:col-3 my-2 py-1" *ngIf="ss.subscription_category.toLowerCase() != 'paidinfull'">
            <h6 class="font-medium text-gray-700 text-xs mb-2">{{'SUBSCRIPTIONS.NEXT-BILLING-DATE' | translate}}</h6>
            <strong class="block text-base line-height-1" *ngIf="ss.next_charge_date">{{ss.next_charge_date |
              datetimeformat: 'mediumDate'}}</strong>
          </div>
          <div class="col-12 md:col-6 xl:col-2 my-2 py-1"
            *ngIf="ss.subscription_category.toLowerCase() != 'paidinfull'">
            <h6 class="font-medium text-gray-700 text-xs mb-2">{{'SUBSCRIPTIONS.BILLING_FREQUENCY' | translate}}</h6>
              <strong class="block text-base line-height-1">{{GetBillingFrequency(ss)}}</strong>
          </div>
          <div class="col-6 xl:col-3 my-2 py-1" *ngIf="ss.subscription_category.toLowerCase() == 'paidinfull'">
            <h6 class="font-medium text-gray-700 text-xs mb-2">{{'SUBSCRIPTIONS.END_DATE' | translate}}</h6>
            <strong class="block text-base line-height-1" *ngIf="ss.end_date ">{{ss.end_date |
              datetimeformat: 'mediumDate'}} </strong>
          </div>

          <div class="col-12 md:col-6 xl:col-4 my-2 py-1 xl:flex justify-content-end" *ngIf="ss.payment_method">
            <div class="flex xl:justify-content-end align-items-center pt-3 mt-1 relative">
              <h6 class="text-xs mb-2 text-gray-700 font-medium absolute top-0 -mt-1 w-full text-left">
                {{'SUBSCRIPTIONS.PAYMENT-METHOD' | translate}}
              </h6>
              <strong class="flex align-items-center text-base xl:text-lg line-height-1">
                <img class="mr-2" width="35" src="/assets/img/visa.png" alt="Visa" />{{'SUBSCRIPTIONS.CARD-ENDING-WITH'
                | translate}}
                {{ss.payment_method.masked_card_number}}
              </strong>
            </div>
          </div>
        </div>
        <p-divider styleClass="my-0"></p-divider>
        <!-- Content -->

        <!-- List -->        
        <ul class="list-none p-0 text-xs mb-0"
          *ngIf="ss.subscription_category.toLowerCase() != 'paidinfull' && ss.subscription_category.toLowerCase() != 'dropin'">          
          <ng-container *ngFor="let mm of ss.recurring_template_recurring_services; let i = index">
            <li class="mt-2 pt-1 md:flex align-items-center text-gray-700"
              *ngIf="mm?.service_behaviour && mm.service_behaviour?.toLowerCase() == 'classpackage' || mm.service_behaviour?.toLowerCase() == 'appointmentpackage'">
              <div class="flex align-items-center md:mr-4"><i class="pi pi-check-square mr-2 text-xs">
                  {{mm?.service_type_name_primary_language || mm?.service_type_name_secondary_language | titlecase}}</i>
              </div>
              <div class="md:mr-4 pl-3 ml-1">{{'SUBSCRIPTIONS.SESSIONS' | translate}} - {{mm.service_sessions}}</div>
            </li>
          </ng-container>
        </ul>
        <ul class="list-none p-0 text-xs mb-0"
          *ngIf="ss.subscription_category.toLowerCase() == 'paidinfull' || ss.subscription_category.toLowerCase() == 'dropin'">
          <ng-container *ngFor="let mm of ss.non_recurring_template_recurring_services; let i = index">
            <li class="mt-2 pt-1 md:flex align-items-center text-gray-700"
              *ngIf="mm.service_behaviour && mm.service_behaviour?.toLowerCase() == 'classpackage' || mm.service_behaviour?.toLowerCase() == 'appointmentpackage'">
              <div class="flex align-items-center md:mr-4">
                <i class="pi pi-check-square mr-2 text-xs"></i>
                <span>{{mm?.service_type_name_primary_language || mm?.service_type_name_secondary_language |
                  titlecase}}</span>
              </div>
              <div class="md:mr-4 pl-3 ml-1">{{'SUBSCRIPTIONS.SESSIONS' | translate}} - {{mm.service_sessions}}</div>
            </li>
          </ng-container>
        </ul>
        <!-- List -->

      </div>
    </ng-container>
    <!-- Contract Card -->

    <!-- Progress Card NEW -->

    <ng-container *ngFor="let ss of $.ssSingUp">

      <div class="bg-white shadow-1 border-round text-sm mt-3 p-3 md:px-4">

        <!-- Header -->
        <div class="-mx-3 md:-mx-4 -mt-3 mb-3 px-3 bg-gray-200 border-bottom-1 border-gray-300">

          <div class="grid justify-content-center my-0">
            <div class="col-12 md:col-8 xl:col-4">
              <p-timeline [value]="subscriptionProgress" layout="horizontal" styleClass="-mt-4 -mb-3 ml-2 md:ml-0">
                <ng-template pTemplate="marker" let-event>
                  <span *ngIf="event.status == 'Done'"
                    class="custom-marker h-1rem w-1rem border-1 border-circle bg-primary border-primary"></span>
                  <span *ngIf="event.status == 'Pending'"
                    class="custom-marker h-1rem w-1rem border-1 border-circle bg-white border-gray-400"></span>
                </ng-template>
                <ng-template pTemplate="content" let-progress>
                  <div class="text-xxs md:text-xs white-space-nowrap -mt-2 -ml-2 md:-ml-3">{{progress.title}}</div>
                </ng-template>
              </p-timeline>
            </div>
          </div>
        </div>
        <!-- Header -->

        <!-- Heading -->
        <div class="grid my-0">
          <div class="col-12 md:col-8">
            <p-tag severity="warning" value="{{'SUBSCRIPTIONS.SIGNUP_' + ss.final_status.toUpperCase() |translate }}" styleClass="text-white"></p-tag>
            
            <div class="flex align-items-center mt-2">
              <h3 class="mb-0 mr-2 font-bold text-base md:text-xl">{{ss.plan_primary_name || ss.plan_secondary_name }}
              </h3>
            </div>
          </div>
        </div>
        <!-- Heading -->

        <!-- Content -->
        <p-divider styleClass="my-0"></p-divider>
        <div class="grid my-0">
          <div class="col-12 md:col-6 xl:col-4 my-2 py-1">
            <h6 class="font-medium text-gray-700 text-xs mb-2">{{'SUBSCRIPTIONS.SIGNUP_LINK_EXPIRY' | translate}}</h6>
            <strong class="block text-base line-height-1">{{ss.link_expiry_date | datetimeformat:
              'mediumDate'}}</strong>
          </div>
          <div class="col-12 md:col-6 xl:col-5 my-2 py-1">
            <h6 class="font-medium text-gray-700 text-xs mb-2">{{'SUBSCRIPTIONS.SIGNUP_LINK'| translate}}</h6>
            <div class="flex align-items-center">
              <a href="{{ss.short_link}}" target="_blank" class="text-primary font-bold">{{ss.short_link}}</a>
              <div class="relative inline-block ml-3">
                <p-button styleClass="text-sm justify-content-between align-items-center p-0" pStyleClass="@next"
                  enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout"
                  [hideOnOutsideClick]="true">
                  <div class="flex align-items-center mr-3 p-2">
                    <i class="pi pi-paperclip "></i><span class="p-button-label ml-2">{{ 'SUBSCRIPTIONS.RESEND_LINK' |
                      translate}}</span>
                  </div>
                  <div class="p-2 border-left-1 border-white">
                    <i class="pi pi-angle-down mt-1 ml-1"></i>
                  </div>
                </p-button>
                <div
                  class="resend-dropdown hidden absolute left-0 w-full top-0 mt-6 z-1 bg-white border-round border-1 border-gray-300 py-2 text-sm">
                  <div class="px-3 py-2 hover:bg-gray-200 cursor-pointer" pStyleClass=".resend-dropdown"
                    enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout"
                    [hideOnOutsideClick]="true">{{'SUBSCRIPTIONS.SEND_EMAIL_LINK' | translate}}</div>
                  <div class="px-3 py-2 hover:bg-gray-200 cursor-pointer" pStyleClass=".resend-dropdown"
                    enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout"
                    [hideOnOutsideClick]="true">{{'SUBSCRIPTIONS.SEND_SMS_LINK' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 xl:col-3 my-2 py-1">
            <h6 class="font-medium text-gray-700 text-xs mb-2"> {{'SUBSCRIPTIONS.LAST_LINKED_OPENED' | translate}}</h6>
            <strong class="block text-base xl:text-lg line-height-1">{{ (getYear(ss.link_last_opened)==
              1)?'-': (ss.link_last_opened | datetimeformat: 'mediumDate') }}</strong>
          </div>
        </div>
        <!-- Content -->
      </div>
    </ng-container>

    <!-- Progress Card-->


  </div>
  <!-- Subscriptions Card -->


</ng-container>
<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>