import { STAGE_MASTER_MODEL, STAGE_MASTER_RESPONSE_MODEL } from "@fitness-force/models";



export const STAGE_MASTER_STATE:STAGE_MASTER_RESPONSE_MODEL =   {
        stages: [{
            "id": 0,
            "board_type": "",
            "company_id": 0,
            "tenant_id": 0,
            "name_primary_language": "",
            "name_secondary_language": "",
            "order": 0,
            "status": "Active",
            "stage_master_key": 0,
            "created_by": 0,
            "created_date": "",
            "updated_by": 0,
            "updated_date": ""
        }]
    }     