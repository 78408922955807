import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { DayAgoPipeModule } from 'libs/shared/src/lib/filters/dayago.pipe';
import { TimeLinePipeModule } from 'libs/shared/src/lib/filters/timeline.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'default-date-time',
  templateUrl: './default-date-time.component.html',
  styleUrls: ['./default-date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultDateTimeComponent implements OnInit {
  @Input() columnData = new BehaviorSubject([]);
  @Input() additionData = new BehaviorSubject([]);
  constructor() { }

  ngOnInit(): void {
  }
  dateType(day:any){
    if(day && (day.toLowerCase() == 'today' || day.toLowerCase() == 'yesterday' || day.toLowerCase() == 'tomorrow')) {
      return true
    } else {
      return false
    }
  }
  parseDate(date:any){
    return new Date(date)
  }
  dateToLocalTimeZone(value:any):Date{
    let nextDate = new Date(value);
    nextDate.setMinutes(nextDate.getMinutes() + -1*(new Date().getTimezoneOffset()))
    return  nextDate;
  }
}

@NgModule({
  declarations: [DefaultDateTimeComponent],
  imports: [
    CommonModule,
    DayAgoPipeModule,
    TimeLinePipeModule
  ],
  exports: [DefaultDateTimeComponent]
})
export class DefaultDateTimeComponentModule {
}
