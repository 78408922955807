import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListServiceTypeResponse } from '@fitness-force/models';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, ignoreElements } from 'rxjs/operators';
import { environment } from '../../../../../apps/ff-frontend/src/environments/environment';
import { AppErrors, NotFoundError } from '@fitness-force/errors';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {
  BASE_URL: string = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  GetServiceType(tenantId: number): Observable<ListServiceTypeResponse> {
    let url: string = this.BASE_URL + CONST_URL_SEGEMENTS.SERVICETYPE.GET_SERVICETYPE.replace('{tenant_id}', tenantId.toString());
    return this.http.get(url, this.httpOptions)
      .pipe(
        // should have been mapped 
        map((res: any) => { return res; }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
