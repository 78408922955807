export interface RepeatRule {
    repeat: string;
    interval: number;
    until: Date | null;
    count: number | null;
    weekDays: string[] | null;
    monthDays: number | null;
};

export interface RepeatRuleResponse {
    RRule: string,
    RRuleText: string
};