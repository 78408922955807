import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';
import { InplaceModule } from 'primeng/inplace';
import { InputTextModule } from 'primeng/inputtext';
import { TaskCategoryService } from '@fitness-force/api';
import { ErrorMsgComponentModule } from '@fitness-force/errors';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FactoryHttpLoader } from '../../helpsupport/helpsupport.component';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Component({
  selector: 'inplace-category-edit',
  templateUrl: './inplace-category-edit.component.html',
  styleUrls: ['./inplace-category-edit.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InplaceCategoryEditComponent implements OnInit {
  @Input() additionData = new BehaviorSubject(null);
  @Input() columnData  = new BehaviorSubject(null);
  cData:any;
  aData:any;

  edit = false;
  inplaceForm!: FormGroup;
  @Output() onAction = new EventEmitter();
  categoryForm: any;
  value!: string;
  isFormSubmitting=false;
  constructor(
    private formBuilder: FormBuilder,
    private taskCategoryService: TaskCategoryService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    forkJoin([this.additionData,this.columnData]).subscribe({next:(data:any)=>{
      this.aData=data[0];
      this.cData=data[1];
      this.value = this.aData.primary
      ? this.cData.name_primary_language
      : this.cData.name_secondary_language;
    this.buildForm();
    }});

  }

  buildForm() {
    this.inplaceForm = this.formBuilder.group({
      value: [
        this.aData.primary
          ? this.cData.name_primary_language
          : this.cData.name_secondary_language,
        [Validators.required],
      ],
    });
  }

  updateValue() {
    if (this.inplaceForm.valid) {
      let rawValue = this.inplaceForm.getRawValue();
      this.isFormSubmitting=true;
      if (
        this.aData.primary &&
        rawValue.value != this.cData.name_primary_language
      ) {
        let inputData = {
          name_primary_language: rawValue.value,
          name_secondary_language: this.cData.name_secondary_language
            ? this.cData.name_secondary_language
            : '',
        };
        this.taskCategoryService
          .updateTaskCategory(
            this.cData.tenant_id,
            this.cData.id,
            inputData
          )
          .subscribe({
            next: (res:any) => {
              this.isFormSubmitting=false;
              this.onAction.emit({
                action: 'categoryUpdated',
                columnData: this.columnData,
              });
              this.edit = false;
            },
            error: (error:any) => {
              this.isFormSubmitting=false;
              this.handleError(error);
              this.onAction.emit({ action: 'categoryUpdatedError', error });
            },
          });
      } else if (
        !this.aData.primary &&
        rawValue.value != this.cData.name_secondary_language
      ) {
        let inputData = {
          name_secondary_language: rawValue.value,
          name_primary_language: this.cData.name_primary_language,
        };
        this.taskCategoryService
          .updateTaskCategory(
            this.cData.tenant_id,
            this.cData.id,
            inputData
          )
          .subscribe({
            next: (res:any) => {
              this.isFormSubmitting=false;
              this.onAction.emit({
                action: 'categoryUpdated',
                columnData: this.columnData,
              });
              this.edit = false;
            },
            error: (error:any) => {
              this.isFormSubmitting=false;
              this.handleError(error);
              this.onAction.emit({ action: 'categoryUpdatedError', error });
            },
          });
      }
    } else {
     this.edit = false;
    }
  }

  handleError(error: any) {
    if (
      error.originalError &&
      error.originalError.error &&
      error.originalError.error[0]
    ) {
      let currentError = error.originalError.error[0];
      if (currentError.errorCode == 'duplicatevalue') {
        this.inplaceForm.controls['value'].setErrors({
          duplicate: true,
        });
        this.changeDetectorRef.detectChanges();
      }
    }
  }
  close() {
    this.edit = false;
    this.buildForm();
  }

  onKeyEnter(event:any){
    this.updateValue();
  }
  onKeyEscape(event:any){
   this.close()
  }
}

@NgModule({
  declarations: [InplaceCategoryEditComponent],
  imports: [
    CommonModule,
    InplaceModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    InputTextModule,
    ErrorMsgComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [InplaceCategoryEditComponent],
})
export class InplaceCategoryEditComponentModule {}
