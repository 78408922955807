import { createAction, props } from "@ngrx/store";

export const FETCH_STAFF_LIST = createAction(
    'FETCHSTAFFLIST',
    props<{ staffList: any }>()
);

export const FETCH_STAFF_LIST_ALL = createAction(
    'FETCHSTAFFLISTALL',
    props<{ staffListAll: any }>()
);

export const FETCHEDSTAFFDETAILS = createAction(
    'FETCHEDSTAFFDETAILS',
    props<{ staffDetails: any }>()
);

export const ERRORSTAFFLIST = createAction(
    'ERROR_STAFF_LIST',
    props<{ error: any }>()
);