<ng-container *ngIf="{
  row: columnData | async
} as $">
<div>
<button *ngIf="$.row.opportunity === 'Negotiate'" pButton pRipple type="button" icon="pi pi-check"
  class="p-button-rounded p-button-outlined p-0 w-2rem h-2rem text-sm mx-auto"></button>
<!-- Selected State -->
<button *ngIf="$.row.opportunity === 'Trail'" pButton pRipple type="button" icon="pi pi-check"
  class="p-button-rounded p-0 w-2rem h-2rem text-sm mx-auto"></button>
<!-- Selected State -->
</div>
</ng-container>
