export const CONST_URL_SEGEMENTS = {
    /*APIS THOSE CALLED DURING APPLICATION INITIATION.*/
    PUBLIC_APIS: [
        'i18n/.+',
        'companies/.+',
        'token',
        'recovery/password/lookup'
    ],

    BLOG_API: 'https://www.fitnessforce.com/blog/feed/',

    AUTH: {
        LOGIN_TOKEN: '/token',
        PASSWORD_LOOKUP: '/recovery/password/lookup',
        UNIQUE_IDENTIFIER: '/recovery/password/',
        PASSWORD_RESET: '/recovery/password/reset/'
    },

    INIT: {
        GET_LANG_INFO: '/get_info_lang',
        GET_COMPANY_INFO: '/company_info',
        GET_COMPANY_SUBDOMAIN: '/companies/'
    },

    CLAIM: {
        GET_CLAIM_TEMPLATES: '/claim_templates'
    },

    COUNTRY: {
        GET_COUNTRY: '/common/countries'
    },

    STATE: {
        GET_STATE: '/common/countries/{countrycode}/states'
    },

    CITY: {
        GET_CITY: '/common/countries/{countrycode}/states/{statecode}/cities'
    },

    SEARCH: {
        POST_SEARCH: '/search'
    },

    // Tenant apis
    TENANT: {
        GET_TENANT_LIST: '/tenants',
        GET_TENANTBYID: '/tenants'
    },

    STAFF: {
        GET_STAFF_LIST: '/staff',
        GET_STAFF_BYID: '/staff/{staff_id}',
        POST_CHANGE_PASSWORD: '/staff/{staff_id}/change_password',
        POST_SUSPEND: '/staff/{staff_id}/mark_as_suspend',
        POST_TERMINATE: '/staff/{staff_id}/mark_as_terminate',
        POST_CHANGEOWNER: '/staff/{staff_id}/change_owner',
        POST_CHANGEREPORTINGMANAGER: '/staff/{staff_id}/change_reporting_manager',
        POST_CHANGECENTERACCESS: '/staff/{staff_id}/change_center_access',
        POST_ACTIVE: '/staff/{staff_id}/mark_as_active',
        POST_STAFF: '/staff',
        PUT_STAFF: '/staff/{staff_id}'
    },
    SMS: {
        SMS_TEMPLATE_MASTER: '/sms_template_master',
        SEND_SMS: '/send_sms'
    },
    USERS: {
        GET_USER_LIST: '/userList',
        STAFFS: '/staff/'
    },

    CLIENT: {
        POST_CLIENTS: '/clients',
        GET_CLIENTS: '/clients',
        ADD_TO_BLACKLIST: '/add_to_blacklist',
        REMOVE_FROM_BLACKLIST: '/remove_from_blacklist',
        CHANGE_CLIENT_REP: '/change_client_rep',
        SUBSCRIPTIONS: '/subscriptions',
        SUBSCRIPTION_SINGUPS: '/subscription_signups',
        INVOICES: '/invoices',
        PAYMENTS: '/payments',
        PAYMENT_METHODS: '/payment_methods',
        PAYMENT_TIMELINE: '/payment_timeline',
        CLASS_APPT_TAGS: "/class_appt_tags",
        SERVICE_CAT: "/service_categories",
        IRREGULAR_CLIENTS: "/irregular"
    },

    SERVICETYPE: {
        GET_SERVICETYPE: '/tenants/{tenant_id}/service_categories'
    },

    EXPECTED: {
        GET_EXPECTED_LIST: '/expected'
    },

    BALANCE_OVERDUE: {
        GET_BALANCEOVERDUE_LIST: '/balanceoverdue'
    },

    BIRTHDAY: {
        GET_BIRTHDAY_LIST: '/clients/birthdays'
    },

    COLLECTION: {
        GET_COLLECTION_LIST: '/collection'
    },

    FOLLOWUP: {
        GET_FOLLOWUP_LIST: '/followup'
    },

    IRREGULARMEMBER: {
        GET_IRREGULARMEMBER_LIST: '/clients/irregular'
    },

    ONLINETRANSACTION: {
        GET_ONLINETRANSACTION_LIST: '/onlinetransaction'
    },

    RENEWALDUE: {
        GET_RENEWALDUE_LIST: '/renewaldue'
    },

    OPPORTUNITY: {
        OPPORTUNITY: '/opportunities'
    },

    OPPORTUNITYSTAGEMASTER: {
        OPPSTAGEMST: '/opp_stage_mst',

    },

    BOARDS: {
        TITLE: 'boards'
    },

    TASK: {
        GET_TASK: '/tenants/{tenant_id}/tasks',
        POST_TASK_BY_CLIENT: '/tenants/{tenant_id}/clients/tasks',
        POST_RECURRING_TASK_BY_CLIENT: '/tenants/{tenant_id}/clients/recurring_tasks',
        POST_TASK_BY_OPPORTUNITY: '/tenants/{tenant_id}/opportunities/tasks',
        POST_RECURRING_TASK_BY_OPPORTUNITY: '/tenants/{tenant_id}/opportunities/recurring_tasks',
        CREATE_CALL_OUTCOME: '/call_outcome',
        CREATE_TOUR_OUTCOME: '/tour_outcome',
        CREATE_TOUR_OUTCOME_REQUEST: '',
        CREATE_CALL_OUTCOME_REQUEST: ''
    },

    TASKOUTCOME: {
        POST_CALL_OUTCOME: '/tenants/{tenant_id}/tasks/{tsk_id}/call_outcome',
        POST_TOUR_OUTCOME: '/tenants/{tenant_id}/tasks/{tsk_id}/tour_outcome'
    },

    TASKCATEGORY: {
        GETTASKCATEGORY: '/tenants/{tenant_id}/task_catagories',
        UPDATETASKCATEGORY: '/tenants/{tenant_id}/task_catagories/{tsk_category_id}',
        MARKACTIVE: '/tenants/{tenant_id}/task_catagories/{tsk_category_id}/mark_as_active',
        MARKINACTIVE: '/tenants/{tenant_id}/task_catagories/{tsk_category_id}/mark_as_inactive',
    },


    ROOM: {
        ROOM_LIST: "/tenants/{tenant_id}/rooms"
    },

    SUBSCRIPTIONSIGNUP: {
        GET_COMPANY: '/subscription/signup/{sigup_sts_uuid}/company_details',
        GET_TENANT: '/subscription/signup/{sigup_sts_uuid}/tenants',
        GET_ORDERSUMMARY: '/subscription/signup/{sigup_sts_uuid}/get_order_summary',
        GET_ORDERDETAILS: '/subscription/signup/{sigup_sts_uuid}/get_order_subscription_detail',
        GET_ORDER_SUBSCRIPTION_STATUS: '/subscription/signup/{sigup_sts_uuid}/get_order_subscription_status',
        GET_ORDERSUMMARYBILLINGOCCURENCE: '/subscription/signup/{sigup_sts_uuid}/get_order_summary_billing_occurrence',
        POST_PROCEEDTOPAYMENT: '/subscription/signup/{sigup_sts_uuid}/proceed_to_payment',
        POST_SAVETOKEN: '/subscription/signup/{sigup_sts_uuid}/save_token',
        POST_SAVEEXTERNALTRANSACTIONID: '/subscription/signup/{sigup_sts_uuid}/save_external_transaction_id',
        PUT_CLIENTINFO: '/subscription/signup/{sigup_sts_uuid}/client_info',
        GET_WAIVERS: '/subscription/signup/{sigup_sts_uuid}/waivers',
        POST_WAIVERS: '/subscription/signup/{sigup_sts_uuid}/waivers',
        GET_CLIENT_MANDATORYFIELD: '/subscription/signup/{sigup_sts_uuid}/clients/mandatory_client_fields',
        GET_PAYMENTMETHODS: '/subscription/signup/{sigup_sts_uuid}/payment_methods',
    },


    SCHEDULING: {
        APPOINTMENT: {
            GET_APPOINTMENTS: '/tenants/{tenant_id}/appointments',
            POST_APPOINTMENTS: '/tenants/{tenant_id}/appointments',
            POST_RECURRING_APPOINTMENTS: '/tenants/{tenant_id}/recurring_appt',
            GET_APPOINTMENT: '/tenants/{tenant_id}/appointments/{appt_id}',
            PUT_APPOINTMENT: '/tenants/{tenant_id}/appointments/{appt_id}',
            PUT_RECURRING_APPOINTMENT: '/tenants/{tenant_id}/recurring_appt/{appt_recurring_template_id}',
            SUBSTITUTE_COACH: '/tenants/{tenant_id}/appointments/{appt_id}/substitute_coach',
            SUBSTITUTE_COACH_RECURRING: '/tenants/{tenant_id}/recurring_appt/{appt_recurring_template_id}/substitute_coach',
            RESCHEDULE_APPOINTMENT: '/tenants/{tenant_id}/appointments/{appt_id}/reschedule_appointment',
            RESCHEDULE_RECURRING_APPOINTMENT: '/tenants/{tenant_id}/recurring_appt/{appt_recurring_template_id}/reschedule_appt',
            MARK_CLIENT_CHECKEDIN: '/tenants/{tenant_id}/appointments/{appt_id}/mark_client_checkedin',
            MARK_CLIENT_NOSHOW: '/tenants/{tenant_id}/appointments/{appt_id}/mark_client_noshow',
            CANCEL_APPOINTMENT: '/tenants/{tenant_id}/appointments/{appt_id}/cancel_appointment',
        },
        APPOINTMENT_TYPE: {
            GET_APPOINTMENT_TYPES: '/tenants/{tenant_id}/appointment_types'
        },
        APPOINTMENT_SUB_TYPE: {
            GET_APPOINTMENT_SUB_TYPES: '/tenants/{tenant_id}/appointment_types/{appt_type_id}/appt_subtypes'
        },
        CLASS_TYPE: {
            GET_CLASS_TYPES: '/tenants/{tenant_id}/class_types'
        },
        CLASS:{
            GET_CLASSES:'/tenants/{tenant_id}/classes',
            GET_CLASS:'/tenants/{tenant_id}/classes/{class_id}',
            GET_RECURRING_CLASS_TEMPLATE:'/tenants/{tenant_id}/recurring_classes',
            GET_RECURRING_CLASSES:'/tenants/{tenant_id}/recurring_classes/{class_recurring_template_id}/classes',
            POST_CLASSES:'/tenants/{tenant_id}/classes',
            POST_RECURRING_CLASSES:'/tenants/{tenant_id}/recurring_classes',
            PUT_CLASS:'/tenants/{tenant_id}/classes/{class_id}',
            PUT_RECURRING_CLASS:'/tenants/{tenant_id}/recurring_classes/{class_recurring_template_id}',
            SUBSTITUTE_COACH:'/tenants/{tenant_id}/classes/{class_id}/substitute_coach',
            SUBSTITUTE_COACH_RECURRING:'/tenants/{tenant_id}/recurring_classes/{class_recurring_template_id}/substitute_coach',
            RESCHEDULE_CLASS:'/tenants/{tenant_id}/classes/{class_id}/reschedule_class',
            RESCHEDULE_RECURRING_CLASS:'/tenants/{tenant_id}/recurring_classes/{class_recurring_template_id}/reschedule_class',
            CANCEL_CLASS:'/tenants/{tenant_id}/classes/{class_id}/cancel_class',
            DELETE_CLASS:'/tenants/{tenant_id}/classes/{class_id}',
            COMPLETE_CLASS:'/tenants/{tenant_id}/classes/{class_id}/mark_as_completed',
        },
        CLASS_BOOKING:{
            BOOK_CLIENTS_TO_CLASS:'/tenants/{tenant_id}/classes/{class_id}/book_client_to_class',
            REMOVE_CLIENTS_FROM_CLASS:'/tenants/{tenant_id}/classes/{class_id}/remove_client_from_class',
            MARK_CLIENT_NO_SHOW:'/tenants/{tenant_id}/classes/{class_id}/mark_client_noshow',
            MARK_CLIENT_CHECKEDIN:'/tenants/{tenant_id}/classes/{class_id}/mark_client_checkedin',
        }
    },

    INVOICES: {
        GET_INVOICES: '/clients/{client_id}/invoices',
        GET_INVOICE_PDF: '/clients/{client_id}/invoices/{invoice_id}/pdf_invoice',
        VOID_INVOICE: '/tenants/{tenant_id}/clients/{client_id}/invoices/{invoice_id}/void',
        SEND_EMAIL: '/clients/{client_id}/invoices/{invoice_id}/send_email',
        SALES_RETURN: '/tenants/{tenant_id}/clients/{client_id}/invoices/{invoice_id}/sales_return',
        
    },
    PAYMENTS:{
        GET_PAYMENTS: '/clients/{client_id}/payments',
        GET_PAYMENT_PDF: '/clients/{client_id}/payments/{pymt_id}/pdf_payment',
        VOID_PAYMENT: '/tenants/{tenant_id}/clients/{client_id}/payments/{pymt_id}/void',
        SEND_EMAIL: '/clients/{client_id}/payments/{pymt_id}/send_email',
    }
};
