import { Store } from '@ngrx/store';
import { AuthSevices } from 'libs/api/src/lib/auth.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CLAIMS_INFO } from 'apps/ff-frontend/src/app/state/app.state';

@Injectable({
    providedIn: 'root'
  })
export class ClaimsguardService implements CanActivate{
    getSubDomain = '';
    isResponse = '';
    constructor(private authservice: AuthSevices, private router: Router, private store:Store) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authservice.isLoginedIn) {
            return new Promise((resolve, reject)=>{
                this.store.select(CLAIMS_INFO).subscribe(
                    {
                        next: (claimsData:any)=>{
                            console.log('all claims data', claimsData, state, route, route.data, claimsData[route.data.id])
                            if(claimsData[route.data.id]){
                                resolve(claimsData[route.data.id])
                            } else {
                                this.router.navigate(['/'], {queryParams: { returnUrl: state.url }});
                                resolve(false)
                            }
                        },
                        error: (err)=>{
                            resolve(false)
                        }
                    
                    }
                )
            })
           
        } else{
            return false;
        }
    }
}