<!-- Book appointment Modal  -->
<p-dialog [(visible)]="showBookAppointment" [resizable]="false" appendTo="body" [modal]="true"
[breakpoints]="{'991px': '100vw'}" [style]="{width: '720px'}" styleClass="no-header-modal no-overflow-content">
<ng-template pTemplate="header" styleClass="hidden"></ng-template>
<ng-template pTemplate="content" styleClass="p-0">

    <!-- Header -->
    <div class="p-3 md:px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
      <h2 class="mb-0 text-base md:text-lg font-normal">{{'SCHEDULE.CREATEAPPOINTMENT.BOOK_APPOINTMENT' | translate}}</h2>
      <div class="flex align-items-center justify-content-end">
        <div class="hidden md:flex align-items-center text-sm md:mr-5 mt-2 md:mt-0" *ngIf="currentTenant">
          <i class="pi pi-map-marker text-gray-600 mr-2"></i>
          <span>{{currentTenant.name_primary_language || currentTenant.name_secondary_language }}</span>
        </div>
      <div class="pi pi-times text-gray-500 cursor-pointer" (click)="ClosePopup()"></div>
    </div>

    </div>
    <!-- Header -->

     <!-- Modal Content -->
   <form [formGroup]="CreateAppointmentForm" (ngSubmit)="CreateAppointment(CreateAppointmentForm)">
     
      <div class="surface-100 overflow-y-auto h-30rem">
         <div class="px-4 p-3">

            <!-- Form Group -->
            <div class="mb-3">
               <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATEAPPOINTMENT.ADD_MEMBER' | translate}}</label>
               <div class="relative z-2 -mt-2">
                  <input type="hidden" formControlName="client_id" />
                  <div class="p-input-icon-right w-full" pStyleClass=".search-dropdown" [hideOnOutsideClick]="true">
                     <div class="-mx-2">
                     <mini-search class="p-input-icon-right w-full" [readonly]="false" (clients)="setClient($event)"> </mini-search>
                  </div>
                     <app-error-msg
                     *ngIf="SelectedClientFormControl.touched && SelectedClientFormControl.invalid"
                     [errors]="SelectedClientFormControl.errors"
                     [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_CLIENT' | translate">
                     </app-error-msg>
                  </div>
                 
                  <!-- Input Field -->
               </div>
            </div>
            <!-- Form Group -->
            
            <!-- Form Group -->
            <div class="mb-3">
               <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATEAPPOINTMENT.SELECT_PURPOSE' | translate}}</label>
               <!-- Service Icon -->
               <div class="flex align-items-center flex-wrap" *ngIf="(appointmentTypeList$ | async).length > 0; else noApptShow">
                  <ng-container *ngFor="let apptType of (appointmentTypeList$ | async); let i=index">
                     <button  pButton pRipple class="py-1 my-1 h-2rem text-sm border-2 mr-2" *ngIf="i< apptTypeToShow" (click)="SelectAppointmentType(apptType)"
                     [ngClass]="selectedApptType! && selectedApptType == apptType ? getSelectedColor(apptType) : 'p-button-secondary bg-white border-gray-400 text-gray-700'"
                      type="button">
                     <span class="ml-2">{{apptType.name_primary_language}}</span>
                     </button>
                  </ng-container>
                  <div *ngIf="apptTypeToShow < appointmentTypeList$.value.length" class="cursor-pointer text-xs font-bold mr-3">...{{appointmentTypeList$.value.length - apptTypeToShow}} {{'SCHEDULE.CREATEAPPOINTMENT.MORE' | translate}}</div>
                  <div *ngIf="apptTypeToShow < appointmentTypeList$.value.length" class="cursor-pointer text-sm text-primary flex align-items-center white-space-nowrap"  (click)="apptTypeToShow = apptTypeToShow+5">{{'SCHEDULE.CREATEAPPOINTMENT.LOAD_MORE' | translate}} <i
                     class="pi pi-arrow-down ml-1 text-gray-500 text-sm"></i></div>
               </div>
               <ng-template #noApptShow>
                  <div class="-mt-2" >
                     <p-messages severity="warn">
                       <ng-template pTemplate>
                        <div class="ml-2 line-height-3 text-xs">{{'SCHEDULE.CREATEAPPOINTMENT.NO_APPT_INFO' | translate}}
                           <strong class="underline cursor-pointer" (click)="GoToAppointment()">{{'SCHEDULE.CREATEAPPOINTMENT.ADD_APPT' | translate}}
                           </strong>
                           &nbsp; {{'SCHEDULE.CREATEAPPOINTMENT.NO_APPT_INFO1' | translate}}
                         </div>
                       </ng-template>
                     </p-messages>
                   </div>  
               </ng-template>
              
               <!-- Service Icon -->
            </div>
            <!-- Form Group -->
            <!-- Form Group -->
            <div class="mb-3" *ngIf="showApptSubType | async">
               <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATEAPPOINTMENT.SELECT_SUB_PURPOSE' | translate}}</label>
               <div class="p-buttonset test">
                  <ng-container *ngFor="let subType of (appointmentSubTypeList$ | async); let i=index">
                     <!-- <button  pButton pRipple class="text-sm border-right-1 border-white" type="button" (click)="selectedApptSubType = subType"> -->
                        <button pButton pRipple [ngClass]="selectedApptSubType! && selectedApptSubType == subType ? 'text-sm border-right-1 border-white':'p-button-secondary text-sm border-right-1 border-white border-1 hover:text-white'" type="button" (click)="selectedApptSubType = subType">
                        <span class="ml-2">{{subType.name_primary_language}}</span>
                     </button>
                  </ng-container>
               </div>
            </div>
            <!-- Form Group -->
            <!-- Form Group -->
            <div class="mb-0">
               <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{'SCHEDULE.CREATEAPPOINTMENT.APPT_TYPE' | translate}}</label>
               <div class="p-buttonset">
                  <button pButton pRipple icon="pi pi-users" label="{{'SCHEDULE.CREATEAPPOINTMENT.PHYSICAL' | translate}}" type="button" class="text-sm border-right-1 border-white" (click)="SetIsVirtual(false)"  [ngClass]="isVirtal == false ? 'border-white' : 'p-button-secondary' "></button>
                  <button pButton pRipple icon="pi pi-video" label="{{'SCHEDULE.CREATEAPPOINTMENT.VIRTUAL' | translate}}" type="button" class="text-sm border-right-1 border-white" (click)="SetIsVirtual(true)"  [ngClass]="isVirtal ? 'border-white' : 'p-button-secondary' "></button>
               </div>
            </div>
            <!-- Form Group -->
         </div>
         <p-divider styleClass="my-0"></p-divider>
         <div class="p-3">
            <!-- Form Group -->
            <div class="md:flex flex-wrap">
               <div class="mb-3 relative pl-5 md:mr-3">
                  <i class="pi pi-calendar-plus absolute left-0 mt-2 pt-1 text-gray-600"></i>
                  <p-calendar [showIcon]="false" formControlName="startDate" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.SELECTDATE' | translate}}"
                  [minDate]="minimumDate" styleClass="w-full" inputStyleClass="text-sm"></p-calendar>
                  <app-error-msg
                  *ngIf="StartDateFormControl.touched && StartDateFormControl.invalid"
                  [errors]="StartDateFormControl.errors"
                  [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_STARTDATE' | translate">
                  </app-error-msg>
               </div>
               <div class="mb-3 relative pl-5 md:mr-3">
                  <i class="pi pi-clock absolute left-0 mt-2 pt-1 text-gray-600"></i>
                  <p-calendar [showIcon]="false" hourFormat="12" [timeOnly]="true" inputId="timeonly" formControlName="startTime" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.SELECTTIME' | translate}}" styleClass="w-full" StyleClass="text-sm" inputStyleClass="text-sm"></p-calendar>
                  <app-error-msg
                  *ngIf="StartTimeFormControl.touched && StartTimeFormControl.invalid"
                  [errors]="StartTimeFormControl.errors"
                  [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_STARTTIME' | translate">
                  </app-error-msg>
               </div>

               
            <div class="w-full pl-5 mb-3" [ngClass]="!RRuleObject.RRuleTex ? 'md:w-9 ' : '' ">
                  <!-- Input Field -->
               <div *ngIf="!RRuleObject.RRuleText" class="p-input-icon-right w-full text-sm" (click)="showRRule=true">
                  <div class="p-inputtext p-component p-element cursor-pointer bg-blue-50 md:w-15rem">{{'SCHEDULE.CREATEAPPOINTMENT.REPEAT' | translate}}</div>
               </div>
               <div *ngIf="RRuleObject.RRuleText" class="p-input-icon-right w-full text-sm cursor-pointer">
                  <i class="pi pi-times text-gray-500" (click)="showRRule=false; RRuleObject.RRule =undefined;RRuleObject.RRuleText =undefined"></i>
                  <div class="p-inputtext p-component p-element w-full" (click)="showRRule=true">{{RRuleObject.RRuleText}}</div>
               </div>
               <!-- Input Field -->
            </div>
         </div>
         <!-- Form Group -->
         <!-- Form Group -->
         <div class="mb-3 relative pl-5">
            <i class="pi pi-user-plus absolute left-0 mt-2 pt-1 text-gray-600"></i>
            <input type="hidden" formControlName="selectedTrainer" />
            <staff-selector [formGroup]="CreateAppointmentForm" [controlName]="SelectedAssignedFormControl" 
             [styleClass]="'w-full text-sm p-inputtext py-0 px-0 align-items-center'" [placeHolder]="'SCHEDULE.CREATEAPPOINTMENT.SELECTWITHDOTS' | translate "
             [filterstaff]="filterStaffIds">
            </staff-selector>
            <app-error-msg
               *ngIf="SelectedAssignedFormControl.touched && SelectedAssignedFormControl.invalid"
               [errors]="SelectedAssignedFormControl.errors"
               [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_TRAINER' | translate">
             </app-error-msg>

         </div>
         <!-- Form Group -->
         <!-- Form Group -->
         <div *ngIf="!isVirtal" class="mb-3 relative pl-5">
            <i class="pi pi-building absolute left-0 mt-2 pt-1 text-gray-600"></i>
            <p-dropdown [options]="roomList$" styleClass="text-sm w-full"  [showClear]="GetSelectedRoom ? true: false" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.CHOOSEROOM' | translate}}" 
             formControlName="selectedRoom" optionLabel="name_primary_language" filterBy="name_primary_language">
            <ng-template pTemplate="selectedItem">
               <div *ngIf="GetSelectedRoom" class="text-sm">{{GetSelectedRoom.name_primary_language}}</div>
            </ng-template>
            <ng-template let-room pTemplate="item">
               <div class="text-sm">{{room.name_primary_language}} </div>
            </ng-template>
            </p-dropdown>
            <app-error-msg
            *ngIf="SelectedRoomFormControl.touched && SelectedRoomFormControl.invalid"
            [errors]="SelectedRoomFormControl.errors"
            [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_ROOM' | translate">
            </app-error-msg>
         </div>
         <!-- Form Group -->
         <!-- Form Group -->
         <div *ngIf="isVirtal" class="mb-3 relative pl-5">
            <i class="pi pi-paperclip absolute left-0 mt-2 pt-1 text-gray-600"></i>
            <input type="text" class="text-sm w-full" pInputText formControlName="virtualUrl" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.VIRTUALURL' | translate}}" />
            <app-error-msg
            *ngIf="VirtualUrlFormControl.touched && VirtualUrlFormControl.invalid"
            [errors]="VirtualUrlFormControl.errors"
            [fieldLabel]="'SCHEDULE.CREATEAPPOINTMENT.LBL_VIRTUALURL' | translate">
            </app-error-msg>
         </div>
         <!-- Form Group -->
         <!-- Form Group -->
         <div class="mb-0 relative pl-5">
            <i class="pi pi-align-left absolute left-0 mt-2 pt-1 text-gray-600"></i>
            <textarea pInputTextarea class="text-sm w-full h-4rem" formControlName="comments" placeholder="{{'SCHEDULE.CREATEAPPOINTMENT.COMMENT' | translate}}"></textarea>
         </div>
         <!-- Form Group -->
      </div>
      </div>
 
      <div class="flex align-items-center justify-content-between border-top-1 border-gray-300 p-3">
         <div class="w-4">
            <p-button label="{{'common.BUTTONS.CANCEL-BUTTON-TEXT' | translate}}" styleClass="p-button-link text-sm" (click)="ClosePopup()">
            </p-button>
         </div>
         <div class="w-8 text-right">
            <button class="text-sm" pButton pRipple [label]="'SCHEDULE.CREATEAPPOINTMENT.CREATE_APPT' | translate" type="submit" [disabled]="disableSaveBtn"></button>
         </div>
      </div>
   </form>
      <!-- Modal Content -->
</ng-template>
</p-dialog>
<!-- Book appointment Modal  -->


<rrule [visible]="showRRule" [showNeverStop]="true" [minimumDate]="minimumDate" [rRuleObj]="RRuleObject" (hideRrule)="HideRRule($event)"
(emitRRule)="FetchRRuleData($event)">
</rrule>

<app-errors *ngIf="showErrorPopup$ | async as showPopup" [error]="error" [visible]="showPopup" [errorData]="errorMessages"
  (hideErrorPopup)="HideErrorPopup($event)">
</app-errors>

<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>