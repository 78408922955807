import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotFoundError } from '@fitness-force/errors';
import {
  CreateReportFilterRequest,
  CreateResponse,
  GetReportRequest,
  LongReportRequest,
  ReportFilter,
  ReportFilterResponse,
  ReportResponse,
  ScheduleFilterDeleteRequest,
  ScheduleReportFilterRequest,
} from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { AppErrors } from 'libs/errors/src/lib/app.errors';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportFilterService {
  constructor(private http: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetReportByKey(getReportRequest: GetReportRequest): Observable<ReportResponse> {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${getReportRequest.tenant_id}/reports/${getReportRequest.report_key}
        ?rpt_fltr_relative_date_range=${getReportRequest.rpt_fltr_relative_date_range}&rpt_fltr_payload=${getReportRequest.rpt_fltr_payload}`,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  GetReportFiltersByKey(tenantId: number,reportKey: string): Observable<ReportFilter[]> {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/reports/${reportKey}/filter`,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res.items;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  CreateReportFilter(tenantId: number,reportKey: string,createReportFilter: CreateReportFilterRequest): Observable<CreateResponse>
   {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/reports/${reportKey}/filter`,
        createReportFilter,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  UpdateReportFilter(tenantId: number,reportKey: string,filterId: number,updateReportFilter: CreateReportFilterRequest): Observable<CreateResponse>
   {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/reports/${reportKey}/filters/${filterId}`,
        updateReportFilter,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  DeleteReportFilter(tenantId: number,reportKey: string,reportId: number): Observable<CreateResponse> 
  {
    return this.http
      .delete(
        `${environment.BASE_URL}/tenants/${tenantId}/reports/${reportKey}/filters/${reportId}`,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  ScheduleReportFilter(tenantId: number,reportKey: string,reportFilterId: number, scheduleReportPaylkoad: ScheduleReportFilterRequest): Observable<CreateResponse> 
  {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/reports/${reportKey}/filters/${reportFilterId}/schedules`,scheduleReportPaylkoad,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  CreateLongReport(tenantId: number,reportKey: string, longerReportRequest: LongReportRequest): Observable<CreateResponse> 
  {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/reports/${reportKey}/long_report`,longerReportRequest,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  DeleteScheduleFilter(scheduleFilterDeleteRequest: ScheduleFilterDeleteRequest): Observable<CreateResponse> 
  {
    return this.http
      .delete(
        `${environment.BASE_URL}/tenants/${scheduleFilterDeleteRequest.tenant_id}/reports/${scheduleFilterDeleteRequest.report_key}
        /filters/${scheduleFilterDeleteRequest.filter_id}/schedules/${scheduleFilterDeleteRequest.schedule_id}`,
        {
          headers: this.httpOptions.headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

}
