import { Store } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { CompanyInfoStoreInterface, LANGUAGE } from '@fitness-force/models';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { Observable } from 'rxjs';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FactoryHttpLoader } from '@fitness-force/shared';
import { HttpClient } from '@angular/common/http';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';

@Component({
  selector: 'app-lang-dropdown',
  templateUrl: './langdropdown.component.html',
  styleUrls: ['./langdropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangdropdownComponent implements OnInit {
  @Input('Lang') Lang: CompanyInfoStoreInterface | any;
  @Output('EmitLang') EmitLang = new EventEmitter<any>();

  langFromGroup: FormGroup | any;
  constructor(public translate: TranslateService, private store: Store) { }

  ngOnInit(): void {
    this.onComponentInit()
  }
  public companyInfoDetail$!: Observable<any>;
  onComponentInit() {
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO)
    this.langFromGroup = new FormGroup({
      selectedLang: new FormControl('')
    })
    this.langFromGroup.patchValue({ 'selectedLang': this.Lang.selectedLang })
    this.companyInfoDetail$.subscribe((responseData)=>{
    
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    })
  }

  get SelectedLang() {
    return this.langFromGroup.get('selectedLang').value
  }

  get parseSupportedLang(): Array<LANGUAGE> {
    let supportedLangBuffer: Array<LANGUAGE> = [];
    const supportedLang = this.Lang.compDetails.supported_languages.map((l: any) => l.trim().toLowerCase())
    supportedLangBuffer = CONSTANTS_DATA.ALL_LANGUAGES.filter(lang => {
      const languageOnlower = lang.LANG_ABBRE.toLowerCase();
      const langCodeOnlower = lang.LANG_CODE.toLowerCase();
      if (supportedLang && supportedLang.length > 0) {
        return supportedLang && (supportedLang.includes(languageOnlower) || supportedLang.includes(langCodeOnlower))
      }
      else if (this.Lang.compDetails.primary_language == languageOnlower) {
        return lang;
      }
      else {
        return { COUNTRY: 'us', LANG_CODE: 'en', LANG_ABBRE: 'ENGLISH' };
      }
    });
    return supportedLangBuffer;
  }

  onSelectLang(e: LANGUAGE) {
    this.EmitLang.emit(e)
  }
}

@NgModule({
  declarations: [LangdropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [LangdropdownComponent]
})
export class LangdropdownComponentModule {
}
