import { Router, RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { FactoryHttpLoader } from '../helpsupport/helpsupport.component';

@Component({
  selector: 'auto-appointment-create',
  templateUrl: './auto-appointment-create.component.html',
  styleUrls: ['./auto-appointment-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoAppointmentCreateComponent implements OnInit {

  items:any[];
  classes: any[];

  serviceCategories: any[];
  selectedServiceCategory: { name: string; } | undefined;

  dropInPlans: any[];
  selectedDropInPlan: { name: string; } | undefined;

  responsiveOptions: any[];

  amountVal: number | undefined;

  constructor() {
    this.items = [
      {label: 'Admin'},
      {label: 'Appointment Master'},
      {label: 'Appointment List'},
  ];

  this.responsiveOptions = [
    {
        breakpoint: '1024px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
    }
];

  this.serviceCategories = [
    { name: 'Service Category 1' },
    { name: 'Service Category 2' },
    { name: 'Service Category 3' },
    { name: 'Service Category 4' },
    { name: 'Service Category 5' },
  ];

  this.dropInPlans = [
    { name: 'Plan 1' },
    { name: 'Plan 2' },
    { name: 'Plan 3' },
    { name: 'Plan 4' },
    { name: 'Plan 5' },
  ];

  this.classes = [
    {
      color:"green-400",
      bg_color: "bg-green-50",
      title: "Personal Training",
      behaviour: "Paid",
      behaviour_icon: "pi pi-dollar",
      note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      successfully_added: true
    },
    {
      color:"orange-500",
      bg_color: "bg-orange-50",
      title: "P.T. Trail",
      behaviour: "Free",
      behaviour_icon: "pi pi-wallet",
      note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      successfully_added: false
    },
    {
      color:"blue-300",
      bg_color: "bg-blue-50",
      title: "Massage",
      behaviour: "Paid",
      behaviour_icon: "pi pi-dollar",
      note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      successfully_added: false
    },
    {
      color:"pink-700",
      bg_color: "bg-red-50",
      title: "Diet Counselling",
      behaviour: "Paid",
      behaviour_icon: "pi pi-dollar",
      note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      successfully_added: false
    },

  ];

  }

  ngOnInit(): void {
  }


}

@NgModule({
  declarations: [AutoAppointmentCreateComponent],

    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      TableModule,
      ButtonModule,
      InputTextModule,
      RippleModule,
      DividerModule,
      OverlayPanelModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: FactoryHttpLoader,
          deps: [HttpClient],
        },
      }),
      CarouselModule,
      DropdownModule,
      RouterModule
    ],
  exports: [AutoAppointmentCreateComponent]
})
export class AutoAppointmentCreateComponentModule {
}
