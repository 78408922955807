import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {  Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { ListPaymentModeMastersResponse } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { Observable, throwError, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class PaymentModeService {
  constructor(private http: HttpClient, private router: Router) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  createPaymentMode(tenantId: number, createPaymentMode: any) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master`,
        createPaymentMode,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getPaymentMode(tenantId: number,allParams:Array<string>=[]) {

    let parsedParams = new HttpParams()
   if(allParams.length > 0){

    allParams.forEach((p: any) => {

      parsedParams = parsedParams.append(p.name, p.value);
    });
   }
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master`,
        {headers: this.httpOptions.headers , params: parsedParams}
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updatePaymentMode(tenantId: number, paymentModId: number, data: any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master/${paymentModId}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markActive(tenantId: number, paymentModId: number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master/${paymentModId}/mark_as_active`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  delete(tenantId: number, paymentModId: number) {
    return this.http
      .delete(
        `${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master/${paymentModId}`,

        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markInActive(tenantId: number, paymentModId: number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master/${paymentModId}/mark_as_inactive`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getSinglePaymentMode(tenantId: number, paymentModId: number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master/${paymentModId}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  listPaymentMode(tenantId: number): Observable<ListPaymentModeMastersResponse> {
    return this.http.get(`${environment.BASE_URL}/tenants/${tenantId}/payment_mode_master`, this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
