<div [formGroup]="formGroup">
    <p-dropdown [options]="priority" optionLabel="name" placeholder="{{placeHolder}}" styleClass="{{styleClass}}"
        [formControl]="controlName">
        <ng-template pTemplate="Priority">
            <div class="text-sm" *ngIf="Priority">{{Priority.name}}</div>
        </ng-template>
        <ng-template let-priority pTemplate="item">
            <div class="text-sm">{{priority.name}}</div>
        </ng-template>
    </p-dropdown>
</div>