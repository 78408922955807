<p-dialog [(visible)]="visibility" [resizable]="false" appendTo="body" [modal]="true" [closeOnEscape]="true" (onHide)="closeDialogEvent()"
[breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '32vw'}" styleClass="test">
<ng-template pTemplate="header">
  <div class="flex align-items-center">
    <span class="font-semibold text-base text-900">Support</span>
  </div>
</ng-template>
<ng-template pTemplate="content">
  

<ng-container *ngIf="companyInfoDetail$ | async as companyInfoDetail">
  <div class="px-3 pt-2">
    <h4 class="text-xs mb-3 text-gray-700">YOUR ACCOUNT MANAGER</h4>
    <div class="flex align-items-center font-bold">
        <p-avatar label="T"></p-avatar>
      <h4 class="text-base mb-0 ml-3">{{companyInfoDetail.compDetails.account_manager.name}}</h4>
    </div>
    <div class="border-bottom-2 border-top-2 surface-border w-100 text-h3 mt-3 overflow-hidden">
      <div class="md:flex align-items-center">
        <div class="flex align-items-center mr-4 py-3">
          <i class="pi pi-phone mr-2 opacity-50"></i>
          {{companyInfoDetail.compDetails.account_manager.phone_number}}
        </div>
        <div class="flex align-items-center md:py-3 pb-3 pt-2">
          <i class="pi pi-envelope mr-2 opacity-50"></i>
          <a href="mailto:{{companyInfoDetail.compDetails.account_manager.email}}"
            class="text-primary no-underline">{{companyInfoDetail.compDetails.account_manager.email}}</a>
        </div>
      </div>
    </div>
  
  
    <div class="surface-50 px-5 py-4 -mx-5 mt-3">
  
      <!-- Got a Question -->
      <div class="flex">
        <i class="pi pi-question opacity-50 mr-2"></i>
        <div>
          <h3 class="text-base font-bold mb-0">Got a Question?</h3>
          <p class="text-gray-500 text-sm my-2">Get answers from FitnessForce knowledge base.</p>
          <a href="https://support.fitnessforce.com/portal/en/kb" class="text-primary font-bold text-base no-underline">Visit our knowledge base.</a>
        </div>
      </div>
      <!-- Got a Question -->
  
  
      <!-- Sales Enquiry -->
      <div class="flex mt-6">
        <i class="pi pi-phone opacity-50 mr-2"></i>
        <div>
          <h3 class="text-base font-bold mb-0">Sales Enquiries</h3>
          <p class="font-bold my-3 text-sm"><img src="/assets/img/flags/in.svg" alt="India" width="20"
              class="mr-2"> +91 9167778129</p>
          <a href="mailto:sales@fitnessforce.com" class="text-primary font-bold text-base no-underline"><i
              class="pi pi-envelope opacity-50 mr-3 text-gray-800"></i>sales@fitnessforce.com</a>
        </div>
      </div>
      <!-- Sales Enquiry -->
  
    </div>
  
    <div class="flex pt-4 pb-3">
      <i class="pi pi-cog opacity-50 mr-2"></i>
      <div class="text-sm">
        <div>Your IP Address - <strong>202.179.92.89</strong></div>
        <!-- <div class="mt-2">Your Browser - <strong>{{machineDetails?.browser}} Version {{machineDetails?.version}}.0</strong></div> -->
        <div class="mt-2">Your Browser - <strong>{{machineDetails?.browser}}</strong></div>
      </div>
    </div>
  
  </div>
</ng-container>
</ng-template>

</p-dialog>