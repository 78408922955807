import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { CREATE_STAFF, ReportAccessRequest, ReportFilterScheduleResponse, UPDATE_STAFF } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { STAFF_MODEL } from '@fitness-force/models';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  constructor(private http: HttpClient, private router: Router) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  GetStaffList(BASE_URL: string, TENANT_ID: number): Observable<any> {
    let qparam = new HttpParams().set('stf_tent_access_id', TENANT_ID);
    if (TENANT_ID == 0)
      qparam = new HttpParams();

    return this.http.get(BASE_URL + CONST_URL_SEGEMENTS.STAFF.GET_STAFF_LIST, {
      headers: this.httpOptions.headers, params: qparam
    }).pipe(map((res: any) => {
      if (res) {
        return res;
      }
      else {
        return null;
      }
    }), catchError((error: Response) => {
      if (error.status === 404) {
        return throwError(() => new NotFoundError(error));
      }
      return throwError(() => new AppErrors(error));
    }));
  }

  GetStaffById(STAFF_ID: number): Observable<STAFF_MODEL> {
    let url = environment.BASE_URL + CONST_URL_SEGEMENTS.STAFF.GET_STAFF_LIST + '/' + STAFF_ID;
    return this.http.get(url, { headers: this.httpOptions.headers }).pipe(map((res: any) => {
      if (res) { return res; } else { return null; }
    }), catchError((error: Response) => {
      if (error.status === 404)
        return throwError(() => new NotFoundError(error));

      return throwError(() => new AppErrors(error));
    }));
  }

  ChangePassword(BASE_URL: string, STAFF_ID: number, OLD_PASSWORD: string, NEW_PASSWORD: string): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_CHANGE_PASSWORD.replace('{staff_id}', STAFF_ID.toString());
    const params = { 'old_password': OLD_PASSWORD, 'new_password': NEW_PASSWORD, 'UpdatedBy': 0, 'UpdatedDate': new Date() };
    return this.http.post(url, params, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) return throwError(() => new NotFoundError(error));
        return throwError(() => new AppErrors(error));
      })
    );
  }

  SuspendStaff(BASE_URL: string, STAFF_ID: number): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_SUSPEND.replace('{staff_id}', STAFF_ID.toString());
    return this.http.post(url, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) return throwError(() => new NotFoundError(error));
        return throwError(() => new AppErrors(error));
      })
    );
  }

  ActivateStaff(BASE_URL: string, STAFF_ID: number): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_ACTIVE.replace('{staff_id}', STAFF_ID.toString());
    return this.http.post(url, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  TerminateStaff(BASE_URL: string, STAFF_ID: number): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_TERMINATE.replace('{staff_id}', STAFF_ID.toString());
    return this.http.post(url, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  ChangeOwnerStaff(BASE_URL: string, STAFF_ID: number, NEW_OWNER_STAFF_ID: number): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_CHANGEOWNER.replace('{staff_id}', STAFF_ID.toString());
    const params = { 'new_owner_staff_id': NEW_OWNER_STAFF_ID };
    return this.http.post(url, params, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  ChangeReportingManager(BASE_URL: string, STAFF_ID: number, NEW_REPORTING_MANGER: number): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_CHANGEREPORTINGMANAGER.replace('{staff_id}', STAFF_ID.toString());
    const params = { 'reports_to_id': NEW_REPORTING_MANGER };
    return this.http.post(url, params, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) return throwError(() => new NotFoundError(error));
        return throwError(() => new AppErrors(error));
      })
    );
  }

  ChangeTenantAccess(BASE_URL: string, STAFF_ID: number, NEW_TENANT_ACCESS: number[]): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_CHANGECENTERACCESS.replace('{staff_id}', STAFF_ID.toString());
    const params = { 'tenant_access_id': NEW_TENANT_ACCESS };
    return this.http.post(url, params, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => { return res; }),
      catchError((error: Response) => {
        if (error.status === 404) return throwError(() => new NotFoundError(error));
        return throwError(() => new AppErrors(error));
      })
    );
  }

  GetStaffReportSchedule(staffId: number): Observable<ReportFilterScheduleResponse[]> {
    return this.http.get(`${environment.BASE_URL}/staff/${staffId}/schedules`,
      { headers: this.httpOptions.headers }).
      pipe(
        map((res: any) => {
          if (res) {
            return res.items;
          } else {
            return null;
          }
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  CreateStaff(BASE_URL: string, staff: CREATE_STAFF): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.POST_STAFF;
    return this.http.post(url, staff, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => {
        if (res) {
          return res;
        }
        else {
          return null;
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  UpdateStaff(BASE_URL: string, staffId: number, staff: UPDATE_STAFF): Observable<any> {
    const url = BASE_URL + CONST_URL_SEGEMENTS.STAFF.PUT_STAFF.replace('{staff_id}', staffId.toString());
    return this.http.put(url, staff, { headers: this.httpOptions.headers }).pipe(
      map((res: any) => {
        if (res) {
          return res;
        }
        else {
          return null;
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  RequestAccess(requestData: ReportAccessRequest): Observable<Response> {
    return this.http
      .post(`${environment.BASE_URL}/request_access`, requestData, { headers: this.httpOptions.headers })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
