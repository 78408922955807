import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, ViewChild, ElementRef, ChangeDetectorRef, NgZone, Output, EventEmitter, Input } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'ff-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GooglePlacesComponent implements OnInit {
  @ViewChild('searceplace',  {read: ElementRef}) public searchElementRef: ElementRef | any;
  @Output() fetchPlaceInfo:any = new EventEmitter()
  @Input() competitorName: AbstractControl | any
  @Input() placeId: string | any
  @Input("placeHolderName") placeHolderName: string| any;
  isPlaceSearched: boolean = false
 
  private geoCoder: any;
  isLocationSelected = false;
  constructor(private mapsAPILoader: MapsAPILoader, private changeDetectorRef: ChangeDetectorRef,private ngZone: NgZone) { }

  ngOnInit(): void {
    this.onMapReady()
  }
  onMapReady() {
    this.mapsAPILoader.load().then(() => {
    this.geoCoder = new google.maps.Geocoder;
    let ele = this.searchElementRef.nativeElement
    let autocomplete = new google.maps.places.Autocomplete(ele, {
      componentRestrictions: { country: 'in' }
    });

    if(this.placeId)
    {
      var placesService = new google.maps.places.PlacesService(ele);
      /* Get place details */
      placesService.getDetails({
        placeId: this.placeId
      }, (placeResult, status) => {
        if(status === 'OK') {
         this.competitorName.setValue(placeResult.formatted_address)
        }
      });
    }
    
    autocomplete.addListener("place_changed", () => {
      console.log('sea')
      this.closePlaceDailog();
      this.ngZone.run(() => {
        this.isPlaceSearched = true;
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
      
        this.fetchPlaceInfo.emit(place)
      });
    });
  })
  }
  closePlaceDailog() {
    this.isLocationSelected = false
  }
}

@NgModule({
  declarations: [GooglePlacesComponent],
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDH5wyNVLWMF5ktoZ-5m_r7DY4kPDLAWXA',
      libraries: ['geometry', 'places']
    })
  ],
  exports: [GooglePlacesComponent]
})
export class GooglePlacesComponentModule {
}
