import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { ClassTags, CreateClassTypesRequest } from '@fitness-force/models';

import { environment } from 'apps/ff-frontend/src/environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ClassTypeService {
  constructor(private http: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };



  createClassTypes(
    tenantId: number,
    createAppointmentTypesRequest: CreateClassTypesRequest
  ) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/class_types`,
        createAppointmentTypesRequest,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateClassTypes(tenantId: number,class_type_id:number,data:any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/class_types/${class_type_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getClassTypes(tenantId: number,allParams:Array<string>=[]) {

    let parsedParams = new HttpParams()
   if(allParams.length > 0){

    allParams.forEach((p: any) => {

      parsedParams = parsedParams.append(p.name, p.value);
    });
   }
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/class_types`,
        {headers: this.httpOptions.headers , params: parsedParams}
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markActive(tenantId: number,class_type_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/class_types/${class_type_id}/mark_as_active`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markInActive(tenantId: number,class_type_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/class_types/${class_type_id}/mark_as_inactive`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }



  getSingleClassType(tenantId: number,class_type_id:number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/class_types/${class_type_id}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  getClassTags(tenantId: number):Observable<ClassTags[]> {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/class_appt_tags`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res.items;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
