import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(private confirmationService: ConfirmationService) {}
  canDeactivate(
    component: ComponentCanDeactivate
  ): Observable<boolean> | boolean {
    if (component.canDeactivate()) {
      return of(true);
    }
    return new Observable((subscriber) => {
      this.confirmationService.confirm({
        message:
          'You have unsaved changes. Are you sure you want to leave this page?',
        accept: () => {
          subscriber.next(true);
          subscriber.complete();
        },
        reject: () => {
          subscriber.next(false);
          subscriber.complete();
        },
      });
    });
  }
}
