import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { DayAgoPipeModule } from 'libs/shared/src/lib/filters/dayago.pipe';
import { TimeLinePipeModule } from 'libs/shared/src/lib/filters/timeline.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateCellComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [DateCellComponent],
  imports: [
    CommonModule,
    DayAgoPipeModule,
    TimeLinePipeModule
  ],
  exports: [DateCellComponent]
})
export class DateCellComponentModule {
}
