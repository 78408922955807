import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, ignoreElements } from 'rxjs/operators';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { GetStaffListResponse, PagedResults, PaginationFilter } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private router: Router) { }
  
  getStaffList(paginationFilter: PaginationFilter, stf_tent_access_id?: number):Observable<PagedResults<GetStaffListResponse>> {

    let param: any = paginationFilter;
    if (typeof stf_tent_access_id != 'undefined' && stf_tent_access_id) {
      param.append("stf_tent_access_id", stf_tent_access_id);
    }

    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.USERS.STAFFS}`
      , { params: param, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }      
    )
      .pipe(
        map((res: any) => { return res; }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
