import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client, CreateProspectRequest, ListClientResponse, PagedResults } from '@fitness-force/models';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, ignoreElements } from 'rxjs/operators';
import { AppErrors, BadRequestError, NotFoundError } from '@fitness-force/errors';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { ClientStatus } from '@fitness-force/enum';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  createProspect(url: string, client: CreateProspectRequest): Observable<number> {
    return this.http.post(url + '/tenants/' + client.Prospect.createdin_tenant_id.toString() + CONST_URL_SEGEMENTS.CLIENT.POST_CLIENTS, client, this.httpOptions).pipe(
      map((res: any) => res),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getProspects(allparams: any, tenantId: any): Observable<any> {
    let parsedParams = new HttpParams()
    allparams.forEach((p: any) => {
      parsedParams = parsedParams.append(p.name, p.value);
    });

    return this.http.get(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS, {
      headers: this.httpOptions.headers, params: parsedParams
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getIrregularClients(allparams: any, tenantId: any): Observable<any> {
    let parsedParams = new HttpParams()
    allparams.forEach((p: any) => {

      parsedParams = parsedParams.append(p.paramName, p.paramValue);
    });

    return this.http.get(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS + CONST_URL_SEGEMENTS.CLIENT.IRREGULAR_CLIENTS, {
      headers: this.httpOptions.headers, params: parsedParams
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getClientById(clientId: any): Observable<any> {

    return this.http.get(environment.BASE_URL + CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS + '/' + clientId, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  fetchClassApptTags(tenantId: any): Observable<any> {

    return this.http.get(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.CLASS_APPT_TAGS, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  fetchServiceTypes(tenantId: any): Observable<any> {

    return this.http.get(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.SERVICE_CAT, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  updateClientById(clientId: any, tenantId: any, clientData: any): Observable<any> {

    return this.http.put(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS + '/' + clientId, clientData, {
      headers: this.httpOptions.headers
    },

    ).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        console.log('errrt', error)
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  clientAddToBlacklist(clientId: any, tenantId: any, clientData: any): Observable<any> {

    return this.http.post(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS + '/' + clientId + CONST_URL_SEGEMENTS.CLIENT.ADD_TO_BLACKLIST, clientData, {
      headers: this.httpOptions.headers
    },

    ).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        console.log('errrt', error)
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  clientRemoveFromBlacklist(clientId: any, tenantId: any): Observable<any> {

    return this.http.post(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS + '/' + clientId + CONST_URL_SEGEMENTS.CLIENT.REMOVE_FROM_BLACKLIST, {
      headers: this.httpOptions.headers
    },
    ).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        console.log('errrt', error)
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  changeClientRepresentative(clientId: any, tenantId: any, client_rep_data: any): Observable<any> {

    return this.http.post(environment.BASE_URL + CONST_URL_SEGEMENTS.TENANT.GET_TENANTBYID + '/' + tenantId + CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS + '/' + clientId + CONST_URL_SEGEMENTS.CLIENT.CHANGE_CLIENT_REP, client_rep_data, {
      headers: this.httpOptions.headers
    },
    ).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        console.log('errrt', error)
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getSubscriptions(clientId: any): Observable<any> {

    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.SUBSCRIPTIONS}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getSubscriptionSignup(clientId: any): Observable<any> {

    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.SUBSCRIPTION_SINGUPS}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );

  }

  getSubscriptionDetails(clientId: any, clietnSubscriptionId: any): Observable<any> {

    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.SUBSCRIPTIONS}/${clietnSubscriptionId}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );

  }

  getSubscriptionInvoices(clientId: any, clietnSubscriptionId: any): Observable<any> {

    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.SUBSCRIPTIONS}/${clietnSubscriptionId}${CONST_URL_SEGEMENTS.CLIENT.INVOICES}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );

  }

  getPaymentTimeline(clientId: any, clietnSubscriptionId: any, fromDate: any, toDate: any): Observable<any> {
    let parsedParams = new HttpParams()
    parsedParams = parsedParams.append('from_date', fromDate);
    parsedParams = parsedParams.append('to_date', toDate);
    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.SUBSCRIPTIONS}/${clietnSubscriptionId}${CONST_URL_SEGEMENTS.CLIENT.PAYMENT_TIMELINE}`, {
      headers: this.httpOptions.headers, params: parsedParams
    }).pipe(
      map((res: any) => {
        console.log('asdde', res)
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {

        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          console.log('cathf')
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );

  }

  getClientInvoices(clientId: any): Observable<any> {
    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.INVOICES}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        return res
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getClientPayments(clientId: any): Observable<any> {
    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.PAYMENTS}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getClientPaymentMethods(clientId: any): Observable<any> {
    return this.http.get(`${environment.BASE_URL}${CONST_URL_SEGEMENTS.CLIENT.GET_CLIENTS}/${clientId}${CONST_URL_SEGEMENTS.CLIENT.PAYMENT_METHODS}`, {
      headers: this.httpOptions.headers
    }).pipe(
      map((res: any) => {
        if (res) {
          return res
        }
      }),
      catchError((error: Response) => {
        if (error.status === 404) {
          return throwError(() => new NotFoundError(error));
        } else if (error.status === 400) {
          return throwError(() => new BadRequestError(error))
        }
        return throwError(() => new AppErrors(error));
      })
    );
  }

  getClientStatusHtml(status: string): string {
    switch (status) {
      case ClientStatus.Prospect.toString():
      case ClientStatus[ClientStatus.Prospect]:
        return '<span class="bg-pink-500 px-2 py-1 font-bold border-round text-white text-xxs">PROSPECT</span>';
      case ClientStatus.Trail.toString():
      case ClientStatus[ClientStatus.Trail]:
        return '<span class="bg-teal-500 px-2 py-1 font-bold border-round text-white text-xxs">TRIAL</span>';
      case ClientStatus.Client.toString():
      case ClientStatus[ClientStatus.Client]:
        return '<span class="bg-green-500 px-2 py-1 font-bold border-round text-white text-xxs">MEMBER</span>';
      case ClientStatus.ClientNonMember.toString():
      case ClientStatus[ClientStatus.ClientNonMember]:
        return '<span class="bg-cyan-500 px-2 py-1 font-bold border-round text-white text-xxs">NON-MEMBER</span>';
      case ClientStatus.ExClient.toString():
      case ClientStatus[ClientStatus.ExClient]:
        return '<span class="bg-yellow-400 px-2 py-1 font-bold border-round text-white text-xxs">EX-MEMBER</span>';
      case ClientStatus.BlockedClient.toString():
      case ClientStatus[ClientStatus.BlockedClient]:
        return '<span class="bg-gray-400 px-2 py-1 font-bold border-round text-white text-xxs">TERMINATED</span>';
      default:
        return '';
    }
  }
}
