export enum ClientBookingStatus {
    NoStatus = "NoStatus",
    Booked = "Booked",
    Attended = "Attended",
    NoShow = "NoShow",
    Cancelled = "Cancelled",
    Erroneous = "Erroneous",
    Replaced = "Replaced",
    GiveUpSlot = "GiveUpSlot"
}

export enum ClassStatus
{
    Scheduled = "Scheduled",
    Cancelled = "Cancelled",
    Completed = "Completed"
}

export enum AppointmentStatus
{
    Booked = "Booked",
    Attended = "Attended",
    NoShow = "NoShow",
    Cancelled = "Cancelled",
}

export enum CalendarView
{
    Year = "year",
    Month = "month",
    Week = "week",
    Day = "day",
    Agenda = "agenda",
    Trainer = "trainer",
    Studio = "studio",
    Schedule = "schedule",
}


export enum ScheduleType{
    Apppintment="appointment",
    Class="class"
}



