import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  ChangeDetectorRef,
} from '@angular/core';
import { ServiceCategoryService } from '@fitness-force/api';
import { ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { TENANTSINTERFACE } from '@fitness-force/models';
import { Store } from '@ngrx/store';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';

import { ToastModule } from 'primeng/toast';

import { DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable } from 'rxjs';

import { FactoryHttpLoader } from '../helpsupport/helpsupport.component';
import {
  StaffInfoComponent,
  StaffInfoComponentModule,
} from '../staff/staff-info/staff-info.component';
import { ToastComponentModule } from '../toast/toast.component';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { ActionsComponent } from '../data-grid/utility/actions/actions.component';
import { DateCellComponent } from '../data-grid/utility/date-cell/date-cell.component';
import { EntityStatusComponent } from '../data-grid/utility/entity-status/entity-status.component';
import { DataGridComponentModule } from '../data-grid/data-grid.component';
import { ActionColumnComponent } from '../data-grid/utility/action-column/action-column.component';
import { NoComponentComponent } from '../data-grid/empty-state/no-component/no-component.component';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { Status } from '@fitness-force/enum';
import { ServiceCategoryCreateComponentModule } from '../service-category-create/service-category-create.component';

@Component({
  selector: 'service-category',
  templateUrl: './service-category.component.html',
  styleUrls: ['./service-category.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCategoryComponent implements OnInit {
  getAccessTenantList$: Observable<any> = new Observable();
  showOptions: boolean = false;
  tenantId: number = 0;
  tableData = new BehaviorSubject<any[]>([]);
  columns = new BehaviorSubject<any[]>([]);
  activeEditCategory!: any;
  serverCategoryForm: FormGroup;
  primaryLanguage!: string;
  secondaryLanguage!: string;

  loading = new BehaviorSubject<boolean>(false);
  column!: any;
  selectAllAppCatData = new BehaviorSubject(false);
  emptyStateComponent$ = new BehaviorSubject<any>(NoComponentComponent);
  totalRecordLength = new BehaviorSubject({
    totalPage: 10,
    totalItems: 10,
    currentPage: 0,
  });
  apptCatParams = new Map();

  constructor(
    private store: Store,
    private serviceCategoryService: ServiceCategoryService,
    private messageService: MessageService,
    private translate: TranslateService,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.apptCatParams.set('PageNumber', { name: 'PageNumber', value: 1 });
    this.apptCatParams.set('PageSize', { name: 'PageSize', value: 10 });
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });

        this.translate
          .get([
            'common.LANGUAGE.' +
              tenant.selected_tenant.primary_language?.toUpperCase(),
            'common.LANGUAGE.' +
              tenant.selected_tenant.secondary_language?.toUpperCase(),
          ])
          .subscribe((translations) => {
            this.primaryLanguage =
              translations[
                'common.LANGUAGE.' +
                  tenant.selected_tenant.primary_language?.toUpperCase()
              ];
            this.secondaryLanguage =
              translations[
                'common.LANGUAGE.' +
                  tenant.selected_tenant.secondary_language?.toUpperCase()
              ];
          });
      }
      this.getServiceCategoryList();
    });
  }

  getServiceCategoryList() {
    this.loading.next(true);
    this.serviceCategoryService
      .getServiceCategories(this.tenantId, this.getAllParams)
      .subscribe({
        next: (response: any) => {
          if (response.items) {
            this.tableData = new BehaviorSubject<any[]>(response.items);
            this.columnTranslate();
          }
          this.emptyStateComponent$ = new BehaviorSubject<any>(
            NoComponentComponent
          );
          this.totalRecordLength.next({
            totalPage: response.total_pages,
            totalItems: response.total_items,
            currentPage: response.current_page,
          });
          this.selectAllAppCatData.next(false);
          this.loading.next(false);
        },
        error: (error) => {
          this.loading.next(false);
          this.handleError(error);
        },
      });
  }

  columnTranslate() {
    this.translate
      .get([
        'ADMIN.COMMON.LAST_UPDATED_ON',
        'ADMIN.COMMON.LAST_UPDATED_BY',
        'ADMIN.COMMON.CREATED_BY',
        'ADMIN.COMMON.STATUS',
        'ADMIN.SUBSCRIPTION.PLAN_NAME',
      ])
      .subscribe((translations) => {
        this.setColumnTranslate(translations);
      });
  }
  setColumnTranslate(translations: any) {
    this.column = {
      lastUpdateON: translations['ADMIN.COMMON.LAST_UPDATED_ON'],
      lastUpdatedBy: translations['ADMIN.COMMON.LAST_UPDATED_BY'],
      createdBy: translations['ADMIN.COMMON.CREATED_BY'],
      status: translations['ADMIN.COMMON.STATUS'],
      planName: translations['ADMIN.SUBSCRIPTION.PLAN_NAME'],
    };

    this.columns = new BehaviorSubject<any[]>([
      {
        title: this.column.planName,
        classes: ' py-2  white-space-nowrap font-bold ',
        sortable: true,
        filterable: true,
        dataProperty: 'name_primary_language',
        type: 'component',
        data: {
          name: ActionColumnComponent,
          additionData: true,
          aData: { keyName: 'name_primary_language' },
        },
      },
      {
        title: this.column.lastUpdatedBy,
        classes: ' py-2  white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'updated_by',
        type: 'component',
        data: { name: StaffInfoComponent },
      },
      {
        title: this.column.lastUpdateON,
        classes: ' py-2  white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'updated_date',
        type: 'component',
        data: {
          name: DateCellComponent,
          additionData: true,
          aData: { fetch_key: 'updated_date' },
        },
      },
      {
        title: this.column.status,
        classes: 'py-2  white-space-nowrap',
        sortable: false,
        filterable: true,
        dataProperty: 'status',
        type: 'component',
        data: { name: EntityStatusComponent },
      },
      {
        title: '',
        classes: ' py-2  white-space-nowrap',
        dataProperty: 'actions',
        type: 'component',
        data: {
          name: ActionsComponent,
          additionData: true,
          aData: ['edit', 'status'],
        },
      },
    ]);
  }

  handleError(error: any) {
    if (error instanceof NotFoundError) {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'common.errors.SERVER_SIDE.PAGE_NOT_FOUND',
        ])
        .subscribe((translations: any) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.PAGE_NOT_FOUND'],
          });
        });
    } else if (
      error.originalError &&
      error.originalError.error &&
      error.originalError.error[0]
    ) {
      let currentError = error.originalError.error[0];
      if (currentError.errorCode == 'alreadyexist') {
        if (
          currentError?.identifier &&
          currentError.identifier == 'name_pri_lang'
        )
          this.NamePrimary?.setErrors({
            duplicate: true,
          });
        else if (
          currentError?.identifier &&
          currentError.identifier == 'name_sec_lang'
        )
          this.NameSecondary?.setErrors({
            duplicate: true,
          });
        this.changeDetectorRef.detectChanges();
      }
    }
    {
      this.translate
        .get([
          'common.errors.SERVER_SIDE.ERROR_OCCURRED',
          'common.errors.SERVER_SIDE.SOMETHING_WRONG',
        ])
        .subscribe((translations: any) => {
          this.messageService.add({
            severity: 'error',
            summary: translations['common.errors.SERVER_SIDE.ERROR_OCCURRED'],
            detail: translations['common.errors.SERVER_SIDE.SOMETHING_WRONG'],
          });
        });
    }
  }

  closeDialog() {
    this.ref.close({});
  }


  toggleStatus(serviceCat: any) {
    if (
      serviceCat.status == Status.Active.toString() ||
      serviceCat.status == Status[Status.Active]
    ) {
      this.serviceCategoryService
        .markInActive(this.tenantId, serviceCat.id)
        .subscribe({
          next: (res) => {
            this.getServiceCategoryList();
            this.translate
              .get([
                'ADMIN.SERVICES.SERVICE_CATEGORY_INACTIVE',
                'ADMIN.SERVICES.SERVICE_CATEGORY_INACTIVE_MESSAGE',
              ])
              .subscribe((translations) => {
                this.messageService.add({
                  severity: 'success',
                  summary:
                    translations['ADMIN.SERVICES.SERVICE_CATEGORY_INACTIVE'],
                  detail:
                    translations[
                      'ADMIN.SERVICES.SERVICE_CATEGORY_INACTIVE_MESSAGE'
                    ],
                });
              });
          },
          error: (error) => {
            this.handleError(error);
          },
        });
    } else {
      this.serviceCategoryService
        .markActive(this.tenantId, serviceCat.id)
        .subscribe({
          next: (res) => {
            this.getServiceCategoryList();

            this.translate
              .get([
                'ADMIN.SERVICES.SERVICE_CATEGORY_ACTIVE',
                'ADMIN.SERVICES.SERVICE_CATEGORY_ACTIVE_MESSAGE',
              ])
              .subscribe((translations) => {
                this.messageService.add({
                  severity: 'success',
                  summary:
                    translations['ADMIN.SERVICES.SERVICE_CATEGORY_ACTIVE'],
                  detail:
                    translations[
                      'ADMIN.SERVICES.SERVICE_CATEGORY_ACTIVE_MESSAGE'
                    ],
                });
              });
          },
          error: (error) => {
            this.handleError(error);
          },
        });
    }
  }

  editCategory(category: any) {
    this.serviceCategoryService
      .getSingleServiceCategory(this.tenantId, category.id)
      .subscribe({
        next: (res) => {
          this.activeEditCategory = res;
          this.buildEditForm();
        },
        error: (error) => {
          this.handleError(error);
        },
      });
  }

  get NamePrimary(): AbstractControl | null {
    return this.serverCategoryForm.get('name_primary_language');
  }
  get NameSecondary(): AbstractControl | null {
    return this.serverCategoryForm.get('name_secondary_language');
  }

  get DescriptionPrimary(): AbstractControl | null {
    return this.serverCategoryForm.get('description_primary_language');
  }

  get DescriptionSecondary(): AbstractControl | null {
    return this.serverCategoryForm.get('description_secondary_language');
  }

  buildEditForm() {
    this.serverCategoryForm = this.fb.group({
      name_primary_language: [
        this.activeEditCategory.name_primary_language,
        Validators.required,
      ],
      name_secondary_language: [
        this.activeEditCategory.name_secondary_language,
        Validators.required,
      ],
      description_primary_language: [
        this.activeEditCategory.description_primary_language,
        Validators.required,
      ],
      description_secondary_language: [
        this.activeEditCategory.description_secondary_language,
        Validators.required,
      ],
      terms_condition: [
        this.activeEditCategory.terms_condition
          ? this.activeEditCategory.terms_condition
          : '',
      ],
      terms_condition_title: [
        this.activeEditCategory.terms_condition_title
          ? this.activeEditCategory.terms_condition_title
          : '',
      ],
      icon: [this.activeEditCategory.icon ? this.activeEditCategory.icon : ''],
    });
  }

  get Icon(): AbstractControl | null {
    return this.serverCategoryForm.get('icon');
  }
  setIcon(icon: string) {
    this.Icon?.setValue(icon);
  }

  updateServiceCategory() {
    if (this.serverCategoryForm.valid) {
      let data = this.serverCategoryForm.getRawValue();
      this.serviceCategoryService
        .updateServiceCategory(this.tenantId, this.activeEditCategory.id, data)
        .subscribe({
          next: (res) => {
            this.activeEditCategory = null;
            this.getServiceCategoryList();

            this.translate
              .get([
                'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE',
                'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE_MESSAGE',
              ])
              .subscribe((translations) => {
                this.messageService.add({
                  severity: 'success',
                  summary:
                    translations['ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE'],
                  detail:
                    translations[
                      'ADMIN.SERVICES.SERVICE_CATEGORY_UPDATE_MESSAGE'
                    ],
                });
              });
          },
          error: (error) => {
            this.handleError(error);
          },
        });
    }
  }

  editCategoryCancel() {
    this.activeEditCategory = null;
  }

  get getAllParams(): Array<any> {
    return [...this.apptCatParams.values()];
  }

  fetchNextSetData(e: any) {
    this.apptCatParams.set('PageNumber', { name: 'PageNumber', value: e.pn });
    this.apptCatParams.set('PageSize', { name: 'PageSize', value: e.ps });
    // this.tasksParams.set('PageNumber', {paramName: 'PageNumber', paramValue: e.pn})
    // this.tasksParams.set('PageSize', {paramName: 'PageSize', paramValue: e.ps})
    // this.showBulkActionBar = false
    // this.gettableRecord([])
    // this.selectedClient.clear()
    //this.getAllTask()
    //this.currentPage$.next({pageSize:this.tasksParams.get('PageSize'), pageNumber:this.tasksParams.get('PageNumber') })
  }

  onActionEvent(event: any) {
    switch (event.action) {
      case 'edit':
      case 'rowAction':
        this.editCategory(event.columnData);
        break;
      case 'status':
        this.toggleStatus(event.columnData);
        break;
      case 'delete':
        break;
    }
  }
  changedEditCategory(event:any){
    if(event){
      this.getServiceCategoryList();
    }
    this.activeEditCategory=null
  }
}

@NgModule({
  declarations: [ServiceCategoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    DialogModule,
    DividerModule,
    InputTextModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
    ToastComponentModule,
    ErrorMsgComponentModule,
    ToastModule,
    StaffInfoComponentModule,
    DynamicDialogModule,
    DataGridComponentModule,
    SkeletonModule,
    TableModule,
    ServiceCategoryCreateComponentModule

  ],
  exports: [ServiceCategoryComponent],
})
export class ServiceCategoryComponentModule {}
