<p-dialog [(visible)]="showPopup" [closeOnEscape]="closeDialog" [resizable]="false" appendTo="body" [modal]="true"
    [breakpoints]="{'960px': '100vw', '640px': '100vw'}" [style]="{width: '90vw'}" styleClass="no-header-modal">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <div class="xl:flex">

            <!-- Left Side -->
            <div class="xl:w-4 hidden xl:block bg-no-repeat bg-cover bg-center"
                style="background-image: url(/assets/img/create-prospect-img.svg);"></div>
            <!-- Left Side -->

            <!-- Right Side -->
            <div class="xl:w-12 border-left-1 border-gray-300">
                <form [formGroup]="fg" (submit)="CreateProspectSubmit()">
                    <!-- <p-toast (onClose)="setHidePopup()"></p-toast> -->

                    <p-toast (onClose)="setHidePopup()" [style]="{marginTop: '80px'}" styleClass="custom-toast">
                        <ng-template let-message pTemplate="message">
                            <div style="text-align: center">
                                {{message.detail}}
                                <a href="javascript:void(0);" routerLink="/client/profile/{{message.data.clientId}}">
                                    {{ message.data.clientName}}
                                </a>
                            </div>
                        </ng-template>
                    </p-toast>

                    <!-- Header -->
                    <div class="h-4rem pl-4 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">
                        <div class="flex align-items-center">
                            <i class="pi pi-user-plus opacity-50 mr-3"></i>
                            <h2 class="mb-0 font-normal text-lg">
                                {{'Shared.CREATE-PROSPECT.HEADER_ADDPROSPECT' | translate}}
                            </h2>
                        </div>
                        <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-4 mr-4 cursor-pointer"
                            (click)="setHidePopup()"></div>
                    </div>
                    <!-- Header -->

                    <!-- Content -->
                    <div class="surface-50 text-sm px-4 py-2 border-bottom-1 border-gray-300">
                        <div class="grid">
                            <div class="col-12 md:col-4 md:my-2 my-0">
                                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                    {{'Shared.CREATE-PROSPECT.LABEL_FULLNAME' | translate}}
                                    <sup class="p-error">*</sup>
                                </label>
                                <input type="text" pInputText class="w-full" formControlName="name"
                                    placeholder="{{'Shared.CREATE-PROSPECT.PLACEHOLDER_NAME' | translate}}" />
                                <app-error-msg *ngIf="NameControl.touched && NameControl.invalid"
                                    [errors]="NameControl.errors"
                                    [fieldLabel]="'Shared.CREATE-PROSPECT.NAME' | translate">
                                </app-error-msg>
                            </div>

                            <div class="col-12 md:col-4 md:my-2 my-0">
                                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                    {{'Shared.CREATE-PROSPECT.LABEL_EMAILID' | translate}}
                                    <sup class="p-error">*</sup>
                                </label>
                                <input type="email" pInputText class="w-full" formControlName="emailId"
                                    placeholder="name@example.com" />
                                <app-error-msg *ngIf="EmailIdControl.touched && EmailIdControl.invalid"
                                    [errors]="EmailIdControl.errors"
                                    [fieldLabel]="'Shared.CREATE-PROSPECT.EMAILID' | translate">
                                </app-error-msg>
                            </div>

                            <div class="col-12 md:col-4 md:my-2 my-0">
                                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                    {{'Shared.CREATE-PROSPECT.LABEL_MOBILENUMBER' | translate}}
                                    <sup class="p-error">*</sup>
                                </label>
                                <p-inputMask mask="(999) 999-9999" [unmask]="true" formControlName="mobileNo"
                                    class="w-full" placeholder="(___) ___-____"></p-inputMask>
                                <app-error-msg *ngIf="MobileNoControl.touched && MobileNoControl.invalid"
                                    [errors]="MobileNoControl.errors"
                                    [fieldLabel]="'Shared.CREATE-PROSPECT.MOBILENO' | translate">
                                </app-error-msg>
                            </div>

                            <div class="col-12">
                                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                    {{'Shared.CREATE-PROSPECT.LABEL_GENDER' | translate}}
                                </label>
                                <span class="p-buttonset hidden xl:block">
                                    <button type="button" *ngFor="let gEnum of EnumGender" pButton pRipple
                                        label="{{gEnum.value}}"
                                        [ngClass]="{'p-button-secondary border-none md:border-1' : SelectedGender.value?.value !== gEnum.value }"
                                        (click)="SetGender(gEnum)" class="text-sm w-4-sm border-right-1 border-white">
                                    </button>
                                </span>

                                <div class="block xl:hidden">
                                    <p-dropdown [options]="EnumGender" formControlName="selectedGender"
                                        optionLabel="value" filterBy="name" styleClass="w-full">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="text-sm" *ngIf="SelectedGender.value">
                                                {{SelectedGender.value.value}}
                                            </div>
                                        </ng-template>
                                        <ng-template let-gender pTemplate="item">
                                            <div class="text-sm">{{gender.value}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <app-error-msg styleClass="md:block"
                                    *ngIf="SelectedGender.dirty && SelectedGender.invalid"
                                    [errors]="SelectedGender.errors"
                                    [fieldLabel]="'CreateEditStaff.GENDER' | translate">
                                </app-error-msg>
                            </div>

                            <div class="col-12 xl:col-6 md:col-6 md:my-2 my-0">
                                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                    {{'Shared.CREATE-PROSPECT.LABEL_CHANNEL' | translate}}
                                </label>
                                <span class="p-buttonset white-space-nowrap">
                                    <button type="button" *ngFor="let channelOption of channelCollection"
                                        class="p-button-primary border-right-1 border-white md:w-3 text-sm"
                                        [ngClass]="{'p-button-primary': channelOption.channel === ChannelControl.value.channel,'p-button-secondary': channelOption.channel !== ChannelControl.value.channel}"
                                        (click)="ChannelControl = channelOption" pButton pRipple
                                        label="{{channelOption.channel}}"></button>
                                    <app-error-msg *ngIf="ChannelControl.touched && ChannelControl.invalid"
                                        [errors]="ChannelControl.errors"
                                        [fieldLabel]="'Shared.CREATE-PROSPECT.CHANNEL' | translate">
                                    </app-error-msg>
                                </span>
                            </div>

                            <div class="col-12 xl:col-4 md:col-6 md:my-2 my-0">
                                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                    {{'Shared.CREATE-PROSPECT.LABEL_ENQUIRYSOURCE' | translate}}
                                </label>
                                <p-dropdown [options]="Source" formControlName="source" placeholder="Select source"
                                    styleClass="w-full text-sm align-items-center" [disabled]="DisableSource">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="text-sm" *ngIf="SourceControl.value">
                                            {{SourceControl.value}}
                                        </div>
                                    </ng-template>
                                    <ng-template let-source pTemplate="item">
                                        <div class="text-sm">{{source}}</div>
                                    </ng-template>
                                </p-dropdown>
                                <app-error-msg *ngIf="SourceControl.dirty && SourceControl.invalid"
                                    [errors]="SourceControl.errors"
                                    [fieldLabel]="'Shared.CREATE-PROSPECT.ENQUIRYSOURCE' | translate">
                                </app-error-msg>
                            </div>

                            <div class="col-12 xl:col-6 md:col-6 md:my-1 my-0" style="display:none;">
                                <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                    {{'Shared.CREATE-PROSPECT.LABEL_PROMOTIONALPREFERENCE' | translate}}
                                </label>
                                <span class="p-buttonset white-space-nowrap">
                                    <button type="button"
                                        *ngFor="let preferenceOption of promotionalPreferenceCollection"
                                        [ngClass]="{'p-button-primary' : IsPromotionOptionSelected(preferenceOption),'p-button-secondary' : !IsPromotionOptionSelected(preferenceOption)}"
                                        (click)="PromotionalPreferenceControl = preferenceOption"
                                        class="p-button-primary border-right-1 text-sm border-white md:w-3" pButton
                                        pRipple label="{{preferenceOption.name}}"
                                        icon="{{preferenceOption.icon}}"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Content -->

                    <!-- Content -->
                    <div class="px-4 pt-4 text-sm border-bottom-1 border-gray-300">
                        <div class="flex align-items-center">
                            <p-inputSwitch formControlName="createTask"></p-inputSwitch>
                            <span class="ml-3 md:text-lg text-base font-bold line-height-1 mb-0">
                                {{'Shared.CREATE-PROSPECT.SPAN_CREATEOPPORTUNITYTASK' | translate}}
                            </span>
                        </div>

                        <div class="md:ml-7 mt-3 md:mt-0">
                            <div class="grid">
                                <div class="col-6 md:col-3 md:my-3 my-1">
                                    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                        {{'Shared.CREATE-PROSPECT.LABEL_TASKTYPE' | translate}}
                                    </label>
                                    <p-dropdown [options]="taskTypeCollection" formControlName="taskType"
                                        [disabled]="!CreateTask" optionLabel="name" styleClass="w-full text-sm"
                                        placeholder="Select..." scrollHeight="80px">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="task-item task-item-value flex align-items-center"
                                                *ngIf="TaskTypeControl.value">
                                                <i class="mr-3 {{TaskTypeControl.value.icon}}"></i>
                                                <div class="text-sm">{{TaskTypeControl.value.name}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-task pTemplate="item">
                                            <div class="task-item flex align-items-center">
                                                <i class="mr-3 {{task.icon}}"></i>
                                                <div class="text-sm">{{task.name}}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <app-error-msg *ngIf="TaskTypeControl.dirty && TaskTypeControl.invalid"
                                        [errors]="TaskTypeControl.errors"
                                        [fieldLabel]="'Shared.CREATE-PROSPECT.TASKTYPE' | translate">
                                    </app-error-msg>
                                </div>

                                <div class="col-6 md:col-3 md:my-3 my-1">
                                    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                        {{'Shared.CREATE-PROSPECT.LABEL_TASKON' | translate}}
                                    </label>
                                    <p-dropdown [options]="taskOnCollection" formControlName="taskOn" optionLabel="name"
                                        styleClass="w-full" [disabled]="!CreateTask" styleClass="w-full text-sm"
                                        placeholder="Select...">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="text-sm" *ngIf="TaskOnControl.value">
                                                {{TaskOnControl.value.name}}</div>
                                        </ng-template>
                                        <ng-template let-taskOn pTemplate="item">
                                            <div class="text-sm">{{taskOn.name}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                    <p-calendar *ngIf="TaskOnControl.value.value == 'custom date' && CreateTask"
                                        [showIcon]="true" formControlName="taskOnDate" styleClass="w-full mt-2"
                                        inputStyleClass="text-sm w-full"></p-calendar>
                                    <app-error-msg *ngIf="TaskOnControl.dirty && TaskOnControl.invalid"
                                        [errors]="TaskOnControl.errors"
                                        [fieldLabel]="'Shared.CREATE-PROSPECT.TASKON' | translate">
                                    </app-error-msg>
                                    <app-error-msg *ngIf="TaskOnDateControl.touched && TaskOnDateControl.invalid"
                                        [errors]="TaskOnDateControl.errors"
                                        [fieldLabel]="'Shared.CREATE-PROSPECT.TASKONDATE' | translate">
                                    </app-error-msg>
                                </div>

                                <div class="col-12 md:col-6 md:my-3 my-1">
                                    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                        {{'Shared.CREATE-PROSPECT.LABEL_INTERESTEDIN' | translate}}
                                    </label>
                                    <p-multiSelect [options]="interestedInCollection" formControlName="interestedIn"
                                        [disabled]="!CreateTask" defaultLabel="Select a service type" optionLabel="name"
                                        display="chip" styleClass="w-full text-sm max-w-full"></p-multiSelect>
                                    <app-error-msg *ngIf="InterestedInControl.touched && InterestedInControl.invalid"
                                        [errors]="InterestedInControl.errors"
                                        [fieldLabel]="'Shared.CREATE-PROSPECT.INTERESTEDIN' | translate">
                                    </app-error-msg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Content -->

                    <!-- Modal Footer -->
                    <div class="px-4 py-3">
                        <div class="flex align-items-center justify-content-between">
                            <div class="w-6">
                                <p-button label="{{'Shared.CREATE-PROSPECT.BUTTON_CANCEL' | translate}}"
                                    styleClass="p-button-link text-sm" (click)="setHidePopup()">
                                </p-button>
                            </div>
                            <div class="w-6 text-right">
                                <button class="text-sm" type="submit" pButton pRipple
                                    label="{{'Shared.CREATE-PROSPECT.BUTTON_CREATEPROFILE' | translate}}"></button>
                            </div>
                        </div>
                    </div>
                    <!-- Modal Footer -->

                    <app-errors *ngIf="showErrorPopup | async as show_Popup" [visible]="show_Popup"
                        [errorData]="errorMessages" [error]="error" (hideErrorPopup)="HideErrorPopup($event)">
                    </app-errors>
                </form>
            </div>
            <!-- Right Side -->
        </div>
    </ng-template>
</p-dialog>