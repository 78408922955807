<form [formGroup]="langFromGroup"  >
<p-dropdown [options]="parseSupportedLang" formControlName="selectedLang" (onChange)="onSelectLang(SelectedLang)"  optionLabel="LANG_CODE" styleClass="border-none align-items-center">
    <ng-template pTemplate="selectedItem">
      <div class="country-item country-item-value flex align-items-center" *ngIf="SelectedLang">
        <img class="mr-3" width="20" src="assets/img/flags/{{SelectedLang.COUNTRY}}.svg" />
        <div class="text-sm">{{SelectedLang.LANG_CODE  | uppercase}}</div>
      </div>
    </ng-template>
    <ng-template let-language pTemplate="item">
      <div class="country-item flex align-items-center">
        <img class="mr-3" width="20" src="assets/img/flags/{{language.COUNTRY}}.svg" />
        <div class="text-sm">{{language.LANG_CODE | uppercase}}</div>
      </div>
    </ng-template>
  </p-dropdown>
</form>