import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgModule, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FooterComponentModule, HeaderComponentModule, SidebarComponentModule } from '@fitness-force/core';
import { PlanSummary } from '@fitness-force/models';
import { Observable } from 'rxjs';

import { BillingSummaryComponentModule } from '../billing-summary/billing-summary.component';
import { PaymentSummaryComponentModule } from '../payment-summary/payment-summary.component';

@Component({
  selector: 'billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingComponent implements OnInit {

  @Input('planSummarydata') planSummary$: Observable<PlanSummary>;
  @Input('showBilling') showbilling: boolean = false;

  @Output() closeSidePopupMember: EventEmitter<{ hideSidebar: boolean, uuid: string | null }> = new EventEmitter();

  showPaidFullSidebar$: Observable<boolean> | any = false;
  showPaymentStatusSidebar$: Observable<boolean> | any = false;
  uuid: string;

  constructor() { }

  ngOnInit(): void {
    this.showPaidFullSidebar$ = this.showbilling;
  }

  HidePaymentSummary(evt: any) {
    this.showPaymentStatusSidebar$ = false;
    this.closeSidePopupMember.emit({ hideSidebar: false, uuid: this.uuid });
  }

  CloseBillingSummary(evt: any) {
    if (evt.clientSignupUUID) {
      this.showPaidFullSidebar$ = false;
      this.uuid = evt.clientSignupUUID;
      this.showPaymentStatusSidebar$ = true;
    }
    else {
      this.closeSidePopupMember.emit({ hideSidebar: false, uuid: this.uuid });
    }
  }
}

@NgModule({
  declarations: [BillingComponent],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    HeaderComponentModule,
    SidebarComponentModule,
    FooterComponentModule,
    FormsModule,
    PaymentSummaryComponentModule,
    BillingSummaryComponentModule,
  ],
  exports: [BillingComponent]
})
export class BillingComponentModule {
}
