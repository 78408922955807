import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'payment-data',
  templateUrl: './payment-data.component.html',
  styleUrls: ['./payment-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDataComponent implements OnInit {
  
  @Input() columnData = new BehaviorSubject(null);
  @Output() onAction = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  OpenPaymentDetails(payment: any) {
    this.onAction.emit({ action: 'openpayment', columnData: payment });
  }
}

@NgModule({
  declarations: [PaymentDataComponent],
  imports: [CommonModule],
  exports: [PaymentDataComponent],
})
export class PaymentDataComponentModule {}
