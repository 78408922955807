import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function  DecimalValidation(controlName: string, decimals:number) {
    return (formGroup: FormGroup) => {
        if (!formGroup.controls) return;
        const control = formGroup.controls[controlName];
        const value =  control.value;          
         if (control.errors) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        const regValue =  /^[1-9]\d*(\.\d+)?$/; /// DNW ^[-+]?([1-9]{1}\d*|0{1})(\.?)(?(2)\d+)$/; //NEED TO SEE IF COMMA AND + signed needs to be allowed
        if (!regValue.test(value)) {
            control.setErrors({ decimalNumberLimit: true });
        } else {
            control.setErrors(null);
        }        
    }
}



// import { FormControl } from "@angular/forms";
// import * as moment from "moment";

// export function DateValidator(format = "MM/dd/YYYY"): any {
//   return (control: FormControl): { [key: string]: any } => {
//     const val = moment(control.value, format, true);

//     if (!val.isValid()) {
//       return { invalidDate: true };
//     }

//     return null;
//   };
// }
 