<p-dialog [(visible)]="changeTenantAccesDialog" (onHide)="ClosePopup()" [resizable]="false" appendTo="body"
    [modal]="true" [breakpoints]="{'767px': '100vw'}" [style]="{width: '570px'}"
    styleClass="no-header-modal overflow-visible">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <form [formGroup]="formGroup" (ngSubmit)="ValidateAndSubmit()">
            <!-- Header -->
            <div class="p-3 md:px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
                <div>
                    <h2 class="mb-0 text-base md:text-lg font-normal">
                        {{'STAFF.CHANGE_TENANT_ACCESS.CHANGECENTREACCESSOF' | translate}}
                        <strong>{{staff.name}} </strong>
                        <span class="text-xs text-gray-600"> - {{staff.id}}</span>
                    </h2>
                </div>
                <div class="pi pi-times text-gray-500 cursor-pointer" (click)="ClosePopup()"></div>
            </div>
            <!-- Header -->

            <!-- Modal Content -->
            <div class="surface-100 p-3 md:px-5 md:py-4">
                <!-- Column -->
                <div class="">
                    <label class="font-medium text-gray-700 text-xs mb-2 block uppercase"> {{'STAFF.CHANGE_TENANT_ACCESS.ADDTENANTACCESS' | translate}}</label>
                    <div class="relative">
                        <p-multiSelect *ngIf="(accessTenant$ | async) as tenants"
                            placeholder="{{'STAFF.CHANGE_TENANT_ACCESS.TENANTPLACEHOLDER' | translate}}"
                            [options]="tenants" formControlName="new_tenant_access" optionLabel="name_primary_language"
                            styleClass="w-full text-sm"></p-multiSelect>
                    </div>
                </div>
                <!-- Column -->
            </div>
            <!-- Modal Content -->

            <div class="flex align-items-center justify-content-between border-top-1 border-gray-300  p-3 md:px-5">
                <div class="w-4">
                    <p-button label="{{'STAFF.CHANGE_TENANT_ACCESS.CANCEL' | translate}}" styleClass="p-button-link p-0"
                        class="text-sm" class="text-sm" (click)="ClosePopup()">
                    </p-button>
                </div>
                <div class="w-8 text-right">
                    <button type="submit" class="text-sm" disabled pButton pRipple [disabled]="!formGroup.valid"
                        label="{{'STAFF.CHANGE_TENANT_ACCESS.SAVE' | translate}}"></button>
                </div>
            </div>

            <app-errors *ngIf="showErrorPopup | async as show_Popup" [visible]="show_Popup" [errorData]="errorMessages"
                (hideErrorPopup)="HideErrorPopup($event)">
            </app-errors>
        </form>
    </ng-template>
</p-dialog>