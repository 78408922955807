import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppointmentService, AppointmentTypesService, TimeconversionService } from '@fitness-force/api';
import { AppErrors, ErrorMsgComponentModule } from '@fitness-force/errors';
import { AppointmentType, AppointmentTypeById, CreateAppointmentRequest, CreateRecurringAppointmentRequest, RepeatRuleResponse, TENANTSINTERFACE } from '@fitness-force/models';
import { AssignedToComponentModule, ErrorsComponentModule, FactoryHttpLoader, RruleComponentModule } from '@fitness-force/shared';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { COMPANY_INFO, STAFF_STATE, APPOINTMENT_TYPE_STATE, ROOM_LIST_STATE, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StyleClassModule } from 'primeng/styleclass';
import { BehaviorSubject, Observable } from 'rxjs';
import { MiniSearchComponentModule } from '../../../search/mini-search/mini-search.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import {MbscCalendarEvent} from '@mobiscroll/angular';
import { Ienum, ScheduleType, StaffStatus } from '@fitness-force/enum';
import { timeLineService } from 'libs/shared/src/lib/filters/timeline.pipe';
import { Router } from '@angular/router';
@Component({
  selector: 'book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class BookAppointmentComponent implements OnInit {

  @Input('visible') showBookAppointment: boolean = false;
  @Input('tenantId') tenantId: number =0;
  @Input('startDateTime') startDateTime: string;
  @Output('CloseBookAppointment') hideBookAppointment: EventEmitter<boolean> = new EventEmitter();
  @Output('appointmentEvent') appointmentEvent: EventEmitter<any> = new EventEmitter();
  @Output('reloadData') reloadData: EventEmitter<any> =new EventEmitter();

  CreateAppointmentForm: FormGroup = new FormGroup({});
  apptTypeToShow:number=5;
  selectedApptType:AppointmentType;
  selectedApptSubType:Observable<any> | any;
  isVirtal:boolean=false;
  showRRule: boolean = false;
  RRuleObject = <RepeatRuleResponse>{};
  selectedclient:any;

  showApptSubType= new BehaviorSubject(false);
  selectedRoom: { name: string } | any;
  selectedTrainer: {name: string, availability: string, ff_picture: string} | undefined
  getAccessedStaffList$: Observable<any> | any;
  appointmentTypeList$= new BehaviorSubject<any[]>([]);
  appointmentSubTypeList$ = new BehaviorSubject<any[]>([]);
  roomList$: Observable<any> | any;

  errorDataModal = [] as any;
  errorMessages = [] as any;
  showErrorPopup$ = new BehaviorSubject(false);
  minimumDate = new Date();

  currentTenant:Observable<any> | any;
  disableSaveBtn:boolean= false;
  error = new BehaviorSubject(null as (AppErrors | null));
  filterStaffIds = new BehaviorSubject<number[]>([0]);
  
  constructor(
    private http: HttpClient,
    private store: Store,
    private translate: TranslateService,
    private fb: FormBuilder,
    private apptTypeService:AppointmentTypesService,
    private timeConversionService : TimeconversionService,
    public datePipe: DatePipe,
    public appointmentService: AppointmentService,
    private messageService: MessageService,
    private router: Router
    
  ) {}

  ngOnInit(): void {
    this.componentInit();
  }
  
  componentInit() {
    this.store.select(COMPANY_INFO).subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.currentTenant= tenant.selected_tenant;
    });

    this.CreateAppointmentForm = this.fb.group({
      client_id:['',[Validators.required]],
      startDate: [this.startDateTime, [Validators.required]],
      startTime: [this.startDateTime, [Validators.required]],
      selectedTrainer:['',[Validators.required]],
      selectedRoom:[''],
      virtualUrl:[''],
      comments: [''],
    });

    this.GetAppointmentTypes();
    this.GetStaffs();
    this.GetRooms();
  }

  GetAppointmentTypes()
  {
    this.store.select(APPOINTMENT_TYPE_STATE).subscribe((responseData: AppointmentType[]) => {
      this.appointmentTypeList$.next(responseData.filter(appt =>appt.status.toLowerCase() =='active'));
      if(this.appointmentTypeList$.value.length > 0)
      {
        this.selectedApptType = responseData[0];
        if(this.selectedApptType.is_room_mandatory)
        {
          this.SelectedRoomFormControl.setValidators(Validators.required);
          this.SelectedRoomFormControl.updateValueAndValidity();
        }
        this.GetAppointmentSubTypes(this.appointmentTypeList$.value[0].id);
      }
    });
  }

  GetAppointmentSubTypes(appointmentTypeId:number)
  {
    this.apptTypeService.getSingleAppointmentType(this.tenantId,appointmentTypeId).subscribe({
      next: (response: AppointmentTypeById) => {
       this.appointmentSubTypeList$.next(response.subtypes);
      if(response.subtypes)
      {
        this.selectedApptSubType = response.subtypes[0];
        this.showApptSubType.next(true);
      }
      else{
        this.selectedApptSubType = null;
        this.showApptSubType.next(false);
      }
      },
      error: (error) => {
        this.selectedApptSubType= null;
        this.showApptSubType.next(false);
      },
    });
  }

  GetStaffs()
  {
      this.store.select(STAFF_STATE).subscribe((data: any) => {
        if (data && data.staffList && data.staffList.length > 0)
        {
          let validStaff: number[] = [];
          this.getAccessedStaffList$ = data.staffList.filter((staff: any) => 
          { 
            let oStaffStatus = this.OStaffStatus.find((o: any) => (o.value == staff.status || o.key == staff.status));
            if(oStaffStatus!.value == StaffStatus.Active || oStaffStatus!.key == StaffStatus.Active && staff.can_take_appointment)
            {
              validStaff.push(staff.id);
              return staff;
            }
          });
          this.filterStaffIds.next(validStaff);
        }
      });
  }

  GetRooms()
  {
    this.store.select(ROOM_LIST_STATE).subscribe((responseData) => {
      this.roomList$ = responseData;
    });
  }

  SelectAppointmentType(apptType:any)
  {
    this.selectedApptType= apptType;
    this.GetAppointmentSubTypes(apptType.id);
  }

  getSelectedColor(apptType: any) {
    return `border-${apptType.color}-400 bg-${apptType.color}-50 text-${apptType.color}-400 font-bold`;
  }

  SetIsVirtual(isVirtual:boolean)
  {
    this.isVirtal=isVirtual;
    if(isVirtual){
      this.SelectedRoomFormControl.setValidators(null);
      this.SelectedRoomFormControl.setValue(null);
    } 
    else {
      this.SelectedRoomFormControl.setValidators(this.selectedApptType.is_room_mandatory ? Validators.required : null);
      this.VirtualUrlFormControl.setValidators(null);
      this.VirtualUrlFormControl.setValue(null);
    }

    this.SelectedRoomFormControl.updateValueAndValidity();
    this.VirtualUrlFormControl.updateValueAndValidity();
  }

  HideRRule(e: any): void {
    this.showRRule = e;
  }

  FetchRRuleData(e: any): void {
    this.RRuleObject.RRule = e.RRule;
    this.RRuleObject.RRuleText=e.RRuleText;
  }

  setClient(e: any) {
    if (e && e.clt_id > 0) {
      this.selectedclient =e;
      this.SelectedClientFormControl.setValue(e.clt_id);
    }
    else {
      this.selectedclient =null;
      this.SelectedClientFormControl.setValue(null);
    }
    this.SelectedClientFormControl.updateValueAndValidity();
  }

  CreateAppointment(form:FormGroup)
  {
  if(this.CreateAppointmentForm.valid)
  {
    this.disableSaveBtn= true;
    let createAppointment: CreateAppointmentRequest = {} as CreateAppointmentRequest;
    let createRecurringAppointment:CreateRecurringAppointmentRequest ={} as CreateRecurringAppointmentRequest;
    
    createAppointment.appointment_type_id= this.selectedApptType.id;
    createAppointment.client_id=form.value.client_id;
    createAppointment.coach_id= form.value.selectedTrainer.id;
    createAppointment.comments= form.value.comments;
    createAppointment.is_virtual= this.isVirtal;
    createAppointment.virtual_url= form.value.virtualUrl;
    createAppointment.room_id= form.value.selectedRoom ? form.value.selectedRoom.id: 0,
    createAppointment.start_date_time= this.timeConversionService.GetUTCDateTimeByDateAndTime(form.value.startDate,form.value.startTime);
    createAppointment.subtype_id= this.selectedApptSubType ? this.selectedApptSubType.id : 0;
    createRecurringAppointment = createAppointment;
    if(this.RRuleObject.RRule)
    {
      createRecurringAppointment.recurring_rule = this.RRuleObject.RRule;
      createRecurringAppointment.recurring_start_date = createAppointment.start_date_time;
      createRecurringAppointment.start_time= this.timeConversionService.GetUTCTimeFromDate(form.value.startTime);
 
      this.appointmentService.CreateRecurringAppointment(this.tenantId, createRecurringAppointment).subscribe({
       next: async (response: any) => {
         this.translate
             .get('SCHEDULE.MSG.CREATE_RECURRING_APPT_MSG')
             .subscribe((translation) => {
               this.messageService.add({
                 severity: 'success',
                 summary: 'Success',
                 detail: translation,
               });
             });

             if(response.info && response.info.length > 0)
             {
               response.info.forEach((info: any) => {
                 this.translate.get('SCHEDULE.MSG.OVERLAP_MESSAGE',
                 { type:info.jsonObject.identifier,
                 startDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.startDateTime), 'medium'),
                 endDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.endDateTime),'medium')})
                 .subscribe((translation) => {
                  this.messageService.add({
                    severity: 'warn',
                    summary: 'warn',
                    detail: translation,
                  });
                });
               })
             }
             this.reloadData.emit(true);
             await this.timeout(1000);
             this.hideBookAppointment.emit(false);
       },
       error: (error) => {
        this.disableSaveBtn= false;
        this.handleError(error);
       },
     });
    }
    else
    {
     this.appointmentService.CreateAppointment(this.tenantId,createAppointment).subscribe({
       next: async (response: any) => {
        this.translate.get('SCHEDULE.MSG.CREATE_APPT_MSG').subscribe((translation) => {
               this.messageService.add({
                 severity: 'success',
                 summary: 'Success',
                 detail: translation,
               });
           });

           if(response.info && response.info[0])
          {
            let info = response.info[0];
            this.translate.get('SCHEDULE.MSG.OVERLAP_MESSAGE',
              { type:info.jsonObject.identifier,
              startDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.startDateTime), 'medium'),
              endDate: this.timeConversionService.formatDate(this.timeConversionService.ConvertUTCToLocalDateTime(info.jsonObject.endDateTime),'medium')})
              .subscribe((translation) => {
              this.messageService.add({
                severity: 'warn',
                summary: 'warn',
                detail: translation,
              });
            });
          }
          this.reloadData.emit(true);
           await this.timeout(1000);
           this.hideBookAppointment.emit(false);
       },
       error: (error) => {
        this.disableSaveBtn= false;
        this.handleError(error);
       },
     });
    }
  }
  else{
    Object.keys(this.CreateAppointmentForm.controls).forEach((field) => {
      const control = this.CreateAppointmentForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  }

  
  handleError(error: any) {
    this.error.next(error);
    this.showErrorPopup$.next(true);
  }

  timeout(ms:any  ) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  HideErrorPopup(event: any) {
    this.showErrorPopup$?.next(false);
    this.errorMessages = [];
  }

  GoToAppointment()
  {
    this.router.navigate(['/admin/appointment-categories/create-appointment-category']);
  }


  ClosePopup() {
    this.showBookAppointment = false;
    this.CreateAppointmentForm.reset();
    this.hideBookAppointment.emit(this.showBookAppointment);
  }

  get SelectedAssignedFormControl(): FormControl {
    return this.CreateAppointmentForm.controls.selectedTrainer as FormControl;
  }

  get GetSelectedRoom(): any {
    return this.CreateAppointmentForm.controls.selectedRoom.value;
  }

  get StartDateFormControl(): FormControl {
    return this.CreateAppointmentForm.controls.startDate as FormControl;
  }

  get StartTimeFormControl(): FormControl {
    return this.CreateAppointmentForm.controls.startTime as FormControl;
  }

  get SelectedRoomFormControl(): FormControl {
    return this.CreateAppointmentForm.controls.selectedRoom as FormControl;
  }

  get VirtualUrlFormControl(): FormControl {
    return this.CreateAppointmentForm.controls.virtualUrl as FormControl;
  }

  get SelectedClientFormControl(): FormControl {
    return this.CreateAppointmentForm.controls.client_id as FormControl;
  }

  get OStaffStatus(): Ienum<StaffStatus>[] {
    return Object.entries(StaffStatus).filter(key => typeof key[1] != 'number').map(([key, value]) => ({ key, value }));
  }
  
}


@NgModule({
  declarations: [BookAppointmentComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    InputTextareaModule,
    DropdownModule,
    FormsModule,
    DividerModule,
    AvatarModule,
    StyleClassModule,
    FormsModule,
    ReactiveFormsModule,
    RruleComponentModule,
    MiniSearchComponentModule,
    AssignedToComponentModule,
    ErrorsComponentModule,
    ErrorMsgComponentModule,
    ToastModule,
    MessagesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    DatePipe,
  ],
  exports: [BookAppointmentComponent]
})
export class BookAppointmentComponentModule {
}


