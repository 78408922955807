import { CommonModule } from '@angular/common';
import {
  Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, ViewChild, TemplateRef,
} from '@angular/core';
import { AppErrors, ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { COMPANY_INFO, ROUTER_STATE, STAGE_MASTER_STATE, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { InplaceModule } from 'primeng/inplace';
import { CONSTANTS_CRM } from '@fitness-force/constants';
import { ErrorsComponentModule, FactoryHttpLoader, LostDealComponentModule, TaskOpportunityInfoComponentModule } from '@fitness-force/shared';
import { BillingComponentModule } from '../../billing/billing/billing.component';
import { CurrencyformatPipeModule } from '../../../filters/currencyformat.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StyleClassModule } from 'primeng/styleclass';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CreateCallOutcomeRequest, CreateFollowupTaskByDateRequest, CreateLogCallNotesRequest, CreateTourRequest, PlanSummary, PlanSummaryClient, PlanSummaryCRM, STAGE_MASTER_MODEL, TENANTSINTERFACE, ValidationError } from '@fitness-force/models';
import { CallOutcome, OpportunityBoardType } from '@fitness-force/enum';
import * as moment from 'moment';
import { TaskCallOutcomeService } from 'libs/api/src/lib/crm/taskoutcome.service';
import { ClientService, OpportunityService, TimeconversionService } from '@fitness-force/api';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';



@Component({
  selector: 'app-call-outcome',
  templateUrl: './call-outcome.component.html',
  styleUrls: ['./call-outcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],

})
export class CallOutcomeComponent implements OnInit {

  getAccessTenantList$: Observable<any> | any;
  readonly truevalue: boolean = true;
  tenantId: number = 0;

  errMsg: Error | any;
  errorMessages = [] as any;
  showErrorPopup = new BehaviorSubject(false);
  errorDataModal = [] as ValidationError[];
  errorDataModalSubject = new BehaviorSubject([] as ValidationError[]);

  @Input('visible') showCallOutcome: boolean = false;
  @Input('data') modelData: any;

  @Output() hideOutCome: EventEmitter<any> = new EventEmitter();
  @Output() modified: EventEmitter<any> = new EventEmitter();

  @ViewChild('noanswer') noanswer: TemplateRef<any> | any;
  @ViewChild('contactmade') contactmade: TemplateRef<any> | any;
  @ViewChild('booktour') booktour: TemplateRef<any> | any;
  @ViewChild('notintrested') notintrested: TemplateRef<any> | any;

  public companyInfoDetail$!: Observable<any>;
  companyDetails: any;

  stageMaster$: Observable<any> = new Subject();
  frmCallOutComeNoAnswer: FormGroup | any;
  frmCallOutcomeContactmade: FormGroup | any;
  frmCallOutcomeBookTour: FormGroup | any;
  frmCallOutNotInterested: FormGroup | any;

  convertibility: any = CONSTANTS_CRM.OPPORTUNITY_CONVERTITBILITY;

  selectedConvertibility!: any;
  outcome: any[];
  values: string[] | undefined;
  followUpDate: Date | undefined;
  contractEndDate: Date | undefined;
  mylookupservice: any;
  hideOnOutsideClick: any;
  selectedOutcome!: any;


  selectedOutcomeTemplate: any;
  selectTime!: string;
  markcompletedchecked: boolean = false;
  value: boolean = false;
  taskId: any;

  //#region billing sidebar
  planSummarydataObject$: BehaviorSubject<PlanSummary> = new BehaviorSubject({} as PlanSummary);
  showPaidFullSidebar: boolean = false;
  //#endregion billing sidebar

  showChooseReason: boolean = false;
  showLostDealDialog = false;
  lostDealItemInfo = '';


  constructor(
    public store: Store,
    public translate: TranslateService,
    private clientService: ClientService,
    private fb: FormBuilder,
    private callOutcomeService: TaskCallOutcomeService,
    private timeService: TimeconversionService,
    private messageService: MessageService,
    private opportunityService: OpportunityService,
  ) {
    this.showErrorPopup.next(false);
  }

  translateOutComeOption(): void {
    this.outcome = CONSTANTS_CRM.CALL_OUTCOME;
    this.translate.get([
      'OUTCOME.CALLOUTCOME.NO_ANSWER_LEFT_MESSAGE',
      'OUTCOME.CALLOUTCOME.CONTACT_MADE',
      'OUTCOME.CALLOUTCOME.NOT_INTERESTED',
      'OUTCOME.CALLOUTCOME.BOOK_TOUR',
      'OUTCOME.CALLOUTCOME.SIGN_UP'
    ]).subscribe((translation) => {
      this.outcome.forEach((obj: any) => {
        switch (String(obj.shortname).toLowerCase()) {
          case "noanswer":
            obj.name = translation['OUTCOME.CALLOUTCOME.NO_ANSWER_LEFT_MESSAGE'];
            break;
          case "contactmade":
            obj.name = translation['OUTCOME.CALLOUTCOME.CONTACT_MADE'];
            break;
          case "notintrested":
            obj.name = translation['OUTCOME.CALLOUTCOME.NOT_INTERESTED'];
            break;
          case "booktour":
            obj.name = translation['OUTCOME.CALLOUTCOME.BOOK_TOUR'];
            break;
          case "signup":
            obj.name = translation['OUTCOME.CALLOUTCOME.SIGN_UP'];
            break;
          default:
            console.log(obj);
            break;
        }
      });

    });
  }

  ngOnInit(): void {
    this.translateOutComeOption();
    this.componentInit();
    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' });
      }
    });

    this.frmCallOutComeNoAnswer = this.fb.group({
      noanswer_expecteddate: new FormControl('', [Validators.required]),
      noanswer_expectedtime: new FormControl('', [Validators.required]),
      noanswer_notes: new FormControl(''),
      noanswer_markascomplete: new FormControl(this.markcompletedchecked, [Validators.required])
    });

    this.frmCallOutcomeContactmade = this.fb.group({
      contactmade_notes: new FormControl('', [Validators.required]),
      contactmade_expecteddate: new FormControl('', [Validators.required]),
      contactmade_expectedtime: new FormControl('', [Validators.required]),
    });

    this.frmCallOutNotInterested = this.fb.group({
      notintrested_markcompleted: new FormControl(''),
      notintrested_notes: new FormControl('', [Validators.required]),
    });


    this.frmCallOutcomeBookTour = this.fb.group({
      booktour_expecteddate: new FormControl('', [Validators.required]),
      booktour_expectedtime: new FormControl('', [Validators.required]),
      booktour_notes: new FormControl('', [Validators.required])
    });
    this.stageMaster$ = this.store.select(STAGE_MASTER_STATE);
  }

  componentInit() {
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO);
    this.companyInfoDetail$.subscribe((responseData) => {
      this.companyDetails = responseData;
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' })
      }
    })
  }



  //#region  "generic"
  setHidePopup(modified: boolean = false) {
    this.clearAllFields();
    this.showCallOutcome = false;
    this.hideOutCome.emit({ showPopup: this.showCallOutcome, modified });
  }



  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
  }

  get ShowErrorPopup(): Observable<boolean> {
    return this.showErrorPopup?.asObservable() || of(false);
  }

  get getErrorData(): Observable<ValidationError[]> {

    return of(this.errorDataModalSubject.getValue());
    //return this.errorDataModalSubject.asObservable() || of([] as ValidationError[]);
  }

  SelectOutcome(item: any) {

    this.selectedOutcome = item;

    if (item.shortname == 'noanswer') { this.selectedOutcomeTemplate = this.noanswer; }
    if (item.shortname == 'contactmade') this.selectedOutcomeTemplate = this.contactmade;
    if (item.shortname == 'notintrested') this.selectedOutcomeTemplate = this.notintrested;
    if (item.shortname == 'booktour') this.selectedOutcomeTemplate = this.booktour;
    if (item.shortname == 'signup') {
      // this.selectedOutcomeTemplate = this.signupform;
      let draggedProduct = this.modelData;
      let planSummary: PlanSummary = {
        id: 0,
        client: {
          client_id: draggedProduct.client_info.id,
          client_name: draggedProduct.client_info.full_name,
          client_status: draggedProduct.client_info.status,
        } as PlanSummaryClient,
        couponcode: null,
        tenant_id: this.tenantId,
        company_id: draggedProduct.company_id,
        startdate: new Date(),
        plan_selectable: true,
        CRM: {
          opp_id: draggedProduct.id,
          task_id: draggedProduct.latest_task?.id || 0,
          board_id: draggedProduct.board_type
        } as PlanSummaryCRM,
        Scheduling: null,
      } as PlanSummary;

      this.planSummarydataObject$.next(planSummary);
      this.showPaidFullSidebar = true;
    }
  }

  hideBillingSummary(event: any) {
    this.showPaidFullSidebar = false;
    this.planSummarydataObject$.next({} as PlanSummary);
    if (event.uuid && String(event.uuid).trim() != '') {
      this.setHidePopup();
    }
  }

  SelectOutcomeMob(item: any) {
    this.SelectOutcome(item.value);
  }

  get getoutcome(): any {
    return this.selectedOutcomeTemplate;
  }

  clearAllFields() {
    //NO ANSWER CLEAR DATA
    this.getNoAnswer_MarkasComplete.value = false;
    this.getNoAnswer_ExpectedDate.value = "";
    this.getNoAnswer_ExpectedTime.value = "";
    this.getNoAnswer_Notes.value = "";

    this.getBookTour_ExpectedDate.value = "";
    this.getBookTour_ExpectedTime.value = "";
    this.getBookTour_Notes.value = "";

    this.getContactMade_ExpectedDate.value = "";
    this.getContactMade_ExpectedTime.value = "";
    this.getContactMade_Notes.value = "";

    this.getNotInterested_Notes.value = "";

  }


  //#endregion

  //#region "No Answer / Left a message"

  CreateCallOutcomeNoAnswer(formdata: FormGroup) {
    this.frmCallOutComeNoAnswer.markAllAsTouched();

    if (!this.frmCallOutComeNoAnswer.valid) {
      return;
    }

    this.errorDataModalSubject = new BehaviorSubject([] as ValidationError[]);

    let createCallOutcomeRequest: CreateCallOutcomeRequest = {} as CreateCallOutcomeRequest;
    let logCall = {} as CreateLogCallNotesRequest;
    let reschedule_task = {} as CreateFollowupTaskByDateRequest;

    logCall.transaction_notes = this.getNoAnswer_Notes.value;
    logCall.transaction_log_date = new Date().toISOString();

    createCallOutcomeRequest.mark_as_complete = this.getNoAnswer_MarkasComplete.value;
    reschedule_task.scheduled_datetime = this.timeService.GetUTCDateTimeByDateAndTime(this.getNoAnswer_ExpectedDate.value, this.getNoAnswer_ExpectedTime.value);

    createCallOutcomeRequest.outcome = CallOutcome.NoAnswer_LeftMessage;
    createCallOutcomeRequest.log_call = logCall;
    if (!createCallOutcomeRequest.mark_as_complete)
      createCallOutcomeRequest.reschedule_task = reschedule_task;

    this.callOutcomeService
      .createTaskCallOutcome(createCallOutcomeRequest, this.tenantId, this.modelData.latest_task.id)
      .subscribe({
        next: async (data: any) => {
          if (data > 0) {
          }
          this.translate
            .get('common.message.record_updated')
            .subscribe((translation) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: translation, //'Scheduled Filter Deleted Successfully',
              });
            });

          await this.timeout(1000);
          this.selectedOutcome = null;
          this.selectedOutcomeTemplate = null;
          this.clearAllFields();
          this.setHidePopup(true);

        },
        error: (error: AppErrors) => {
          this.errorDataModal = [] as ValidationError[];
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
            this.errorMessages.push(error.originalError.error.errorMessage)
            this.showErrorPopup?.next(true);
          }
          else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error.errorMessage)
              this.errorDataModal = this.errorMessages;

            }
            else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage)
              });
              this.errorDataModal = this.errorMessages;
            }
            this.errorDataModalSubject.next(this.errorDataModal);
            this.showErrorPopup?.next(true);
          }
        },
      });

  }



  get getNoAnswer_MarkasComplete() {
    return this.frmCallOutComeNoAnswer.get('noanswer_markascomplete')
  }

  get getNoAnswer_ExpectedDate() {
    return this.frmCallOutComeNoAnswer.get('noanswer_expecteddate')
  }

  get getNoAnswer_ExpectedTime() {
    return this.frmCallOutComeNoAnswer.get('noanswer_expectedtime')
  }

  get getNoAnswer_Notes() {

    return this.frmCallOutComeNoAnswer.get('noanswer_notes')
  }

  setNoAnswer_MarkCompleteValidator() {
    if (this.frmCallOutComeNoAnswer != undefined && this.frmCallOutComeNoAnswer != null) {
      this.frmCallOutComeNoAnswer.get('noanswer_markascomplete').valueChanges
        .subscribe((value: boolean) => {
          if (value === true) {
            this.getNoAnswer_ExpectedDate.setValidators(null);
            this.getNoAnswer_ExpectedTime.setValidators(null);
          }
          else {
            this.getNoAnswer_ExpectedDate.setValidators([Validators.required]);
            this.getNoAnswer_ExpectedTime.setValidators([Validators.required]);
          }
        });
    }
  }

  toggleNoAnswer(isChecked: boolean) {
    this.markcompletedchecked = isChecked;
    if (isChecked) {
      this.noAnswerDisableControls()
    } else {
      this.noAnswerEnableControls()
    }
  }

  noAnswerDisableControls() {
    //this.Name.disable()
    this.getNoAnswer_ExpectedDate.setValue(null);
    this.getNoAnswer_ExpectedTime.setValue(null);

    this.getNoAnswer_ExpectedDate.disable();
    this.getNoAnswer_ExpectedTime.disable();

    this.getNoAnswer_ExpectedDate.setValidators([Validators.required]);
    this.getNoAnswer_ExpectedTime.setValidators([Validators.required]);

  }

  noAnswerEnableControls() {
    this.getNoAnswer_ExpectedDate.enable();
    this.getNoAnswer_ExpectedTime.enable();

    this.getNoAnswer_ExpectedDate.setValidators(null);
    this.getNoAnswer_ExpectedTime.setValidators(null);

  }

  //#endregion

  //#region "Call Outcome"
  CreateCallOutcomeContactMade(formdata: FormGroup) {
    this.frmCallOutcomeContactmade.markAllAsTouched();
    if (!this.frmCallOutcomeContactmade.valid) {
      return;
    }



    let createCallOutcomeRequest: CreateCallOutcomeRequest = {} as CreateCallOutcomeRequest;
    let logCall = {} as CreateLogCallNotesRequest;
    let reschedule_task = {} as CreateFollowupTaskByDateRequest;


    logCall.transaction_notes = this.getContactMade_Notes.value;
    logCall.transaction_log_date = new Date().toISOString();

    reschedule_task.scheduled_datetime = this.timeService.GetUTCDateTimeByDateAndTime(this.getContactMade_ExpectedDate.value, this.getContactMade_ExpectedTime.value);

    //createCallOutcomeRequest.mark_as_complete = this.getNoAnswer_MarkasComplete.value;
    createCallOutcomeRequest.outcome = CallOutcome.ContactMade;
    createCallOutcomeRequest.log_call = logCall;
    createCallOutcomeRequest.reschedule_task = reschedule_task;

    this.callOutcomeService
      .createTaskCallOutcome(createCallOutcomeRequest, this.tenantId, this.modelData.latest_task.id)
      .subscribe({
        next: async (data: any) => {
          if (data > 0) {
          }
          this.translate
            .get('common.message.record_updated')
            .subscribe((translation) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: translation, //'Scheduled Filter Deleted Successfully',
              });
            });

          await this.timeout(1000);

          this.clearAllFields();
          this.setHidePopup(true);
          this.selectedOutcome = null;
          this.selectedOutcomeTemplate = null;
        },
        error: (error: AppErrors) => {
          this.errorDataModal = [] as ValidationError[];
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
            this.errorMessages.push(error.originalError.error.errorMessage)
            this.showErrorPopup?.next(true);
          }
          else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error.errorMessage)
              this.errorDataModal = this.errorMessages;

            }
            else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage)
              });
              this.errorDataModal = this.errorMessages;
            }
            this.showErrorPopup?.next(true);
          }
        },
      });
    this.errorDataModalSubject.next(this.errorDataModal);
  }

  get getContactMade_Notes() {
    return this.frmCallOutcomeContactmade.get('contactmade_notes')
  }

  get getContactMade_ExpectedDate() {
    return this.frmCallOutcomeContactmade.get('contactmade_expecteddate')
  }

  get getContactMade_ExpectedTime() {
    return this.frmCallOutcomeContactmade.get('contactmade_expectedtime')
  }

  //#endregion

  //#region "NotInterested"


  CreateNotInteresredNotInterested(formdata: FormGroup) {
    this.frmCallOutNotInterested.markAllAsTouched();
    if (!this.frmCallOutNotInterested.valid) {
      return;
    }

    let createCallOutcomeRequest: CreateCallOutcomeRequest = {} as CreateCallOutcomeRequest;
    let logCall = {} as CreateLogCallNotesRequest;


    logCall.transaction_notes = this.getNotInterested_Notes.value;
    logCall.transaction_log_date = new Date().toISOString();

    createCallOutcomeRequest.mark_as_complete = this.getNotInterested_MarkCompleted.value;
    createCallOutcomeRequest.outcome = CallOutcome.NotIntrested;
    createCallOutcomeRequest.log_call = logCall;

    this.callOutcomeService
      .createTaskCallOutcome(createCallOutcomeRequest, this.tenantId, this.modelData.latest_task.id)
      .subscribe({
        next: (data: any) => {
          if (data > 0) {
          }
          this.translate
            .get('common.message.record_updated')
            .subscribe((translation) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: translation, //'Scheduled Filter Deleted Successfully',
              });
            });
          // open lost deal only if it is opportunity
          if (this.modelData.stage_master_id) {
            this.OpenLostDeal(this.modelData);
          }
          this.clearAllFields();
          //this.setHidePopup(true);
          this.selectedOutcome = null;
          this.selectedOutcomeTemplate = null;
        },
        error: (error: AppErrors) => {
          this.errorDataModal = [] as ValidationError[];
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
            this.errorMessages.push(error.originalError.error.errorMessage)
            this.showErrorPopup?.next(true);
          }
          else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error.errorMessage)
              this.errorDataModal = this.errorMessages;

            }
            else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage)
              });
              this.errorDataModal = this.errorMessages;
            }
            this.showErrorPopup?.next(true);
          }
        },
      });
    this.errorDataModalSubject.next(this.errorDataModal);
  }

  get getNotInterested_Notes() {
    return this.frmCallOutNotInterested.get('notintrested_notes')
  }

  get getNotInterested_MarkCompleted() {
    return this.frmCallOutNotInterested.get('notintrested_markcompleted')
  }


  //OPEN LOSTCOMPOENT
  //OpportunityGetService(tenantId: number, boardType: OpportunityBoardType, oppId: number): Observable<Response> {

  //#endregion

  //#region  "BookTour"


  CreateCallOutcomeBookTour(formdata: FormGroup) {
    this.frmCallOutcomeBookTour.markAllAsTouched();

    if (!this.frmCallOutcomeBookTour.valid) {
      return;
    }

    let createCallOutcomeRequest: CreateCallOutcomeRequest = {} as CreateCallOutcomeRequest;
    let logCall = {} as CreateLogCallNotesRequest;



    logCall.transaction_notes = this.getBookTour_Notes.value;
    logCall.transaction_log_date = new Date().toISOString();

    let book_tour = {} as CreateTourRequest;
    book_tour.scheduled_datetime = this.timeService.GetUTCDateTimeByDateAndTime(this.getBookTour_ExpectedDate.value, this.getBookTour_ExpectedTime.value);
    book_tour.assigned_to_staff_id = this.modelData.assigned_to_staff_id;

    //createCallOutcomeRequest.mark_as_complete = this.getNoAnswer_MarkasComplete.value;
    createCallOutcomeRequest.outcome = CallOutcome.BookTour;
    createCallOutcomeRequest.log_call = logCall;

    createCallOutcomeRequest.book_tour = book_tour;
    createCallOutcomeRequest.mark_as_complete = true;
    this.callOutcomeService
      .createTaskCallOutcome(createCallOutcomeRequest, this.tenantId, this.modelData.latest_task.id)
      .subscribe({
        next: async (data: any) => {
          if (data > 0) {
          }
          this.translate
            .get('common.message.record_updated')
            .subscribe((translation) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: translation, //'Scheduled Filter Deleted Successfully',
              });
            });

          await this.timeout(1000);
          this.lostDealItemInfo = this.modelData;
          this.clearAllFields();
          this.setHidePopup(true);
          this.selectedOutcome = null;
          this.selectedOutcomeTemplate = null;
        },
        error: (error: AppErrors) => {
          this.errorDataModal = [] as ValidationError[];
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
            this.errorMessages.push(error.originalError.error.errorMessage)
            this.showErrorPopup?.next(true);
          }
          else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error.errorMessage)
              this.errorDataModal = this.errorMessages;

            }
            else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage)
              });
              this.errorDataModal = this.errorMessages;
            }
            this.showErrorPopup?.next(true);
          }
        },
      });
    this.errorDataModalSubject.next(this.errorDataModal);
  }

  get getBookTour_ExpectedDate() {
    return this.frmCallOutcomeBookTour.get('booktour_expecteddate')
  }

  get getBookTour_ExpectedTime() {
    return this.frmCallOutcomeBookTour.get('booktour_expectedtime')
  }

  get getBookTour_Notes() {
    return this.frmCallOutcomeBookTour.get('booktour_notes')
  }

  GetErrorData(event: any) {
    this.errorMessages = event.errors;
    this.showErrorPopup?.next(event.showErrors);
  }


  GetUpdatedData(event: any) {
    this.modelData = event;
    this.modified.emit(true)
  }

  closeDailog() {
    this.showChooseReason = false;
    this.showLostDealDialog = false;
    this.clearAllFields();
    this.setHidePopup(true);
  }

  OpenLostDeal(modatData: any) {
    this.showChooseReason = true;
    this.lostDealItemInfo = modatData;
  }

  clientStatus(client: any): string {
    if (client && client.status)
      return this.clientService.getClientStatusHtml(client.status.toString());
    else
      return '';
  }

  getStageStatus(stageMasterid: number): Observable<string | undefined> {
    return this.store.select(STAGE_MASTER_STATE).pipe(
      map(
        (value: STAGE_MASTER_MODEL[]) =>
          value.find(obj => obj.id == stageMasterid)?.name_primary_language

      )
    );
  }

  timeout(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}


@NgModule({
  declarations: [CallOutcomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ChipsModule,
    ChipModule,
    InplaceModule,
    ReactiveFormsModule,
    TaskOpportunityInfoComponentModule,
    CalendarModule,
    InputSwitchModule,
    CalendarModule,
    StyleClassModule,
    ErrorMsgComponentModule,
    ErrorsComponentModule,
    ToastModule,
    MessagesModule,
    CurrencyformatPipeModule,
    BillingComponentModule,
    LostDealComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [CallOutcomeComponent]
})

export class CallOutcomeComponentModule {
}
