import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthSevices } from 'libs/api/src/lib/auth.service';
import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FETCHED_COMPANY_DETAILS, SELECT_LANGUAGE, SET_CLAIMS_DETAILS } from './state/actions';
import { COMPANY_INFO } from './state/app.state';
import { CompanyInfoStoreInterface } from '@fitness-force/models';
import { Observable, Subscription } from 'rxjs';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { LocationService } from '@fitness-force/api';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NgxHotjarService } from 'ngx-hotjar';


@Component({
  selector: 'fitness-force-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ff-frontend';
  public companyInfoDetail$: Observable<any> | any;
  subscription!: Subscription;
  constructor(
    public store: Store<CompanyInfoStoreInterface>,
    private authService: AuthSevices,
    private router: Router,
    private actRoute: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    // commented could be used as Example here https://github.com/maxandriani/ngx-hotjar/
   // public hjService: NgxHotjarService,

  ) { }
  ngOnInit(): void {
    this.componentInit();
  }

  componentInit() {
    this.store.dispatch({ type: 'FETCHED_COMPANY_DETAILS' });
    this.companyInfoDetail$ = this.store.select(COMPANY_INFO)
    if (this.authService.isLoginedIn) {
      this.store.dispatch(SET_CLAIMS_DETAILS({ claims: this.authService.parseDecodedToken }));
      this.store.dispatch({ type: 'FETCHED_TENANT_LIST' });
    }
      // push GTM data layer for every visited page
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };

          this.gtmService.pushTag(gtmTag);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
  //FETCHED_COMPANY_DETAILS
}
