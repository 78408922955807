


  <ng-container *ngIf="{
    row: columnData | async
  } as $">
  <div class="flex align-items-center cursor-pointer" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein"
  leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true"
  >

  <div class="text-gray-300"  *ngIf="getStaffDetails($.row.assigned_to_staff_id) && getStaffDetails($.row.assigned_to_staff_id)?.ff_picture; else noContactPic">
    <p-avatar image="{{getStaffDetails($.row.assigned_to_staff_id).ff_picture}}"></p-avatar>
  </div>
  <ng-template #noContactPic>
    <p-avatar styleClass="text-sm" *ngIf="getStaffDetails($.row.assigned_to_staff_id)" label="{{getStaffDetails($.row.assigned_to_staff_id)?.name | titlecase | shortName}}"
      ></p-avatar>
  </ng-template>

  <span class="text-primary white-space-nowrap ml-2" *ngIf="getStaffDetails($.row.assigned_to_staff_id)">{{getStaffDetails($.row.assigned_to_staff_id)?.name  | titlecase}}</span>
</div>
<div *ngIf="!getStaffDetails($.row.assigned_to_staff_id)?.name ">-</div>

<!-- Dropdown -->
<div class="border-round shadow-2 absolute text-600 hidden origin-top w-22rem left-0 top-0 mt-6 z-2">

  <!-- Dropdown Content -->
<div class="bg-white -mx-4 -mt-4 flex align-items-start justify-content-between px-3 py-2">
  <div class="flex mt-2 w-10rem line-height-1">
    <span class="text-base font-medium text-800">{{getStaffDetails($.row.assigned_to_staff_id)?.name  | titlecase}}</span>
    <span class="text-xs text-600 ml-1 mt-1">{{getStaffDetails($.row.assigned_to_staff_id)?.id}}</span>
  </div>
  <span class="bg-green-500 px-2 py-1 font-bold border-round text-white text-xxs uppercase ml-4 mt-1">{{getStaffDetails($.row.assigned_to_staff_id)?.designation}}</span>
</div>
<!-- Dropdown Content -->

   <!-- Dropdown Footer -->
<div class="px-3 py-2 -mx-4 -mb-4 bg-gray-200 text-800 text-xs" *ngIf="$.row && $.row.client_representative_id &&  $.staff">
  <div class="flex align-items-center mb-2">
    <i class="pi pi-phone mr-2 text-gray-500"></i>
    <span *ngIf="getStaffDetails($.row.assigned_to_staff_id)?.mobile_number != 0"> {{getStaffDetails($.row.assigned_to_staff_id)?.mobile_country_code}}  {{getStaffDetails($.row.assigned_to_staff_id)?.mobile_number}} </span>
     <span *ngIf="getStaffDetails($.row.assigned_to_staff_id)?.mobile_number == 0">NA</span>
  </div>
  <div class="flex align-items-center mb-1">
    <i class="pi pi-envelope mr-2 text-gray-500"></i>
    <span class="break-word">{{getStaffDetails($.row.assigned_to_staff_id)?.email_id}}</span>
  </div>
</div>
<!-- Dropdown Footer -->

 

</div>
<!-- Dropdown -->
</ng-container>
