<ng-container *ngIf="{
  row: columnData | async,
  adata: additionData | async
} as $">

  <div class="relative w-6rem h-2rem flex align-items-center justify-content-center"
    *ngIf="$.row && $.row.oppportunity_info ">
    <img class="absolute w-6rem h-2rem" src="/assets/img/icons/opportunity-stage-bg.svg" />
    <div></div>
    <span *ngIf="$.row && $.adata && $.row.oppportunity_info "
      class="relative z1 text-xs text-primary font-bold w-5rem white-space-nowrap text-overflow-ellipsis overflow-hidden block">{{parseOpportunity($.row.oppportunity_info.stage_master_id,
      $.adata)?.name_primary_language}}</span>
  </div>
  <small class="text-xxs block"
    *ngIf="$.row && $.adata && $.row.oppportunity_info ">{{$.row.oppportunity_info.board_type}}</small>
  <div *ngIf="$.row && !$.row.oppportunity_info ">-</div>
</ng-container>