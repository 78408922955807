import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { PaymentGatewaySupported } from '@fitness-force/enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'razorpay',
  templateUrl: './razorpay.component.html',
  styleUrls: ['./razorpay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RazorpayComponent implements OnInit {
  @Input('visible') showpg$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
  @Input('transactionId') internalTransactionId: string = "";
  @Input() orderId: string = "";
  @Input() customerId: string = "";
  @Input('uuid') uuid: string = "";

  constructor(private _ngZone: NgZone, private router: Router) {
    //this.loadScript();
  }

  ngOnInit(): void {
    let oThis = this;
    setTimeout(() => {
      const Razorpay = (window as any).Razorpay
      var options = {
        "key": CONSTANTS_DATA.PAYMENT_GATEWAY_VENDOR_KEY.RAZORPAY.partnerKey,
        "order_id": this.orderId,
        "customer_id": this.customerId,
        "account_id": CONSTANTS_DATA.PAYMENT_GATEWAY_VENDOR_KEY.RAZORPAY.accountId,
        "recurring": "1",
        "handler": function (response: any) {
          oThis.redirectTo(response.razorpay_order_id, response.razorpay_payment_id);
        },
        "notes": {
          /* We need to plan */
          // "note_key 1": "Beam me up Scotty",
          // "note_key 2": "Tea. Earl Gray. Hot."
        },
        "theme": {
          "color": CONSTANTS_DATA.PAYMENT_GATEWAY_VENDOR_KEY.RAZORPAY.themeColor
        }
      };

      var rzp1 = new Razorpay(options);
      rzp1.open();
    }, 200)
  }

  redirectTo(orderid: string, externalTransactionId: string): void {
    // step 2: process payment from partner backend
    this._ngZone.run(() => {
      this.router.navigate(['/' + this.uuid + '/payment_callback'], {
        queryParams: {
          pg: PaymentGatewaySupported.RazorpayIndia,
          orderid: orderid,
          externaltransactionid: externalTransactionId,
          internaltransactionid: this.internalTransactionId,
        },
      });
    });
  }

}

@NgModule({
  declarations: [RazorpayComponent],
  imports: [],
  exports: [RazorpayComponent]
})
export class RazorpayComponentModule {
}
