import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'date-formated',
  templateUrl: './date-formated.component.html',
  styleUrls: ['./date-formated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFormatedComponent implements OnInit {

  @Input() columnData = new BehaviorSubject(null);
  @Input() additionData = new BehaviorSubject(null);
  keyName!: string ;
  value!:number;
  format!:string;
  constructor() {}

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [DateFormatedComponent],
  imports: [CommonModule],
  exports: [DateFormatedComponent]
})
export class DateFormatedComponentModule {
}


