import { getCurrencySymbol } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Country, RelativeDate, ReportFormBase } from '@fitness-force/models';
import { Store } from '@ngrx/store';
import { COMPANY_INFO, COUNTRY_MASTER_STATE } from 'apps/ff-frontend/src/app/state/app.state';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private timePeriod: any;
  miner_currency = new BehaviorSubject(0);

  constructor( public store: Store,) {
    this.store.select(COUNTRY_MASTER_STATE).subscribe((country:Country) => {
      this.miner_currency.next(country.miner_currency);
    });
  }

  GetRelativeFromDate(date: Date, relativeDate: RelativeDate, day: number) {
    const relativeDates: any = { from_date: Date, to_date: Date };
    switch (relativeDate) {
      case RelativeDate.Today: {
        relativeDates.from_date = new Date();
        relativeDates.to_date = new Date();
        return relativeDates;
      }

      case RelativeDate.Tomorrow: {
        {
          relativeDates.from_date = new Date(
            date.setDate(date.getDate() + day)
          );
          relativeDates.to_date = relativeDates.from_date;
          return relativeDates;
        }
      }
      case RelativeDate.ThisMonth: {
        relativeDates.from_date = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        relativeDates.to_date = new Date();
        return relativeDates;
      }
      case RelativeDate.ThisQuarter: {
        var quarter = Math.floor((date.getMonth() / 3));
        var firstDate = new Date(date.getFullYear(), quarter * 3, 1);
        var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
        relativeDates.from_date = firstDate;
        relativeDates.to_date = endDate;
        return relativeDates;
      }
      case RelativeDate.Yesterday: {
        relativeDates.from_date = new Date(date.setDate(date.getDate() + day));
        relativeDates.to_date = relativeDates.from_date;
        return relativeDates;
      }
      case RelativeDate.LastSevenDays: {
        relativeDates.from_date = new Date(date.setDate(date.getDate() + day));
        relativeDates.to_date = new Date();
        return relativeDates;
      }
      case RelativeDate.LastMonth: {
        relativeDates.from_date = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        relativeDates.to_date = new Date(
          new Date(date.getFullYear(), date.getMonth(), 1).setDate(
            relativeDates.from_date.getDate() - 1
          )
        );
        return relativeDates;
      }
      case RelativeDate.LastThirtyDays: {
        relativeDates.from_date = new Date(date.setDate(date.getDate() + day));
        relativeDates.to_date = new Date();
        return relativeDates;
      }
      case RelativeDate.CustomDateRange: {
        relativeDates.from_date = new Date();
        relativeDates.to_date = new Date();
        return relativeDates;
      }
      default:
        return date;
    }
  }

  GetRelativeToDate(date: Date, relativeDate: RelativeDate, day: number) {
    switch (relativeDate) {
      case RelativeDate.Today:
        return new Date();
      case RelativeDate.Tomorrow: {
        return date.setDate(date.getDate() + day);
      }
      case RelativeDate.ThisMonth: {
        return new Date();
      }
      case RelativeDate.ThisQuarter:
        return new Date();
      case RelativeDate.Yesterday: {
        return date.setDate(date.getDate() + day);
      }
      case RelativeDate.LastSevenDays: {
        return date;
      }
      case RelativeDate.LastMonth: {
        let lastMonth: Date = new Date(date.getFullYear(), date.getMonth(), 1);
        return lastMonth.setDate(lastMonth.getDate() - 1);
      }
      case RelativeDate.LastThirtyDays: {
        return date;
      }
      case RelativeDate.CustomDateRange: {
        return new Date();
      }
      default:
        return date;
    }
  }

  toFormGroup(fields: ReportFormBase<string>[]) {
    const group: any = {};
    fields.forEach((field) => {
      group[field.key] = field.required
        ? new FormControl(field.value || '', Validators.required)
        : new FormControl(field.value || '');
    });
    return new FormGroup(group);
  }

  GetTimePeriods() {
    return (this.timePeriod = [
      {
        name: 'Yesterday',
        label: 'Yesterday',
        value: -1,
        date: this.GetRelativeFromDate(new Date(), RelativeDate.Yesterday, -1),
      },
      {
        name: 'Today',
        label: 'Today',
        value: 0,
        date: this.GetRelativeFromDate(new Date(), RelativeDate.Today, 0),
      },
      {
        name: 'Tomorrow',
        label: 'Tomorrow',
        value: 1,
        date: this.GetRelativeFromDate(new Date(), RelativeDate.Tomorrow, 1),
      },
      {
        name: 'ThisMonth',
        label: 'This Month',
        value: 3,
        date: this.GetRelativeFromDate(new Date(), RelativeDate.ThisMonth, 0),
      },
      {
        name: 'ThisQuarter',
        label: ' This Quarter',
        value: 33,
        date: this.GetRelativeFromDate(new Date(), RelativeDate.ThisQuarter, 1),
      },
      {
        name: 'LastSevenDays',
        label: 'Last SevenDays',
        value: -7,
        date: this.GetRelativeFromDate(
          new Date(),
          RelativeDate.LastSevenDays,
          -7
        ),
      },

      {
        name: 'LastThirtyDays',
        label: 'Last Thirty Days',
        value: -30,
        date: this.GetRelativeFromDate(
          new Date(),
          RelativeDate.LastThirtyDays,
          -30
        ),
      },
      {
        name: 'LastMonth',
        label: 'Last Month',
        value: -3,
        date: this.GetRelativeFromDate(new Date(), RelativeDate.LastMonth, 0),
      },
      {
        name: 'CustomDateRange',
        label: 'Custom DateRange',
        value: 99,
        date: { from_date: new Date(), to_date: new Date() },
      },
    ]);
  }

  // Convert minur currency amount in currency amount
  ConvertAmountFromMinorCurrency(currencyAmount:number){
    let currencyMultiplier = Math.pow(10, this.miner_currency.value);
    currencyAmount = (currencyAmount / currencyMultiplier);
    return currencyAmount;
  }

  // Convert currency amount in  minur currency amount
  ConvertMinorCurrencyFromAmount(currencyAmount:number){
    let currencyMultiplier = Math.pow(10, this.miner_currency.value);
    //currencyAmount = Math.floor(currencyAmount * currencyMultiplier);
    // Changed for 79.99 gets converted to 79.98, ceil working fine
    currencyAmount = Math.ceil(currencyAmount * currencyMultiplier);
    return currencyAmount;
  }


}
