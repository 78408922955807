<div [formGroup]="formGroup">
    <p-dropdown [options]="taskType" optionLabel="name" placeholder="{{placeHolder}}" styleClass="{{styleClass}}"
        [formControl]="controlName">
        <ng-template pTemplate="SelectedTaskType">
            <div class="task-item task-item-value flex align-items-center text-sm" *ngIf="SelectedTaskType.value">
                <i class="mr-3 {{SelectedTaskType.icon}}"></i>
                <div class="text-sm">{{SelectedTaskType.name}}</div>
            </div>
        </ng-template>
        <ng-template let-tasktype pTemplate="item">
            <div class="task-item flex align-items-center">
                <i class="mr-3 {{tasktype.icon}}"></i>
                <div class="text-sm">{{tasktype.name}}</div>
            </div>
        </ng-template>
    </p-dropdown>
</div>