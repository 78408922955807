<p-dialog [(visible)]="createSmsModal" [resizable]="false" appendTo="body" [modal]="true" [closeOnEscape]="true" (onHide)="closeDialogEvent()"
[breakpoints]="{'991px': '100vw'}" [style]="{width: '870px'}" styleClass="no-header-modal overflow-visible">

<ng-template pTemplate="header" styleClass="hidden"></ng-template>
<ng-template pTemplate="content" styleClass="p-0">
  <ng-container *ngIf="{
    client: allClientData$ | async,
    tenants: getAccessTenantList$ | async,
    smsTemplate: allSmsTemplates$ | async
  } as $">

  <!-- Header -->
  <div class="py-3 px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
    <h2 class="mb-0 text-base md:text-lg font-normal">{{'SMS.CREATE_SMS_FOR' | translate}} <strong>{{$.client.full_name}}</strong></h2>
    <div class="pi pi-times text-gray-500 cursor-pointer" (click)="closeDialogEvent()"></div>
  </div>
  <!-- Header -->
  <form [formGroup]="smsFormGroup" (ngSubmit)="sendSms()">
  <!-- Content -->
  <div class="p-3 md:p-4">

    <!-- Composer -->
    <div class="bg-white shadow-3 border-round relative text-sm">

      <div class="px-3 md:px-4 py-3">

      <!-- From -->
      <div class="flex align-items-center">
        <span class="mr-2 w-3rem">{{'SMS.FROM' | translate}}</span>
        <strong>{{$.tenants?.selected_tenant?.name_primary_language}}, {{$.tenants?.selected_tenant?.city}}</strong>
      </div>
      <!-- From -->

      <!-- To -->
      <div class="flex align-items-center my-3 border-bottom-1 pb-3 border-gray-300" >
        <span class="mr-2 w-3rem">{{'SMS.TO' | translate}}</span>
        <span *ngFor="let c of $.client ">
          <strong>{{c?.full_name}} <span class="font-normal">{{ c?.id}}</span> ({{ c?.mobile_country_code}} {{c?.mobile_number}})</strong>,
        </span>
        </div>
      <!-- To -->

      <!-- Message -->
      <div class="w-full relative">
        <textarea pInputTextarea class="w-full h-12rem md:h-17rem" formControlName="smsDesc"
          [placeholder]="'SMS.SMS_DESP' | translate"></textarea>
      </div>
      <!-- Message -->

    </div>
    <form [formGroup]="smsUtilityForm">
      <!-- Options -->
      <div class="bg-gray-200 mt-2 text-center md:text-left py-1 md:px-3 px-0 md:flex align-items-center">
        <p-fileUpload mode="basic" chooseIcon="" uploadIcon="" cancelIcon="" chooseLabel="Attach Document"
          styleClass="bg-gray-200 p-0 text-gray-600 font-bold border-none text-sm hidden md:block"
          name="myfile[]" url="./upload.php">
        </p-fileUpload>
          <ng-container *ngIf="(allSmsTemplates$ | async) as templates">
          <p-dropdown [options]="templates" formControlName="smsTemplates" [showClear]="SmsTemplates.value ? true : false" (onChange)="selectedTemplate()" optionLabel="name_primary_language" filterBy="name_primary_language"
            styleClass="border-none bg-gray-200 text-primary text-sm font-bold" placeholder="Use SMS Template">
            <ng-template pTemplate="selectedItem">
              <strong *ngIf="SmsTemplates.value" class="text-sm text-primary">{{SmsTemplates.value.name_primary_language}}</strong>
            </ng-template>
            <ng-template let-template pTemplate="item">
              <div class="text-sm">{{template.name_primary_language}}</div>
            </ng-template>
          </p-dropdown>
        </ng-container>
      </div>
      <!-- Options -->
    </form>

    </div>
    <!-- Composer -->

  </div>
  <!-- Content -->

  <!-- Modal Footer -->
<div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400">
  <div class="flex align-items-center justify-content-between">
    <div class="w-4">
      <p-button type="button" [label]="'SMS.CANCEL' | translate" styleClass="p-button-link text-sm" (click)="closeDialogEvent()"></p-button>
    </div>
    <div class="w-8 text-right">
      <button type="submit" [disabled]="!smsFormGroup.valid"  pButton pRipple [label]="'SMS.SEND_SMS' | translate" styleClass="text-sm"></button>
    </div>
  </div>
</div>
<!-- Modal Footer -->
</form>
</ng-container>
</ng-template>

</p-dialog>
<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>
