
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'empty-state-container',
  templateUrl: './empty-state-container.component.html',
  styleUrls: ['./empty-state-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateContainerComponent implements OnInit, AfterViewInit{
  @Input() emptyState  = new BehaviorSubject(null);
  @ViewChild('emptyStatecontainer', { read: ViewContainerRef }) emptyComponent: ViewContainerRef |any
  constructor( private componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) { }
  @Output() onAction = new EventEmitter();
  componentRef: any;
  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    this.lazyLoadTableData()
}
  lazyLoadTableData(){
    this.emptyState.subscribe((comp:any)=>{
      this.emptyComponent.clear()
      if(comp){
        this.componentRef= this.emptyComponent.createComponent(this.componentFactoryResolver.resolveComponentFactory(comp));
        this.componentRef.instance?.onAction?.subscribe((data:any)=>{
          this.onAction.emit(data)
        })
      }
      this.changeDetectorRef.detectChanges()
    })

  }

}

@NgModule({
  declarations: [EmptyStateContainerComponent],
  imports: [
    CommonModule
  ],
  exports: [EmptyStateContainerComponent]
})
export class EmptyStateContainerComponentModule {
}
