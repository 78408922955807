
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CyptoSevice } from 'libs/api/src/lib/crypto.service';
import { Observable } from 'rxjs';
import {COMPANY_INFO} from  'apps/ff-frontend/src/app/state/app.state'
import { AppInitService } from '@fitness-force/api';
@Injectable({
  providedIn: 'root'
})
export class DomainguardService implements CanActivate{
  getSubDomain = '';
  isResponse = '';
  constructor(private appInit: AppInitService, private store: Store, private router: Router, private cryp: CyptoSevice, private translate: TranslateService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // this.getSubDomain = new URL(window.location.href).port;
      // this.getSubDomain = 'mygym' + '.' + CONSTANTS_DATA.DOMAIN_INFO.DOMAIN;

      this.getSubDomain = 'fftesting.fitnessforce.com';

      return new Promise((resolve, reject)=>{
        this.store.select(COMPANY_INFO).subscribe({
          next: (data:any)=>{
            console.log('this.companyDetails', data,  this.isDomainValid(data.compDetails.subdomain))
           
            if(data.err.status){
              resolve(this.isDomainValid(''));
           } else {
              resolve(this.isDomainValid(data.compDetails.subdomain));
           }
          }
        }
        )
      })
  }

  isDomainValid(domain: string): boolean {

      if (this.getSubDomain == domain) {
        // this.router.navigate(['/'])
        return true;
      } else{
        this.router.navigate(['/page-not-found'])
        return false;
      }
    }
}
