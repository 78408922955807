import { NgModule, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortName"
})
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): any {
    return fullName?fullName
      .split(" ")
      .map(n => n[0])
      .join(""):"";
  }
}

@NgModule({
  declarations: [ShortNamePipe],
  imports: [
  ],
  exports: [ShortNamePipe]
})
export class ShortNamePipeModule { }
