import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TableAction } from '@fitness-force/enum';
import { ReportTableColumnData } from '@fitness-force/models';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CreateOpportunityComponentModule } from '../../crm/create-opportunity/create-opportunity.component';
import { TaskCreateComponentModule } from '../../task/task-create/task-create.component';

@Component({
  selector: 'table-action-buttons',
  templateUrl: './table-action-buttons.component.html',
  styleUrls: ['./table-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableActionButtonsComponent implements OnInit {

  @Input('visible') showButtons: boolean = false;
  @Input('columnData') columnData: any;
  @Input('reportKey') reportKey: any;
  @Input('action') action: any;

  showCreateOpportunityPopup:boolean=false;
  showTaskCreate:boolean=false;
  actionList:any=[];
  constructor() { }

  ngOnInit(): void {
    console.log(this.showButtons,'show btn');
    console.log(this.columnData,'column data');
    this.actionList=ReportTableColumnData.find(c =>c.report_key== this.reportKey)!.actions;
  }

  hideOpportunity(e:any)
  {
  this.showCreateOpportunityPopup =false;
  }

  HideTaskCreate(e:any)
  {
    this.showTaskCreate =false;
  }

  ShowEvent(action:any)
  {
    switch (action.actionType) {
      case TableAction.Opportunity:this.showCreateOpportunityPopup=true; break;
      case TableAction.Task: this.showTaskCreate =true; break;
    }
  }

}

@NgModule({
  declarations: [TableActionButtonsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    ButtonModule,
    RippleModule,
    CreateOpportunityComponentModule,
    TaskCreateComponentModule
  ],
  exports: [TableActionButtonsComponent]
})
export class TableActionButtonsComponentModule {
}
