import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShortNamePipeModule } from 'libs/shared/src/lib/filters/name-initials.pipe';
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StyleClassModule } from 'primeng/styleclass';
import { BehaviorSubject } from 'rxjs';
import { ClientService } from '@fitness-force/api';

@Component({
  selector: 'member-nonmember',
  templateUrl: './member-nonmember.component.html',
  styleUrls: ['./member-nonmember.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberNonmemberComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  constructor(private router: Router, private clientService: ClientService) { }

  ngOnInit(): void {
  }
  gotoClient(id: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/client/profile/', id])
    );
    window.open(url, '_blank');
  }


  getClientType(row: any): string {
    switch (row?.client_status) {
      case "Client":
        return "Member";
      default:
        return ""
    }
  }

  clientStatus(client: any): string {
    if (client && client.client_status)
      return this.clientService.getClientStatusHtml(client.client_status.toString());
    else
      return '';
  }
}

@NgModule({
  declarations: [MemberNonmemberComponent],
  imports: [
    CommonModule,
    OverlayPanelModule,
    AvatarModule,
    StyleClassModule,
    ShortNamePipeModule,
    RouterModule
  ],
  exports: [MemberNonmemberComponent]
})
export class MemberNonmemberComponentModule {
}
