import {ComponentCanDeactivate} from './component-can-deactivate';
import {NgForm} from "@angular/forms";
import { Component } from '@angular/core';

@Component({
  template: ''
})
export abstract class FormCanDeactivate extends ComponentCanDeactivate{

 abstract get form():NgForm;

 canDeactivate():boolean{
      return this.form.submitted || !this.form.dirty
  }
}
