import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { AuthFooterComponentModule } from '../footer/footer.component';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [AboutUsComponent],
  imports: [
    CommonModule,
    AuthFooterComponentModule
  ],
  exports: [AboutUsComponent]
})
export class AboutUsComponentModule {
}
