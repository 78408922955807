import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'with-link',
  templateUrl: './with-link.component.html',
  styleUrls: ['./with-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WithLinkComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)
  keyName!: string ;
  value!:any;
  uri:string;

  constructor(private router :Router) { }

  ngOnInit(): void {
    // this.keyName=this.additionData.keyName;
    // this.uri=this.additionData.uri;
    // this.value=this.columnData[this.keyName];
  }

  onGoToRoute(url:string, id:number){
    this.router.navigate([url, id]);
  }
}

@NgModule({
  declarations: [WithLinkComponent],
  imports: [CommonModule,RouterModule,TooltipModule],
  exports: [WithLinkComponent]
})
export class WithLinkComponentModule {
}
