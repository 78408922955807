import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  Client,
  TENANTSINTERFACE,
} from '@fitness-force/models';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AssignKeyFobComponentModule } from '../../../client-profile/assign-key-fob/assign-key-fob.component';
import { BlockClientComponentModule } from '../../../client-profile/block-client/block-client.component';
import { ChangePasswordComponentModule } from '../../../client-profile/change-password/change-password.component';
import { ChangeSalesRepresentativeComponentModule } from '../../../client-profile/change-sales-representative/change-sales-representative.component';
import { EditProfileComponentModule } from '../../../client-profile/edit-profile/edit-profile.component';
import { CreateOpportunityComponentModule } from '../../../crm/create-opportunity/create-opportunity.component';
import { FactoryHttpLoader } from '../../../helpsupport/helpsupport.component';
import { CreateProspectComponentModule } from '../../../omnisearch/create-prospect/create-prospect.component';
import { TaskCreateComponentModule } from '../../../task/task-create/task-create.component';
import { Store } from '@ngrx/store';
import { TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';

@Component({
  selector: 'prospect-list-actions',
  templateUrl: './prospect-list-actions.component.html',
  styleUrls: ['./prospect-list-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectListActionsComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  @Input() isAllSelected = new BehaviorSubject(false);

  // an observable of set data..
  @Input() selectedRows= new BehaviorSubject(new Map());;
  @Input() rowIndex:number=0;
  @Output() changeEvent = new EventEmitter();

  showOppDialog = false;
  showTaskCreate: boolean = false;
  clients = new BehaviorSubject<Array<any>>([]);
  clientDetails : any = [];
  clientIds: number[] = [];
  showCreateProspectPopup: Observable<boolean> = of(false);
  showCreateOpportunityPopup: Observable<boolean> | any = false;
  toggleEditProfileDialog$ = new BehaviorSubject<boolean | null>(false);
  toggleChangePasswordDialog$ = new BehaviorSubject<boolean | null>(false);
  toggleBlockClientDialog$ = new BehaviorSubject<boolean | null>(false);
  toggleAssignKeyFobDialog$ = new BehaviorSubject<boolean | null>(false);
  toggleChangeSaleRepDialog$ = new BehaviorSubject<boolean | null>(false);
  selectedClient: any = new Map();
  deleteProfileModal: boolean = false;
  mergeProfileModal: boolean = false;
  cancelInvoiceModal: boolean = false;
  changeBillOwnerModal: boolean = false;
  enquirySourceDisable: boolean = true;
  hideDropdown: boolean = true;
  confirmDeleteProfileModal: boolean = false;
  tableData: any;
  allClientData = new BehaviorSubject(null);
  isObservable = false;

  // coldata
  colData!:any

  tenantId: number = 0;
  getAccessTenantList$: Observable<any> | any;
  errMsg: Error | any;
  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.clientIds = [];
    this.columnData.subscribe((val:any)=>{
      this.colData=val;
      this.selectedClient.set('id', val);
      this.clients.next(this.getSelectedClientData);
      this.clientDetails =this.getSelectedClientData;
    })
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
    });
  }

  newOpportunity() {
    this.showOppDialog = true;
    return this.showOppDialog;
  }

  HideTaskCreate(e: any) {
    this.showTaskCreate = false;
  }

  hidePopup(isHide: boolean) {
    this.showCreateProspectPopup = of(isHide);
  }

  get getSelectedClientData(): Array<Client> {
    return [...this.selectedClient.values()];
  }

  showOpp() {
    this.showCreateOpportunityPopup = true;
  }

  OpenPopup() {
    this.showCreateProspectPopup = of(true);
  }

  hideOpportunity(isHide: any) {
    this.showCreateOpportunityPopup = false;
  }

  newTask(data: any) {
    if (this.clientIds.length > 0) {
      this.clientIds = [];
    }
    this.clientIds.push(data.id);
    this.showTaskCreate = true;
  }

  toggleEditProfileDialog() {
    this.toggleEditProfileDialog$.next(true);
  }

  closeEditProfile(e: boolean) {
    this.toggleEditProfileDialog$.next(false);
    this.changeEvent.emit(e);
  }
  closeAssignKeyFob(e: boolean) {
    this.toggleAssignKeyFobDialog$.next(false);
    this.changeEvent.emit(e);
  }

  toggleChangePasswordDialog() {
    this.toggleChangePasswordDialog$.next(true);
  }

  closeChangePasswordDialog(e: boolean) {
    this.toggleChangePasswordDialog$.next(false);
    this.changeEvent.emit(e);
  }
  toggleBlockClientDialog() {
    this.toggleBlockClientDialog$.next(true);
  }
  toggleAssignKeyFobDialog() {
    this.toggleAssignKeyFobDialog$.next(true);
  }
  closeBlockClientDialog(e: any) {
    if(e.close)
    this.toggleBlockClientDialog$.next(false);
    this.changeEvent.emit(e);

  }
  toggleChangeSaleRepDialog() {
    this.toggleChangeSaleRepDialog$.next(true);
  }
  closeChangeSaleRepDialog(e: boolean) {
    this.toggleChangeSaleRepDialog$.next(false);
    this.changeEvent.emit(e);
  }
  navigateToBillinging(clientData: any) {
    this.router.navigate(['/pos/membership-addons', clientData.id]);
  }
}

@NgModule({
  declarations: [ProspectListActionsComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    StyleClassModule,
    OverlayPanelModule,
    DropdownModule,
    DialogModule,
    MessageModule,
    AvatarModule,
    TableModule,
    InputTextModule,
    CreateProspectComponentModule,
    CreateOpportunityComponentModule,
    TaskCreateComponentModule,
    EditProfileComponentModule,
    ChangePasswordComponentModule,
    BlockClientComponentModule,
    AssignKeyFobComponentModule,
    ChangeSalesRepresentativeComponentModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [ProspectListActionsComponent],
})
export class ProspectListActionsComponentModule {}
