<p-dialog [(visible)]="signHereModal" [resizable]="false" appendTo="body" [modal]="true"
    (onShow)="selectDrawActive=false; boostrapSignaturePad();" [breakpoints]="{'767px': '100vw'}"
    [style]="{width: '460px'}" styleClass="no-header-modal" (onHide)="closeDialog()">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <!-- Content -->
        <form [formGroup]="fg">
            <div class="text-sm">
                <i (click)="closeDialog()"
                    class="pi pi-times text-gray-500 cursor-pointer absolute top-0 right-0 mt-3 mr-3"></i>
                <!-- <div class="md:flex"> -->
                <!-- Left Side -->
                <!-- <div class="md:w-4 p-3 border-right-1 border-gray-300">
                        <div class="grid mt-0 mb-3">
                            <div class="col-6 md:col-12">
                                <label class="text-sm mb-2 block">
                                    Full Name
                                    <sup class="p-error">*</sup>
                                </label>
                                <input type="text" class="w-full text-sm" pInputText formControlName="fullName" />
                            </div>
                            <div class="col-6 md:col-12">
                                <label class="text-sm mb-2 block">
                                    Initials
                                    <sup class="p-error">*</sup>
                                </label>
                                <input type="text" class="w-full text-sm" pInputText formControlName="initials" />
                            </div>
                        </div>
                    </div> -->
                <!-- Left Side -->

                <!-- <div class="md:w-8 -mt-5 md:mt-0"> -->
                <div class="-mt-5 md:mt-0">
                    <div class="p-3">
                        <!-- Tab Menu -->
                        <ul class="list-none flex align-items-end m-0 p-0 line-height-3 text-center">
                            <!-- <li (click)="selectDrawActive=false;"
                                    [ngClass]="!selectDrawActive ? 'font-bold text-900 border-bottom-3 border-primary' : 'text-gray-600' "
                                    class="px-3 py-1 cursor-pointer inline-block w-6 md:w-4">Select
                                    Styles</li> -->
                            <li (click)="selectDrawActive=true;" (click)="boostrapSignaturePad();"
                                [ngClass]="selectDrawActive ? 'font-bold border-bottom-3 border-primary' : 'text-gray-600' "
                                class="px-3 py-1 cursor-pointer inline-block w-6 md:w-4">Draw
                            </li>
                        </ul>
                        <p-divider styleClass="mt-0"></p-divider>
                        <!-- Tab Menu -->

                        <!-- Tab Content -->
                        <div class="py-0">

                            <!-- Select Styles -->
                            <!-- <div [hidden]="selectDrawActive">
                                <div
                                    class="border-1 border-gray-300 border-round p-3 mb-2 flex align-items-center justify-content-between cursor-pointer hover:border-primary hover:bg-blue-50">
                                    <span>{{FullName}}</span>
                                    <span>{{Initials}}</span>
                                </div>
                                <div
                                    class="border-1 border-gray-300 border-round p-3 mb-2 flex align-items-center justify-content-between cursor-pointer hover:border-primary hover:bg-blue-50">
                                    <span>{{FullName}}</span>
                                    <span>{{Initials}}</span>
                                </div>
                                <div
                                    class="border-1 border-gray-300 border-round p-3 mb-2 flex align-items-center justify-content-between cursor-pointer hover:border-primary hover:bg-blue-50">
                                    <span>{{FullName}}</span>
                                    <span>{{Initials}}</span>
                                </div>
                                <div
                                    class="border-1 border-gray-300 border-round p-3 mb-2 flex align-items-center justify-content-between cursor-pointer hover:border-primary hover:bg-blue-50">
                                    <span>{{FullName}}</span>
                                    <span>{{Initials}}</span>
                                </div>
                            </div> -->
                            <!-- Select Styles -->

                            <!-- Draw Signature -->
                            <!-- [hidden]="!selectDrawActive" -->
                            <div>
                                <div class="flex align-items-center justify-content-between">
                                    <h5 class="mb-0 text-sm">Draw your signature</h5>
                                    <span class="text-primary cursor-pointer" (click)="clearSignatuore()">Clear</span>
                                </div>
                                <!-- <textarea pInputTextarea class="text-sm w-full my-3 h-8rem"></textarea> -->
                                <canvas id="signature-pad" class="signature-pad"
                                    class="text-sm w-full my-3 h-8rem"></canvas>
                                <p class="mb-0 text-gray-600 text-xs">
                                    By selecting Sign, I agree
                                    that the signature
                                    and
                                    initials will be
                                    the electronic representative of my signature and initials
                                    for
                                    all purpose when
                                    I
                                    (or my agent) use
                                    them on documents, including legally binding contracts -
                                    just
                                    the same as a
                                    pen-and-paper signature or
                                    initial.</p>
                            </div>
                            <!-- Draw Signature -->
                        </div>
                        <!-- Tab Content -->
                    </div>

                    <!-- Footer -->
                    <div class="py-2 px-3 flex justify-content-between align-items-center border-top-1 border-gray-300">
                        <button pButton pRipple type="button" label="Cancel" class="p-button-text text-sm"
                            (click)="closeDialog()"></button>
                        <button pButton pRipple type="button" label="Sign" class="text-sm px-5"
                            (click)="saveSignature()"></button>
                    </div>
                    <!-- Footer -->
                </div>
            </div>
            <!-- </div> -->
            <!-- Content -->

            <!-- Show Errors -->
            <app-errors *ngIf="showErrorPopup | async as showPopup" [visible]="showPopup" [errorData]="errorMessages"
                [error]="error" (hideErrorPopup)="HideErrorPopup($event)">
            </app-errors>
            <!-- Show Errors -->
        </form>
    </ng-template>
</p-dialog>