import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, DebugElement, ComponentFactoryResolver } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { BrowserModule } from '@angular/platform-browser';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, FormArrayName } from '@angular/forms';
import {
  FooterComponentModule,
  HeaderComponentModule,
  SidebarComponentModule,
} from '@fitness-force/core';
import { SidebarModule } from 'primeng/sidebar';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { DropdownModule } from 'primeng/dropdown'
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessagesModule } from 'primeng/messages';
import { CommonModule } from '@angular/common';
import { ChargeBreakUp, ChargingTodaySignUp, Claim, Crm, GetPlanMasterByIdResponse, GetPlanMasterListResponse, ListPaymentModeMastersResponse, ListServiceTypeResponse, OverLappingSubscriptionResponse, OverLappingSubscriptionSignUpResponse, PaymentModeMastersResponse, PaymentSignUp, PlanBreakUpCalculation, PlanBreakUpCalculationRequest, PlanBreakUpCalculationResponse, PlanMasterList, PlanMasterServices, PlanSummary, RecurringSignUp, ServiceBreakUp, ServiceTypeResponse, SignUpRequest, SignUpResponse, StaffDiscountCalculationRequest, StaffDiscountChargeRecurring, StaffDiscountChargingTodayRequest, StaffDiscountServiceFirstChargeRequest, StaffDiscountServiceNonRecurringRequest, StaffDiscountServiceRecurringRequest, StaffDiscountSignUpChargeRequest, STAFFINTERFACE, TaxLineItemBreakUp, TENANTSINTERFACE, ValidationError } from '@fitness-force/models';
import { BehaviorSubject, combineLatestWith, map, Observable, of } from 'rxjs';
import { AdvancedSettingService, AuthSevices, ClaimService, ClientService, CommonService, PaymentModeService, PlanmasterService, PlansubscriptionService, ServiceCategoryService, ServiceTypeService, TimeconversionService } from '@fitness-force/api';
import { AppErrors, NotFoundError, ErrorMsgComponentModule } from '@fitness-force/errors';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FactoryHttpLoader, AssignedToComponentModule, ErrorsComponentModule } from '@fitness-force/shared';

import { DateTimeFormatPipeModule } from '../../../../../../../libs/shared/src/lib/filters/datetimeformat.pipe';
import { HttpClient } from '@angular/common/http';
import { PayModeLevel1, PayModeLevel2, PlanCategory, PlanIntervalType, PlanType, Roles, Status } from '@fitness-force/enum';
import { COMPANY_INFO, STAFF_STATE, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { Store } from '@ngrx/store';
import { SkeletonModule } from "primeng/skeleton";
import { InputNumberModule } from 'primeng/inputnumber';
import { CurrencyformatPipeModule } from '../../../filters/currencyformat.pipe';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { CreditCardValidators } from 'angular-cc-library';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';


@Component({
  selector: 'billing-summary',
  templateUrl: './billing-summary.component.html',
  styleUrls: ['./billing-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingSummaryComponent implements OnInit {

  disablePlanDropdown: boolean = false;

  getAccessTenantList$: Observable<any>;
  tenantId: number = 0;
  subscriptionPlanId: number = 0;
  clientId: number = 0;
  clientName: string = '';
  isInclusive: boolean = false;
  isContract$ = new BehaviorSubject<boolean>(false);
  isCashRecurring: boolean = false;
  otherpayment: boolean = false;
  billing_interval_type$ = new BehaviorSubject<string>('');
  billing_interval_value$ = new BehaviorSubject<number>(0);
  subscription_category$ = new BehaviorSubject<string>('');
  bill_cycle_day: string;
  fg: FormGroup = new FormGroup({});
  planIntervalType = PlanIntervalType;
  planCategory = PlanType;
  oJSON: JSON;

  serviceTypeCollection!: Option[];
  errMsg: Error | any;

  claim$: Observable<Claim[]> = new Observable();
  monthNames: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  fgProducts: FormGroup = new FormGroup({
    frmFirstTimeBaseCostAmt: new FormControl(''),
    frmFirstTimeAmt: new FormControl('', [Validators.required]),
    frmFirstTimeDis: new FormControl('', [Validators.required]),
    frmFirstTimeTaxAmt: new FormControl('', []),
    frmNonRecurringBaseCostAmt: new FormControl(''),
    frmNonRecurringAmt: new FormControl('', [Validators.required]),
    frmNonRecurringDis: new FormControl('', [Validators.required]),
    frmNonRecurringTaxAmt: new FormControl('', []),
    charges: new FormArray([])
  });

  fgProductsRecurring: FormGroup = new FormGroup({
    frmRecurringMembershipBaseCostAmt: new FormControl('', []),
    frmRecurringMembershipAmt: new FormControl('', []),
    frmRecurringMembershipDis: new FormControl('', []),
    frmRecurringMembershipTaxAmt: new FormControl('', []),
  });

  fgPayment: FormGroup = new FormGroup({
    //Default mode Selection.
    otherPaymentMode: new FormControl('cash', [Validators.required]),

    //CASH
    frmCashAmount: new FormControl('', [Validators.required, Validators.min(0)]),

    //Credit CARD
    frmCCNumber: new FormControl('', [Validators.required, CreditCardValidators.validateCCNumber]),
    frmCCNetworkType: new FormControl('', [Validators.required]),
    frmCCExpiryDate: new FormControl('', [CreditCardValidators.validateExpDate]),
    frmCCAuthCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    frmCCAmt: new FormControl('', [Validators.required, Validators.min(0)]),

    //Credit CARD
    frmDCNumber: new FormControl('', [CreditCardValidators.validateCCNumber]),
    frmDCNetworkType: new FormControl('', [Validators.required]),
    frmDCExpiryDate: new FormControl('', [CreditCardValidators.validateExpDate]),
    frmDCAuthCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    frmDCAmt: new FormControl('', [Validators.required, Validators.min(0)]),

    //CHEQUE
    frmChequeBankName: new FormControl('', [Validators.required]),
    frmChequeDate: new FormControl('', [Validators.required]),
    frmChequeAmount: new FormControl('', [Validators.required, Validators.min(0)]),

    //UPI
    frmUpiProvider: new FormControl('', [Validators.required]),
    frmUpiVpaid: new FormControl('', [Validators.required]),
    frmUpiAmount: new FormControl('', [Validators.required, Validators.min(0)]),
  });


  userDiscountLimit: number = 0;
  single_staff_discount: number = 0;
  recurring_staff_discount: number = 0;


  @Input('visible') showPaidFullSidebar: boolean = false;
  // @Input('visible') showPaidFullSidebar$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
  @Input('planSummarydata') planSummary$: Observable<PlanSummary>;

  @Output() hideSidePopup: EventEmitter<{ hideSidebar: boolean, clientSignupUUID: string }> = new EventEmitter();
  @Output() closeSidePopup: EventEmitter<{ hideSidebar: boolean, clientSignupUUID: string }> = new EventEmitter();


  planDetail$: Observable<GetPlanMasterByIdResponse> = new Observable();
  billingCycleOption$ = new BehaviorSubject<any[]>([]);
  isProRata$ = new BehaviorSubject(true);
  paymentModeMaster$: Observable<ListPaymentModeMastersResponse> = new Observable();
  paymentMode$: Observable<ListPaymentModeMastersResponse> = new Observable();
  paymentModeBS$ = new BehaviorSubject<PaymentModeMastersResponse[]>([]);

  totalNonRecurringAmt$ = new BehaviorSubject(0);
  _selectedstaff = new BehaviorSubject<number>(0);
  _filterStaff = new BehaviorSubject<number[]>([0]);

  isCoupounEnabled: boolean = false;
  isTaxInClusive: boolean = false;

  weekDays: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  billOwnerId$ = new BehaviorSubject<number>(0);

  planBreakUpCalculationResponse$ = new BehaviorSubject<PlanBreakUpCalculationResponse>(null!);
  overLappingSubscriptionSignUp$ = new BehaviorSubject<OverLappingSubscriptionSignUpResponse>(null!);
  planBreakUpCalculation$: BehaviorSubject<PlanBreakUpCalculation | null> = new BehaviorSubject<PlanBreakUpCalculation | null>(null);
  planSubscription$: BehaviorSubject<PlanMasterList[] | null> = new BehaviorSubject<PlanMasterList[] | null>(null);
  // firstTimeCharge$ = new BehaviorSubject<number>(null!);
  // oneTimeCharge$ = new BehaviorSubject<number>(null!);

  billdate: Date = new Date();

  planSubscriptionList: {
    id: number, name_primary_language: string, name_secondary_language: string
    , subscription_category: PlanCategory, recurring_services_in_minor_curr: number;
  }[];


  clientSignupUUID: string = "";
  selectedMembership: { name: string, duration: string, amount: string; } | undefined;
  billOwners: any[];
  selectedBillOwners: { name: string, image: string, initials: string; } | undefined;
  startDate: Date | undefined;
  selectedValue: string = 'creditcard';
  selectedBilling: { name: string } | undefined;

  // Variables used in controls
  showContractSidebar: boolean = true;
  applyDiscount: boolean = false;
  applyCoupon: boolean = true;
  couponApplied: boolean = true;
  discountApplied: boolean = false;
  inValidDiscount: Boolean = true;
  onlinePayment: boolean = false;
  // otherPayment: boolean = false;
  requiredInformation: boolean = false;

  //#region  "Static Payments" 
  isCashAllowed: boolean = false;
  isDebitCardAllowed: boolean = false;
  isCreditCardAllowed: boolean = false;
  isNetBankingAllowed: boolean = false;
  isUpiAllowed: boolean = false;
  isWalletAllowed: boolean = false;
  isAmazonPayAllowed: boolean = false;
  isPayTMAllowed: boolean = false;
  isVenmoAllowed: boolean = false;
  isChequeAllowed: boolean = false;
  //#endregion


  showErrorPopup = new BehaviorSubject(false);
  errorMessages = [] as any;
  errorDataModal = [] as ValidationError[];
  currentUserDetails: any;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private translate: TranslateService,
    private clientService: ClientService,
    private planmasterService: PlanmasterService,
    private plansubscriptionService: PlansubscriptionService,
    private commonService: CommonService,
    public authService: AuthSevices,
    public paymentModeService: PaymentModeService,
    private timeService: TimeconversionService,
    private advanceSettingService: AdvancedSettingService,
    private serviceCategoryService: ServiceCategoryService,
    private serviceType: ServiceTypeService,
    private claimService: ClaimService,
  ) {
    this.componentInit();
    this.SetFormFields();

  }

  componentInit() {
    let companyInfoDetail$: Observable<any> = this.store.select(COMPANY_INFO);
    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);
    this.claim$ = this.claimService.GetClaim(environment.BASE_URL);

    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' })
      }
    })

    companyInfoDetail$.subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' })
        this.GetAdvanceSetting();
      }
    })


    this.currentUserDetails = this.authService.decodedToken;
    this.single_staff_discount = this.currentUserDetails.single_staff_discount;
    this.recurring_staff_discount = this.currentUserDetails.recurring_staff_discount;


  }


  ngOnInit(): void {
    this.planSummary$.subscribe((plan: PlanSummary) => {
      this.tenantId = plan.tenant_id;
      this.clientId = plan.client.client_id;
      this.clientName = plan.client.client_name;
      this.subscriptionPlanId = plan.id;
      this.disablePlanDropdown = plan.id == 0;

      this.filterStaff();

      this.createDymamicPaymentControl();
      this.BindOtherPaymentMode(plan.tenant_id);
      this.setPaymentValidator();
      this.BindPlanList(plan.tenant_id);
      this.BindPlanById(plan.tenant_id, plan.id, plan.client.client_id);

    });

    this._selectedstaff.next(Number(this.authService.loggedInStaffId));
    //this.fg.controls.billdata.setValue((new Date())); 
  }



  GetAdvanceSetting() {
    let tenant_settings = this.advanceSettingService.getAdvanceSetting(this.tenantId, 'tax_calculation');
    if (tenant_settings) {
      console.log(tenant_settings);
      tenant_settings.subscribe({
        next: (res) => {
          if (res.value == "inclusive") {
            this.isInclusive = true;
          }
        }
      });
    }
  }

  setPaymentValidator() {
    this.fgPayment.controls['otherPaymentMode'].valueChanges.subscribe(
      val => {

        let otherPaymentModeid = 0;
        let mode = '';

        if (val.toLowerCase().startsWith("otherpaymentmode_rb_")) {
          otherPaymentModeid = parseInt(val.toLowerCase().replace("otherpaymentmode_rb_", ""))
          val = "otherpaymentmode";
        }
        //this.clearPaymentValidator();

        console.log(val);
        switch (val) {
          case "cash":
            this.frmCash_AmountFormControl.clearValidators();
            this.frmCash_AmountFormControl.addValidators([Validators.required]);
            this.frmCash_AmountFormControl.updateValueAndValidity();
            break;
          case "cheque":
            this.frmCheque_AmountFormControl.clearValidators();
            this.frmCheque_AmountFormControl.addValidators([Validators.required]);
            this.frmCheque_AmountFormControl.updateValueAndValidity();
            break;
          case "upi":
            this.frmUpi_AmountFormControl.clearValidators();
            this.frmUpi_AmountFormControl.addValidators([Validators.required]);
            this.frmUpi_AmountFormControl.updateValueAndValidity();
            break;
          case "debit":
            this.frmDC_AmountFormControl.clearValidators();
            this.frmDC_AmountFormControl.addValidators([Validators.required]);
            this.frmDC_AmountFormControl.updateValueAndValidity();

            break;
          case "credit":
            this.frmCC_AmountFormControl.clearValidators();
            this.frmCC_AmountFormControl.addValidators([Validators.required]);
            this.frmCC_AmountFormControl.updateValueAndValidity();
            break;
          case "otherpaymentmode":
            this.fgPayment.controls["inp_otherPaymentMode_" + otherPaymentModeid].addValidators([Validators.required]);
            this.fgPayment.controls["inp_otherPaymentMode_" + otherPaymentModeid].updateValueAndValidity();
            break;
        }
      }
    )
  }

  clearPaymentValidator() {
    this.frmCash_AmountFormControl.removeValidators([Validators.required]);

    this.frmCheque_AmountFormControl.removeValidators([Validators.required]);

    this.frmUpi_AmountFormControl.removeValidators([Validators.required]);

    this.frmDC_AmountFormControl.removeValidators([Validators.required]);

    this.frmCC_AmountFormControl.removeValidators([Validators.required]);

    Object.keys(this.fgPayment.controls).forEach(key => {
      if (key.toLowerCase().startsWith("inp_otherpaymentmode_")) {
        this.fgPayment.controls[key].removeValidators([Validators.required]);
      }
    });
    this.frmCheque_AmountFormControl.updateValueAndValidity();

  }

  SetFormFields(): void {
    this.fg = this.fb.group({
      ddlMembership: new FormControl('', []),
      ddlplanSubscriptionList: new FormControl('', []),
      ddlBillingCycle: new FormControl('', []),
      billdata: new FormControl(new Date(), []),
      billingOwner: new FormControl('', []),
      subscriptionstartdate: new FormControl((new Date()), []),
    });

    this.fg.controls.ddlplanSubscriptionList.valueChanges.subscribe((plan: any) => {
      if (plan && plan.id) {
        this.subscriptionPlanId = plan.id;
        this.BindPlanById(plan.tenant_id, plan.id, this.clientId);
      }
    });
  }

  closeBillingSideBar(sGuid: string) {
    this.closeSidePopup.emit({ hideSidebar: false, clientSignupUUID: this.clientSignupUUID });
  }


  BindPlanList(tenant_id: number) {
    this.planmasterService.getSubscriptionPlanService(tenant_id).subscribe({
      next: (planList: GetPlanMasterListResponse) => {
        let oPlan: PlanMasterList[] = planList.items.filter((o) => {
          return o.status == Status.Active || o.status.toString() == Status[Status.Active];
        });
        this.planSubscription$.next(oPlan);
        if (this.subscriptionPlanId > 0) {
          let selectedPlan = oPlan.find((o) => o.id == this.subscriptionPlanId);
          this.fg.controls.ddlplanSubscriptionList.setValue(selectedPlan);
        }
      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          console.log(JSON.stringify(error));
        } else {
          alert('something went wrong');
        }
      }
    });
  }



  BindOtherPaymentMode(tenant_id: number) {
    this.paymentModeMaster$ = this.paymentModeService.listPaymentMode(tenant_id)

    this.paymentModeMaster$.subscribe({
      next: (listpaymentMode: ListPaymentModeMastersResponse) => {
        CONSTANTS_DATA.PAYMENTMODE.IN.forEach(obj => {
          listpaymentMode.items.push(obj as any);
        });

        this.isCashAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.Cash && e.id == PayModeLevel2.Cash).length > 0
        this.isDebitCardAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.Card && e.id == PayModeLevel2.Debit).length > 0
        this.isCreditCardAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.Card && e.id == PayModeLevel2.Credit).length > 0
        this.isNetBankingAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.NetBanking && e.id == PayModeLevel2.NetBanking).length > 0
        this.isUpiAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.UPI && e.id == PayModeLevel2.UPI).length > 0
        this.isAmazonPayAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.Wallet && e.id == PayModeLevel2.AmazonPay).length > 0
        this.isPayTMAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.Wallet && e.id == PayModeLevel2.Paytm).length > 0
        this.isVenmoAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.Wallet && e.id == PayModeLevel2.Venmo).length > 0
        this.isChequeAllowed = listpaymentMode.items.filter(e => e.status == Status.Active && e.type == PayModeLevel1.Cheque && e.id == PayModeLevel2.Cheque).length > 0

        this.paymentModeBS$.next(listpaymentMode.items.filter(e => {
          return (e.status == Status.Active || e.status.toString() == Status[Status.Active]) && (e.type == PayModeLevel1.Other || e.type.toString() == PayModeLevel1[PayModeLevel1.Other])
        }));

      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          // this.errMsg = error;
        } else {
          alert('something went wrong');
        }
      }
    });
  }

  createDymamicPaymentControl() {
    this.paymentModeBS$.subscribe({
      next: (paymentModeMastersResponse: PaymentModeMastersResponse[]) => {
        console.log(paymentModeMastersResponse);
        paymentModeMastersResponse.forEach((value: PaymentModeMastersResponse, index: number) => {
          this.fgPayment.addControl('inp_otherPaymentMode_' + value.id.toString(), new FormControl(0, []));
        });
      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          // this.errMsg = error;
        } else {
          alert('something went wrong');
        }
      }
    });
  }

  SaveSubscription(isOfflinePayment: boolean) {
    let paymentSignUp: PaymentSignUp = {} as PaymentSignUp;
    if (isOfflinePayment) {

      // this.fgPayment.markAllAsTouched();
      // if (!this.fgPayment.valid) {
      //   return;
      // }

      paymentSignUp = this.getPaymentObject();
      this.fgPayment.markAllAsTouched();
      if (!this.validatePaymentObject()) {
        return;
      }
    }
    else {
      paymentSignUp = {} as PaymentSignUp;
    }

    let orgPlanSummary: PlanSummary = {} as PlanSummary;
    let crm: Crm = {} as Crm;
    this.planSummary$.subscribe(
      {
        next: (res) => {
          orgPlanSummary = res;
          crm.sigup_sts_opp_id = res?.CRM?.opp_id;
          crm.sigup_sts_tsk_id = res?.CRM?.task_id;
          crm.sigup_sts_board_type = res?.CRM?.board_id;
        }
      });

    let signUpRequest = {

      plan_id: this.subscriptionPlanId,
      start_date: this.subscriptionStartdate.value,
      bill_cycle_day: (this.subscription_category$.value.toString() == PlanCategory.DropIn.toString() || this.subscription_category$.value == PlanCategory[PlanCategory.DropIn]) ? this.subscriptionStartdate.value.getDate() : this.billingCycle.value.value,
      sales_rep: this.billingOwner.value.id,
      issue_date: this.billDate.value,
      billing_name: this.clientName,
      is_offline_payment: isOfflinePayment,
      offline_payment: paymentSignUp,
      is_coupon_code_applied: false,
      sigup_coupon_code: "",
      charging_today: {} as ChargingTodaySignUp,
      charging_recurring: {} as RecurringSignUp,
      //current_keyfob: "", //need to set
      crm: crm,
      //schedule: {} as ScheduleSignUp
    } as SignUpRequest;

    this.planBreakUpCalculation$.subscribe((_objResponse: PlanBreakUpCalculation | null) => {
      console.log(_objResponse);
      if (_objResponse) {
        signUpRequest.charging_today = _objResponse.charging_today;
        signUpRequest.charging_recurring = _objResponse.charging_recurring;
        console.log("signUpRequest", signUpRequest);
        //Payment
        let val = this.otherPaymentMethod;
        this.plansubscriptionService.SubscriptionSignup(this.tenantId, this.clientId, signUpRequest).subscribe({
          next: (_objResponse: SignUpResponse) => {
            this.clientSignupUUID = _objResponse.clientsignupuuId;
            this.closeBillingSideBar(_objResponse.clientsignupuuId);
          },
          error: (_obj: any) => {

          },
          complete: () => {
          }
        });
      }
    });
  }


  getPaymentObject() {
    let paymentSignUp = {} as PaymentSignUp;
    var mode = this.otherPaymentMethod;
    var otherPaymentModeid = 0;
    paymentSignUp.vpa_id = "";
    paymentSignUp.upi_provider = "";
    paymentSignUp.network_type = "";
    paymentSignUp.card_number = "";
    paymentSignUp.bank_name = "";
    paymentSignUp.auth_code = "";

    if (mode.toLowerCase().startsWith("otherpaymentmode_rb_")) {
      otherPaymentModeid = parseInt(mode.toLowerCase().replace("otherpaymentmode_rb_", ""))
      mode = "otherpaymentmode";
    }

    switch (mode) {
      case "cash":
        paymentSignUp.amount_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(this.frmCash_Amount);
        paymentSignUp.paymode_level1 = PayModeLevel1.Cash;
        paymentSignUp.paymode_level2_id = PayModeLevel2.Cash;
        break;
      case "cheque":
        paymentSignUp.amount_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(this.frmCheque_Amount);
        paymentSignUp.cheque_date = this.frmCheque_Date;
        paymentSignUp.bank_name = this.frmCheque_BankName;
        paymentSignUp.paymode_level1 = PayModeLevel1.Cheque;
        paymentSignUp.paymode_level2_id = PayModeLevel2.Cheque;
        break;
      case "upi":
        paymentSignUp.paymode_level1 = PayModeLevel1.UPI;
        paymentSignUp.paymode_level2_id = PayModeLevel2.UPI;
        break;
      case "debit":
        paymentSignUp.amount_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(this.frmDC_Amount);
        paymentSignUp.network_type = this.frmDC_NetworkType;
        paymentSignUp.exp_date = this.frmDC_ExpiryDate;
        paymentSignUp.card_number = this.frmDC_Number.toString();
        paymentSignUp.auth_code = this.frmDC_AuthCode.toString();
        paymentSignUp.paymode_level1 = PayModeLevel1.Card;
        paymentSignUp.paymode_level2_id = PayModeLevel2.Debit;
        break;
      case "credit":
        paymentSignUp.amount_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(this.frmCC_Amount);
        paymentSignUp.network_type = this.frmCC_NetworkType;
        paymentSignUp.exp_date = this.frmCC_ExpiryDate;
        paymentSignUp.card_number = this.frmCC_Number.toString();
        paymentSignUp.auth_code = this.frmCC_AuthCode.toString();
        paymentSignUp.paymode_level1 = PayModeLevel1.Card;
        paymentSignUp.paymode_level2_id = PayModeLevel2.Credit;
        break;
      case "otherpaymentmode":
        paymentSignUp.amount_in_minor_currency = this.commonService.ConvertMinorCurrencyFromAmount(this.fgPayment.controls["inp_otherPaymentMode_" + otherPaymentModeid].value);
        paymentSignUp.paymode_level1 = PayModeLevel1.Other;
        break;
    }
    return paymentSignUp;
  }

  validatePaymentObject(): boolean {
    let paymentSignUp = {} as PaymentSignUp;
    var mode = this.otherPaymentMethod;
    var otherPaymentModeid = 0;
    var isValid = true;

    if (mode.toLowerCase().startsWith("otherpaymentmode_rb_")) {
      otherPaymentModeid = parseInt(mode.toLowerCase().replace("otherpaymentmode_rb_", ""))
      mode = "otherpaymentmode";
    }

    switch (mode) {
      case "cash":
        if (this.frmCash_AmountFormControl.status == "INVALID")
          isValid = false;
        break;
      case "cheque":
        if (this.frmCheque_AmountFormControl.status == "INVALID"
          || this.frmCheque_DateFormControl.status == "INVALID"
          || this.frmCheque_BankNameFormControl.status == "INVALID"
        )
          isValid = false;
        break;
      case "upi":
        if (this.frmUpi_AmountFormControl.status == "INVALID"
          || this.frmUpiVpaidFormControl.status == "INVALID"
          || this.frmUpiProviderFormControl.status == "INVALID"
        )
          isValid = false;
        break;
      case "debit":
        if (this.frmDC_AmountFormControl.status == "INVALID"
          || this.frmDC_AuthCodeFormControl.status == "INVALID"
          || this.frmDC_CardExpiryFormControl.status == "INVALID"
          || this.frmDC_NetworkTypeFormControl.status == "INVALID"
          || this.frmDC_NumberFormControl.status == "INVALID"
        )
          isValid = false;
        break;
      case "credit":
        if (this.frmCC_AmountFormControl.status == "INVALID"
          || this.frmCC_AuthCodeFormControl.status == "INVALID"
          || this.frmCC_CardExpiryFormControl.status == "INVALID"
          || this.frmCC_NetworkTypeFormControl.status == "INVALID"
          || this.frmCC_NumberFormControl.status == "INVALID"
        )
          isValid = false;
        break;
      case "otherpaymentmode":
        if (this.fgPayment.controls["inp_otherPaymentMode_" + otherPaymentModeid].status == "INVALID")
          isValid = false;
        break;
    }
    return isValid;
  }


  BindPlanById(tenant_id: number, plan_id: number, client_id: number) {
    if (plan_id > 0) {
      this.planDetail$ = this.planmasterService.getSubscriptionPlanServiceById(tenant_id, plan_id);
      this.planDetail$.subscribe({
        next: (i: GetPlanMasterByIdResponse) => {
          console.log('planDetail$');
          this.bill_cycle_day = i.bill_cycle_day;
          this.billing_interval_type$.next(i.billing_interval_type.toString());
          this.billing_interval_value$.next(i.billing_interval_value);
          this.subscription_category$.next(i.subscription_category);

          if (i.subscription_category == PlanType.Contract || i.subscription_category == PlanType.ContractConvertsToPayAsYouGo
            || i.subscription_category == PlanType.PayAsYouGo || i.feature_is_cash_recurring) {
            this.onlinePayment = true;
          }
          if (
            i.subscription_category == PlanCategory.DropIn.toString()
            || i.subscription_category == PlanCategory[PlanCategory.DropIn]
            || i.subscription_category == PlanCategory.PaidInFull.toString()
            || i.subscription_category == PlanCategory[PlanCategory.PaidInFull]
            || i.feature_is_cash_recurring) {
            this.isCashRecurring = true;
          }

          //set is Contract.
          this.SetContractRecurring(i.contract_term, i.feature_is_cash_recurring);

          this.SetBillingCycle_init();

          this.bindPlanBreakUp(tenant_id, plan_id, client_id);
        },
        error: (error: AppErrors) => {
          this.errorMessages = [];
          if (error instanceof NotFoundError) {
            this.errMsg = error.originalError.message;
          } else {
            if (error.originalError.status == 500) {
              this.errorMessages.push(error.originalError.error.errorMessage);
              this.errorDataModal = this.errorMessages;
              this.showErrorPopup?.next(true);
              this.errorMessages = [];
            } else {
              error.originalError.error.forEach((e: any) => {
                this.errorMessages.push(e.errorMessage);
              });
              this.errorDataModal = this.errorMessages;
              this.showErrorPopup?.next(true);

            }
          }
        },
        complete: () => {
        }
      });
    }
  }


  SetContractRecurring(contract_term: number, feature_is_cash_recurring: boolean) {
    if (contract_term != 1)
      this.isContract$.next(true);
    if (feature_is_cash_recurring)
      this.isCashRecurring = true;

  }

  getPlanType(c: PlanCategory): string {
    let strCategory: string = '';
    switch (c.toString()) {
      case PlanCategory.Contract.toString():
      case PlanCategory[PlanCategory.Contract]:
        strCategory = PlanType.Contract.toUpperCase();
        break;
      case PlanCategory.ContractConvertsToPayAsYouGo.toString():
      case PlanCategory[PlanCategory.ContractConvertsToPayAsYouGo]:
        strCategory = PlanType.ContractConvertsToPayAsYouGo.toUpperCase();
        break;
      case PlanCategory.DropIn.toString():
      case PlanCategory[PlanCategory.DropIn]:
        strCategory = PlanType.DropIn.toUpperCase();
        break;
      case PlanCategory.PaidInFull.toString():
      case PlanCategory[PlanCategory.PaidInFull]:
        strCategory = PlanType.PaidInFull.toUpperCase();
        break;
      case PlanCategory.PayAsYouGo.toString():
      case PlanCategory[PlanCategory.PayAsYouGo]:
        strCategory = PlanType.PayAsYouGo.toUpperCase();
        break;
    }
    return strCategory;
  }

  getPlanAmount(p: PlanMasterList): number {
    let amt: number = 0;
    switch (p.subscription_category.toString()) {
      case PlanCategory.Contract.toString():
      case PlanCategory[PlanCategory.Contract]:
        amt = p.recurring_services_in_minor_currency;
        break;
      case PlanCategory.ContractConvertsToPayAsYouGo.toString():
      case PlanCategory[PlanCategory.ContractConvertsToPayAsYouGo]:
        amt = p.recurring_services_in_minor_currency;
        break;
      case PlanCategory.DropIn.toString():
      case PlanCategory[PlanCategory.DropIn]:
        amt = p.non_recurring_services_in_minor_currency;
        break;
      case PlanCategory.PaidInFull.toString():
      case PlanCategory[PlanCategory.PaidInFull]:
        amt = p.non_recurring_services_in_minor_currency;
        break;
      case PlanCategory.PayAsYouGo.toString():
      case PlanCategory[PlanCategory.PayAsYouGo]:
        amt = p.recurring_services_in_minor_currency;
        break;
    }
    return amt;
  }

  SetBillingCycle() {
    let day: number = 0;
    let weekDays: number = (this.subscriptionStartdate.value).getDay();
    let days: any[] = [];

    if (this.billing_interval_type$.value.toString() == PlanIntervalType[PlanIntervalType.Month]) {
      let d: string[] = this.bill_cycle_day.split(',');
      day = new Date(this.subscriptionStartdate.value).getDate();

      if (this.billingCycleOption$.value.find((v: any) => v.isSignup == true)) {
        this.billingCycleOption$.value.find((v: any) => v.isSignup == true).value = day.toString();
      }
    }
    if (this.billing_interval_type$.value.toString() == PlanIntervalType[PlanIntervalType.Week]) {
      let d: string[] = this.bill_cycle_day.split(',');
      day = new Date(this.subscriptionStartdate.value).getDay();

      if (this.billingCycleOption$.value.find((v: any) => v.isSignup == true)) {
        this.billingCycleOption$.value.find((v: any) => v.isSignup == true).value = (weekDays + 1) * -1;
      }
    }
  }

  SetBillingCycle_init() {
    let day: number = 0;
    let weekDays: number = (this.subscriptionStartdate.value).getDay();
    let days: any[] = [];

    if (this.billing_interval_type$.value.toString() == PlanIntervalType[PlanIntervalType.Month]) {
      let d: string[] = this.bill_cycle_day.split(',');
      day = new Date(this.subscriptionStartdate.value).getDate();

      d.forEach((obj: string) => {
        if (parseInt(obj) == 0) {
          //if (d.indexOf(day.toString()) < 0) {
          this.translate.get('POS.MEMBERSHIP_ADDON').subscribe(translation => {
            days.push({ value: day, name: translation.SIGNUPDATE, isSignup: true });
          });
          //}
        }
        else
          days.push({ value: parseInt(obj), name: this.nth(parseInt(obj)) + ' of month', isSignup: false });
      });
      //let nearestDay = days.find((v: any) => v.value >= days) || null;

      let nearestDay: number = 0;
      let subDate = this.subscriptionStartdate.value;

      for (let i = 0; i < 32; i++) {
        days.forEach((obj: any) => {
          if (subDate.getDate() == parseInt(obj["value"])) {
            nearestDay = days.find((v: any) => v.value == obj["value"]) || null;
          }
        });
        if (nearestDay)
          break;
        subDate.setDate(subDate.getDate() + 1);
      }

      if (nearestDay)
        this.billingCycle.setValue(nearestDay);
      else
        this.billingCycle.setValue(days[0]);
    }

    if (this.billing_interval_type$.value.toString() == PlanIntervalType[PlanIntervalType.Week]) {
      let d: string[] = this.bill_cycle_day.split(',');
      day = new Date(this.subscriptionStartdate.value).getDay();

      d.forEach((obj: string) => {
        if (parseInt(obj) == 0) {
          //if (d.indexOf(((weekDays + 1) * -1).toString()) < 0)
          //days.push({ value: (weekDays + 1) * -1, name: this.weekDays[weekDays], isSignup: true });

          this.translate.get('POS.MEMBERSHIP_ADDON').subscribe(translation => {
            days.push({ value: (weekDays + 1) * -1, name: translation.SIGNUPDATE, isSignup: true });
          });
        }
        else
          days.push({ value: parseInt(obj), name: this.weekDays[(parseInt(obj) * -1) - 1], isSignup: false });
      });

      let nearestDay: number = 0;
      //let nearestDay = days.find((v: any) => v.value <= days) || null;      

      let subDate = this.subscriptionStartdate.value;

      for (let i = 0; i < 8; i++) {
        days.forEach((obj: any) => {
          if ((subDate.getDay() + 1) * -1 == parseInt(obj["value"])) {
            nearestDay = days.find((v: any) => v.value == obj["value"]) || null;
          }
        });
        if (nearestDay)
          break;
        subDate.setDate(subDate.getDate() + 1);
      }

      if (nearestDay)
        this.billingCycle.setValue(nearestDay);
      else
        this.billingCycle.setValue(days[0]);
    }
    this.billingCycleOption$.next(days);
  }


  //#region

  allServiceNameHtml(oData: GetPlanMasterByIdResponse): string {
    let serviceCategoryName: string[] = [];
    let serviceCategory: any[] = [];
    oData.recurring_services.forEach((oService: PlanMasterServices) => {
      if (serviceCategoryName.indexOf(oService.name_primary_language) == -1) {
        serviceCategoryName.push(oService.name_primary_language);
        serviceCategory.push({ name: oService.name_primary_language, isBaseMembership: oService.is_base_membership });
      }
    });

    let str: string = `
              {{First}}
              {{Others}}
    `;

    let other: string = '';
    let first: string = '';
    for (let i = 0; i < serviceCategory.length; i++) {
      if (i == 0)
        first = serviceCategory[i].name + (serviceCategory[i].isBaseMembership ? ' <span class="inline-block px-2 line-height-4 text-xxs bg-gray-200 border-round" > Base Membership </span>' : '');
      else
        other = other + '<br class="md:hidden"> + ' + serviceCategory[i].name + (serviceCategory[i].isBaseMembership ? ' <span class="inline-block px-2 line-height-4 text-xxs bg-gray-200 border-round" > Base Membership </span>' : '');
    }

    return str.replace('{{First}}', first).replace('{{Others}}', other);
  }


  bindPlanBreakUp(tenant_id: number, plan_id: number, client_id: number): void {
    let planBreakUpCalculationRequest: PlanBreakUpCalculationRequest = {
      client_id: this.clientId,
      coupon_code: '',
      is_coupon_code_applied: false,
      issue_date: this.formatDate(this.billDate.value),
      subscription_bill_cycle_day: (this.subscription_category$.value.toString() == PlanCategory.DropIn.toString()
        || this.subscription_category$.value == PlanCategory[PlanCategory.DropIn])
        ? this.subscriptionStartdate.value.getDate() : this.billingCycle.value.value,
      subscription_start_date: this.formatDate(this.subscriptionStartdate.value)
    };    
    this.plansubscriptionService.getPlanBreakUpCalculation(tenant_id, plan_id, planBreakUpCalculationRequest).subscribe({
      next: (_objResponse: PlanBreakUpCalculationResponse) => {
        this.errorMessages = [];

        if (_objResponse.plan_breakup_calculation != null) {
          this.planBreakUpCalculationResponse$.next(_objResponse);
          this.planBreakUpCalculation$.next(_objResponse.plan_breakup_calculation);
          this.overLappingSubscriptionSignUp$.next(_objResponse.overlapping_subscription);
          this.initializeDynamicControl(_objResponse.plan_breakup_calculation);
        }
        else {
          if (_objResponse.overlapping_subscription.overlapping_subscription_signup != null) {
            this.errorMessages.push(_objResponse.overlapping_subscription.overlapping_subscription_signup.overlapping_errormessage);
            this.errorDataModal = this.errorMessages;
            this.showErrorPopup?.next(true);
          }
          else {
            if (_objResponse.overlapping_subscription.overlapping_client_subscription.new_date != null) {
              this.translate.get('POS.MEMBERSHIP_ADDON.OVERLAP_SUBSCRIPTION',
                {
                  date:
                    this.timeService.formatDate(_objResponse.overlapping_subscription.overlapping_client_subscription.new_date.subs_start_date, 'mediumdate')
                }).subscribe(translation => {
                  this.errorMessages.push(translation);
                  this.errorDataModal = this.errorMessages;
                  this.showErrorPopup?.next(true);
                });
            }
            else {
              this.errorMessages.push(_objResponse.overlapping_subscription.overlapping_client_subscription.overlapping_errormessage);
              this.errorDataModal = this.errorMessages;
              this.showErrorPopup?.next(true);
            }
          }
        }
      },
      error: (error: AppErrors) => {
        this.errorMessages = [];
        if (error instanceof NotFoundError) {
          this.errMsg = error.originalError.message;
        } else {
          if (error.originalError.status == 500) {
            this.errorMessages.push(error.originalError.error.errorMessage);
            this.errorDataModal = this.errorMessages;
            this.showErrorPopup?.next(true);
          } else {
            error.originalError.error.forEach((e: any) => {
              this.errorMessages.push(e.errorMessage);
            });
            this.errorDataModal = this.errorMessages;
            this.showErrorPopup?.next(true);
          }
        }
      },
      complete: () => {

      }
    });
  }


  getChargesNetCost(taxableAmount: number, taxAmount: number): number {
    return (this.isInclusive) ? taxableAmount + taxAmount : taxableAmount;
  }

  getNetCost(taxableAmount: number, taxAmount: number): number {
    return (this.isInclusive) ? taxableAmount + taxAmount : taxableAmount;
  }

  nth(i: number) {
    var j = i % 10, k = i % 100;
    if (j == 1 && k != 11) { return i + "st"; }
    if (j == 2 && k != 12) { return i + "nd"; }
    if (j == 3 && k != 13) { return i + "rd"; }
    return i + "th";
  }


  initializeDynamicControl(_objResponse: PlanBreakUpCalculation) {

    this.frmFirstTimeBaseCostAmount = this.getTotalBaseCost(_objResponse.charging_today.service_first_charge.services);
    this.netCost$.subscribe((obj: number) => {
      this.firstTimeAmount = this.commonService.ConvertAmountFromMinorCurrency(obj);
    })
    this.firstTimeTaxAmount = this.getTotalTaxAmount(_objResponse.charging_today.service_first_charge.services);


    this.frmNonRecurringBaseCostAmount = this.getTotalBaseCost(_objResponse.charging_today.service_non_recurring.services);

    this.nonRecurringNetCost$.subscribe((obj: number) => {
      this.nonRecurringAmount = this.commonService.ConvertAmountFromMinorCurrency(obj);
    })
    // this.nonRecurringAmount = this.commonService.ConvertAmountFromMinorCurrency(parseInt(this.getTotal(_objResponse.charging_today.service_non_recurring.services)));
    this.nonRecurringTaxAmount = this.getTotalTaxAmount(_objResponse.charging_today.service_non_recurring.services);

    this.recurringMembershipBaseCost = this.getTotalBaseCost(_objResponse.charging_recurring.services);

    // //DEME
    // this.recurringMembershipAmount = 
    // this.commonService.ConvertAmountFromMinorCurrency(parseInt(this.getTotal(_objResponse.charging_recurring.services)));

    // //DEME

    this.netCostRecurring$.subscribe((obj: number) => {
      this.recurringMembershipAmount = this.commonService.ConvertAmountFromMinorCurrency(obj);
    })

    this.recurringMembershipTaxAmount = this.getTotalTaxAmount(_objResponse.charging_recurring.services);

    this.firstTimeDiscountFormControl.addValidators([Validators.required, Validators.min(0)]);
    this.nonRecurringDiscountFormControl.addValidators([Validators.required, Validators.min(0)]);


    let fg: FormGroup = new FormGroup({});
    _objResponse.charging_today.signup_charges.forEach((value: ChargeBreakUp, index: number) => {

      fg.addControl('chargesbasecost_' + value.id.toString(), new FormControl(value.amount_in_minor_currency, [Validators.required]));
      fg.addControl('charges_' + value.id.toString(), new FormControl(this.commonService.ConvertAmountFromMinorCurrency(this.getChargeNetCost(value)), [Validators.required]));
      fg.addControl('discount_' + value.id.toString(), new FormControl(value.discount_amt_in_minor_currency, Validators.required));

    });
    this.charges.push(fg);

  }

  calculateDiscount(basePriceCtrl: FormControl, amtCtrl: FormControl, disCtrl: FormControl) {

    let lineItemChargeAmount: number = 0;
    lineItemChargeAmount = this.commonService.ConvertAmountFromMinorCurrency(basePriceCtrl.value);
    if (
      lineItemChargeAmount - (lineItemChargeAmount * this.single_staff_discount / 100) <= amtCtrl.value
      && lineItemChargeAmount >= amtCtrl.value
    ) {
      disCtrl.setValue(100 - ((amtCtrl.value * 100) / lineItemChargeAmount));  // set discount
      this.inValidDiscount = true;
    }
    else {
      //await this.timeout(1000);
      this.userDiscountLimit = this.single_staff_discount;
      amtCtrl.setValue(lineItemChargeAmount);
      disCtrl.setValue(0);
      this.inValidDiscount = true
    }
  }


  calculateAmount(basePriceCtrl: FormControl, amtCtrl: FormControl, disCtrl: FormControl) {

    let lineItemChargeAmount: number = basePriceCtrl.value;
    lineItemChargeAmount = this.commonService.ConvertAmountFromMinorCurrency(lineItemChargeAmount);
    let netValue = lineItemChargeAmount - (lineItemChargeAmount * disCtrl.value / 100);
    if (lineItemChargeAmount - (lineItemChargeAmount * this.single_staff_discount / 100) <= netValue) {
      amtCtrl.setValue(netValue);
      this.inValidDiscount = true;
    }
    else {
      setTimeout((obj: any) => {
        this.userDiscountLimit = this.single_staff_discount;

        amtCtrl.setValue(lineItemChargeAmount);
        disCtrl.setValue(0);
        this.inValidDiscount = false
      }, 10)
    }
  }

  calculateChargeDiscount(lineItemChargeAmount: number, amtCtrl: string, disCtrl: string) {

    lineItemChargeAmount = this.commonService.ConvertAmountFromMinorCurrency(lineItemChargeAmount);

    let amtControl: any = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup).controls[amtCtrl];
    let disControl: any = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup).controls[disCtrl];

    if (lineItemChargeAmount - (lineItemChargeAmount * this.single_staff_discount / 100) <= amtControl.value
      && lineItemChargeAmount >= amtControl.value) {
      disControl.setValue(100 - ((amtControl.value * 100) / lineItemChargeAmount));  // set discount
      this.inValidDiscount = true;
    }
    else {
      setTimeout((obj: any) => {
        this.userDiscountLimit = this.single_staff_discount;

        amtControl.setValue(lineItemChargeAmount);
        disControl.setValue(0);
        this.inValidDiscount = false

      }, 10)
    }
  }

  calculateChargeAmount(lineItemChargeAmount: number, amtCtrl: string, disCtrl: string) {

    let amtControl: any = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup).controls[amtCtrl];
    let disControl: any = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup).controls[disCtrl];

    lineItemChargeAmount = this.commonService.ConvertAmountFromMinorCurrency(lineItemChargeAmount);
    let netValue = lineItemChargeAmount - (lineItemChargeAmount * disControl.value / 100);

    if (lineItemChargeAmount - (lineItemChargeAmount * this.single_staff_discount / 100) <= netValue) {
      amtControl.setValue(netValue);
      this.inValidDiscount = true;
    }
    else {
      setTimeout((obj: any) => {
        this.userDiscountLimit = this.single_staff_discount;
        amtControl.setValue(lineItemChargeAmount);
        disControl.setValue(0);
        this.inValidDiscount = false

      }, 10)
    }
  }

  calculateRecurringAmount(basePriceCtrl: FormControl, amtCtrl: FormControl, disCtrl: FormControl) {

    let lineItemChargeAmount: number = basePriceCtrl.value;
    lineItemChargeAmount = this.commonService.ConvertAmountFromMinorCurrency(lineItemChargeAmount);
    let netValue = lineItemChargeAmount - (lineItemChargeAmount * disCtrl.value / 100);
    if (lineItemChargeAmount - (lineItemChargeAmount * this.recurring_staff_discount / 100) <= netValue) {
      amtCtrl.setValue(netValue);
      this.inValidDiscount = true;
    }
    else {
      setTimeout((obj: any) => {
        this.userDiscountLimit = this.recurring_staff_discount;
        amtCtrl.setValue(lineItemChargeAmount);
        disCtrl.setValue(0);
        this.inValidDiscount = false
      }, 10)
    }
  }

  calculateRecurringDiscount(basePriceCtrl: FormControl, amtCtrl: FormControl, disCtrl: FormControl) {

    let lineItemChargeAmount: number = 0;
    lineItemChargeAmount = this.commonService.ConvertAmountFromMinorCurrency(basePriceCtrl.value);
    if (
      lineItemChargeAmount - (lineItemChargeAmount * this.recurring_staff_discount / 100) <= amtCtrl.value
      && lineItemChargeAmount >= amtCtrl.value
    ) {
      disCtrl.setValue(100 - ((amtCtrl.value * 100) / lineItemChargeAmount));  // set discount
      this.inValidDiscount = true;
    }
    else {
      setTimeout((obj: any) => {
        this.userDiscountLimit = this.recurring_staff_discount;
        // await this.timeout(1000);
        amtCtrl.setValue(lineItemChargeAmount);
        disCtrl.setValue(0);
        this.inValidDiscount = true
      }, 10)
    }
  }

  get charges(): FormArray { return this.fgProducts.get('charges') as FormArray; }

  ordinal_suffix_of(i: any) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  getTotalBaseCost(serviceBreakUp: ServiceBreakUp[]): number {
    return serviceBreakUp?.reduce(function (acc, obj) { return acc + obj.base_cost_in_minor_currency; }, 0);
  }

  getTotal(serviceBreakUp: ServiceBreakUp[]): string {
    return serviceBreakUp.reduce(function (acc, obj) { return acc + obj.taxable_cost_in_minor_currency; }, 0).toString();
  }

  getTotalTaxAmount(serviceBreakUp: ServiceBreakUp[]): number {
    let taxAmount = 0;
    serviceBreakUp?.forEach((value: ServiceBreakUp, index: number) => {
      if (value.tax)
        taxAmount = taxAmount + (value.tax.reduce(function (acc, obj) { return acc + obj.amt_in_minor_currency; }, 0))
    })

    return taxAmount;
  }

  getTaxAmount(tax: TaxLineItemBreakUp[]): number {
    let taxAmount = 0;
    if (tax)
      taxAmount = tax.reduce(function (acc, obj) { return acc + obj.amt_in_minor_currency; }, 0)
    return taxAmount;
  }

  getTotalChargeTaxAmount(charge: ChargeBreakUp[]): number {
    let taxAmount = 0;
    charge.forEach((value: ChargeBreakUp, index: number) => {
      taxAmount = taxAmount + (value.tax.reduce(function (acc, obj) { return acc + obj.amt_in_minor_currency; }, 0))
    })
    return taxAmount;
  }

  getConcatStringfromArray(serviceBreakUp: PlanMasterServices[]): string {
    let cats = serviceBreakUp?.map((item) => {
      return item.name_primary_language;
    }).join("+ ");
    return cats;
  }

  StaffDiscountCancel() {
    this.inValidDiscount = true;
    this.planBreakUpCalculation$.subscribe((_objResponse: PlanBreakUpCalculation | null) => {
      if (_objResponse) {
        this.netCost$.subscribe((obj: number) => {
          this.firstTimeAmount = this.commonService.ConvertAmountFromMinorCurrency(obj);
          if (this.getTotalBaseCost(_objResponse.charging_today.service_first_charge.services) - this.commonService.ConvertMinorCurrencyFromAmount(this.firstTimeAmount) == 0) {
            this.firstTimeDiscount = 0;
          }
          else {
            this.firstTimeDiscount = 100 - this.commonService.ConvertMinorCurrencyFromAmount(this.firstTimeAmount) * 100 / this.getTotalBaseCost(_objResponse.charging_today.service_first_charge.services);
          }
        })

        this.nonRecurringNetCost$.subscribe((obj: number) => {
          this.nonRecurringAmount = this.commonService.ConvertAmountFromMinorCurrency(obj);

          if (this.getTotalBaseCost(_objResponse.charging_today.service_non_recurring.services) - this.commonService.ConvertMinorCurrencyFromAmount(this.nonRecurringAmount) == 0) {
            this.nonRecurringDiscount = 0;
          }
          else {
            this.nonRecurringDiscount = 100 - this.commonService.ConvertMinorCurrencyFromAmount(this.nonRecurringAmount) * 100 / this.getTotalBaseCost(_objResponse.charging_today.service_non_recurring.services);
          }
        })

        this.netCostRecurring$.subscribe((obj: number) => {
          this.recurringMembershipAmount = this.commonService.ConvertAmountFromMinorCurrency(obj);

          if (this.getTotalBaseCost(_objResponse.charging_recurring.services) - this.commonService.ConvertMinorCurrencyFromAmount(this.recurringMembershipAmount) == 0) {
            this.recurringMembershipDiscount = 0;
          }
          else {
            this.recurringMembershipDiscount = 100 - this.commonService.ConvertMinorCurrencyFromAmount(this.recurringMembershipAmount) * 100 / this.getTotalBaseCost(_objResponse.charging_recurring.services);
          }
        })

        let frmCharges: FormGroup = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup);
        let controlIds: Array<number> = [];

        Object.keys(frmCharges.controls).forEach(key => {
          if (!controlIds.includes(parseInt(key.substring(key.indexOf('_') + 1))))
            controlIds.push(parseInt(key.substring(key.indexOf('_') + 1)));
        });

        let fg: FormGroup = new FormGroup({});
        _objResponse.charging_today.signup_charges.forEach((value: ChargeBreakUp, index: number) => {
          controlIds.forEach(val => {
            if (value.id.toString() == val.toString()) {

              frmCharges.controls['charges_' + val.toString()].setValue(
                this.commonService.ConvertAmountFromMinorCurrency(this.getChargeNetCost(value)));
              if (frmCharges.controls['chargesbasecost_' + val.toString()].value - this.getChargeNetCost(value) == 0) {
                frmCharges.controls['discount_' + val.toString()].setValue(0);
              }
              else {
                frmCharges.controls['discount_' + val.toString()].setValue(
                  100 - (this.getChargeNetCost(value) * 100 / frmCharges.controls['chargesbasecost_' + val.toString()].value));
              }
            }
          });
        });
      }
    });
  }

  StaffDiscountBreakup() {
    let staffDiscountCalculationRequest = {} as StaffDiscountCalculationRequest;
    let staffDiscountChargeRecurring = {} as StaffDiscountChargeRecurring;
    let staffDiscountChargingTodayRequest = {} as StaffDiscountChargingTodayRequest;
    let staffDiscountSignUpChargeRequest: StaffDiscountSignUpChargeRequest[] = [];

    staffDiscountChargingTodayRequest.service_first_charge = {} as StaffDiscountServiceFirstChargeRequest;
    staffDiscountChargingTodayRequest.service_non_recurring = {} as StaffDiscountServiceNonRecurringRequest;
    staffDiscountChargeRecurring.service_recurring = {} as StaffDiscountServiceRecurringRequest;
    staffDiscountCalculationRequest.client_id = this.clientId;
    staffDiscountCalculationRequest.subscription_bill_cycle_day = this.billingCycle.value.value;
    staffDiscountCalculationRequest.issue_date = this.billDate.value;
    staffDiscountCalculationRequest.subscription_start_date = this.subscriptionStartdate.value;
    staffDiscountChargingTodayRequest.service_first_charge.discount_amt_in_minor_currency = 0;
    staffDiscountChargingTodayRequest.service_non_recurring.discount_amt_in_minor_currency = 0;
    staffDiscountChargeRecurring.service_recurring.discount_amt_in_minor_currency = 0;

    if (this.frmFirstTimeBaseCostAmount)
      staffDiscountChargingTodayRequest.service_first_charge.discount_amt_in_minor_currency =
        this.frmFirstTimeBaseCostAmount - Math.round(this.commonService.ConvertMinorCurrencyFromAmount(this.firstTimeAmount));

    if (this.frmNonRecurringBaseCostAmount)
      staffDiscountChargingTodayRequest.service_non_recurring.discount_amt_in_minor_currency =
        this.frmNonRecurringBaseCostAmount - Math.round(this.commonService.ConvertMinorCurrencyFromAmount(this.nonRecurringAmount));

    if (this.recurringMembershipBaseCost)
      staffDiscountChargeRecurring.service_recurring.discount_amt_in_minor_currency =
        this.recurringMembershipBaseCost - Math.round(this.commonService.ConvertMinorCurrencyFromAmount(this.recurringMembershipAmount));

    staffDiscountCalculationRequest.charging_recurring = staffDiscountChargeRecurring;

    let frmCharges: FormGroup = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup);
    let controlIds: Array<number> = [];

    //get charges control id.
    Object.keys(frmCharges.controls).forEach(key => {
      if (!controlIds.includes(parseInt(key.substring(key.indexOf('_') + 1))))
        controlIds.push(parseInt(key.substring(key.indexOf('_') + 1)));
    });

    controlIds.forEach(val => {
      let staffDiscountSignupCharge = {} as StaffDiscountSignUpChargeRequest;
      staffDiscountSignupCharge.id = val;
      staffDiscountSignupCharge.discount_amt_in_minor_currency =
        frmCharges.controls['chargesbasecost_' + val.toString()].value
        - Math.round(this.commonService.ConvertMinorCurrencyFromAmount(frmCharges.controls['charges_' + val.toString()].value));

      staffDiscountSignUpChargeRequest.push(staffDiscountSignupCharge);

      //Set Value.
      staffDiscountChargingTodayRequest.signup_charges = staffDiscountSignUpChargeRequest;
      staffDiscountCalculationRequest.charging_today = staffDiscountChargingTodayRequest;

    });

    this.plansubscriptionService.getStaffDiscountCalculation(this.tenantId, this.subscriptionPlanId, staffDiscountCalculationRequest).subscribe({
      next: (_objResponse: PlanBreakUpCalculationResponse) => {
        console.log(_objResponse);
        this.planBreakUpCalculationResponse$.next(_objResponse);
        this.planBreakUpCalculation$.next(_objResponse.plan_breakup_calculation);
        this.overLappingSubscriptionSignUp$.next(_objResponse.overlapping_subscription);

      }
    });

    //get value and store it in the object
    //get MonthlyFees
    //get MonthlyFees


  }

  RemoveDiscount(): void {

    let staffDiscountCalculationRequest = {} as StaffDiscountCalculationRequest;

    let staffDiscountChargeRecurring = {} as StaffDiscountChargeRecurring;
    let staffDiscountChargingTodayRequest = {} as StaffDiscountChargingTodayRequest;
    let staffDiscountSignUpChargeRequest: StaffDiscountSignUpChargeRequest[] = [];

    staffDiscountChargingTodayRequest.service_first_charge = {} as StaffDiscountServiceFirstChargeRequest;
    staffDiscountChargingTodayRequest.service_non_recurring = {} as StaffDiscountServiceNonRecurringRequest;
    staffDiscountChargeRecurring.service_recurring = {} as StaffDiscountServiceRecurringRequest;


    staffDiscountCalculationRequest.client_id = this.clientId;
    staffDiscountCalculationRequest.subscription_bill_cycle_day = this.billingCycle.value.value;
    staffDiscountCalculationRequest.issue_date = this.billDate.value;
    staffDiscountCalculationRequest.subscription_start_date = this.subscriptionStartdate.value;

    staffDiscountChargingTodayRequest.service_first_charge.discount_amt_in_minor_currency = 0;
    staffDiscountChargingTodayRequest.service_non_recurring.discount_amt_in_minor_currency = 0;
    staffDiscountChargeRecurring.service_recurring.discount_amt_in_minor_currency = 0;
    staffDiscountCalculationRequest.charging_recurring = staffDiscountChargeRecurring;

    let frmCharges: FormGroup = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup);
    let controlIds: Array<number> = [];

    //get charges control id.
    Object.keys(frmCharges.controls).forEach(key => {
      if (!controlIds.includes(parseInt(key.substring(key.indexOf('_') + 1))))
        controlIds.push(parseInt(key.substring(key.indexOf('_') + 1)));
    });

    controlIds.forEach(val => {
      let staffDiscountSignupCharge = {} as StaffDiscountSignUpChargeRequest;
      staffDiscountSignupCharge.id = val;
      staffDiscountSignupCharge.discount_amt_in_minor_currency = 0;
      staffDiscountSignUpChargeRequest.push(staffDiscountSignupCharge);
      //Set Value.
      staffDiscountChargingTodayRequest.signup_charges = staffDiscountSignUpChargeRequest;
      staffDiscountCalculationRequest.charging_today = staffDiscountChargingTodayRequest;
    });

    this.plansubscriptionService.getStaffDiscountCalculation(this.tenantId, this.subscriptionPlanId, staffDiscountCalculationRequest).subscribe({
      next: (_objResponse: PlanBreakUpCalculationResponse) => {
        console.log(_objResponse);
        this.planBreakUpCalculationResponse$.next(_objResponse);
        this.planBreakUpCalculation$.next(_objResponse.plan_breakup_calculation);
        this.overLappingSubscriptionSignUp$.next(_objResponse.overlapping_subscription);

        this.firstTimeDiscount = 0;
        this.nonRecurringDiscount = 0;
        this.recurringMembershipDiscount = 0;

        let frmCharges: FormGroup = (((this.fgProducts.controls.charges as FormArray).controls)[0] as FormGroup);

        _objResponse.plan_breakup_calculation.charging_today.signup_charges.forEach((value: ChargeBreakUp, index: number) => {

          frmCharges.controls['chargesbasecost_' + value.id.toString()].setValue(value.amount_in_minor_currency);
          frmCharges.controls['charges_' + value.id.toString()].setValue(this.commonService.ConvertAmountFromMinorCurrency(this.getChargeNetCost(value)));
          frmCharges.controls['discount_' + value.id.toString()].setValue(value.discount_amt_in_minor_currency);

        });
      }
    });

  }


  GetTaxMode(tenant_id: number) {
    this.paymentModeMaster$ = this.paymentModeService.listPaymentMode(tenant_id)
    this.paymentModeMaster$.subscribe({
      next: (listpaymentMode: ListPaymentModeMastersResponse) => {
        return listpaymentMode;
      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          // this.errMsg = error;
        } else {
          alert('something went wrong');
        }
      }
    });
  }


  async GetRevisedPrice_OnStartDateChange() {
    this.fgProducts.controls.charges = new FormArray([]);
    this.SetBillingCycle();
    this.bindPlanBreakUp(this.tenantId, this.subscriptionPlanId, this.clientId);
  }

  async GetRevisedPrice_OnCycleChange() {
    this.fgProducts.controls.charges = new FormArray([]);
    ////this.SetBillingCycle();
    this.bindPlanBreakUp(this.tenantId, this.subscriptionPlanId, this.clientId);
  }


  //#region "getproperty"
  get planList() {
    return this.fg.controls.ddlplanSubscriptionList as FormControl;
  }

  get billingCycle() {
    return this.fg.controls.ddlBillingCycle as FormControl;
  }

  get billCycle() {
    return this.billingCycle.value;
  }

  get billingOwner() {
    return this.fg.controls.billingOwner as FormControl;
  }

  get billDate() {
    return this.fg.controls.billdata as FormControl;
  }

  get subscriptionStartdate() {
    return this.fg.controls.subscriptionstartdate as FormControl;
  }

  //#endregion "getproperty"


  // #region "TotalCost"


  get frmgetRecurringAmount(): number {
    return this.frmFirstTimeBaseCostAmountFormControl.value as number;
  }

  // #endregion "TotalCost"



  //#region BaseCost
  get frmFirstTimeBaseCostAmountFormControl(): FormControl {
    return this.fgProducts.controls.frmFirstTimeBaseCostAmt as FormControl;
  }

  get frmFirstTimeBaseCostAmount(): number {
    return this.frmFirstTimeBaseCostAmountFormControl.value as number;
  }

  set frmFirstTimeBaseCostAmount(val: number) {
    this.frmFirstTimeBaseCostAmountFormControl.setValue(val);
  }

  get frmNonRecurringBaseCostAmountFormControl(): FormControl {
    return this.fgProducts.controls.frmNonRecurringBaseCostAmt as FormControl;
  }

  get frmNonRecurringBaseCostAmount(): number {
    return this.frmNonRecurringBaseCostAmountFormControl.value as number;
  }

  set frmNonRecurringBaseCostAmount(val: number) {
    this.frmNonRecurringBaseCostAmountFormControl.setValue(val);
  }

  //#region BaseCost

  get recurringCost$(): Observable<number> {
    let oThis = this;
    return this.planBreakUpCalculation$.pipe(
      map((value: PlanBreakUpCalculation | null) => {
        if (value) {
          return value.charging_recurring.services.reduce(function (acc, obj) {
            return acc + obj.taxable_cost_in_minor_currency + oThis.getTaxAmount(obj.tax);
          }, 0);
        }
        else
          return 0;
      })
    );
  }

  isPlanBehaviourRecurring(planCategory: string): boolean {
    return (
      planCategory == PlanCategory.Contract.toString()
      || planCategory == PlanCategory[PlanCategory.Contract]
      || planCategory == PlanCategory.ContractConvertsToPayAsYouGo.toString()
      || planCategory == PlanCategory[PlanCategory.ContractConvertsToPayAsYouGo]
      || planCategory == PlanCategory.PayAsYouGo.toString()
      || planCategory == PlanCategory[PlanCategory.PayAsYouGo]
    );
  }

  get netCost$(): Observable<number> {
    let oThis = this;
    return this.planBreakUpCalculationResponse$.pipe(
      map((value: PlanBreakUpCalculationResponse) => {
        if (value) {
          return value.plan_breakup_calculation.charging_today.service_first_charge.services?.reduce(function (acc, obj) {
            return acc + oThis.getNetCost(obj.taxable_cost_in_minor_currency, oThis.getTaxAmount(obj.tax));
          }, 0);
        }
        else {
          return 0;
        }
      })
    );
  }

  get nonRecurringNetCost$(): Observable<number> {
    let oThis = this;
    return this.planBreakUpCalculationResponse$.pipe(
      map((value: PlanBreakUpCalculationResponse) => {
        if (value) {
          return value.plan_breakup_calculation.charging_today.service_non_recurring.services.reduce(function (acc, obj) {
            return acc + oThis.getNetCost(obj.taxable_cost_in_minor_currency, oThis.getTaxAmount(obj.tax));
          }, 0);
        }
        else
          return 0;
      })
    );
  }

  getChargeNetCost(charge: ChargeBreakUp): number {
    return this.getNetCost(charge.taxable_cost_in_minor_currency, this.getTaxAmount(charge.tax));
  }

  get netCostRecurring$(): Observable<number> {
    let oThis = this;
    return this.planBreakUpCalculationResponse$.pipe(
      map((value: PlanBreakUpCalculationResponse) => {
        if (value) {
          return value.plan_breakup_calculation.charging_recurring.services.reduce(function (acc, obj) {
            return acc + oThis.getNetCost(obj.taxable_cost_in_minor_currency, oThis.getTaxAmount(obj.tax));
          }, 0);
        }
        else
          return 0;
      })
    );
  }

  get baseCostRecurring$(): Observable<number> {
    let oThis = this;
    return this.planBreakUpCalculationResponse$.pipe(
      map((value: PlanBreakUpCalculationResponse) => {
        if (value) {
          return value.plan_breakup_calculation.charging_recurring.services.reduce(function (acc, obj) {
            return acc + oThis.getNetCost(obj.base_cost_in_minor_currency, oThis.getTaxAmount(obj.tax));
          }, 0);
        }
        else
          return 0;
      })
    );
  }



  get totalOneTimeCost$(): Observable<number> {
    return this.planBreakUpCalculationResponse$.pipe(
      map((value: PlanBreakUpCalculationResponse) => {
        if (value) {
          let firstChargeCost: number = 0
          let firstChargeTaxCost: number = 0
          let nonRecurringChargeCost: number = 0
          let nonRecurringTaxChargeCost: number = 0
          let chargeCost: number = 0
          let chargeTaxCost: number = 0
          if (value && value.plan_breakup_calculation) {
            if (value.plan_breakup_calculation.charging_today.service_first_charge.services) {
              firstChargeCost = value.plan_breakup_calculation.charging_today.service_first_charge.services.reduce(function (acc, obj) {
                return acc + obj.taxable_cost_in_minor_currency;
              }, 0);
            }

            if (value.plan_breakup_calculation.charging_today.service_first_charge.services) {
              firstChargeTaxCost = this.getTotalTaxAmount(value.plan_breakup_calculation.charging_today.service_first_charge.services);
            }

            nonRecurringChargeCost = value.plan_breakup_calculation.charging_today.service_non_recurring.services.reduce(function (acc, obj) {
              return acc + obj.taxable_cost_in_minor_currency;
            }, 0);

            nonRecurringTaxChargeCost = this.getTotalTaxAmount(value.plan_breakup_calculation.charging_today.service_non_recurring.services);

            chargeCost = value.plan_breakup_calculation.charging_today.signup_charges.reduce(function (acc, obj) {
              return acc + obj.taxable_cost_in_minor_currency;
            }, 0);

            chargeTaxCost = this.getTotalChargeTaxAmount(value.plan_breakup_calculation.charging_today.signup_charges)

          }
          return firstChargeCost + firstChargeTaxCost + nonRecurringChargeCost + nonRecurringTaxChargeCost + chargeCost + chargeTaxCost;
        }
        else
          return 0;
      })
    );
  }



  //#region firstTimeAmount
  get firstTimeAmountFormControl(): FormControl {
    return this.fgProducts.controls.frmFirstTimeAmt as FormControl;
  }

  get firstTimeAmount(): number {
    return this.firstTimeAmountFormControl.value as number;
  }

  set firstTimeAmount(val: number) {
    this.firstTimeAmountFormControl.setValue(val);
  }

  get firstTimeTaxAmountFormControl(): FormControl {
    return this.fgProducts.controls.frmFirstTimeTaxAmt as FormControl;
  }

  get firstTimeTaxAmount(): number {
    return this.firstTimeTaxAmountFormControl.value as number;
  }

  set firstTimeTaxAmount(val: number) {
    this.firstTimeTaxAmountFormControl.setValue(val);
  }

  get firstTimeDiscountFormControl(): FormControl {
    return this.fgProducts.controls.frmFirstTimeDis as FormControl;
  }

  get firstTimeDiscount(): number {
    return this.firstTimeDiscountFormControl.value as number;
  }

  set firstTimeDiscount(val: number) {
    this.firstTimeDiscountFormControl.setValue(val);
  }

  //#endregion firstTimeAmount

  //#region nonRecurringAmount

  get nonRecurringAmountFormControl(): FormControl {
    return this.fgProducts.controls.frmNonRecurringAmt as FormControl;
  }

  get nonRecurringAmount(): number {
    return this.nonRecurringAmountFormControl.value as number;
  }

  set nonRecurringAmount(val: number) {
    this.nonRecurringAmountFormControl.setValue(val);
  }

  get nonRecurringTaxAmountFormControl(): FormControl {
    return this.fgProducts.controls.frmNonRecurringTaxAmt as FormControl;
  }

  get nonRecurringTaxAmount(): number {
    return this.nonRecurringTaxAmountFormControl.value as number;
  }

  set nonRecurringTaxAmount(val: number) {
    this.nonRecurringTaxAmountFormControl.setValue(val);
  }

  get nonRecurringDiscountFormControl(): FormControl {
    return this.fgProducts.controls.frmNonRecurringDis as FormControl;
  }

  get nonRecurringDiscount(): number {
    return this.nonRecurringDiscountFormControl.value as number;
  }

  set nonRecurringDiscount(val: number) {
    this.nonRecurringDiscountFormControl.setValue(val);
  }

  //#endregion nonRecurringAmount

  //#region RecurringAmount

  get recurringMembershipBaseCostFormControl(): FormControl {
    return this.fgProductsRecurring.controls.frmRecurringMembershipBaseCostAmt as FormControl;
  }

  get recurringMembershipBaseCost(): number {
    return this.recurringMembershipBaseCostFormControl.value as number;
  }

  set recurringMembershipBaseCost(val: number) {
    this.recurringMembershipBaseCostFormControl.setValue(val);
  }

  get recurringMembershipFormControl(): FormControl {
    return this.fgProductsRecurring.controls.frmRecurringMembershipAmt as FormControl;
  }

  get recurringMembershipAmount(): number {
    return this.recurringMembershipFormControl.value as number;
  }

  set recurringMembershipAmount(val: number) {

    this.recurringMembershipFormControl.setValue(val);
  }

  get recurringMembershipDiscountFormControl(): FormControl {
    return this.fgProductsRecurring.controls.frmRecurringMembershipDis as FormControl;
  }

  get recurringMembershipDiscount(): number {
    return this.recurringMembershipDiscountFormControl.value as number;
  }

  set recurringMembershipDiscount(val: number) {
    this.recurringMembershipDiscountFormControl.setValue(val);
  }

  get recurringMembershipTaxAmountFormControl(): FormControl {
    return this.fgProductsRecurring.controls.frmRecurringMembershipTaxAmt as FormControl;
  }

  get recurringMembershipTaxAmount(): number {
    return this.recurringMembershipTaxAmountFormControl.value as number;
  }

  set recurringMembershipTaxAmount(val: number) {
    this.recurringMembershipTaxAmountFormControl.setValue(val);
  }

  getfrmCash_Amount() {
    return this.commonService.ConvertMinorCurrencyFromAmount(this.frmCash_Amount);
  }

  getfrmCheque_Amount() {
    return this.commonService.ConvertMinorCurrencyFromAmount(this.frmCheque_Amount);
  }

  getfrmUpi_Amount() {
    return this.commonService.ConvertMinorCurrencyFromAmount(this.frmUpi_Amount);
  }


  getfrmCC_Amount() {
    return this.commonService.ConvertMinorCurrencyFromAmount(this.frmCC_Amount);
  }

  getfrmDC_Amount() {
    return this.commonService.ConvertMinorCurrencyFromAmount(this.frmDC_Amount);
  }

  changePaymentMode(evt: any) {
    console.log("event", evt);
  }

  //#endregion nonRecurringAmount


  //#region "Payment"

  //#region Dynamic Control

  get otherPaymentMethodFormControl(): FormControl {
    return this.fgPayment.controls.otherPaymentMode as FormControl;
  }

  get otherPaymentMethod(): string {
    console.log(this.otherPaymentMethodFormControl.value);
    return this.otherPaymentMethodFormControl.value as string;
  }

  set otherPaymentMethod(val: string) {
    this.otherPaymentMethodFormControl.setValue(val);
  }

  //#endregion Dynamic Control

  //#region Credit Card

  get frmCC_NumberFormControl(): FormControl {
    return this.fgPayment.controls.frmCCNumber as FormControl;
  }

  get frmCC_Number(): number {
    return this.frmCC_NumberFormControl.value as number;
  }

  set frmCC_Number(val: number) {
    this.frmCC_NumberFormControl.setValue(val);
  }


  get frmCC_NetworkTypeFormControl(): FormControl {
    return this.fgPayment.controls.frmCCNetworkType as FormControl;
  }

  get frmCC_NetworkType(): string {
    return this.frmCC_NetworkTypeFormControl.value as string;
  }

  set frmCC_NetworkType(val: string) {
    this.frmCC_NetworkTypeFormControl.setValue(val);
  }

  get frmCC_CardExpiryFormControl(): FormControl {
    return this.fgPayment.controls.frmCCExpiryDate as FormControl;
  }

  get frmCC_ExpiryDate(): Date {
    return this.frmCC_CardExpiryFormControl.value as Date;
  }

  set frmCC_ExpiryDate(val: Date) {
    this.frmCC_CardExpiryFormControl.setValue(val);
  }


  get frmCC_AuthCodeFormControl(): FormControl {
    return this.fgPayment.controls.frmCCAuthCode as FormControl;
  }

  get frmCC_AuthCode(): number {
    return this.frmCC_AuthCodeFormControl.value as number;
  }

  set frmCC_AuthCode(val: number) {
    this.frmCC_AuthCodeFormControl.setValue(val);
  }

  get frmCC_AmountFormControl(): FormControl {
    return this.fgPayment.controls.frmCCAmt as FormControl;
  }

  get frmCC_Amount(): number {
    return this.frmCC_AmountFormControl.value as number;
  }

  set frmCC_Amount(val: number) {
    this.frmCC_AmountFormControl.setValue(val);
  }
  //#endregion Credit Card

  //#region Debit Card
  get frmDC_NumberFormControl(): FormControl {
    return this.fgPayment.controls.frmDCNumber as FormControl;
  }

  get frmDC_Number(): number {
    return this.frmDC_NumberFormControl.value as number;
  }

  set frmDC_Number(val: number) {
    this.frmDC_NumberFormControl.setValue(val);
  }


  get frmDC_NetworkTypeFormControl(): FormControl {
    return this.fgPayment.controls.frmDCNetworkType as FormControl;
  }

  get frmDC_NetworkType(): string {
    return this.frmDC_NetworkTypeFormControl.value as string;
  }

  set frmDC_NetworkType(val: string) {
    this.frmDC_NetworkTypeFormControl.setValue(val);
  }

  get frmDC_CardExpiryFormControl(): FormControl {
    return this.fgPayment.controls.frmDCExpiryDate as FormControl;
  }

  get frmDC_ExpiryDate(): Date {
    return this.frmDC_NetworkTypeFormControl.value as Date;
  }

  set frmDC_ExpiryDate(val: Date) {
    this.frmDC_NetworkTypeFormControl.setValue(val);
  }


  get frmDC_AuthCodeFormControl(): FormControl {
    return this.fgPayment.controls.frmDCAuthCode as FormControl;
  }

  get frmDC_AuthCode(): number {
    return this.frmDC_AuthCodeFormControl.value as number;
  }

  set frmDC_AuthCode(val: number) {
    this.frmDC_AuthCodeFormControl.setValue(val);
  }

  get frmDC_AmountFormControl(): FormControl {
    return this.fgPayment.controls.frmDCAmt as FormControl;
  }

  get frmDC_Amount(): number {
    return this.frmDC_AmountFormControl.value as number;
  }

  set frmDC_Amount(val: number) {
    this.frmDC_AmountFormControl.setValue(val);
  }
  //#endregion Debit Card


  //#region Cheque
  get frmCheque_BankNameFormControl(): FormControl {
    return this.fgPayment.controls.frmChequeBankName as FormControl;
  }

  get frmCheque_BankName(): string {
    return this.frmCheque_BankNameFormControl.value as string;
  }

  set frmCheque_BankName(val: string) {
    this.frmCheque_BankNameFormControl.setValue(val);
  }


  get frmCheque_DateFormControl(): FormControl {
    return this.fgPayment.controls.frmChequeDate as FormControl;
  }

  get frmCheque_Date(): Date {
    return this.frmCheque_DateFormControl.value as Date;
  }

  set frmCheque_Date(val: Date) {
    this.frmCheque_DateFormControl.setValue(val);
  }

  get frmCheque_AmountFormControl(): FormControl {
    return this.fgPayment.controls.frmChequeAmount as FormControl;
  }

  get frmCheque_Amount(): number {
    return this.frmCheque_AmountFormControl.value as number;
  }

  set frmCheque_Amount(val: number) {
    this.frmCheque_AmountFormControl.setValue(val);
  }
  //#endregion Debit Card

  //#region UPI

  get frmCash_AmountFormControl(): FormControl {
    return this.fgPayment.controls.frmCashAmount as FormControl;
  }

  get frmCash_Amount(): number {
    return this.frmCash_AmountFormControl.value as number;
  }

  set frmCash_Amount(val: number) {
    this.frmCash_AmountFormControl.setValue(val);
  }

  //#endregion UPI

  //#region Cash

  get frmUpi_AmountFormControl(): FormControl {
    return this.fgPayment.controls.frmUpiAmount as FormControl;
  }

  get frmUpi_Amount(): number {
    return this.frmUpi_AmountFormControl.value as number;
  }

  set frmUpi_Amount(val: number) {
    this.frmUpi_AmountFormControl.setValue(val);
  }

  get frmUpiProviderFormControl(): FormControl {
    return this.fgPayment.controls.frmUpiProvider as FormControl;
  }

  get frmUpiProvider(): number {
    return this.frmUpiProviderFormControl.value as number;
  }

  set frmUpiProvider(val: number) {
    this.frmUpiProviderFormControl.setValue(val);
  }


  get frmUpiVpaidFormControl(): FormControl {
    return this.fgPayment.controls.frmUpiVpaid as FormControl;
  }

  get frmUpiVpaid(): number {
    return this.frmUpiVpaidFormControl.value as number;
  }

  set frmUpiVpaid(val: number) {
    this.frmUpiVpaidFormControl.setValue(val);
  }

  get billingInterval(): number {
    return this.billing_interval_value$.value;
  }

  get biilingIntervalType(): string {
    return this.billing_interval_type$.value;
  }
  //#endregion Cash

  //#endregion "Payment"

  formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  getPaymentBalance(totalAmt: number, ctrl: string) {
    console.log(totalAmt, this.fgPayment.get('inp_otherPaymentMode_' + ctrl.toString())?.value);
    return this.commonService.ConvertMinorCurrencyFromAmount(this.commonService.ConvertAmountFromMinorCurrency(totalAmt) - this.fgPayment.get('inp_otherPaymentMode_' + ctrl.toString())?.value)
  }

  paymentModeValidationSet() {

    this.totalOneTimeCost$.subscribe({
      next: (val: number) => {

        this.frmCash_AmountFormControl.addValidators([Validators.max(this.commonService.ConvertAmountFromMinorCurrency(val))]);
        this.frmCash_AmountFormControl.updateValueAndValidity();


        this.frmCheque_AmountFormControl.addValidators([Validators.max(this.commonService.ConvertAmountFromMinorCurrency(val))]);
        this.frmCheque_AmountFormControl.updateValueAndValidity();


        this.frmCC_AmountFormControl.addValidators([Validators.max(this.commonService.ConvertAmountFromMinorCurrency(val))]);
        this.frmCC_AmountFormControl.updateValueAndValidity();


        this.frmDC_AmountFormControl.addValidators([Validators.max(this.commonService.ConvertAmountFromMinorCurrency(val))]);
        this.frmDC_AmountFormControl.updateValueAndValidity();


        this.frmUpi_AmountFormControl.addValidators([Validators.max(this.commonService.ConvertAmountFromMinorCurrency(val))]);
        this.frmUpi_AmountFormControl.updateValueAndValidity();

        Object.keys(this.fgPayment.controls).forEach(key => {
          console.log(this.fgPayment.controls[key]);
          if (key.toLowerCase().startsWith("inp_otherpaymentmode_")) {
            this.fgPayment.controls[key].addValidators([Validators.max(this.commonService.ConvertAmountFromMinorCurrency(val))]);
            this.fgPayment.controls[key].updateValueAndValidity();
          }
        });
        console.log(val);
      },
      error: (error) => {
      }


    });
  }

  //#region "Error"

  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
  }

  GetErrorData(event: any) {
    this.errorMessages = event.errors;
    this.showErrorPopup?.next(event.showErrors);
  }

  get ShowErrorPopup(): Observable<boolean> {
    return this.showErrorPopup?.asObservable() || of(false);
  }

  getInterval(billing_interval_value: number, billing_interval_type: PlanIntervalType) {

    let str: string = "";
    if (billing_interval_type == PlanIntervalType.Month || billing_interval_type.toString() == PlanIntervalType[PlanIntervalType.Month]) {
      if (billing_interval_value > 1)
        str = "months";
      else
        str = "month";
    }
    else if (billing_interval_type == PlanIntervalType.Week
      || billing_interval_type.toString() == PlanIntervalType[PlanIntervalType.Week]) {
      if (billing_interval_value > 1)
        str = "weeks";
      else
        str = "week";
    }
    return str.toUpperCase();
  }

  isTypeContract(subscription_category: PlanCategory): boolean {
    if (
      (subscription_category == PlanCategory.DropIn || subscription_category.toString() == PlanCategory[PlanCategory.DropIn])
      || (subscription_category == PlanCategory.PaidInFull || subscription_category.toString() == PlanCategory[PlanCategory.PaidInFull])
    ) {
      return false;
    }
    else {
      return true;
    }
  }

  clientStatus(client: any): string {
    if (client && client.client_status)
      return this.clientService.getClientStatusHtml(client.client_status.toString());
    else
      return '';
  }
  //#endregion "Error"

  filterStaff() {
    let claim: Claim[] = {} as Claim[];
    let staffs: STAFFINTERFACE[] = [];
    ;

    let validStaff: number[] = [];
    this.claim$.pipe(
      combineLatestWith(this.store.select(STAFF_STATE))
    )
      .subscribe(([objClaim, objStaff]) => {
        claim = objClaim;
        staffs = objStaff.staffList;

        if (staffs.length > 0) {
          staffs.forEach((stf) => {
            if ((stf.role == Roles.Admin || stf.role.toString() == Roles[Roles.Admin] || stf.role == Roles.FFAdmin || stf.role.toString() == Roles[Roles.FFAdmin])
              || claim.filter(o => o.id == stf.claim_template_id && (o.can_admin_billing == true || o.can_do_billing == true)).length > 0)
              validStaff.push(stf.id);
          });
          this._filterStaff.next(validStaff);
        }
      })
  }

  ordinalNumber(n: number | null | undefined): string {
    if (n) {
      let s = ["th", "st", "nd", "rd"];
      let v: number = n % 100;
      return n.toString() + "<sup>" + (s[(v - 20) % 10] || s[v] || s[0]).toString() + "</sup> ";
    }
    else
      return '';
  }

  monthName(data: Date): string {
    return this.monthNames[new Date(data).getMonth()];
  }

}



@NgModule({
  declarations: [BillingSummaryComponent],
  imports: [
    FormsModule,
    InputNumberModule,
    ButtonModule,
    FooterComponentModule,
    HeaderComponentModule,
    SidebarComponentModule,
    SidebarModule,
    AvatarModule,
    AvatarGroupModule,
    DropdownModule,
    DividerModule,
    CalendarModule,
    ReactiveFormsModule,
    RadioButtonModule,
    MessagesModule,
    CommonModule,
    SkeletonModule,
    CurrencyformatPipeModule,
    AssignedToComponentModule,
    BrowserModule,
    DateTimeFormatPipeModule,
    ErrorsComponentModule,
    ErrorMsgComponentModule,
    StyleClassModule,
    InputTextModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [BillingSummaryComponent]
})
export class BillingSummaryComponentModule {
}
interface Option {
  name: string, value: number
}