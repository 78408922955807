import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { STAFFINTERFACE } from '@fitness-force/models';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { matchValidator } from '../../../validators/passwordMismatch.validator';
import { SpecialCharacterCheck } from '../../../validators/specialCharacter.validator';
import { MinimumNumericalCheck } from '../../../validators/minimunNumericCharCheck.validator';
import { StaffService } from 'libs/api/src/lib/staff.service';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { AppErrors, ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MinimumLowerCaseCheck } from '../../../validators/minimumLowercaseCheck.validator';
import { MinimumUpperCaseCheck } from '../../../validators/minimumUppercaseCheck.validator';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { COMPANY_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { ErrorsComponentModule } from '@fitness-force/shared';

@Component({
  selector: 'staff-change-password',
  templateUrl: './staff-change-password.component.html',
  styleUrls: ['./staff-change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaffChangePasswordComponent implements OnInit {
  @Input("visible") changePasswordModal: boolean = false;
  @Input("staff") staff: STAFFINTERFACE = {} as STAFFINTERFACE;
  @Output('closePopup') closePopup = new EventEmitter();
  public companyInfoDetail$!: Observable<any>;

  showErrorPopup = new BehaviorSubject(false);
  errorMessages = [] as any;
  error = new BehaviorSubject(null as (AppErrors | null));
  HideErrorPopup(event: any) {
    this.showErrorPopup?.next(false);
    this.errorMessages = [];
    this.error.next(null);
  }

  formGroup: FormGroup;
  constructor(private fb: FormBuilder, private staffService: StaffService, public translate: TranslateService, private store: Store) {
    this.formGroup = this.fb.group({
      old_password: new FormControl('', [Validators.required]),
      new_password: new FormControl('', [Validators.required,
      Validators.minLength(8),
      matchValidator('new_confirm_password', true),
      SpecialCharacterCheck('new_password', 1),
      MinimumNumericalCheck('new_password', 1),
      MinimumLowerCaseCheck('new_password', 1),
      MinimumUpperCaseCheck('new_password', 1)
      ]),
      new_confirm_password: new FormControl('', [Validators.required]),
    });

    this.formGroup.patchValue({ open_errorpoup: false, });
  }

  get OldPassword() {
    return this.formGroup.controls.old_password;
  }

  get NewPassword() {
    return this.formGroup.controls.new_password;
  }

  get NewConfirmPassword() {
    return this.formGroup.controls.new_confirm_password;
  }

  ngOnInit(): void {
    this.componentInit();
  }

  componentInit() {
    this.store.select(COMPANY_INFO).subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });
  }

  ValidateAndSubmit(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      let staffId = this.staff.id;
      let oldPassword = this.OldPassword.value;
      let newPassword = this.NewPassword.value;
      this.staffService.ChangePassword(environment.BASE_URL, staffId, oldPassword, newPassword).subscribe({
        next: (data: any) => {
          this.changePasswordModal = false;
          this.closePopup.emit();
        },
        error: (error: AppErrors) => {
          this.error.next(error);
          this.showErrorPopup?.next(true);
        }
      });
    }
  }

  ClosePopup(): void {
    this.formGroup.reset();
    this.changePasswordModal = false;
    this.closePopup.emit();
  }
}

function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

@NgModule({
  declarations: [StaffChangePasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    PasswordModule,
    ButtonModule,
    StyleClassModule,
    InputTextModule,
    ErrorMsgComponentModule,
    ErrorsComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [StaffChangePasswordComponent]
})
export class StaffChangePasswordComponentModule {
}
