import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { AgePipeModule } from 'libs/shared/src/lib/filters/age.pipe';
import { DayAgoPipeModule } from 'libs/shared/src/lib/filters/dayago.pipe';
import { TimeLinePipeModule } from 'libs/shared/src/lib/filters/timeline.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'age-cell',
  templateUrl: './age-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgeCellComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)

  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [AgeCellComponent],
  imports: [
    CommonModule,
    AgePipeModule,
  ],
  exports: [AgeCellComponent]
})
export class AgeCellComponentModule {
}
