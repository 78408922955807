
<ng-container *ngIf="{row: columnData | async} as $">
  <ng-container *ngFor="let invoice of $.row.invoiceNumbers">
    <a class="text-primary" href="javascript:void(0)" (click)="OpenInvoiceDetails(invoice)" >{{invoice}}</a> 
  </ng-container> 
</ng-container>


<!-- <invoice-details [showInvoice]="showInvoice" [invoiceDetails]="invoiceDetails"></invoice-details> -->

