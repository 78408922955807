import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import {
  PlanBreakUpCalculationRequest, PlanBreakUpCalculationResponse,
  StaffDiscountCalculationRequest, SignUpRequest, SignUpResponse
} from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, throwError, Observable, map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PlansubscriptionService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private http: HttpClient, private route: Router) { }

  getPlanBreakUpCalculation(tenant_id: number, subscription_plan_id: number, planBreakUpCalculationRequest: PlanBreakUpCalculationRequest): Observable<PlanBreakUpCalculationResponse> {
    return this.http.post(`${environment.BASE_URL}/tenants/${tenant_id}/subscription_plans/${subscription_plan_id}/plan_breakup_calculation`
      , planBreakUpCalculationRequest
      , this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  getStaffDiscountCalculation(tenant_id: number, subscription_plan_id: number, staffDiscountCalculationRequest: StaffDiscountCalculationRequest)
    : Observable<PlanBreakUpCalculationResponse> {
    return this.http
      .post(`${environment.BASE_URL}/tenants/${tenant_id}/subscription_plans/${subscription_plan_id}/staff_discount_calculation`
        , staffDiscountCalculationRequest
        , this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


  SubscriptionSignup(tenant_id: number, client_id: number, SignUpRequest: SignUpRequest)
    : Observable<SignUpResponse> {
    return this.http
      .post(`${environment.BASE_URL}/tenants/${tenant_id}/clients/${client_id}/subscription_signups`
        , SignUpRequest
        , this.httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

}
