import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';

@Component({
  selector: 'app-lost-deal-banner',
  templateUrl: './lost-deal-banner.component.html',
  styleUrls: ['./lost-deal-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LostDealBannerComponent implements OnInit {
  @Input() title:string = ''
  constructor() { }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [LostDealBannerComponent],
  imports: [],
  exports: [LostDealBannerComponent]
})
export class LostDealBannerComponentModule {
}
