import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgModule,
  ChangeDetectorRef,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ClientService } from '@fitness-force/api';
import { CONSTANTS_DATA } from '@fitness-force/constants';
import { PlanIntervalType } from '@fitness-force/enum';
import { BadRequestError, NotFoundError } from '@fitness-force/errors';
import { Store } from '@ngrx/store';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject } from 'rxjs';
import { CurrencyformatPipeModule } from '../../../filters/currencyformat.pipe';
import { DateTimeFormatPipeModule } from '../../../filters/datetimeformat.pipe';
import { timeLineService } from '../../../filters/timeline.pipe';
import { FactoryHttpLoader } from '../../helpsupport/helpsupport.component';

@Component({
  selector: 'subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionListComponent implements OnInit {
  allSubscriptions$ = new BehaviorSubject<any>([]);
  allSubscriptionsSingups$ = new BehaviorSubject<any>([]);
  showsubscriptionDetails: boolean = false;
  clientId: number = 0;
  pendingDuesTableData: any[] = [];

  subscriptionProgress: any[] = [
    {
      title: 'Initiated',
      step: 1,
      status: 'Done',
    },
    {
      title: 'In-Progress',
      step: 2,
      status: 'Done',
    },
    {
      title: 'Completed',
      step: 3,
      status: 'Pending',
    },
  ];

  weekDays = [{key:'Sunday', value:-1},{key:'Monday', value:-2},
          {key:'Tuesday', value:-3},{key:'Wednesday', value:-4},
          {key:'Thursday', value:-5},{key:'Friday', value:-6},
          {key:'Saturday', value:-7}];

  days: Array<string> = CONSTANTS_DATA.DAYS;


  constructor(
    private store: Store,
    private clientService: ClientService,
    private messageService: MessageService,
    private activatedRouter: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.componentInit();
  }
  componentInit() {
    this.activatedRouter.params.subscribe((r: any) => {
      this.clientId = r.clientid;
      this.getAllSubscriptions(r.clientid);
      this.getSubscriptionSignups(r.clientid);
    });
  }
  getAllSubscriptions(clientId: any) {
    this.clientService.getSubscriptions(clientId).subscribe({
      next: (subscp: any) => {
        this.allSubscriptions$.next(subscp.items);
      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error has occured!',
            detail: 'Page Not Found',
          });
        } else if (error instanceof BadRequestError) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error has occured!',
            detail: error.originalError.error[0].errorMessage,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error has occured!',
            detail: 'Something went wrong',
          });
        }
      },
    });
  }

  getSubscriptionSignups(clientId: any) {
    this.clientService.getSubscriptionSignup(clientId).subscribe({
      next: (subscp: any) => {
        if (subscp.items)
          this.allSubscriptionsSingups$.next(subscp.items.filter((x: any) => x.final_status.toLowerCase() != 'closed'));

      },
      error: (error) => {
        if (error instanceof NotFoundError) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error has occured!',
            detail: 'Page Not Found',
          });
        } else if (error instanceof BadRequestError) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error has occured!',
            detail: error.originalError.error[0].errorMessage,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error has occured!',
            detail: 'Something went wrong',
          });
        }
      },
    });
  }
  
  BacktoAllSubscriptions() {
    this.showsubscriptionDetails = false;
  }

 

  GetBillingFrequency(subscription:any): string {
    let translate:string='';
    if (subscription.billing_interval_value && subscription.billing_interval_type.toLowerCase() == 'month') {
      let ordinalSuffix = this.ordinalSuffixOfBillingCycle(subscription.bill_cycle_day);
      if (subscription.billing_interval_value == 1) {
        this.translate.get('SUBSCRIPTIONS.ONE_MONTH_TYPE',{billingCycleDay: subscription.bill_cycle_day,ordinalSuffix:ordinalSuffix}).subscribe((trans) =>{
          translate= trans;
        })
      } else if (subscription.billing_interval_value == 2) {
        this.translate.get('SUBSCRIPTIONS.TWO_MONTH_TYPE',{billingCycleDay: subscription.bill_cycle_day,ordinalSuffix:ordinalSuffix}).subscribe((trans) =>{
          translate= trans;
        })
      } else if (subscription.billing_interval_value == 3) {
        this.translate.get('SUBSCRIPTIONS.THREE_MONTH_TYPE',{billingCycleDay: subscription.bill_cycle_day,ordinalSuffix:ordinalSuffix}).subscribe((trans) =>{
          translate= trans;
        })
      }  else {
        this.translate.get('SUBSCRIPTIONS.MORE_MONTH_TYPE',{intervalValue:subscription.billing_interval_value ,billingCycleDay: subscription.bill_cycle_day,ordinalSuffix:ordinalSuffix}).subscribe((trans) =>{
          translate= trans;
        })
      }
      return translate;
    } else if (subscription.billing_interval_value && subscription.billing_interval_type.toLowerCase() == 'week') {
      let day:string='';
      if(subscription.bill_cycle_day == 0)
        day = this.days[new Date(subscription.sign_up_date).getDay()];
      else
        day = this.weekDays.find(d => d.value == subscription.bill_cycle_day)!.key;
      if (subscription.billing_interval_value == 1) {
         this.translate.get('SUBSCRIPTIONS.WEEKLY_SUNDAY',{day:day}).subscribe((trans) =>{
          translate= trans;
        })
      }
      else if (subscription.billing_interval_value == 2) {
        this.translate.get('SUBSCRIPTIONS.2WEEK_SUNDAY',{day:day}).subscribe((trans) =>{
          translate= trans;
        })
      }
       else {
        this.translate.get('SUBSCRIPTIONS.MORE_WEEK',{intervalType: subscription.billing_interval_value,day:day}).subscribe((trans) =>{
          translate= trans;
        })
      }
      return translate;
    } else {
      return subscription.billing_interval_type;
    }
  }


  ordinalSuffixOfBillingCycle(billingCycleDate: number) {
    let billingCycleDateBuff = billingCycleDate % 10,
      billingCycleDateBuffAvg = billingCycleDate % 100;
    if (billingCycleDateBuff == 1 && billingCycleDateBuffAvg != 11) {
      return 'st';
    }
    if (billingCycleDateBuff == 2 && billingCycleDateBuffAvg != 12) {
      return 'nd';
    }
    if (billingCycleDateBuff == 3 && billingCycleDateBuffAvg != 13) {
      return 'rd';
    }
    return 'th';
  }

  reloadDates(next_Billing_Date: any) {
    const duration = timeLineService(next_Billing_Date);
    return `${duration.label} ${duration.type}`;
  }
  goToSubscriptionDetails(ss: any) {
    this.router.navigate([
      `client/profile/${ss.client_id}/subscriptions/${ss.id}`,
    ]);
  }

  getInterval(billing_interval_value: number, billing_interval_type: PlanIntervalType) {
    let str: string = "";
    if (billing_interval_type == PlanIntervalType.Month || billing_interval_type.toString() == PlanIntervalType[PlanIntervalType.Month]) {
      if (billing_interval_value > 1)
        str = "months";
      else
        str = "month";
    }
    else if (billing_interval_type == PlanIntervalType.Week
      || billing_interval_type.toString() == PlanIntervalType[PlanIntervalType.Week]) {
      if (billing_interval_value > 1)
        str = "weeks";
      else
        str = "week";
    }
    return str.toUpperCase();
  }
  getYear(dateYear: Date) {
    return new Date(dateYear).getFullYear();
  }


}

@NgModule({
  declarations: [SubscriptionListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    AvatarModule,
    TagModule,
    StyleClassModule,
    ChipsModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    TableModule,
    OverlayPanelModule,
    BlockUIModule,
    EditorModule,
    RouterModule,
    DividerModule,
    TimelineModule,
    ToastModule,
    DateTimeFormatPipeModule,
    CurrencyformatPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [SubscriptionListComponent],
})
export class SubscriptionListComponentModule { }
