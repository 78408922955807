export enum TaxRegime {
    GSTIndia = 1,
    VAT = 2,
    SalesTaxUSA = 3
}

export enum TaxType {
    CGST = 1,
    SGST = 2,
    IGST = 3,
    VAT = 4,
    SalesTax = 5
}

export enum TaxCalculationMethod {
    Inclusive = 1,
    Exclusive = 2
}

export enum InvoiceStatus
{
    Void = 'void',
    Open = 'open',
    PartialOpen = 'partialopen',
    Closed = 'closed'
}

export enum PaymentStatus
{
    Open = 'open',
    PartiallyOpen = 'partialOpen',
    Closed = 'closed',
    PendingRealization = 'pendingrealization',
    Erroneous = 'Erroneous',
    Void = 'void'
}

