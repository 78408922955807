import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { TimelineModule } from 'primeng/timeline';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { StyleClassModule } from 'primeng/styleclass';
import { HeaderComponentModule } from '@fitness-force/core';


import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable, combineLatest, of, Subscription, timer } from 'rxjs';

import { environment } from 'apps/ff-frontend/src/environments/environment';
import { StatusandSubscriptionDetails, SubscriptionSignupCharges, SubscriptionSignUpOrderSummaryResponse, SubscriptionSignupService, SubscriptionSignUpStatus, SubscriptionSignupTaxLineItem, SubscriptionSignUpWithBillingJsonResponse, TENANTSINTERFACE } from '@fitness-force/models';
import { SubscriptionSignupByUuidService } from '@fitness-force/api';

import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { PlanIntervalType, SignUpStatus } from '@fitness-force/enum';
import { CurrencyformatPipe, CurrencyformatPipeModule } from '../../../filters/currencyformat.pipe';
import { COMPANY_INFO, TENANTS_INFO } from 'apps/ff-frontend/src/app/state/app.state';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { QrCodeModule } from 'ng-qrcode';
import { HttpClient } from '@angular/common/http';
import { FactoryHttpLoader } from '../../helpsupport/helpsupport.component';

import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';


@Component({
  selector: 'payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSummaryComponent implements OnInit {

  @Input('uuid') uuid: string = '';
  @Input('visible') showPaymentStatusSidebar: boolean = false;
  @Output() hidePaymentSummaryPopup: EventEmitter<boolean> = new EventEmitter();

  subsData$ = new BehaviorSubject({} as SubscriptionSignUpOrderSummaryResponse);

  getAccessTenantList$: Observable<any>;
  tenantId: number = 0;

  bitlyLink: string = "";

  showContractSidebar: boolean = false;
  showSkeleton: boolean = true;
  showLoader: boolean = true;
  isOffline: boolean = false;

  planIntervalType?: PlanIntervalType;
  paymentStatus2: any[];
  timerSubscription: Subscription;
  subscriptionSignUpStatusArray: SubscriptionSignUpStatus[] = [];
  subscriptionSignUpStatus$ = new BehaviorSubject<SubscriptionSignUpStatus[]>([]);
  clientId: number;
  subscriptionId: number;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private subscriptionSignUp: SubscriptionSignupByUuidService,
    private messageService: MessageService,
    public currencyPipe: CurrencyformatPipe,
    private translate: TranslateService,
  ) {

  }


  ngOnInit(): void {
    this.componentInit();
    this.getOrderDetails(this.uuid);

    // timer(0, 10000) call the function immediately and every 10 seconds
    this.timerSubscription = timer(0, 3000).pipe(
      map(() => {
        this.GetOrderSubscriptionStatus(this.uuid); // load data contains the http request
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.timerSubscription.unsubscribe();
  }

  componentInit(): void {

    let companyInfoDetail$: Observable<any> = this.store.select(COMPANY_INFO);

    this.getAccessTenantList$ = this.store.select(TENANTS_INFO);

    this.getAccessTenantList$.subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' })
      }
    })

    companyInfoDetail$.subscribe((responseData) => {
      this.translate.setDefaultLang(responseData.selectedLang.LANG_CODE);
      this.translate.use(responseData.selectedLang.LANG_CODE);
    });

    this.store.select(TENANTS_INFO).subscribe((tenant: TENANTSINTERFACE) => {
      this.tenantId = tenant.selected_tenant.id;
      if (this.tenantId > 0) {
        this.store.dispatch({ type: 'ROUTER_GO' })
      }
    })

    if (this.uuid) {
      this.subscriptionSignUpStatusArray.push(
        { title: "SENDWAIVER", text: "SENDWAIVER_DTL", icon: "pi pi-check", step: 1, status: "Pending", }
        , { title: "CLIENTSIGN_WAIVER", text: "CLIENTSIGN_WAIVER_DTL", icon: "", step: 2, status: "Not Done", }
        , { title: "MEMBERDETAILS", text: "MEMBERDETAILS_DTL", icon: "", step: 3, status: "Not Done", }
        , { title: "SUBSCRIPTIONCREATED", text: "SUBSCRIPTIONCREATED_DTL", icon: "", step: 4, status: "Not Done" },
      );
    }
    this.subscriptionSignUpStatus$.next(this.subscriptionSignUpStatusArray);
  }

  getOrderDetails(uuid: string): void {
    //let subscriptionPayload: SubscriptionSignUpOrderSummaryResponse =

    this.subscriptionSignUp.GetOrderDetails(environment.BASE_URL, uuid).subscribe({
      next: (subscriptionSignUpWithBillingJsonResponse: SubscriptionSignUpWithBillingJsonResponse) => {
        this.subsData$.next(subscriptionSignUpWithBillingJsonResponse.order_details);
        this.showSkeleton = false;
        if (
          subscriptionSignUpWithBillingJsonResponse.status == SignUpStatus.SignUpSuccessfull || subscriptionSignUpWithBillingJsonResponse.status.toString() == SignUpStatus[SignUpStatus.SignUpSuccessfull]
          || subscriptionSignUpWithBillingJsonResponse.status == SignUpStatus.SignUpFailed || subscriptionSignUpWithBillingJsonResponse.status.toString() == SignUpStatus[SignUpStatus.SignUpFailed]
          || subscriptionSignUpWithBillingJsonResponse.status == SignUpStatus.SignUpExpired || subscriptionSignUpWithBillingJsonResponse.status.toString() == SignUpStatus[SignUpStatus.SignUpExpired]
        ) {
          this.isOffline = true;
        }
        this.bitlyLink = subscriptionSignUpWithBillingJsonResponse.full_link
        this.planIntervalType = subscriptionSignUpWithBillingJsonResponse.order_details.billing_interval_type;
        console.log("subscriptionSignUpOrderSummaryResponse object : ", subscriptionSignUpWithBillingJsonResponse)
      }
    });

  }

  GetOrderSubscriptionStatus(uuid: string): void {
    this.subscriptionSignUp.GetOrderSubscriptionStatus(environment.BASE_URL, uuid).subscribe({
      next: (res: StatusandSubscriptionDetails) => {
        // if (res.client_subscription_id > 0)
        //   this.setTimeLineStatus(SignUpStatus.SignUpSuccessfull);
        // else
        //   this.setTimeLineStatus(res.status);
        
        if ((res.client_subscription_id && res.client_subscription_id > 0)
          || res.status == SignUpStatus.SignUpSuccessfull || res.status.toString() == SignUpStatus[SignUpStatus.SignUpSuccessfull]) {

          this.setTimeLineStatus(SignUpStatus.SignUpSuccessfull);
          // this.timerSubscription.unsubscribe();
          // this.showLoader = false;
          this.clientId = res.client_id;
          this.subscriptionId = res.client_subscription_id;
        }
        else if (res.status == SignUpStatus.SignUpFailed || res.status.toString() == SignUpStatus[SignUpStatus.SignUpFailed]
          || res.status == SignUpStatus.SignUpExpired || res.status.toString() == SignUpStatus[SignUpStatus.SignUpExpired]) {
          this.showLoader = false;
          this.timerSubscription.unsubscribe();
        }
        else
          this.setTimeLineStatus(res.status);
        this.subscriptionSignUpStatus$.next(this.subscriptionSignUpStatusArray);
      }
    });
  }


  setHidePaymentSideBar() {
    this.showPaymentStatusSidebar = false;
    this.hidePaymentSummaryPopup.emit(this.showPaymentStatusSidebar);
  }

  // firePaymentHideEvent(event: any) {
  //   this.showPaymentStatusSidebar = false;
  //   this.hidePaymentSummaryPopup.emit(this.showPaymentStatusSidebar);
  // }



  payingToday(oData: SubscriptionSignUpOrderSummaryResponse): number {
    return (
      this.nonRecurringAmount(oData) + this.nonRecurringTaxAmount(oData)
      + this.proRataAmount(oData) + this.proRataTaxAmount(oData)
      + this.chargeAmount(oData) + this.chargeTaxAmount(oData)
    );
  }

  proRataAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_today && oData.charging_today.service_first_charge && oData.charging_today.service_first_charge.services) {
      for (let i = 0; i < oData.charging_today.service_first_charge.services.length; i++) {
        amount = amount + oData.charging_today.service_first_charge.services[i].taxable_cost_in_minor_curr;
      }
    }
    return amount;
  }

  proRataTaxAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_today && oData.charging_today.service_first_charge && oData.charging_today.service_first_charge.services) {
      for (let i = 0; i < oData.charging_today.service_first_charge.services.length; i++) {
        for (let j = 0; j < oData.charging_today.service_first_charge.services[i].tax.tax_line_items.length; j++) {
          amount = amount + oData.charging_today.service_first_charge.services[i].tax.tax_line_items[j].amt_in_minor_curr;
        }
      }
    }

    return amount;
  }

  recurringAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_recurring && oData.charging_recurring.services) {
      for (let i = 0; i < oData.charging_recurring.services.length; i++) {
        amount = amount + oData.charging_recurring.services[i].taxable_cost_in_minor_curr;
      }
    }

    return amount;
  }

  recurringTaxAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_recurring && oData.charging_recurring.services) {
      for (let i = 0; i < oData.charging_recurring.services.length; i++) {
        for (let j = 0; j < oData.charging_recurring.services[i].tax.tax_line_items.length; j++) {
          amount = amount + oData.charging_recurring.services[i].tax.tax_line_items[j].amt_in_minor_curr;
        }
      }
    }

    return amount;
  }

  nonRecurringAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_today && oData.charging_today.service_non_recurring && oData.charging_today.service_non_recurring.services) {
      for (let i = 0; i < oData.charging_today.service_non_recurring.services.length; i++) {
        amount = amount + oData.charging_today.service_non_recurring.services[i].taxable_cost_in_minor_curr;
      }
    }
    return amount;
  }

  nonRecurringTaxAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_today && oData.charging_today.service_non_recurring && oData.charging_today.service_non_recurring.services) {
      for (let i = 0; i < oData.charging_today.service_non_recurring.services.length; i++) {
        for (let j = 0; j < oData.charging_today.service_non_recurring.services[i].tax.tax_line_items.length; j++) {
          amount = amount + oData.charging_today.service_non_recurring.services[i].tax.tax_line_items[j].amt_in_minor_curr;
        }
      }
    }

    return amount;
  }

  chargeAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_today && oData.charging_today.signup_charges) {
      for (let i = 0; i < oData.charging_today.signup_charges.length; i++) {
        amount = amount + oData.charging_today.signup_charges[i].chrgmst_net_cost_in_minor_curr;
      }
    }
    return amount;
  }

  chargeTaxAmount(oData: SubscriptionSignUpOrderSummaryResponse): number {
    let amount: number = 0;
    if (oData.charging_today && oData.charging_today.signup_charges) {
      for (let i = 0; i < oData.charging_today.signup_charges.length; i++) {
        for (let j = 0; j < oData.charging_today.signup_charges[i].tax.tax_line_items.length; j++) {
          amount = amount + oData.charging_today.signup_charges[i].tax.tax_line_items[j].amt_in_minor_curr;
        }
      }
    }
    return amount;
  }

  allServiceNameHtml(oData: SubscriptionSignUpOrderSummaryResponse): string {
    let serviceCategoryName: string[] = [];
    let serviceCategory: any[] = [];
    oData.charging_recurring?.services?.forEach((oService: SubscriptionSignupService) => {
      if (serviceCategoryName.indexOf(oService.category_name_pri_lang) == -1) {
        serviceCategoryName.push(oService.category_name_pri_lang);
        serviceCategory.push({ name: oService.category_name_pri_lang, isBaseMembership: oService.is_base_membership });
      }
    });

    oData.charging_today?.service_non_recurring?.services?.forEach((oService: SubscriptionSignupService) => {
      if (serviceCategoryName.indexOf(oService.category_name_pri_lang) == -1) {
        serviceCategoryName.push(oService.category_name_pri_lang);
        serviceCategory.push({ name: oService.category_name_pri_lang, isBaseMembership: oService.is_base_membership });
      }
    });

    let str: string = `
        <div>
          <div class="mt-2 mb-0 md:mb-1 text-xs text-gray-600">
              {{First}}
              {{Others}}
          </div>
        </div>
    `;
    // <div class="text-right ml-3">{{recurringAmount}}</div>

    let other: string = '';
    let first: string = '';
    for (let i = 0; i < serviceCategory.length; i++) {
      if (i == 0)
        first = serviceCategory[i].name + (serviceCategory[i].isBaseMembership ? ' <span class="inline-block px-2 line-height-4 text-xxs bg-gray-200 border-round"> Base Membership </span>' : '');
      else
        other = other + '<br class="md:hidden"> + ' + serviceCategory[i].name + (serviceCategory[i].isBaseMembership ? ' <span class="inline-block px-2 line-height-4 text-xxs bg-gray-200 border-round" > Base Membership </span>' : '');
    }

    let strAmount: string = this.currencyPipe.transform(this.recurringAmount(oData) + this.recurringTaxAmount(oData), undefined);
    return str.replace('{{First}}', first).replace('{{Others}}', other).replace('{{recurringAmount}}', strAmount);
  }

  serviceNonRecurringNameHtml(oData: SubscriptionSignUpOrderSummaryResponse): string {
    let serviceCategoryName: string[] = [];
    let serviceCategory: any[] = [];
    oData.charging_today?.service_non_recurring?.services?.forEach((oService: SubscriptionSignupService) => {
      if (serviceCategoryName.indexOf(oService.category_name_pri_lang) == -1) {
        serviceCategoryName.push(oService.category_name_pri_lang);
        serviceCategory.push({ name: oService.category_name_pri_lang, isBaseMembership: oService.is_base_membership });
      }
    });

    let str: string = `
      <div>
        <div>One time add-ons</div>
        <div class="mt-2 mb-0 md:mb-1 text-xs text-gray-60">
          {{First}}
          {{Others}}
        </div>
      </div>
      <div class="text-right ml-3">{{recurringAmount}}</div>
    `;

    let other: string = '';
    let first: string = '';
    for (let i = 0; i < serviceCategory.length; i++) {
      if (i == 0)
        first = serviceCategory[i].name;
      else
        other = other + '<br class="md:hidden"> + ' + serviceCategory[i].name;
    }

    let strAmount: string = this.currencyPipe.transform(this.nonRecurringAmount(oData), undefined);
    return str.replace('{{First}}', first).replace('{{Others}}', other).replace('{{recurringAmount}}', strAmount);
  }



  getTaxDetails(oData: SubscriptionSignUpOrderSummaryResponse): string {
    let tax: string[] = [];
    oData.charging_today?.signup_charges?.forEach((oCharges: SubscriptionSignupCharges) => {
      if (oCharges.tax && oCharges.tax.tax_line_items && oCharges.tax.tax_line_items.length > 0)
        oCharges.tax.tax_line_items.forEach((oTax: SubscriptionSignupTaxLineItem) => {
          if (tax.indexOf(oTax.name_pri_lang) == -1) tax.push(oTax.name_pri_lang);
        });
    });

    oData.charging_today?.service_first_charge?.services?.forEach((oService: SubscriptionSignupService) => {
      if (oService.tax && oService.tax.tax_line_items && oService.tax.tax_line_items.length > 0)
        oService.tax.tax_line_items.forEach((oTax: SubscriptionSignupTaxLineItem) => {
          if (tax.indexOf(oTax.name_pri_lang) == -1) tax.push(oTax.name_pri_lang);
        });
    });

    oData.charging_today?.service_non_recurring?.services?.forEach((oService: SubscriptionSignupService) => {
      if (oService.tax && oService.tax.tax_line_items && oService.tax.tax_line_items.length > 0)
        oService.tax.tax_line_items.forEach((oTax: SubscriptionSignupTaxLineItem) => {
          if (tax.indexOf(oTax.name_pri_lang) == -1) tax.push(oTax.name_pri_lang);
        });
    });

    return tax.join("</br>");
  }



  ordinalNumber(n: number | null | undefined): string {
    if (n) {
      let s = ["th", "st", "nd", "rd"];
      let v: number = n % 100;
      return n.toString() + (s[(v - 20) % 10] || s[v] || s[0]).toString();
    }
    else
      return '';
  }

  get isWeeklyPlan(): boolean {

    return (this.planIntervalType && this.planIntervalType?.toString() == PlanIntervalType[PlanIntervalType.Week]) || false;
  }

  get isDayPlan(): boolean {
    return false;
    // return (this.planIntervalType && this.planIntervalType?.toString() == PlanIntervalType[PlanIntervalType.Day]) || false;
  }

  get isMonthlyPlan(): boolean {
    return (this.planIntervalType && this.planIntervalType?.toString() == PlanIntervalType[PlanIntervalType.Month]) || false;
  }

  ResendPaymentLink(mode: string) {
    if (mode == 'email') {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Email send' });
    }
    else if (mode == 'sms') {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Email send' });
    }

  }

  setTimeLineStatus(res: SignUpStatus) {
    if (res == SignUpStatus.Open || res.toString() == SignUpStatus[SignUpStatus.Open]) {
      this.subscriptionSignUpStatusArray[0].status = "Done";
      this.subscriptionSignUpStatusArray[0].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[1].status = "Pending";
    }
    else if (res == SignUpStatus.WaiverDone || res.toString() == SignUpStatus[SignUpStatus.WaiverDone]) {
      //else if (res < 5) {
      this.subscriptionSignUpStatusArray[0].status = "Done";
      this.subscriptionSignUpStatusArray[0].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[1].status = "Done";
      this.subscriptionSignUpStatusArray[1].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[2].status = "Pending";
    }
    else if (res == SignUpStatus.MandatoryInformationDone || res.toString() == SignUpStatus[SignUpStatus.MandatoryInformationDone]) {
      this.subscriptionSignUpStatusArray[0].status = "Done";
      this.subscriptionSignUpStatusArray[0].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[1].status = "Done";
      this.subscriptionSignUpStatusArray[1].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[2].status = "Done";
      this.subscriptionSignUpStatusArray[2].icon = "pi pi-check";

      this.subscriptionSignUpStatusArray[3].status = "Pending";


    }
    else if (res == SignUpStatus.SignUpSuccessfull || res.toString() == SignUpStatus[SignUpStatus.SignUpSuccessfull]) {
      this.subscriptionSignUpStatusArray[0].status = "Done";
      this.subscriptionSignUpStatusArray[0].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[1].status = "Done";
      this.subscriptionSignUpStatusArray[1].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[2].status = "Done";
      this.subscriptionSignUpStatusArray[2].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[3].status = "Done";
      this.subscriptionSignUpStatusArray[3].icon = "pi pi-check";
      this.timerSubscription.unsubscribe();
      this.showLoader = false;
    }
    else if (res == SignUpStatus.SignUpFailed || res.toString() == SignUpStatus[SignUpStatus.SignUpFailed]
      || res == SignUpStatus.SignUpExpired || res.toString() == SignUpStatus[SignUpStatus.SignUpExpired]) {
      this.subscriptionSignUpStatusArray[0].status = "Done";
      this.subscriptionSignUpStatusArray[0].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[1].status = "Done";
      this.subscriptionSignUpStatusArray[1].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[2].status = "Done";
      this.subscriptionSignUpStatusArray[2].icon = "pi pi-check";
      this.subscriptionSignUpStatusArray[3].status = "Error";
      this.subscriptionSignUpStatusArray[3].icon = "Not Done";
      this.showLoader = false;
      this.timerSubscription.unsubscribe();
    }
  }

}

@NgModule({
  declarations: [PaymentSummaryComponent],
  imports: [
    CommonModule,
    OverlayPanelModule,
    DividerModule,
    TimelineModule,
    ButtonModule,
    StyleClassModule,
    TooltipModule,
    HeaderComponentModule,
    ProgressBarModule,
    SidebarModule,
    CurrencyformatPipeModule,
    QrCodeModule,
    ToastModule,
    MessagesModule,
    SkeletonModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [PaymentSummaryComponent]
})
export class PaymentSummaryComponentModule {
}
function SIGNUPSTEP_INFO(SIGNUPSTEP_INFO: any) {
  throw new Error('Function not implemented.');
}

function title(title: any, arg1: string, text: any, arg3: string, icon: any, arg5: string, step: any, arg7: number, status: string, arg9: string) {
  throw new Error('Function not implemented.');
}

