<div id="app-sidebar" [ngClass]="sidebarMinizeClass" class="bg-black-alpha-90 h-screen flex-shrink-0 fixed lg:sticky left-0 top-0 z-100 hidden lg:block border-right-1 border-gray-900
  select-none">
  <div class="flex flex-column h-full" [ngClass]="sidebarMinimized ? '' : 'w-14rem'">
    <!-- <div (mouseenter)="sidebarMinimized=false" (mouseleave)="sidebarMinimized=true" class="flex flex-column h-full relative">   -->
    <div class="
        flex
        align-items-center
        justify-content-center
        p-2
        flex-shrink-0
        surface-0
        h-3rem
      " [ngClass]="sidebarMinimized ? '' : 'p-2'">
      <img [ngClass]="sidebarMinimized ? 'w-8rem' : ''" width="130"
        src="{{ (this.tenantLogo)?this.tenantLogo:this.companyLogo }}" alt="Image" />
       
    </div>
    <span (click)="sidebarMinimized = !sidebarMinimized" class="
        w-2rem
        h-2rem
        border-circle border-1 border-white
        bg-black-alpha-90
        hidden
        lg:flex
        align-items-center
        justify-content-center
        cursor-pointer
        text-white
        absolute
        right-0
        -mr-3
        mt-7
        z-2
      ">
      <i class="pi" [ngClass]="
          sidebarMinimized ? 'pi-angle-right' : 'pi-angle-left'
        "></i></span>
    <p-scrollPanel styleClass="h-screen w-full" class="lg:mt-3" #sidebar>
      <ul class="list-none px-0 mx-0 mt-3 mb-8">
        <li *ngFor="let menu of Menus" class="border-bottom-1 border-gray-900 py-2">
          <a [ngClass]="sidebarMinimized ? 'justify-content-center' : ''"
            *ngIf="menu.submenus.length > 0; else singlemenu" pRipple
            [ngClass]="selectedUrl == menu.url ? 'bg-gray-800' : ''" class="
              flex
              align-items-center
              cursor-pointer
              py-2
              px-2
              mx-2
              border-round
              hover:bg-gray-800
              text-gray-300
              hover:text-white
              transition-duration-150 transition-colors
            " pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
            leaveActiveClass="slideup">
            <i class="{{ menu.icon }}" ></i>
            <span class="font-medium ml-2" [ngClass]="
                sidebarMinimized && sidebarMinizeClass == 'lg:sticky'
                  ? 'lg:hidden'
                  : 'inline'
              ">{{ 'common.SIDEBAR.' + menu.title | translate }}</span>
            <i class="{{ menu.icon2 }}"></i>
          </a>
          <ul class="
              list-none
              p-0
              m-0
              overflow-y-hidden
              transition-all transition-duration-400 transition-ease-in-out
              text-sm
              opacity-70
            " [ngClass]="sidebarMinimized ? 'lg:hidden' : ''">
            <li *ngFor="let submenu of menu.submenus">
              <a [routerLink]="submenu.url" pRipple [ngClass]="selectedUrl == submenu.url ? 'bg-gray-800' : ''"
                class="
                  flex
                  align-items-center
                  cursor-pointer
                  pr-3
                  pl-5
                  py-2
                  hover:bg-blue-800
                  text-gray-300
                  hover:text-blue-200
                  transition-duration-150 transition-colors
                ">
                <span class="font-medium">{{
                  'common.SIDEBAR.' + submenu.title | translate
                  }}</span>
              </a>
            </li>
          </ul>
          <ng-template #singlemenu>
            <a [ngClass]="sidebarMinimized ? 'justify-content-center' : ''" [routerLink]="menu.url" pRipple
              [ngClass]="selectedUrl == menu.url ? 'bg-gray-800' : ''"  class="
                no-underline
                flex
                align-items-center
                cursor-pointer
                py-3
                px-2
                mx-2
                border-round
                hover:bg-gray-800
                text-gray-300
                hover:text-white
                transition-duration-150 transition-colors
              ">
              <i class="{{ menu.icon }}" ></i>
              <span class="font-medium ml-2" [ngClass]="sidebarMinimized ? 'lg:hidden' : 'inline'">{{ 'common.SIDEBAR.'
                + menu.title | translate }}</span>
            </a>
          </ng-template>
        </li>
      </ul>
    </p-scrollPanel>
    <div class="
        block
        lg:hidden
        px-3
        text-white text-center
        border-top-1 border-white-alpha-40
        pt-4
      ">
      <div class="mb-3">
        <button pButton pRipple label="Contact Support" icon="pi pi-envelope"></button>
      </div>
      <p>{{ 'common.SIDEBAR.DOWNLOADAPP' | translate }}</p>
      <div class="d-flex mb-2">
        <a class="mr-2" href="#" target="_blank"><img src="assets/img/apple-logo.svg" alt="Apple" width="66" /></a>
        <a class="mr-2" href="#" target="_blank"><img src="assets/img/googleplay-logo.svg" alt="Google Play"
            width="66" /></a>
        <span><img src="assets/img/footer-qr-code.jpg" alt="QR Code" width="38" /></span>
      </div>
      <ul class="
          list-inline
          flex
          justify-content-center
          list-none
          mb-3
          text-xs
          pl-0
          mt-2
        ">
        <li class="mr-3">
          <a href="#" class="text-white no-underline hover:text-primary">{{
            'common.SIDEBAR.TERMS_USE' | translate
            }}</a>
        </li>
        <li>
          <a href="#" class="text-white no-underline hover:text-primary">{{
            'common.SIDEBAR.PRIVACY_POLICY' | translate
            }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>