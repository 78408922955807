<div [ngClass]="isTabMenuSticky? 'fixed w-full top-0 z-2': ''">

  <ng-container *ngIf="(allClients$ | async) as client">
    <!-- Client Block -->
    <div class="border-bottom-1 border-gray-300 bg-white z-3 relative" (scroll)="stickyProfileInfo()">

      <div class="card" *ngIf="isTabMenuSticky">
        <div class="card-container green-container">
          <div class="flex flex-wrap bg-white-100 p-2">
            <div class="w-12 md:w-6 bg-white-300 text-black-900 font-bold  flex align-items-center ">
              <div class="flex align-items-center">
                <div [innerHTML]="clientStatus(client)"></div>
                <!-- <span class="bg-green-500 px-2 py-1 line-height-3 font-bold border-round text-white text-xxs mr-1"
                  *ngIf="client.client_status">{{client.client_status | TextTransForm }}</span> -->
                <ng-container *ngFor="let s of (stageMaster$ | async)">
                  <div class="relative ml-1 w-6rem h-2rem overflow-hidden flex align-items-center"
                    *ngIf="client.stage_master_id == s.id">
                    <img src="/assets/img/icons/opportunity-stage-bg.svg" class="absolute w-6rem h-2rem"><span
                      class="relative z1 text-xs text-primary font-bold ml-2 w-10 block overflow-hidden text-overflow-ellipsis white-space-nowrap text-ellipsis block line-height-4">
                      {{s.name_primary_language | titlecase}}</span>
                  </div>
                </ng-container>

              </div>
              <div class="flex align-items-center ml-3">
                <!-- Pic -->
                <div class="relative">

                  <p-avatar *ngIf="!client.image" [label]="client.full_name | titlecase | shortName" shape="circle"
                    [styleClass]="isTabMenuSticky ? 'lg:w-2rem lg:h-2rem w-2rem h-2rem text-xl' : 'md:w-6rem md:h-6rem w-3rem h-3rem text-2xl'">
                  </p-avatar>
                  <p-avatar *ngIf="client.image" [image]="client.image" shape="circle"
                    [styleClass]="isTabMenuSticky ? 'lg:w-2rem lg:h-2rem w-2rem h-2rem text-xl' : 'md:w-6rem md:h-6rem w-3rem h-3rem text-2xl'">
                  </p-avatar>
                  <i class="pi pi-ban absolute left-0 top-0 p-error"
                    [ngClass]="isTabMenuSticky ? 'text-4xl' : 'text-5xl lg:text-8xl'"
                    *ngIf="client && client.is_blacklisted"></i>
                  <div [ngClass]="isTabMenuSticky ? 'w-1rem h-1rem' : ' w-2rem h-2rem p-2'"
                    class="bg-gray-700 border-circle flex align-items-center justify-content-center absolute right-0 top-0 mt-1 -mr-2">
                    <img *ngIf="isTabMenuSticky && client.client_status.toLowerCase() !== 'prospect'" width="10"
                      [src]="'/assets/img/icons/'+crownType(client.client_since).crown+'.svg'" alt="Icon" />
                    <img *ngIf="!isTabMenuSticky && client.client_status.toLowerCase() !== 'prospect'"
                      [src]="'/assets/img/icons/'+crownType(client.client_since).crown+'.svg'" alt="Icon" />
                  </div>
                </div>
                <!-- Pic -->
                <h3 class="mb-0 text-primary text-lg ml-3" [ngClass]="isTabMenuSticky ? '' : ''">{{client.full_name}}
                </h3>
                <p class="mb-0 text-xs text-600 ml-2 font-normal">- {{client.id}}</p>
              </div>
            </div>
            <!-- <div class="w-12 md:w-6 bg-white-300 text-gray-300 font-bold p-1 flex align-items-center ">
            </div> -->
          </div>
        </div>
      </div>
      <!-- Desktop View -->
      <div class="hidden md:block xl:flex align-items-center" *ngIf="!isTabMenuSticky">

        <!-- Left Side -->
        <div class="xl:w-9 p-3 relative">
          <!-- BG Image if birthday month-->
          <ng-container *ngIf="client.date_of_birth | bday">
            <img class="absolute right-0 top-0 w-full h-full opacity-50" src="/assets/img/birthday-bg.png"
              alt="Birthday" />
          </ng-container>

          <!-- BG Image if birthday month-->

          <div class="z-1 relative flex align-items-center">

            <!-- Pic -->

            <div class="relative mt-3">
              <i class="pi pi-ban absolute left-0 top-0 p-error text-5xl md:text-6xl lg:text-8xl"
                *ngIf="client && client.is_blacklisted"></i>
              <p-avatar *ngIf="!client.image" [label]="client.full_name | titlecase | shortName" shape="circle"
                styleClass="lg:w-6rem lg:h-6rem w-3rem h-3rem text-xl xl:text-4xl"></p-avatar>
              <p-avatar *ngIf="client.image" [image]="client.image" shape="circle"
                styleClass="lg:w-6rem lg:h-6rem w-3rem h-3rem text-4xl"></p-avatar>
              <div *ngIf="client.client_status.toLowerCase() != 'prospect'"
                class="bg-gray-700 w-1rem lg:w-2rem h-1rem lg:h-2rem border-circle lg:p-2 flex align-items-center justify-content-center absolute right-0 top-0 mt-1 -mr-2">
                <img class="hidden lg:inline-block"
                  [src]="'/assets/img/icons/'+crownType(client.client_since).crown+'.svg'" alt="Icon" />
                <img width="10" class="lg:hidden"
                  [src]="'/assets/img/icons/'+crownType(client.client_since).crown+'.svg'" alt="Icon" />
              </div>
            </div>

            <!-- Pic -->

            <!-- Content -->
            <div class="md:ml-4 w-full">


              <!-- Top Area -->
              <div class="flex py-2 border-bottom-2 border-gray-300">

                <!-- Name -->
                <div class="w-7">
                  <div class="flex align-items-center mb-2">
                    <div [innerHTML]="clientStatus(client)"></div>
                    <ng-container *ngFor="let s of client.stage_master">
                      <div>
                        <div class="relative ml-1 w-6rem h-2rem overflow-hidden flex align-items-center">
                          <img src="/assets/img/icons/opportunity-stage-bg.svg" class="absolute w-6rem h-2rem">
                          <span
                            class="relative z1 text-xs text-primary font-bold ml-2 w-10 block overflow-hidden text-overflow-ellipsis white-space-nowrap text-ellipsis block line-height-4">
                            {{ getStageStatus(s.stage_master_id) | async }}
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="flex align-items-center">
                    <h3 class="mb-0 text-primary text-lg">{{client.full_name}}</h3>
                    <p class="mb-0 text-xs text-600 ml-2 font-normal">- {{client.id}}</p>
                  </div>
                </div>
                <!-- Name -->

                <!-- Info Icons -->
                <div class="w-5 flex justify-content-end text-700">
                  <div class="text-center ml-3" [pTooltip]="'CLIENT_PROFILE.SEND-SMS' | translate"
                    tooltipStyleClass="text-sm" tooltipPosition="bottom" (click)="toggleCreateSmsDialog()">
                    <div
                      class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-300 flex align-items-center justify-content-center cursor-pointer hover:bg-gray-100">
                      <i class="pi pi-comments text-600"></i>
                    </div>
                    <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.SMS' | translate}}</div>
                  </div>
                  <div class="text-center ml-3" [pTooltip]="'CLIENT_PROFILE.SEND-EMAIL' | translate"
                    tooltipStyleClass="text-sm" tooltipPosition="bottom" (click)="toggleSendEmailDialog()">
                    <div
                      class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-300 flex align-items-center justify-content-center cursor-pointer hover:bg-gray-100">
                      <i class="pi pi-envelope text-600"></i>
                    </div>
                    <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.EMAIL' | translate}}</div>
                  </div>
                  <!-- <div class="text-center ml-3" [pTooltip]="'CLIENT_PROFILE.CALL' | translate"  tooltipStyleClass="text-sm" tooltipPosition="bottom">
                  <div
                    class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-300 flex align-items-center justify-content-center cursor-pointer hover:bg-gray-100">
                    <i class="pi pi-phone text-600"></i></div>
                  <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.CALL' | translate}}</div>
                </div> -->
                  <!-- <div class="text-center ml-3" [pTooltip]="'CLIENT_PROFILE.SEND-WHATS-APP' | translate" tooltipStyleClass="text-sm"
                  tooltipPosition="bottom">
                  <div
                    class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-300 flex align-items-center justify-content-center cursor-pointer hover:bg-gray-100">
                    <img class="opacity-50 w-1rem" src="/assets/img/icons/whatsapp.svg" alt="Icon" /></div>
                  <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.WHATS_APP' | translate}}</div>
                </div> -->
                  <div class="text-center ml-3 relative">
                    <prospect-list-actions [columnData]="allClients$" (changeEvent)="changeInAction($event)">
                    </prospect-list-actions>
                  </div>
                </div>
                <!-- Info Icons -->


              </div>
              <!-- Top Area -->

              <!-- Bottom Area -->
              <div class="py-2">

                <!-- Features -->
                <ul class="list-none p-0 m-0 flex align-items-center justify-content-between">
                  <li class="flex align-items-center text-xs mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-coin mr-2 text-600"
                      width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                      <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1">
                      </path>
                      <path d="M12 6v2m0 8v2"></path>
                    </svg>
                    <span class="text-700">{{'CLIENT_PROFILE.PENDING_AMOUNT' | translate}} -</span>
                    <strong>&nbsp;{{client.total_outstanding_in_minor_currency | currencyformat}}<sup>.00</sup></strong>
                  </li>
                  <li class="flex align-items-center text-xs mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone mr-2 text-600"
                      width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                      </path>
                    </svg>
                    <span class="text-700">{{'CLIENT_PROFILE.MOBILE' | translate}} -</span>
                    <strong>&nbsp;{{client.mobile_country_code}}
                      {{client.mobile_number}}</strong>
                  </li>
                  <li class="flex align-items-center text-xs mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-device-mobile mr-2 text-600" width="24" height="24"
                      viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                      stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <rect x="7" y="4" width="10" height="16" rx="1"></rect>
                      <line x1="11" y1="5" x2="13" y2="5"></line>
                      <line x1="12" y1="17" x2="12" y2="17.01"></line>
                    </svg>
                    <span class="text-700">{{'CLIENT_PROFILE.APP' | translate}} - </span>
                    <strong *ngIf="client.application_installed">&nbsp;{{'CLIENT_PROFILE.INSTALLED' |
                      translate}}</strong>
                    <strong class="text-xs block text-left line-height-2" *ngIf="!client.application_installed"
                      (click)="shareAppModal=true">&nbsp;{{'CLIENT_PROFILE.NOT_INSTALLED' | translate}}
                      <span class="block text-primary cursor-pointer ml-2">({{'CLIENT_PROFILE.SEND_LINK' |
                        translate}})</span></strong>
                  </li>
                  <li class="flex align-items-center text-xs">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-gift mr-2 text-600"
                      width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <rect x="3" y="8" width="18" height="4" rx="1"></rect>
                      <line x1="12" y1="8" x2="12" y2="21"></line>
                      <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7"></path>
                      <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5">
                      </path>
                    </svg>
                    <span class="text-700">{{'CLIENT_PROFILE.BIRTHDAY' | translate}} - </span>
                    <strong>&nbsp;{{client.date_of_birth | datetimeformat: 'mediumDate'}}</strong>
                    <strong *ngIf="!client.date_of_birth">Not Specified</strong>
                  </li>
                </ul>
                <!-- Features -->

                <!-- Tags -->
                <!-- <div class="flex align-items-center">
                <p-chips [(ngModel)]="tags" inputStyleClass="text-sm" styleClass="border-none w-full mt-3 text-sm"
                  placeholder="Add Tags"></p-chips> -->

                <!-- When We click on more link, it will display all the items. We should display 3-4 items only in array of p-chips so that it doesn't go to next line. On clicking more, all items in array should be loaded -->
                <!-- <span *ngIf="showMoreTags" (click)="addTags()"
                  class="text-primary cursor-pointer ml-2 flex align-items-center white-space-nowrap text-xs mt-3">
                  + 4 more <i class="pi pi-chevron-down text-xxs ml-1"></i></span>
              </div> -->
                <!-- Tags -->

              </div>
              <!-- Bottom Area -->

            </div>
            <!-- Content -->

          </div>
        </div>
        <!-- Left Side -->

        <!-- Right Side -->
        <div class="xl:w-3 bg-blue-50 p-3">
          <div class="mb-3">
            <h4 class="text-xs font-normal text-600 mb-2">{{'CLIENT_PROFILE.HOME_CLUB' | translate}}</h4>
            <ng-container *ngFor="let tenant of allTenants">
              <p class="text-sm text-900 font-bold mb-0"
                *ngIf="client.home_club_tenant_id && (tenant.id == client.home_club_tenant_id)">{{tenant.status}}
                {{tenant.name_primary_language}}, {{tenant.city}}</p>
            </ng-container>


          </div>
          <div class="mb-3">
            <h4 class="text-xs font-normal text-600 mb-2">{{'CLIENT_PROFILE.MEMBER_SINCE' | translate}}</h4>

            <p class="text-sm text-900 font-bold mb-0 flex align-items-center"
              *ngIf="client.client_status.toLowerCase() !== 'prospect'">
              {{client.client_since | datetimeformat: 'mediumDate'}}
              <span class="text-600 font-normal text-xs mx-3">({{memberSince(client.client_since).exact}})</span>
              <img *ngIf="crownType(client.client_since).crown !== 'default'"
                [src]="'/assets/img/icons/'+crownType(client.client_since).crown+'.svg'" alt="Icon" />
            </p>

            <p class="text-sm text-900 font-bold mb-0 flex align-items-center"
              *ngIf="client.client_status.toLowerCase() == 'prospect'">Not Specified</p>
          </div>
          <div class="mb-0">
            <h4 class="text-xs font-normal text-600 mb-2">{{'CLIENT_PROFILE.LIFETIME_VALUE' | translate}}</h4>
            <p class="text-sm text-900 font-bold mb-0 flex align-items-center">
              {{client.lifetime_value | currencyformat}}

              <img class="ml-3"
                *ngIf="crownType(client.client_since).coin !== 'default' && client.client_status.toLowerCase() != 'prospect'"
                [src]="'/assets/img/icons/'+crownType(client.client_since).coin+'.svg'" alt="Icon" />
            </p>
          </div>
        </div>
        <!-- Right Side -->

      </div>
      <!-- Desktop View -->

      <!-- Mobile View -->
      <div class="relative md:hidden" *ngIf="!isTabMenuSticky">

        <div class="flex align-items-center justify-content-between p-2">

          <div class="flex align-items-center">

            <!-- Badge -->
            <!-- <ng-container *ngFor="let s of (stageMaster$ | async)">
              <div class="relative mb-1 w-6rem h-2rem mr-2 p-overflow-hidden" *ngIf="client.stage_master_id == s.id">
                <img src="/assets/img/icons/opportunity-stage-bg.svg" class="absolute w-6rem h-2rem"><span
                  class="relative text-xs text-primary font-bold ml-2 mt-2 block">{{s.name_primary_language |
                  titlecase}}</span>
              </div>
            </ng-container> -->



            <!-- Badge -->

            <!-- UserName and Pic -->
            <div class="flex align-items-center">
              <div class="relative">
                <p-avatar *ngIf="!client.image" [label]="client.full_name | titlecase | shortName" shape="circle"
                  styleClass="w-2rem h-2rem text-sm"></p-avatar>
                <p-avatar *ngIf="client.image" [image]="client.image" shape="circle"
                  styleClass="lg:w-6rem lg:h-6rem w-3rem h-3rem text-4xl"></p-avatar>
                <div
                  class="bg-gray-700 w-1rem h-1rem border-circle p-1 flex align-items-center justify-content-center absolute right-0 top-0 -mr-2 mt-2">
                  <img [src]="'/assets/img/icons/'+crownType(client.client_since).crown+'.svg'" alt="Icon" />
                </div>
              </div>
              <h4
                class="text-sm text-primary mb-0 w-8rem text-overflow-ellipsis white-space-nowrap overflow-hidden ml-3">
                {{client.full_name}}</h4>
              <ng-container *ngFor="let s of client.stage_master">
                <div>
                  <div class="relative ml-1 w-6rem h-2rem overflow-hidden flex align-items-center">
                    <img src="/assets/img/icons/opportunity-stage-bg.svg" class="absolute w-6rem h-2rem">
                    <span
                      class="relative z1 text-xs text-primary font-bold ml-2 w-10 block overflow-hidden text-overflow-ellipsis white-space-nowrap text-ellipsis block line-height-4">
                      {{ getStageStatus(s.stage_master_id) | async }}
                    </span>
                  </div>
                  <!-- <small class="text-xxs block">{{ s.opportunity_board_type | titlecase }}</small> -->
                </div>
              </ng-container>
            </div>

            <!-- UserName and Pic -->

          </div>

          <!-- Options -->
          <div class="flex align-items-center justify-content-end">

            <!-- Settings -->
            <div class="mr-5">
              <prospect-list-actions [columnData]="allClients$" (changeEvent)="changeInAction($event)">
              </prospect-list-actions>
            </div>
            <!-- Settings -->

          </div>
          <!-- Options -->

        </div>

        <!-- Toggle -->
        <div class="cursor-pointer absolute right-0 top-0 mt-4 mr-3" pStyleClass="@next" enterClass="hidden"
          enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
          <i class="pi pi-chevron-down text-500"></i>
        </div>
        <!-- Toggle -->

        <!-- Dropdown -->

        <div class="hidden text-800 origin-top w-full bg-white text-sm">

          <div class="surface-200 px-3 md:py-3 py-1 flex align-items-center justify-content-between">
            <span
              class="bg-green-500 px-2 py-1 line-height-3 font-bold border-round text-white text-xxs uppercase mr-1">MEMBER</span>

            <div class="flex align-items-center justify-content-end">
              <div class="text-center ml-3" (click)="toggleCreateSmsDialog()">
                <div
                  class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-400 flex align-items-center justify-content-center cursor-pointer bg-white hover:bg-gray-100">
                  <i class="pi pi-comments text-600"></i>
                </div>
                <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.SMS' | translate}}</div>
              </div>

              <div class="text-center ml-3" (click)="toggleSendEmailDialog()">
                <div
                  class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-400 flex align-items-center justify-content-center cursor-pointer bg-white hover:bg-gray-100">
                  <i class="pi pi-envelope text-600"></i>
                </div>
                <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.EMAIL' | translate}} </div>
              </div>
              <!-- <div class="text-center ml-3">
                <div
                  class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-400 flex align-items-center justify-content-center cursor-pointer bg-white hover:bg-gray-100">
                  <i class="pi pi-phone text-600"></i></div>
                <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.CALL' | translate}}</div>
              </div>
              <div class="text-center ml-3">
                <div
                  class="w-2rem h-2rem border-circle mx-auto border-1 border-gray-400 flex align-items-center justify-content-center cursor-pointer bg-white hover:bg-gray-100">
                  <img class="opacity-50 w-1rem" src="/assets/img/icons/whatsapp.svg" alt="Icon" /></div>
                <div class="mt-1 text-xxs">{{'CLIENT_PROFILE.WHATS_APP' | translate}}</div>
              </div> -->
            </div>

          </div>

          <div class="bg-blue-50 p-3">
            <div class="mb-3">
              <h4 class="text-xs font-normal text-600 mb-2">{{'CLIENT_PROFILE.HOME_CLUB' | translate}}</h4>
              <ng-container *ngFor="let tenant of allTenants">
                <p class="text-sm text-900 font-bold mb-0"
                  *ngIf="client.home_club_tenant_id && (tenant.id == client.home_club_tenant_id)">{{tenant.status}}
                  {{tenant.name_primary_language}}, {{tenant.city}}</p>
              </ng-container>
            </div>
            <div class="mb-3">
              <h4 class="text-xs font-normal text-600 mb-2">{{'CLIENT_PROFILE.MEMBER_SINCE' | translate}}</h4>
              <p class="text-sm text-900 font-bold mb-0 flex align-items-center">
                {{client.client_since | datetimeformat: 'mediumDate'}}
                <span class="text-600 font-normal text-xs mx-3">({{memberSince(client.client_since).exact}})
                </span>
                <img [src]="'/assets/img/icons/'+crownType(client.client_since).crown+'.svg'" alt="Icon" />
              </p>
            </div>
            <div class="mb-0">
              <h4 class="text-xs font-normal text-600 mb-2">{{'CLIENT_PROFILE.LIFETIME_VALUE' | translate}}</h4>
              <p class="text-sm text-900 font-bold mb-0 flex align-items-center">
                {{client.lifetime_value | currencyformat}}<sup class="text-600 text-xs font-normal">.00</sup>
                <img class="ml-3" [src]="'/assets/img/icons/'+crownType(client.client_since).coin+'.svg'" alt="Icon" />
              </p>
            </div>
          </div>

          <!-- Features -->
          <ul class="list-none px-3 m-0 border-bottom-1 border-gray-300 pb-1">
            <li class="flex align-items-center text-xs my-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-coin mr-2 text-600" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="9"></circle>
                <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1"></path>
                <path d="M12 6v2m0 8v2"></path>
              </svg>
              <span class="text-700">{{'CLIENT_PROFILE.PENDING_AMOUNT' | translate}} -</span>
              <strong>&nbsp;{{client.total_outstanding_in_minor_currency | currencyformat}}<sup>.00</sup></strong>
            </li>
            <li class="flex align-items-center text-xs my-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone mr-2 text-600"
                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                </path>
              </svg>
              <span class="text-700">{{'CLIENT_PROFILE.MOBILE' | translate}} # -</span>
              <strong>&nbsp;{{client.mobile_country_code}} {{client.mobile_no}}</strong>
            </li>
            <li class="flex align-items-center text-xs my-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-mobile mr-2 text-600"
                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <rect x="7" y="4" width="10" height="16" rx="1"></rect>
                <line x1="11" y1="5" x2="13" y2="5"></line>
                <line x1="12" y1="17" x2="12" y2="17.01"></line>
              </svg>
              <span class="text-700">{{'CLIENT_PROFILE.APP' | translate}} - </span>
              <strong *ngIf="client.application_installed">&nbsp;{{'CLIENT_PROFILE.INSTALLED' | translate}}</strong>
              <strong class="text-xs block text-left line-height-2" *ngIf="!client.application_installed"
                (click)="shareAppModal=true">&nbsp;{{'CLIENT_PROFILE.NOT_INSTALLED' | translate}}
                <span class="block text-primary cursor-pointer ml-2">({{'CLIENT_PROFILE.SEND_LINK' |
                  translate}})</span></strong>
            </li>
            <li class="flex align-items-center text-xs my-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-gift mr-2 text-600" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <rect x="3" y="8" width="18" height="4" rx="1"></rect>
                <line x1="12" y1="8" x2="12" y2="21"></line>
                <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7"></path>
                <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5"></path>
              </svg>
              <span class="text-700">{{'CLIENT_PROFILE.BIRTHDAY' | translate}} - </span> <strong
                *ngIf="client.date_of_birth">&nbsp;{{client.date_of_birth | datetimeformat: 'mediumDate'}}</strong>
            </li>
          </ul>
          <!-- Features -->

          <!-- Tags -->
          <!-- <div class="flex align-items-center px-3">
          <p-chips [(ngModel)]="tags" inputStyleClass="text-sm" styleClass="border-none w-full mt-3 text-sm"
            placeholder="Add Tags"></p-chips>
          <span class="text-primary cursor-pointer ml-2 flex align-items-center white-space-nowrap text-xs">+ 3 more
            <i class="pi pi-chevron-down text-xxs ml-1"></i></span>
        </div> -->
          <!-- Tags -->

          <!-- Activities -->
          <!-- <div class="bg-blue-50 p-3">
            <div class="relative">
              <button pButton pRipple type="button" label="Activities"
                class="p-button-outlined text-sm font-bold w-full flex-row-reverse text-left bg-white"
                pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                leaveActiveClass="fadeout" [hideOnOutsideClick]="true"> <i class="pi pi-chevron-down"></i>
              </button>
              <ul
                class="list-none p-0 m-0 hidden text-800 origin-top mb-6 shadow-2 absolute bottom-0 left-0 w-full bg-white text-sm">
                <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.ACTIVITIES' | translate}}</li>
                <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.TASKS' | translate}}</li>
                <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.OPPORTUNITY' | translate}}</li>
                <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.BOOKING' | translate}}</li>
                <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.SUBSCRIPTIONS' | translate}}</li>
                <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.CHECK-INS' | translate}}</li>
                <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.FITNESS' | translate}}</li>
              </ul>
            </div>
          </div> -->
          <!-- Activities -->

        </div>
        <!-- Dropdown -->

      </div>
      <!-- Mobile View -->

    </div>
    <!-- Client Block -->

  </ng-container>

  <div class="px-3 md:-mt-3" [ngClass]="isTabMenuSticky ? 'py-3 md:pb-0 bg-white border-gray-300 shadow-1': ''">

    <!-- Tabs for Desktop -->
    <ul class="list-none p-0 m-0 hidden md:flex text-sm" (scroll)="stickyProfileInfo()"
      [ngClass]="isTabMenuSticky ? '-ml-3 border-bottom-1 bg-white border-gray-300': 'mt-3'">

      <!-- <li class="mr-2 xl:mr-3"><a href="javascript:void(0)"
        class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary">{{'CLIENT_PROFILE.TABS.ACTIVITIES' | translate}}</a>
    </li>
    <li class="mr-2 xl:mr-3"><a href="client-profile-tasks"
        class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary">{{'CLIENT_PROFILE.TABS.TASKS' | translate}}</a>
    </li>
    <li class="mr-2 xl:mr-3"><a href="javascript:void(0)"
        class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary">{{'CLIENT_PROFILE.TABS.OPPORTUNITY' | translate}}</a>
    </li>
    <li class="mr-2 xl:mr-3"><a href="javascript:void(0)"
        class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary">{{'CLIENT_PROFILE.TABS.BOOKING' | translate}}</a>
    </li> -->
      <!-- Active State (These classes need to be added border-bottom-2 border-primary font-bold ) -->
      <li class="mr-2 xl:mr-3">
        <a href="javascript:void(0)" class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary"
          [ngClass]="activeTab.toLowerCase() =='subscription' ? 'border-bottom-2 border-primary font-bold':''"
          [routerLink]="['/client/profile', clientId]">
          {{'CLIENT_PROFILE.TABS.SUBSCRIPTIONS' | translate}}
        </a>
      </li>
      <!-- Active State (These classes need to be added border-bottom-2 border-primary font-bold ) -->
      <li class="mr-2 xl:mr-3">
        <a href="javascript:void(0)"
          [ngClass]="activeTab.toLowerCase() =='transaction' ? 'border-bottom-2 border-primary font-bold':''"
          class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary"
          [routerLink]="['/client/profile', clientId,'transaction']">
          {{'CLIENT_PROFILE.TABS.TRANSACTIONS' | translate}}
        </a>
      </li>
      <!-- <li class="mr-2 xl:mr-3"><a href="javascript:void(0)"
        class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary">{{'CLIENT_PROFILE.TABS.CHECK-INS' | translate}}</a>
    </li>
    <li class="mr-2 xl:mr-3"><a href="javascript:void(0)"
        class="xl:px-3 p-2 no-underline text-800 block line-height-4 hover:text-primary">{{'CLIENT_PROFILE.TABS.FITNESS' | translate}}</a>
    </li> -->
    </ul>
    <!-- Tabs for Desktop -->

    <!-- Selectbox for Mobile -->
    <div class="relative md:hidden z-2" [ngClass]="isTabMenuSticky ? '': 'my-3'">
      <button pButton pRipple type="button" [label]="'CLIENT_PROFILE.TABS.ACTIVITIES'| translate"
        class="p-button-outlined text-sm font-bold w-full flex-row-reverse text-left bg-white" pStyleClass="@next"
        enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout"
        [hideOnOutsideClick]="true">
        <i class="pi pi-chevron-down"></i>
      </button>
      <ul
        class="list-none p-0 m-0 hidden text-800 origin-top mt-6 shadow-2 absolute top-0 left-0 w-full bg-white text-sm">
        <!-- <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.ACTIVITIES' | translate}}</li>
      <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.TASKS' | translate}}</li>
      <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.OPPORTUNITY' | translate}}</li>
      <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.BOOKING' | translate}}</li> -->
        <li class="py-2 px-3 hover:bg-gray-100" (click)="goToSubscriptionTab()">{{'CLIENT_PROFILE.TABS.SUBSCRIPTIONS' |
          translate}}</li>
        <li class="py-2 px-3 hover:bg-gray-100" (click)="goToTransactionTab()">{{'CLIENT_PROFILE.TABS.TRANSACTIONS' |
          translate}}</li>
        <!-- <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.CHECK-INS' | translate}}</li>
      <li class="py-2 px-3 hover:bg-gray-100">{{'CLIENT_PROFILE.TABS.FITNESS' | translate}}</li> -->
      </ul>
    </div>
    <!-- Selectbox for Mobile -->
    <!-- <router-outlet></router-outlet> -->
    <!-- <subscription-list></subscription-list> -->
  </div>
  <!-- Tabs -->
  <!-- Modals -->
</div>



<!-- Share App Link Modal  -->
<p-dialog [(visible)]="shareAppModal" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'991px': '100vw'}" [style]="{width: '990px'}" styleClass="no-header-modal">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">

    <!-- Header -->
    <div class="md:px-4 p-3 flex align-items-center justify-content-between border-bottom-1 border-gray-300">

      <h2 class="mb-0 text-base md:text-lg font-normal">Share App link via Email or SMS with <strong>Andrea
          Solan</strong> <span class="text-xs text-gray-600"> - 45653663</span></h2>

      <div class="pi pi-times text-gray-500 cursor-pointer" (click)="shareAppModal = false"></div>
    </div>
    <!-- Header -->


    <!-- Content -->
    <div class="flex">

      <!-- Left Side-->
      <div class="surface-100 border-right-1 border-gray-300 lg:w-8 w-full flex flex-column justify-content-between">

        <!-- Modal Content -->
        <div class="py-3 md:py-5 px-3 md:px-5 ">

          <!-- Grid -->
          <div class="grid align-items-end w-full">
            <div class="col-12 md:col-6">

              <div (click)="showSmsToast=true" (click)="shareAppModal = false"
                class="cursor-pointer flex align-items-center p-4 bg-white text-primary border-1 border-primary border-round no-underline font-bold hover:bg-primary hover:text-white">
                <i class="pi pi-comments mr-2"></i><span>Share link via SMS</span>
              </div>
              <p class="mb-0 mt-1 text-xs text-gray-600 md:absolute">Link shared 2 mins ago.</p>
            </div>
            <div class="col-12 md:col-6">

              <div (click)="showSmsToast=true" (click)="shareAppModal = false"
                class="cursor-pointer flex align-items-center p-4 bg-white text-primary border-1 border-primary border-round no-underline font-bold hover:bg-primary hover:text-white">
                <i class="pi pi-envelope mr-2"></i><span>Share link via Email</span>
              </div>
            </div>
          </div>
          <!-- Grid -->

        </div>
        <!-- Modal Content -->


        <!-- Modal Footer -->
        <div class="lg:px-5 px-2 py-4 border-top-1 border-gray-400 bg-white">
          <div
            class="flex flex-wrap md:flex-nowrap align-items-center justify-content-center md:justify-content-between">
            <div class="flex align-items-center">
              <a href="#" class="mr-1"><img src="/assets/img/apple-logo.svg" alt="Apple Logo" /></a>
              <a href="#" class="mr-3"><img src="/assets/img/googleplay-logo.svg" alt="Apple Logo" /></a>
            </div>
            <span class="text-sm">Link for Apple Appstore and Google Play store will be sent to the
              customer.</span>
          </div>
        </div>
        <!-- Modal Footer -->

      </div>
      <!-- Left Side -->

      <!-- Right Side -->
      <div class="pl-3 w-4 hidden lg:flex align-items-center justify-content-center p-3 text-right">
        <img width="205" src="/assets/img/share-link-img.svg" alt="Image" />
      </div>
      <!-- Right Side -->

    </div>
    <!-- Content -->


  </ng-template>

</p-dialog>
<!-- Share App Link Modal -->



<!-- Send Email Modal -->

<!-- Send Email Modal -->
<send-email *ngIf="toggleSendEmailDialog$ | async" [sendEmailModal]="toggleSendEmailDialog$ | async"
  [clientData$]="allClients$" (closeSendEmailDialog)="closeSendEmailDialog($event)"></send-email>
<!-- Send Email Modal - Less Access -->
<p-dialog [(visible)]="sendEmailModal2" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'991px': '100vw'}" [style]="{width: '870px'}" styleClass="no-header-modal overflow-visible">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">


    <!-- Header -->
    <div class="py-3 px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
      <h2 class="mb-0 text-base md:text-lg font-normal">Send email for <strong>Andrea Solan</strong></h2>
      <div class="pi pi-times text-gray-500 cursor-pointer" (click)="sendEmailModal = false"></div>
    </div>
    <!-- Header -->

    <!-- Content -->
    <div class="p-3 md:p-4">


      <!-- Composer -->
      <div class="bg-white shadow-3 border-round relative text-sm">

        <div class="px-3 md:px-4 py-3">

          <!-- From -->
          <div class="flex align-items-center">
            <span class="mr-2 w-3rem">From:</span>
            <strong>Anytime Fitness, Juhu</strong>
          </div>
          <!-- From -->

          <!-- To -->
          <div class="flex align-items-center my-3 border-bottom-1 pb-3 border-gray-300">
            <span class="mr-2 w-3rem">To:</span>
            <strong>Davis Macey <span class="font-normal">560901</span> (andrea@andreasolan.com)</strong>
          </div>
          <!-- To -->

          <!-- Message -->
          <div class="w-full relative">

            <div
              class="absolute top-0 left-0 z-1 bg-black-alpha-30 w-full h-full flex align-items-center justify-content-center">
              <i class="pi pi-lock text-6xl"></i>
            </div>

            <p-editor [disabled]="true" [readonly]="true" [(ngModel)]="editorText" [style]="{'height':'220px'}">
            </p-editor>

          </div>
          <!-- Message -->

        </div>

        <!-- Options -->
        <div class="bg-gray-200 mt-2 text-center md:text-left py-1 md:px-3 px-0 md:flex align-items-center">
          <p-dropdown [options]="attachments" [(ngModel)]="selectedAttachment" optionLabel="name" filterBy="name"
            styleClass="border-none bg-gray-200 text-primary text-sm font-bold" placeholder="Attach Document">
            <ng-template pTemplate="selectedItem">
              <strong *ngIf="selectedAttachment" class="text-sm text-primary">{{selectedAttachment.name}}</strong>
            </ng-template>
            <ng-template let-attachment pTemplate="item">
              <div class="text-sm">{{attachment.name}}</div>
            </ng-template>
          </p-dropdown>

          <p-dropdown [options]="templates" [(ngModel)]="selectedTemplate" optionLabel="name" filterBy="name"
            styleClass="border-none bg-gray-200 text-primary text-sm font-bold" placeholder="Use Email Template">
            <ng-template pTemplate="selectedItem">
              <strong *ngIf="selectedTemplate" class="text-sm text-primary">{{selectedTemplate.name}}</strong>
            </ng-template>
            <ng-template let-template pTemplate="item">
              <div class="text-sm">{{template.name}}</div>
            </ng-template>
          </p-dropdown>
        </div>
        <!-- Options -->

      </div>
      <!-- Composer -->

    </div>
    <!-- Content -->

    <!-- Modal Footer -->
    <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400">
      <div class="flex align-items-center justify-content-between">
        <div class="w-4">
          <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="toggleSendEmailDialog()"></p-button>
        </div>
        <div class="w-8 text-right">
          <button pButton pRipple label="Send Email" class="text-sm" (click)="toggleSendEmailDialog()"></button>
        </div>
      </div>
    </div>
    <!-- Modal Footer -->


  </ng-template>

</p-dialog>
<!-- Send Email Modal  - Less Access -->






<!-- Send SMS Modal  -->
<create-sms *ngIf="toggleCreateSmsDialog$ | async" [createSmsModal]="toggleCreateSmsDialog$ | async"
  [clientData$]="allClients$" (closeCreateSmsDialog)="closeCreateSmsDialog($event)"></create-sms>
<!-- Send SMS Modal -->


<!-- Send SMS - Less Access Modal -->
<p-dialog [(visible)]="sendSMSModal2" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'991px': '100vw'}" [style]="{width: '870px'}" styleClass="no-header-modal overflow-visible">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">

    <!-- Header -->
    <div class="py-3 px-4 flex align-items-center justify-content-between border-bottom-1 border-gray-300">
      <h2 class="mb-0 text-base md:text-lg font-normal">Create SMS for <strong>Andrea Solan</strong></h2>
      <div class="pi pi-times text-gray-500 cursor-pointer" (click)="sendSMSModal = false"></div>
    </div>
    <!-- Header -->

    <!-- Content -->
    <div class="p-3 md:p-4">


      <!-- Composer -->
      <div class="bg-white shadow-3 border-round relative text-sm">

        <div class="px-3 md:px-4 py-3">

          <!-- From -->
          <div class="flex align-items-center">
            <span class="mr-2 w-3rem">From:</span>
            <strong>Anytime Fitness, Juhu</strong>
          </div>
          <!-- From -->

          <!-- To -->
          <div class="flex align-items-center my-3 pb-3 border-bottom-1 border-gray-300">
            <span class="mr-2 w-3rem">To:</span>
            <strong>Davis Macey <span class="font-normal">560901</span> (+91 9898987678)</strong>
          </div>
          <!-- To -->

          <!-- Message -->
          <div class="w-full relative">
            <p-blockUI [target]="disabledSMS" [blocked]="true">
              <i class="pi pi-lock text-6xl"></i>
            </p-blockUI>

            <textarea #disabledSMS pInputTextarea class="text-sm h-17rem"></textarea>
          </div>
          <!-- Message -->

        </div>

        <!-- Options -->
        <div class="bg-gray-200 text-center mt-2 md:text-left py-1 md:px-3 px-0 md:flex align-items-center">
          <p-dropdown [options]="attachments" [(ngModel)]="selectedAttachment" optionLabel="name" filterBy="name"
            styleClass="border-none bg-gray-200 text-primary text-sm font-bold" placeholder="Attach Document">
            <ng-template pTemplate="selectedItem">
              <strong *ngIf="selectedAttachment" class="text-sm text-primary">{{selectedAttachment.name}}</strong>
            </ng-template>
            <ng-template let-attachment pTemplate="item">
              <div class="text-sm">{{attachment.name}}</div>
            </ng-template>
          </p-dropdown>

          <p-dropdown [options]="templates" [(ngModel)]="selectedTemplate" optionLabel="name" filterBy="name"
            styleClass="border-none bg-gray-200 text-primary text-sm font-bold" placeholder="Use SMS Template">
            <ng-template pTemplate="selectedItem">
              <strong *ngIf="selectedTemplate" class="text-sm text-primary">{{selectedTemplate.name}}</strong>
            </ng-template>
            <ng-template let-template pTemplate="item">
              <div class="text-sm">{{template.name}}</div>
            </ng-template>
          </p-dropdown>
        </div>
        <!-- Options -->

      </div>
      <!-- Composer -->

    </div>
    <!-- Content -->

    <!-- Modal Footer -->
    <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400">
      <div class="flex align-items-center justify-content-between">
        <div class="w-4">
          <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="sendSMSModal = false"></p-button>
        </div>
        <div class="w-8 text-right">
          <button pButton pRipple label="Send SMS" styleClass="text-sm" (click)="sendSMSModal = false"></button>
        </div>
      </div>
    </div>
    <!-- Modal Footer -->

  </ng-template>

</p-dialog>
<!-- Send SMS Modal - Less Access -->


<!-- Create Template Modal  -->
<p-dialog [(visible)]="createTemplateModal" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'767px': '100vw'}" [style]="{width: '720px'}" styleClass="no-header-modal">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">


    <!-- Header -->
    <div class="h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">

      <div class="flex align-items-center">
        <h2 class="mb-0 text-base md:text-lg font-normal">Create a New Template</h2>
      </div>
      <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
        (click)="createTemplateModal = false"></div>
    </div>
    <!-- Header -->


    <!-- Modal Content -->
    <div class="surface-100 py-3 md:py-5 px-3 md:px-5">

      <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase text-gray-600">Please enter a new template name:
      </h4>
      <input type="tel" class="w-full text-sm" pInputText value="" />

    </div>
    <!-- Modal Content -->


    <!-- Modal Footer -->
    <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
      <div class="flex align-items-center justify-content-between">
        <div class="w-4">
          <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="createTemplateModal = false">
          </p-button>
        </div>
        <div class="w-8 text-right">
          <button class="text-sm" disabled pButton pRipple label="Save"></button>
        </div>
      </div>
    </div>
    <!-- Modal Footer -->


  </ng-template>

</p-dialog>
<!-- Create Template Modal -->




<!-- Modals -->


<!-- Toast SMS -->
<div *ngIf="showSmsToast" class="inline-block fixed right-0 top-0 mt-8 mr-8 z-4">
  <p-messages severity="success">
    <ng-template pTemplate>
      <div class="p-success flex w-full">
        <i class="pi pi-check p-success text-base"></i>
        <div class="ml-2">
          <span class="custom-message text-sm">App link shared via SMS</span>
        </div>
      </div>
    </ng-template>
  </p-messages>
</div>
<!-- Toast SMS -->


<p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>