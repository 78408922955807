
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiModule } from '@fitness-force/api';
import { appointmentTypeReducer, cityListReducer, claimsReducer, classTypeReducer, clearState, companyReducer, countryListReducer, locationReducer, reportReducer, roomReducer, serviceTypeReducer, staffReducer, stageReducer, stateListReducer, tenantReducer } from './state/app.reducer';
import { CompanyEffects } from './state/effect';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AuthguardService, ClaimsguardService, DomainguardService,CanDeactivateGuard } from '@fitness-force/shared';
import { RouterSerializerClass } from './state/serializer';
import { IntercepterService } from 'libs/api/src/lib/intercepter.service';
import { ConfirmationService } from 'primeng/api';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxHotjarModule } from 'ngx-hotjar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MbscModule } from '@mobiscroll/angular';
export function FactoryHttpLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/dist/i18n/', '.json');
}

export const routerStateConfig = {
  stateKey: 'router', // state-slice name for routing state
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ApiModule,
    ConfirmDialogModule,
    MbscModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      company: companyReducer,
      claims: claimsReducer,
      tenants: tenantReducer,
      staff: staffReducer,
      router: routerReducer,
      stages: stageReducer,
      countries: countryListReducer,
      appointmentTypes: appointmentTypeReducer,
      classTypes: classTypeReducer,
      states: stateListReducer,
      cities: cityListReducer,
      serviceTypeList: serviceTypeReducer,
      rooms: roomReducer,
      location: locationReducer,
    }, {
      // metaReducers: !environment.production ? [] : [],
      metaReducers: [clearState],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([CompanyEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({
      serializer: RouterSerializerClass
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDH5wyNVLWMF5ktoZ-5m_r7DY4kPDLAWXA',
      libraries: ['geometry', 'places']
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.googleGTM,
      // gtm_auth: YOUR_GTM_AUTH,
      // gtm_preview: YOUR_GTM_ENV
    }),
    NgxHotjarModule.forRoot(environment.hj)

  ],
  providers: [
    DomainguardService,
    AuthguardService,
    GoogleMapsAPIWrapper,
    ClaimsguardService,
    CanDeactivateGuard,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: IntercepterService, multi: true },
    { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
