import { Dropdown, ReportListResponse, REPORTSTOREINTERFACE, Textbox } from '@fitness-force/models';

export const INITIAL_REPORT_LIST_STATE: ReportListResponse = {
  category: '',
  icon: '',
  reports: []
}
  // {
  //   report_key: 'SubscriptionSignUpReport',
  //   claims: [],
  //   payload:
  //     '{"from_date", "to_date", "plan_id", "time_zone", "current_page", "page_size" }',
  //   default_payload: '{"from_date":"", "to_date", "plan_id", "time_zone", "current_page", "page_size" }',
  //   mandatory_fields: [
  //     new Dropdown({
  //       value: '',
  //       key: 'time_period',
  //       label: 'Select time period',
  //       required: true,
  //       order: 3,
  //       options: [],
  //     }),
  //     new Textbox({
  //       key: 'firstName',
  //       label: 'First name',
  //       value: 'Bombasto',
  //       order: 1,
  //     }),

  //     new Textbox({
  //       key: 'emailAddress',
  //       label: 'Email',
  //       type: 'email',
  //       order: 2,
  //     }),
  //   ],
  // },
  // {
  //   report_key: 'ClientListReport',
  //   claims: [],
  //   payload:
  //     '{"from_date", "to_date", "plan_id", "time_zone", "current_page", "page_size" }',
  //   default_payload: '',
  //   mandatory_fields: [
  //     new Dropdown({
  //       value: '',
  //       key: 'account_manager',
  //       label: 'Account manager',
  //       required: true,
  //       order: 1,
  //       options: [],
  //     }),
  //     new Dropdown({
  //       value: '',
  //       key: 'center_name',
  //       label: 'Center name',
  //       required: true,
  //       order: 2,
  //       options: [],
  //     }),
  //     new Dropdown({
  //       value: '',
  //       key: 'enquiry_source',
  //       label: 'Enquiry source',
  //       required: true,
  //       order: 3,
  //       options: [],
  //     }),
  //   ],
  // },

