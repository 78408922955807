
        <!-- Change Password Modal  -->
        <p-dialog [(visible)]="changePasswordModal" [resizable]="false" appendTo="body" [modal]="true"
        
          [breakpoints]="{'991px': '100vw'}" [style]="{width: '960px'}" styleClass="no-header-modal">
  
          <ng-template pTemplate="header" styleClass="hidden"></ng-template>
          <ng-template pTemplate="content" styleClass="p-0">
  
  
            <!-- Header -->
            <div class="h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">
  
              <div class="flex align-items-center">
                <h2 class="mb-0 text-base md:text-lg font-normal">Change Password for <strong>Andrea Solan </strong>
                  <span class="text-xs text-gray-600"> - 45653663</span>
                </h2>
              </div>
              <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
                (click)="changePasswordModal = false"></div>
            </div>
            <!-- Header -->
  
  
            <!-- Content -->
            <div class="flex">
  
              <!-- Left Side-->
              <div class="border-right-1 border-gray-300 lg:w-6 w-full">
  
                <!-- Modal Content -->
                <div class="py-2 md:py-4 px-3 md:px-5 surface-100">
                  <!-- Column -->
                  <div class="mb-3 md:mb-4 lg:w-11">
                    <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">New Password</h4>
                    <p-password [toggleMask]="true" styleClass="w-full p-input-icon-right"></p-password>
                  </div>
                  <!-- Column -->
  
                  <!-- Column -->
                  <div class="mb-3 md:mb-4 lg:w-11">
                    <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">Confirm New Password</h4>
                    <p-password [toggleMask]="true" styleClass="w-full p-input-icon-right"></p-password>
                  </div>
                  <!-- Column -->
                </div>
                <!-- Modal Content -->
  
                <!-- Password Rules -->
                <div class="lg:px-5 px-3 py-3 border-top-1 border-gray-400 bg-white">
                  <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-2 md:py-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks text-500"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M7 12l5 5l10 -10"></path>
                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                      </svg>
                      <span class="ml-3 text-sm md:text-base">At least 8 characters long</span>
                    </li>
                    <li class="flex align-items-center py-2 md:py-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks text-500"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M7 12l5 5l10 -10"></path>
                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                      </svg>
                      <span class="ml-3 text-sm md:text-base">One lowercase character</span>
                    </li>
                    <li class="flex align-items-center py-2 md:py-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks text-500"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M7 12l5 5l10 -10"></path>
                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                      </svg>
                      <span class="ml-3 text-sm md:text-base">One uppercase character</span>
                    </li>
                    <li class="flex align-items-center py-2 md:py-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks text-500"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M7 12l5 5l10 -10"></path>
                        <path d="M2 12l5 5m5 -5l5 -5"></path>
                      </svg>
                      <span class="ml-3 text-sm md:text-base">One number, symbol, or whitespace character</span>
                    </li>
                  </ul>
                </div>
                <!-- Password Rules -->
  
  
                <!-- Modal Footer -->
                <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
                  <div class="flex align-items-center justify-content-between">
                    <div class="w-4">
                      <p-button label="Cancel" styleClass="p-button-link text-sm" (click)="changePasswordModal = false">
                      </p-button>
                    </div>
                    <div class="w-8 text-right">
                      <button disabled pButton pRipple label="Reset Password" class="text-sm"></button>
                    </div>
                  </div>
                </div>
                <!-- Modal Footer -->
  
              </div>
              <!-- Left Side -->
  
              <!-- Right Side -->
              <div class="pl-3 w-6 hidden lg:flex align-items-center justify-content-center p-3 text-right">
                <img src="/assets/img/change-pwd-img.svg" alt="Image" />
              </div>
              <!-- Right Side -->
  
            </div>
            <!-- Content -->
  
  
          </ng-template>
  
        </p-dialog>
        <!-- Change Password Modal -->
