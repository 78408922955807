
    export interface Tag {
        id: number;
        name_primary_language: string;
        name_secondary_language: string;
    }

    export interface AppointmentType {
        id: number;
        service_type_id: number;
        name_primary_language: string;
        name_secondary_language: string;
        behaviour: string;
        color: string;
        status: string;
        accessible_on_staff_facing_apps: boolean;
        accessible_on_member_facing_apps: boolean;
        accessible_on_website_widgits: boolean;
        logo: string;
        duration_in_minutes: number;
        is_room_mandatory: boolean;
        tags: Tag[];
        created_by: number;
        updated_by: number;
        created_date: Date;
        updated_date: Date;
    }

    export interface AppointmentTypeList {
        items: AppointmentType[];
    }

    export interface AppointmentTypeStore {
        appointmentTypes: AppointmentType[]
     };



    export interface ApptSubtype {
        id: number;
        company_id: number;
        tenant_id: number;
        created_by: number;
        created_date: Date;
        appt_type_id: number;
        name_primary_language: string;
        name_secondary_language: string;
        description_primary_language: string;
        description_secondary_language: string;
        things_to_carry_primary_language: string;
        things_to_carry_secondary_language: string;
    }

    export interface AppointmentTypeById {
        id: number;
        company_id: number;
        tenant_id: number;
        service_type_id: number;
        name_primary_language: string;
        name_secondary_language: string;
        behaviour: string;
        color: string;
        status: string;
        accessible_on_staff_facing_apps: boolean;
        accessible_on_member_facing_apps: boolean;
        accessible_on_website_widgits: boolean;
        logo: string;
        commission_mode: string;
        minimum_booking_cancel_time: number;
        mark_trainer_busy: boolean;
        is_room_mandatory: boolean;
        booking_opens_in_minutes: number;
        is_online_cancel_booking_allowed: boolean;
        duration_in_minutes: number;
        tags: Tag[];
        created_by: number;
        updated_by: number;
        created_date: Date;
        updated_date: Date;
        subtypes: ApptSubtype[];
    }

