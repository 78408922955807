<p-dialog [(visible)]="showDailog" [resizable]="false" appendTo="body" [modal]="true"
  [breakpoints]="{'1199px': '100vw'}" [style]="{width: '740px'}" styleClass="no-header-modal">

  <ng-template pTemplate="header" styleClass="hidden"></ng-template>
  <ng-template pTemplate="content" styleClass="p-0">


    <!-- Header -->
    <div class="h-3rem md:h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-2 border-gray-300">
      <div class="flex align-items-center">
        <h2 class="mb-0 text-lg">Lost Lead Reason</h2>
      </div>
      <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
        (click)="closeAllDialogs()"></div>
    </div>
    <!-- Header -->
    <!-- Header -->
    <div class="flex align-items-center justify-content-between py-2 px-3">
      <div class="flex align-items-center my-1">
        <span class="mr-2" *ngIf="lostDealInfo.client_info && lostDealInfo.client_info.image"><img class="border-circle" width="32" [src]="lostDealInfo.client_info.image" alt="Avatar" /></span>
        <span class="text-primary font-bold text-base md:text-lg mr-2" *ngIf="lostDealInfo">{{lostDealInfo.client_info.full_name}}</span>
        <span class="text-600 font-normal text-sm" *ngIf="lostDealInfo">Client ID - {{lostDealInfo.client_info.id}}</span>
      </div>
      <div [innerHTML]="clientStatus(lostDealInfo.client_info)"></div>
    </div>
    <!-- Header -->


    <!-- Content -->
    <div class="p-3 md:p-6 surface-100">
      <div class="grid">

        <div class="col-12 md:col-6 my-2">
          <div
            class="bg-white border-2 border-gray-300 border-round p-2 flex align-items-center line-height-1 cursor-pointer hover:bg-primary hover:border-primary transition-all transition-duration-300"
            (click)=" showReasonDailog('lost-to-competitor-img', 'lost to competitors')">
            <span
              class="h-3rem w-3rem bg-primary flex align-items-center justify-content-center border-round transition-all transition-duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-x h-2rem w-2rem"
                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                <path d="M17 9l4 4m0 -4l-4 4"></path>
              </svg>
            </span>
            <strong class="text-xl ml-3 transition-all transition-duration-300">Lost to Competitors</strong>
          </div>
        </div>


        <div class="col-12 md:col-6 my-2">
          <div
            class="bg-white border-2 border-gray-300 border-round p-2 flex align-items-center line-height-1 cursor-pointer hover:bg-primary hover:border-primary transition-all transition-duration-300"
            (click)="showReasonDailog('lost-to-high-price', 'Lost due to Price High')">
            <span
              class="h-3rem w-3rem bg-primary flex align-items-center justify-content-center border-round transition-all transition-duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-coin h-2rem w-2rem" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="9"></circle>
                <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1"></path>
                <path d="M12 6v2m0 8v2"></path>
              </svg>
            </span>
            <strong class="text-xl ml-3 transition-all transition-duration-300">High Price</strong>
          </div>
        </div>

        <div class="col-12 md:col-6 my-2">
          <div
            class="bg-white border-2 border-gray-300 border-round p-2 flex align-items-center line-height-1 cursor-pointer hover:bg-primary hover:border-primary transition-all transition-duration-300"
            (click)="showReasonDailog('lost-to-missing-facility-img', 'Missing Facility')">
            <span
              class="h-3rem w-3rem bg-primary flex align-items-center justify-content-center border-round transition-all transition-duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-question-mark h-2rem w-2rem"
                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                <line x1="12" y1="19" x2="12" y2="19.01"></line>
              </svg>
            </span>
            <strong
              class="text-xl ml-3 transition-all transition-duration-300 transition-all transition-duration-300">Missing
              Facility</strong>
          </div>
        </div>

        <div class="col-12 md:col-6 my-2">
          <div
            class="bg-white border-2 border-gray-300 border-round p-2 flex align-items-center line-height-1 cursor-pointer hover:bg-primary hover:border-primary transition-all transition-duration-300"
            (click)="showReasonDailog('lost-to-other-reasons-img', 'Lost to Other Reasons')">
            <span
              class="h-3rem w-3rem bg-primary flex align-items-center justify-content-center border-round transition-all transition-duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-dots h-2rem w-2rem" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="5" cy="12" r="1"></circle>
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="19" cy="12" r="1"></circle>
              </svg>
            </span>
            <strong class="text-xl ml-3 transition-all transition-duration-300">Other Reason</strong>
          </div>
        </div>


        <div class="col-12 md:col-6 my-2">
          <div
            class="bg-white border-2 border-gray-300 border-round p-2 flex align-items-center line-height-1 cursor-pointer hover:bg-primary hover:border-primary transition-all transition-duration-300"
            (click)="showReasonDailog('lost-to-no-contactable-img', 'Not Contactable')">
            <span
              class="h-3rem w-3rem bg-primary flex align-items-center justify-content-center border-round transition-all transition-duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone-off h-2rem w-2rem"
                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="3" y1="21" x2="21" y2="3"></line>
                <path
                  d="M5.831 14.161a15.946 15.946 0 0 1 -2.831 -8.161a2 2 0 0 1 2 -2h4l2 5l-2.5 1.5c.108 .22 .223 .435 .345 .645m1.751 2.277c.843 .84 1.822 1.544 2.904 2.078l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a15.963 15.963 0 0 1 -10.344 -4.657">
                </path>
              </svg>
            </span>
            <strong class="text-xl ml-3 transition-all transition-duration-300">Not Contactable</strong>
          </div>
        </div>

      </div>
    </div>
    <!-- Content -->

  </ng-template>

</p-dialog>
<app-lost-deal-reason *ngIf="lostDealReason | async" [reason]="lostDealReason | async" [showLostReason]="togglelostReason" [dealInfo]="lostDealInfo" (goBack)="closeReasonDialog()" (closeDailogs)="closeAllDialogs()" (reloadData)="reloadData()"></app-lost-deal-reason>