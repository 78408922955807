import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { AgePipeModule } from 'libs/shared/src/lib/filters/age.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'irregular-age-cell',
  templateUrl: './irregular-age-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IrregularAgeCellComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)
  dateValue!:string;

  constructor() { }

  ngOnInit(): void {
    this.columnData.subscribe((value:any)=>{
       if(new Date(value?.last_visited_date) > new Date(value?.created_date)){
        this.dateValue=value?.last_visited_date;
       }else{
        this.dateValue=value?.created_date;
       }
    })
  }

}

@NgModule({
  declarations: [IrregularAgeCellComponent],
  imports: [
    CommonModule,
    AgePipeModule,
  ],
  exports: [IrregularAgeCellComponent]
})
export class IrregularAgeCellComponentModule {
}
