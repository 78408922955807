import { TableAction } from "@fitness-force/enum";
import { ClientGenderComponent, ClientRegularStatusComponent } from "@fitness-force/shared";

export class ReportFormBase<T> {
  value: any | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: { key: string; value: string }[];

  constructor(
    options: {
      value?: any;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: { key: string; value: string }[] | any;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
  }
}

export class Dropdown extends ReportFormBase<string> {
  override controlType = 'dropdown';
}

export class TimePeriodDropdown extends ReportFormBase<string> {
  override controlType = 'timeperioddropdown';
}

export class Textbox extends ReportFormBase<string> {
  override controlType = 'textbox';
}

export class HiddenField extends ReportFormBase<string> {
  override controlType = 'hiddenfield';
}



export const ReportTableColumnData = [
  {
    report_key: 'ClientListReport',
    actions:[
      {
        label: 'Create Apportunity',
        class: 'p-button-text text-xs md:text-sm mb-1 xl:mb-2',
        type: "button",
        actionType: TableAction.Opportunity,
        icon:"pi pi-plus"
      },
      {
        label: 'Create Task',
        class: 'p-button-text text-xs md:text-sm mb-1 xl:mb-2',
        type: "button",
        actionType: TableAction.Task,
        icon:"pi pi-list",
      }
    ],
    columns: [
      {
        title: 'CLIENT NAME',
        classes: 'py-2 text-gray-600 w-15rem white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'clt_full_name',
        type: 'default',
        data: {},
      },
      {
        title: 'EMAIL',
        classes: 'py-2 text-gray-600 w-15rem white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'clt_emailid',
        type: 'default',
      },
      {
        title: 'MOBILE NO',
        classes: 'py-2 text-gray-600 w-15rem white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'clt_mobile_no',
        type: 'default',
      },
      {
        title: 'DATE OF BIRTH',
        classes: 'py-2 text-gray-600 w-15rem white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'clt_dateofbirth',
        type: 'default',
      },
      {
        title: 'GENDER',
        classes: 'py-2 text-gray-600 w-15rem white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'clt_gender',
        type: 'component',
        data: {name: ClientGenderComponent}
      },
      {
        title: 'CLIENT IRREGULAR',
        classes: 'py-2 text-gray-600 w-15rem white-space-nowrap',
        sortable: true,
        filterable: true,
        dataProperty: 'clt_is_irregular',
        type: 'component',
        data: {name: ClientRegularStatusComponent}
      }
    ],
  },
];
