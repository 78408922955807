import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'contract-term-view',
  templateUrl: './contract-term-view.component.html',
  styleUrls: ['./contract-term-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractTermViewComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  @Input() additionData =  new BehaviorSubject(null);


  constructor() { }

  ngOnInit(): void {

  }



}

@NgModule({
  declarations: [ContractTermViewComponent],
  imports: [CommonModule],
  exports: [ContractTermViewComponent]
})
export class ContractTermViewComponentModule {
}
