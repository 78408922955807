<!-- Change Password Modal  -->
<p-dialog [(visible)]="changePasswordModal" [resizable]="false" [modal]="true" (onHide)="ClosePopup()" [breakpoints]="{'991px': '100vw'}"
    [style]="{width: '960px'}" styleClass="no-header-modal">
    <ng-template pTemplate="header" styleClass="hidden"></ng-template>
    <ng-template pTemplate="content" styleClass="p-0">
        <form [formGroup]="formGroup" (ngSubmit)="ValidateAndSubmit()">
            <!-- Header -->
            <div class="h-4rem md:pl-4 pl-3 pr-5 flex align-items-center relative border-bottom-1 border-gray-300">
                <div class="flex align-items-center">
                    <h2 class="mb-0 text-base md:text-lg font-normal">
                        {{'STAFF.CHANGEPASSWORD.HEADER_CHANGE_PASSWORD_FOR' | translate}}
                        <strong>{{staff.name}}</strong>
                        <span class="text-xs text-gray-600"> - {{staff.id}}</span>
                    </h2>
                </div>
                <div class="pi pi-times opacity-50 absolute right-0 top-0 mt-3 mr-3 md:mt-4 md:mr-4 cursor-pointer"
                    (click)="ClosePopup()"></div>
            </div>
            <!-- Header -->
            <!-- Content -->
            <div class="flex">
                <!-- Left Side-->
                <div class="border-right-1 border-gray-300 lg:w-6 w-full">
                    <!-- Modal Content -->
                    <div class="py-2 md:py-4 px-3 md:px-5 surface-100">
                        <!-- Column -->
                        <div class="mb-3 lg:w-11">
                            <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                {{'STAFF.CHANGEPASSWORD.HEADER_OLD_PASSWORD' | translate}}
                            </h4>
                            <p-password [toggleMask]="true" formControlName="old_password"
                                styleClass="w-full p-input-icon-right" [feedback]="false"></p-password>
                            <app-error-msg *ngIf="(OldPassword.touched || OldPassword.dirty) && OldPassword.invalid"
                                [errors]="OldPassword.errors"
                                [fieldLabel]="'STAFF.CHANGEPASSWORD.OLD_PASSWORD' | translate">
                            </app-error-msg>
                        </div>
                        <!-- Column -->
                        <!-- Column -->
                        <div class="mb-3 md:mb-4 lg:w-11">
                            <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                {{'STAFF.CHANGEPASSWORD.HEADER_NEW_PASSWORD' | translate}}
                            </h4>
                            <p-password [toggleMask]="true" formControlName="new_password"
                                styleClass="w-full p-input-icon-right"></p-password>
                            <app-error-msg *ngIf="NewPassword.touched && NewPassword.invalid"
                                [errors]="NewPassword.errors"
                                [fieldLabel]="'STAFF.CHANGEPASSWORD.NEW_PASSWORD' | translate">
                            </app-error-msg>
                        </div>
                        <!-- Column -->
                        <!-- Column -->
                        <div class="lg:w-11">
                            <h4 class="font-medium text-gray-700 text-xs mb-2 block uppercase">
                                {{'STAFF.CHANGEPASSWORD.HEADER_CONFIRM_NEW_PASSWORD' | translate}}
                            </h4>
                            <p-password [toggleMask]="true" formControlName="new_confirm_password"
                                styleClass="w-full p-input-icon-right"></p-password>
                            <app-error-msg *ngIf="NewConfirmPassword.touched && NewConfirmPassword.invalid"
                                [errors]="NewConfirmPassword.errors"
                                [fieldLabel]="'STAFF.CHANGEPASSWORD.NEW_CONFIRM_PASSWORD' | translate">
                            </app-error-msg>
                        </div>
                        <!-- Column -->
                    </div>
                    <!-- Modal Content -->
                    <!-- Password Rules -->
                    <div class="lg:px-5 px-3 py-3 border-top-1 border-gray-400 bg-white">
                        <ul class="list-none p-0 m-0 text-sm">
                            <li class="flex align-items-center py-2">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-checks text-500" width="24" height="24"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M7 12l5 5l10 -10"></path>
                                    <path d="M2 12l5 5m5 -5l5 -5"></path>
                                </svg>
                                <span class="ml-3 text-sm md:text-base">
                                    {{'STAFF.CHANGEPASSWORD.SPAN_ATLEAST8CHARLONG' | translate}}
                                </span>
                            </li>
                            <li class="flex align-items-center py-2">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-checks text-500" width="24" height="24"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M7 12l5 5l10 -10"></path>
                                    <path d="M2 12l5 5m5 -5l5 -5"></path>
                                </svg>
                                <span class="ml-3 text-sm md:text-base">
                                    {{'STAFF.CHANGEPASSWORD.SPAN_ONELOWERCHAR' | translate}}
                                </span>
                            </li>
                            <li class="flex align-items-center py-2">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-checks text-500" width="24" height="24"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M7 12l5 5l10 -10"></path>
                                    <path d="M2 12l5 5m5 -5l5 -5"></path>
                                </svg>
                                <span class="ml-3 text-sm md:text-base">
                                    {{'STAFF.CHANGEPASSWORD.SPAN_ONEUPPERCHAR' | translate}}
                                </span>
                            </li>
                            <li class="flex align-items-center py-2">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-checks text-500" width="24" height="24"
                                    viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M7 12l5 5l10 -10"></path>
                                    <path d="M2 12l5 5m5 -5l5 -5"></path>
                                </svg>
                                <span class="ml-3 text-sm md:text-base">
                                    {{'STAFF.CHANGEPASSWORD.SPAN_SPECIALCHAR' | translate}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <!-- Password Rules -->
                    <!-- Modal Footer -->
                    <div class="lg:px-4 px-2 py-3 border-top-1 border-gray-400 bg-white">
                        <div class="flex align-items-center justify-content-between">
                            <div class="w-4">
                                <p-button label="{{'STAFF.CHANGEPASSWORD.CANCEL' | translate}}"
                                    styleClass="text-sm p-button-link" (click)="ClosePopup()">
                                </p-button>
                            </div>
                            <div class="w-8 text-right">
                                <button type="submit" pButton pRipple
                                    label="{{'STAFF.CHANGEPASSWORD.RESETPASSWORD' | translate}}"
                                    class="text-sm"></button>
                            </div>
                        </div>
                    </div>
                    <!-- Modal Footer -->
                </div>
                <!-- Left Side -->
                <!-- Right Side -->
                <div class="pl-3 w-6 hidden lg:flex align-items-center justify-content-center p-3 text-right">
                    <img src="/assets/img/change-pwd-img.svg" alt="Image" />
                </div>
                <!-- Right Side -->
            </div>
            <!-- Content -->
        </form>
    </ng-template>
</p-dialog>
<!-- Change Password Modal -->
<!-- </ng-container> -->

<!-- Confirm Delete Profile Modal  -->
<app-errors *ngIf="showErrorPopup | async as show_Popup" [visible]="show_Popup" [errorData]="errorMessages" [error]="error"
    (hideErrorPopup)="HideErrorPopup($event)">
</app-errors>
<!-- Confirm Delete Profile Modal -->