import { ClientService } from '@fitness-force/api';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BadRequestError, ErrorMsgComponentModule, NotFoundError } from '@fitness-force/errors';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject } from 'rxjs';
import { FactoryHttpLoader } from '../../helpsupport/helpsupport.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'assign-key-fob',
  templateUrl: './assign-key-fob.component.html',
  styleUrls: ['./assign-key-fob.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignKeyFobComponent implements OnInit {
  @Input() assignKeyFobModal: boolean | null = false;
  @Input() clientData$:any = new BehaviorSubject(null)
  @Output() closeassignKeyFobModalDialog =  new EventEmitter()
  assignKeyFobFormGroup: FormGroup = new FormGroup({})
  validAlphaNumericPattern = "^[a-zA-Z0-9]{12}$";
  allClientData:any
  constructor(private fb: FormBuilder,public translate: TranslateService, private clientService: ClientService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.componentInit()
  }
  
  componentInit(){
    this.assignKeyFobFormGroup = this.fb.group({
      keyBobNumber: ['', [Validators.required, Validators.maxLength(12), Validators.pattern(this.validAlphaNumericPattern)]]
    })
    this.clientData$.subscribe((res:any)=>{
      this.allClientData = res
    })
  }
  defaultAssignKeyFobFormValue(){
    this.KeyBobNumber.setValue( this.allClientData.mobile_number)
  }
  get KeyBobNumber(): FormControl{
    return this.assignKeyFobFormGroup.get('keyBobNumber') as FormControl;
  }
  closeDialogEvent(){
    this.closeassignKeyFobModalDialog.emit(false)
  }
  postAssignKeyFob(){
    const updateProfileData = {
      "home_club_tenant_id": this.allClientData.home_club_tenant_id,
      "full_name": this.allClientData.full_name,
      "mobile_country_code": this.allClientData.mobile_country_code,
      "mobile_number": this.allClientData.mobile_number,
      "email_id": this.allClientData.email_id,
      "date_of_birth": this.allClientData.date_of_birth,
      "gender": this.allClientData.gender,
      "location": this.allClientData.location,
      "second_mobile_country_code":  this.allClientData.second_mobile_country_code,
      "second_mobile_number": this.allClientData.second_mobile_number,
      "preferred_language":  this.allClientData.preferred_language,
      "place_id": this.allClientData.place_id,
      "address":  this.allClientData.address,
      "city_code":  this.allClientData.city_code,
      "state_code":this.allClientData.state_code,
      "country_code": this.allClientData.country_code,
      "address_pin_code": this.allClientData.address_pin_code,
      "emergency_contact_number": this.allClientData.emergency_contact_number,
      "emergency_contact_person": this.allClientData.emergency_contact_person,
      "source": this.allClientData.source,
      "channel":  this.allClientData.channel,
      "heartrate_monitoring_id":  this.allClientData.heartrate_monitoring_id,
      "referred_by":  this.allClientData.referred_by,
      "promotional_sms":   this.allClientData.promotional_sms,
      "promotional_email": this.allClientData.promotional_email,
      "medical_alert": this.allClientData.medical_alert,
      "note": this.allClientData.note,
      "taxnumber":this.allClientData.taxnumber,
      "custom_fields":this.allClientData.custom_fields,
      "current_keyfob": this.KeyBobNumber.value ? this.KeyBobNumber.value :  this.allClientData.current_keyfob ,
      "client_representative_id": this.allClientData.client_representative_id,
      "createdin_tenant_id":  this.allClientData.createdin_tenant_id,
      "place_id_request": this.allClientData.place_id_request,
      "migration_id": this.allClientData.migration_id,
      "image_base64": this.allClientData.image_base64,
      "password": this.allClientData.password
    }
    this.clientService.updateClientById(this.allClientData.id, this.allClientData.home_club_tenant_id, updateProfileData).subscribe({
      next: (res)=>{
      console.log('res', res)
      this.closeDialogEvent()
      },
      error: (error)=>{
        console.log('errrr', error)
        if (error instanceof NotFoundError) {
         
          this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Page Not Found'});
        } else if (error instanceof BadRequestError) {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail: error.originalError.error[0].errorMessage});
        }
        else {
          this.messageService.add({severity:'error', summary:'Error has occured!', detail:'Something went wrong'});
        }
      }
    }
    )
  }
}

@NgModule({
  declarations: [AssignKeyFobComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AvatarModule,
    ButtonModule,
    RippleModule,
    BlockUIModule,
    StyleClassModule,
    InputTextModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      }
    }),
    ErrorMsgComponentModule,
    ToastModule
  ],
  exports: [AssignKeyFobComponent]
})
export class AssignKeyFobComponentModule {
}
