import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { throwError, Observable, pipe } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Report, ReportAccessRequest, ReportListResponse } from '@fitness-force/models';
import { environment } from 'apps/ff-frontend/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient, private router: Router) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  GetReports(): Observable<ReportListResponse[]> {
    return this.http
      .get(`${environment.BASE_URL}/reports`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  MarkAsFevouriteReport(reportkey: string): Observable<Response> {
    return this.http
      .post(`${environment.BASE_URL}/reports/${reportkey}/add_favourite`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  RemoveFromFevouriteReport(reportkey: string): Observable<Response> {
    return this.http
      .post(`${environment.BASE_URL}/reports/${reportkey}/remove_favourite`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  RequestReportAccess(requestData: ReportAccessRequest): Observable<Response> {
    return this.http
      .post(`${environment.BASE_URL}/request_access`, requestData, { headers: this.httpOptions.headers })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
