import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { CoinIcons, Crown, TimeLine } from '@fitness-force/enum';
import { timeLineService } from 'libs/shared/src/lib/filters/timeline.pipe';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'member-crown',
  templateUrl: './member-crown.component.html',
  styleUrls: ['./member-crown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberCrownComponent implements OnInit {
  @Input() columnData  = new BehaviorSubject(null);
  crownType!:any;
  constructor() { }

  ngOnInit(): void {
  }
  isBeyondOneYear(clientSince:string,created:string):boolean{
    const duration  = (new Date(clientSince) > new Date(created))?timeLineService(clientSince):timeLineService(created);
    if(duration.value >= TimeLine.BeyondOneYears && duration.value < TimeLine.BeyondThreeYears && duration.type == TimeLine.TimeAgo ){
      this.crownType= {crown: Crown.SilverCrown, coin: CoinIcons.LessSpentIcon}
      return true;
    } else if(duration.value >= TimeLine.BeyondThreeYears && duration.value < TimeLine.BeyondFiveYears && duration.type == TimeLine.TimeAgo){
      this.crownType= {crown: Crown.GrayCrown, coin: CoinIcons.MediumSpentIcon}
      return true;
    } else if(duration.value >= TimeLine.BeyondFiveYears && duration.type == TimeLine.TimeAgo ){
      this.crownType= {crown: Crown.GoldCrown, coin:  CoinIcons.MostSpentIcon}
      return true;
    }
    return false;

  }
}

@NgModule({
  declarations: [MemberCrownComponent],
  imports: [
    CommonModule
  ],
  exports: [MemberCrownComponent]
})
export class MemberCrownComponentModule {
}
