<div [formGroup]="form" [ngSwitch]="field.controlType">

  <label *ngIf="field.key !=''" [attr.for]="field.key" class="font-medium text-gray-700 text-xs mb-2 block uppercase">{{field.label | translate}}</label>

  <input *ngSwitchCase="'textbox'" class="p-inputtext  w-full text-sm" [formControlName]="field.key" [type]="field.type">

  <input *ngSwitchCase="'hiddenfield'" type="hidden" [formControlName]="field.key">

  <p-dropdown [options]="field.options" *ngSwitchCase="'dropdown'" [formControlName]="field.key"
    optionLabel="key" optionValue="value" filterBy="key" styleClass="w-full"
    placeholder="{{'REPORTS.REPORT_TEMPLATE.SELECT_PLACEHOLDER' | translate}}">

    <!-- <ng-template pTemplate="selectedItem">
      <div class="text-sm" *ngIf="GetFieldValue(form,field.key)">{{GetFieldValue(form,field.key).key}}</div>
    </ng-template> -->

    <ng-template let-option pTemplate="item">
      <div class="text-sm">{{option.key}}</div>
    </ng-template>

  </p-dropdown>


  <p-dropdown *ngSwitchCase="'timeperioddropdown'" [options]="field.options" [formControlName]="field.key"
    optionLabel="label"  styleClass="w-full text-sm" (onChange)="SelectTimePeriod($event,form)"
    placeholder="{{'REPORTS.REPORT_TEMPLATE.SELECT_PLACEHOLDER' | translate}}" >
    
    <ng-template pTemplate="selectedItem">
      <div *ngIf="selectedRelativeDate">
        <div *ngIf="timePeriodDate; else customDate">
          <div class="text-sm" *ngIf="timePeriodDate != null">{{timePeriodDate[0] | date: 'MM/dd/yyyy'}} -
            {{timePeriodDate[1] | date: 'MM/dd/yyyy'}}</div>
        </div>

        <ng-template #customDate>
          <div class="text-sm flex align-items-center justify-content-between">
            {{selectedTimePeriod.label}}
            <span class="text-gray-500">{{selectedTimePeriod.date['from_date'] | date: 'mediumDate'}}</span>
          </div>
        </ng-template>
      </div>

    </ng-template>
    <ng-template let-time pTemplate="item">

      <div *ngIf="time.value == 99; else customDateOne">
        <div (click)="customDateModal.next(true)" class="text-sm">{{time.label}}</div>
      </div>

      <ng-template #customDateOne>
        <div class="text-sm flex align-items-center justify-content-between">
          {{time.label}}
          <span class="text-gray-500">{{time.date['from_date']  | date: 'mediumDate'}}</span>
        </div>
      </ng-template>

    </ng-template>
  </p-dropdown>

  <div *ngSwitchCase="'timeperioddropdown'">
    <p-messages  *ngIf="moreThanThreeMonths" severity="error">
      <ng-template pTemplate >
        <div class="-m-2 flex align-items-center">
          <i class="pi pi-exclamation-triangle mr-2 p-error"></i>
          <span class="custom-message p-error text-xs">{{'REPORTS.REPORT_TEMPLATE.FILTER_INFO_1' | translate}}
            <strong>
              <a href="javascript:void(0)" (click)="OpenLongerReport()" class="p-error">{{'REPORTS.REPORT_TEMPLATE.CLICK_HERE' | translate}}</a>
            </strong>
            {{'REPORTS.REPORT_TEMPLATE.FILTER_INFO_2' | translate}}
          </span>
        </div>
      </ng-template>
    </p-messages>
  </div>

  <app-error-msg *ngIf="GetField(form,field.key).touched && GetField(form,field.key).invalid"
  [errors]="GetField(form,field.key).errors" [fieldLabel]="field.label"></app-error-msg>
 
</div>

<app-datepicker *ngIf="customDateModal | async as showPopup" [visible]="showPopup" [form]="form" [dateRange]="timePeriodDate"
  (selectedDate)="onDateSelect($event,form)" (hideCalender)="hideCalender($event)"></app-datepicker>
