import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { AgePipeModule } from 'libs/shared/src/lib/filters/age.pipe';
import { timeLineService } from 'libs/shared/src/lib/filters/timeline.pipe';
import { BehaviorSubject } from 'rxjs';
import { MemberCrownComponentModule } from '../member-crown/member-crown.component';

@Component({
  selector: 'member-since-large',
  templateUrl: './member-since-large.component.html',
  styleUrls: ['./member-since-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberSinceLargeComponent implements OnInit {
  @Input() columnData  = new BehaviorSubject(null);
  dateValue!:string;
  constructor() { }

  ngOnInit(): void {
    this.columnData.subscribe((value:any)=>{
      if(new Date(value?.client_since) > new Date(value?.created_date)){
       this.dateValue=value?.client_since;
      }else{
       this.dateValue=value?.created_date;
      }
   })
  }
  memberSince(fromDate:string){
    return timeLineService(fromDate)
  }
}

@NgModule({
  declarations: [MemberSinceLargeComponent],
  imports: [
    CommonModule,
    AgePipeModule,
    MemberCrownComponentModule
  ],
  exports: [MemberSinceLargeComponent]
})
export class MemberSinceLargeComponentModule {
}
