
<ng-container *ngIf="{
  row: columnData | async
} as $">
<ng-container *ngIf="$.row && $.row.type">
  <i class="{{getIconName($.row.type)}} mr-1"></i><span>{{$.row.type | titlecase}}</span>
</ng-container>
<ng-container *ngIf="$.row && !$.row.type">...</ng-container>
</ng-container>

