import { Component, OnInit, ChangeDetectionStrategy, NgModule, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Component({
  selector: 'action-column',
  templateUrl: './action-column.component.html',
  styleUrls: ['./action-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionColumnComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null)
  aData!:any;

  @Output() onAction = new EventEmitter(); // {type:actionName,columnData }

  keyName!: string ;
  value!:any;
  constructor() {

  }

  ngOnInit(): void {
    forkJoin([this.additionData,this.columnData]).subscribe({next:(data:any)=>{
      this.aData=data[1];

      this.keyName=data[0].keyName;
    this.value=this.aData[this.keyName];
    }});

   }

  executeAction(action:string){
    this.onAction.emit({action,columnData:this.aData});
  }

}

@NgModule({
  declarations: [ActionColumnComponent],
  imports: [],
  exports: [ActionColumnComponent]
})
export class ActionColumnComponentModule {
}
