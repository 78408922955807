import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";


export function confirmationTextValidator(
  matchTo: string,
  isBlocked: any, 
  reverse?: boolean
): ValidatorFn {
  return (control: AbstractControl | any): 
  ValidationErrors | null => {
    console.log('control', control.value.toLowerCase().replaceAll(/\s/g,''), control.parent?.controls[matchTo].value.toLowerCase().replaceAll(/\s/g,''))
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (c) {
        c.updateValueAndValidity();
      }
      return null;
    }
    const confirMationText = 'iconfirm'+(control.parent?.controls as any)[matchTo].value.toLowerCase().replaceAll(/\s/g,'')+'canbe'+isBlocked.toLowerCase().replaceAll(/\s/g,'')
    const controlValue =  control.value.toLowerCase().replaceAll(/\s/g,'')
    const confirMationTextQoute = "'iconfirm" +(control.parent?.controls as any)[matchTo].value.toLowerCase().replaceAll(/\s/g,"")+"canbe"+isBlocked.toLowerCase().replaceAll(/\s/g,"") + "'"
    return !!control.parent &&
      !!control.parent.value &&
      (controlValue == confirMationText || controlValue == confirMationTextQoute)  ? null : { wrongText: true };
  };
}
