import { Cities } from "@fitness-force/models";

export const CITY_MASTER_STATE: Cities = {
    city: {
        id: 0,
        name: '',
        code: '',
        state_code: '',
        state_id: 0
    },
    cities: []
}