import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, EventEmitter, Output } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BehaviorSubject } from 'rxjs';
import { FactoryHttpLoader } from '../../../helpsupport/helpsupport.component';
@Component({
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null)
  @Input() additionData = new BehaviorSubject(null) // expected data ["edit","active","inactive"] etc
  @Input() rowIndex:number=0;

  @Output() onAction = new EventEmitter(); // {type:actionName,columnData }
  showOptions: boolean = false;
  constructor() { }

  ngOnInit(): void {
   }

  executeAction(action:string,columnData:any,overlayElement:any){
    overlayElement.hide();
    this.onAction.emit({action,columnData:columnData,rowIndex:this.rowIndex});
  }
  isActive(columnData:any):boolean{
    return columnData.status.toUpperCase() == 'Active'.toUpperCase()
  }

}

@NgModule({
  declarations: [ActionsComponent],
  imports: [
    CommonModule,
     ButtonModule,
     OverlayPanelModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    })
  ],
  exports: [ActionsComponent]
})
export class ActionsComponentModule {
}
