import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONST_URL_SEGEMENTS } from '@fitness-force/constants';
import { AppErrors, NotFoundError } from '@fitness-force/errors';

import { environment } from 'apps/ff-frontend/src/environments/environment';
import { throwError, Observable, pipe } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService {

  constructor(private http: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  getServiceCategories(tenantId: number,allParams:Array<string>=[]) {
    let parsedParams = new HttpParams()
    if(allParams.length > 0){

     allParams.forEach((p: any) => {

       parsedParams = parsedParams.append(p.name, p.value);
     });
    }
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/service_categories`,
        {headers: this.httpOptions.headers , params: parsedParams}
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
  getSingleServiceCategory(tenantId: number,categoryId:number) {
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/service_categories/${categoryId}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  updateServiceCategory(tenantId: number,ser_cat_id:number,data:any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/service_categories/${ser_cat_id}`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


createServiceCategory(tenantId: number,data:any) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/service_categories`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }



  markActive(tenantId: number,ser_cat_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/service_categories/${ser_cat_id}/mark_as_active`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }

  markInActive(tenantId: number,ser_cat_id:number) {
    return this.http
      .post(
        `${environment.BASE_URL}/tenants/${tenantId}/service_categories/${ser_cat_id}/mark_as_inactive`,
        {},
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }


}
