import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { timeLineService } from '@fitness-force/shared';
import { BehaviorSubject } from 'rxjs';

import { MemberCrownComponentModule } from '../member-crown/member-crown.component';

@Component({
  selector: 'member-since',
  templateUrl: './member-since.component.html',
  styleUrls: ['./member-since.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberSinceComponent implements OnInit {
  @Input() columnData  = new BehaviorSubject(null);
  constructor() { }

  ngOnInit(): void {
  }
  memberSince(fromDate:string){
   
    return timeLineService(fromDate)
  }
}

@NgModule({
  declarations: [MemberSinceComponent],
  imports: [
    CommonModule,
    MemberCrownComponentModule
  ],
  exports: [MemberSinceComponent]
})
export class MemberSinceComponentModule {
}
