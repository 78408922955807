import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CurrencyformatPipeModule } from 'libs/shared/src/lib/filters/currencyformat.pipe';
import { DateTimeFormatPipeModule } from 'libs/shared/src/lib/filters/datetimeformat.pipe';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject } from 'rxjs';
import { InvoiceDetailsComponentModule } from '../../../client-profile/invoice-details/invoice-details.component';
import { CurrencyComponentModule } from '../currency/currency.component';

@Component({
  selector: 'invoice-data',
  templateUrl: './invoice-data.component.html',
  styleUrls: ['./invoice-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDataComponent implements OnInit {
  @Input() columnData  = new BehaviorSubject(null);
  @Output() onAction = new EventEmitter();
  
  showInvoice:boolean= false;
  invoiceDetails:any;
  constructor() { }

  ngOnInit(): void {
  }

  OpenInvoiceDetails(invoiceNumber: string)
  {
    //this.showInvoice = true;
    //this.invoiceDetails= this.columnData;
    this.onAction.emit({action:'openinvoice',columnData:invoiceNumber});
  }
}

@NgModule({
  declarations: [InvoiceDataComponent],
  imports: [
    CommonModule,
    DividerModule,
    TimelineModule,
    ToastModule,
    DateTimeFormatPipeModule,
    CurrencyformatPipeModule,
    TableModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    AvatarModule,
    TagModule,
    StyleClassModule,
    CurrencyComponentModule,
    DialogModule,
    InvoiceDetailsComponentModule
    
  ],
  exports: [InvoiceDataComponent]
})
export class InvoiceDataComponentModule {
}
