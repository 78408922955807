export enum ClassAppointmentBehaviour {
  Free = 0,
  Paid = 1,
  Membership_Paid = 2,
  Free_for_Members = 3,
}

export enum AppointmentCommissionMode{
  NoCommission="No Commission",
  RevenueShare="Revenue Share",
  FlatFees="FlatFees",
}


export enum AppointmentBehaviour {
  Paid = "Paid",
  Membership_Paid = "Membership + Paid",
  Free = "Free",
  Free_for_Members = "Free for members",
  // AppointmentBehaviour = "AppointmentBehaviour"
}

export enum ThingsToCarry{
  Water_Bottle="Water Bottle",
  Id_card="Id card",
  Towel="Towel",
  Resistance_N="Resistance N",
}

export enum AppointmentDurations{
  "15 min"="15",
  "30 min"="30",
  "45 min"="45",
  "60 min"="60",
  "90 min"="90",
}








