import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppErrors, NotFoundError } from '@fitness-force/errors';
import { environment } from 'apps/ff-frontend/src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvancedSettingService {
  constructor(private http: HttpClient, private router: Router) {}
  BASE_URL: string = environment.BASE_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  getAdvanceSetting(tenantId:number,tentstgKey:string){
    return this.http
      .get(
        `${environment.BASE_URL}/tenants/${tenantId}/tenant_settings/${tentstgKey}`,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
  updateAdvanceSettings(tenantId: number,  data: any) {
    return this.http
      .put(
        `${environment.BASE_URL}/tenants/${tenantId}/tenant_settings/update`,
        data,
        this.httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: Response) => {
          if (error.status === 404) {
            return throwError(() => new NotFoundError(error));
          }
          return throwError(() => new AppErrors(error));
        })
      );
  }
}
