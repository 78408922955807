<!-- Contract Sidebar -->
<p-sidebar [(visible)]="showPaymentStatusSidebar" [showCloseIcon]="false" position="right" [blockScroll]="false"
  styleClass="w-full payment-sidebar" [dismissible]="false" [closeOnEscape]="false">

  <div *ngIf="showLoader" class="absolute top-0 left-0 w-full">
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
  </div>

  <!-- Header -->
  <div class="-mt-1 py-2 px-3 md:p-3 border-bottom-1 border-gray-300 relative flex align-items-center justify-content-between">

    <h4 class="flex align-items-center mb-0 mr-3 font-normal">
      <i class="pi pi-money-bill text-gray-400 mr-3"></i>
      <span class="text-lg font-normal">{{'POS.PAYMENT_SUCCESS.PAYMENT_INITATED' | translate}}</span>
    </h4>

    <!-- Close Icon -->
    <i class="pi pi-times text-gray-600 cursor-pointer absolute right-0 top-0 mt-2 md:mt-3 mr-3"
      (click)="setHidePaymentSideBar()"></i>
    <!-- Close Icon -->

  </div>
  <!-- Header -->

  <!-- Top Part -->
  <div class="py-2 px-3 md:p-3" *ngIf="subsData$ | async as subscription">
    <span *ngIf="!showSkeleton" class="bg-blue-50 px-2 py-1 border-round text-primary text-xs border-primary border-1 inline-block mt-1 md:mt-0">
      {{'POS.PLANTYPE.' + subscription.subscription_category.toUpperCase() |translate }}
    </span>
    <p-skeleton *ngIf="showSkeleton" width="100%" height="1.35rem" styleClass="mb-0 mt-2 md:mb-1"></p-skeleton>

    <!-- Heading -->
    <div class="flex justify-content-between mt-2 md:mt-3">
      <div *ngIf="!showSkeleton">
        <h3 class="text-base font-bold mb-2">{{subscription.name_primary_language}}</h3>
        <p class="text-xs text-gray-600 mb-2 md:w-20rem">{{subscription.description_primary_language}}</p>
      </div>
      <p-skeleton *ngIf="showSkeleton" width="100%" height="1.35rem" styleClass="mb-0 mt-2 md:mb-1"></p-skeleton>

      <!-- <div *ngIf="!showSkeleton" class="white-space-nowrap ml-5 text-lg text-right">
        <strong>{{ this.payingToday(subscription) | currencyformat }}</strong>
        <i class="block text-xs mt-1" *ngIf="isMonthlyPlan"> {{ordinalNumber(subscription.subscription_bill_cycle_day)}}
          of every month</i>
        <i class="block text-xs mt-1" *ngIf="isWeeklyPlan"> on every
          {{ordinalNumber(subscription.billing_interval_value)}} {{weekNames[(subscription.subscription_bill_cycle_day *
          -1) - 1]}}
        </i>
        <i class="block text-gray-600 text-xxs">{{'POS.MEMBERSHIP_ADDON.PRICEWITHTAX' | translate}}</i>
      </div>
       -->
      <p-skeleton *ngIf="showSkeleton" width="4rem" height="1.35rem"></p-skeleton>
    </div>
    <!-- Heading -->

    <p-divider styleClass="m-0"></p-divider>

    <!-- Row -->
    <div *ngIf="!showSkeleton" class="mb-0 mt-2 md:mb-1 text-xs text-gray-600"
      [innerHTML]="allServiceNameHtml(subscription)">
    </div>
    <!-- Row -->

    <p-skeleton *ngIf="showSkeleton" width="100%" height="1.35rem" styleClass="mb-0 mt-2 md:mb-1"></p-skeleton>

    <!-- Features -->
    <p-divider styleClass="my-2"></p-divider>
    <div *ngIf="!showSkeleton" class="grid my-0 text-xs white-space-nowrap">
      <ng-container *ngFor="let charge of subscription.charging_today?.signup_charges">
        <div class="col-12 sm:col-4 flex align-items-center">
          <i class="pi pi-check-square text-xs text-gray-500 mr-2"></i>
          <span>{{charge.chrgmst_name_pri_lang}} + {{charge.chrgmst_net_cost_in_minor_curr |
            currencyformat}}</span>
        </div>
      </ng-container>
    </div>
    <p-divider styleClass="mt-2 mb-4"></p-divider>

    <p-skeleton *ngIf="showSkeleton" width="100%" height="1.35rem" styleClass=""></p-skeleton>

  </div>
  <!-- Top Part -->

  <!-- Mid Part -->
  <img class="block mx-auto w-10rem md:w-14rem" src="/assets/img/handheld-mobile-img.svg" alt="Image" />

  <!-- Error Message -->
  <div class="-my-3">
    <p-messages severity="warn">
      <ng-template pTemplate>
        <div class="flex align-items-centers md:w-29rem">
          <i class="pi pi-info-circle mr-2 p-warn text-xl"></i>
          <span class="custom-message p-warn text-sm"><strong>{{'POS.PAYMENT_SUCCESS.CLOSE_WINDOW_ANYTIME' |
              translate}}</strong>
            {{'POS.PAYMENT_SUCCESS.PAYMENT_RUN_BACKGROUND' | translate}} </span>
        </div>
      </ng-template>
    </p-messages>
  </div>
  <!-- Error Message -->

  <!-- Mid Part -->

  <!-- Bottom Part -->
  <div class="py-2 px-3 md:p-3 surface-100 border-bottom-1 border-gray-300">
    <div class="text-center mx-auto md:w-28rem">
      <h3 class="text-2xl mb-2">{{'POS.PAYMENT_SUCCESS.ALMOST_DONE' | translate}}</h3>
      <p class="mb-0 text-base text-gray-700">{{'POS.PAYMENT_SUCCESS.GREATJOB' | translate}}</p>
    </div>

    <p-divider styleClass="w-5rem mt-3 mb-4 mx-auto border-top-2 border-gray-400"></p-divider>

    <p-skeleton *ngIf="showSkeleton" width="100%" height="10.35rem" styleClass=""></p-skeleton>

    <!-- Timeline -->
    <!-- Timeline -->
    <ng-container *ngIf="subscriptionSignUpStatus$ | async as paymentStatus">
      <p-timeline *ngIf="!showSkeleton" [value]="paymentStatus" styleClass="payment-status md:w-28rem mt-5 md:ml-4">
        <ng-template pTemplate="marker" let-event>
          <span *ngIf="event.status == 'Done'"
            class="custom-marker h-2rem w-2rem border-circle bg-green-500 text-white flex align-items-center justify-content-center">
            <i [ngClass]="event.icon"></i>
          </span>
          <span *ngIf="event.status == 'Error'"
            class="custom-marker h-2rem w-2rem border-1 border-circle p-error flex align-items-center justify-content-center">{{event.step}}</span>
          <span *ngIf="event.status == 'Pending'" class="flex align-items-center justify-content-center relative">
            <i class="pi pi-spin pi-spinner text-primary text-4xl absolute left-0 top-0"></i>
            <span
              class="custom-marker h-2rem w-2rem border-circle text-primary flex align-items-center justify-content-center text-sm">{{event.step}}</span>
          </span>
          <span *ngIf="event.status == 'Not Done'"
            class="custom-marker h-2rem w-2rem border-circle border-2 text-gray-300 border-gray-300 flex align-items-center justify-content-center text-sm">
            {{event.step}}
          </span>
        </ng-template>
        <ng-template pTemplate="content" let-event>
          <div class="mb-4">
            <h4 class="text-sm font-bold mb-0" [ngClass]="event.status=='Error' ? 'p-error' : '' ">
              {{'POS.PAYMENT_SUCCESS.' + event.title.toUpperCase() |translate }}</h4>
            <p class="text-sm m-0" [ngClass]="event.status=='Error' ? 'p-error' : 'text-gray-600' ">
              {{'POS.PAYMENT_SUCCESS.' + event.text.toUpperCase() |translate }}</p>


            <div *ngIf="event.step == 1">
              <div class="text-sm text-gray-600 m-0">{{'POS.PAYMENT_SUCCESS.WAIVER_LINK' | translate}} <a
                  class="text-primary" href="{{bitlyLink}}">{{bitlyLink}}</a></div>
              <div class="md:flex align-items-center my-2">
                <div class="w-11rem h-11rem md:mr-2">
                  <qr-code value={{bitlyLink}} size="4" errorCorrectionLevel="L" centerImageSrc="undefined"
                    centerImageSize="undefined" margin="4">
                  </qr-code>
                </div>
                <div class="relative inline-block mt-3 md:mt-0" *ngIf="subscriptionId || subscriptionId <=0">
                  <p-button styleClass="text-sm justify-content-between align-items-center p-0" pStyleClass="@next"
                    enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout"
                    [hideOnOutsideClick]="true">
                    <div class="flex align-items-center mr-3 p-2">
                      <i class="pi pi-paperclip "></i><span
                        class="p-button-label ml-2">{{'POS.PAYMENT_SUCCESS.RESEND_LINK' | translate}}</span>
                    </div>
                    <div class="p-2 border-left-1 border-white">
                      <i class="pi pi-angle-down mt-1 ml-1"></i>
                    </div>
                  </p-button>
                  <div
                    class="resend-dropdown hidden absolute left-0 w-full top-0 mt-6 z-1 bg-white border-round border-1 border-gray-300 py-2 text-sm">
                    <div (click)="ResendPaymentLink('email')" class="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                      pStyleClass=".resend-dropdown" enterClass="hidden" enterActiveClass="scalein"
                      leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                      {{'POS.PAYMENT_SUCCESS.SEND_EMAIL_LINK' | translate}}</div>
                    <div (click)="ResendPaymentLink('sms')" class="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                      pStyleClass=".resend-dropdown" enterClass="hidden" enterActiveClass="scalein"
                      leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                      {{'POS.PAYMENT_SUCCESS.SEND_SMS_LINK' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-timeline>
    </ng-container>
    <!-- Timeline -->

  </div>
  <!-- Bottom Part -->

  <div class="text-center my-5" *ngIf="this.subscriptionId > 0">
    <p-button label="Goto Subscription Details" class="text-sm" styleClass="w-20rem"
      [routerLink]="['/client/profile',clientId,'subscriptions',subscriptionId]"></p-button>
  </div>
  <p-toast position="top-right" [breakpoints]="{'767px': {width: '100%', left: '0'}}"></p-toast>
</p-sidebar>
<!-- Contract Sidebar -->