import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, EventEmitter, Output } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { FactoryHttpLoader } from '../../../helpsupport/helpsupport.component';

@Component({
  selector: 'no-services',
  templateUrl: './no-services.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoServicesComponent implements OnInit {

  @Output() onAction = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  addServiceModal(){
    this.onAction.emit({action:"createService"});
  }
}

@NgModule({
  declarations: [NoServicesComponent],
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FactoryHttpLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [NoServicesComponent]
})
export class NoServicesComponentModule {
}
