import { TENANTSINTERFACE } from './../../../../../libs/models/src/lib/tenant';
import { AppointmentTypeList, City, ClassTypeList, Country, IPSetting, ListRoomResponse, State, TENANT_MODEL } from '@fitness-force/models';
import { ActivatedRoute, Router } from '@angular/router';
import { StaffService } from './../../../../../libs/api/src/lib/staff.service';
import { TenantService } from './../../../../../libs/api/src/lib/tenant.service';
import { Store } from '@ngrx/store';
import { environment } from './../../environments/environment';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ERRORREPORTLIST, ERRORSTAFFLIST, ERROR_COMPANY_DETAILS, ERROR_TENANT_LIST, FETCHED_COMPANY_DETAILS, FETCHED_TENANT_DETAILS, FETCHED_TENANT_LIST
  , FETCH_APPOINTMENT_TYPE_LIST, FETCH_CITY_MASTER, FETCH_CITY_MASTER_LIST, FETCH_CLASS_TYPE_LIST, FETCH_COUNTRY_MASTER, FETCH_COUNTRY_MASTER_LIST, FETCH_CURRENT_LOCATION, FETCH_REPORT_LIST, FETCH_ROOM_LIST, FETCH_SERVICETYPE_LIST, FETCH_STAFF_LIST, FETCH_STAFF_LIST_ALL, FETCH_STAGE_MASTER, SELECT_LANGUAGE } from './actions';
import { catchError, map, of, switchMap, Observable, tap, mergeMap, concatMap, exhaustMap, forkJoin, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { CLAIMS_INFO, ROUTER_STATE, TENANTS_INFO } from './app.state';
import { AppInitService, AppointmentTypesService, AuthSevices, CityService, ClassTypeService, CountryService, LocationService, OpportunityStageService, ReportsService, RoomService, ServiceTypeService, StateService } from '@fitness-force/api';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { OpportunityBoardType } from '@fitness-force/enum';
import { FETCH_STATE_MASTER, FETCH_STATE_MASTER_LIST } from './actions/state.action';


@Injectable()
export class CompanyEffects {
  accessTenants: Array<TENANT_MODEL> = []
  selectedTenant: any;
  tenantId: any;
  tenant: any;
  selected_tenant: any;
  currentRoute: any
  country: any;
  state: any;
  city: any;

  constructor(
    private actions$: Actions,
    private store: Store,
    private appInit: AppInitService,
    private tenants: TenantService,
    private staff: StaffService,
    private authService: AuthSevices,
    private router: Router,
    private reportService: ReportsService,
    private activeRoute: ActivatedRoute,
    private stageMasterService: OpportunityStageService,
    private countryService: CountryService,
    private appointmentTypeService: AppointmentTypesService,
    private classTypeService: ClassTypeService,
    private stateService: StateService,
    private cityService: CityService,
    private serviceTypeService: ServiceTypeService,
    private roomService:RoomService,
    private locationService: LocationService
  ) {
  }

  getCompanyDetails$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCHED_COMPANY_DETAILS'),
      switchMap(() => this.appInit.getCompanyInformation('', environment.BASE_URL)),
      mergeMap((companyDetails: any) => [
          SELECT_LANGUAGE({ lang: this.appInit.parseLanguage(companyDetails.primary_language) }),
          FETCHED_COMPANY_DETAILS({ companyDetails }),
          this.store.dispatch({ type: 'FETCH_CURRENT_LOCATION' })
        ]),
      catchError((err) => of(
        ERROR_COMPANY_DETAILS({ err: { details: { status: err.originalError.status }, status: true } }))),
    );
  });

  getTenantList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCHED_TENANT_LIST'),
      exhaustMap(() => this.tenants.GetTenantList(environment.BASE_URL)),
      map((tenantList: any) => {
        const TENANT_DEFAULT = localStorage.getItem('TENANT_DEFAULT');
        this.store.select(CLAIMS_INFO).subscribe((data) => {
          this.accessTenants = tenantList.items.filter((item: any) => { return data.accessed_tenant.includes((item.id).toString()) })
        })
        this.store.select(ROUTER_STATE).subscribe((rd) => {
          this.tenantId = rd.queryParams.tenant
        })

        if (this.tenantId) {
          this.selected_tenant = this.accessTenants.find((t: any) => (t.id == parseInt(this.tenantId)) ? t.id : t[0]);

        } else if (TENANT_DEFAULT) {
          this.selected_tenant = this.accessTenants.find((t: any) => (t.id == parseInt(TENANT_DEFAULT)) ? t.id : t[0]);
        } else {
          this.selected_tenant = this.accessTenants[0]
        }
        return { accessTenant: this.accessTenants, selectedTenant: this.selected_tenant }
      }),
      mergeMap((tenant) => [
        FETCHED_TENANT_LIST({ accessed_tenant: tenant.accessTenant }),
        FETCHED_TENANT_DETAILS({ selected_tenant: tenant.selectedTenant })
      ]
      ),
      tap((tf: any) => {
        this.store.dispatch({ type: 'FETCH_COUNTRY_MASTER_LIST' });
        this.store.dispatch({ type: 'FETCH_STATE_MASTER_LIST' });
        this.store.dispatch({ type: 'FETCH_CITY_MASTER_LIST' });
  }),
      catchError((err) => of(ERROR_TENANT_LIST({ err }))),
    );
  });


  getStaffList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_STAFF_LIST'),
      withLatestFrom(this.store.select(TENANTS_INFO)),
      map((value: any) => {
        return value[1].selected_tenant;
      }),
      exhaustMap((selectedTenant:any) => {
        let staffList=of([]);
        if(selectedTenant.id){
           staffList = this.staff.GetStaffList(
            environment.BASE_URL,
            selectedTenant.id
          );
        }
        let staffListAll = this.staff.GetStaffList(environment.BASE_URL, 0);
        let obj = forkJoin({
          staffList: staffList,
          staffListAll: staffListAll,
        });
        return obj;
      }),
      map((staff:any) => {
        return {
          staffList: staff.staffList && staff.staffList.items?staff.staffList.items:staff.staffList,
          staffListAll: staff.staffListAll.items,
        };
      }),
      mergeMap((staffs) => [
        FETCH_STAFF_LIST({ staffList: staffs.staffList }),
        FETCH_STAFF_LIST_ALL({ staffListAll: staffs.staffListAll }),
      ]),
      catchError((err) => of(ERRORSTAFFLIST(err)))
    );
  });


  
  getStageMaster$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_STAGE_MASTER'),
      mergeMap(() => this.stageMasterService.OpportunityStageMasterListService(this.selected_tenant.id, OpportunityBoardType.NewMemberBoard)),
      map((stages: any) => {
        // console.log('------- EFFects running  stages', stages)
        return (FETCH_STAGE_MASTER({ stages: stages.items }));
      }),
      catchError((err) => of(console.log(err))),
    );
  });

  getCountryMasterList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_COUNTRY_MASTER_LIST'),
      exhaustMap(() => this.countryService.GetCountry(environment.BASE_URL)),
      map((countries: any) => {
        this.country = countries.find((o: Country) => {
          return (
            String(o.three_letter_code).toLowerCase() ==
            String(this.selected_tenant.country).toLowerCase() ||
            String(o.two_letter_code).toLowerCase() ==
            String(this.selected_tenant.country).toLowerCase()
          );
        });
        // console.log('countryy', this.country);
        return { countries: countries, country: this.country }
      }),
      mergeMap((country) => [
        FETCH_COUNTRY_MASTER_LIST({ countries: country.countries }),
        FETCH_COUNTRY_MASTER({ country: country.country }),
      ]),
      catchError((err) => of(console.log(err))),
    );
  });

  getStateMasterList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_STATE_MASTER_LIST'),
      exhaustMap(() => this.stateService.GetState(environment.BASE_URL, this.selected_tenant.country)),
      map((states: any) => {
        this.state = states.find((o: State) => {
          return (
            String(o.code).toLowerCase() ==
            String(this.selected_tenant.state).toLowerCase()
          );
        });
        // console.log('state', this.state);
        return { states: states, state: this.state }
      }),
      mergeMap((States) => [
        FETCH_STATE_MASTER_LIST({ states: States.states }),
        FETCH_STATE_MASTER({ state: States.state }),
      ]),
      catchError((err) => of(console.log(err))),
    );
  });


  getCityMasterList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_CITY_MASTER_LIST'),
      exhaustMap(() => this.cityService.GetCity(environment.BASE_URL, this.selected_tenant.country, this.selected_tenant.state)),
      map((cities: any) => {
        this.city = cities.find((o: City) => {
          return (
            String(o.code).toLowerCase() ==
            String(this.selected_tenant.city).toLowerCase()
          );
        });
        // console.log('city', this.city);
        return { cities: cities, city: this.city }
      }),
      mergeMap((Cities) => [
        FETCH_CITY_MASTER_LIST({ cities: Cities.cities }),
        FETCH_CITY_MASTER({ city: Cities.city }),
      ]),
      catchError((err) => of(console.log(err))),
    );
  });


  routerNavigate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      tap((r: any) => {
        const routerStateQueryParam = r.payload.routerState.queryParams
        this.currentRoute = routerStateQueryParam.tenant ? r.payload.routerState.url.split('?')[0] : r.payload.routerState.url
        // console.log('rrrrrrr', r)
      }),
      concatMap(() => this.store.select(TENANTS_INFO)),
      map((tenant: TENANTSINTERFACE): void => {
        // console.log('this.activeRoute.firstChild?.snapshot.data.isTenan', this.activeRoute.firstChild?.snapshot.data.isTenant)
        if (this.activeRoute.firstChild?.snapshot.data.isTenant) {
          this.router.navigate([this.currentRoute], {
            queryParams: { tenant: tenant.selected_tenant.id },
          })
        }

      })
    );
  }, { dispatch: false });

  getReportList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_REPORT_LIST'),
      mergeMap(() => this.reportService.GetReports()),
      map((reports: any) => {
        // console.log('reports', reports)
        return (
          FETCH_REPORT_LIST(reports)
        )
      }),
      catchError((err) => of(ERRORREPORTLIST(err))),
    );
  });


  getAppointmentTypeList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_APPOINTMENT_TYPE_LIST'),
      mergeMap(() => this.appointmentTypeService.getAppointmentTypes(this.selected_tenant.id, [])),
      map((appointmentTypes: AppointmentTypeList) => {
        return (FETCH_APPOINTMENT_TYPE_LIST({ appointmentTypes: appointmentTypes.items }))
      }),
      catchError((err) => of(console.log(err))),
    );
  });

  getClassTypeList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_CLASS_TYPE_LIST'),
      mergeMap(() => this.classTypeService.getClassTypes(this.selected_tenant.id)),
      map((classTypes: ClassTypeList) => {
        //console.log('classTtypes', classTypes);
        return (FETCH_CLASS_TYPE_LIST({ classTypes: classTypes.items }))
      }),
      catchError((err) => of(console.log(err))),
    );
  });

  getServiceTypeList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_SERVICETYPE_LIST'),
      mergeMap(() => this.serviceTypeService.GetServiceType(this.selected_tenant.id.toString())),
      map((serviceTypeList: any) => {
        return (
          FETCH_SERVICETYPE_LIST(serviceTypeList)
        )
      }),
      catchError((err) => of(ERRORREPORTLIST(err))),
    );
  });

  getRoomList$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_ROOM_LIST'),
      mergeMap(() => this.roomService.GetRoomList(this.selected_tenant.id)),
      map((rooms: ListRoomResponse) => {
        return (FETCH_ROOM_LIST({ rooms: rooms.items }))
      }),
      catchError((err) => of(console.log(err))),
      );
    });


  getCurrentLocation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType('FETCH_CURRENT_LOCATION'),
      mergeMap(() => this.locationService.getPosition()),
      map((res: any) => {
        return(FETCH_CURRENT_LOCATION({ location:res}))
      }),
      catchError((err) => of(console.log(err))),
      );
    });

}
