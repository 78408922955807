import { RippleModule } from 'primeng/ripple';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CreateProspectComponentModule } from '../../../omnisearch/create-prospect/create-prospect.component';

@Component({
  selector: 'new-prospect',
  templateUrl: './new-prospect.component.html',
  styleUrls: ['./new-prospect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewProspectComponent implements OnInit {
  showCreateProspectPopup: Observable<boolean> = of(false);
  selectedClient: any = new Map()
  clients: any = new BehaviorSubject([])
  constructor() { }

  ngOnInit(): void {
  }
  hidePopup(isHide: boolean) {

    this.showCreateProspectPopup = of(isHide);
  }
  get getSelectedClientData(): Array<any> {
    return [...this.selectedClient.values()];
  }
  OpenPopup() {
    this.clients.next(this.getSelectedClientData)
    this.showCreateProspectPopup = of(true);
  }
}

@NgModule({
  declarations: [NewProspectComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    CreateProspectComponentModule
  ],
  exports: [NewProspectComponent]
})
export class NewProspectComponentModule {
}
