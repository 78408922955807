import { createAction, props } from "@ngrx/store";

export const FETCH_REPORT_LIST = createAction(
    'FETCHREPORTSLIST',
    props<{ reportList: any }>()
);

export const FETCHEDREPORTDETAILS = createAction(
    'FETCHEDREPORT',
    props<{ report: any }>()
);

export const ERRORREPORTLIST  =  createAction(
    'ERROR_REPORT_LIST',
    props<{ error: any }>()
);