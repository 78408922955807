export * from './company.store';
export * from  './master/claims.store';
export * from './tenant.store';
export * from './staff.store';
export * from './reports/reports.store';
export * from './master/stage-master.store';
export * from './master/country-master.store';
export * from './classappointment/appointment-type.store';
export * from './classappointment/class-type.store';
export * from './master/state-master.store';
export * from './master/city-master.store';
export * from './master/servicetype.store';
export * from './master/room.store';
export * from './location.store';
