import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, NgModule, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'opportunity-status',
  templateUrl: './opportunity-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpportunityStatusComponent implements OnInit {
  @Input() columnData = new BehaviorSubject(null);
  constructor() { }

  ngOnInit(): void {
  }
}
@NgModule({
  declarations: [OpportunityStatusComponent],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    StyleClassModule
  ],
  exports: [OpportunityStatusComponent]
})
export class OpportunityStatusModule {
}